import * as backend from "api/backend/solutions/chatbotSolutionServiceEndpoint";
import { getHandlingFromError } from "api/error-handling";

export const getChatbotExecution = async body => {
  const { data, error } = await backend.getChatbotExecution(body);
  return { data, error: getHandlingFromError(error) };
};

export const getChatbotGeneration = async body => {
  const { data, error } = await backend.getChatbotGeneration(body);
  return { data, error: getHandlingFromError(error) };
};
