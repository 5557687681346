import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { range } from "lodash";

import NavWithTabs from "components/ui/NavWithTabs";
import { BigTitle, SmallTitle } from "components/ui/Text";
import ModelView from "components/views/ModelView";
import { useRLPipelineOutputsByIds } from "api/services/projectService/useRLPipelineOutputsByIds";
import JobCard from "components/widgets/JobCard";
import SampleGoGameModal from "components/widgets/GoGame/SampleGoGameModal";
import { getGeneratedData, getJobBundleEvalustions } from "api/services/jobService";
import BarChart from "components/charts/BarChart";
import { isEmpty } from "lodash";
import GoGameInteraction from "./GoGameInteractionSimple";

import TimelineIcon from "@material-ui/icons/Timeline";
import Modal from "components/ui/Modal";
import { Gap } from "components/Layout";

const ViewUnderTab = styled.div`
  padding: 30px 20px 30px 20px;
  background-color: ${props => props.theme.color.closer0};
  width: 100%;
  height: 600px;
  overflow-y: auto;
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0px 0px 5px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RatingBtn = styled.div`
  border-radius: 50px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid ${props => props.theme.color.primary};
  :hover {
    background-color: ${props => props.theme.color.furthest};
    cursor: pointer;
  }
`;

const JobsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const JobRow = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  gap: 10px;
`;

const ColumnTitles = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  gap: 10px;
`;

const GenNumber = styled.div`
  place-self: center;
  font-weight: bold;
`;

const RatingModalContent = styled.div`
  padding: 20px;
  display: flex;
  width: 700px;
  height: 400px;
`;

const CubeViewRL = ({ cube }) => {
  const [pipelineOutputs, , pipelinesFetchErr] = useRLPipelineOutputsByIds(cube?.pipelineIds || []);

  const [sampleData, setSampleData] = useState(null);
  const [showSampleGoGameModal, setShowSampleGoGameModal] = useState(false);
  const [generatedDataError, setGeneratedDataError] = useState(null);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [evalRatings, setEvalRatings] = useState(null);
  const [evalRatingsBarData, setEvalRatingsBarData] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") || "Model");

  useEffect(() => {
    if (evalRatings) {
      const barData = evalRatings.map((e, idx) => ({ generation: idx, rating: e }));
      setEvalRatingsBarData(barData);
    }
  }, [evalRatings]);

  const fecthAndDisplaySampleData = async jobId => {
    const { data, error } = await getGeneratedData(jobId);
    setSampleData(data);
    setGeneratedDataError(error);
    setShowSampleGoGameModal(true);
  };

  const doGetRatings = async () => {
    const { data, error } = await getJobBundleEvalustions(pipelineOutputs[0].jobBundle.id);
    setEvalRatings(data);
  };

  const selectTab = tabName => {
    setSearchParams({ tab: tabName });
    setSelectedTab(tabName);
  };

  if (pipelinesFetchErr) {
    return JSON.stringify(pipelinesFetchErr);
  }

  if (generatedDataError) {
    return JSON.stringify(generatedDataError);
  }

  if (!pipelineOutputs[0]) {
    return (
      <>
        <BigTitle>{cube.name}</BigTitle>
        <Gap />
        loading pipeline outputs...
      </>
    );
  }

  const numGens = pipelineOutputs[0].jobBundle.trainingJobs.length / 2;
  const jobs = pipelineOutputs[0].jobBundle.trainingJobs;
  return (
    <>
      {evalRatingsBarData && (
        <Modal title={"Evaluation by generations"} open={showRatingModal} handleClose={() => setShowRatingModal(false)}>
          <RatingModalContent>
            {!isEmpty(evalRatingsBarData) ? (
              <BarChart
                data={evalRatingsBarData}
                xAxisKey={"generation"}
                yAxisKeyAndColors={[
                  {
                    key: "rating",
                    color: "#0491FF",
                  },
                ]}
                chartSize={{ width: 500, height: 300 }}
              />
            ) : (
              <div>No rating data yet !</div>
            )}
          </RatingModalContent>
        </Modal>
      )}
      {sampleData && (
        <SampleGoGameModal
          games={sampleData}
          open={showSampleGoGameModal}
          onClose={() => setShowSampleGoGameModal(false)}
        />
      )}
      <BigTitle>{cube.name}</BigTitle>
      <NavWithTabs
        tabNames={["Model", "Training", "Interaction"]}
        selectedTabName={selectedTab}
        onTabSelect={newTab => selectTab(newTab)}
      />
      <ViewUnderTab>
        {selectedTab === "Model" && <ModelView modelId={pipelineOutputs[0].model.id} />}
        {selectedTab === "Training" && (
          <>
            <RatingBtn
              onClick={() => {
                doGetRatings();
                setShowRatingModal(true);
              }}
            >
              <TimelineIcon /> Elo Ratings
            </RatingBtn>
            <JobsContainer>
              <ColumnTitles>
                <SmallTitle>Gen №</SmallTitle>
                <SmallTitle>Generation</SmallTitle>
                <SmallTitle>Training</SmallTitle>
              </ColumnTitles>
              {range(numGens).map(genNumber => (
                <JobRow key={genNumber}>
                  <GenNumber>{genNumber}</GenNumber>
                  <JobCard job={jobs[genNumber * 2]} showSampleData={fecthAndDisplaySampleData} />
                  <JobCard job={jobs[genNumber * 2 + 1]} />
                </JobRow>
              ))}
            </JobsContainer>
          </>
        )}
        {selectedTab === "Interaction" && (
          <GoGameInteraction
            trainingGenerationJobMap={{ ...jobs.filter((e, i) => i % 2 == 1 && e.status === "DONE") }}
          />
        )}
      </ViewUnderTab>
    </>
  );
};

export default CubeViewRL;
