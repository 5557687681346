import { useEffect } from "react";
import styled from "styled-components";
import { getSampleDataPointFromDeployment } from "api/services/deploymentService";
import { getCssCode, getHtmlCode, getJsCode } from "components/widgets/ApiConfigPlay/solutionCode";
import { postSolutionQuery } from "api/services/projectService";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

const Container = styled.div`
  grid-column: span 2;
  padding: 0;
  border: 1px dashed ${props => props.theme.color.closer1};
  border-radius: 5px;
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;

  .table-container {
    padding: 10px 0;
    width: 100%;
    overflow-x: scroll;
    grid-column: span 2;
  }

  .table {
    display: grid;
    gap: 15px;
  }
  .table .column-name {
    font-weight: bold;
  }
  .table input {
    min-width: 70px;
    padding: 8px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    width: 100%;
    transition: border 0.2s;
    :focus {
      border: 1px solid #0191ff;
    }
  }

  .outputs-section {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    width: max-content;
  }
  .outputs-section .column-name-output {
    font-weight: bold;
    color: #0191ff;
    text-align: end;
  }

  .blue-button {
    align-self: start;
    padding: 5px 20px;
    cursor: pointer;
    color: ${props => props.theme.color.primary};
    background-color: ${props => props.theme.color.furthest};
    border-radius: 40px;
    border: 2px solid ${props => props.theme.color.primary};
    pointer-events: ${props => props.isDisabled && "none"};
    opacity: ${props => props.isDisabled && 0.2};

    :hover {
      color: ${props => props.theme.color.furthest};
      background-color: ${props => props.theme.color.primary};
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

const populateInitialInputs = async (deploymentClusterId, apiConfigId) => {
  const { data: inputRow } = await getSampleDataPointFromDeployment(deploymentClusterId);

  Object.keys(inputRow).forEach(colName => {
    const input = document.querySelector(`.container-${apiConfigId} #${getSafeColName(colName)}-input`);
    if (input) {
      if (typeof inputRow[colName] === "number") {
        input.type = "number";
      }
      input.value = inputRow[colName];
    }
  });
};

const populatePredictions = async (sessionId, apiConfig) => {
  const inputRow = {};
  apiConfig?.config?.inputs?.forEach(inputCol => {
    const inputValue = document.querySelector(`.container-${apiConfig.id} #${getSafeColName(inputCol)}-input`).value;
    inputRow[inputCol] = inputValue;
  });

  document.querySelector(`.container-${apiConfig.id}`).classList.add("disabled");

  const { data: predictionResult, error } = await postSolutionQuery(sessionId, apiConfig?.id, inputRow);
  if (error) {
    alert(JSON.stringify(error));
    document.querySelector(`.container-${apiConfig.id}`).classList.remove("disabled");
    return;
  }
  apiConfig?.config?.outputs.forEach(outputCol => {
    const outputEl = document.querySelector(`.container-${apiConfig.id} #${getSafeColName(outputCol)}-output`);
    outputEl.innerHTML = predictionResult?.result?.[outputCol]?.winner || predictionResult?.result?.[outputCol]?.value;
  });

  document.querySelector(`.container-${apiConfig.id}`).classList.remove("disabled");
};

const getSafeColName = colName => colName.replaceAll("/", "-");

const ApiConfigPlay = ({
  deploymentClusterId,
  sessionId,
  apiConfig,
  featureTypeDescriptors,
  onChangeSolutionCode,
  solutionId,
}) => {
  useEffect(() => {
    const bearerToken = `Bearer ${getLoginTokenFromClientStorage()}`;
    onChangeSolutionCode({
      html: getHtmlCode(),
      js: getJsCode(
        deploymentClusterId,
        apiConfig,
        featureTypeDescriptors,
        bearerToken,
        solutionId,
        window.location.origin
      ),
      css: getCssCode(),
    });
  }, [featureTypeDescriptors]);

  useEffect(() => {
    const table = document.querySelector(`.container-${apiConfig.id} .table`);

    table.innerHTML = apiConfig?.config?.inputs?.map(colName => `<div class="column-name">${colName}</div>`).join("");
    table.style = "grid-template-columns: repeat(" + apiConfig?.config?.inputs?.length + ", 1fr);";

    table.innerHTML =
      table.innerHTML +
      apiConfig?.config?.inputs
        .map(colName => {
          const featureDescription = featureTypeDescriptors?.find(desc => desc.key === colName);
          if (featureDescription?.type?.typeName === "CATEGORICAL") {
            return `
              <select id="${getSafeColName(colName)}-input">
                ${featureDescription?.type?.categories?.map(cat => `<option value="${cat}">${cat}</option>`).join("")}
              </select>`;
          }
          return `<input id="${getSafeColName(colName)}-input" type="text" />`;
        })
        .join("");

    const outputsSection = document.querySelector(`.container-${apiConfig.id} .outputs-section`);
    outputsSection.innerHTML = apiConfig?.config?.outputs
      .map(
        colName => `
        <div class="column-name-output">${colName}</div>
        <div id="${getSafeColName(colName)}-output"></div>
      `
      )
      .join("");

    populateInitialInputs(deploymentClusterId, apiConfig?.id);
  }, [apiConfig, featureTypeDescriptors]);

  return (
    <Container className={`container-${apiConfig.id}`}>
      <div className="table-container">
        <div className="table"></div>
      </div>
      <button className="blue-button" onClick={() => populatePredictions(sessionId, apiConfig)}>
        Predict
      </button>
      <div className="outputs-section"></div>
    </Container>
  );
};

export default ApiConfigPlay;
