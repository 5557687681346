import styled from "styled-components";
import { useState, useEffect } from "react";
import { PlayCircleOutline, PauseCircleOutline } from "@material-ui/icons";

import { getTrainingJobById, killJobById, patchJobById } from "api/services/jobService";
import useTrainingProgressForJobId from "api/services/jobService/useTrainingProgressForJobId";
import TrainingJobLineChart from "components/widgets/TrainingJobLineChart";
import ProgressBar from "components/ui/ProgressBar";
import { Gap } from "components/Layout";
import { postContinuePipelineTraining } from "api/services/projectService";
import { round } from "lodash";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  background-color: ${props => props.theme.color.closer0_5};
  height: 500px;
  padding-bottom: 100px;
  padding-top: 10px;
`;

const PauseResumeBlock = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  justify-items: center;
  align-items: center;
`;

const PauseResumeButton = styled.div`
  ${props => props.isDisabled && "opacity: 0.1; pointer-events: none;"}
  margin: 10px;
  display: flex;
  padding: 10px;
  height: 20px;
  width: 200px;
  size: 14px;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.closest};
  border: 1px solid ${props => props.theme.color.closest};
  border-radius: 50px;

  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    border-color: ${props => props.theme.color.primary};
  }
`;

const WaitingContainer = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: max-content auto;
  height: 40px;
  gap: 10px;
  align-items: center;
`;

const PulsingBlob = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: rgba(1, 145, 255, 1);
  box-shadow: 0 0 0 0 rgba(1, 145, 255, 1);
  animation: pulse-blue 2s infinite;

  @keyframes pulse-blue {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
`;

const PauseResumeIcon = styled.div``;

const ProgressBarContainer = styled.div`
  width: calc(100% - 150px);
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
`;

const useMessageStrChangingOverTime = ({ msToMessage, shouldTimerStart = true }) => {
  const [message, setMessage] = useState(msToMessage[0]);
  const [msSinceStart, setMsSinceStart] = useState(0);

  useEffect(() => {
    if (!shouldTimerStart) {
      return;
    }

    const interval = setInterval(() => {
      setMsSinceStart(prevMs => prevMs + 1000);
    }, 1000);

    return () => clearInterval(interval);
  }, [shouldTimerStart]);

  useEffect(() => {
    if (!shouldTimerStart) {
      return;
    }

    const message = msToMessage[msSinceStart];
    if (message) {
      setMessage(message);
    }
  }, [msSinceStart, msToMessage, shouldTimerStart]);

  return [message];
};

const TrainingProgressView = ({ pipelineOutput, doRestartPipelineOutputPolling }) => {
  const navigate = useNavigate();

  const [jobWorkerId, setJobWorkerId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const jobId = pipelineOutput?.trainingJob?.id;

  const [trainingJobProgressPoints, trainingJob] = useTrainingProgressForJobId(jobId, pipelineOutput?.id);
  const [trainingProgress, setTrainingProgress] = useState(0);
  const [maxIter, setMaxIter] = useState(0);

  const isTrainingFinished = trainingJobProgressPoints?.length === maxIter && trainingJobProgressPoints?.length > 0;

  const [preTrainingMessage] = useMessageStrChangingOverTime({
    msToMessage: {
      0: "The machine has been allocated…",
      3000: "Training is about to start…",
      8000: "Please be patient...",
    },
    shouldTimerStart: trainingJob?.status === "RUNNING" && !trainingJobProgressPoints?.length,
  });

  const [postTrainingMessage] = useMessageStrChangingOverTime({
    msToMessage: {
      0: "Packing up model...",
      3000: "Uploading for serving...",
      8000: "About to launch...",
    },
    shouldTimerStart: trainingJob?.status === "RUNNING" && isTrainingFinished,
  });

  useEffect(() => {
    process.env.REACT_APP_IS_RESEARCH && jobId && doGetJobWorkerId();
  }, [jobId]);

  useEffect(() => {
    setMaxIter(trainingJobProgressPoints?.[0]?.max_iter || 0);
  }, [trainingJobProgressPoints?.[0]?.max_iter]);

  useEffect(() => {
    if (trainingJob?.status === "RUNNING" && trainingJobProgressPoints?.length === 0) {
      setTrainingProgress(0.1);
      return;
    }

    const realProgress = round(trainingJobProgressPoints?.length / maxIter, 2);
    if (realProgress > 0.1 && realProgress <= 0.9) {
      setTrainingProgress(realProgress);
      return;
    }

    if (trainingJob?.status === "DONE") {
      setTrainingProgress(1);
      return;
    }
  }, [trainingJobProgressPoints?.length, trainingJob?.status]);

  const doPauseJob = async () => {
    setIsLoading(true);
    await patchJobById(jobId, {}, { cancelRequested: true });
    doRestartPipelineOutputPolling();
  };

  const doResumeJob = async () => {
    setIsLoading(true);
    const { error } = await postContinuePipelineTraining(pipelineOutput?.pipelineId);
    if (error) {
      alert("Error resuming training ", JSON.stringify(error));
    }
    setIsLoading(false);
    doRestartPipelineOutputPolling();
    window.location.reload();
  };

  const doGetJobWorkerId = async () => {
    const { data, error } = await getTrainingJobById(jobId);
    if (error) {
      return;
    }
    setJobWorkerId(data.workerId);
  };

  let aboveProgressBarContent = <Gap height="40px" />;
  if (trainingJob?.status === "RUNNING" && !trainingJobProgressPoints?.length) {
    aboveProgressBarContent = (
      <WaitingContainer>
        <PulsingBlob />
        {preTrainingMessage}
      </WaitingContainer>
    );
  }
  if (trainingJob?.status === "RUNNING" && trainingJobProgressPoints?.length > 0 && !isTrainingFinished) {
    aboveProgressBarContent = (
      <PauseResumeButton isDisabled={isLoading} onClick={doPauseJob}>
        Pause training
        <PauseResumeIcon>
          <PauseCircleOutline />
        </PauseResumeIcon>
      </PauseResumeButton>
    );
  }
  if (trainingJob?.status === "RUNNING" && isTrainingFinished) {
    aboveProgressBarContent = (
      <WaitingContainer>
        <PulsingBlob />
        {postTrainingMessage}
      </WaitingContainer>
    );
  }
  if (trainingJob?.status === "CANCELLED") {
    aboveProgressBarContent = (
      <PauseResumeButton onClick={doResumeJob}>
        Resume training
        <PauseResumeIcon>
          <PlayCircleOutline />
        </PauseResumeIcon>
      </PauseResumeButton>
    );
  }
  if (trainingJob?.status === "DONE") {
    aboveProgressBarContent = (
      <PauseResumeButton onClick={() => navigate("?tab=API")}>Go to deployment</PauseResumeButton>
    );
  }

  return (
    <Container>
      <PauseResumeBlock>
        {aboveProgressBarContent}
        <ProgressBarContainer>
          <ProgressBar currentValue={trainingJob?.progress} maxValue={100} />
          {trainingJob?.progress?.toFixed()}%
        </ProgressBarContainer>
      </PauseResumeBlock>
      {/* {error && <div>{error?.message}</div>} */}
      {process.env.REACT_APP_IS_RESEARCH && (
        <div>
          Job Id: {jobId}, Worker Id: {jobWorkerId}
        </div>
      )}
      <TrainingJobLineChart data={trainingJobProgressPoints} />
    </Container>
  );
};

export default TrainingProgressView;
