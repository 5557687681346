import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  generateDatasetProcessedPreview,
  getDatasetPreviewByDatasetId,
  getDatasetInfoByDatasetId,
  deleteDataset,
} from "api/services/dataService";
import TabularDatasetPreview from "components/widgets/TabularDatasetPreview";
import ImageDatasetPreview from "components/widgets/ImageLabelDataPreview";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { isImageDataset, isTabularDataset, isTextDataset } from "utils/dataset";
import DatasetListSelect from "components/views/CreatePipelineView/DatasetListSelect";
import { Gap } from "components/Layout";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const ModalContent = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  padding: 20px;
  display: grid;
  gap: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
`;

const AreYouSureText = styled.div`
  font-weight: bold;
  grid-column: span 2;
`;

const RedButton = styled(Button)`
  padding: 4px 0;
  color: ${props => (props.isDisabled ? props.theme.color.closer2 : props.theme.color.error)};
  border: 2px solid ${props => (props.isDisabled ? props.theme.color.closer2 : props.theme.color.error)};
  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.error};
  }
`;

const PickDatasetModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 20px;
  width: 650px;
`;

const ScrollingContainer = styled.div`
  height: 500px;
  overflow: auto;
`;

const DeleteDatasetConfirmModal = ({ isOpen, onClose, onClickYes, isDisabled }) => (
  <Modal title="Confirmation" open={isOpen} handleClose={onClose}>
    <ModalContent isDisabled={isDisabled}>
      <AreYouSureText>Are you sure you want to delete this dataset?</AreYouSureText>
      <RedButton value="Yes, delete it" onClick={onClickYes} />
      <Button value="No, cancel" onClick={onClose} />
    </ModalContent>
  </Modal>
);

const ButtonAndText = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-items: end;
`;

const PickDatasetModal = ({ isOpen, onClose, onChooseReplaceDataset }) => {
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Modal open={isOpen} handleClose={onClose} title="Choose new dataset">
      <PickDatasetModalContent>
        <ScrollingContainer>
          <DatasetListSelect
            selectedDatasetId={selectedDataset?.id}
            showEngineSelect={false}
            onDatasetSelect={newDataset => setSelectedDataset(newDataset)}
            onFilterDatasets={dataset => isTextDataset(dataset)}
          />
        </ScrollingContainer>
        <Gap />
        <ButtonAndText>
          Selected: {selectedDataset?.name}
          <Button
            isDisabled={isLoading}
            value="Replace"
            onClick={async () => {
              if (!selectedDataset?.id) {
                return;
              }
              setIsLoading(true);
              await onChooseReplaceDataset(selectedDataset?.id);
              setIsLoading(false);
            }}
          />
        </ButtonAndText>
      </PickDatasetModalContent>
    </Modal>
  );
};

const getImageAndLabelField = datasetInfo => {
  const labelFieldTypes = ["CATEGORICAL"];
  const labelFields = datasetInfo?.config?.featureTypeDescriptors
    .filter(e => labelFieldTypes.includes(e.type.typeName))
    .map(e => e.key);

  const imageField = datasetInfo?.config?.featureTypeDescriptors.filter(
    descriptor => descriptor.type?.structure === "IMAGE"
  )[0].key;

  return { imageField, labelFields };
};

const DatasetPreviewFinetune = ({ datasetId, dataProcessingConfig, onChooseReplaceDataset }) => {
  const [datasetInfo, setDatasetInfo] = useState(null);
  const [datasetPreview, setDatasetPreview] = useState(null);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPickDatasetModalOpen, setIsPickDatasetModalOpen] = useState(false);

  const [isDeletionConfirmModalOpen, setIsDeletionConfirmModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const doFetchDatasetProcessedPreview = async () => {
    const { data, error } = await generateDatasetProcessedPreview(datasetId, dataProcessingConfig);
    setError(error);
    setDatasetPreview(data);
    setIsLoading(false);
  };

  const doFetchDatasetPreview = async () => {
    const { data, error } = await getDatasetPreviewByDatasetId(datasetId);
    setError(error);
    setDatasetPreview(data);
    setIsLoading(false);
  };

  const doFetchDatasetInfo = async () => {
    const { data, error } = await getDatasetInfoByDatasetId(datasetId);
    setError(error);
    setDatasetInfo(data);
  };

  useEffect(() => {
    if (!datasetId) {
      return;
    }

    doFetchDatasetInfo();
    if (dataProcessingConfig) {
      doFetchDatasetProcessedPreview();
      return;
    }
    doFetchDatasetPreview();
  }, [datasetId, dataProcessingConfig]);

  if (!datasetId) {
    return <Container>You no longer have access to this dataset</Container>;
  }

  if (isLoading || !datasetInfo) {
    return <Container>Loading dataset...</Container>;
  }

  if (error?.redirectUrl) {
    window.location = error.redirectUrl;
    return null;
  }

  if (error?.message) {
    return `Error while fetching dataset: ${error.message}`;
  }

  return (
    <Container>
      <PickDatasetModal
        isOpen={isPickDatasetModalOpen}
        onClose={() => setIsPickDatasetModalOpen(false)}
        onChooseReplaceDataset={onChooseReplaceDataset}
      />
      <Button value="Replace dataset" onClick={() => setIsPickDatasetModalOpen(true)} />
      {isTabularDataset(datasetInfo) && (
        <TabularDatasetPreview
          columns={datasetInfo?.config?.featureTypeDescriptors?.map(e => e.key)}
          datasetPreview={datasetPreview}
        />
      )}
      {isImageDataset(datasetInfo) && (
        <ImageDatasetPreview
          datasetPreview={datasetPreview}
          imageField={getImageAndLabelField(datasetInfo).imageField}
          labelFields={getImageAndLabelField(datasetInfo).labelFields}
        />
      )}
      <DeleteDatasetConfirmModal
        isOpen={isDeletionConfirmModalOpen}
        onClose={() => setIsDeletionConfirmModalOpen(false)}
        isDisabled={isDeleting}
        onClickYes={async () => {
          setIsDeleting(true);
          const { error } = await deleteDataset(datasetId);
          if (error) {
            setError(error);
            setIsDeleting(false);
            setIsDeletionConfirmModalOpen(false);
            return;
          }
          window.location = "/data";
        }}
      />
    </Container>
  );
};

export default DatasetPreviewFinetune;
