import PipelineStatus from "components/widgets/PipelineStatus";

const displayPipelineStatusUntilContentReady = ({
  selectedTab,
  pipelineOutputs,
  pipelineStatus = "DRAFT",
  doRestartPolling = () => {},
}) => {
  const trainingJobStatus = pipelineOutputs[0]?.trainingJob?.status;
  const evaluationJobStatus = pipelineOutputs[0]?.evaluationJob?.status;
  const dataProcessingJobStatus = pipelineOutputs[0]?.dataProcessingJob?.status;
  const isAutotunePipeline = pipelineOutputs[0]?.trainingBundle;

  if (pipelineStatus === "DRAFT") {
    if (
      dataProcessingJobStatus === "REGISTERED" ||
      dataProcessingJobStatus === "IN_PROGRESS" ||
      dataProcessingJobStatus === "ABORT" ||
      dataProcessingJobStatus === "KILLED" ||
      dataProcessingJobStatus === "DONE_FAILED" ||
      selectedTab === "Training" ||
      selectedTab === "Evaluation" ||
      selectedTab === "API" ||
      selectedTab === "Notebook"
    ) {
      return <PipelineStatus pipelineOutput={pipelineOutputs[0]} />;
    }

    return null;
  }

  if (selectedTab === "Dataset" || selectedTab === "Model") {
    return null;
  }

  if (
    selectedTab === "Training" &&
    (trainingJobStatus === "RUNNING" ||
      trainingJobStatus === "DONE" ||
      trainingJobStatus === "FAILED" ||
      trainingJobStatus === "AWAITING_WORKER")
  ) {
    return null;
  }

  if (selectedTab === "Evaluation" && evaluationJobStatus === "DONE") {
    return null;
  }

  if (selectedTab === "API" && (trainingJobStatus === "DONE" || isAutotunePipeline)) {
    return null;
  }

  if (selectedTab === "Notebook" && trainingJobStatus === "DONE") {
    return null;
  }

  return <PipelineStatus pipelineOutput={pipelineOutputs[0]} doRestartPolling={doRestartPolling} />;
};

export default displayPipelineStatusUntilContentReady;
