import { useEffect, useState } from "react";
import HubspotButton from "components/ui/HubspotButton";
import { getHubspotOauthToken, hubspotLogin } from "api/services/authenticationService";
import ResourceModal from "components/widgets/HubspotIntegration/ResourceModal";

const HubspotIntegration = ({ platformRedirectUrl, onImportResourceData }) => {
  const [hubspotDriveToken, setHubspotToken] = useState(null);
  const [resourceTypeModalVisible, setResourceTypeModalVisible] = useState(false);
  const [resourceType, setResourceType] = useState("contacts");

  const doGetHubspotToken = async () => {
    const { data, error } = await getHubspotOauthToken();
    if (error) {
      return;
    }
    setHubspotToken(data);
  };

  useEffect(() => {
    doGetHubspotToken();
  }, []);

  const handelBtnClick = async () => {
    if (hubspotDriveToken) {
      setResourceTypeModalVisible(true);
    } else {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { data: redirectUrl } = await hubspotLogin(`${baseUrl}/oauth-callback/hubspot`);
      sessionStorage.setItem("preOauthUrl", platformRedirectUrl);
      window.location = redirectUrl;
    }
  };

  return (
    <>
      <ResourceModal
        open={resourceTypeModalVisible}
        handleClose={() => setResourceTypeModalVisible(false)}
        resourceType={resourceType}
        onResourceTypeSelect={resourceType => {
          setResourceType(resourceType);
        }}
        onConfirm={() => {
          onImportResourceData(resourceType);
          setResourceTypeModalVisible(false);
        }}
      />
      <HubspotButton isAuthorised={hubspotDriveToken} onClick={handelBtnClick} />
    </>
  );
};

export default HubspotIntegration;
