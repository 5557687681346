import { round } from "lodash";
import { useState, useEffect } from "react";

const useGraduallyFillArray = (arr, hasBeenScanned) => {
  const sliceSize = round(arr?.length / 40);

  const [outputArr, setOutputArr] = useState([]);
  const [indOfLatestItem, setIndOfLatestItem] = useState(sliceSize);

  useEffect(() => {
    setOutputArr([]);
    setIndOfLatestItem(sliceSize);
  }, [arr]);

  useEffect(() => {
    if (indOfLatestItem >= arr?.length + sliceSize) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setOutputArr([...outputArr, ...arr.slice(indOfLatestItem - sliceSize, indOfLatestItem)]);
      setIndOfLatestItem(indOfLatestItem + sliceSize);
    }, 15);

    return () => clearTimeout(timeoutId);
  }, [indOfLatestItem]);

  if (!arr) {
    return [];
  }

  return hasBeenScanned ? arr : outputArr;
};

export default useGraduallyFillArray;
