import styled from "styled-components";

import Modal from "components/ui/Modal";
import ModelView from "components/views/ModelView";

const Container = styled.div`
  width: 800px;
  height: 600px;
  background-color: ${props => props.theme.color.closer0};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const ModelInspectionModal = ({
  modelId,
  onClose,
  open,
  modelName,
  datasetFeatureTypeDescriptors,
  inputCols,
  outputCols,
}) => {
  return (
    <Modal open={open} handleClose={() => onClose()} title={modelName}>
      <Container>
        <ModelView
          modelId={modelId}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
          inputCols={inputCols}
          outputCols={outputCols}
        />
      </Container>
    </Modal>
  );
};

export default ModelInspectionModal;
