import { useState, useEffect } from "react";
import styled from "styled-components";

import { getManagementConfigOfLoggedInUser, putManagementConfigOfLoggedInUser } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { SmallTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import SelectInput from "components/ui/SelectInput";
import Button from "components/ui/Button";

const InputsGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
`;

const GreyBox = styled.div`
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const SettingTitle = styled(SmallTitle)`
  font-weight: normal;
`;

const SettingsPage = () => {
  const [managementConfig, setManagementConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    doPopulateManagementConfig();
  }, []);

  const doPopulateManagementConfig = async () => {
    const { data, error } = await getManagementConfigOfLoggedInUser();
    setManagementConfig(data);
    setError(error);
    setIsLoading(false);
  };

  const doSaveConfig = async () => {
    setIsLoading(true);
    const { data, error } = await putManagementConfigOfLoggedInUser(managementConfig);
    setManagementConfig(data);
    setError(error);
    setIsLoading(false);
  };

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout isDisabled={isLoading}>
      <GreyBox>
        <SettingTitle>Training management</SettingTitle>
        <InputsGroup>
          <SelectInput
            title="Default worker type"
            value={managementConfig?.trainingManagement?.defaultWorkerType}
            onSetNewValue={newValue => {
              setManagementConfig({
                ...managementConfig,
                trainingManagement: {
                  ...managementConfig.trainingManagement,
                  defaultWorkerType: newValue,
                },
              });
            }}
          >
            <option value="CPU_SMALL">CPU_SMALL</option>
            <option value="CPU_MEDIUM">CPU_MEDIUM</option>
            <option value="CPU_LARGE">CPU_LARGE</option>
            <option value="GPU">GPU</option>
          </SelectInput>
        </InputsGroup>
        <SettingTitle>Deployment management</SettingTitle>
        <InputsGroup>
          <TextInput
            title="Max Instances Per Cube"
            value={managementConfig?.deploymentManagement?.maxInstances}
            onNewInput={newValue => {
              setManagementConfig({
                ...managementConfig,
                deploymentManagement: {
                  ...managementConfig.deploymentManagement,
                  maxInstances: newValue,
                },
              });
            }}
          />
          <TextInput
            title="Timeout minutes"
            value={managementConfig?.deploymentManagement?.timeoutMinutes}
            onNewInput={newValue => {
              setManagementConfig({
                ...managementConfig,
                deploymentManagement: {
                  ...managementConfig.deploymentManagement,
                  timeoutMinutes: newValue,
                },
              });
            }}
          />
          <TextInput
            title="Global max instances"
            value={managementConfig?.deploymentManagement?.globalMaxInstances}
            onNewInput={newValue => {
              setManagementConfig({
                ...managementConfig,
                deploymentManagement: {
                  ...managementConfig.deploymentManagement,
                  globalMaxInstances: newValue,
                },
              });
            }}
          />
        </InputsGroup>
        <SettingTitle>Jupyter management</SettingTitle>
        <InputsGroup>
          <TextInput
            title="Max Instances Per Cube"
            value={managementConfig?.jupyterManagement?.maxInstances}
            onNewInput={newValue => {
              setManagementConfig({
                ...managementConfig,
                jupyterManagement: {
                  ...managementConfig.jupyterManagement,
                  maxInstances: newValue,
                },
              });
            }}
          />
          <TextInput
            title="Timeout minutes"
            value={managementConfig?.jupyterManagement?.timeoutMinutes}
            onNewInput={newValue => {
              setManagementConfig({
                ...managementConfig,
                jupyterManagement: {
                  ...managementConfig.jupyterManagement,
                  timeoutMinutes: newValue,
                },
              });
            }}
          />
          <TextInput
            title="Global max instances"
            value={managementConfig?.jupyterManagement?.globalMaxInstances}
            onNewInput={newValue => {
              setManagementConfig({
                ...managementConfig,
                jupyterManagement: {
                  ...managementConfig.jupyterManagement,
                  globalMaxInstances: newValue,
                },
              });
            }}
          />
        </InputsGroup>
        <Gap />
        <Button value="Save" onClick={doSaveConfig} />
      </GreyBox>
    </CenteredWithTopNavLayout>
  );
};

export default SettingsPage;
