import styled from "styled-components";
import { Link } from "react-router-dom";

import GitHubIcon from "@material-ui/icons/GitHub";
import { CubeIcon, DatasetIcon, DeploymentIcon, ModelIcon, TrainingIcon } from "components/ui/Icons";
import { Gap } from "components/Layout";
import UserBadge from "components/ui/UserBadge";
import ForkCubeButton from "components/widgets/ForkCubeButton";
import useCreatedByAvatarSrc from "api/services/projectService/useCreatedByAvatarSrc";
import useLatestPipelineOutputByCubeId from "api/services/projectService/useLatestPipelineOutputByCubeId";
import usePollDeploymentClusterNonStop from "components/views/DeploymentTabViewNoTasks/usePollDeploymentClusterNonStop";
import Tooltip from "components/ui/Tooltip";

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const Icons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SubTitle = styled.div`
  font-size: 11px;
  color: ${props => props.theme.color.closest};
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const CardContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 6px;
  border: 1px solid ${props => props.theme.color.closer1};
  background: linear-gradient(
    90deg,
    ${props => props.theme.color.closer0} 0%,
    ${props => props.theme.color.closer0} 100%
  );

  :hover {
    background: none;
    background-color: ${props => props.theme.color.closer1};
    ${Title} {
      color: ${props => props.theme.color.primary};
    }

    ${CardLink} {
      color: ${props => props.theme.color.primary};
    }
  }

  .MuiCircularProgress-root {
    color: ${props => props.theme.color.primary};
  }
`;

const IconAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const Indicator = styled.div`
  display: flex;
  gap: 5px;
  font-size: 11px;
  align-items: center;
  svg {
    fill: ${props => props.theme.color.closer2};
  }
  :hover svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const IndicatorConatiner = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  gap: 15px;
`;

const GithubCubeCard = ({ cube, showAuthorBadge = true }) => {
  const createdByAvatarSrc = useCreatedByAvatarSrc(cube?.createdBy);

  const latestPipelineOutput = useLatestPipelineOutputByCubeId(cube?.id);
  const trainingJobStatus = latestPipelineOutput?.trainingJob?.status;

  const [deploymentCluster] = usePollDeploymentClusterNonStop([latestPipelineOutput?.trainingJob?.id]);
  let deploymentStatus = "REGSITERED";
  if (deploymentCluster?.instanceCount > 0 && !deploymentCluster?.readyToUse) {
    deploymentStatus = "IN_PROGRESS";
  }
  if (deploymentCluster?.readyToUse) {
    deploymentStatus = "DONE";
  }

  return (
    <CardContainer>
      <div>
        <Title>
          <CardLink to={`/cube/${cube?.id}`}>{cube?.name}</CardLink>
        </Title>
        <SubTitle>Created at {cube?.createdAt}</SubTitle>
        {!showAuthorBadge && <Gap height="10px" />}
        {showAuthorBadge && <UserBadge userName={cube?.createdBy} userAvatar={createdByAvatarSrc} />}
        <IndicatorConatiner>
          <Indicator>
            <Link to={`/cube/${cube?.id}?tab=Dataset`}>
              <Tooltip title="Dataset">
                <DatasetIcon />
              </Tooltip>
            </Link>
          </Indicator>
          <Indicator>
            <Link to={`/cube/${cube?.id}?tab=Model`}>
              <Tooltip title="Model">
                <ModelIcon />
              </Tooltip>
            </Link>
          </Indicator>
          <Indicator>
            <Link to={`/cube/${cube?.id}?tab=Training`}>
              <Tooltip title="Training">
                <TrainingIcon status={trainingJobStatus} />
              </Tooltip>
            </Link>
          </Indicator>
          <Indicator>
            <Link to={`/cube/${cube?.id}?tab=Deployment`}>
              <Tooltip title="API">
                <DeploymentIcon status={deploymentStatus} />
              </Tooltip>
            </Link>
          </Indicator>
        </IndicatorConatiner>
      </div>
      <IconAndButton>
        <Icons>
          <GitHubIcon fontSize="medium" />
          <CubeIcon height="25px" />
        </Icons>
        {!showAuthorBadge && <Gap height="10px" />}
        {cube?.id && <ForkCubeButton cubeId={cube?.id} />}
      </IconAndButton>
    </CardContainer>
  );
};

export default GithubCubeCard;
