import { getLatestPipelineOutputFromCubeId } from "api/services/projectService";
import { useState, useEffect } from "react";

const useLatestPipelineOutputByCubeId = cubeId => {
  const [pipelineOutput, setPipelineOutput] = useState(null);

  useEffect(() => {
    doPopulateLatestPipelineOutput();
    const intervalId = setInterval(doPopulateLatestPipelineOutput, 2000);
    return () => clearInterval(intervalId);
  }, [cubeId]);

  const doPopulateLatestPipelineOutput = async () => {
    const { data } = await getLatestPipelineOutputFromCubeId(cubeId);
    setPipelineOutput(data);
  };

  return pipelineOutput;
};

export default useLatestPipelineOutputByCubeId;
