import { useState } from "react";
import styled from "styled-components";
import Button from "components/ui/Button";

import { getImageInpainting } from "api/services/deploymentService";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";
import ImageTrueSize from "components/widgets/ImageTrueSize";

const InpaintButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const RecoveredImgLoading = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${props => props.theme.color.closer0};
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const ImageInpainting = ({
  deploymentClusterId,
  inputImgSrc,
  isSelected,
  onSelect,
  trainingJobId,
  modelConfig,
  datasetFeatureTypeDescriptors,
}) => {
  const [inpaintingResult, setInpaintingResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doImageInpaint = async e => {
    e.stopPropagation();
    setIsFetchingPrediction(true);
    const { data, error } = await getImageInpainting(deploymentClusterId, inputImgSrc, "inpaint_image");
    setInpaintingResult(data);
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} readOnly />
      <TitleText>Image inpainting</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{
          image: inputImgSrc,
        }}
        outputCols={["image"]}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        isEnabled={isSelected}
        trainingJobId={trainingJobId}
        tag="inpaint_image"
        modelConfig={modelConfig}
      />
    </LabelAndModals>
  );

  if (!inpaintingResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <InpaintButton onClick={doImageInpaint} value="Recover" />
          <RecoveredImgLoading />
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <InpaintButton value="Recover" onClick={doImageInpaint} />
        <ImageTrueSize base64Data={inpaintingResult} />
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default ImageInpainting;
