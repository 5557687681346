import ReactFlow, { Controls } from "react-flow-renderer";
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getReactFlowElementsFromModelConfig } from "components/widgets/ModelViewerCustomTrainingPipeline/react-flow-utils";
import {
  DataNode,
  DataNodeEditable,
} from "components/widgets/ModelViewerCustomTrainingPipeline/react-flow-components/DataNodes";
import OperationEdge from "components/widgets/ModelViewerCustomTrainingPipeline/react-flow-components/OperationEdge";
import OperationEdgeNonEditable from "components/widgets/ModelViewerCustomTrainingPipeline/react-flow-components/OperationEdgeNonEditable";

export const ReactFlowModelEditor = ({ reactFlowElements, onEdgeConnect, onLoad }) => (
  <ReactFlow
    maxZoom={100}
    elements={reactFlowElements}
    onConnect={onEdgeConnect}
    nodesConnectable
    onLoad={onLoad}
    snapToGrid={true}
    snapGrid={[10, 10]}
    nodeTypes={{ dataNode: DataNodeEditable }}
    edgeTypes={{ operationEdge: OperationEdge }}
  >
    <Controls />
  </ReactFlow>
);

const ReactFlowModelViewer = ({ reactFlowElements }) => (
  <ReactFlow
    maxZoom={100}
    elements={reactFlowElements}
    snapToGrid={true}
    snapGrid={[10, 10]}
    nodesConnectable={false}
    nodeTypes={{ dataNode: DataNode }}
    edgeTypes={{ operationEdge: OperationEdgeNonEditable }}
    onLoad={reactFlowInstance => setTimeout(() => reactFlowInstance.fitView(), 100)}
  >
    <Controls />
  </ReactFlow>
);

const Container = styled.div`
  height: 100%;
`;

const Legend = styled.div``;

const LegendRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;
`;

const InputRect = styled.div`
  width: 15px;
  height: 15px;
  background-color: ${props => props.theme.color.in_progress};
`;

const OuptputRect = styled(InputRect)`
  background-color: ${props => props.theme.color.primary};
`;

const ModelViewerCustomTrainingPipeline = ({ modelConfig, datasetFeatureTypeDescriptors, inputCols, outputCols }) => {
  const [reactFlowElements, setReactFlowElements] = useState([]);

  useEffect(() => {
    const newReactFlowElements = getReactFlowElementsFromModelConfig(
      modelConfig,
      datasetFeatureTypeDescriptors,
      inputCols,
      outputCols
    );
    setReactFlowElements(newReactFlowElements);
  }, [modelConfig]);

  return (
    <Container>
      {/* {inputCols && outputCols && outputCols?.length !== 0 && (
        <Legend>
          <LegendRow>
            <InputRect />
            inputs
          </LegendRow>
          <LegendRow>
            <OuptputRect />
            outputs
          </LegendRow>
        </Legend>
      )} */}
      <ReactFlowModelViewer reactFlowElements={reactFlowElements} />
    </Container>
  );
};

export default ModelViewerCustomTrainingPipeline;
