import { useEffect, useState } from "react";
import styled from "styled-components";

import { getAllVisibleCubes } from "api/services/projectService";
import { getLoggedInUserName } from "api/services/authenticationService";
import GithubCubeCard from "components/ui/GithubCubeCard";
import PageSizeSelect from "components/navigation/pagination/PageSizeSelect";
import FilterTag from "components/ui/FilterTag";
import { CubeCard } from "components/ui/CubeCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 10px;
  min-height: calc(100 * ${props => props.pageSize / 2}px + 10 * ${props => props.pageSize / 2 - 1}px);
`;

const isGithubCube = cube => cube.name?.includes("/");

const FilterTagsContainer = styled.div`
  padding-bottom: 20px;
`;

const FiltersAndPageSize = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const DEFAULT_PAGE_SIZE = 6;

const CubeListView = () => {
  const [cubes, setCubes] = useState([]);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilteringMyCubes, setIsFilteringMyCubes] = useState(false);
  const [nextOffset, setNextOffset] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const prevOffsets =
    searchParams
      .get("prevOffsetsStr")
      ?.split(",")
      ?.filter(s => !!s) || [];
  const offset = searchParams.get("offset");
  const pageSize = searchParams.get("pageSize") || DEFAULT_PAGE_SIZE;

  const doFetchCubes = async () => {
    setIsLoading(true);
    const { data, error } = await getAllVisibleCubes({
      pageSize,
      offset,
    });
    setCubes(data?.items);
    setNextOffset(data?.offset);
    setHasMore(data?.hasMore);
    setError(error);
    setIsLoading(false);
  };

  const sortedAndfilteredCubes = cubes.filter(cube => {
    const loggedInUserName = getLoggedInUserName();
    if (!isFilteringMyCubes) {
      return true;
    }
    return cube.createdBy === loggedInUserName;
  });

  useEffect(() => {
    doFetchCubes();
  }, [searchParams]);

  if (isLoading) {
    return "Loading cubes...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  const prevUrl = `?pageSize=${pageSize}&offset=${prevOffsets?.slice(-1)}&prevOffsetsStr=${[prevOffsets.slice(0, -1)]}`;
  const nextUrl = `?pageSize=${pageSize}&offset=${nextOffset}&prevOffsetsStr=${[...prevOffsets, offset]}`;

  return (
    <>
      <FiltersAndPageSize>
        <FilterTagsContainer>
          <FilterTag isActive={isFilteringMyCubes} onToggle={() => setIsFilteringMyCubes(!isFilteringMyCubes)}>
            Created by me
          </FilterTag>
        </FilterTagsContainer>
        <PageSizeSelect pageSize={pageSize} onSelectNewPageSize={newPageSize => navigate(`?pageSize=${newPageSize}`)} />
      </FiltersAndPageSize>
      <CardsContainer pageSize={pageSize}>
        {sortedAndfilteredCubes.map(cube => {
          if (isGithubCube(cube)) {
            return <GithubCubeCard key={cube.id} cube={cube} />;
          }
          return <CubeCard key={cube.id} cube={cube} />;
        })}
      </CardsContainer>
      <PrevNextPageButtons
        onClickPrev={() => navigate(prevUrl)}
        isPrevDisabled={!offset}
        onClickNext={() => navigate(nextUrl)}
        isNextDisabled={!hasMore}
      />
    </>
  );
};

export default CubeListView;
