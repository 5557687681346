import * as backend from "api/backend/dataServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { hiddenDatasetIds } from "App";

export const getDatasetById = async datasetId => {
  const { data, error } = await backend.getDatasetById(datasetId);
  return { data, error: getHandlingFromError(error) };
};

export const patchDataset = async (datasetId, fieldsToUpdate) => {
  const { data, error } = await backend.patchDataset(datasetId, fieldsToUpdate);
  return { data, error: getHandlingFromError(error) };
};

export const deleteDataset = async datasetId => {
  const { data, error } = await backend.deleteDataset(datasetId);
  return { data, error: getHandlingFromError(error) };
};

export const getDatasetPreviewByDatasetId = async (datasetId, number = 10) => {
  const { data, error } = await backend.getDatasetPreviewById(datasetId, number);
  return { data, error: getHandlingFromError(error) };
};

export const getRandomSampleFromDatasetPreview = async datasetId => {
  const { data, error } = await backend.getDatasetPreviewById(datasetId, 50);
  if (error) {
    return { data, error: getHandlingFromError(error) };
  }

  try {
    const randomRow = data[Math.floor(Math.random() * data.length)];
    return { data: randomRow, error: null };
  } catch {
    return { data: null, error: getHandlingFromError({ message: "Could not sample dataset preview" }) };
  }
};

export const uploadDataset = async (name, sharedWith, dataFile) => {
  const formDataBody = new FormData();
  formDataBody.append("file", dataFile);

  const { data, error } = await backend.uploadDataset({ name, sharedWith, dataFileName: "dataFile.bz" }, formDataBody);
  return { data, error: getHandlingFromError(error) };
};

export const ingestDataFromGoogleDriveFile = async (
  name,
  dataFormat,
  processingMethod,
  sharedWith,
  googleDriveFileId
) => {
  const { data, error } = await backend.ingestDataFromGoogleDriveFile(
    name,
    dataFormat,
    processingMethod,
    sharedWith,
    googleDriveFileId
  );
  return { data, error: getHandlingFromError(error) };
};

export const ingestDataFromHubspotContacts = async (name, processingMethod, sharedWith) => {
  const { data, error } = await backend.ingestDataFromHubspotContacts(name, processingMethod, sharedWith);
  return { data, error: getHandlingFromError(error) };
};

export const ingestDataFromSentenceLabelingTask = async (id, name) => {
  const { data, error } = await backend.ingestDataFromSentenceLabelingTask(id, name);
  return { data, error: getHandlingFromError(error) };
};

export const getDataIngestionJob = async jobId => {
  const { data, error } = await backend.getDataIngestionJob(jobId);
  return { data, error: getHandlingFromError(error) };
};

export const generateDatasetProcessedPreview = async (datasetId, processConfig, number = 10) => {
  const { data, error } = await backend.generateDatasetProcessedPreview(datasetId, processConfig, (number = 10));
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getDatasetsCreatedByUserName = async userName => {
  const { data, error } = await backend.getDatasetsCreatedByUserName(userName);
  const filteredDatasets = data.filter(dataset => !hiddenDatasetIds.includes(dataset.id));
  return { data: filteredDatasets, error: getHandlingFromError(error) };
};

export const getAllVisibleDatasets = async () => {
  const { data, error } = await backend.getAllVisibleDatasets();
  const filteredDatasets = data.filter(dataset => !hiddenDatasetIds.includes(dataset.id));
  return { data: filteredDatasets, error: getHandlingFromError(error) };
};

export const getDatasetInfoByDatasetId = async datasetId => {
  const { data, error } = await backend.getDatasetInfoById(datasetId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getDataInfoByDataName = async datasetName => {
  const { data, error } = await backend.getDatasetInfoByName(datasetName);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getTaskById = async taskId => {
  const { data, error } = await backend.getTaskById(taskId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getTasksByIds = async taskIds => {
  const tasks = await Promise.all(taskIds.map(taskId => backend.getTaskById(taskId)));
  return { data: tasks.map(e => e.data) };
};

export const addNewTask = async task => {
  const { data, error } = await backend.addNewTask(task);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const postDataProcessingJob = async body => {
  const { data, error } = await backend.postDataProcessingJob(body);
  return { data, error: getHandlingFromError(error) };
};

export const getDataProcessingJob = async jobId => {
  const { data, error } = await backend.getDataProcessingJob(jobId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const cloneDataset = async (datasetId, name) => {
  const { data, error } = await backend.cloneDataset(datasetId, name);
  return { data, error: getHandlingFromError(error) };
};

export const getDatasetsFindByKey = async (params = {}) => {
  const { data, error } = await backend.getDatasetsFindByKey(params);
  return { data, error: getHandlingFromError(error) };
};

export const postDataIngestionJobsExternalDb = async (params = {}, body = {}) => {
  const { data, error } = await backend.postDataIngestionJobsExternalDb(params, body);
  return { data, error: getHandlingFromError(error) };
};

export const postUploadToFlowDataset = async (params = {}, body = {}) => {
  const { data, error } = await backend.postUploadToFlowDataset(params, body);
  return { data, error: getHandlingFromError(error) };
};
