import { isEmpty } from "lodash";

export const isImageDataset = datasetInfo => {
  const filteredFilters = datasetInfo?.config?.featureTypeDescriptors.filter(
    descriptor => descriptor.type?.structure === "IMAGE"
  );
  return !isEmpty(filteredFilters);
};

export const isTabularDataset = datasetInfo => {
  const tabularFieldTypes = ["CATEGORICAL", "INTEGER", "FLOAT"];
  const filteredDescriptors = datasetInfo?.config?.featureTypeDescriptors.filter(
    descriptor =>
      tabularFieldTypes.includes(descriptor.type.typeName) ||
      (descriptor.type.typeName === "NDARRAY" &&
        (descriptor.type?.structure === "TEXT" || descriptor.type?.structure === "SEQUENCE"))
  );
  return filteredDescriptors?.length === datasetInfo?.config?.featureTypeDescriptors?.length;
};

export const isTextDataset = datasetInfo => {
  return (
    datasetInfo?.config?.featureTypeDescriptors?.length === 2 &&
    datasetInfo?.config?.featureTypeDescriptors?.find(desc => desc?.type?.structure === "TEXT")
  );
};
