import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import UserProfileTabsView from "components/views/UserProfileTabsView";
import GithubAccountIntegration from "components/widgets/GithubAccountIntegration";
import { getUserProfileByUserName, updateUserProfile } from "api/services/projectService";
import { doesUserNameExist } from "api/services/authenticationService";
import { useIsUserOnOwnPage } from "hooks/userState";
import { TutorialModalEditFirstName } from "components/widgets/TutorialOverlay";
import FileInput from "components/ui/FileInput";
import { getBase64FromImageFile } from "utils/common";
import { CancelIcon, EditIcon, SquareHeartIcon, TickIcon } from "components/ui/Icons";
import Button from "components/ui/Button";
import TextArea from "components/ui/TextArea";
import TextInput from "components/ui/TextInput";

const Panel = styled.div`
  width: 100%;
  padding: 20px;
  padding-bottom: 10px;
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  background-color: ${props => props.theme.color.closer0};

  column-gap: 50px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto;
  align-items: start;
`;

const PanelEditing = styled(Panel)`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none"};
  grid-template-columns: auto 1fr 400px;
`;

const AvatarFileInput = styled(FileInput)`
  position: absolute;
  top: 115px;
  left: 10px;
  padding: 5px;
  min-width: 0;
  min-height: 0;
  background-color: ${props => props.theme.color.primary};
  svg {
    fill: ${props => props.theme.color.furthest};
  }

  :hover {
    background-color: ${props => props.theme.color.furthest};
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const UserAvatarContainer = styled.div`
  position: relative;
`;

const UserAvatar = styled.img`
  height: 150px;
  width: 150px;
  margin-bottom: 20px;

  border: double 2px transparent;
  border-radius: 300px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, ${props => props.theme.color.primary}, ${props => props.theme.color.feature});
  background-origin: border-box;
  background-clip: content-box, border-box;

  object-fit: cover;
`;

const UserUserName = styled.div`
  width: max-content;
  padding: 5px;
  font-size: 14px;
  font-family: monospace;
  ${props =>
    props.theme.name === "light" &&
    `color: ${props.theme.color.furthest};
     background-color: ${props.theme.color.closest};
  `};

  ${props =>
    props.theme.name === "dark" &&
    `color: ${props.theme.color.closest};
     background-color: ${props.theme.color.closer1_5};
  `};

  width: 150px;
  text-align: center;
  grid-column: 1;
  grid-row: 2;
`;

const FavContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  fill: ${props => props.theme.color.primary};

  justify-self: center;
  grid-column: 1;
  grid-row: 3;
  padding-top: 10px;
`;

const NamePositionBioContainer = styled.div``;

const NamePositionBioInputs = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding-bottom: 25px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none"};
`;

const GithubIntegrationContainer = styled.div`
  grid-column: 2;
`;

const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 10px;
`;

const AttrTitle = styled.div`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
  line-height: 22px;
`;

const EditButtonContainer = styled.div`
  grid-row: 1;
  grid-column: 3;
  padding: 8px;
  border-radius: 20px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }

  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const EditButtons = styled.div`
  display: flex;
  gap: 10px;
  grid-column: 2;
  align-items: start;
`;

const TextInputFont16 = styled(TextInput)`
  font-size: 16px;
`;

const UserPage = () => {
  const { userName } = useParams();

  const [userProfile, setUserProfile] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const isUserOnOwnPage = useIsUserOnOwnPage();
  const [isEditing, setIsEditing] = useState(false);

  const doCheckIfUserNameExists = async () => {
    const { error } = await doesUserNameExist(userName);
    setError(error);
  };

  const doFetchProfileData = async () => {
    const { data, error } = await getUserProfileByUserName(userName);
    setError(error);
    setUserProfile(data);
    setIsLoading(false);
  };

  const doUpdateProfileData = async () => {
    setIsLoading(true);
    const { data, error } = await updateUserProfile(userProfile);
    setError(error);
    setUserProfile(data);
    setIsLoading(false);
    setIsEditing(false);
  };

  useEffect(() => {
    doCheckIfUserNameExists();
    doFetchProfileData();
  }, []);

  if (error?.message) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  if (error?.redirectUrl) {
    window.location = error?.redirectUrl;
    return null;
  }

  const userAvatarSrc = userProfile?.image.includes("/images/")
    ? userProfile?.image
    : `data:image/png;base64,${userProfile?.image}`;

  if (isEditing) {
    return (
      <CenteredWithTopNavLayout>
        <TutorialModalEditFirstName />
        <Gap />
        <PanelEditing isDisabled={isLoading}>
          <UserAvatarContainer>
            <UserAvatar src={userAvatarSrc} />
            <AvatarFileInput
              buttonText=""
              icon={<EditIcon />}
              onFileSelect={async newFile => {
                const base64String = await getBase64FromImageFile(newFile);
                const base64StringData = base64String.split(",")[1];
                setUserProfile({ ...userProfile, image: base64StringData });
              }}
              isShowingFileChosenText={false}
            />
          </UserAvatarContainer>
          <UserUserName>{userName}</UserUserName>
          <FavContainer>
            <SquareHeartIcon />
            243 fans
          </FavContainer>
          <NamePositionBioInputs>
            <TextInputFont16
              placeholder="First Name"
              value={userProfile?.firstName}
              onNewInput={firstName => setUserProfile({ ...userProfile, firstName })}
            />
            <TextInputFont16
              placeholder="Last Name"
              value={userProfile?.lastName}
              onNewInput={lastName => setUserProfile({ ...userProfile, lastName })}
            />
            <TextInputFont16
              placeholder="Email"
              value={userProfile?.email}
              onNewInput={email => setUserProfile({ ...userProfile, email })}
            />
            <TextInputFont16
              placeholder="Position"
              value={userProfile?.position}
              onNewInput={position => setUserProfile({ ...userProfile, position })}
            />
            <TextArea
              value={userProfile?.bio}
              onNewInput={bio => setUserProfile({ ...userProfile, bio })}
              placeholder="Bio"
            />
          </NamePositionBioInputs>
          <EditButtons>
            <Button
              variant="highlighted"
              icon={<TickIcon height="12px" />}
              onClick={doUpdateProfileData}
              value="Save"
            />
            <Button icon={<CancelIcon height="12px" />} onClick={() => setIsEditing(false)} value="Cancel" />
            <GithubIntegrationContainer>
              <GithubAccountIntegration />
            </GithubIntegrationContainer>
          </EditButtons>
        </PanelEditing>
        <Gap height="30px" />
        <UserProfileTabsView userName={userName} />
      </CenteredWithTopNavLayout>
    );
  }

  return (
    <CenteredWithTopNavLayout>
      <TutorialModalEditFirstName />
      <Gap />
      <Panel>
        <UserAvatar src={userAvatarSrc} />
        <UserUserName>{userName}</UserUserName>
        <FavContainer>
          <SquareHeartIcon />
          243 fans
        </FavContainer>
        <NamePositionBioContainer>
          {(userProfile?.firstName || userProfile?.lastName) && (
            <Name>{`${userProfile?.firstName || ""} ${userProfile?.lastName || ""}`}</Name>
          )}
          <AttrTitle>Position</AttrTitle>
          <div>{userProfile?.position || "-"}</div>
          <Gap height="10px" />
          <AttrTitle>Bio</AttrTitle>
          <div>{userProfile?.bio || "-"}</div>
        </NamePositionBioContainer>
        {isUserOnOwnPage && (
          <GithubIntegrationContainer>
            <GithubAccountIntegration />
          </GithubIntegrationContainer>
        )}
        {isUserOnOwnPage && (
          <EditButtonContainer onClick={() => setIsEditing(true)}>
            <EditIcon />
          </EditButtonContainer>
        )}
      </Panel>
      <Gap height="30px" />
      <UserProfileTabsView userName={userName} />
    </CenteredWithTopNavLayout>
  );
};

export default UserPage;
