import styled from "styled-components";
import { range } from "lodash";

const PaginationContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-top: 20px;
  justify-content: center;
`;

const PaginationItem = styled.div`
  ${props => props.isCurrentPage && "font-weight: bold;"};
  padding: 20px;
  border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const PaginationDots = styled.div`
  padding: 20px 0;
  border-radius: 5px;
`;

const WINDOW_LENGTH = 5;

const PaginationPagesButtons = ({ numPages, onClickPageNum, currentPageNum }) => {
  let windowStart = Math.max(1, currentPageNum - 2);
  if (windowStart > numPages - WINDOW_LENGTH) {
    windowStart = Math.max(1, numPages - WINDOW_LENGTH + 1);
  }
  let windowEnd = Math.min(numPages, windowStart + WINDOW_LENGTH - 1);

  return (
    <PaginationContainer>
      {currentPageNum > 3 && <PaginationDots>...</PaginationDots>}
      {range(windowStart, windowEnd + 1).map(pageNum => (
        <PaginationItem
          key={pageNum}
          onClick={() => onClickPageNum(pageNum)}
          isCurrentPage={currentPageNum === pageNum}
        >
          {pageNum}
        </PaginationItem>
      ))}
      {currentPageNum < numPages - 2 && <PaginationDots>...</PaginationDots>}
    </PaginationContainer>
  );
};

export default PaginationPagesButtons;
