import { useState, useEffect } from "react";
import GoGamePlay from "components/widgets/GoGame/GoGamePlay";
import SelectInput from "components/ui/SelectInput";
import styled from "styled-components";
import Button from "components/ui/Button";
import { isEmpty } from "lodash";
import {
  createDeploymentCluster,
  shutDownADeploymentCluster,
  turnOnADeploymentCluster,
  getDeploymentClustersOfLoggedInUser,
} from "api/services/deploymentService";
import { SignalCellularNullSharp } from "@material-ui/icons";

const GenerationSelectContainer = styled.div`
  height: 250px;
  width: 200px;
  dispaly: inline-flex;
  justify-content: space_evently;
  flex-wrap: wrap;
`;

const Container = styled.div`
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.5;"}
`;

const GoGameInteraction = ({ trainingGenerationJobMap }) => {
  const [generations, setGenerations] = useState([]);
  const [selectedGeneration, setSelectedGeneration] = useState(0);
  const [playerServiceUrlObj, setPlayerServiceUrlObj] = useState(null);

  useEffect(() => {
    trainingGenerationJobMap && setGenerations(Object.keys(trainingGenerationJobMap));
  }, [trainingGenerationJobMap]);

  useEffect(() => {
    const urlObjectTemplate = {
      domain: "35.246.13.175",
      basePath: "v1/models",
      resourcePath: "player_model:predict",
    };

    const generationUrlOjbMapping = {
      0: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8501` },
      1: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8502` },
      2: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8503` },
      3: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8504` },
      4: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8505` },
      5: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8506` },
      6: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8507` },
      7: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8508` },
      8: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8509` },
      9: { ...urlObjectTemplate, domain: `${urlObjectTemplate.domain}:8510` },
    };

    setPlayerServiceUrlObj(generationUrlOjbMapping[selectedGeneration]);
  }, [selectedGeneration]);

  if (!trainingGenerationJobMap || isEmpty(trainingGenerationJobMap)) {
    return <div>No Generation is finished to interact with. </div>;
  }

  return (
    <>
      {generations && (
        <GenerationSelectContainer>
          <div>Select a generation: </div>
          <SelectInput
            value={selectedGeneration}
            onSetNewValue={newVal => setSelectedGeneration(newVal)}
            fontSize="18px"
          >
            {generations?.map(label => (
              <option value={label}>{label}</option>
            ))}
          </SelectInput>
        </GenerationSelectContainer>
      )}
      {/* <GoGamePlay playerServiceUrl={""} /> */}
      {playerServiceUrlObj && (
        <Container isEnabled={true}>
          <GoGamePlay playerServiceUrlObj={playerServiceUrlObj} />
        </Container>
      )}
    </>
  );
};

export default GoGameInteraction;
