import styled from "styled-components";
import { useState, useEffect } from "react";

import Button from "components/ui/Button";
import { getAccessToken, getLoggedInUserName } from "api/services/authenticationService";
import { NoSideNavWithTopNavLayout as NoSideNavNoTopNavLayout } from "components/Layout";
import { isLoggedInUserTokenValid } from "api/services/projectService";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import TextInput from "components/ui/TextInput";

const Form = styled.form`
  background-color: ${props => `${props.theme.color.furthest}DD`};
  display: grid;
  border: 2px solid ${props => props.theme.color.closer1_5};
  padding: 50px 150px;
  padding-top: 84px;
  width: 600px;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
`;

const ErrorMessage = styled.div`
  height: 14px;
  color: ${props => props.theme.color.error};
  text-align: center;
`;

const TunnelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const Logo = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
  width: 70px;
  padding-bottom: 100px;
`;

const TextInputFont16 = styled(TextInput)`
  font-size: 16px;
`;

const AnimatedTunnel = () => {
  useEffect(() => {
    const threeJsScript = document.createElement("script");
    threeJsScript.src = "/static/scripts/three.min.js";
    threeJsScript.async = true;
    document.body.appendChild(threeJsScript);

    threeJsScript.onload = () => {
      const tunnelScript = document.createElement("script");
      tunnelScript.src = "/static/scripts/tunnel.js";
      tunnelScript.async = true;
      document.body.appendChild(tunnelScript);

      tunnelScript.onload = () => {
        // eslint-disable-next-line no-undef
        tunnel.setup({
          element: document.getElementById("tunnel"),
        });
      };
    };
  }, []);

  return <TunnelContainer id="tunnel" />;
};

const LoginPage = () => {
  const [inputUserName, setInputUserName] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const doRedirectIfUserTokenValid = async () => {
    const isValid = await isLoggedInUserTokenValid();
    if (isValid) {
      const loggedInUserName = getLoggedInUserName();
      if (loggedInUserName) {
        window.location = `/${loggedInUserName}`;
      }
      return;
    }
    localStorage.removeItem("accessToken");
  };

  useEffect(() => {
    doRedirectIfUserTokenValid();
  }, []);

  const logInAndRedirect = async e => {
    setIsLoading(true);
    e.preventDefault();
    const { data, error } = await getAccessToken(inputUserName, password);
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    setLoginTokenInClientStorage(data?.accessToken);
    window.location = `/${inputUserName}`;
  };

  return (
    <NoSideNavNoTopNavLayout>
      <AnimatedTunnel />
      <Logo src="/images/logo-white.png" />
      <Form onSubmit={logInAndRedirect}>
        <TextInputFont16
          placeholder="Username"
          value={inputUserName}
          onNewInput={newUsername => setInputUserName(newUsername)}
          isRequired
        />
        <TextInputFont16
          placeholder="Password"
          value={password}
          type="password"
          onNewInput={newPassword => setPassword(newPassword)}
          isRequired
        />
        <Button variant="highlighted" type="submit" value={isLoading ? "Loading..." : "Login"} />
        <ErrorMessage>{error?.message}</ErrorMessage>
      </Form>
    </NoSideNavNoTopNavLayout>
  );
};

export default LoginPage;
