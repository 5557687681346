import { useState, useEffect } from "react";
import GoogleDriveButton from "components/ui/GoogleDriveButton";
import { getGoogleDriveOauthToken, googleDriveLogin, getGoogleDriveFiles } from "api/services/authenticationService";
import FileListModal from "components/widgets/GoogleDriveIntegration/FileListModal";

const GoogleDriveIntegration = ({ platformRedirectUrl, onFileSelect, selectedFileId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [googleDriveToken, setGoogleDriveToken] = useState(null);
  const [googleDriveFiles, setGoogleDriveFiles] = useState([]);
  const [fileListModalVisible, setFileListModalVisible] = useState(false);

  useEffect(() => {
    doGetGoogleDriveToken();
  }, []);

  const doGetGoogleDriveToken = async () => {
    const { data, error } = await getGoogleDriveOauthToken();
    if (error) {
      return;
    }
    setGoogleDriveToken(data);
  };

  const doGetGoogleDriveFiles = async () => {
    setIsLoading(true);
    const { data, error } = await getGoogleDriveFiles();
    if (error) {
      return;
    }
    const filteredFiles = data.filter(file => file.name.endsWith(".csv"));
    setIsLoading(false);
    setGoogleDriveFiles(filteredFiles);
  };

  const handelBtnClick = async () => {
    if (googleDriveToken) {
      setFileListModalVisible(true);
      await doGetGoogleDriveFiles();
    } else {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { data: redirectUrl } = await googleDriveLogin(`${baseUrl}/oauth-callback/googledrive`);
      sessionStorage.setItem("preOauthUrl", platformRedirectUrl);
      window.location = redirectUrl;
    }
  };

  return (
    <>
      <FileListModal
        isLoadingFiles={isLoading}
        open={fileListModalVisible}
        fileList={googleDriveFiles}
        onFileSelect={(fileId, fileName) => {
          setFileListModalVisible(false);
          onFileSelect(fileId, fileName);
        }}
        handleClose={() => setFileListModalVisible(false)}
      />
      <GoogleDriveButton isAuthorised={googleDriveToken} onClick={handelBtnClick} sideText="" />
    </>
  );
};

export default GoogleDriveIntegration;
