import { AssignmentTurnedInOutlined } from "@material-ui/icons";
import { getLoggedInUserName, getAdminUserGoogleCalendarAccessToken } from "api/services/authenticationService";
import { CenteredWithTopNavLayout, Gap, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import { useState, useEffect } from "react";
import styled from "styled-components";
import ChatbotInterface from "./ChatbotInterface";
import CommandInterpretationView from "./CommandInterpretationView";

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const ChatBotAndCode = styled.div`
  ${props => props.isDisabled && "opacity: 0.3;pointer-events: none"}
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Steps = ({ stepNames, onStepSelect, selectedStep, latestStep }) => {
  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const ChatBot = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const [userInput, setUserInput] = useState("");
  const [shouldRefreshCode, setShouldRefreshCode] = useState(true);
  const [adminUserGoogleCalendarToken, setAdminUserGoogleCalendarToken] = useState(null);

  const loggedInUserName = getLoggedInUserName();

  const doFetchAdminUserGoogleDriveToken = async () => {
    const { data, error } = await getAdminUserGoogleCalendarAccessToken();
    if (error) {
      return;
    }
    setAdminUserGoogleCalendarToken(data);
  };

  useEffect(() => {
    doFetchAdminUserGoogleDriveToken();
  }, []);

  return loggedInUserName ? (
    <CenteredWithTopNavLayout>
      <BigTitle>{"Calendar AI"}</BigTitle>
      <Gap />
      {/* <Steps
        stepNames={["Import data", "Train", "Deployment review"]}
        latestStep={"Train"}
        selectedStep={selectedStep}
        onStepSelect={newStep => setSelectedStep(newStep)}
      />
      <Gap /> */}
      {selectedStep === "Import data" && <ImportDataView />}
      {selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && (
        <>
          <ChatBotAndCode isDisabled={!adminUserGoogleCalendarToken}>
            <ChatbotInterface
              userInput={userInput}
              onSetUserInput={newInput => {
                setShouldRefreshCode(true);
                setUserInput(newInput);
              }}
              onSendBtn={() => {
                setShouldRefreshCode(false);
              }}
              adminUserGoogleCalendarToken={adminUserGoogleCalendarToken}
            />
            {adminUserGoogleCalendarToken && (
              <CommandInterpretationView
                userInput={userInput}
                shouldRefreshCode={shouldRefreshCode}
                adminGoogleCalendarToken={adminUserGoogleCalendarToken}
              />
            )}
          </ChatBotAndCode>
        </>
      )}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <BigTitle>{"Calendar AI"}</BigTitle>
      <Gap />
      <ChatBotAndCode isDisabled={!adminUserGoogleCalendarToken}>
        <ChatbotInterface
          userInput={userInput}
          onSetUserInput={newInput => {
            setShouldRefreshCode(true);
            setUserInput(newInput);
          }}
          onSendBtn={() => {
            setShouldRefreshCode(false);
          }}
          adminUserGoogleCalendarToken={adminUserGoogleCalendarToken}
        />
        {adminUserGoogleCalendarToken && (
          <CommandInterpretationView
            userInput={userInput}
            shouldRefreshCode={shouldRefreshCode}
            adminGoogleCalendarToken={adminUserGoogleCalendarToken}
          />
        )}
      </ChatBotAndCode>
    </UnLoggedInCenteredWithTopNavLayout>
  );
};
export default ChatBot;
