import styled from "styled-components";
import SelectInput from "components/ui/SelectInput";

const SizeSelect = styled(SelectInput)`
  width: max-content;
`;

const PageSizeSelect = ({ pageSize, onSelectNewPageSize }) => (
  <SizeSelect
    value={pageSize}
    title="Page size"
    onSetNewValue={newPageSize => onSelectNewPageSize(parseInt(newPageSize))}
  >
    <option value="6">6</option>
    <option value="12">12</option>
    <option value="24">24</option>
    <option value="48">48</option>
  </SizeSelect>
);

export default PageSizeSelect;
