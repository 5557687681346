import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import { CenteredWithTopNavLayout } from "components/Layout";
import CubeListView from "components/views/CubeListView";
import { getLoggedInUserName } from "api/services/authenticationService";
import { SmallVerticalGap } from "components/Layout";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import { getCubesFindByKey } from "api/services/projectService";
import { getDatasetsFindByKey } from "api/services/dataService";
import { CubeCard } from "components/ui/CubeCard";
import { DatasetCard } from "components/ui/cards";
import { FlowLogoInner } from "components/ui/Icons";

const Cards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

const PageTitle = styled.a`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
`;

const FlowPage = () => {
  const userName = getLoggedInUserName();
  const navigate = useNavigate();

  const [flowCube, setFlowCube] = useState([]);
  const [flowDataset, setFlowDataset] = useState([]);

  useEffect(() => {
    doPopulateCubesAndDatasets();
  }, []);

  const doPopulateCubesAndDatasets = async () => {
    const { data: cube } = await getCubesFindByKey({ key: "boltzflow" });
    const { data: dataset } = await getDatasetsFindByKey({ key: "boltzflow" });

    setFlowCube(cube);
    setFlowDataset(dataset);
  };

  if (!userName) {
    return <CenteredWithTopNavLayout>Please log in first to see the content.</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <SmallVerticalGap />
      <PageTitle href="https://boltzflow.com" target="_blank">
        <FlowLogoInner />
        Boltzflow
      </PageTitle>
      <SmallVerticalGap style={{ height: "20px" }} />
      <Cards>
        <DatasetCard
          assetType="dataset"
          title={flowDataset.name}
          subTitle={flowDataset.createdAt}
          createdByUserName={flowDataset.createdBy}
          datasetId={flowDataset.id}
        />
        <CubeCard cube={flowCube} />
      </Cards>
    </CenteredWithTopNavLayout>
  );
};

export default FlowPage;
