import { isEdge } from "react-flow-renderer";

const doDescendantsHaveEdgesToAncestors = (nodeId, allEdges, ancestors) => {
  const outgoingEdges = allEdges.filter(edge => edge.source === nodeId);

  if (outgoingEdges.length === 0) {
    return false;
  }

  if (outgoingEdges.find(edge => ancestors.includes(edge.target))) {
    return true;
  }

  return outgoingEdges
    .map(edge => doDescendantsHaveEdgesToAncestors(edge.target, allEdges, [nodeId, ...ancestors]))
    .some(doesChildCauseCycle => doesChildCauseCycle);
};

const willNewEdgeCreateCycle = (reactFlowElements, newEdgeElement) => {
  const allEdges = [...reactFlowElements.filter(el => isEdge(el)), newEdgeElement];

  return doDescendantsHaveEdgesToAncestors("0", allEdges, []);
};

export const isNewEdgeValid = (reactFlowElements, newEdgeElement) => {
  if (willNewEdgeCreateCycle(reactFlowElements, newEdgeElement)) {
    return { isValid: false, message: "Cycles not allowed" };
  }

  if (reactFlowElements.find(exisitngEdge => exisitngEdge.id === newEdgeElement.id)) {
    return { isValid: false, message: "No duplicate edges" };
  }

  return { isValid: true, message: "" };
};

export const getModelConfigFromReactFlowElements = reactFlowElements => {
  const latentDims = reactFlowElements.find(el => el?.id === "0")?.data?.dims;

  const layerConfig = reactFlowElements
    .filter(element => isEdge(element))
    .map(edge => {
      const sourceNode = reactFlowElements.find(element => element.id === edge.source);
      const targetNode = reactFlowElements.find(element => element.id === edge.target);

      const edgePropertiesNoFuncs = {};
      Object.keys(edge.data).forEach(edgeProperty => {
        if (typeof edge.data[edgeProperty] !== "function" && edgeProperty !== "direction") {
          edgePropertiesNoFuncs[edgeProperty] = edge.data[edgeProperty];
        }
      });

      return {
        ...edgePropertiesNoFuncs,
        resIndices: [parseInt(edge?.source), parseInt(edge?.target)],
        inputDims: sourceNode.data.dims,
        outputDims: targetNode.data.dims,
        type: edge.data?.type || "FC",
      };
    });

  return { layerConfig, latentDims };
};
