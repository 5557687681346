import styled from "styled-components";

const Tip = styled.div`
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(${props => props.translateY});
  width: max-content;
  ${props => {
    if (props.placement === "right") {
      return `
        left: auto;
        right: 0;
        top: 0;
        transform: translateX(100%) translateY(10%);
      `;
    }
    if (props.placement === "left") {
      return `
        left: 0;
        top: 0;
        transform: translateX(-100%) translateY(10%);
      `;
    }
    if (props.placement === "top") {
      return `
        top: 0;
        transform: translateX(-50%) translateY(-100%);
      `;
    }
  }}
  background-color: ${props => props.theme.color.furthest};
  z-index: 10;
  box-shadow: 0 8px 24px ${props => (props.theme.name === "light" ? "rgba(140, 149, 159, 0.2)" : "rgba(0, 0, 0, 0.4)")};
  opacity: 0;
  transition: opacity 0.2s;
  color: ${props => props.theme.color.closest};
  pointer-events: none;
`;

const TipContainer = styled.div`
  position: relative;
  :hover {
    ${Tip} {
      opacity: 1;
    }
  }
`;

const Tooltip = ({ children, title, placement = "bottom", translateY = "10%" }) => {
  return (
    <TipContainer>
      {children}
      <Tip placement={placement} translateY={translateY}>
        {title}
      </Tip>
    </TipContainer>
  );
};

export default Tooltip;
