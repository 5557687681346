import { useState } from "react";
import styled from "styled-components";
import Button from "components/ui/Button";

import { getTextRewriting } from "api/services/deploymentService";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";

const RewriteButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const RecoveredTxt = styled.div`
  padding: 10px;
  width: 400px;
  font-size: 18px;
  height: 100px;
  border-radius: 10px;
  overflow-y: auto;
  background-color: ${props => props.theme.color.closer0};
`;

const RecoveredTxtLoading = styled.div`
  width: 400px;
  height: 100px;
  background-color: ${props => props.theme.color.closer0};
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const getInputTextWithMissing = (inputTxtSrc, missingWordsIndices) => {
  if (!inputTxtSrc) {
    return inputTxtSrc;
  }
  const inputTextWithMissing = [...inputTxtSrc];
  missingWordsIndices.forEach(index => {
    inputTextWithMissing[index] = null;
  });
  return inputTextWithMissing;
};

const TextRewriting = ({
  deploymentClusterId,
  inputTxtSrc,
  isSelected,
  onSelect,
  missingWordsIndices,
  trainingJobId,
  datasetFeatureTypeDescriptors,
  modelConfig,
}) => {
  const [rewrittenResult, setRewrittenResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doTextRewriting = async e => {
    e.stopPropagation();
    setIsFetchingPrediction(true);

    const inputTextWithMissing = getInputTextWithMissing(inputTxtSrc, missingWordsIndices);
    const { data, error } = await getTextRewriting(deploymentClusterId, inputTextWithMissing, "rewrite_text");
    setRewrittenResult(data.filter(e => !(e === "<START>" || e === "<END>")).join(" "));
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} />
      <TitleText>Text rewriting</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{ sentence_text: getInputTextWithMissing(inputTxtSrc, missingWordsIndices) }}
        outputCols={["sentence_text"]}
        isEnabled={isSelected}
        trainingJobId={trainingJobId}
        tag="rewrite_text"
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
      />
    </LabelAndModals>
  );

  if (!rewrittenResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <RewriteButton onClick={doTextRewriting} value="Rewrite" />
          <RecoveredTxtLoading />
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <RewriteButton value="Rewrite" onClick={doTextRewriting} />
        <RecoveredTxt>{rewrittenResult}</RecoveredTxt>
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default TextRewriting;
