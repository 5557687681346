import styled from "styled-components";
import { useState, useEffect } from "react";
import { isEqual } from "lodash";

import { getSampleDataPointFromDeployment } from "api/services/deploymentService";
import ExpandableOnTitleClick from "components/widgets/ExpandableOnTitleClick";
import ImageDataEnrichmentView from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView";
import ImageContentMatchingView from "components/views/DeploymentTabViewNoTasks/ImageView/ImageContentMatchingView";
import InputImagePanel from "components/views/DeploymentTabViewNoTasks/ImageView/InputImagePanel";

const Container = styled.div``;

const TaskTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const enrichmentTasks = ["Label prediction", "Image inpainting", "Image generation", "Data synthesis"];
const tasksWhichCanBeSelectedTogether = ["Label prediction", "Image inpainting"];

const getSelectedTasks = (selectedTasks, clickedTask) => {
  if (tasksWhichCanBeSelectedTogether.includes(clickedTask)) {
    if (selectedTasks.includes("Label prediction") || selectedTasks.includes("Image inpainting")) {
      if (selectedTasks.includes("Label prediction") && selectedTasks.includes("Image inpainting")) {
        return selectedTasks.filter(task => task !== clickedTask);
      }
      return [...new Set([...selectedTasks, clickedTask])];
    }
    if (isEqual(selectedTasks, ["Label prediction"])) {
      if (clickedTask === "Image inpainting") {
        return tasksWhichCanBeSelectedTogether;
      }
    }
    if (isEqual(selectedTasks, ["Image inpainting"])) {
      if (clickedTask === "Label prediction") {
        return tasksWhichCanBeSelectedTogether;
      }
    }
  }
  return [clickedTask];
};

const ImageView = ({ pipelineOutput, deploymentClusterId, featureTypeDescriptors }) => {
  const [inputImgSrc, setInputImgSrc] = useState(null);
  const [inputLabel, setInputLabel] = useState(null);

  const [selectedTasks, setSelectedTasks] = useState([enrichmentTasks[0]]);
  const [error, setError] = useState(null);

  const [selectedTaskCategory, setSelectedTaskCategory] = useState("");

  const [isLabelVisible, setIsLabelVisible] = useState(true);

  const setTaskCategory = taskCategory => {
    if (selectedTaskCategory === taskCategory) {
      setSelectedTaskCategory("");
    } else {
      setSelectedTaskCategory(taskCategory);
    }
  };

  useEffect(() => {
    doFetchTestInput();
  }, []);

  const doFetchTestInput = async () => {
    setInputImgSrc(null);
    setInputLabel(null);
    const { data, error } = await getSampleDataPointFromDeployment(deploymentClusterId);
    if (error) {
      setError(error);
      return;
    }
    const { image, label } = data;
    setInputImgSrc(image);
    setInputLabel(label);
  };

  const onTaskSelect = selectedTask => {
    const newTasks = getSelectedTasks(selectedTasks, selectedTask);
    setSelectedTasks(newTasks);
  };

  if (error) {
    return JSON.stringify(error);
  }

  let isLabelInputDisabled = false;
  let isImgInputDisabled = false;
  let isImgEditable = false;
  if (selectedTasks.includes("Label prediction") || selectedTasks.includes("Image inpainting")) {
    isLabelInputDisabled = true;
    isImgEditable = true;
  }
  if (isEqual(selectedTasks, ["Image generation"])) {
    isImgInputDisabled = true;
  }
  if (isEqual(selectedTasks, ["Data synthesis"])) {
    isImgInputDisabled = true;
    isLabelInputDisabled = true;
  }
  if (selectedTaskCategory === "Content matching") {
    isImgInputDisabled = false;
    isLabelInputDisabled = false;
    isImgEditable = true;
  }
  if (selectedTaskCategory === "") {
    isImgInputDisabled = true;
    isLabelInputDisabled = true;
    isImgEditable = false;
  }

  return (
    <Container>
      <InputImagePanel
        inputBase64Data={inputImgSrc}
        inputLabel={inputLabel}
        onSetNewBase64Data={newImg => setInputImgSrc(newImg)}
        onSetNewLabel={newLabel => setInputLabel(newLabel)}
        isImgEditable={isImgEditable}
        isImgInputDisabled={isImgInputDisabled}
        isLabelInputDisabled={isLabelInputDisabled}
        areEyesShowing={selectedTaskCategory === "Content matching"}
        isLabelVisible={isLabelVisible}
        onSetIsLabelVisible={isVisible => setIsLabelVisible(isVisible)}
        deploymentClusterId={deploymentClusterId}
      />
      <TaskTypesContainer>
        <ExpandableOnTitleClick
          title="Data enrichment"
          isInitiallyExpanded={selectedTaskCategory === "Data enrichment"}
          onClick={() => setTaskCategory("Data enrichment")}
        >
          <ImageDataEnrichmentView
            inputImgSrc={inputImgSrc}
            inputLabel={inputLabel}
            selectedTasks={selectedTasks}
            onTaskSelect={onTaskSelect}
            deploymentClusterId={deploymentClusterId}
            trainingJobId={pipelineOutput.trainingJob?.id}
            modelConfig={pipelineOutput.model}
            featureTypeDescriptors={featureTypeDescriptors}
          />
        </ExpandableOnTitleClick>
        <ExpandableOnTitleClick
          title="Content matching"
          isInitiallyExpanded={selectedTaskCategory === "Content matching"}
          onClick={() => setTaskCategory("Content matching")}
        >
          <ImageContentMatchingView
            deploymentClusterId={deploymentClusterId}
            inputImgSrc={inputImgSrc}
            inputLabel={inputLabel}
            isLabelVisible={isLabelVisible}
          />
        </ExpandableOnTitleClick>
        {/* <ExpandableOnTitleClick
          title="Outlier detection"
          isInitiallyExpanded={selectedTaskCategory === "Outlier detection"}
          onClick={() => setTaskCategory("Outlier detection")}
        >
          <ImageOutlierDetectionView deploymentId={0} inputImgSrc={inputImgSrc} />
        </ExpandableOnTitleClick> */}
      </TaskTypesContainer>
    </Container>
  );
};

export default ImageView;
