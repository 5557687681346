import jwt_decode from "jwt-decode";

import * as backend from "api/backend/authenticationServiceEndpoints";
import { errorTypes, getHandlingFromError } from "api/error-handling";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

export const getLoggedInUserName = () => {
  try {
    const accessToken = getLoginTokenFromClientStorage();
    const { sub } = jwt_decode(accessToken);
    return sub;
  } catch {
    return "";
  }
};

export const isUserLoggedIn = () => {
  return !!getLoggedInUserName();
};

export const getAccessToken = async (username, password) => {
  const { data, error } = await backend.getLoginTokens(username, password);

  if (error) {
    const errorHandlingDetails = getHandlingFromError(error);
    return { data, error: errorHandlingDetails };
  }

  return { data, error };
};

export const doesUserNameExist = async userName => {
  let doesUserExist = false;

  const { data } = await backend.getAllUsers();
  if (data.find(user => user.userName === userName)) {
    doesUserExist = true;
  }

  if (!doesUserExist) {
    return {
      data: null,
      error: getHandlingFromError({ type: errorTypes.NOT_FOUND }),
    };
  }

  return { data: true, error: null };
};

export const registerUser = async userInfo => {
  const { data, error } = await backend.addUser(userInfo);

  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getAllUsers = async () => {
  const { data, error } = await backend.getAllUsers();
  return { data, error };
};

export const getGoogleDriveOauthToken = async () => {
  const { data, error } = await backend.getGoogleDriveOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const revokeGoogleDriveOauthToken = async () => {
  const { data, error } = await backend.revokeGoogleDriveOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const googleDriveLogin = async callbackUrl => {
  const { data, error } = await backend.googleDriveLogin(callbackUrl);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const googleDriveOauthCallBack = async code => {
  const { data, error } = await backend.googleDriveOauthCallBack(code);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getGoogleDriveFiles = async () => {
  const { data, error } = await backend.getGoogleDriveFiles();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getGoogleDriveFileContent = async fileId => {
  const { data, error } = await backend.getGoogleDriveFileContent(fileId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getHubspotOauthToken = async () => {
  const { data, error } = await backend.getHubspotOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const hubspotLogin = async callbackUrl => {
  const { data, error } = await backend.hubspotLogin(callbackUrl);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const hubspotOauthCallBack = async code => {
  const { data, error } = await backend.hubspotOauthCallBack(code);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const hubspotGetContacts = async count => {
  const { data, error } = await backend.hubspotGetContacts(count);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getSalesforceOauthToken = async () => {
  const { data, error } = await backend.getSalesforceOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const salesforceLogin = async callbackUrl => {
  const { data, error } = await backend.salesforceLogin(callbackUrl);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const salesforceOauthCallBack = async code => {
  const { data, error } = await backend.salesforceOauthCallBack(code);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getGmailOauthToken = async () => {
  const { data, error } = await backend.getGmailOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const gmailLogin = async callbackUrl => {
  const { data, error } = await backend.gmailLogin(callbackUrl);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const gmailOauthCallBack = async code => {
  const { data, error } = await backend.gmailOauthCallBack(code);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const sendEmail = async email => {
  const { data, error } = await backend.sendEmail(email);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getLinkedinOauthToken = async () => {
  const { data, error } = await backend.getLinkedinOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const linkedinLogin = async callbackUrl => {
  const { data, error } = await backend.linkedinLogin(callbackUrl);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const linkedinOauthCallBack = async code => {
  const { data, error } = await backend.linkedinOauthCallBack(code);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getLinkedinConnections = async (start, count) => {
  const { data, error } = await backend.getLinkedinConnections(start, count);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getLinkedinProfile = async () => {
  const { data, error } = await backend.getLinkedinProfile();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getGoogleCalendarOauthToken = async () => {
  const { data, error } = await backend.getGoogleCalendarOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const revokeGoogleCalendarOauthToken = async () => {
  const { data, error } = await backend.revokeGoogleCalendarOauthToken();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const googleCalendarLogin = async callBackUrl => {
  const { data, error } = await backend.googleCalendarLogin(callBackUrl);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const googleCalendarCallBack = async code => {
  const { data, error } = await backend.googleCalendarCallBack(code);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const addGoogleCalendaerUserRefereshToken = async refreshToken => {
  const { data, error } = await backend.addGoogleCalendaerUserRefereshToken(refreshToken);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const createGoogleCalendarEvent = async event => {
  const { data, error } = await backend.createGoogleCalendarEvent(event);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getAdminUserGoogleCalendarAccessToken = async () => {
  const { data, error } = await backend.getAdminUserGoogleCalendarAccessToken();
  return { data, error: getHandlingFromError(error) };
};

export const demoUserSendMeetingRequestEmail = async email => {
  const { data, error } = await backend.demoUserSendMeetingRequestEmail(email);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};
