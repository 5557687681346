import { useEffect, useState } from "react";

import DeploymentTabViewNoTasks from "components/views/DeploymentTabViewNoTasks";
import PipelineIdRadioSelector from "components/widgets/PipelineIdRadioSelector";

const MultiDeplymentTabView = ({ pipelineOutputs, pipelineIdsToCompare, cubeName }) => {
  const [selectedPipelineId, setSelectedPipelineId] = useState(null);

  useEffect(() => {
    setSelectedPipelineId(pipelineIdsToCompare[0]);
  }, [pipelineIdsToCompare]);

  const selectedPipelineOutput = pipelineOutputs.find(
    pipelineOutput => pipelineOutput.pipelineId === selectedPipelineId
  );

  if (!selectedPipelineOutput) {
    return "No selected pipeline output";
  }

  return (
    <>
      {pipelineOutputs.length > 1 && (
        <PipelineIdRadioSelector
          pipelineIds={pipelineOutputs.map(output => output.pipelineId)}
          selectedPipelineId={selectedPipelineId}
          onPipelineIdSelect={newPipelineId => setSelectedPipelineId(newPipelineId)}
        />
      )}
      {selectedPipelineId && <DeploymentTabViewNoTasks pipelineOutput={selectedPipelineOutput} cubeName={cubeName} />}
    </>
  );
};

export default MultiDeplymentTabView;
