import styled, { createGlobalStyle } from "styled-components";
import { getBezierPath, getEdgeCenter } from "react-flow-renderer";
import { lightTheme } from "App";
import { useState, useEffect, useRef } from "react";

const Path = styled.path`
  fill: none;
  stroke-width: 3;
  stroke: ${props => props.color || props.theme.color.closer1};
  ${props => !props.isVisible && "stroke-opacity: 0;"}
`;

const IconPath = styled.path`
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke: #3d3d3d;
`;

const edgeColor = "#999898";

const IconCircle = styled.circle`
  fill: ${props => props.fill || edgeColor};
`;

const ReluIcon = ({ cx = 0, cy = 0, scale = 0.7, onClick, layerType }) => (
  <g
    onClick={onClick}
    style={{ cursor: "pointer" }}
    transform={`translate(${cx - scale * 16}, ${cy - scale * 16}) scale(${scale})`}
  >
    <IconCircle cx="16.5" cy="16.5" r="16.5" fill={layerTypeToColor[layerType]} />
    <IconPath d="M5.97412 17.973H16.2155L25.085 9.10345" />
  </g>
);

const SigmoidIcon = ({ cx = 0, cy = 0, scale = 0.4, onClick, layerType }) => (
  <g transform={`translate(${cx - 29 * scale}, ${cy - 29 * scale}) scale(${scale})`}>
    <IconCircle onClick={onClick} cx="29" cy="29" r="29" fill={layerTypeToColor[layerType]} />
    <IconPath d="M8 36.8149C37.3313 39.8055 18.4181 17.1018 50 20.2396" />
  </g>
);

const TanhIcon = ({ cx = 0, cy = 0, scale = 0.4, onClick, layerType }) => (
  <g transform={`translate(${cx - 29 * scale}, ${cy - 29 * scale}) scale(${scale})`}>
    <IconCircle onClick={onClick} cx="29" cy="29" r="29" fill={layerTypeToColor[layerType]} />
    <IconPath d="M14.7085 46.0651C20.8088 24.8275 35.413 31.3814 43.4115 12.1187" />
  </g>
);

const ArrowHeadStyling = createGlobalStyle`
  #react-flow__arrow polyline {
    stroke: ${edgeColor};
  }
`;

const layerTypeToColor = {
  FC: edgeColor,
  TransposeConv2D: "#a6cae3",
};

const getPathData = ({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, direction = "forward" }) => {
  const edgePath =
    direction === "forward"
      ? getBezierPath({
          sourceX,
          sourceY,
          sourcePosition,
          targetX,
          targetY,
          targetPosition,
        })
      : getBezierPath({
          sourceX: targetX,
          sourceY: targetY,
          sourcePosition: targetPosition,
          targetX: sourceX,
          targetY: sourceY,
          targetPosition: sourcePosition,
        });

  return edgePath;
};

const getWhetherForwardOrBackwardShowing = (id, taskPath) => {
  if (!taskPath.length) {
    return [false, false];
  }

  const [edgeSourceId, edgeTargetId] = id.split("-");

  if (!taskPath.flat().includes(edgeSourceId)) {
    return [true, true];
  }

  let showForward = false,
    showBackward = false;
  taskPath.forEach(([sourceId, targetId]) => {
    if (edgeSourceId === sourceId && edgeTargetId === targetId) {
      showForward = true;
    }
    if (edgeSourceId === targetId && edgeTargetId === sourceId) {
      showBackward = true;
    }
  });

  return [showForward, showBackward];
};

const CIRCLE_SPEED = 2;
const CIRCLE_SIZE = 3;

const useCircleCoords = pathRef => {
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);

  const [maxLength, setMaxLength] = useState(0);

  const [distanceAlongPath, setDistanceAlongPath] = useState(0);

  useEffect(() => {
    const pathLength = pathRef.current?.getTotalLength();
    setMaxLength(pathLength);

    const intervalId = setInterval(() => {
      setDistanceAlongPath(prevDist => prevDist + CIRCLE_SPEED);
    }, 20);

    return () => clearInterval(intervalId);
  }, [pathRef.current]);

  useEffect(() => {
    if (distanceAlongPath >= maxLength) {
      setDistanceAlongPath(0);
    }

    const point = pathRef.current?.getPointAtLength(distanceAlongPath);
    setCenterX(point?.x);
    setCenterY(point?.y);
  }, [distanceAlongPath]);

  return [centerX, centerY];
};

const useBackwardCircleCoords = pathRef => {
  const [centerX, setCenterX] = useState(0);
  const [centerY, setCenterY] = useState(0);

  const [maxLength, setMaxLength] = useState(0);

  const [distanceAlongPath, setDistanceAlongPath] = useState(0);

  useEffect(() => {
    const pathLength = pathRef.current?.getTotalLength();
    setDistanceAlongPath(pathLength);
    setMaxLength(pathLength);

    const intervalId = setInterval(() => {
      setDistanceAlongPath(prevDist => prevDist - CIRCLE_SPEED);
    }, 20);

    return () => clearInterval(intervalId);
  }, [pathRef.current]);

  useEffect(() => {
    if (distanceAlongPath <= 0) {
      setDistanceAlongPath(maxLength);
    }

    const point = pathRef.current?.getPointAtLength(distanceAlongPath);
    setCenterX(point?.x);
    setCenterY(point?.y);
  }, [distanceAlongPath]);

  return [centerX, centerY];
};

const OperationEdgeNonEditable = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data: { type = "FC", activeFun, direction } = {},
}) => {
  const pathRef = useRef();
  const [circleX, circleY] = useCircleCoords(pathRef);
  const [circleBackX, circleBackY] = useBackwardCircleCoords(pathRef);

  const edgePath = getPathData({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    direction: "forward",
  });
  const edgePathBack = getPathData({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    direction: "backward",
  });
  const [centerX, centerY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  let activeFunIcon = null;
  if (activeFun === "RELU") {
    activeFunIcon = <ReluIcon cx={centerX} cy={centerY} onClick={() => {}} layerType={type} />;
  }
  if (activeFun === "SIGMOID") {
    activeFunIcon = <SigmoidIcon cx={centerX} cy={centerY} onClick={() => {}} layerType={type} />;
  }
  if (activeFun === "TANH") {
    activeFunIcon = <TanhIcon cx={centerX} cy={centerY} onClick={() => {}} layerType={type} />;
  }
  if (activeFun === "LINEAR") {
    activeFunIcon = <IconCircle cx={centerX} cy={centerY} r={11} onClick={() => {}} layerType={type} />;
  }

  const isDarkTheme = localStorage.getItem("theme") === "dark";

  return (
    <>
      <ArrowHeadStyling isDarkTheme={isDarkTheme} />
      <Path
        isVisible
        ref={pathRef}
        id={id}
        d={edgePath}
        // markerEnd="url(#react-flow__arrow)"
        color={layerTypeToColor[type]}
      />
      <Path
        isVisible
        id={id}
        d={edgePathBack}
        // markerEnd="url(#react-flow__arrow)"
        color={layerTypeToColor[type]}
      />
      {activeFunIcon}
      {/* {(direction === "forward" || direction === "twoway") && <circle cx={circleX} cy={circleY} r={CIRCLE_SIZE} fill={`${lightTheme.color.primary}`} />}
      {(direction === "backward" || direction === "twoway")  && <circle cx={circleBackX} cy={circleBackY} r={CIRCLE_SIZE} fill={`${lightTheme.color.in_progress}`} />} */}
    </>
  );
};

export default OperationEdgeNonEditable;
