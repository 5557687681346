import styled from "styled-components";
import { Switch } from "@material-ui/core";

const SwitchSelectedSpan = styled.span`
  color: ${props => props.theme.color.primary};
`;

const Container = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  .MuiSwitch-track {
    background-color: ${props => props.theme.color.closer2};
  }
`;

const TwoNameSwitch = ({ leftName = "", rightName = "", isOnLeft, onSwitch, isDisabled, dataTutorialId }) => (
  <Container isDisabled={isDisabled} data-tutorial-id={dataTutorialId}>
    {!isOnLeft ? <span>{leftName}</span> : <SwitchSelectedSpan>{leftName}</SwitchSelectedSpan>}
    <Switch color="default" checked={!isOnLeft} onClick={onSwitch} />
    {!isOnLeft ? <SwitchSelectedSpan>{rightName}</SwitchSelectedSpan> : <span>{rightName}</span>}
  </Container>
);

export default TwoNameSwitch;
