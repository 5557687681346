import EvaluationReport from "./EvaluationReport";
import Modal from "components/ui/Modal";
import styled from "styled-components";

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  display: flex;
  width: 700px;
  height: 400px;
`;

const EvalComparingModal = ({ evalJobs, open, handleClose }) => {
  return (
    <Modal handleClose={handleClose} open={open} title={"Evaluation Reulsts Comparison"}>
      <ModalContent>
        <EvaluationReport evaluationJobs={evalJobs}></EvaluationReport>
      </ModalContent>
    </Modal>
   
  )
}

export default EvalComparingModal;