import styled from "styled-components";
import { Close } from "@material-ui/icons";

const SpanCloseButtonContainer = styled.div`
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, ${props => props.theme.color.error} 20%, transparent 100%);
  cursor: pointer;
  width: 40px;
  height: calc(1em + 4px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const AnnoSpan = styled.span`
  overflow: hidden;
  position: relative;
  display: inline;
  background-color: ${props => props.bgColor};
  color: white;
  :hover {
    cursor: pointer;
    ${SpanCloseButtonContainer} {
      opacity: 1;
    }
  }
`;

const AnnotationSpan = ({ onClick, children, bgColor }) => {
  return (
    <AnnoSpan bgColor={bgColor} onClick={onClick}>
      {children}
      <SpanCloseButtonContainer>
        <Close />
      </SpanCloseButtonContainer>
    </AnnoSpan>
  );
};

export default AnnotationSpan;
