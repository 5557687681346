import styled from "styled-components";
import { useState, useEffect } from "react";
import { range } from "lodash";
import { Link } from "react-router-dom";

import Button from "components/ui/Button";
import {
  getDeployedTrainingJobIdsOfLoggedInUser,
  deployTrainingJobAndShutDownOthers,
} from "api/services/deploymentService";

import JobCard from "components/widgets/JobCard";
import EvalComparingModal from "./EvalComparingModal";
import EvalJobInspectionModal from "components/views/TrainingAutotuneView/EvalJobInspectionModal";
import TrainingJobInspectionModal from "components/views/TrainingAutotuneView/TrainingJobInspectionModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
`;

const Row = styled.div`
  display: flex;
  gap: 20px;
  padding-bottom: 20px;
  align-items: center;
`;

const InspectButton = styled(Button)`
  min-height: 0;
  min-width: 0;
  padding: 5px 10px;
`;

const CheckboxInput = styled.input`
  width: 80px;
  height: 20px;
  cursor: pointer;
`;

const DeployedText = styled.div`
  color: green;
  font-weight: bold;
`;

const HeaderRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const ColumnTitle = styled.div`
  width: 400px;
  font-size: 18px;
`;

const BottomButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const TrainingAutotuneView = ({ trainingJobs, evaluationJobs, onSuccessfulDeploy = () => {} }) => {
  const [selectedEvalJobs, setSelectedEvalJobs] = useState([]);
  const [deployedTrainingJobsIds, setDeployedTrainingJobsIds] = useState([]);
  const [trainInspectionModalShow, setTrainInpsectModalShow] = useState(false);
  const [evalInspectionModalShow, setEvalInpsectModalShow] = useState(false);

  const [isEvalComparingModalOpen, setIsEvalComparingModalOpen] = useState(false);
  const [evalJobToInspect, setEvalJobToInspect] = useState(null);
  const [trainingJobToInpsect, setTrainingJobToInspect] = useState(null);

  const [deploymentStatus, setDeploymentStatus] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateDeployedTrainingJobsIds();
  }, []);

  const doPopulateDeployedTrainingJobsIds = async () => {
    const { data, error } = await getDeployedTrainingJobIdsOfLoggedInUser();
    setDeployedTrainingJobsIds(data);
    setError(error);
  };

  const doDeploySelectedTrainingJob = async () => {
    setDeploymentStatus("Deploying...");
    const { data, error } = await deployTrainingJobAndShutDownOthers(selectedTrainingJobId, trainingJobs);
    if (error) {
      setError(error);
      return;
    }
    setDeploymentStatus("");
    setDeployedTrainingJobsIds([data.trainingJobId]);
    onSuccessfulDeploy();
  };

  const selectedTrainingJobId = trainingJobs.find(job => job.id === selectedEvalJobs[0]?.jobConfig?.trainingJobId)?.id;
  const selectedTrainingJob = trainingJobs.find(job => job.id === selectedEvalJobs[0]?.jobConfig?.trainingJobId);

  const areAllSelected = selectedEvalJobs.length === evaluationJobs.length;
  const bulkSelecttionButton = areAllSelected ? (
    <InspectButton value="Unselect all" onClick={() => setSelectedEvalJobs([])} />
  ) : (
    <InspectButton value="Select all" onClick={() => setSelectedEvalJobs(evaluationJobs)} />
  );

  return (
    <Container>
      <HeaderRow>
        {bulkSelecttionButton}
        <ColumnTitle>Training</ColumnTitle>
        <ColumnTitle>Evaluation</ColumnTitle>
      </HeaderRow>
      {range(0, trainingJobs.length).map(i => (
        <Row key={i}>
          <CheckboxInput
            type="checkbox"
            checked={selectedEvalJobs.find(evalJob => evalJob.id === evaluationJobs[i].id) || false}
            onChange={e => {
              const newSelectedJobs = e.target.checked
                ? [...selectedEvalJobs, evaluationJobs[i]]
                : selectedEvalJobs.filter(evalJob => evalJob.id !== evaluationJobs[i].id);
              setSelectedEvalJobs(newSelectedJobs);
            }}
          />
          <JobCard
            job={trainingJobs[i]}
            showIcon={false}
            inspectButton={
              <InspectButton
                value="Inspect"
                onClick={() => {
                  setTrainingJobToInspect(trainingJobs[i]);
                  setTrainInpsectModalShow(true);
                }}
              />
            }
          />
          <JobCard
            job={evaluationJobs[i]}
            showIcon={false}
            showProgress={false}
            inspectButton={
              <InspectButton
                value="Inspect"
                onClick={() => {
                  setEvalJobToInspect(evaluationJobs[i]);
                  setEvalInpsectModalShow(true);
                }}
              />
            }
          />

          {deployedTrainingJobsIds.includes(trainingJobs[i].id) && (
            <Link to="?tab=Deployment" reloadDocument>
              <DeployedText>deployed</DeployedText>
            </Link>
          )}
        </Row>
      ))}

      <BottomButtons>
        <Button
          value="Compare"
          isDisabled={selectedEvalJobs.length < 2}
          onClick={() => setIsEvalComparingModalOpen(true)}
        />
        <EvalComparingModal
          evalJobs={selectedEvalJobs}
          handleClose={() => setIsEvalComparingModalOpen(false)}
          open={isEvalComparingModalOpen}
        />
        <Button
          value="Deploy"
          isDisabled={
            selectedEvalJobs.length !== 1 ||
            deployedTrainingJobsIds.includes(selectedTrainingJobId) ||
            selectedTrainingJob?.status !== "DONE"
          }
          onClick={doDeploySelectedTrainingJob}
        />
      </BottomButtons>
      {deploymentStatus}

      <TrainingJobInspectionModal
        isOpen={trainInspectionModalShow}
        trainingJob={trainingJobToInpsect}
        onClose={() => setTrainInpsectModalShow(false)}
      />

      <EvalJobInspectionModal
        isOpen={evalInspectionModalShow}
        evalJob={evalJobToInspect}
        onClose={() => setEvalInpsectModalShow(false)}
      />
      {error && JSON.stringify(error)}
    </Container>
  );
};

export default TrainingAutotuneView;
