import { useState, useRef } from "react";
import styled from "styled-components";
import { Search } from "@material-ui/icons";
import NavHeader from "components/widgets/NavHeader";
const SearchContainer = styled.div`
  position: relative;
  width: 400px;
`;

const ModeButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 16px;
  cursor: pointer;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.primary};
`;

const SearchIcon = () => (
  <IconContainer>
    <Search fontSize="small" />
  </IconContainer>
);

const CenteredContentContainer = styled.div`
  margin-top: 380px;
  height: auto;
  padding: 10px 200px 0px 200px;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`;

const Input = styled.input`
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  width: 100%;
  background-color: transparent;
  border-bottom: none;
  color: ${props => props.theme.color.closest};
  border: 1px solid white;
  padding: 10px 10px 10px 35px;
  border-radius: 60px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
`;

const Banner = styled.div`
  position: fixed;
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ;
  background-image: linear-gradient(rgba(8, 8, 96, 0.3), rgba(5, 5, 5, 0.8), rgba(10, 10, 10, 0.5)),
    url("/images/market-place/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
`;

const WelcomeTitle = styled.div`
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  color: white !important;
  margin-bottom: 20px;
`;

const WelcomeSubTitle = styled.div`
  text-align: left;
  font-size: 14px;
  color: white !important;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  z-index: 10;
`;

const BannerLayout = ({ children }) => {
  const [query, setQuery] = useState("");
  const inputRef = useRef(null);
  return (
    <Container>
      <a href={"/market-place"}>
        <Logo src={"/images/logo-white.png"} />
      </a>
      <NavHeader hasStickySearchBar={false} bgColor={"transparent"} />
      <Banner>
        <WelcomeTitle>{"Welcome to Boltzhub Marketplace"}</WelcomeTitle>
        <WelcomeSubTitle>{"Foundation AI made easy."}</WelcomeSubTitle>
        <SearchContainer>
          <ModeButton>
            <SearchIcon />
          </ModeButton>
          <Input
            ref={inputRef}
            type="text"
            placeholder="Search"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />
        </SearchContainer>
      </Banner>
      <CenteredContentContainer>{children}</CenteredContentContainer>
    </Container>
  );
};

export default BannerLayout;
