import { useState } from "react";
import styled from "styled-components";
import { BigTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import FileInput from "components/ui/FileInput";
import Button from "components/ui/Button";
import { uploadPackage } from "api/services/packageService";
import { sleep } from "utils/common";

const FormContainer = styled.div`
  position: relative;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
`;

const FormFields = styled.fieldset`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
`;

const DataSourceInputGroup = styled.div`
  ${props => !props.isSelected && "opacity: 0.5;"}
  :hover {
    ${props => !props.isSelected && "cursor: pointer;"}
  }
  ${props =>
    props.isSelected &&
    `border: 1px solid ${props.theme.color.primary}; box-shadow: 0 8px 24px rgba(140, 149, 159, 0.1);`}
  flex-wrap: wrap;
  display: flex;
  gap: 10px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 10px;
  padding: 10px;
`;

const CreatePackageView = () => {
  const [isPackageBeingPrepared, setIsPackageBeingPrepared] = useState(false);
  const [dataFile, setDataFile] = useState(null);
  const [name, setName] = useState("");

  const doCreatePackage = async () => {
    setIsPackageBeingPrepared(true);
    uploadPackage(dataFile, name?.replaceAll(" ", "-"));
    await sleep(500);
    window.location = "/packages-dashboard";
  };

  return (
    <FormContainer>
      <BigTitle isDisabled={isPackageBeingPrepared}>Create Package</BigTitle>
      <TextInput
        title="Package"
        placeholder="my dataset"
        value={name}
        onNewInput={newName => setName(newName)}
        dataTutorialId="dataset-name-input"
        isDisabled={isPackageBeingPrepared}
        isRed={!name && dataFile}
      />
      <FormFields isDisabled={isPackageBeingPrepared}>
        At the moment, we only support PNG format images.
        <DataSourceInputGroup isSelected={true}>
          <FileInput
            isDisabled={false}
            fileName={dataFile?.name}
            onFileSelect={file => {
              setName(file.name.match(/.*(?=\.)/)?.[0]);
              setDataFile(file);
            }}
          />
        </DataSourceInputGroup>
      </FormFields>
      <Button
        dataTutorialId="create-dataset-button"
        value="Create Package"
        isDisabled={!dataFile || !name}
        onClick={doCreatePackage}
      />
    </FormContainer>
  );
};

export default CreatePackageView;
