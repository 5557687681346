import { useState, useEffect } from "react";
import styled from "styled-components";

import { getDatasetPreviewByDatasetId, getDatasetInfoByDatasetId } from "api/services/dataService";
import { SmallTitle } from "components/ui/Text";
import { CenterContent } from "components/Layout";
import EditColumnDetails from "components/views/DatasetPreviewWithEditing/EditColumnDetails";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const TableContainer = styled.div`
  overflow-x: scroll;
`;

const IdLabel = styled.div`
  color: ${props => props.theme.color.primary};
`;

const getBackgroundColor = props => {
  const backgrounds = [
    `linear-gradient(${props.theme.color.closer2}44, ${props.theme.color.closer2}44)`,
    `linear-gradient(${props.theme.color.in_progress}44, ${props.theme.color.in_progress}44)`,
    `repeating-linear-gradient(-45deg, ${props.theme.color.closest}30, ${props.theme.color.closest}30 10px, ${props.theme.color.closer2}10 10px, ${props.theme.color.closer2}10 20px)`,
  ];
  const backgroundConditions = [props.isSelected, props.isColumnTypeEdited, props.isColumnDropped];

  return backgrounds
    .map((background, index) => (backgroundConditions[index] ? background : ""))
    .filter(bground => !!bground)
    .join(", ");
};

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 20px 14px;
  border-bottom: 1px solid ${props => props.theme.color.darkGrey};
  cursor: pointer;
  :hover {
    background-color: ${props => !props.isSelected && props.theme.color.closer1};
  }
  background: ${getBackgroundColor};
`;

const Td = styled.td`
  color: ${props => (props.isNull ? props.theme.color.closer1 : props.theme.color.closest)};
  padding: 14px 14px;
  border-bottom: 1px solid ${props => props.theme.color.closest};
  cursor: pointer;
`;

const columnBlacklist = ["seasonality"];

const InputDatasetPreview = ({ datasetId, nonJoinOps, onSetNonJoinOps }) => {
  const [datasetInfo, setDatasetInfo] = useState(null);
  const [datasetPreview, setDatasetPreview] = useState(null);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [selectedColumnName, setSelectedColumnName] = useState(null);

  const doFetchDatasetPreview = async () => {
    const { data, error } = await getDatasetPreviewByDatasetId(datasetId);
    setError(error);
    setDatasetPreview(data);
    setIsLoading(false);
  };

  const doFetchDatasetInfo = async () => {
    const { data, error } = await getDatasetInfoByDatasetId(datasetId);
    setError(error);
    setDatasetInfo(data);

    const rawColumnNames = data?.config.featureTypeDescriptors.map(e => e.key);
    const columnNames = rawColumnNames.filter(e => !columnBlacklist.includes(e));
    setSelectedColumnName(columnNames[0]);
  };

  useEffect(() => {
    if (!datasetId) {
      return;
    }

    doFetchDatasetInfo();
    doFetchDatasetPreview();
  }, [datasetId]);

  if (!datasetId) {
    return <Container>You no longer have access to this dataset</Container>;
  }

  if (isLoading || !datasetInfo) {
    return <Container>Loading dataset...</Container>;
  }

  if (error?.redirectUrl) {
    window.location = error.redirectUrl;
    return null;
  }

  if (error?.message) {
    return `Error while fetching dataset: ${error.message}`;
  }

  const rawColumnNames = datasetInfo?.config.featureTypeDescriptors.map(e => e.key);
  const columnNames = rawColumnNames.filter(e => !columnBlacklist.includes(e));

  const selectedColumnType = datasetInfo?.config.featureTypeDescriptors?.find(
    descriptor => descriptor.key === selectedColumnName
  )?.type?.typeName;

  return (
    <Container>
      <EditColumnDetails
        columnName={selectedColumnName}
        columnOriginalType={selectedColumnType}
        nonJoinOps={nonJoinOps}
        onSetNonJoinOps={newOps => onSetNonJoinOps(newOps)}
      />
      <CenterContent>
        <SmallTitle>Dataset: {datasetInfo?.name}</SmallTitle>
      </CenterContent>
      <TableContainer data-tutorial-id="data-table">
        <table>
          <thead>
            <tr>
              {columnNames.map(columnName => {
                const isColumnId = nonJoinOps.find(op => op.name === "SetIndex")?.config?.groups?.includes(columnName);
                const isColumnDropped = nonJoinOps.find(op => op.name === "Drop")?.config?.groups?.includes(columnName);

                const originalColumnDataType = datasetInfo?.config.featureTypeDescriptors?.find(
                  descriptor => descriptor.key === columnName
                )?.type?.typeName;

                const columnDataTypeFromOps =
                  nonJoinOps.find(op => op.name === "SetType")?.config?.groups?.find(group => group.name === columnName)
                    ?.type || originalColumnDataType;

                return (
                  <Th
                    key={columnName}
                    isSelected={selectedColumnName === columnName}
                    onClick={() => setSelectedColumnName(columnName)}
                    isColumnDropped={isColumnDropped}
                    isColumnTypeEdited={columnDataTypeFromOps !== originalColumnDataType}
                  >
                    {isColumnId && <IdLabel>ID</IdLabel>}
                    {columnName}
                  </Th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {datasetPreview?.map((dataPoint, rowIndex) => (
              <tr key={rowIndex}>
                {columnNames.map((columnName, colIndex) => {
                  const val = Array.isArray(dataPoint[columnName])
                    ? dataPoint[columnName].join(" ")
                    : dataPoint[columnName];
                  return (
                    <Td isNull={val === null} key={`${rowIndex}-${val}-${colIndex}`}>
                      {val === null ? "null" : val}
                    </Td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  );
};

export default InputDatasetPreview;
