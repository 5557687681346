import { useEffect, useState } from "react";
import styled from "styled-components";

import { getImageAnnotationTasksWithDefault, postImageAnnotationTask } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import Button from "components/ui/Button";
import { Link, useNavigate } from "react-router-dom";
import Modal from "components/ui/Modal";
import FileInput from "components/ui/FileInput";
import TextInput from "components/ui/TextInput";
import { BigTitle } from "components/ui/Text";

const AnnotationTaskCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;

const AnnotationTaskTitle = styled.div`
  transition: color 0.2s;
  font-size: 18px;
  font-weight: bold;
`;

const AnnotationTaskCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.2s;
  background-color: ${props => props.theme.color.closer0};
  height: 70px;
  :hover {
    background-color: ${props => props.theme.color.closer1};
    ${AnnotationTaskTitle} {
      color: ${props => props.theme.color.primary};
    }
  }
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const ModalContent = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 600px;
  padding: 20px;
`;

const CreateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const UploadImagesModal = ({ isOpen, onClose }) => {
  const [imagesFile, setImagesFile] = useState(null);
  const [isCreatingTask, setIsCreatingTask] = useState(false);
  const [taskName, setTaskName] = useState("");

  const navigate = useNavigate();

  const doCreateTaskAndRedirect = async () => {
    setIsCreatingTask(true);
    const { data: newTask, error } = await postImageAnnotationTask(taskName, imagesFile);
    if (error) {
      alert(JSON.stringify(error));
      setIsCreatingTask(false);
      return;
    }
    navigate(`/image-annotation-task/${newTask.id}`);
  };

  return (
    <Modal open={isOpen} handleClose={onClose} title="Upload Images">
      <ModalContent isDisabled={isCreatingTask}>
        <div>Upload your images as a .tar file</div>
        <TextInput
          title="Dataset name"
          value={taskName}
          onNewInput={newName => setTaskName(newName)}
          placeholder="My image dataset"
        />
        <FileInput
          title="Images archive"
          fileName={imagesFile?.name}
          onFileSelect={newFile => setImagesFile(newFile)}
        />
        <CreateButtonContainer>
          <Button isDisabled={!imagesFile || !taskName} value="Create" onClick={doCreateTaskAndRedirect} />
        </CreateButtonContainer>
      </ModalContent>
    </Modal>
  );
};

const ImageAnnotationTaskListPage = () => {
  const [annotationTasks, setAnnotationTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);

  useEffect(() => {
    doPopulateAnnotationTasks();
  }, []);

  const doPopulateAnnotationTasks = async () => {
    const { data, error } = await getImageAnnotationTasksWithDefault();
    setAnnotationTasks(data);
    setError(error);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading tasks...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>Image annotation tasks</BigTitle>
      <Gap />
      <Button value="Add new" onClick={() => setIsCreateTaskModalOpen(true)} />
      <Gap />
      <AnnotationTaskCards>
        {annotationTasks.map(annotationTask => (
          <CardLink key={annotationTask.id} to={`/image-annotation-task/${annotationTask?.id}`}>
            <AnnotationTaskCardContainer>
              <AnnotationTaskTitle>{annotationTask?.name}</AnnotationTaskTitle>
            </AnnotationTaskCardContainer>
          </CardLink>
        ))}
      </AnnotationTaskCards>
      <UploadImagesModal isOpen={isCreateTaskModalOpen} onClose={() => setIsCreateTaskModalOpen(false)} />
    </CenteredWithTopNavLayout>
  );
};

export default ImageAnnotationTaskListPage;
