import { useEffect } from "react";
import styled from "styled-components";

import ProgressBar from "components/ui/ProgressBar";
import usePollIngestionJob from "components/widgets/CreateDataProgress/usePollIngestionJob";
import { getDatasetById, patchDataset } from "api/services/dataService";

const getInitialReadmeStringForDataset = async datasetId => {
  const { data: dataset } = await getDatasetById(datasetId);
  const readMeStr = `# ${dataset?.name}
  
Number of data points: <b>${dataset?.config?.totalDataPoints}</b>

columns:<br/>${dataset?.config?.featureTypeDescriptors
    .map(
      col =>
        `&nbsp;&nbsp;&nbsp;&nbsp;\`${col.key}\` type: <b>${
          col?.type?.typeName
        } </b><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${
          col?.type?.categories?.join("<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;") || ""
        }<br />`
    )
    .join("<br />")}


    `;

  return { data: readMeStr };
};

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const CreateDataProgress = ({
  ingestionJobId,
  onIngestionFinish,
  onIngestionError = () => {},
  datasetFieldsToPatchAfterCreation = {},
}) => {
  const [job, error] = usePollIngestionJob(ingestionJobId);

  useEffect(() => {
    doHandleIngestionFinish();
  }, [job?.status]);

  const doHandleIngestionFinish = async () => {
    if (job?.status === "DONE") {
      const { data: readMe } = await getInitialReadmeStringForDataset(job.outputDatasetId);
      await patchDataset(job.outputDatasetId, { ...datasetFieldsToPatchAfterCreation, readMe });
      onIngestionFinish(job.outputDatasetId);
    }
    if (job?.status === "DONE_FAILED") {
      onIngestionError();
    }
  };

  let progress = 0;
  let statusText = "Uploading...";
  if (ingestionJobId) {
    statusText = "Uploaded. Preparing...";
    progress = 80;
  }
  if (job?.status === "DONE") {
    statusText = "";
    progress = 100;
  }

  if (error) {
    return JSON.stringify(error);
  }
  return (
    <ProgressContainer>
      {statusText} {progress}%
      <ProgressBar currentValue={progress} maxValue={100} />
    </ProgressContainer>
  );
};

export default CreateDataProgress;
