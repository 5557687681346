import { AssignmentTurnedInOutlined } from "@material-ui/icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { CenteredWithTopNavLayout, Gap, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import GoGamePlay from "components/widgets/GoGame/GoGamePlay";
import { useState } from "react";
import styled from "styled-components";

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const StepCheckbox = styled.input``;

const Steps = ({ stepNames, onStepSelect, selectedStep, latestStep }) => {
  const latestStepIndex = stepNames.indexOf(latestStep);

  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
          {/* <StepCheckbox type="checkbox" checked={i <= latestStepIndex} onChange={() => {}} /> */}
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const GoGamePlayInterface = () => {
  const playerServiceUrlObj = {
    domain: "35.246.13.175:8510",
    basePath: "v1/models",
    resourcePath: "player_model:predict",
  };
  return <GoGamePlay playerServiceUrlObj={playerServiceUrlObj} />;
};

const GoGameBot = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const [latestStep, setLatestStep] = useState("Train");

  const loggedInUserName = getLoggedInUserName();

  return loggedInUserName ? (
    <CenteredWithTopNavLayout>
      <BigTitle>{"Go Game Bot"}</BigTitle>
      <Gap />
      {/* <Steps
        stepNames={["Import data", "Train", "Deployment review"]}
        latestStep={latestStep}
        selectedStep={selectedStep}
        onStepSelect={newStep => setSelectedStep(newStep)}
      />
      <Gap /> */}
      {selectedStep === "Import data" && <ImportDataView />}
      {selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && <GoGamePlayInterface />}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <GoGamePlayInterface />
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default GoGameBot;
