import styled from "styled-components";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import {
  getTextsForTextAnnotationTask,
  patchTextOfTextAnnotationTask,
  triggerDownloadOfTextAnnotationTaskData,
} from "api/services/projectService";
import Button from "components/ui/Button";
import TextLabellingArea from "components/views/TextLabellingArea";
import Carousel from "components/ui/Carousel";

const TextThumbnail = styled.div`
  position: relative;
  border-radius: 10px;
  width: calc(950px / 8);
  height: calc(950px / 8);
  background-color: ${props => props.theme.color.closer0};
  color: ${props => props.theme.color.closer2};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const NumAnnotations = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.furthest};
  border: 1px solid ${props => props.theme.color.closer1};
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 5px 10px;
`;

const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TextAnnotationTaskPage = () => {
  const { textAnnotationTaskId } = useParams();
  const [texts, setTexts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedTextId, setSelectedTextId] = useState(null);
  const [textIdToAnnotations, setTextIdToAnnotations] = useState({});

  useEffect(() => {
    doPopulateTextsForTask();
  }, [textAnnotationTaskId]);

  const doPopulateTextsForTask = async () => {
    const { data, error } = await getTextsForTextAnnotationTask(textAnnotationTaskId);
    setError(error);
    setTexts([...data, ...data, ...data]);
    const newTextIdToAnnotations = {};
    data.forEach(text => (newTextIdToAnnotations[text?.id] = text?.annotations || []));
    setTextIdToAnnotations(newTextIdToAnnotations);
    setSelectedTextId([...data, ...data, ...data]?.[0]?.id);

    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <ExportButtonContainer>
        <Button
          value="Export annotated data"
          onClick={() => triggerDownloadOfTextAnnotationTaskData(textAnnotationTaskId)}
        />
      </ExportButtonContainer>
      <Gap />
      <Carousel
        numVisibleSlides={7}
        slides={texts.map((text, i) => (
          <TextThumbnail key={`${text?.id}-${i}`} onClick={() => setSelectedTextId(text?.id)}>
            {textIdToAnnotations[text?.id] ? (
              <NumAnnotations>
                {textIdToAnnotations[text?.id].length} ann{textIdToAnnotations[text?.id].length > 1 && "s"}
              </NumAnnotations>
            ) : null}
            {text.text}
          </TextThumbnail>
        ))}
      />
      <TextLabellingArea
        isOpen={!!selectedTextId}
        onClose={() => setSelectedTextId(null)}
        annotations={textIdToAnnotations[selectedTextId]}
        onNewAnnotations={newAnnotations => {
          setTextIdToAnnotations({ ...textIdToAnnotations, [selectedTextId]: newAnnotations });
          patchTextOfTextAnnotationTask(textAnnotationTaskId, selectedTextId, { annotations: newAnnotations });
        }}
        text={texts?.find(text => text?.id === selectedTextId)?.text}
      />
    </CenteredWithTopNavLayout>
  );
};

export default TextAnnotationTaskPage;
