import { zapiGet, zapiPost } from "api/zapi";
import { apiGet } from "api/api-http-methods";

export const getModelById = async modelId => {
  const { data, error } = await zapiGet(`/model-service/api/v1/models/${modelId}`);
  return { data, error };
};

export const getModels = async params => {
  const { data, error } = await zapiGet(`/model-service/api/v1/models`, params);
  return { data, error };
};

export const registerRecommendedModels = async datasetId => {
  const { data, error } = await zapiPost(`/model-service/api/v1/datasets/${datasetId}/model-recommendations`);
  return { data, error };
};

export const saveModel = async modelConfig => {
  const { data, error } = await zapiPost(`/model-service/api/v1/models`, {}, modelConfig);
  return { data, error };
};

export const getModelCode = async (modelId, codeLibrary) => {
  const { data, error } = await apiGet(`/model-service/api/v1/models/code/${modelId}`, { codeLibrary });
  return { data: data.objectJSON, error };
};

export const postModelRecommendation = async partialModelConfig => {
  const { data, error } = await zapiPost(`/model-service/api/v1/model-recommendation`, {}, partialModelConfig);
  return { data, error };
};
