import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { useEffect, useState } from "react";

import ImageTrueSizeAnnotationEditor from "components/widgets/ImageTrueSizeAnnotationEditor";
import { getPackageById } from "api/services/packageService";
import Button from "components/ui/Button";
import { CenteredWithTopNavLayoutInternal } from "components/PackageLayout";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 60px auto 600px 100px;
  column-gap: 20px;
  row-gap: 10px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ImageTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const ImageTitleWithButtons = styled.div`
  font-weight: bold;
  font-size: 18px;
  display: grid;
  grid-template-columns: 1fr auto auto;
`;

const OcrImageAnnotated = styled(ImageTrueSizeAnnotationEditor)`
  grid-column: span 1;
  overflow: scroll;
  height: 600px;
`;

const OcrImageOriginal = styled(ImageTrueSizeAnnotationEditor)`
  grid-column: span 1;
  overflow: scroll;
  height: 600px;
`;

const BottomButtons = styled.div`
  align-items: end;
  grid-column: 2;
  justify-content: end;
  display: flex;
  gap: 10px;
`;

const PackageTitle = styled.div`
  grid-column: span 2;
  font-size: 18px;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const FilePage = () => {
  const { fileId } = useParams();
  const navigate = useNavigate();

  const [fileBase64Data, setFileBase64Data] = useState("");
  const [annotations, setAnnotations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [imgWidth, setImgWidth] = useState(2000);
  const [imgHeight, setImgHeight] = useState(2000);

  useEffect(() => {
    doPopulateFileDataAndAnnotations();
  }, [fileId]);

  const doPopulateFileDataAndAnnotations = async () => {
    const { data, error } = await getPackageById(fileId);
    setFileBase64Data(data.image);
    setAnnotations(data.annotations);
    setIsLoading(false);
  };

  return (
    <CenteredWithTopNavLayoutInternal>
      <Container isDisabled={isLoading}>
        <PackageTitle>
          Package: <BoldSpan>{fileId}</BoldSpan>
        </PackageTitle>
        <ImageTitle>Original</ImageTitle>
        <ImageTitleWithButtons>
          OCR
          <button
            onClick={() => {
              setImgWidth(imgWidth + 400);
              setImgHeight(imgHeight + 400);
            }}
          >
            +
          </button>
          <button
            onClick={() => {
              setImgWidth(imgWidth - 400);
              setImgHeight(imgHeight - 400);
            }}
          >
            -
          </button>
        </ImageTitleWithButtons>
        <OcrImageOriginal base64Data={fileBase64Data} width={imgWidth} height={imgHeight} noCentering />
        <OcrImageAnnotated
          base64Data={fileBase64Data}
          width={imgWidth}
          height={imgHeight}
          annotations={annotations}
          onNewAnnotations={newAnnotations => setAnnotations(newAnnotations)}
          noCentering
        />
        <BottomButtons>
          <Button value="Save" onClick={() => navigate(`/package/${fileId}`)} />
          {/* <Button value="Next" /> */}
        </BottomButtons>
      </Container>
    </CenteredWithTopNavLayoutInternal>
  );
};

export default FilePage;
