import { useState, useEffect } from "react";
import styled from "styled-components";
import { getEmails, updateEmails } from "api/services/market-place/calendar";
import {
  getUserProfileByUserName,
  updateUserProfile,
  saveUserPreferences,
  getUserPreferences,
} from "api/services/projectService";
import { isEqual } from "lodash";
import { Add, Delete } from "@material-ui/icons";
import Button from "components/ui/Button";
import SelectInput from "components/ui/SelectInput";
import TextInput from "components/ui/TextInput";

const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
`;

const HorizontalContent = styled.div`
  display: flex;
  gap: 5px;
`;

const AppTabInfoDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
  color: ${props => props.theme.color.closer3};
`;

const TimeSlotRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const TimeSlotInputContainer = styled.div`
  width: 100px;
`;

const EmailRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const EmailBlock = styled.div`
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const EmailInputContainer = styled.div`
  width: 300px;
`;

const CircleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};
  border-radius: 100%;
  border: 2px solid ${props => props.theme.color.primary};
  height: 35px;
  width: 35px;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  svg {
    font-size: 20px;
  }

  :hover {
    color: ${props => props.theme.color.closer2};
    border: 2px solid ${props => props.theme.color.closer2};
  }
`;

const Buttons = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const HorizonLayout = styled.div`
  display: flex;
  gap: 50px;
`;

const Block = styled.div`
  padding: 0px 25px 15px 0px;
  width: 400px;
  border-right: 1px solid ${props => props.theme.color.closer1};
`;

const PreferenceBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Setting = ({
  onEmailsUpdated,
  onEmailsUnchanged,
  onUserProfileUpdated,
  onUserProfileUnchanged,
  onPreferencesUpdated,
  onPreferencesUnchanged,
  isEnabled,
  userName,
  onUpdateProfile = () => {},
  fieldToFocus = "",
}) => {
  const [originalEmails, setOriginalEmails] = useState([]);
  const [registeredEmails, setRegiteredEmails] = useState([]);
  const [originalUserProfile, setOriginalUserProfile] = useState(null);
  const [localUserProfile, setLocalUserProfile] = useState(null);
  const [originalPreferences, setOriginalPreferences] = useState(null);
  const [localPreferences, setLocalPreferences] = useState(null);
  const [emailMessage, setEmailMessage] = useState("");
  const [userProfileMessage, setUserProfileMessage] = useState("");
  const [preferenceMessage, setPreferenceMessage] = useState("");

  useEffect(() => {
    if (userName) {
      doFetchUserProfile();
      doFetchPreferences();
    }
  }, [userName]);

  useEffect(() => {
    if (originalUserProfile) {
      doFetchRegisteredEmails();
    }
    handleUserProfileUpdate();
  }, [originalUserProfile]);

  useEffect(() => {
    setUserProfileMessage("");
    handleUserProfileUpdate();
  }, [localUserProfile]);

  useEffect(() => {
    setEmailMessage("");
    handleEmailsUpdate();
  }, [registeredEmails]);

  useEffect(() => {
    handleEmailsUpdate();
  }, [originalEmails]);

  useEffect(() => {
    setPreferenceMessage("");
    handlePreferenceUpdate();
  }, [localPreferences]);

  useEffect(() => {
    handlePreferenceUpdate();
  }, [originalPreferences]);

  const handleEmailsUpdate = () => {
    if (
      !isEqual(
        registeredEmails.filter(e => !(e === "")),
        originalEmails.filter(e => !(e === ""))
      )
    ) {
      onEmailsUpdated();
    } else {
      onEmailsUnchanged();
    }
  };

  const handleUserProfileUpdate = () => {
    if (!isEqual(originalUserProfile, localUserProfile)) {
      onUserProfileUpdated();
    } else {
      onUserProfileUnchanged();
    }
  };

  const handlePreferenceUpdate = () => {
    if (!isEqual(originalPreferences, localPreferences)) {
      onPreferencesUpdated();
    } else {
      onPreferencesUnchanged();
    }
  };

  const doFetchUserProfile = async () => {
    const { data, error } = await getUserProfileByUserName(userName);

    if (!error) {
      setOriginalUserProfile(data);
      setLocalUserProfile(data);
    }
  };

  const doFetchRegisteredEmails = async () => {
    const { data, error } = await getEmails();
    if (!error) {
      let filteredData = data?.filter(email => email !== originalUserProfile?.email);
      setRegiteredEmails(filteredData);
      setOriginalEmails(filteredData);
    }
  };

  const doFetchPreferences = async () => {
    const { data, error } = await getUserPreferences(userName);
    if (!error) {
      setOriginalPreferences(data);
      setLocalPreferences(data);
    }
  };

  const validEmail = email => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const doSaveProfile = async () => {
    const { error, data } = await updateUserProfile(localUserProfile);
    if (error) {
      setUserProfileMessage("Failed when updating user's profile");
    } else {
      onUpdateProfile(data);
      setUserProfileMessage("User's profile has been successfully updated.");
      setOriginalUserProfile(localUserProfile);
    }
  };

  const doSavePreferences = async () => {
    const { error } = await saveUserPreferences(userName, localPreferences);
    if (error) {
      setPreferenceMessage("Failed when updating user's preferences");
    } else {
      setPreferenceMessage("User's profile has been successfully preferences.");
      setOriginalPreferences(localPreferences);
    }
  };

  const doSaveRegisteredEmails = async () => {
    let filteredEmails = registeredEmails.filter(e => !(e === ""));
    let allEmailsValid = true;
    for (var i = 0; i < filteredEmails.length; i++) {
      if (!validEmail(filteredEmails[i])) {
        allEmailsValid = false;
        setEmailMessage(`Failed. The Email address "${filteredEmails[i]}" seems invalid.`);
        break;
      }
    }

    if (allEmailsValid) {
      const { error } = await updateEmails([originalUserProfile.email, ...filteredEmails]);
      if (error) {
        setEmailMessage("Failed when updating emails.");
      } else {
        setEmailMessage("Emails have been successfully updated.");
        setOriginalEmails(filteredEmails);
      }
    }
  };

  const addOneHour = inputHourStr => {
    let elems = inputHourStr.split(":");
    let hour = +elems[0];
    let minute = elems[1];
    hour += 1;
    return `${hour}:${minute}`;
  };

  return (
    <AppTabInfoDescription>
      {isEnabled ? (
        <HorizonLayout>
          <Block>
            <h2>Profile </h2>
            <UserDetails>
              <HorizontalContent>
                <TextInput
                  title="First name"
                  isFocussed={fieldToFocus === "firstName"}
                  isRed={!localUserProfile?.firstName}
                  value={localUserProfile?.firstName}
                  onNewInput={firstName => setLocalUserProfile({ ...localUserProfile, firstName })}
                />
                <TextInput
                  title="Last name"
                  isFocussed={fieldToFocus === "lastName"}
                  isRed={!localUserProfile?.lastName}
                  value={localUserProfile?.lastName}
                  onNewInput={lastName => setLocalUserProfile({ ...localUserProfile, lastName })}
                />
              </HorizontalContent>
              <TextInput
                title="Email"
                isFocussed={fieldToFocus === "email"}
                isRed={!localUserProfile?.email}
                value={localUserProfile?.email}
                onNewInput={email => setLocalUserProfile({ ...localUserProfile, email })}
              />
              <TextInput
                title="Company"
                isFocussed={fieldToFocus === "organisation"}
                isRed={!localUserProfile?.organisation}
                value={localUserProfile?.organisation}
                onNewInput={organisation => setLocalUserProfile({ ...localUserProfile, organisation })}
              />
              <TextInput
                title="Position"
                isFocussed={fieldToFocus === "position"}
                isRed={!localUserProfile?.position}
                value={localUserProfile?.position}
                onNewInput={position => setLocalUserProfile({ ...localUserProfile, position })}
              />
              <p>{userProfileMessage}</p>
              <Buttons>
                <Button
                  isDisabled={false}
                  value={"Save"}
                  onClick={() => {
                    doSaveProfile();
                  }}
                />
              </Buttons>
            </UserDetails>
          </Block>
          <Block>
            <PreferenceBlock>
              <h2>Preferences </h2>

              <SelectInput
                title="Default meeting length"
                value={localPreferences?.meetingLength / 60}
                onSetNewValue={meetingLength =>
                  setLocalPreferences({ ...localPreferences, meetingLength: meetingLength * 60 })
                }
              >
                <option value={15}>15 minutes</option>
                <option value={30}>30 minutes </option>
                <option value={45}>45 minutes</option>
                <option value={60}>60 minutes</option>
                <option value={90}>90 minutes</option>
              </SelectInput>
              <SelectInput
                title="Default timezone"
                value={localPreferences?.timezone}
                onSetNewValue={timezone => setLocalPreferences({ ...localPreferences, timezone })}
              >
                <option value="Europe/London">Europe/London</option>
                <option value="UTC">UTC</option>
                <option value="Europe/Berlin">Europe/Berlin</option>
                <option value="CET">CET</option>
              </SelectInput>

              <b>Preferred Meeting Time</b>

              {localPreferences?.timeSlots?.map((slot, idx) => {
                return (
                  <TimeSlotRow key={`timeslot-${idx}`}>
                    from
                    <TimeSlotInputContainer>
                      <SelectInput
                        value={slot.start}
                        onSetNewValue={newVal => {
                          let updatedTimeSlots = [...localPreferences?.timeSlots];
                          updatedTimeSlots.splice(idx, 1, {
                            ...slot,
                            start: newVal.trim().toLowerCase(),
                            end: addOneHour(newVal.trim().toLowerCase()),
                          });
                          setLocalPreferences({ ...localPreferences, timeSlots: updatedTimeSlots });
                        }}
                      >
                        {[
                          "09:00",
                          "10:00",
                          "11:00",
                          "12:00",
                          "13:00",
                          "14:00",
                          "15:00",
                          "16:00",
                          "17:00",
                          "18:00",
                          "19:00",
                          "20:00",
                        ].map(e => (
                          <option key={e} value={e}>
                            {e}
                          </option>
                        ))}
                      </SelectInput>
                    </TimeSlotInputContainer>
                    to
                    <TimeSlotInputContainer>
                      <SelectInput
                        value={slot.end}
                        onSetNewValue={newVal => {
                          let updatedTimeSlots = [...localPreferences?.timeSlots];
                          updatedTimeSlots.splice(idx, 1, { ...slot, end: newVal.trim().toLowerCase() });
                          setLocalPreferences({ ...localPreferences, timeSlots: updatedTimeSlots });
                        }}
                      >
                        {[
                          "09:00",
                          "10:00",
                          "11:00",
                          "12:00",
                          "13:00",
                          "14:00",
                          "15:00",
                          "16:00",
                          "17:00",
                          "18:00",
                          "19:00",
                          "20:00",
                        ]
                          .filter(e => e >= slot.start)
                          .map(e => (
                            <option key={e} value={e}>
                              {e}
                            </option>
                          ))}
                      </SelectInput>
                    </TimeSlotInputContainer>
                    <CircleButton
                      onClick={() => {
                        let updatedTimeSlots = [...localPreferences?.timeSlots];
                        updatedTimeSlots.splice(idx, 1);
                        setLocalPreferences({ ...localPreferences, timeSlots: updatedTimeSlots });
                      }}
                    >
                      <Delete />
                    </CircleButton>
                  </TimeSlotRow>
                );
              })}
              <CircleButton
                onClick={() => {
                  let updatedTimeSlots = localPreferences?.timeSlots
                    ? [...localPreferences?.timeSlots, { start: "09:00", end: "10:00" }]
                    : [{ start: "09:00", end: "10:00" }];
                  setLocalPreferences({ ...localPreferences, timeSlots: updatedTimeSlots });
                }}
              >
                <Add />
              </CircleButton>
              <p>{preferenceMessage}</p>
              <Buttons>
                <Button
                  isDisabled={false}
                  value={"Save"}
                  onClick={() => {
                    doSavePreferences();
                  }}
                />
              </Buttons>
            </PreferenceBlock>
          </Block>
        </HorizonLayout>
      ) : (
        <div>Please enable the Application first. Then you can access the Setting. </div>
      )}
    </AppTabInfoDescription>
  );
};

export default Setting;
