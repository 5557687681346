import { ArrowForwardIos } from "@material-ui/icons";
import { useEffect, useState } from "react";
import styled from "styled-components";

const CarouselContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-content: start;
  gap: 5px;
`;

const SlidesContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const CarouselButton = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  display: flex;
  border-radius: 50px;
  align-self: center;
  padding: 10px;
  cursor: pointer;
  width: max-content;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const CarouselButtonRight = styled(CarouselButton)`
  justify-self: end;
`;

const Carousel = ({ slides, onSlideSelect = () => {}, numVisibleSlides = 1 }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    onSlideSelect(currentSlide);
  }, [currentSlide]);

  return (
    <CarouselContainer>
      <CarouselButton
        isDisabled={currentSlide === 0}
        onClick={() => currentSlide > 0 && setCurrentSlide(currentSlide - 1)}
      >
        <div style={{ transform: "rotate(180deg)", transformOrigin: "center" }}>
          <ArrowForwardIos style={{ paddingLeft: "4px" }} />
        </div>
      </CarouselButton>
      <SlidesContainer>{slides.slice(currentSlide, currentSlide + numVisibleSlides)}</SlidesContainer>
      <CarouselButtonRight
        isDisabled={currentSlide === slides.length - 1}
        onClick={() => currentSlide < slides.length - 1 && setCurrentSlide(currentSlide + 1)}
      >
        <ArrowForwardIos style={{ paddingLeft: "2px", paddingRight: "2px" }} />
      </CarouselButtonRight>
    </CarouselContainer>
  );
};

export default Carousel;
