import { CenteredWithTopNavLayoutInternal } from "components/PackageLayout";
import { useParams } from "react-router-dom";

const RCPackagePage = () => {
  const { packageId } = useParams();

  return (
    <CenteredWithTopNavLayoutInternal>
      this is RC package page for package: {packageId}
    </CenteredWithTopNavLayoutInternal>
  );
};

export default RCPackagePage;
