import styled from "styled-components";
import { isEqual } from "lodash";
import { useState, useRef } from "react";

import SuggestionsPopover from "components/views/application/CustomerFeedbackAssistant/SuggestionsPopover";

const getCaretCoords = () => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  const rect = range.getClientRects()[0];
  return { x: rect?.x, y: window.scrollY + rect?.y };
};

const moveCaretToEndOfElement = contentEditableElement => {
  const range = document.createRange();
  range.selectNodeContents(contentEditableElement);
  range.collapse(false);
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
};

const TextInputArea = styled.div`
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-family: "Montserrat";
  font-size: 16px;
  width: 100%;
  background-color: ${props => props.theme.color.furthest};
  border-bottom: none;
  color: ${props => props.theme.color.closest};
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 10px;
  height: 200px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
`;

const TextEditorWithSuggestions = () => {
  const [editorText, setEditorText] = useState("");
  const [popoverCoords, setPopoverCoords] = useState({ x: 0, y: 0 });
  const textEditorRef = useRef();

  const showSuggestionsPopoverOnTab = e => {
    if (e.key !== "Tab" && e.key !== "ArrowDown" && e.key !== "ArrowUp") {
      setPopoverCoords({ x: 0, y: 0 });
      return;
    }

    e.preventDefault();
    setPopoverCoords(getCaretCoords());
  };

  const isPopoverVisible = !isEqual(popoverCoords, { x: 0, y: 0 });
  const { top, left } = textEditorRef?.current?.getBoundingClientRect() || { top: 0, left: 0 };

  return (
    <>
      {isPopoverVisible && (
        <SuggestionsPopover
          x={popoverCoords?.x || left + 10}
          y={popoverCoords?.y || top + 10}
          inputText={editorText}
          onPressEnterNewEditorText={newEditorText => {
            textEditorRef.current.innerText = newEditorText;
            moveCaretToEndOfElement(textEditorRef?.current);
            setEditorText(newEditorText);
          }}
        />
      )}
      <TextInputArea
        ref={textEditorRef}
        contentEditable
        onKeyDown={showSuggestionsPopoverOnTab}
        onInput={e => setEditorText(e.target.innerText)}
      />
    </>
  );
};

export default TextEditorWithSuggestions;
