import styled from "styled-components";
import { useState, useEffect } from "react";

import {
  getTextLabelEmbeddingVec,
  getTextNearestNeighbours,
  getDeploymentClusterById,
} from "api/services/deploymentService";
import EmbeddingGridView from "components/widgets/EmbeddingGridView";
import MultiOptionSelector from "components/ui/MultiOptionSelector";
import Button from "components/ui/Button";
import ProgressBar from "components/ui/ProgressBar";
import { parseWithOptions } from "date-fns/fp";
import { isEmpty } from "lodash";

const DisplayButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const Container = styled.div`
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.6;"}
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  width: 500px;
  white-space: nowrap;
`;

const EmbeddingHolder = styled.div`
  background-color: ${props => props.theme.color.closer1};
  width: 90%;
  height: 100px;
  border-radius: 10px;
  margin: 10px;
`;

const SearchResults = styled.div`
  width: 90%;
  margin: 20px;
  height: 400px;
  overflow-y: auto;
`;

const SearchResultContainer = styled.div`
  position: relative;
  border-top: 2px solid ${props => props.theme.color.furthest};
  width: 100%;
  height: 50px;
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  background-color: ${props => (props.isMatch ? props.theme.color.success : props.theme.color.error)};
  padding: 2px;
  border-radius: 2px;
  border: 1px solid ${props => props.theme.color.closer2};
`;

const MatchingText = styled.div`
  margin: 20px;
  width: 100%;
`;

const Word = styled.span`
  margin-left: 5px;
  font-size: 18px;
  color: ${props => (props.isMatch ? props.theme.color.primary : null)};
  border-radius: 5px;
`;

const TextContentMatchingView = ({ deploymentClusterId, inputTxtSrc, inputLabel, isLabelVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [embeddingVec, setEmbeddingVec] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [deploymentCluster, setDeploymentCluster] = useState(null);
  const [readinessProgress, setRedinessProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const SearchResult = ({ text, label, isLabelMatch }) => {
    return (
      <SearchResultContainer>
        <MatchingText>
          {text
            .filter(e => !(e === "<START>" || e === "<END>"))
            .map(word => (
              <Word isMatch={inputTxtSrc.includes(word)}>{word}</Word>
            ))}
        </MatchingText>
        <Label isMatch={isLabelMatch}>{label}</Label>
      </SearchResultContainer>
    );
  };

  useEffect(() => {
    deploymentClusterId && doStartPollingReadiness();
  }, [deploymentClusterId]);

  useEffect(() => {
    if (deploymentCluster) {
      setRedinessProgress(deploymentCluster.embeddingCacheBuildProgress);
      if (deploymentCluster.embeddingCacheBuildProgress === 1) {
        clearInterval(intervalId);
      }
    }
  }, [deploymentCluster]);

  useEffect(() => {
    setEmbeddingVec(null);
    setSearchResults([]);
  }, [inputTxtSrc, inputLabel, isLabelVisible]);

  const doStartPollingReadiness = () => {
    clearInterval(intervalId);
    doFetchDeploymentCluster();
    const fetchingIntervalId = setInterval(doFetchDeploymentCluster, 3000);
    setIntervalId(fetchingIntervalId);
  };

  const doFetchDeploymentCluster = async () => {
    const { data, error } = await getDeploymentClusterById(deploymentClusterId);
    setDeploymentCluster(data);
  };

  const doFetchEmbeddingOfData = async () => {
    setIsLoading(true);
    const { data, error } = await getTextLabelEmbeddingVec(
      deploymentClusterId,
      inputTxtSrc,
      isLabelVisible ? inputLabel : null
    );
    setIsLoading(false);
    setEmbeddingVec(data);
  };

  const doFetchNearestNeighbours = async () => {
    setIsLoading(true);
    const { data, error } = await getTextNearestNeighbours(
      deploymentClusterId,
      inputTxtSrc,
      isLabelVisible ? inputLabel : null,
      30
    );
    setIsLoading(false);
    setSearchResults(data);
  };

  const doEmbeddingAndFetchNearestNeighbours = () => {
    doFetchNearestNeighbours();
    doFetchEmbeddingOfData();
  };

  return (
    <Container isEnabled={!isLoading}>
      {embeddingVec && (
        <>
          Data Embedding: <EmbeddingGridView embeddingVec={embeddingVec} numSquaresRow={28} />
        </>
      )}
      <DisplayButton
        isDisabled={readinessProgress < 1}
        value={"Find Similar Data"}
        onClick={doEmbeddingAndFetchNearestNeighbours}
      />
      {/* <MultiOptionSelector options={["Search", "Visualisation"]} selectedOption={"Search"} /> */}
      <SearchResults>
        {readinessProgress < 1 ? (
          <ProgressContainer>
            <span> Preparing search in progress: {Math.ceil(readinessProgress * 100)} %</span>
            <ProgressBar currentValue={Math.ceil(readinessProgress * 100)} maxValue={100} />
          </ProgressContainer>
        ) : (
          !isEmpty(searchResults) &&
          inputTxtSrc &&
          inputLabel &&
          searchResults.map((result, i) => (
            <SearchResult
              key={i}
              text={result.sentence_text}
              label={result.sentence_label}
              isLabelMatch={result.sentence_label === inputLabel}
            />
          ))
        )}
      </SearchResults>
    </Container>
  );
};

export default TextContentMatchingView;
