import { useNavigate } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import Modal from "components/ui/Modal";
import Button from "components/ui/Button";
import { Gap } from "components/Layout";
import { postDataIngestionJobsExternalDb } from "api/services/dataService";
import { DatabaseIcon } from "components/ui/Icons";
import usePollJob from "api/services/jobService/usePollJob";

const ModalContent = styled.div`
  width: 280px;
  padding: 20px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  padding-bottom: 12px;
`;

const Select = styled.select`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const Input = styled.input`
  outline: none;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer1};
  font-family: "Montserrat";
  padding: 4px;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const SmallButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 2px 0px;
  margin: 0 auto;
`;

const IconContainer = styled.div`
  padding: 2px 16px;
  border: 2px solid ${props => props.theme.color.closer1};
  display: flex;
  align-items: center;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.primary};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }

  svg {
    fill: ${props => props.theme.color.closer2};
  }
`;

const StyledProgress = styled.progress`
  width: 100%;
  margin-top: 12px;

  @keyframes pulse-blue {
    0% {
      opacity: 0.3;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0.3;
    }
  }
`;

const ErrMsg = styled.div`
  margin-top: 12px;
  color: ${props => props.theme.color.error};
  text-align: center;
  line-height: 1.2;
`;

const DB_TYPES = [
  { name: "MONGO", label: "MongoDB" },
  { name: "MYSQL", label: "MySQL" },
];
const FIELDS = [
  {
    name: "username",
    type: "string",
    _meta: { dbTypes: ["MONGO", "MYSQL"], placeholder: "win-test", placeholderMongo: "test-user" },
  },
  {
    name: "password",
    type: "password",
    _meta: { dbTypes: ["MONGO", "MYSQL"], placeholder: "admin", placeholderMongo: "test-password" },
  },
  {
    name: "host",
    type: "string",
    _meta: { dbTypes: ["MONGO", "MYSQL"], placeholder: "35.189.120.201", placeholderMongo: "35.189.120.201" },
  },
  {
    name: "port",
    type: "number",
    _meta: { dbTypes: ["MONGO", "MYSQL"], placeholder: "3306", placeholderMongo: "27017" },
  },
  {
    name: "database",
    type: "string",
    _meta: { dbTypes: ["MONGO", "MYSQL"], placeholder: "cabbage", placeholderMongo: "test_db" },
  },

  { name: "collection", type: "string", _meta: { dbTypes: ["MONGO"], placeholderMongo: "country" } },
  { name: "table", type: "string", _meta: { dbTypes: ["MYSQL"], placeholder: "myTable" } },
];

const ERR_MSG = "Could not ingest from database. Please try again.";

const ConnectDatabaseModal = ({ datasetName = "" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dbTypeName, setDbTypeName] = useState(DB_TYPES[0]?.name);
  const [dbSource, setDbSource] = useState({
    username: "",
    password: "",
    host: "",
    port: "",
    database: "",
    collection: "",
    table: "",
  });
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [jobId, setJobId] = useState(null);
  const job = usePollJob(jobId);

  useEffect(() => {
    if (job?.progress === 100 && job?.status !== "FAILED") {
      setIsLoading(false);
      navigate(`/data/${job?.parameters?.outputDatasetId}`);
    }

    if (job?.status === "FAILED") {
      setIsLoading(false);
    }
  }, [job?.progress, job?.status]);

  const doCreateDataset = async () => {
    setIsLoading(true);

    const payloadDbSource = cloneDeep(dbSource);
    if (dbTypeName === "MONGO") {
      delete payloadDbSource.table;
    }
    if (dbTypeName === "MYSQL") {
      delete payloadDbSource.collection;
    }

    const body = {
      externalFormat: dbTypeName,
      config: {
        dbSource: payloadDbSource,
      },
      outputDataset: {
        name: datasetName || "my-dataset-from-db",
      },
    };
    const { data } = await postDataIngestionJobsExternalDb({}, body);
    setJobId(data?.id);
  };

  const fields = FIELDS.filter(field => field._meta.dbTypes.includes(dbTypeName));
  const areAllFieldsFilled = fields.every(field => dbSource[field.name]);

  return (
    <>
      <IconContainer onClick={() => setIsOpen(true)}>
        <DatabaseIcon height="18px" />
      </IconContainer>
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent isDisabled={isLoading}>
          <Select value={dbTypeName} onChange={e => setDbTypeName(e.target.value)}>
            {DB_TYPES.map(dbType => (
              <option key={dbType.name} value={dbType.name}>
                {dbType.label}
              </option>
            ))}
          </Select>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "4px",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            {fields.map(field => (
              <Fragment key={field.name}>
                <label>{field.name}</label>
                <Input
                  value={dbSource[field.name]}
                  onChange={e => setDbSource({ ...dbSource, [field.name]: e.target.value })}
                  type={field.type}
                  placeholder={dbTypeName === "MONGO" ? field._meta.placeholderMongo : field._meta.placeholder}
                  onKeyDown={e => {
                    const placeholder = dbTypeName === "MONGO" ? field._meta.placeholderMongo : field._meta.placeholder;
                    if (e.key === "Tab") {
                      setDbSource({ ...dbSource, [field.name]: placeholder });
                    }
                  }}
                />
              </Fragment>
            ))}
          </div>
          <Gap height="32px" />
          <SmallButton
            variant="highlighted"
            isDisabled={!areAllFieldsFilled}
            value="Create dataset"
            onClick={doCreateDataset}
          />
          {job?.status === "FAILED" ? (
            <ErrMsg>{ERR_MSG}</ErrMsg>
          ) : (
            <StyledProgress
              style={{
                opacity: job?.status === "RUNNING" ? 1 : 0,
                animation: job?.status === "RUNNING" ? "pulse-blue 1.5s infinite" : "none",
              }}
              value={job?.progress || 1}
              max="100"
            />
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConnectDatabaseModal;
