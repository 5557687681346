import styled from "styled-components";

const getValuesMatrixFromTablePreview = (rows, columnNames) => {
  if (!rows || rows?.length === 0) {
    return [[]];
  }
  let valuesMatrix = [columnNames, ...rows.map(row => columnNames.map(colName => row[colName]))];
  return valuesMatrix;
};

const getJToColNameMap = rows => {
  const m = {};
  Object.keys(rows?.[0]).forEach((colName, j) => {
    m[j] = colName;
  });
  return m;
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;

const Table = styled.div`
  display: grid;
  width: max-content;
  grid-template-columns: repeat(${props => props.numCols}, max-content);
  background-color: ${props => {
    if (props.isSelected) {
      return props.theme.color.closer1_5;
    }
    if (props.isHighlighted) {
      return props.theme.color.closer1;
    }
    return props.theme.color.closer0;
  }};
  row-gap: 5px;
  column-gap: 15px;
  padding: 5px;
`;

const Cell = styled.div`
  max-width: 150px;
  ${props => props.isSelected && "background-color: lightgreen"};
  ${props => props.isHighlighted && "background-color: lightblue"};
`;

const RowIdCell = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
  font-weight: bold;
`;

const HeaderCell = styled.div`
  font-weight: bold;
  cursor: pointer;
  ${props => props.isSelected && "background-color: lightgreen"};
  ${props => props.isHighlighted && "background-color: lightblue"};
`;

const TableTitles = styled.div`
  display: grid;
  width: max-content;
  gap: 10px;
  background-color: ${props => {
    if (props.isSelected) {
      return props.theme.color.closer1_5;
    }
    if (props.isHighlighted) {
      return props.theme.color.closer1;
    }
    return props.theme.color.closer0;
  }};
  padding: 5px;
  grid-template-columns: 1fr 1fr;
`;

const TablePreviewWithPlots = ({
  packageName,
  tableName,
  tableRows,
  columnNames,
  highlightedCol,
  onHighlightCol = () => {},
  selectedCol,
  onSelectCol = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {},
  isTableHighlighted,
  isTableSelected,
}) => {
  const valuesMatrix = getValuesMatrixFromTablePreview(tableRows, columnNames);
  const jToColName = getJToColNameMap(tableRows);

  return (
    <Container onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <TableTitles isHighlighted={isTableHighlighted} isSelected={isTableSelected}>
        <div>{packageName}</div>
        <div>{tableName}</div>
      </TableTitles>
      <Table numCols={valuesMatrix[0]?.length} isHighlighted={isTableHighlighted} isSelected={isTableSelected}>
        {valuesMatrix.map((row, i) =>
          row.map((val, j) => {
            if (i === 0 && j === 0) {
              return <Cell key="0-0" />;
            }

            if (j === 0) {
              return <RowIdCell key={`${i}-${j}`}>{val}</RowIdCell>;
            }

            const colName = jToColName[j];
            if (i === 0) {
              return (
                <HeaderCell
                  onMouseOver={() => onHighlightCol(colName)}
                  onMouseLeave={() => onHighlightCol(null)}
                  onClick={() => onSelectCol(colName)}
                  key={`${i}-${j}`}
                  isHighlighted={highlightedCol === colName && isTableHighlighted}
                  isSelected={selectedCol === colName && isTableSelected}
                >
                  {val}
                </HeaderCell>
              );
            }

            return (
              <Cell
                key={`${i}-${j}`}
                id={`${i}-${j}`}
                isHighlighted={highlightedCol === colName && isTableHighlighted}
                isSelected={selectedCol === colName && isTableSelected}
              >
                {val}
              </Cell>
            );
          })
        )}
      </Table>
    </Container>
  );
};

export default TablePreviewWithPlots;
