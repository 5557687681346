import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getOcrAnnotationTasks } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { BigTitle } from "components/ui/Text";

const AnnotationTaskCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
`;

const AnnotationTaskTitle = styled.div`
  transition: color 0.2s;
  font-size: 18px;
  font-weight: bold;
`;

const AnnotationTaskCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.2s;
  background-color: ${props => props.theme.color.closer0};
  height: 70px;
  :hover {
    background-color: ${props => props.theme.color.closer1};
    ${AnnotationTaskTitle} {
      color: ${props => props.theme.color.primary};
    }
  }
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const ImageLabellingTaskListPage = () => {
  const [annotationTasks, setAnnotationTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateAnnotationTasks();
  }, []);

  const doPopulateAnnotationTasks = async () => {
    const { data, error } = await getOcrAnnotationTasks();
    setAnnotationTasks(data?.slice(0, 5));
    setError(error);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading tasks...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>Image annotation tasks</BigTitle>
      <Gap />
      <AnnotationTaskCards>
        {annotationTasks.map(annotationTask => (
          <CardLink key={annotationTask.id} to={`/data/build-dataset/image-labelling-tasks/${annotationTask?.id}`}>
            <AnnotationTaskCardContainer>
              <AnnotationTaskTitle>{annotationTask?.name}</AnnotationTaskTitle>
            </AnnotationTaskCardContainer>
          </CardLink>
        ))}
      </AnnotationTaskCards>
    </CenteredWithTopNavLayout>
  );
};

export default ImageLabellingTaskListPage;
