import styled from "styled-components";
import { useState, useEffect } from "react";

import { BigTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import Button from "components/ui/Button";
import { Link } from "react-router-dom";
import { CenteredWithTopNavLayout } from "components/Layout";
import { registerUser } from "api/services/authenticationService"

const CenteredFormContent = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 50px 50px 50px 50px;
  border-radius: 14px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;
  

const LoginTitle = styled(BigTitle)`
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 50px;
`;

const ButtonContainer = styled.div`
  position: relative;
  padding-top: 40px;
`;

const Gap = styled.div`
  height: 10px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: 15px;
  color: ${props => props.theme.color.error};
  width: max-content;
  left: calc(50% - max-content);
  text-align: center;
`;

const Message = styled.div`
  margin: 10px;
  color: ${props => props.theme.color.primary};
`;

const RegistrationPage = () => {
  const [inputUserName, setInputUserName] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");


  useEffect(() => {
    
  }, []);

  const doRegister = async e => {    
    setIsLoading(true);
    const userInfo = {
      userName: inputUserName,
      email: "fakeEmail", 
      password: password,
      repeatedPassword: password,
    };

    const { data, error } = await registerUser(userInfo);
    if (error) {
      setError(error);
      return;
    }

    setMessage("Registration successful. ");
    
    
  };

  return (
    <CenteredWithTopNavLayout>
      <CenteredFormContent>
        <Form>
          <LoginTitle>Register</LoginTitle>
          {
            error && <Message>
              Something wrong. {error.message}
            </Message>
          }
          {message && 
            <Message>
              {message} Click <Link to={"/login"} > here </Link> to login. 
              
            </Message> }
          <TextInput
            title="Username"
            value={inputUserName}
            onNewInput={newUsername => setInputUserName(newUsername)}
            isRequired
          />
          <Gap />
          <TextInput
            title="Password"
            value={password}
            type="password"
            onNewInput={newPassword => setPassword(newPassword)}
            isRequired
          />
          <ButtonContainer>
            <ErrorMessage>{error?.message}</ErrorMessage>
            <Button 
              onClick={() => { inputUserName && password && doRegister();} }
              type="button" 
              value={isLoading ? "Loading..." : "Register"} />
          </ButtonContainer>
        </Form>
      </CenteredFormContent>
    </CenteredWithTopNavLayout>
  );
};

export default RegistrationPage;
