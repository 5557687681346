import { useState, useEffect } from "react";
import GmailButton from "components/ui/GmailButton";
import { getGmailOauthToken, gmailLogin, sendEmail } from "api/services/authenticationService";

const GmailIntegration = ({ platformRedirectUrl }) => {
  const [gmailToken, setGmailToken] = useState(null);
  const [emailSentMessage, setEmailSentMessage] = useState("");

  const doGetGmailToken = async () => {
    const { data, error } = await getGmailOauthToken();
    if (error) {
      return;
    }
    setGmailToken(data);
  };

  const doSendAnEmail = async () => {
    const { error } = await sendEmail({
      from: "me",
      to: ["hanchen.xiong@boltzbit.com"],
      subject: "test eamil",
      bodyText: "Test email content",
    });
    if (error) {
      console.log(error);
    } else {
      setEmailSentMessage("Congratulations, you just sent an email to Hanchen(hanchen.xiong@boltzbit.com");
    }
  };

  useEffect(() => {
    doGetGmailToken();
  }, []);

  const handelBtnClick = async () => {
    if (gmailToken) {
      doSendAnEmail();
    } else {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { data: redirectUrl } = await gmailLogin(`${baseUrl}/oauth-callback/gmail`);
      sessionStorage.setItem("preOauthUrl", platformRedirectUrl);
      window.location = redirectUrl;
    }
  };

  return (
    <>
      <GmailButton value={gmailToken ? "Send an email" : "Authorize integration"} onClick={handelBtnClick} />
      {emailSentMessage}
    </>
  );
};

export default GmailIntegration;
