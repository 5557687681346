import { useState, useEffect } from "react";
import Button from "components/ui/Button";
import {
  getGoogleCalendarOauthToken,
  googleCalendarLogin,
  revokeGoogleCalendarOauthToken,
} from "api/services/authenticationService";

const CalendarIntegrate = ({ platformRedirectUrl, onPostOatuh, onRevokePostOauth }) => {
  const [googleCalendarToken, setGoogleCalendarToken] = useState(null);

  const doGetGoogleCalendarToken = async () => {
    const { data, error } = await getGoogleCalendarOauthToken();
    if (error) {
      return;
    }
    setGoogleCalendarToken(data);
    onPostOatuh();
  };

  const doRevokeToken = async () => {
    await revokeGoogleCalendarOauthToken();
    setGoogleCalendarToken(null);
  };

  useEffect(() => {
    doGetGoogleCalendarToken();
  }, []);

  const doStartOauth = async () => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const { data: redirectUrl } = await googleCalendarLogin(`${baseUrl}/oauth-callback/googlecalendar`);
    sessionStorage.setItem("preOauthUrl", platformRedirectUrl);
    window.location = redirectUrl;
  };

  const handelBtnClick = async () => {
    if (googleCalendarToken) {
      doRevokeToken();
      onRevokePostOauth();
    } else {
      doStartOauth();
    }
  };

  return (
    <>
      <Button value={googleCalendarToken ? "Disable" : "Enable"} onClick={handelBtnClick} />
    </>
  );
};

export default CalendarIntegrate;
