import { useEffect, useState } from "react";

import TableFlat from "components/ui/TableFlat";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

import styled from "styled-components";
import { range } from "lodash";

const DATA1_POINTS = [
  {
    name: "",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const DATA2_POINTS = [
  {
    name: "",
    uv: 2000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 1000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "",
    uv: 7800,
    pv: 200,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 308,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    uv: 7800,
    pv: 200,
    amt: 2290,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    name: "",
    uv: 2780,
    pv: 308,
    amt: 2000,
  },
];

const DATA1 = range(0, 1000).map(() => DATA1_POINTS[Math.floor(Math.random() * DATA1_POINTS.length)]);
const DATA2 = range(0, 1000).map(() => DATA2_POINTS[Math.floor(Math.random() * DATA2_POINTS.length)]);

const Container = styled.div`
  padding: 10px;
`;

const BigNumberCard = styled.div`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 4px 8px;
  border-radius: 4px;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: end;
`;

const BigNumber = styled.div`
  font-size: 24px;
  font-weight: 600;
  padding: 8px 0;
`;

const CardIcon = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => props.theme.color.primary};
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: -8px;
  left: 8px;
  border-radius: 4px;
`;

const Cards = styled.div`
  display: flex;
  gap: 10px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Plots = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const DashboardTabView = () => {
  const [dataIndex, setDataIndex] = useState(0);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataIndex(prev => prev + 1);
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [dataIndex]);

  return (
    <Container>
      <Cards>
        <BigNumberCard>
          <CardIcon style={{ backgroundColor: "#0191ff" }} />
          <div>Revenue Savings</div>
          <BigNumber>$ 1000</BigNumber>
        </BigNumberCard>
        <BigNumberCard>
          <CardIcon style={{ backgroundColor: "#0191ff" }} />
          <div>Cost</div>
          <BigNumber>$ 780</BigNumber>
        </BigNumberCard>
        <BigNumberCard>
          <CardIcon style={{ backgroundColor: "#0191ff" }} />
          <div>Sales</div>
          <BigNumber>10,000</BigNumber>
        </BigNumberCard>
      </Cards>

      <Plots>
        <LineChart
          width={500}
          height={300}
          data={DATA1?.slice(0 + dataIndex, 20 + dataIndex)}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line isAnimationActive={false} type="monotone" dataKey="pv" stroke="#0191ff" activeDot={{ r: 8 }} />
          <Line isAnimationActive={false} type="monotone" dataKey="uv" stroke="#009933" />
        </LineChart>

        <LineChart
          width={500}
          height={300}
          data={DATA2.slice(0 + dataIndex, 20 + dataIndex)}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            isAnimationActive={false}
            animationDuration={0}
            type="monotone"
            dataKey="pv"
            stroke="#0191ff"
            activeDot={{ r: 8 }}
          />
          <Line isAnimationActive={false} type="monotone" dataKey="uv" stroke="#009933" />
        </LineChart>
      </Plots>
      <TableFlat
        style={{ marginTop: "20px", textAlign: "left" }}
        rows={[
          ["Countries", "Number of predictions", "Revenue", "Cost", "Sales"],
          ["USA", "100", "$ 1000", "$ 780", "10,000"],
          ["Canada", "100", "$ 1000", "$ 780", "10,000"],
          ["Mexico", "100", "$ 1000", "$ 780", "10,000"],
          ["Brazil", "100", "$ 1000", "$ 780", "10,000"],
        ]}
      />
    </Container>
  );
};

export default DashboardTabView;
