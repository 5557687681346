export const someRepositories = [
  {
    name: "IntegrationTest",
    updated_at: "Updated yesterday",
  },
];

export const someModels = [
  {
    name: "nlp-model",
    updated_at: "Updated yesterday",
  },
  {
    name: "butterfly-model",
    updated_at: "Updated two days ago",
  },
];

export const someDatasets = [
  {
    name: "text-dataset",
    updated_at: "Updated yesterday",
  },
  {
    name: "butterfly-dataset",
    updated_at: "Updated two days ago",
  },
  {
    name: "random-dataset",
    updated_at: "Updated 7 days ago",
  },
];

export const anImagePredictionResult = {
  id: "61e945defc08877d75e90b87",
  taskId: "61ded7b588704b192da40658",
  deploymentId: "61e7f23cfc08877d75e90b7d",
  status: "DONE",
  timestamp: "Jan 20, 2022 11:22:07 AM",
  latencyMs: 66,
  results: [
    {
      label: {
        type: "CATEGORICAL",
        winner: "0",
        scores: {
          0: 0.999689,
          1: 1.49814369e-10,
          2: 6.15121749e-8,
          3: 0.00000137771417,
          4: 4.8396771e-9,
          5: 0.000306225,
          6: 0.00000228847421,
          7: 1.96259649e-8,
          8: 0.00000108525853,
          9: 1.67792857e-9,
        },
      },
    },
  ],
};

export const PYTHON_CODE = `import argparse
import json
import logging
import pathlib
import signal
import time
from mmap import ACCESS_READ, mmap

import numpy as np
import torch
import torch.nn as nn
import torch.nn.functional as F
import torch.utils.data
from bitarray import bitarray


class ExitHandler:
    def __init__(self):
        signal.signal(signal.SIGTERM, self.exit_gracefully)
        self.exit_signal = False

    def exit_gracefully(self, *args):
        self.exit_signal = True


EXIT_HANDLER = ExitHandler()
LOGGER = logging.getLogger(__name__)


class BzDataReader:
    @classmethod
    def bytes_per_data_point(cls, config):
        return sum(cls.feature_byte_lengths(config))

    @classmethod
    def feature_shapes(cls, config):
        shapes = []
        for feature in config["featureTypeDescriptors"]:
            if feature["type"]["typeName"] == "NDARRAY":
                shapes += [feature["type"]["shape"]]
            else:
                shapes += [[1]]
        return shapes

    @classmethod
    def feature_elements(cls, config):
        elements = []
        for feature in config["featureTypeDescriptors"]:
            if feature["type"]["typeName"] == "NDARRAY":
                elements += [np.prod(feature["type"]["shape"])]
            else:
                elements += [1]
        return elements

    @classmethod
    def feature_dtypes(cls, config):
        dtypes = []
        for feature in config["featureTypeDescriptors"]:
            type_name = feature["type"]["typeName"]
            if type_name == "NDARRAY":
                element_type = feature["type"]["elementType"]
                dtypes += [
                    element_type.get(
                        "storage", cls.DEFAULT_STORAGE[element_type["typeName"]]
                    )
                ]
            else:
                dtypes += [
                    feature["type"].get("storage", cls.DEFAULT_STORAGE[type_name])
                ]
        return dtypes

    @classmethod
    def feature_byte_lengths(cls, config):
        feature_bytes = []
        mask_length = 0
        for feature in config["featureTypeDescriptors"]:
            if feature["type"]["typeName"] == "NDARRAY":
                elements = np.prod(feature["type"]["shape"])
                feature_bytes += [
                    elements
                    * cls.num_of_bytes(feature["type"]["elementType"]["storage"])
                ]
                mask_length += elements
            else:
                feature_bytes += [cls.num_of_bytes(feature["type"]["storage"])]
                mask_length += 1
        return feature_bytes + [(mask_length + 7) // 8]

    @classmethod
    def num_of_bytes(cls, d_type: str):
        for i in range(-3, 0):
            if d_type[i:].isdigit():
                return int(int(d_type[i:]) / 8)

    DEFAULT_STORAGE = {
        "CATEGORICAL": "uint8",
        "INTEGER": "int32",
        "FLOAT": "float32",
        "NDARRAY": None,
    }

    def __init__(self, bz_data_file):
        """
        Initialise the data reader.

        :param bz_data_file: path to the raw data file
        """
        self._data_file = bz_data_file
        with open(self._data_file, "rb", 0) as f:
            config_length = int.from_bytes(f.read(4), "big")
            self._config_offset = config_length + 4
            self.config = dict(json.loads(f.read(config_length).decode("utf-8")))

    def numpy_data(self):
        with open(self._data_file, "rb", 0) as f, mmap(
            f.fileno(), 0, access=ACCESS_READ
        ) as s:
            n = self.config["totalDataPoints"]
            f_lengths = self.feature_byte_lengths(self.config)
            f_shapes = self.feature_shapes(self.config)
            f_elements = self.feature_elements(self.config)
            f_dtypes = self.feature_dtypes(self.config)
            ll = self.bytes_per_data_point(self.config)

            offset = self._config_offset
            ba_offset = 0
            ba_l = f_lengths[-1] * 8  # length in bits of bitarray mask per feature
            np_data = {}

            # fill the bit array
            o = offset + ll - f_lengths[-1]
            ba = bitarray(endian="little")
            for i in range(n):
                ba.frombytes(s[o + ll * i : o + ll * i + f_lengths[-1]])

            for ii, feature in enumerate(self.config["featureTypeDescriptors"]):
                d_type = f_dtypes[ii]
                shape = tuple([n] + f_shapes[ii])
                fl = f_lengths[ii]
                # extract the data for feature ii from the raw bytes data
                dt = np.dtype(d_type).newbyteorder(">")

                def data_slice(ind):
                    return slice(offset + ll * ind, offset + ll * ind + fl)

                data = np.concatenate(
                    [np.frombuffer(s[data_slice(i)], dt) for i in range(n)]
                ).reshape(shape)
                np_data[feature["key"]] = data
                offset += fl

                # extract mask for feature ii from bitarray
                elements = f_elements[ii]

                def ba_slice(ind):
                    return slice(
                        ba_offset + ba_l * ind, ba_offset + ba_l * ind + elements
                    )

                f_mask = np.concatenate(
                    [np.array(ba[ba_slice(i)].tolist(), np.uintc) for i in range(n)]
                ).reshape(shape)
                np_data[feature["key"] + "-mask"] = f_mask
                ba_offset += elements

        return np_data


class FeedforwardNN(nn.Module):
    def __init__(self, inputs, outputs, architecture) -> None:
        super().__init__()
        self.input_layer = TabularInputLayer(
            inputs, architecture["categorical_feature_embedding_dim"]
        )

        feedforward_list = []
        for i in range(architecture["n_layers"]):
            if i == 0:
                input_dim = self.input_layer.output_dim
            else:
                input_dim = architecture["latent_dim"]
            feedforward_list.append(nn.Linear(input_dim, architecture["latent_dim"]))

        self.layers = nn.ModuleList(feedforward_list)

        (output,) = outputs
        self.output_key = output["key"]
        self.output_type = output["type"]
        if self.output_type["typeName"] == "CATEGORICAL":
            k = len(self.output_type["categories"])
            self.prediction_head = nn.Linear(architecture["latent_dim"], k)
        else:
            self.prediction_head = nn.Linear(architecture["latent_dim"], 1)

    def forward(self, x):
        x = self.input_layer(x)
        for layer in self.layers:
            x = F.relu(layer(x))
        y = self.prediction_head(x)
        return y

    def build_loss(self, model_output, target):
        # build loss
        if self.output_type["typeName"] == "CATEGORICAL":
            return F.cross_entropy(model_output, target)
        else:
            return F.mse_loss(model_output, target)


class TabularInputLayer(nn.Module):
    def __init__(self, inputs, categorical_feature_embedding_dim) -> None:
        super().__init__()

        self.embedding_map = nn.ModuleDict()
        self.input_keys = []
        self.output_dim = 0
        for input_ in inputs:
            self.input_keys.append(input_["key"])
            input_type = input_["type"]
            if input_type["typeName"] == "CATEGORICAL":
                self.embedding_map[input_["key"]] = nn.Embedding(
                    len(input_type["categories"]),
                    embedding_dim=categorical_feature_embedding_dim,
                )
                self.output_dim += categorical_feature_embedding_dim
            else:
                self.output_dim += 1

    def forward(self, x):
        y = []
        for key in self.input_keys:
            val = x[key]
            if key in self.embedding_map:
                val = self.embedding_map[key](val)
            y.append(val)
        return torch.concat(y, dim=-1)


class PreProcessor(object):
    def __init__(self, output_key):
        self.output_key = output_key

    def __call__(self, batch, device):
        x = {
            key: val.to(device) for key, val in batch.items() if key != self.output_key
        }
        y = batch[self.output_key].to(device)
        return x, y


class TabularDataset(torch.utils.data.Dataset):
    def __init__(self, bz_data_config, np_data) -> None:
        super().__init__()
        self.bz_data_config = bz_data_config
        self.np_data = np_data

    def __len__(self):
        return self.bz_data_config["totalDataPoints"]

    def __getitem__(self, idx):
        datapoint = dict()
        for key, val in self.np_data.items():
            if np.issubdtype(val.dtype, np.integer):
                datapoint[key] = torch.as_tensor(val[idx].astype(np.int64))
            else:
                datapoint[key] = torch.as_tensor(val[idx], dtype=torch.float)
        return datapoint

    def collate_fn(self, batch):
        collated = dict()
        for datapoint in batch:
            for key, val in datapoint.items():
                collated.setdefault(key, []).append(val)
        for key, val in collated.items():
            collated[key] = torch.stack(val, dim=0)
        return collated

    @classmethod
    def from_bz_data(cls, data_file_path):
        data_path = pathlib.Path(data_file_path)
        if data_path.is_dir():
            bz_data_config = None
            np_data = dict()
            for single_data_file_path in data_path.iterdir():
                bz_data = BzDataReader(single_data_file_path)
                if bz_data_config is None:
                    bz_data_config = bz_data.config
                else:
                    bz_data_config["totalDataPoints"] += bz_data.config[
                        "totalDataPoints"
                    ]
                for key, val in bz_data.numpy_data().items():
                    np_data.setdefault(key, []).append(val)
                LOGGER.info(
                    "load %s, n = %s", data_file_path, bz_data.config["totalDataPoints"]
                )

            for key, val in np_data.items():
                np_data[key] = np.concatenate(val, axis=0)
                if np_data[key].shape[0] != bz_data_config["totalDataPoints"]:
                    LOGGER.warning(
                        f"data feature \`{key}\` does not match dataset size, dropped"
                    )
                    np_data.pop(key)
        else:
            bz_data = BzDataReader(data_path)
            bz_data_config = bz_data.config
            np_data = bz_data.numpy_data()
            LOGGER.info(
                "load %s, n = %s", data_file_path, bz_data.config["totalDataPoints"]
            )

        LOGGER.info("load bz_data, n = %s", bz_data_config["totalDataPoints"])

        return cls(bz_data_config, np_data)


def log_train(log_file, iter, **kwargs):
    if iter == "DONE":
        log_line = "training finished"
    else:
        log_line = json.dumps({"iter": iter, **kwargs})

    if log_file is None:
        print(log_line)
    else:
        with open(log_file, "a") as f:
            f.write(log_line + "\n")
            f.flush()


def train_step(model, batch, optimizer):
    x, y = batch
    model_logits = model(x)
    loss = model.build_loss(model_logits, y)

    optimizer.zero_grad()
    loss.backward()
    optimizer.step()

    return loss.item(), y.shape[0]


def train(model, pre_processor, dataset, training_config, start_iter=0, log_file=None):
    device = torch.device("cuda:0" if torch.cuda.is_available() else "cpu")
    # create optimizer
    optimizer = torch.optim.Adam(
        model.parameters(), lr=training_config["learning_rate"]
    )
    # create dataloader
    train_dataloader = torch.utils.data.DataLoader(
        dataset,
        batch_size=training_config["batch_size"],
        # shuffle=True,
        collate_fn=dataset.collate_fn,
        # num_workers=8,
        # prefetch_factor=4,
        pin_memory=torch.cuda.is_available(),
    )
    # train loop
    model.to(device).train()

    step = start_iter
    for epoch in range(1, training_config["n_epoch"] + 1):
        loss = 0
        n = 0
        for batch in train_dataloader:
            t = time.time()
            batch = pre_processor(batch, device=device)
            # batch = (dat.to(device) for dat in batch)
            batch_loss, batch_size = train_step(model, batch, optimizer)

            loss += batch_loss * batch_size
            n += batch_size

            log_train(
                log_file,
                step + 1,
                max_iter=training_config["n_steps"],
                epoch=epoch,
                elapsed=time.time() - t,
                loss=float(loss / n),
            )
            step += 1

            if "n_steps" in training_config and step >= training_config["n_steps"]:
                log_train(log_file, "DONE")
                return
            if EXIT_HANDLER.exit_signal:
                LOGGER.info(
                    "Exit signal received, stop at current training step %s", step
                )
                return

        # loss /= n
        # log_train(log_file, epoch + 1, float(loss))
    log_train(log_file, "DONE")


def serving_processor_code():
    """
    {
        "tab": 4,
        "bos": "# <SCRIPT_START>",
        "eos": "# <SCRIPT_END>"
    }
    """
    # <SCRIPT_START>
    import json
    import pathlib
    from typing import Awaitable, Callable, Dict, List

    import torch
    import torch.nn as nn
    import torch.nn.functional as F

    class FeedforwardNN(nn.Module):
        def __init__(self, inputs, outputs, architecture) -> None:
            super().__init__()
            self.input_layer = TabularInputLayer(
                inputs, architecture["categorical_feature_embedding_dim"]
            )

            feedforward_list = []
            for i in range(architecture["n_layers"]):
                if i == 0:
                    input_dim = self.input_layer.output_dim
                else:
                    input_dim = architecture["latent_dim"]
                feedforward_list.append(
                    nn.Linear(input_dim, architecture["latent_dim"])
                )

            self.layers = nn.ModuleList(feedforward_list)

            (output,) = outputs
            self.output_key = output["key"]
            self.output_type = output["type"]
            if self.output_type["typeName"] == "CATEGORICAL":
                k = len(self.output_type["categories"])
                self.prediction_head = nn.Linear(architecture["latent_dim"], k)
            else:
                self.prediction_head = nn.Linear(architecture["latent_dim"], 1)

        def forward(self, x):
            x = self.input_layer(x)
            for layer in self.layers:
                x = F.relu(layer(x))
            y = self.prediction_head(x)
            return y

        def build_loss(self, model_output, target):
            # build loss
            if self.output_type["typeName"] == "CATEGORICAL":
                return F.cross_entropy(model_output, target)
            else:
                return F.mse_loss(model_output, target)

    class TabularInputLayer(nn.Module):
        def __init__(self, inputs, categorical_feature_embedding_dim) -> None:
            super().__init__()

            self.embedding_map = nn.ModuleDict()
            self.input_keys = []
            self.output_dim = 0
            for input_ in inputs:
                self.input_keys.append(input_["key"])
                input_type = input_["type"]
                if input_type["typeName"] == "CATEGORICAL":
                    self.embedding_map[input_["key"]] = nn.Embedding(
                        len(input_type["categories"]),
                        embedding_dim=categorical_feature_embedding_dim,
                    )
                    self.output_dim += categorical_feature_embedding_dim
                else:
                    self.output_dim += 1

        def forward(self, x):
            y = []
            for key in self.input_keys:
                val = x[key]
                if key in self.embedding_map:
                    val = self.embedding_map[key](val)
                y.append(val)
            return torch.concat(y, dim=-1)

    class ServingProcessor(object):
        def __init__(self, root_dir="/input"):
            root_dir = pathlib.Path(root_dir)
            self.model_config = json.loads((root_dir / "model_config.json").read_text())
            self.model = FeedforwardNN(
                self.model_config["inputs"],
                self.model_config["outputs"],
                self.model_config["architecture"],
            ).eval()
            model_weights = torch.load(
                root_dir / "weights/model.pt", map_location=torch.device("cpu")
            )
            self.model.load_state_dict(model_weights)

        async def predict(
            self, json_payload: Dict, tf_predict: Callable[[Dict], Awaitable[Dict]]
        ) -> List:
            json_input = json_payload["inputs"]
            x = self.preprocess(json_input, self.model_config["inputs"])
            y = self.model(x)
            json_output = self.postprocess(y, self.model_config["outputs"])
            return json_output

        def preprocess(self, json_input: List[Dict], inputs_config: List[Dict]) -> Dict:
            x = dict()
            for input_config in inputs_config:
                input_vals = []

                for datapoint in json_input:
                    input_vals.append(datapoint[input_config["key"]])

                input_type = input_config["type"]
                if input_type["typeName"] in ("INTEGER", "CATEGORICAL"):
                    input_vals = torch.as_tensor(input_vals, dtype=torch.long)
                else:
                    input_vals = torch.as_tensor(input_vals, dtype=torch.float32)

                x[input_config["key"]] = input_vals.view(len(json_input), -1)
            return x

        def postprocess(
            self, model_output: torch.Tensor, outputs_config: List[Dict]
        ) -> List:
            (output_config,) = outputs_config
            output_key = output_config["key"]
            output_type = output_config["type"]

            if output_config["type"] == "CATEGORICAL":
                output_vocab = output_type["categories"]

                probs = model_output.softmax(dim=-1).tolist()
                preds = model_output.argmax(dim=-1).tolist()
                if output_vocab is not None:
                    preds = [output_vocab[pred] for pred in preds]
                outputs = [
                    {
                        output_key: pred,
                        "scores": {
                            (output_vocab[i] if output_vocab is not None else i): p
                            for i, p in enumerate(prob)
                        },
                    }
                    for pred, prob in zip(preds, probs)
                ]
                return outputs

            else:
                outputs = []
                for (pred,) in model_output.tolist():
                    if output_type["typeName"] == "INTEGER":
                        pred = int(pred)
                    outputs.append({output_key: pred})
                return outputs

    # <SCRIPT_END>
    return ServingProcessor


def save_tfserving():
    import tensorflow as tf

    class TFServingWrapper(tf.Module):
        @tf.function(
            input_signature=[tf.TensorSpec((1,), dtype=tf.int32, name="test_input")]
        )
        def run(self, test_input):
            return {"outputs": "success"}

    wrapper = TFServingWrapper()
    tf.saved_model.save(
        wrapper,
        "./output/weights/saved_model/1",
        signatures={"serving_default": wrapper.run},
    )


def save_model(model):
    pathlib.Path("./output/weights").mkdir(parents=True, exist_ok=True)
    torch.save(model.state_dict(), "./output/weights/model.pt")
    save_tfserving()


def save(model):
    import inspect
    import shutil
    import tarfile

    script_config = json.loads(inspect.getdoc(serving_processor_code) or "")
    write = False
    with open("./output/serving_processor.py", "w") as f:
        for line in inspect.getsource(serving_processor_code).split("\n"):
            line = line[script_config["tab"] :]
            if line == script_config["bos"]:
                write = True
                continue
            if line == script_config["eos"]:
                break
            if write:
                f.write(line + "\n")

    save_model(model)
    with tarfile.open("./output/serving-pack.tgz", "w:gz") as tar:
        tar.add("./input/model_config.json", arcname="model_config.json")
        tar.add("./output/weights/model.pt", arcname="weights/model.pt")
        tar.add("./output/weights/saved_model", arcname="tf-model")
        tar.add("./output/serving_processor.py", arcname="serving_processor.py")
    shutil.rmtree("./output/weights/saved_model")


if __name__ == "__main__":
    parser = argparse.ArgumentParser()
    parser.add_argument("--model-config", default="./input/model_config.json")
    parser.add_argument("--weights", default="./input/weights/")
    parser.add_argument("--data", default="./input/data/")
    parser.add_argument("--training-config", default="input/training_config.json")
    parser.add_argument("--start-iter", type=int, default=0)
    parser.add_argument("--log-file", default="./log/run.log")
    args = parser.parse_args()

    logging.basicConfig(
        filename=args.log_file,
        level=logging.INFO,
        format="%(asctime)s:%(levelname)s:%(name)s:%(message)s",
    )

    with open(args.training_config, "r") as f:
        training_config = json.load(f)
    training_config = {
        "batch_size": 10,
        "n_train": 0.8,
        "n_epoch": 100,
        "n_steps": training_config["train"]["max_iter"],
        "learning_rate": 2e-4,
        "max_grad_norm": 10,
    }

    with open(args.model_config, "r") as f:
        model_config = json.load(f)
    model = FeedforwardNN(
        model_config["inputs"], model_config["outputs"], model_config["architecture"]
    )
    pre_processor = PreProcessor(model.output_key)
    dataset = TabularDataset.from_bz_data(args.data)

    # train
    train(
        model, pre_processor, dataset, training_config, args.start_iter, args.log_file
    )

    # save
    save(model)
    LOGGER.info("saved model to ./output")
`;
