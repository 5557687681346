import { zapiGet, zapiPost, zapiDelete, zapiPatch } from "api/zapi";
import { apiGet } from "api/api-http-methods";

export const getJobOrganicMetricByJobId = async jobId => {
  const { data, error } = await zapiGet(`/job-service/api/v1/job/metrics`, { job_id: jobId });
  return { data, error };
};

export const getJobChainMetricByJobId = async jobId => {
  const { data, error } = await zapiGet(`/job-service/api/v1/jobs/${jobId}/chain-log`, { job_id: jobId });
  return { data, error };
};

export const getEvaluationJobById = async evalJobId => {
  const { data, error } = await zapiGet(`/job-service/api/v1/evaluation-jobs/${evalJobId}`);
  return { data, error };
};

export const getTrainingJobById = async jobId => {
  const { data, error } = await zapiGet(`/core/job-service/api/v2/jobs/${jobId}`);
  return { data, error };
};

export const getJob = async id => {
  const { data, error } = await zapiGet(`/core/job-service/api/v2/jobs/${id}`);
  return { data, error };
};

export const patchJobById = async (jobId, params = {}, body = {}) => {
  const { data, error } = await zapiPatch(`/core/job-service/api/v2/jobs/${jobId}`, params, body);
  return { data, error };
};

export const getEvaluationJobs = async () => {
  const { data, error } = await zapiGet(`/job-service/api/v1/evaluation-jobs`);
  return { data, error };
};

export const createEvaluationJob = async jobConfig => {
  const { data, error } = await zapiPost(`/job-service/api/v1/evaluation-jobs`, {}, jobConfig);
  return { data, error };
};

export const getGeneratedData = async jobId => {
  const { data, error } = await zapiGet(`/job-service/api/v1/jobs/${jobId}/generation-data`);
  return { data, error };
};

export const getJobBundleEvalustions = async jobBundleId => {
  const { data, error } = await zapiGet(`/job-service/api/v1/rl-job-bundles/${jobBundleId}/evaluations`);
  return { data, error };
};

export const getRecommendedTrainingConfig = async () => {
  const { data, error } = await zapiGet("/job-service/api/v1/recommended-job-config");
  return { data, error };
};

export const killJobById = async jobId => {
  const { data, error } = await zapiDelete("/job-service/api/v1/job/kill", { job_id: jobId });
  return { data, error };
};

export const continueJobById = async jobId => {
  const { data, error } = await zapiGet(`/job-service/api/v1/jobs/${jobId}/continue`);
  return { data, error };
};

export const getCustomCodeTemplate = async () => {
  const { data, error } = await apiGet(`/job-service/api/v1/custom-code-template`);
  return { data: data.objectJSON, error };
};
