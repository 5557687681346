import { useEffect, useState } from "react";
import { getDataIngestionJob } from "api/services/dataService";

const usePollIngestionJob = ingestionJobId => {
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    setJob(null);
  }, [ingestionJobId]);

  useEffect(() => {
    if (!ingestionJobId) {
      return;
    }

    const pollIntervalId = setInterval(doPopulateJob, 2000);
    setIntervalId(pollIntervalId);
    doPopulateJob();

    return () => clearInterval(pollIntervalId);
  }, [ingestionJobId]);

  useEffect(() => {
    if (error || job?.status === "DONE" || job?.status === "DONE_FAILED") {
      clearInterval(intervalId);
    }
  }, [job, error]);

  const doPopulateJob = async () => {
    const { data, error } = await getDataIngestionJob(ingestionJobId);
    setJob(data);
    setError(error);
  };

  return [job, error];
};

export default usePollIngestionJob;
