import { zapiGet, zapiPost, zapiPatch } from "api/zapi";
import { errorTypes } from "api/error-handling";

export const getCubeByRepoSlug = async repositoryOwnerPlusName => {
  const { data, error } = await zapiGet("/github-service/api/v1/cubes", {
    repositoryOwnerPlusName
  });
  return { data, error };
};

export const getGithubCubes = async params => {
  const { data, error } = await zapiGet("/github-service/api/v1/cubes", params);
  if ((error?.type === errorTypes.ZAPI_FAIL) && error?.message.includes("No github User information was found")) {
    return {
      data: data, 
      error: {
        type: errorTypes.NO_GITHUB_ACCOUNT,
        message: error.message
      }
    }
  }
  return { data, error };
}

export const createCubeFromRepo = async repositoryOwnerPlusName => {
  const { data, error } = await zapiPost(
    "/github-service/api/v1/cubes",
    {
      repositoryOwnerPlusName
    },
    {}
  );
  return { data, error };
};

export const createCubeIntegrationFromDataset = async (
  datasetId,
  repositoryOwnerPlusName
) => {
  const { data, error } = await zapiPost(
    "/github-service/api/v1/cubes",
    {
      datasetId,
      repositoryOwnerPlusName
    },
  );
  return { data, error };
};


export const getCubePipelineByCommit = async commitId => {
  const { data, error } = await zapiGet(
  `/github-service/api/v1/commit/${commitId}/pipeline`
  );
  return { data, error };
};

export const postPipelineConfig = async pipelineConfig => {
  const { data, error } = await zapiPost(
    "/github-service/api/v1/pipelines", {}, pipelineConfig
  );
  return {data, error}
};

export const patchPipelineConfig = async (pipelineId, pipelineConfig) => {
  const { data, error } = await zapiPatch(`/github-service/api/v1/pipelines/${pipelineId}`, {}, pipelineConfig);
  return { data, error };
};

export const getProjectByRepoSlug = async repositoryName => {
  const { data, error } = await zapiGet("/github-service/api/v1/projects", {
    repositoryName,
  });
  return { data, error };
};

export const getLoggedInUserRepositories = async () => {
  const { data, error } = await zapiGet("/github-service/api/v1/repositories");
  return { data, error };
};

export const createProjectFromRepo = async repoSlug => {
  const { data, error } = await zapiPost(
    "/github-service/api/v1/projects",
    {
      repositoryName: repoSlug,
    },
    {}
  );
  return { data, error };
};

export const createProjectIntegrationFromDataset = async (
  datasetId,
  repoSlug
) => {
  const { data, error } = await zapiPost(
    "/github-service/api/v1/projects",
    {
      datasetId: datasetId,
      repositoryName: repoSlug,
    },
  );
  return { data, error };
};

export const connectGithubAccount = async (githubAccount, githubToken) => {
  const { data, error } = await zapiPost(
    "/github-service/api/v1/github_account",
    { githubAccount, githubToken },
    {}
  );
  return { data, error };
};

export const patchGithubAccount = async fieldsToUpdate => {
  const { data, error } = await zapiPatch(
    "/github-service/api/v1/github_account",
    fieldsToUpdate,
    {},
  );
  return { data, error };
};

export const getConnectedGithubAccount = async () => {
  const { data, error } = await zapiGet(
    "/github-service/api/v1/github_account"
  );
  return { data, error };
};

export const getCommitsForRepoSlugBySha = async (repoSlug, shaOrBranch) => {
  const { data, error } = await zapiGet(
    `/github-service/api/v1/repositories/${repoSlug}/commits`,
    { sha_or_branch: shaOrBranch }
  );
  return { data, error };
};

export const getBranchesForRepoSlug = async repoSlug => {
  const { data, error } = await zapiGet(
    `/github-service/api/v1/repositories/${repoSlug}/branches`
  );
  return { data, error };
};

export const getFileListByRepoSlugAndBranchName = async (repoSlug, branchName) => {
  const { data, error } = await zapiGet(
    `/github-service/api/v1/repositories/${repoSlug}/folder_content_branch/${branchName}`
  );
  return { data, error };
};

export const getFileContentByRepoSlugBranchNameAndFileName = async (repoSlug, branchName, filePath) => {
  const { data, error } = await zapiGet(
    `/github-service/api/v1/repositories/${repoSlug}/file_content_branch/${branchName}`,
    { path: filePath }
  );
  return { data, error };
};

export const getPipelineIdFromCommitSha = async (commitSha) => {
  const { data, error } = await zapiGet(
    `/github-service/api/v1/commit/${commitSha}/pipeline`
  );
  return { data, error };
}