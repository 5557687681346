import { zapiGet, zapiPost, zapiPatch } from "api/zapi";
import { apiPost } from "api/api-http-methods";
import { apiGetRawResponse } from "api/api-http-methods";

export const uploadPackage = async (name, formDataBody) => {
  const { data, error } = await apiPost("/project-service/api/v1/ocr/files", { name }, formDataBody);
  return { data, error };
};

export const getPackageById = async packageId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/ocr/files/${packageId}`);
  return { data, error };
};

export const getAllPackages = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/ocr/files");
  return { data, error };
};

export const searchWithKeywords = async query => {
  const { data, error } = await zapiPost("/project-service/api/v1/ocr/tables", null, { keyword: query });
  return { data, error };
};

export const downloadPackgeFile = async (packageId, tableId) => {
  const res = await apiGetRawResponse(`/project-service/api/v1/ocr/files/${packageId}/download/${tableId}`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.download = `${packageId}-${tableId}.xlsx`;
  anchor.href = objectUrl;
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
};

export const getAnnotationsByFileAndPage = async (fileId, pageNumber) => {
  const { data, error } = await zapiGet(`/project-service/api/v1/ocr/files/${fileId}/pages/${pageNumber}/annotations`);
  return { data, error };
};

export const patchAnnotationsByFileAndPage = async (fileId, pageNumber, filePageAnnotations) => {
  const { data, error } = await zapiPatch(
    `/project-service/api/v1/ocr/files/${fileId}/pages/${pageNumber}/annotations`,
    null,
    filePageAnnotations
  );
  return { data, error };
};

export const triggerDownloadOfAnnotationData = async () => {
  const res = await apiGetRawResponse(`/project-service/api/v1/ocr/files/annotations`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "annotations.json";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};
