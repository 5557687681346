import { useEffect, useRef } from "react";
import styled from "styled-components";
import { CopyBlock, dracula } from "react-code-blocks";

const CodeMirrorWrapper = styled.div`
  height: ${props => props.height};
  overflow-y: auto;
  width: 100%;
`;

const CustomCodeMirror = ({ value, height = "400px", showLineNumber = true }) => {
  const codeBlockRef = useRef();

  useEffect(() => {
    codeBlockRef.current.scrollTop = codeBlockRef.current.scrollHeight;
  }, [value]);

  return (
    <CodeMirrorWrapper ref={codeBlockRef} height={height}>
      <CopyBlock
        id="code"
        language="jsx"
        text={value}
        showLineNumbers={showLineNumber}
        wrapLines={true}
        theme={dracula}
      />
    </CodeMirrorWrapper>
  );
};

export default CustomCodeMirror;
