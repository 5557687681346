import styled from "styled-components";

const Input = styled.input`
  border: none;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  color: ${props => props.theme.color.closest};
  width: 100%;
`;

const Container = styled.label`
  display: block;
  cursor: text;

  padding: 10px 15px;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.color.closest};
  font-family: "Montserrat";
  width: 100%;

  border: 2px solid ${props => props.theme.color.closer1_5};
  position: relative;
  display: inline-block;
  height: max-content;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const CornerRect = styled.div`
  width: 2px;
  height: 2px;
  position: absolute;
  background-color: ${props => props.theme.color.primary};
`;

const TextInput = ({
  isFocussed,
  value = "",
  title,
  type = "text",
  onNewInput,
  isRequired,
  dataTutorialId,
  placeholder,
  className,
  min,
  max,
  isDisabled,
  isRed,
}) => (
  <Container isDisabled={isDisabled} className={className}>
    <CornerRect style={{ top: "-2px", left: "-2px" }} />
    <CornerRect style={{ top: "-2px", right: "-2px" }} />
    <CornerRect style={{ bottom: "-2px", left: "-2px" }} />
    <CornerRect style={{ bottom: "-2px", right: "-2px" }} />

    <Input type={type} value={value} onChange={e => onNewInput(e.target.value)} placeholder={placeholder || title} />
  </Container>
);

export default TextInput;
