import { apiGet, apiPost } from "api/api-http-methods";
import { zapiGet, zapiPost, zapiDelete } from "api/zapi";
import { errorTypes } from "api/error-handling";

export const getLoginTokens = async (userName, password) => {
  const { data, error } = await apiPost(
    "/authentication-service/login",
    {},
    {
      userName,
      password,
    }
  );
  if (error) {
    return { data, error: { type: errorTypes.LOGIN_ERROR } };
  }
  return { data, error };
};

export const getAllUsers = async () => {
  const { data, error } = await apiGet("/authentication-service/users");
  return { data, error };
};

export const addUser = async userInfo => {
  const { data, error } = await apiPost("/authentication-service/users", {}, userInfo);
  return { data, error };
};

export const getGoogleDriveOauthToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/googledrive/token");
  return { data, error };
};

export const revokeGoogleDriveOauthToken = async () => {
  const { data, error } = await zapiDelete("/authentication-service/googledrive/token");
  return { data, error };
};

export const googleDriveLogin = async callBackUrl => {
  const { data, error } = await zapiGet("/authentication-service/googledrive/login", { callbackUrl: callBackUrl });
  return { data, error };
};

export const googleDriveOauthCallBack = async code => {
  const { data, error } = await zapiGet("/authentication-service/googledrive/oauth-callback", { code: code });
  return { data, error };
};

export const getGoogleDriveFiles = async () => {
  const { data, error } = await zapiGet("/authentication-service/googledrive/files");
  return { data, error };
};

export const getGoogleDriveFileContent = async fileId => {
  const { data, error } = await zapiGet("/authentication-service/googledrive/file/content", { fileId: fileId });
  return { data, error };
};

export const getHubspotOauthToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/hubspot/token");
  return { data, error };
};

export const revokeHubspotOauthToken = async () => {
  const { data, error } = await zapiDelete("/authentication-service/hubspot/token");
  return { data, error };
};

export const hubspotLogin = async callBackUrl => {
  const { data, error } = await zapiGet("/authentication-service/hubspot/login", { callbackUrl: callBackUrl });
  return { data, error };
};

export const hubspotOauthCallBack = async code => {
  const { data, error } = await zapiGet("/authentication-service/hubspot/oauth-callback", { code: code });
  return { data, error };
};

export const hubspotGetContacts = async count => {
  const { data, error } = await zapiGet("/authentication-service/hubspot/contacts", { count });
  return { data, error };
};

export const getSalesforceOauthToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/salesforce/token");
  return { data, error };
};

export const salesforceLogin = async callBackUrl => {
  const { data, error } = await zapiGet("/authentication-service/salesforce/login", { callbackUrl: callBackUrl });
  return { data, error };
};

export const salesforceOauthCallBack = async code => {
  const { data, error } = await zapiGet("/authentication-service/salesforce/oauth-callback", { code: code });
  return { data, error };
};

export const getGmailOauthToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/gmail/token");
  return { data, error };
};

export const revokeGmailOauthToken = async () => {
  const { data, error } = await zapiDelete("/authentication-service/gmail/token");
  return { data, error };
};

export const gmailLogin = async callBackUrl => {
  const { data, error } = await zapiGet("/authentication-service/gmail/login", { callbackUrl: callBackUrl });
  return { data, error };
};

export const gmailOauthCallBack = async code => {
  const { data, error } = await zapiGet("/authentication-service/gmail/oauth-callback", { code: code });
  return { data, error };
};

export const sendEmail = async email => {
  const { data, error } = await zapiPost("/authentication-service/gmail/emails", null, { ...email });
  return { data, error };
};

export const getLinkedinOauthToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/linkedin/token");
  return { data, error };
};

export const linkedinLogin = async callBackUrl => {
  const { data, error } = await zapiGet("/authentication-service/linkedin/login", { callbackUrl: callBackUrl });
  return { data, error };
};

export const linkedinOauthCallBack = async code => {
  const { data, error } = await zapiGet("/authentication-service/linkedin/oauth-callback", { code: code });
  return { data, error };
};

export const getLinkedinConnections = async (start, count) => {
  const { data, error } = await zapiGet("/authentication-service/linkedin/connections", { start, count });
  return { data, error };
};

export const getLinkedinProfile = async () => {
  const { data, error } = await zapiGet("/authentication-service/linkedin/profile");
  return { data, error };
};

export const getGoogleCalendarOauthToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/googlecalendar/token");
  return { data, error };
};

export const revokeGoogleCalendarOauthToken = async () => {
  const { data, error } = await zapiDelete("/authentication-service/googlecalendar/token");
  return { data, error };
};

export const googleCalendarLogin = async callBackUrl => {
  const { data, error } = await zapiGet("/authentication-service/googlecalendar/login", { callbackUrl: callBackUrl });
  return { data, error };
};

export const googleCalendarCallBack = async code => {
  const { data, error } = await zapiGet("/authentication-service/googlecalendar/oauth-callback", { code: code });
  return { data, error };
};

export const createGoogleCalendarEvent = async event => {
  const { data, error } = await zapiPost("/authentication-service/googlecalendar/events", null, { ...event });
  return { data, error };
};

export const addGoogleCalendaerUserRefereshToken = async refreshToken => {
  const { data, error } = await zapiPost("/authentication-service/googlecalendar/refresh-token", { refreshToken });
  return { data, error };
};

export const getAdminUserGoogleCalendarAccessToken = async () => {
  const { data, error } = await zapiGet("/authentication-service/platform-googlecalendar-user/admin-user/token");
  return { data, error };
};

export const demoUserSendMeetingRequestEmail = async email => {
  const { data, error } = await zapiPost("/authentication-service/gmail-demo-user/emails", null, email);
  return { data, error };
};
