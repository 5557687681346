import { cloneDeep } from "lodash";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import {
  SF,
  deleteCharAt,
  drawCursorAtLineAndLetterIndex,
  drawBoxes,
  getBoxes,
  getMouseEventLocation,
  getXandYForLineAndLetterIndex,
  insertCharAt,
  insertTableBlockAt,
  splitBlockAt,
  drawBlueSelectionBoxes,
  deleteCharsInBlocksInRange,
  PAGE_WIDTH_PX,
  START_X,
  getBoxAndLetterIndexFromExternalCursor,
} from "utils/word-utils";

const ARROW_KEYS = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"];

const ContentCanvas = styled.canvas`
  position: absolute;
  left: 0;
  top: ${props => props.topOffset}px;
  width: ${props => props.viewportWidth}px;
  height: ${props => props.viewportHeight}px;
  pointer-events: none;
`;

const CursorCanvas = styled(ContentCanvas)`
  background-color: transparent;
`;

const CanvasContainer = styled.div`
  position: relative;
  display: grid;
  height: ${props => props.viewportHeight}px;
  /* height: calc(100vh - ${props => props.topOffset}px); */
  overflow: auto;
`;

// const StyledSearchInput = styled(SearchInput)`
//   padding: 0;
// `;

const TallEmptyDiv = styled.div`
  /* height: 100000px; */
  width: 0px;
`;

/* 
// BE representation
blocks = [
  { text: "This could be very very long ..."}
  ...
]

// canvas representation
boxes = [
  { text: "This could be", blockIndex: 0, blockStartIndex: 0 },
  { text: "very very long ...", blockIndex: 0, blockStartIndex: 14 },
  ...
]
*/
const WordDoc = ({
  blocks = [{ text: "" }],
  externalCursor = { blockIndex: null, letterIndex: null },
  onChangeBlocks = newBlocks => {},
  onChangeSelection = ({ blockIndex, start, end }) => {},
  topOffset = 0,
  onPressTab = () => {},
  onPressApproveOrReject = newBlocks => {},
  cursorStrokeStyle,
  cursorLineWidth,
  cursorMsg,
  isSavingApproval,
}) => {
  const canvasContainerRef = useRef(null);
  const contentCanvasRef = useRef(null);
  const cursorCanvasRef = useRef(null);

  const [canvasSize] = useState({ width: 1200, height: 1000 });
  const [inputPosition, setInputPosition] = useState({ x: null, y: null });
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [pageTopY, setPageTopY] = useState(0);
  const [sourceModalY, setSourceModalY] = useState(null);
  const [sourceModalMeta, setSourceModalMeta] = useState(null);
  const [sourceModalMetaLocation, setSourceModalMetaLocation] = useState({
    blockIndex: null,
    styleIndex: null,
  });
  const [isSourceModalOpen, setIsSourceModalOpen] = useState(false);

  const docState = useRef({
    cursor: {
      boxIndex: 0,
      letterIndex: 0,
    },
    endCursor: {
      boxIndex: 0,
      letterIndex: 0,
    },
    boxes: [],
  });

  useEffect(() => {
    drawCursorAtLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxIndex: docState.current.cursor.boxIndex,
      letterIndex: docState.current.cursor.letterIndex,
      boxes: docState.current.boxes,
      cursorStrokeStyle,
      cursorLineWidth,
      cursorMsg,
    });
  }, [cursorStrokeStyle, cursorLineWidth, cursorMsg]);

  useEffect(() => {
    if (externalCursor?.blockIndex === null || externalCursor?.letterIndex === null) {
      return;
    }

    const { boxIndex, letterIndex } = getBoxAndLetterIndexFromExternalCursor({
      blockIndex: externalCursor.blockIndex,
      blockLetterIndex: externalCursor.letterIndex,
      boxes: docState.current.boxes,
    });

    docState.current.cursor.boxIndex = boxIndex;
    docState.current.cursor.letterIndex = letterIndex;
    docState.current.endCursor.boxIndex = boxIndex;
    docState.current.endCursor.letterIndex = letterIndex;

    drawCursorAtLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxIndex,
      letterIndex,
      boxes: docState.current.boxes,
      cursorStrokeStyle,
      cursorLineWidth,
      cursorMsg,
    });
  }, [externalCursor?.blockIndex, externalCursor?.letterIndex]);

  useEffect(() => {
    const ctx = contentCanvasRef.current?.getContext("2d");
    const cursorCtx = cursorCanvasRef.current?.getContext("2d");
    ctx.font = `${14 * SF}px Montserrat`;
    cursorCtx.font = `${14 * SF}px Montserrat`;

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("paste", onPaste);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("paste", onPaste);
    };
  }, [cursorCanvasRef.current, contentCanvasRef.current, JSON.stringify(blocks)]);

  useEffect(() => {
    const boxes = getBoxes({
      blocks,
      ctx: contentCanvasRef.current?.getContext("2d"),
      pageTopY,
    });
    drawBoxes({
      ctx: contentCanvasRef.current?.getContext("2d"),
      boxes: boxes,
      pageTopY,
    });
    drawCursorAtLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxIndex: docState.current.cursor.boxIndex,
      letterIndex: docState.current.cursor.letterIndex,
      boxes: boxes,
      cursorStrokeStyle,
      cursorLineWidth,
      cursorMsg,
    });
    const { boxIndex: startBoxIndex, letterIndex: startLetterIndex } = docState.current.cursor;
    const { boxIndex: endBoxIndex, letterIndex: endLetterIndex } = docState.current.endCursor;
    drawBlueSelectionBoxes({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes,
      startBoxIndex,
      startLetterIndex,
      endBoxIndex,
      endLetterIndex,
    });
    docState.current.boxes = boxes;
  }, [JSON.stringify(blocks), document.fonts.check("14px Montserrat"), pageTopY]);

  const onPaste = e => {
    const clipboardText = e.clipboardData.getData("text");
    const { boxes } = docState.current;
    let { boxIndex, letterIndex } = docState.current.cursor;
    // when clicking at end of block, insertion is offset by one space
    const newBlocks = insertCharAt({
      blocks,
      boxes,
      boxIndex,
      letterIndex,
      char: clipboardText,
    });
    onChangeBlocks(newBlocks);
  };

  const onCanvasMouseDown = e => {
    setIsMouseDown(true);
    setInputPosition({ x: null, y: null });

    const [boxIndex, letterIndex] = getMouseEventLocation({
      e,
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current?.boxes,
    });

    drawCursorAtLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxIndex,
      letterIndex,
      boxes: docState.current?.boxes,
      cursorStrokeStyle,
      cursorLineWidth,
      cursorMsg,
    });

    const { y } = getXandYForLineAndLetterIndex({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current.boxes,
      boxIndex,
      letterIndex,
    });
    setSourceModalY(y);
    const box = docState.current?.boxes?.[boxIndex];
    const letterIndexMeta = box?.styles?.find(style => letterIndex >= style.start && letterIndex <= style.end)?.meta;
    setSourceModalMeta(letterIndexMeta);
    setSourceModalMetaLocation({
      blockIndex: box?.blockIndex,
      styleIndex: box.styles?.findIndex(style => letterIndex >= style.start && letterIndex <= style.end),
    });

    docState.current.endCursor.boxIndex = null;
    docState.current.endCursor.letterIndex = null;
    docState.current.cursor.boxIndex = boxIndex;
    docState.current.cursor.letterIndex = letterIndex;
  };

  const onCanvasMouseMove = e => {
    if (!isMouseDown) {
      return;
    }
    const { boxIndex: startBoxIndex, letterIndex: startLetterIndex } = docState.current.cursor;
    const boxes = docState.current.boxes;

    const [endBoxIndex, endLetterIndex] = getMouseEventLocation({
      e,
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes: docState.current?.boxes,
    });

    drawBlueSelectionBoxes({
      ctx: cursorCanvasRef.current.getContext("2d"),
      boxes,
      startBoxIndex,
      startLetterIndex,
      endBoxIndex,
      endLetterIndex,
    });

    const startBox = boxes[startBoxIndex];
    const endBox = boxes[endBoxIndex];
    onChangeSelection({
      startBlockIndex: boxes[startBoxIndex].blockIndex,
      startLetterIndex: startBox.blockStartIndex + startLetterIndex,
      endBlockIndex: boxes[endBoxIndex].blockIndex,
      endLetterIndex: endBox.blockStartIndex + endLetterIndex,
    });

    docState.current.cursor.boxIndex = startBoxIndex;
    docState.current.cursor.letterIndex = startLetterIndex;
    docState.current.endCursor.boxIndex = endBoxIndex;
    docState.current.endCursor.letterIndex = endLetterIndex;
  };

  const onCanvasMouseUp = e => {
    setIsMouseDown(false);
    const { boxes } = docState.current;
    const { boxIndex, letterIndex } = docState.current.cursor;
    const { boxIndex: endBoxIndex, letterIndex: endLetterBoxIndex } = docState.current.endCursor;

    const startBlockIndex = boxes?.[boxIndex]?.blockIndex;
    const startLetterIndex = boxes?.[boxIndex]?.blockStartIndex + letterIndex;
    const endBlockIndex = boxes?.[endBoxIndex]?.blockIndex ?? null;
    const endLetterIndex = boxes?.[endBoxIndex]?.blockStartIndex + endLetterBoxIndex || null;

    onChangeSelection({
      startBlockIndex,
      startLetterIndex,
      endBlockIndex,
      endLetterIndex,
    });
  };

  const onKeyDown = e => {
    if (e.key === "Tab") {
      e.preventDefault();

      const { boxIndex, letterIndex: boxLetterIndex } = docState.current.cursor;
      const blockIndex = docState.current.boxes?.[boxIndex]?.blockIndex;
      const letterIndex = docState.current.boxes?.[boxIndex]?.blockStartIndex + boxLetterIndex;
      onPressTab({ blockIndex, letterIndex });
      return;
    }

    if (e.metaKey || document.activeElement.tagName === "INPUT") {
      return;
    }
    e.preventDefault();
    setSourceModalY(null);

    let newBlocks = [...blocks];
    const { boxes } = docState.current;
    let { boxIndex, letterIndex } = docState.current.cursor;
    let { boxIndex: endBoxIndex, letterIndex: endLetterIndex } = docState.current.endCursor;

    if (e.key === "Backspace" && typeof endBoxIndex === "number") {
      [newBlocks, boxIndex, letterIndex] = deleteCharsInBlocksInRange({
        blocks,
        boxes,
        startBoxIndex: boxIndex,
        startLetterIndex: letterIndex,
        endBoxIndex,
        endLetterIndex,
      });
    }

    if (e.key === "Backspace" && endBoxIndex === null) {
      newBlocks = deleteCharAt({
        blocks,
        boxes,
        boxIndex,
        letterIndex,
      });
      letterIndex -= 1;
      if (letterIndex < 0) {
        boxIndex -= 1;
        letterIndex = Math.max(boxes?.[boxIndex]?.text?.length - 1, 0);
      }
    }

    if (e.key === "/" && boxes?.[boxIndex]?.text === "") {
      const { x, y } = getXandYForLineAndLetterIndex({
        ctx: cursorCanvasRef.current.getContext("2d"),
        boxes,
        boxIndex,
        letterIndex,
      });
      setInputPosition({ x, y });
      return;
    }

    // character key
    if (e.key.length === 1) {
      newBlocks = insertCharAt({
        blocks,
        boxes,
        boxIndex,
        letterIndex,
        char: e.key,
      });
      const newBoxes = getBoxes({
        blocks: newBlocks,
        ctx: contentCanvasRef.current?.getContext("2d"),
        pageTopY,
      });

      letterIndex += 1;
      const box = newBoxes?.[boxIndex];
      // TODO: add better logic for detecting when it's time to jump to next line
      if (letterIndex > box?.text?.length) {
        letterIndex = 1;
        boxIndex += 1;
      }
    }

    if (e.key === "Enter") {
      newBlocks = splitBlockAt({ blocks, boxes, boxIndex, letterIndex });
      letterIndex = 0;
      boxIndex += 1;
    }

    if (e.key === "ArrowUp") {
      boxIndex = Math.max(0, boxIndex - 1);
      letterIndex = Math.min(letterIndex, boxes?.[boxIndex]?.text?.length || 0);
    }
    if (e.key === "ArrowDown") {
      boxIndex = Math.min(boxes.length - 1, boxIndex + 1);
      letterIndex = Math.min(letterIndex, boxes?.[boxIndex]?.text?.length || 0);
    }
    if (e.key === "ArrowLeft") {
      letterIndex -= 1;
      if (letterIndex < 0) {
        boxIndex -= 1;
        letterIndex = boxes?.[boxIndex]?.text?.length || 0;
      }
    }
    if (e.key === "ArrowRight") {
      letterIndex += 1;
      if (letterIndex > boxes?.[boxIndex]?.text?.length) {
        boxIndex += 1;
        letterIndex = 0;
      }
    }

    if (ARROW_KEYS.includes(e.key)) {
      drawCursorAtLineAndLetterIndex({
        ctx: cursorCanvasRef.current.getContext("2d"),
        boxIndex,
        letterIndex,
        boxes: docState.current.boxes,
        cursorStrokeStyle,
        cursorLineWidth,
        cursorMsg,
      });
    }

    docState.current.cursor.letterIndex = letterIndex;
    docState.current.cursor.boxIndex = boxIndex;
    docState.current.endCursor.boxIndex = null;
    docState.current.endCursor.letterIndex = null;
    onChangeBlocks(newBlocks);

    const newBoxes = getBoxes({
      blocks: newBlocks,
      ctx: contentCanvasRef.current?.getContext("2d"),
      pageTopY,
    });
    onChangeSelection({
      startBlockIndex: newBoxes?.[boxIndex]?.blockIndex,
      startLetterIndex: newBoxes?.[boxIndex]?.blockStartIndex + letterIndex,
      endBlockIndex: null,
      endLetterIndex: null,
    });
  };

  const onPressEnterInCommandInput = () => {
    let newBlocks = [...blocks];
    const { boxes } = docState.current;
    let { boxIndex, letterIndex } = docState.current.cursor;

    newBlocks = insertTableBlockAt({ blocks, boxes, boxIndex, letterIndex });
    letterIndex = 0;
    boxIndex += 1;

    setInputPosition({ x: null, y: null });

    docState.current.cursor.letterIndex = letterIndex;
    docState.current.cursor.boxIndex = boxIndex;
    onChangeBlocks(newBlocks);
  };

  return (
    <CanvasContainer
      viewportHeight={canvasSize?.height}
      ref={canvasContainerRef}
      onScroll={e => {
        setPageTopY(-e.target.scrollTop);
        const { y } = getXandYForLineAndLetterIndex({
          ctx: cursorCanvasRef.current.getContext("2d"),
          boxes: docState.current.boxes,
          boxIndex: docState.current.cursor.boxIndex,
          letterIndex: docState.current.cursor.letterIndex,
        });
        setSourceModalY(y);
      }}
      onMouseDown={onCanvasMouseDown}
      onMouseMove={onCanvasMouseMove}
      onMouseUp={onCanvasMouseUp}
      topOffset={topOffset}
    >
      <TallEmptyDiv />
      <ContentCanvas
        ref={contentCanvasRef}
        viewportWidth={canvasSize?.width}
        viewportHeight={canvasSize?.height}
        width={canvasSize?.width * SF || 0}
        height={canvasSize?.height * SF || 0}
        topOffset={topOffset}
      />
      <CursorCanvas
        ref={cursorCanvasRef}
        viewportWidth={canvasSize?.width}
        viewportHeight={canvasSize?.height}
        width={canvasSize?.width * SF || 0}
        height={canvasSize?.height * SF || 0}
        topOffset={topOffset}
      />
      {/* {sourceModalMeta && sourceModalY !== null && !sourceModalMeta?.isUserLabel && (
        <ReferenceCard
          sourceModalMeta={sourceModalMeta}
          style={{
            position: "fixed",
            zIndex: 2,
            left: START_X + PAGE_WIDTH_PX + 140,
            top: topOffset + sourceModalY,
          }}
          isSavingApproval={isSavingApproval}
          onClickFile={() => setIsSourceModalOpen(true)}
          onClickVerify={e => {
            e.stopPropagation();
            const { blockIndex, styleIndex } = sourceModalMetaLocation;
            const newBlocks = [...blocks];
            newBlocks[blockIndex].styles[styleIndex].meta.isApproved = true;
            onPressApproveOrReject(newBlocks);
            setSourceModalMeta({ ...sourceModalMeta, isApproved: true });
          }}
          onClickUnverify={e => {
            e.stopPropagation();
            const { blockIndex, styleIndex } = sourceModalMetaLocation;
            const newBlocks = [...blocks];
            newBlocks[blockIndex].styles[styleIndex].meta.isApproved = false;
            onPressApproveOrReject(newBlocks);
            setSourceModalMeta({
              ...sourceModalMeta,
              isApproved: false,
            });
          }}
        />
      )} */}
      {/* {sourceModalMeta && sourceModalY !== null && sourceModalMeta?.isUserLabel && (
        <ReferenceCardUser
          sourceModalMeta={sourceModalMeta}
          style={{
            position: "fixed",
            zIndex: 2,
            left: START_X + PAGE_WIDTH_PX + 140,
            top: topOffset + sourceModalY,
          }}
          onClickDelete={e => {
            e.stopPropagation();
            const metaId = sourceModalMeta?.id;
            const { blockIndex } = sourceModalMetaLocation;
            const newBlocks = cloneDeep(blocks);

            const stylesWithoutMeta = newBlocks[blockIndex].styles.map(style => {
              if (style?.meta?.id === metaId) {
                return {
                  ...style,
                  meta: null,
                };
              }
              return style;
            });
            newBlocks[blockIndex].styles = stylesWithoutMeta;
            setSourceModalMeta(null);
            onChangeBlocks(newBlocks);
          }}
        />
      )} */}
      {/* {inputPosition.x !== null && inputPosition.y !== null && (
        <StyledSearchInput
          placeholder=""
          style={{
            position: "fixed",
            outline: "blue",
            zIndex: 1,
            border: "none",
            width: "300px",
            left: inputPosition?.x,
            top: topOffset + inputPosition?.y,
          }}
          dropdownOptionStyle={{
            padding: "4px",
            paddingLeft: "8px",
          }}
          borderWidth={1}
          bgColor="white"
          autoFocus
          onPressEnter={onPressEnterInCommandInput}
          recommendationType="excel"
        />
      )}
      <PagePreviewTextAndTableModal
        open={isSourceModalOpen}
        handleClose={() => setIsSourceModalOpen(false)}
        tableDocumentLocation={{
          fileId: sourceModalMeta?.fileId,
          pageNumber: sourceModalMeta?.pageNumber,
          fileName: sourceModalMeta?.fileName,
        }}
      /> */}
    </CanvasContainer>
  );
};

export default WordDoc;
