import styled from "styled-components";
import { isEmpty } from "lodash";
import TrModal from "components/TrModal";
import { Gap } from "components/Layout";
import { useState } from "react";
import { useEffect } from "react";
import { sleep } from "utils/common";
import { Save } from "@material-ui/icons";

const getString = value => {
  if (Array.isArray(value)) {
    return value.join(" ");
  }
  return value;
};

const columnNameToDisplayName = {
  context: "Context",
  prompt: "Prompt",
  generatedText: "Target Output",
  "generatedText (predicted)": "GPT Output",
};

const disp = value => {
  return columnNameToDisplayName[value] || value;
};

const Table = styled.table`
  table-layout: fixed;
`;

const Container = styled.div`
  overflow-x: scroll;
`;

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 20px 14px;
  border-bottom: 1px solid ${props => props.theme.color.darkGrey};
`;

const Td = styled.td`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${props => (props.isNull ? props.theme.color.closer1 : props.theme.color.closest)};
  padding: 14px 14px;
  border-bottom: 1px solid ${props => props.theme.color.closest};
`;

const StyledTrModal = styled(TrModal)`
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ContextCard = styled.textarea`
  padding: 10px;
  max-width: 80%;
  background-color: ${props => props.theme.color.closer1};
  white-space: pre-wrap;
  line-height: 1.2;
  overflow-y: auto;
  height: 200px;

  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.closer0};
  outline: none;
  font-family: "Montserrat", sans-serif;
  resize: none;
  width: 100%;

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const PreviewSectionTitle = styled.div`
  border-top: 1px solid lightgrey;
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  padding: 8px 0;
`;

const StyledInput = styled.textarea`
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0;
  background-color: ${props => props.theme.color.furthest};
  outline: none;
  font-family: "Montserrat", sans-serif;
  resize: none;
  width: 100%;

  :hover {
    opacity: 0.6;
  }

  :focus {
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const IconButton = styled.div`
  cursor: pointer;
  :hover {
    color: ${props => props.theme.color.primary};
  }
  ${props => props.disabled && "pointer-events: none; opacity: 0.5;"}
`;

const DataPointModal = ({ initialDataPoint, rowIndex, columnNames, setDatasetPreview = () => {} }) => {
  const [dataPoint, setDataPoint] = useState(initialDataPoint);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setDataPoint(initialDataPoint);
  }, [JSON.stringify(initialDataPoint)]);

  const dataPointContext = getString(dataPoint?.[columnNames?.[0]]);
  const dataPointPrompt = getString(dataPoint?.[columnNames?.[1]]);
  const dataPointGeneratedText = getString(dataPoint?.[columnNames?.[2]]);

  return (
    <StyledTrModal
      key={rowIndex}
      modalContent={
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <PreviewSectionTitle style={{ marginTop: "-10px", borderTop: "none" }}>Context</PreviewSectionTitle>
            <IconButton
              disabled={isSaving}
              onClick={async () => {
                setIsSaving(true);
                await sleep(200);
                setDatasetPreview(prev =>
                  prev?.map((point, i) => {
                    if (i === rowIndex) {
                      return dataPoint;
                    }
                    return point;
                  })
                );
                setIsSaving(false);
              }}
            >
              <Save />
            </IconButton>
          </div>
          <ContextCard
            value={dataPointContext}
            onChange={e => {
              setDataPoint({ ...dataPoint, [columnNames[0]]: e.target.value });
            }}
          />
          <Gap height="40px" />
          <PreviewSectionTitle>Prompt</PreviewSectionTitle>
          <StyledInput
            value={dataPointPrompt}
            onChange={e => {
              setDataPoint({ ...dataPoint, [columnNames[1]]: e.target.value });
            }}
          />
          <Gap height="40px" />
          <PreviewSectionTitle>Output</PreviewSectionTitle>
          <StyledInput
            style={{ height: "300px" }}
            value={dataPointGeneratedText}
            onChange={e => {
              setDataPoint({ ...dataPoint, [columnNames[2]]: e.target.value });
            }}
          />
        </div>
      }
    >
      {columnNames.map((columnName, colIndex) => {
        const val = Array.isArray(initialDataPoint[columnName])
          ? initialDataPoint[columnName].join(" ")
          : initialDataPoint[columnName];
        return (
          <Td
            isNull={val === null}
            key={`${rowIndex}-${val}-${colIndex}`}
            style={{ width: `${100 / columnNames.length}%` }}
          >
            {val === null ? "null" : val}
          </Td>
        );
      })}
    </StyledTrModal>
  );
};

const TabularDatasetPreview = ({
  columns,
  datasetPreview,
  blacklistColumns,
  noHeader,
  setDatasetPreview = () => {},
}) => {
  if (isEmpty(datasetPreview)) {
    return "No results !";
  }

  const columnBlacklist = blacklistColumns || [];

  const pointWithMostColumns = datasetPreview.reduce((prevPoint, currentPoint) => {
    return Object.keys(currentPoint).length >= Object.keys(prevPoint).length ? currentPoint : prevPoint;
  });
  const rawColumnNames = columns || Object.keys(pointWithMostColumns);
  const columnNames = rawColumnNames.filter(e => !columnBlacklist.includes(e));

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            {!isEmpty(columnNames) &&
              columnNames.map(columnName => <Th key={columnName}>{noHeader ? "" : disp(columnName)}</Th>)}
          </tr>
        </thead>
        <tbody>
          {datasetPreview?.map((dataPoint, rowIndex) => {
            return (
              <DataPointModal
                initialDataPoint={dataPoint}
                rowIndex={rowIndex}
                columnNames={columnNames}
                setDatasetPreview={setDatasetPreview}
              />
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default TabularDatasetPreview;
