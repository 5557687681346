import { CenteredWithTopNavLayoutInternal } from "components/PackageLayout";
import PackagesDashboardView from "components/views/PackagesDashboardView";
import { getLoggedInUserName } from "api/services/authenticationService";
import { Link } from "react-router-dom";

const PackagesDashboardPage = () => {
  const userName = getLoggedInUserName();

  if (!userName) {
    return (
      <div>
        Please log in first to see the content. <Link to={"/login"}>Click here to login.</Link>
      </div>
    );
  }

  return (
    <CenteredWithTopNavLayoutInternal>
      <PackagesDashboardView />
    </CenteredWithTopNavLayoutInternal>
  );
};

export default PackagesDashboardPage;
