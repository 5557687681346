import { useState, useEffect } from "react";
import { getFirstDeploymentClusterOfTrainingJobs } from "api/services/deploymentService";

const usePollDeploymentClusterNonStop = trainingJobIds => {
  const [deploymentCluster, setDeploymentCluster] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let pollIntervalId = null;
    const doStartPolling = async () => {
      pollIntervalId = setInterval(doPopulateDeploymentCluster, 4000);
      doPopulateDeploymentCluster();
    };

    doStartPolling();

    return () => clearInterval(pollIntervalId);
  }, [JSON.stringify(trainingJobIds)]);

  const doPopulateDeploymentCluster = async () => {
    const { data: deploymentCluster, error } = await getFirstDeploymentClusterOfTrainingJobs(trainingJobIds);
    setError(error);
    setDeploymentCluster(deploymentCluster);
  };

  return [deploymentCluster, error];
};

export default usePollDeploymentClusterNonStop;
