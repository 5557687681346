import { Routes, Route } from "react-router-dom";

import HomePage from "pages";
import UserPage from "pages/:userName";
import LoginPage from "pages/login";
import DatasetPage from "pages/data/:datasetId";
import DataListPage from "pages/data";
import CubeListPage from "pages/cubes";
import CreateDataPage from "pages/data/create";
import CreateModelPage from "pages/model/create";
import ModelPage from "pages/model/:modelId";
import CubePage from "pages/cube/:cubeId";
import TutorialPage from "pages/tutorial/TutorialPage";
import TutorialsListPage from "pages/tutorial/TutorialListPage";
import AccountPage from "pages/:userName/account";
import BillingPage from "pages/:userName/billing";
import DocumentationPage from "pages/documentation";
import CreateCubePage from "pages/cube/create";
import CreatePipelineView from "components/views/CreatePipelineView";
import RegistrationPage from "pages/registration";
import DesignSystemPage from "pages/design-system";
import AllJobsPage from "pages/all-jobs";
import ApplicationListPage from "pages/applications";
import ApplicationPage from "pages/applications/:applicationId";
import PaymentPage from "pages/payment";
import SequenceView from "components/views/DeploymentTabViewNoTasks/SequenceView";
import SettingsPage from "pages/settings";
import OauthCallBack from "components/views/OauthCallBack/index.js";
import OauthCallBackTest from "components/views/OauthCallBack/OauthCallBackTest";
import SurveysListPage from "pages/surveys";
import SurveyPage from "pages/survey/:surveyId";
import CreateSurveyPage from "pages/survey/create";
import SurveySubmitResponsePage from "pages/survey/:surveyId/submit-response";
import OcrAnnotationTaskListPage from "pages/ocr-annotation-tasks";
import OcrAnnotationTaskPage from "pages/ocr-annotation-tasks/:ocrAnnotationTaskId";
import ImageAnnotationTaskListPage from "pages/image-annotation-tasks";
import ImageAnnotationTaskPage from "pages/image-annotation-task/:imageAnnotationTaskId";
import TextAnnotationTaskListPage from "pages/text-annotation-tasks";
import TextAnnotationTaskPage from "pages/text-annotation-task/:textAnnotationTaskId";
import SentenceLabelingTaskListPage from "pages/sentence-labeling-tasks/index.js";
import SentenceLabelingTaskPage from "pages/sentence-labeling-tasks/:sentenceLabelingTaskId.js";
import MarketPlacePage from "pages/market-place/index.js";
import MarketPlaceAppPage from "pages/market-place/:appName/index.js";
import DemosPage from "pages/demos";
import CreatePackagePage from "pages/create-package";
import PackagesDashboardPage from "pages/packages-dashboard";
import PackageTableSearchPage from "pages/packages-search";
import PackagePage from "pages/package/:packageId";
import RCPackagePage from "pages/rc-package/:packageId";
import FilePage from "pages/file/:fileId";
import CalendarMeetingRequestPage from "pages/market-place/calendar/CalendarMeetingRequestPage";
import BuildDatasetPage from "pages/data/build-dataset";
import ImageLabellingTaskListPage from "pages/data/build-dataset/image-labelling-tasks";
import ImageLabellingTaskPage from "pages/data/build-dataset/image-labelling-task/:imageLabellingTaskId";
import ComponentsPage from "pages/components";
import FlowPage from "pages/flow";

const PageComponentOnRoute = () => (
  <Routes>
    <Route path="/deployments/forecast" element={<SequenceView />} />
    <Route path="/create-pipeline" element={<CreatePipelineView />} />
    <Route path="/" element={<HomePage />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/data" element={<DataListPage />} />
    <Route path="/data/:datasetId" element={<DatasetPage />} />
    <Route path="/data/create/:source" element={<CreateDataPage />} />
    <Route path="/data/create" element={<CreateDataPage />} />
    <Route path="/data/build-dataset" element={<BuildDatasetPage />} />
    <Route path="/oauth-callback-test" element={<OauthCallBackTest />} />
    <Route path="/oauth-callback/:source" element={<OauthCallBack />} />
    <Route path="/model/:modelId" element={<ModelPage />} />
    <Route path="/model/create" element={<CreateModelPage />} />
    <Route path="/cubes" element={<CubeListPage />} />
    <Route path="/cube/:cubeId" element={<CubePage />} />
    <Route path="/cube/create" element={<CreateCubePage />} />
    <Route path="/tutorials" element={<TutorialsListPage />} />
    <Route path="/tutorial_page" element={<TutorialPage />} />
    <Route path="/tutorial/:tutorialId" element={<TutorialPage />} />
    <Route path="/cubes" element={<CubeListPage />} />
    <Route path="/applications" element={<ApplicationListPage />} />
    <Route path="/applications/:applicationId" element={<ApplicationPage />} />
    <Route path="/market-place" element={<MarketPlacePage />} />
    <Route path="/market-place/:appName" element={<MarketPlaceAppPage />} />
    <Route path="/market-place/calendar/meeting-request" element={<CalendarMeetingRequestPage />} />
    <Route path="/settings" element={<SettingsPage />} />

    <Route path="/demos" element={<DemosPage />} />

    <Route path="/surveys" element={<SurveysListPage />} />
    <Route path="/survey/:surveyId" element={<SurveyPage />} />
    <Route path="/survey/create" element={<CreateSurveyPage />} />
    <Route path="/survey/:surveyId/submit-response" element={<SurveySubmitResponsePage />} />

    <Route path="/sentence-labeling-tasks" element={<SentenceLabelingTaskListPage />} />
    <Route path="/sentence-labeling-task/:taskId" element={<SentenceLabelingTaskPage />} />

    <Route path="/login" element={<LoginPage />} />
    <Route path="/reg" element={<RegistrationPage />} />
    <Route path="/404" element={"not found"} />
    <Route path=":userName" element={<UserPage />} />
    <Route path=":userName/account" element={<AccountPage />} />
    <Route path=":userName/billing" element={<BillingPage />} />
    <Route path=":userName/payment" element={<PaymentPage />} />

    <Route path="/design-system" element={<DesignSystemPage />} />
    <Route path="/all-jobs" element={<AllJobsPage />} />
    <Route path="/components" element={<ComponentsPage />} />

    <Route path="/ocr-annotation-tasks" element={<OcrAnnotationTaskListPage />} />
    <Route path="/ocr-annotation-tasks/:ocrAnnotationTaskId" element={<OcrAnnotationTaskPage />} />

    <Route path="/data/build-dataset/image-labelling-tasks" element={<ImageLabellingTaskListPage />} />
    <Route
      path="/data/build-dataset/image-labelling-tasks/:imageLabellingTaskId"
      element={<ImageLabellingTaskPage />}
    />

    <Route path="/image-annotation-tasks" element={<ImageAnnotationTaskListPage />} />
    <Route path="/image-annotation-task/:imageAnnotationTaskId" element={<ImageAnnotationTaskPage />} />

    <Route path="/text-annotation-tasks" element={<TextAnnotationTaskListPage />} />
    <Route path="/text-annotation-task/:textAnnotationTaskId" element={<TextAnnotationTaskPage />} />

    <Route path="/login" element={<LoginPage />} />
    <Route path="/documentation" element={<DocumentationPage />} />
    <Route path="/404" element={"not found"} />

    <Route path="/create-package" element={<CreatePackagePage />} />
    <Route path="/packages-dashboard" element={<PackagesDashboardPage />} />
    <Route path="/packages-search" element={<PackageTableSearchPage />} />
    <Route path="/package/:packageId" element={<PackagePage />} />
    <Route path="/rc-package/:packageId" element={<RCPackagePage />} />
    <Route path="/file/:fileId" element={<FilePage />} />
    <Route path="/flow" element={<FlowPage />} />
  </Routes>
);

export default PageComponentOnRoute;
