import styled from "styled-components";
import { useState } from "react";

import ApiDesignPool from "components/views/DeploymentTabViewNoTasks/CsvView/ApiDesignPool";
import { ApiConfigCard } from "components/ui/cards";
import { Link } from "react-router-dom";
import { postSolution, patchSolution, deleteApiConfig } from "api/services/projectService";
import { Gap } from "components/Layout";
import Modal from "components/ui/Modal";
import CustomCodeMirror from "components/ui/CustomCodeMirror";

const Container = styled.div``;

const ApiConfigs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  padding: 10px;
`;

const ModalContent = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  ${props => props.greyBground && `background-color: ${props.theme.color.closer0};`};
  padding: 20px;
  width: 500px;
`;

const SolutionLink = styled(Link)`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.primary};
  padding: 10px;
  padding-bottom: 20px;
`;

const NoSolutionText = styled.div`
  display: block;
  font-size: 14px;
  padding: 10px;
  padding-bottom: 20px;
`;

const CsvViewApiDesign = ({ pipelineOutput, deploymentClusterId, cubeName }) => {
  const [inputRow, setInputRow] = useState({});
  const [inputMaskedCols, setInputMaskedCols] = useState([]);

  const [apiConfigs, setApiConfigs] = useState(pipelineOutput?.apiConfigs || []);
  const [solution, setSolution] = useState(pipelineOutput?.solutionApi || "");
  const [error, setError] = useState(null);

  const [apiConfigToView, setApiConfigToView] = useState(null);
  const [showApiModal, setShowApiModal] = useState(false);

  const removeApiConfigFromSolution = async apiConfigId => {
    const filteredApiConfigs = solution.apiConfigIds.filter(e => e !== apiConfigId);
    const { data: updatedSolution, error } = await patchSolution(solution.id, {
      apiConfigIds: filteredApiConfigs,
    });
    setError(error);
    setSolution(updatedSolution);
  };

  const removeApiConfigFromPipeline = async apiConfig => {
    const { data, error } = await deleteApiConfig(apiConfig.id);
    return { data, error };
  };

  return (
    <Container>
      {apiConfigToView && (
        <Modal handleClose={() => setShowApiModal(false)} open={showApiModal} title={apiConfigToView?.name}>
          <ModalContent>
            <CustomCodeMirror
              value={JSON.stringify(apiConfigToView?.config, null, 4)}
              height="300px"
              showLineNumber={false}
            />
            <Gap />
          </ModalContent>
        </Modal>
      )}
      <ApiDesignPool
        inputRow={inputRow}
        onSetInputRow={newRow => setInputRow(newRow)}
        maskedCols={inputMaskedCols}
        onSetInputMaskedCols={newMaskedCols => setInputMaskedCols(newMaskedCols)}
        deploymentClusterId={deploymentClusterId}
        onAddApiConfig={apiConfig => setApiConfigs([...apiConfigs, apiConfig])}
        pipelineId={pipelineOutput.pipelineId}
      />
      {solution?.id ? (
        <SolutionLink to={`/applications/${solution?.id}`}>{solution?.name}</SolutionLink>
      ) : (
        <NoSolutionText>Add an api config to create a solution</NoSolutionText>
      )}
      <ApiConfigs>
        {apiConfigs.map(apiConfig => (
          <ApiConfigCard
            key={apiConfig.id}
            apiConfig={apiConfig}
            isPartOfSolution={solution?.apiConfigIds?.includes(apiConfig.id)}
            onClickAddApiConfigToSolution={async () => {
              if (solution?.id) {
                const { data: updatedSolution, error } = await patchSolution(solution.id, {
                  apiConfigIds: [...solution.apiConfigIds, apiConfig.id],
                });
                setError(error);
                setSolution(updatedSolution);
                return;
              }
              const { data: createdSolution, error } = await postSolution({
                name: `A solution for cube ${cubeName}`,
                deploymentClusterId,
                pipelineId: pipelineOutput?.pipelineId,
                apiConfigIds: [apiConfig.id],
              });
              setError(error);
              setSolution(createdSolution);
            }}
            onClickRemoveApiConfigFromSolution={() => {
              removeApiConfigFromSolution(apiConfig.id);
            }}
            onCrossBtn={async () => {
              await removeApiConfigFromSolution(apiConfig.id);
              const { error } = await removeApiConfigFromPipeline(apiConfig);
              if (!error) {
                const filteredApiConfigs = apiConfigs.filter(e => e.id !== apiConfig.id);
                setApiConfigs(filteredApiConfigs);
              }
            }}
            onVisibilityBtn={() => {
              setApiConfigToView(apiConfig);
              setShowApiModal(true);
            }}
          />
        ))}
      </ApiConfigs>
      {error && <div>{JSON.stringify(error)}</div>}
    </Container>
  );
};

export default CsvViewApiDesign;
