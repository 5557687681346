import styled from "styled-components";

import TextField from "@material-ui/core/TextField";

const Form = styled.form`
  padding: 0 8px;
  label {
    color: ${props => props.theme.color.closest} !important;
  }
  .MuiInputBase-input.MuiInput-input {
    color: ${props => props.theme.color.closest};
  }
  .MuiInput-underline:after {
    border-color: ${props => props.theme.color.closest} !important;
  }
  .MuiInput-underline:before {
    border-color: ${props => props.theme.color.closest} !important;
  }
`;

const DateTimePicker = ({ label, dateTime, handleChange }) => (
  <Form noValidate>
    <TextField
      id={label}
      label={label}
      type="datetime-local"
      value={dateTime}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={e => handleChange(e.target.value)}
    />
  </Form>
);

export default DateTimePicker;
