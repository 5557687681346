import { useState, useEffect } from "react";
import { getDeployments, postDeployment } from "api/services/deploymentService";
import { shutDownADeploymentCluster, turnOnADeploymentCluster } from "api/services/deploymentService";

const usePollDeploymentCluster = modelId => {
  const [deploymentCluster, setDeploymentCluster] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    doStartPolling();
  }, [modelId]);

  useEffect(() => {
    if (!deploymentCluster?.readyToUse && deploymentCluster?.instanceCount > 0 && !error) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
    clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [deploymentCluster, error]);

  const doStartPolling = async () => {
    clearInterval(intervalId);
    const pollIntervalId = setInterval(doPopulateDeploymentCluster, 2000);
    setIntervalId(pollIntervalId);
    doPopulateDeploymentCluster();
  };

  const doPopulateDeploymentCluster = async () => {
    const { data: deployments, error } = await getDeployments({ modelIds: [modelId] });
    setError(error);
    setDeploymentCluster(deployments?.[0]);
  };

  const doTurnOn = async () => {
    setIsLoading(true);
    const { error } = await turnOnADeploymentCluster(deploymentCluster.id);
    setError(error);
    doStartPolling();
  };

  const doTurnOff = async () => {
    setIsLoading(true);
    const { error } = await shutDownADeploymentCluster(deploymentCluster.id);
    setError(error);
    doStartPolling();
  };

  const doCreate = async () => {
    setIsLoading(true);
    const { error } = await postDeployment({ modelId, instanceCount: 1 });
    setError(error);
    doStartPolling();
  };

  return [deploymentCluster, isLoading, error, doCreate, doTurnOn, doTurnOff];
};

export default usePollDeploymentCluster;
