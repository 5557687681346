import styled from "styled-components";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Close, Visibility } from "@material-ui/icons";
import { getUserProfileByUserName } from "api/services/projectService";

import { DatabaseIcon, DatasetIcon, ModelIcon } from "components/ui/Icons";
import { Gap } from "components/Layout";
import UserBadge from "components/ui/UserBadge";
import CreateCubeButton from "components/widgets/CreateCubeButton";
import Button from "components/ui/Button";
import ProgressBar from "components/ui/ProgressBar";

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const SubTitle = styled.div`
  font-size: 11px;
  color: ${props => props.theme.color.closest};
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const CardContainer = styled.div`
  min-height: 120px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  /* background: linear-gradient(
    90deg,
    ${props => props.theme.color.closer0} 0%,
    ${props => props.theme.color.closer0} 100%
  ); */
  background-color: ${props => props.theme.color.closer0};

  :hover {
    background: none;
    background-color: ${props => props.theme.color.closer1};
    ${Title} {
      color: ${props => props.theme.color.primary};
    }

    ${CardLink} {
      color: ${props => props.theme.color.primary};
    }
  }

  .MuiCircularProgress-root {
    color: ${props => props.theme.color.primary};
  }
`;

const ApiCardContainer = styled(CardContainer)`
  background: ${props => (props.isPartOfSolution ? props.theme.color.success : props.theme.color.furthest)};
  :hover {
    svg {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const ApiCardTitle = styled(Title)`
  padding-bottom: 0;
`;

const IconAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const SmallButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 2px 20px;
`;

const ApiConfigContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ApiConfigSubTitle = styled(SubTitle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseButtonContainer = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
  border-radius: 20px;
  margin-left: 5px;
  padding: 1px;
  :hover {
    background: ${props => props.theme.color.furthest};
  }
`;

const TitleAndButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
`;

const useCreatedByAvatarSrc = createdByUserName => {
  const [createdByAvatar, setCreatedByAvatar] = useState(null);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [createdByUserName]);

  const doFetchCreatorAvatar = async () => {
    const { data } = await getUserProfileByUserName(createdByUserName);
    setCreatedByAvatar(data?.image.includes("/images/") ? data?.image : `data:image/png;base64,${data?.image}`);
  };

  return createdByAvatar;
};

export const ApiConfigCard = ({
  apiConfig,
  onClickAddApiConfigToSolution,
  onClickRemoveApiConfigFromSolution,
  onCrossBtn,
  onVisibilityBtn,
  isPartOfSolution,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <ApiCardContainer isPartOfSolution={isPartOfSolution} isDisabled={isLoading}>
      <ApiConfigContent>
        <TitleAndButtons>
          <ApiCardTitle>{apiConfig.name}</ApiCardTitle>
          <CloseButtonContainer onClick={onVisibilityBtn}>
            <Visibility />
          </CloseButtonContainer>
          <CloseButtonContainer
            onClick={async () => {
              setIsLoading(true);
              await onCrossBtn();
              setIsLoading(false);
            }}
          >
            <Close />
          </CloseButtonContainer>
        </TitleAndButtons>
        <ApiConfigSubTitle>{`predict ${apiConfig?.config?.outputs?.join(", ")}`}</ApiConfigSubTitle>
        {isPartOfSolution ? (
          <SmallButton
            value="Remove from solution"
            onClick={async () => {
              setIsLoading(true);
              await onClickRemoveApiConfigFromSolution();
              setIsLoading(false);
            }}
          />
        ) : (
          <SmallButton
            value="Add to solution"
            onClick={async () => {
              setIsLoading(true);
              await onClickAddApiConfigToSolution();
              setIsLoading(false);
            }}
          />
        )}
      </ApiConfigContent>
    </ApiCardContainer>
  );
};

const CardButtons = styled.div`
  display: grid;
  gap: 5px;
  justify-items: end;
  padding-top: 5px;
  grid-template-columns: auto auto;
`;

export const DatasetCard = ({ title, subTitle, createdByUserName, datasetId = "" }) => {
  const createdByAvatarSrc = useCreatedByAvatarSrc(createdByUserName);

  return (
    <CardContainer isLoading={false} type="DATASET">
      <div>
        <Title>
          <CardLink to={`/data/${datasetId}`}>{title}</CardLink>
        </Title>
        <SubTitle>{subTitle}</SubTitle>
        <Gap height="10px" />
        {createdByUserName && <UserBadge userName={createdByUserName} userAvatar={createdByAvatarSrc} />}
      </div>
      <IconAndButton>
        <DatasetIcon height="25px" />
        {!createdByUserName && <Gap height="10px" />}
        <CardButtons>
          <CreateCubeButton datasetId={datasetId} datasetTitle={title} />
        </CardButtons>
      </IconAndButton>
    </CardContainer>
  );
};

export const ModelCard = ({ title, subTitle, createdByUserName, modelId = "" }) => {
  const createdByAvatarSrc = useCreatedByAvatarSrc(createdByUserName);

  return (
    <CardContainer>
      <div>
        <Title>
          <CardLink to={`/model/${modelId}`}>{title}</CardLink>
        </Title>
        <SubTitle>{subTitle}</SubTitle>
        {!createdByUserName && <Gap height="10px" />}
        {createdByUserName && <UserBadge userName={createdByUserName} userAvatar={createdByAvatarSrc} />}
      </div>
      <ModelIcon height="25px" />
    </CardContainer>
  );
};

const PackageTypeBadge = styled.div`
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.closer1};
`;

const ProgressContainer = styled.div`
  width: 100px;
  height: 20px;
`;

export const PackageCard = ({ title, subTitle, createdByUserName, packageId = "", type = "package" }) => {
  const createdByAvatarSrc = useCreatedByAvatarSrc(createdByUserName);

  return (
    <CardContainer>
      <div>
        <Title>
          <CardLink to={`/package/${packageId}`}>{title}</CardLink>
        </Title>
        <SubTitle>{subTitle}</SubTitle>
        {!createdByUserName && <Gap height="10px" />}
        {createdByUserName && <UserBadge userName={createdByUserName} userAvatar={createdByAvatarSrc} />}
      </div>
      <PackageTypeBadge>{type}</PackageTypeBadge>
    </CardContainer>
  );
};

export const SimplePackageCard = ({ title, packageId = "", type = "package", isReady }) => {
  return (
    <CardContainer>
      <div>
        <Title isDisabled={!isReady}>
          <CardLink to={`/package/${packageId}`}>{title}</CardLink>
        </Title>
        <ProgressContainer>{!isReady && <ProgressBar currentValue={50} maxValue={100} />}</ProgressContainer>
      </div>
      <PackageTypeBadge>{type}</PackageTypeBadge>
    </CardContainer>
  );
};
