import { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";

import { Gap } from "components/Layout";
import { getUserActivitiesOfUserGroupedByDay } from "api/services/projectService";
import { CubeIcon, ModelIcon, DatasetIcon } from "components/ui/Icons";
import ActivityDaysGrid from "components/widgets/ActivityDaysGrid";
import Button from "components/ui/Button";

const Activity = styled.div`
  padding-bottom: 10px;
`;

const UserName = styled.span`
  font-weight: 600;
`;

const Resource = styled.span`
  font-weight: 600;
`;

const ActivityDaysContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ActivitiesOnDay = styled.div`
  margin-left: 30px;
  padding-left: 20px;
  border-left: 2px solid;
  border-image: linear-gradient(to top, ${props => props.theme.color.feature}, ${props => props.theme.color.primary}) 1;

  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const DayTitle = styled.div`
  font-size: 16px;
  padding-top: 10px;
  padding-bottom: 20px;
`;

const TargetAnchor = styled(ReactRouterLink)`
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.color.primary};
`;

const ActivityAndLink = styled.div``;

const TargetLink = ({ name, type, url }) => {
  if (!name) {
    return null;
  }

  let icon = null;
  if (type === "DATASET") {
    icon = <DatasetIcon height="20px" />;
  }
  if (type === "CUBE") {
    icon = <CubeIcon width="20px" />;
  }
  if (type === "MODEL") {
    icon = <ModelIcon width="20px" />;
  }

  return (
    <TargetAnchor to={url}>
      {icon}
      {name}
    </TargetAnchor>
  );
};

const ActivitiesOfUser = ({ userName, showSeeAllLink, initialNumItems = 5 }) => {
  const [activitiesByDay, setActivitiesByDay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [lastVisibleIndex, setLastVisibleIndex] = useState(initialNumItems);

  const doFetchActivities = async () => {
    const { data, error } = await getUserActivitiesOfUserGroupedByDay(userName);
    setActivitiesByDay(data);
    setError(error);
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchActivities();
  }, [userName]);

  if (isLoading) {
    return "Loading activities...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  const orderedActivityDays = Object.keys(activitiesByDay).sort((day1, day2) => {
    if (new Date(day1) < new Date(day2)) {
      return 1;
    }
    return -1;
  });
  const areThereMoreActivitiesToShow = lastVisibleIndex <= Object.values(activitiesByDay).flat().length;

  let itemIndex = 0;
  return (
    <>
      <ActivityDaysGrid activitiesByDay={activitiesByDay} />
      <Gap />
      <ActivityDaysContainer>
        {orderedActivityDays.map(day => {
          const activities = activitiesByDay[day];
          itemIndex += 1;
          if (itemIndex >= lastVisibleIndex) {
            return null;
          }
          return (
            <div key={day}>
              <DayTitle>{day}</DayTitle>
              <ActivitiesOnDay>
                {activities.map(activity => {
                  itemIndex += 1;
                  if (itemIndex >= lastVisibleIndex) {
                    return null;
                  }
                  return (
                    <ActivityAndLink key={`${activity.timestamp}-${activity.action}-${activity.targetId}`}>
                      <Activity>
                        <UserName>{userName}</UserName>
                        &nbsp;{activity.action.toLowerCase()} a&nbsp;
                        <Resource>{activity.targetType.toLowerCase()}</Resource>&nbsp;
                      </Activity>
                      <TargetLink name={activity.targetName} type={activity.targetType} url={activity.targetUrl} />
                    </ActivityAndLink>
                  );
                })}
              </ActivitiesOnDay>
            </div>
          );
        })}
      </ActivityDaysContainer>
      <Gap />
      {showSeeAllLink && <Button value="See all" onClick={() => (window.location = `/${userName}?tab=Activities`)} />}
      {areThereMoreActivitiesToShow && !showSeeAllLink && (
        <Button value="See more" onClick={() => setLastVisibleIndex(lastVisibleIndex + 5)} />
      )}
    </>
  );
};

export default ActivitiesOfUser;
