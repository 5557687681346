import { useState, useEffect } from "react";
import styled from "styled-components";
import { round } from "lodash";
import { getEvaluationJobsByEvalJobIds } from "api/services/jobService";
import MultiExpandableTaskEvaluation from "components/views/EvaluationView/MultiExpandableTaskEvaluation";

const Container = styled.div`
  padding: 20px;
`;

const TrainingMetricsContainer = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
  width: max-content;
`;

const PanelTitle = styled.div`
  padding: 20px 20px 20px 0;
  font-size: 20px;
  font-weight: bold;
`;

const TaskEvaluationsContainer = styled.div``;

const TrainingMetricName = styled.td`
  font-weight: bold;
  padding-bottom: 10px;
`;

const TrainingMetricValue = styled.td`
  padding: 0 10px;
`;

const PipelineId = styled.td`
  font-weight: bold;
  padding: 0 10px;
  padding-bottom: 10px;
`;

const Gap = styled.div`
  height: 40px;
`;

const getFormattedPipelineId = pipeline => `${pipeline.pipelineId.slice(0, 3)}...${pipeline.pipelineId.slice(-3)}`;

const getTrainingMetricValuesFromEvalJobs = (metricName, evalJobs) => {
  return evalJobs.map(evalJob => {
    let metricValue = evalJob?.result?.trainingMetrics?.[metricName];
    if (typeof metricValue === "number") {
      metricValue = round(metricValue, 4);
    }
    if (metricValue?.length) {
      metricValue = metricValue.map(val => round(val, 4)).join(" , ");
    }
    if (evalJob.status === "ABORT") {
      metricValue = metricName === "inferLoss" ? " job aborted" : "-";
    }
    if (evalJob.status === "IN_PROGRESS") {
      metricValue = metricName === "inferLoss" ? " in progress" : "...";
    }
    if (evalJob.status === "REGISTERED") {
      metricValue = metricName === "inferLoss" ? " job registered" : "...";
    }
    return metricValue;
  });
};

const metricNamesToMetricLabels = {
  inferLoss: "Data loss",
  trainLoss: "Feature loss",
};

const MultiEvaluationView = ({ pipelineOutputs }) => {
  const [evaluationJobs, setEvaluationJobs] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const doFetchEvaluationJob = async () => {
    const evalJobIds = pipelineOutputs.map(pipeline => pipeline.evaluationJob.id);
    const { data, error } = await getEvaluationJobsByEvalJobIds(evalJobIds);
    setEvaluationJobs(data);
    setError(error);
    setIsLoading(false);
  };

  useEffect(() => {
    pipelineOutputs.length && doFetchEvaluationJob();
  }, [pipelineOutputs]);

  if (isLoading) {
    return "Loading...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  const firstEvaluationJob = evaluationJobs.find(evalJob => evalJob?.result) || evaluationJobs[0];
  if (!firstEvaluationJob.result?.trainingMetrics || !firstEvaluationJob.result?.taskEvaluations) {
    return `Evaluation Results ${firstEvaluationJob.id} not ready, status: ${firstEvaluationJob.status}`;
  }

  return (
    <Container>
      <PanelTitle>Training Job Metrics</PanelTitle>
      <TrainingMetricsContainer>
        <table>
          <tbody>
            <tr>
              <td></td>
              {pipelineOutputs.map(pipeline => (
                <PipelineId key={pipeline.pipelineId}>{getFormattedPipelineId(pipeline)}</PipelineId>
              ))}
            </tr>
            {Object.keys(firstEvaluationJob.result.trainingMetrics)
              .filter(metricName => metricName === "inferLoss" || metricName === "trainLoss")
              .map(metricName => {
                const metricValues = getTrainingMetricValuesFromEvalJobs(metricName, evaluationJobs);
                return (
                  <tr key={metricName}>
                    <TrainingMetricName>{metricNamesToMetricLabels[metricName]}</TrainingMetricName>
                    {metricValues.map((metricValue, i) => (
                      <TrainingMetricValue key={`${i}`}>{metricValue}</TrainingMetricValue>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </TrainingMetricsContainer>
      <Gap />
      <PanelTitle>Offline Task Evaluations</PanelTitle>
      <TaskEvaluationsContainer>
        {firstEvaluationJob.result.taskEvaluations.map(taskEvaluation => (
          <MultiExpandableTaskEvaluation
            key={taskEvaluation.taskName}
            taskEvaluation={taskEvaluation}
            pipelineOutputs={pipelineOutputs}
            evaluationJobs={evaluationJobs}
          />
        ))}
      </TaskEvaluationsContainer>
    </Container>
  );
};

export default MultiEvaluationView;
