import styled from "styled-components";

const ProgressBarContainer = styled.div`
  border-radius: 20px;
  position: relative;
  width: 100%;
  height: 14px;
  background-color: ${props => props.theme.color.closer1};
`;

const Progress = styled.div`
  transition: width 0.5s;
  border-radius: 20px;
  position: absolute;
  height: 100%;
  width: ${props => (props.currentValue / props.maxValue) * 100}%;
  max-width: 100%;
  background-color: ${props => props.theme.color.primary};
`;

const ProgressBar = ({ currentValue, maxValue, className }) => (
  <ProgressBarContainer className={className}>
    <Progress currentValue={currentValue} maxValue={maxValue} />
  </ProgressBarContainer>
);

export default ProgressBar;
