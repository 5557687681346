import styled from "styled-components";

import NotebookTopBar from "components/widgets/TopBars/NotebookTopBar";
import usePollJupyterEnv from "components/views/usePollJupyterEnv";

const Container = styled.div`
  padding: 20px;
`;

const NotebookTabView = ({ pipelineOutput, isTopBarShowing }) => {

  const [jupyterEnv, isDoingFirstFetch, errorEnv, doCreateEnv, doActivateEnv, doInactivateEnv] = usePollJupyterEnv(
    pipelineOutput?.pipelineId,
    pipelineOutput?.trainingJob?.id
  );

  if (isDoingFirstFetch) {
    return "Fetching notebook...";
  }

  if (errorEnv) {
    return JSON.stringify(errorEnv);
  }

  let urlPath = null;
  if (jupyterEnv?.activeSession?.sessionPath && jupyterEnv?.activeSession?.sessionToken) {
    urlPath = `/jupyter-session/${jupyterEnv?.activeSession?.sessionPath}/notebooks/notebook.ipynb?token=${jupyterEnv?.activeSession?.sessionToken}`;
  }

  const isTopBarEnabled = !jupyterEnv || jupyterEnv.status === "ACTIVE" || jupyterEnv.status === "INACTIVE";
  return (
    <>
      {isTopBarShowing && (
        <NotebookTopBar
          isEnabled={isTopBarEnabled}
          isNotebookActive={jupyterEnv?.activeSession?.sessionPath}
          onClickTurnOn={jupyterEnv ? doActivateEnv : doCreateEnv}
          onClickTurnOff={doInactivateEnv}
        />
      )}

      <Container>{urlPath && <object width="100%" height="1000" data={urlPath}></object>}</Container>
    </>
  );
};

export default NotebookTabView;
