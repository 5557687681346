import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { deleteLike, getActivitiesClones, getLikesActivties, putLike } from "api/services/projectService";
import Modal from "components/ui/Modal";
import UserBadgePicOnly from "components/ui/UserBadgePicOnly";
import { getLoggedInUserName } from "api/services/authenticationService";
import { cloneDataset, getDatasetInfoByDatasetId } from "api/services/dataService";

const EngagementContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;

const Badge = styled.div`
  display: flex;
  gap: 0px;
`;

const LeftBadge = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  height: 25px;
  width: 80px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 10px;

  svg {
    fill: ${props => (props.isLiked ? props.theme.color.primary : props.theme.color.closer2)};
    font-size: 15px;
  }
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }

  :hover svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const RightBadge = styled.div`
  height: 25px;
  width: 35px;
  font-size: 11px;
  font-weight: bold;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 5px 5px 5px;
  border: 1px solid ${props => props.theme.color.closer2};
  border-left: 0px;
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

const ModalContent = styled.div`
  position: relative;
  min-height: 400px;
  width: 600px;
  padding: 5px;
  align-items: start;
`;

const ClonedDatasetActivityLink = ({ cloneActivity }) => {
  const [datasetName, setDatasetName] = useState("");

  useEffect(() => {
    doPopulateDatasetName();
  }, [cloneActivity]);

  const doPopulateDatasetName = async () => {
    const { data } = await getDatasetInfoByDatasetId(cloneActivity?.cloneId);
    setDatasetName(data?.name);
  };

  return (
    <UserLink key={cloneActivity?.cloneId} to={`/data/${cloneActivity?.cloneId}`}>
      <UserBadgePicOnly userId={cloneActivity?.userId} />
      {cloneActivity?.userId}/{datasetName}
    </UserLink>
  );
};

const ClonedDatasetsModal = ({ clonedDatasetsActivities, isOpen, onClose }) => (
  <Modal open={isOpen} handleClose={onClose}>
    <ModalContent>
      {clonedDatasetsActivities?.map(cloneActivity => (
        <ClonedDatasetActivityLink key={cloneActivity?.cloneId} cloneActivity={cloneActivity} />
      ))}
    </ModalContent>
  </Modal>
);

const UsersWhoLikedModalContent = styled.div`
  position: relative;
  min-height: 400px;
  width: 400px;
  padding: 5px;
`;

const UserLink = styled(Link)`
  padding: 5px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const UsersWhoLikedModal = ({ likesActivities, isOpen, onClose }) => (
  <Modal open={isOpen} handleClose={onClose}>
    <UsersWhoLikedModalContent>
      {likesActivities?.map(likeActivity => (
        <UserLink key={likeActivity?.userId} to={`/${likeActivity?.userId}`}>
          <UserBadgePicOnly userId={likeActivity?.userId} />
          {likeActivity?.userId}
        </UserLink>
      ))}
    </UsersWhoLikedModalContent>
  </Modal>
);

const EngagementBadgesDataset = ({ datasetId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [clonedActivities, setClonedActivities] = useState([]);
  const [isClonedDatasetsModelOpen, setIsClonedDatasetsModelOpen] = useState(false);

  const [likesActivties, setLikesActivties] = useState([]);
  const [isLikesModalOpen, setIsLikesModalOpen] = useState(false);

  const [doesLoggedInUserLikeCube, setDoesLoggedInUserLikeCube] = useState(false);

  useEffect(() => {
    doPopulateClonedDatasetActivities();
    doPopulateLikesActivities();

    return () => {
      setIsClonedDatasetsModelOpen(false);
    };
  }, [datasetId]);

  const doPopulateClonedDatasetActivities = async () => {
    const { data } = await getActivitiesClones({ originalId: datasetId, targetType: "DATASET" });
    setClonedActivities(data);
  };

  const doPopulateLikesActivities = async () => {
    const { data } = await getLikesActivties({ targetId: datasetId, targetType: "DATASET" });
    setLikesActivties(data);

    setDoesLoggedInUserLikeCube(!!data?.find(activity => activity?.userId === getLoggedInUserName()));
  };

  return (
    <EngagementContainer>
      <Badge>
        <LeftBadge
          isDisabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const { data: clonedDataset, error: cloneErr } = await cloneDataset(datasetId, `cloned from ${datasetId}`);
            if (cloneErr) {
              alert(JSON.stringify(cloneErr));
              return;
            }

            navigate(`/data/${clonedDataset?.id}`);
            setIsLoading(false);
          }}
        >
          <AccountTreeIcon />
          Fork
        </LeftBadge>
        <RightBadge onClick={() => setIsClonedDatasetsModelOpen(true)}>{clonedActivities?.length}</RightBadge>
      </Badge>
      <Badge>
        <LeftBadge
          isLiked={doesLoggedInUserLikeCube}
          isDisabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            if (doesLoggedInUserLikeCube) {
              await deleteLike({ targetId: datasetId, targetType: "DATASET" });
              await doPopulateLikesActivities();
              setDoesLoggedInUserLikeCube(false);
            } else {
              await putLike({ targetId: datasetId, targetType: "DATASET" });
              await doPopulateLikesActivities();
              setDoesLoggedInUserLikeCube(true);
            }
            setIsLoading(false);
          }}
        >
          <FavoriteIcon />
          Likes
        </LeftBadge>
        <RightBadge onClick={() => setIsLikesModalOpen(true)}>{likesActivties?.length}</RightBadge>
      </Badge>
      <ClonedDatasetsModal
        clonedDatasetsActivities={clonedActivities}
        isOpen={isClonedDatasetsModelOpen}
        onClose={() => setIsClonedDatasetsModelOpen(false)}
      />
      <UsersWhoLikedModal
        likesActivities={likesActivties}
        isOpen={isLikesModalOpen}
        onClose={() => setIsLikesModalOpen(false)}
      />
    </EngagementContainer>
  );
};

export default EngagementBadgesDataset;
