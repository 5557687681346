import styled from "styled-components";
import { useState } from "react";

import { Bar, BarChart, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { Gap } from "components/Layout";
import { SmallTitle } from "components/ui/Text";
import { format as dateFormat, formatDuration, formatISO } from "date-fns";
import DateTimePicker from "components/ui/DateTimePicker";
import { round } from "lodash";
import SelectInput from "components/ui/SelectInput";
import usePollSolutionMetrics from "components/widgets/SolutionStats/usePollSolutionMetrics";

const getNonCamelCase = str => {
  let newStr = "";
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    const nextChar = str[i + 1];

    if (i === 0) {
      newStr += char.toUpperCase();
      continue;
    }
    if (nextChar && nextChar === nextChar.toUpperCase()) {
      newStr += char + " ";
      continue;
    }
    newStr += char;
  }

  return newStr;
};

const Container = styled.div``;

const MetricContainer = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  margin-bottom: 5px;
`;

const PeriodSettings = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 40px;
  padding-bottom: 40px;
`;

const PeriodSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const MetricName = styled.div`
  font-weight: bold;
`;

const MetricValue = styled.div``;

const Charts = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
`;

const SelectInterval = styled(SelectInput)`
  width: 184px;
`;

const MetricLineChart = ({ data, metricNameToGraph }) => (
  <div>
    <SmallTitle>{getNonCamelCase(metricNameToGraph)}</SmallTitle>
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={data} margin={{ left: -24, top: 10 }}>
        <XAxis
          dataKey="periodStart"
          tickFormatter={periodStartString => dateFormat(new Date(`${periodStartString} UTC`), "HH:mm")}
        />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey={metricNameToGraph} stroke="#0191ff" />
      </LineChart>
    </ResponsiveContainer>
  </div>
);

const MetricBarChart = ({ data, metricNameToGraph }) => (
  <div>
    <SmallTitle>{getNonCamelCase(metricNameToGraph)}</SmallTitle>
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data} margin={{ left: -24, top: 10 }}>
        <XAxis
          dataKey="periodStart"
          tickFormatter={periodStartString => dateFormat(new Date(`${periodStartString} UTC`), "HH:mm")}
        />
        <YAxis />
        <Tooltip />
        <Bar type="monotone" dataKey={metricNameToGraph} fill="#0191ff" />
      </BarChart>
    </ResponsiveContainer>
  </div>
);

const getDateTimeStr = dateString => formatISO(new Date(dateString)).substring(0, 16);

const getDateNowPlusMinutes = minutes => {
  const newEpoch = Date.now() + minutes * 60 * 1000;
  return new Date(newEpoch);
};

const SolutionStatsView = ({ solutionId }) => {
  const [periodStartDateTime, setPeriodStartDateTime] = useState(getDateTimeStr(getDateNowPlusMinutes(-5)));
  const [periodEndDateTime, setPeriodEndDateTime] = useState(getDateTimeStr(getDateNowPlusMinutes(5)));
  const [intervalSizeMinutes, setIntervalSizeMinutes] = useState(1);

  const [metrics, isLoading, error] = usePollSolutionMetrics(
    solutionId,
    new Date(periodStartDateTime),
    new Date(periodEndDateTime),
    intervalSizeMinutes
  );

  if (isLoading) {
    return "Loading stats...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <Container>
      <Gap />
      <PeriodSettings>
        <PeriodSettingContainer>
          <MetricName>Period Start</MetricName>
          <DateTimePicker
            dateTime={periodStartDateTime}
            handleChange={newDateTimeStr => {
              setPeriodStartDateTime(newDateTimeStr);
            }}
          />
        </PeriodSettingContainer>
        <PeriodSettingContainer>
          <MetricName>Period End</MetricName>
          <DateTimePicker
            dateTime={periodEndDateTime}
            handleChange={newDateTimeStr => {
              setPeriodEndDateTime(newDateTimeStr);
            }}
          />
        </PeriodSettingContainer>
        <PeriodSettingContainer>
          <MetricName>Interval Size</MetricName>
          <SelectInterval
            value={intervalSizeMinutes}
            onSetNewValue={newInterval => {
              setIntervalSizeMinutes(newInterval);
              setPeriodStartDateTime(getDateTimeStr(getDateNowPlusMinutes(-newInterval * 5)));
              setPeriodEndDateTime(getDateTimeStr(getDateNowPlusMinutes(newInterval * 5)));
            }}
          >
            <option value={1}>1 minute</option>
            <option value={10}>10 minutes</option>
            <option value={60}>1 hour</option>
            <option value={24 * 60}>1 day</option>
          </SelectInterval>
        </PeriodSettingContainer>
      </PeriodSettings>
      {Object.keys(metrics)
        .filter(metricName => !["intervals", "periodStart", "periodEnd"].includes(metricName))
        .map(metricName => (
          <MetricContainer key={metricName}>
            <MetricName>{getNonCamelCase(metricName)}</MetricName>
            <MetricValue>
              {metricName.includes("Duration")
                ? formatDuration({ seconds: round(metrics[metricName] / 1000, 2) })
                : metrics[metricName]}
            </MetricValue>
          </MetricContainer>
        ))}
      <Gap />
      <Charts>
        <MetricBarChart data={metrics?.intervals} metricNameToGraph="totalUsers" />
        <MetricLineChart data={metrics?.intervals} metricNameToGraph="totalQueries" />
        <MetricLineChart data={metrics?.intervals} metricNameToGraph="totalSessions" />
        <MetricLineChart data={metrics?.intervals} metricNameToGraph="averageSessionDuration" />
        <MetricLineChart data={metrics?.intervals} metricNameToGraph="queriesPerSession" />
      </Charts>
    </Container>
  );
};

export default SolutionStatsView;
