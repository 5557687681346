import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  display: flex;
  justify-content: space-between;
  overflow-x: auto;
`;

const LeftTabOptions = styled.div`
  display: flex;
`;

const TabOption = styled.div`
  ${props => props.isDisabled && "opacity: 0.3; pointer-events: none;"}
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  line-height: 20px;
  font-weight: ${props => (props.isSelected ? "bold" : "normal")};
  color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closest)};
  border-bottom: ${props => (props.isSelected ? "2px solid " + props.theme.color.primary : "none")};
  transition: color 0.2s;
  img {
    transition: filter 0.2s;
    ${props => props.isSelected && "filter: none"};
  }
  svg {
    fill: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer1_5)};
  }
  border-top: 2px solid ${props => props.theme.color.closer1};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
`;

const OcrNavWithTabs = ({ tabs = [], selectedTabIdx, onTabIdxSelect }) => {
  return (
    <Container>
      <LeftTabOptions>
        {tabs.map((tabName, idx) => (
          <TabOption onClick={() => onTabIdxSelect(idx)} key={idx} isSelected={selectedTabIdx === idx}>
            {tabName}
          </TabOption>
        ))}
      </LeftTabOptions>
    </Container>
  );
};

export default OcrNavWithTabs;
