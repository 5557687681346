import { isEqual } from "lodash";

export const stringifyModelConfig = modelConfig => JSON.stringify(modelConfig, null, 2);

export const isModelConfigValid = modelConfig => {
  const { layerConfig, latentDims } = modelConfig;

  if (!layerConfig) {
    return false;
  }

  if (!layerConfig.every(edge => edge?.resIndices?.length === 2)) {
    return false;
  }

  if (!layerConfig.every(edge => edge?.outputDims && edge?.inputDims)) {
    return false;
  }

  const latentOutgoingEdges = layerConfig.filter(edge => edge?.resIndices[0] === 0);
  const areAllLatentOutgoingEdgesOk = latentOutgoingEdges.every(edge => isEqual(edge?.inputDims, latentDims));
  if (!areAllLatentOutgoingEdgesOk) {
    return false;
  }

  return true;
};
