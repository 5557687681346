import * as backend from "api/backend/market-place/calendar";
import { getHandlingFromError } from "api/error-handling";

export const getEmails = async () => {
  const { data, error } = await backend.getEmails();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const updateEmails = async emails => {
  const { data, error } = await backend.postEmails(emails);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const sendMeetingRequest = async meetingRequest => {
  const { data, error } = await backend.postMeetingRequest(meetingRequest);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};
