import styled from "styled-components";
import { useState } from "react";
import EmbeddingGridView from "components/widgets/EmbeddingGridView";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
`;

const BookResult = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
  width: calc(50% - 30px);
  border-radius: 5px;
`;

const BookTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 10px;
`;

const BookImg = styled.img`
  cursor: pointer;
`;

const BookDetails = styled.div``;

const TagName = styled.td`
  font-weight: bold;
  padding-right: 10px;
  padding-bottom: 5px;
`;

const ColumnTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: calc(50% - 30px);
`;

const BookResultsView = ({ books, showEmbeddings = false }) => {
  const [hoverBookIsbn10, setHoverBookIsbn10] = useState(null);

  return (
    <Container>
      {books?.length > 0 && (
        <>
          <ColumnTitle>Boltzbit results</ColumnTitle>
          <ColumnTitle>Baseline</ColumnTitle>
        </>
      )}
      {books.map((book, i) => (
        <>
          <BookResult key={`${book.tags.isbn10}-${i}`}>
            <BookImg
              src={`data:image/png;base64,${book.image}`}
              onMouseEnter={e => setHoverBookIsbn10(book.tags.isbn10)}
              onMouseLeave={e => setHoverBookIsbn10(null)}
            />
            <BookDetails>
              <BookTitle>{book.tags.title}</BookTitle>
              {hoverBookIsbn10 === book.tags.isbn10 ? (
                <table>
                  <tbody>
                    <tr>
                      <TagName>description</TagName>
                    </tr>
                    <tr>{book.tags.description}</tr>
                  </tbody>
                </table>
              ) : (
                <table>
                  <tbody>
                    {Object.keys(book.tags)
                      .sort()
                      .filter(tagName => tagName !== "title")
                      .map(tagName => (
                        <tr key={`${book.tags.isbn10}-${tagName}`}>
                          <TagName>{tagName}</TagName>
                          <td>{JSON.stringify(book.tags[tagName]).slice(0, 50)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </BookDetails>
          </BookResult>
          {/* {showEmbeddings && (
            <EmbeddingGridView
              title={"Data Embedding"}
              embeddingVec={book.embedding}
              numSquaresRow={40}
              squareSize={10}
            />
          )} */}
        </>
      ))}
    </Container>
  );
};

export default BookResultsView;
