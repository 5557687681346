import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 100%;
  overflow-x: auto;
`;

const Title = styled.div`
  position: sticky;
  left: 0;
  font-weight: bold;
  padding: 4px;
  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.furthest};
`;

const Td = styled.td`
  position: relative;
  padding: 4px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  min-width: 70px;
  text-align: left;
  ${props => props.isLeftAligned && "text-align: left;"}
  ${props => props.isBold && "font-weight: 600;"}
  ${props => props.isSticky && "position: sticky; left: 0; outline: 1px solid #ccc; z-index: 2;"}
  background-color: ${props => props.theme.color.furthest};
  padding-right: 32px;
`;

const Table = styled.table`
  table-layout: fixed;
`;

const Select = styled.select`
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
  outline: none;
  font-family: "Montserrat", sans-serif;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const Input = styled.input`
  border: 1px solid transparent;
  border-radius: 0;
  outline: none;
  font-family: "Montserrat", sans-serif;
  :hover {
    background-color: ${props => props.theme.color.closer0};
    border: 1px solid ${props => props.theme.color.closer1_5};
  }
`;

const InputWithState = ({ value, onBlur = e => {} }) => {
  const [inputValue, setInputValue] = useState(value);

  return (
    <Input
      value={inputValue}
      onBlur={onBlur}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.target.blur();
        }
      }}
      onChange={e => setInputValue(e.target.value)}
    />
  );
};

const TableFlat = ({
  title = "",
  rows = [],
  boldColumnIndices = [],
  boldRowIndices = [0],
  underlineRowIndices = [],
  leftAlignedColumnIndices = [0],
  style = {},
  tableStyle = {},
  cellStyle = {},
  emailModalColumns = [],
  onChangeDealData = (dealId, columnName, newColumnValue) => {},
  columnNameToOptions = {},
  dealNameToId = {},
  dealIdToEmailContent = {},
  columnNamesWithTextInput = [],
  columnNamesWithDateInput = [],
}) => {
  return (
    <Container style={style}>
      {title && <Title>{title}</Title>}
      <Table style={tableStyle}>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, columnIndex) => {
                const columnName = rows?.[0]?.[columnIndex];
                const dealName = rows?.[rowIndex]?.[0];
                const dealId = dealNameToId?.[dealName];

                let cellContent = cell;

                const isSelectInputCell = columnNameToOptions[columnName]?.length > 0;
                const isTextInputCell = columnNamesWithTextInput?.includes(columnName);
                const isDateInputCell = columnNamesWithDateInput?.includes(columnName);

                const updateDealDataFromEvent = e => {
                  const newColumnValue = e.target.value;
                  onChangeDealData(dealId, columnName, newColumnValue);
                };

                if (rowIndex > 0 && isSelectInputCell) {
                  cellContent = (
                    <Select value={cell} onChange={updateDealDataFromEvent}>
                      {columnNameToOptions[columnName].map((option, i) => (
                        <option key={i} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  );
                }
                if (rowIndex > 0 && isTextInputCell) {
                  cellContent = <InputWithState value={cell} onBlur={updateDealDataFromEvent} />;
                }
                if (rowIndex > 0 && isDateInputCell) {
                  cellContent = <Input value={cell} type="date" onChange={updateDealDataFromEvent} />;
                }

                return (
                  <Td
                    isSticky={columnIndex === 0}
                    isBold={boldRowIndices.includes(rowIndex) || boldColumnIndices.includes(columnIndex)}
                    isLeftAligned={leftAlignedColumnIndices.includes(columnIndex)}
                    key={columnIndex}
                    style={cellStyle}
                  >
                    {cellContent}
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TableFlat;
