import styled from "styled-components";
import { isEqual } from "lodash";

import ModelEditView from "components/views/ModelEditView";
import MultiModelView from "components/views/MultiModelView";
import ModelTopBar from "components/widgets/TopBars/ModelTopBar";
import displayPipelineStatusUntilContentReady from "components/widgets/PipelineStatus/displayPipelineStatusUntilContentReady";
import RecommendedModelConfigEditor from "components/widgets/RecommendedModelConfigEditor";
import ModelArchView from "components/views/ModelArchView";
import TrainingTopBar from "components/widgets/TopBars/TrainingTopBar";
import TrainingProgressView from "components/views/TrainingProgressView";
import TrainingExamplesView from "components/views/TrainingExamplesView";
import TrainingEditView from "components/views/TrainingEditView";
import TrainingProgressViewCustom from "components/views/TrainingProgressViewCustom";
import MultiTrainingProgressView from "components/widgets/MultiTrainingProgressView";
import EvaluationView from "components/views/EvaluationView";
import MultiEvaluationView from "components/views/MultiEvaluationView";
import EvaluationTopBar from "components/widgets/TopBars/EvaluationTopBar";
import useSearchParamsState from "hooks/useSearchParamsState";
import { DatasetIcon, ModelIcon, TrainingIcon } from "components/ui/Icons";
import ModelRecommendationFromPrompt from "components/widgets/ModelRecommendationFromPrompt";
import ModelRecommendationFromPromptAnimated from "components/widgets/ModelRecommendationFromPropmtAnimated";
import DatasetPreviewAnimated from "components/views/DatasetPreviewAnimated";
import CustomizedTrainingEditor from "components/widgets/CustomizedTrainingEditor";

const Container = styled.div`
  display: grid;
  height: 100%;
  box-shadow: ${props => props.theme.shadow};
`;

const RightSideContent = styled.div``;

const SidebarContainer = styled.div`
  padding-top: 36px;
  border-right: 1px solid ${props => props.theme.color.closer1};
  height: 100%;
  background-color: ${props => props.theme.color.closer0_5};
`;

const Subtab = styled.div`
  padding: 10px;
  background-color: ${props => (props.isSelected ? `${props.theme.color.primary}22` : "transparent")};
  font-weight: ${props => (props.isSelected ? 600 : 400)};
  cursor: pointer;
  :hover {
    background-color: ${props => `${props.theme.color.primary}22`};
  }

  svg {
    ${props => props.isSelected && `fill: ${props.theme.color.primary};`}
  }
`;

const ModelTabView = ({
  hasUserEditedPipeline,
  isDataJobInProgress,

  isEditMode,
  setIsEditMode,

  editedModel,
  setEditedModel,

  modelView,
  setModelView,

  selectedPipelineIds,
  pipelineOutputs,

  doesCubeBelongToLoggedInUser,

  doSavePipelineDraft,
  doExecutePipeline,
  doRestartPolling,

  isCustomizedTrainingMode,
  setIsCustomizedTrainingMode,

  isShowingTrainingPlot,
  setIsShowingTrainingPlot,

  editedTrainingConfig,
  setEditedTrainingConfig,

  editedCustomCode,
  setEditedCustomCode,

  pipelineModelId,

  pipelineConfig,
  onNewPipelineConfig,
}) => {
  const [subTab, setSubTab] = useSearchParamsState({ paramName: "subTab", initialValue: "Validation" });
  const selectedPipelineOutput = pipelineOutputs?.find(
    pipelineOutput => pipelineOutput?.id === selectedPipelineIds?.[0]
  );

  if (!pipelineConfig) {
    return "Fetching pipeline config...";
  }

  if (!pipelineConfig?.modelConfig?.architecture) {
    return (
      // <RecommendedModelConfigEditor
      //   featureTypeDescriptors={pipelineOutputs?.[0]?.dataset?.config?.featureTypeDescriptors}
      //   pipelineConfig={pipelineConfig}
      //   onFetchRecommendedModelConfig={newModelConfig => {
      //     setEditedModel(newModelConfig);
      //     // onNewPipelineConfig({ ...pipelineConfig, modelConfig: newModelConfig });
      //   }}
      // />
      <ModelRecommendationFromPromptAnimated
        pipelineConfig={pipelineConfig}
        datasetName={pipelineOutputs?.[0]?.dataset?.name}
        featureTypeDescriptors={pipelineOutputs?.[0]?.dataset?.config?.featureTypeDescriptors}
      />
    );
  }

  const pipelineStatus = pipelineConfig?.status;
  const selectedTab = "Model";

  let rightSideContent = (
    <>
      {doesCubeBelongToLoggedInUser && (
        <ModelTopBar
          onClickTrainingLog={() => setSubTab("Validation")}
          onClickCode={() => setSubTab("Network")}
          hasModelId={!!pipelineOutputs?.[0]?.model?.id}
          modelName={editedModel?.name}
          onModelNameEdit={newName => setEditedModel({ ...editedModel, name: newName })}
          isEditMode={isEditMode}
          onClickCancel={() => {
            setEditedModel(pipelineConfig?.modelConfig);
            setIsEditMode(false);
          }}
          onClickEdit={() => setIsEditMode(true)}
          onClickSave={doSavePipelineDraft}
          onClickExecute={doExecutePipeline}
          hasUserEditedPipeline={hasUserEditedPipeline}
          modelView={modelView}
          onSetModelView={newView => setModelView(newView)}
          isSubmitDisabled={!pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
        />
      )}
      {isEditMode && selectedPipelineIds.length === 1 && !pipelineOutputs?.[0]?.customCode && (
        <>
          {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs, pipelineStatus }) || (
            <ModelEditView
              modelView={modelView}
              modelConfig={editedModel?.architecture}
              onModelConfigChange={newArchitecture => setEditedModel({ ...editedModel, architecture: newArchitecture })}
              datasetFeatureTypeDescriptors={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors}
            />
          )}
        </>
      )}
      {!isEditMode && selectedPipelineIds.length === 1 && !pipelineOutputs?.[0]?.customCode && (
        <div style={{ border: "1px solid #d5d5d5" }}>
          {/* {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs, pipelineStatus }) || (
            <ModelArchView
              modelId={pipelineOutputs[0]?.model?.id}
              architecture={editedModel?.architecture}
              modelView={modelView}
              inputCols={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors?.map(feature => feature.key)}
              outputCols={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors?.map(feature => feature.key)}
              datasetFeatureTypeDescriptors={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors}
            />
          )} */}
          <CustomizedTrainingEditor
            customCode={editedCustomCode}
            onCustomCodeChange={newCode => {
              setEditedCustomCode(newCode);
            }}
            pipelineOutputCustomCode={pipelineConfig?.modelConfig?.trainingScript}
          />
        </div>
      )}
      {!isEditMode && selectedPipelineIds.length > 1 && <MultiModelView pipelineOutputs={pipelineOutputs} />}
    </>
  );

  if (subTab === "Training") {
    rightSideContent = (
      <>
        {!isEditMode &&
          !pipelineOutputs[0]?.customCode &&
          selectedPipelineIds.length === 1 &&
          !isCustomizedTrainingMode && (
            <>
              {doesCubeBelongToLoggedInUser && (
                <TrainingTopBar
                  onClickTrainingLog={() => setSubTab("Validation")}
                  onClickCode={() => setSubTab("Network")}
                  isEditMode={false}
                  onClickEdit={() => setIsEditMode(true)}
                  hasUserEditedPipeline={hasUserEditedPipeline}
                  onClickSave={doSavePipelineDraft}
                  onClickExecute={doExecutePipeline}
                  plotOrExamplesView={isShowingTrainingPlot ? "Plot" : "Examples"}
                  onSetPlotOrExamplesView={newView => setIsShowingTrainingPlot(newView === "Plot")}
                  isSubmitDisabled={!pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                />
              )}
              {/* {displayPipelineStatusUntilContentReady({ selectedTab: "Training", pipelineOutputs, pipelineStatus }) || */}
              {isShowingTrainingPlot ? (
                <TrainingProgressView
                  pipelineOutput={selectedPipelineOutput}
                  doRestartPipelineOutputPolling={doRestartPolling}
                />
              ) : (
                <TrainingExamplesView trainingJobId={null} datasetId={pipelineOutputs[0]?.dataset?.id} />
              )}
            </>
          )}

        {isEditMode && selectedPipelineIds.length === 1 && (
          <>
            {doesCubeBelongToLoggedInUser && (
              <TrainingTopBar
                onClickTrainingLog={() => setSubTab("Validation")}
                onClickCode={() => setSubTab("Network")}
                isEditMode
                onClickCancel={() => {
                  setEditedTrainingConfig(pipelineOutputs[0]?.trainingConfig);
                  setIsEditMode(false);
                }}
                hasUserEditedPipeline={hasUserEditedPipeline}
                onClickSave={doSavePipelineDraft}
                onClickExecute={doExecutePipeline}
                isSubmitDisabled={!pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
              />
            )}
            <TrainingEditView
              pipelineOutputCustomCode={pipelineConfig?.modelConfig?.trainingScript}
              trainingConfig={editedTrainingConfig}
              onTrainingConfigEditing={t => setEditedTrainingConfig(t)}
              customCode={editedCustomCode}
              onCustomCodeEditing={newCode => {
                setEditedCustomCode(newCode);
              }}
              isCustomizedTrainingMode={isCustomizedTrainingMode}
              onSetIsCustomizedTrainingMode={newCustomizedTrainingMode => {
                setIsCustomizedTrainingMode(newCustomizedTrainingMode);
              }}
              modelId={pipelineModelId}
              modelBeUpdated={!isEqual(editedModel, pipelineOutputs?.[0]?.model?.config)}
            />
          </>
        )}
        {!isEditMode && selectedPipelineIds.length === 1 && isCustomizedTrainingMode && (
          <>
            {doesCubeBelongToLoggedInUser && (
              <TrainingTopBar
                onClickTrainingLog={() => setSubTab("Validation")}
                onClickCode={() => setSubTab("Network")}
                isEditMode={isEditMode}
                onClickEdit={() => setIsEditMode(true)}
                hasUserEditedPipeline={hasUserEditedPipeline}
                onClickSave={doSavePipelineDraft}
                onClickExecute={doExecutePipeline}
                isSubmitDisabled={!pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
              />
            )}
            {displayPipelineStatusUntilContentReady({ selectedTab: "Training", pipelineOutputs, pipelineStatus }) || (
              <TrainingProgressViewCustom pipelineOutput={pipelineOutputs[0]} />
            )}
          </>
        )}
        {selectedPipelineIds.length > 1 &&
          (displayPipelineStatusUntilContentReady({ selectedTab: "Training", pipelineOutputs, pipelineStatus }) || (
            <MultiTrainingProgressView pipelineOutputs={pipelineOutputs} />
          ))}
      </>
    );
  }
  if (subTab === "Evaluation") {
    rightSideContent = (
      <>
        {selectedPipelineIds.length === 1 &&
          !pipelineOutputs[0].trainingBundle &&
          (displayPipelineStatusUntilContentReady({ selectedTab: "Evaluation", pipelineOutputs }) || (
            <>
              <EvaluationTopBar
                isEditMode={isEditMode}
                hasUserEditedPipeline={hasUserEditedPipeline}
                onClickCancel={() => setIsEditMode(false)}
                onClickEdit={() => setIsEditMode(true)}
                onClickSave={doSavePipelineDraft}
                onClickExecute={doExecutePipeline}
              />
              <EvaluationView evalJobId={pipelineOutputs[0]?.evaluationJob?.id} pipelineOutput={pipelineOutputs[0]} />
            </>
          ))}
        {selectedPipelineIds.length === 1 && pipelineOutputs[0].trainingBundle && (
          <EvaluationTopBar
            isEditMode={isEditMode}
            hasUserEditedPipeline={hasUserEditedPipeline}
            onClickCancel={() => setIsEditMode(false)}
            onClickEdit={() => setIsEditMode(true)}
            onClickSave={doSavePipelineDraft}
            onClickExecute={doExecutePipeline}
            isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
          />
        )}
        {selectedPipelineIds.length > 1 &&
          (displayPipelineStatusUntilContentReady({ selectedTab: "Evaluation", pipelineOutputs }) || (
            <MultiEvaluationView pipelineOutputs={pipelineOutputs} />
          ))}
      </>
    );
  }

  if (subTab === "Validation") {
    rightSideContent = (
      <>
        {doesCubeBelongToLoggedInUser && (
          <TrainingTopBar
            onClickTrainingLog={() => setSubTab("Validation")}
            onClickCode={() => setSubTab("Network")}
            isEditMode={isEditMode}
            onClickEdit={() => setIsEditMode(true)}
            hasUserEditedPipeline={hasUserEditedPipeline}
            onClickSave={doSavePipelineDraft}
            onClickExecute={doExecutePipeline}
            isSubmitDisabled={!pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
          />
        )}
        <DatasetPreviewAnimated
          datasetId={pipelineOutputs?.[0]?.dataset?.id}
          pipelineOutput={pipelineOutputs?.[0]}
          jobId={pipelineOutputs?.[0]?.trainingJob?.id}
          pipelineId={pipelineOutputs?.[0]?.id}
          doRestartPipelineOutputPolling={doRestartPolling}
        />
      </>
    );
  }

  return (
    <Container>
      {/* <SidebarContainer>
        <Subtab isSelected={subTab === "Validation"} onClick={() => setSubTab("Validation")}>
          <DatasetIcon />
        </Subtab>
        <Subtab isSelected={subTab === "Network"} onClick={() => setSubTab("Network")}>
          <ModelIcon />
        </Subtab>
        <Subtab isSelected={subTab === "Training"} onClick={() => setSubTab("Training")}>
          <TrainingIcon />
        </Subtab>
        <Subtab isSelected={subTab === "Evaluation"} onClick={() => setSubTab("Evaluation")}>
          Evaluation
        </Subtab>
      </SidebarContainer> */}
      <RightSideContent>{rightSideContent}</RightSideContent>
    </Container>
  );
};

export default ModelTabView;
