import { apiGet, apiPost, apiPatch, apiPostFile, apiPut, apiDelete } from "api/api-http-methods";
import { errorTypes } from "api/error-handling";

const isResponseDataShapeCorrect = data => data instanceof Object && "message" in data && "code" in data;

const addZapiChecks = data => {
  if (!isResponseDataShapeCorrect(data)) {
    return {
      data: null,
      error: {
        type: errorTypes.ZAPI_FAIL,
        message: "Response not zapi format",
      },
    };
  }

  const { message, code, objectJSON } = data;

  let parsedJson = {};
  if (objectJSON) {
    try {
      parsedJson = JSON.parse(objectJSON);
      return { data: parsedJson, errorType: null };
    } catch (parseError) {
      return {
        data: null,
        error: {
          type: errorTypes.JSON_PARSE_FAIL,
          message: `Failed to parse objectJSON: ${JSON.stringify(parseError)}`,
        },
      };
    }
  }

  return { data: { message, code }, error: null };
};

export const zapiGet = async (url, params = {}) => {
  const { data, error } = await apiGet(url, params);
  if (error) {
    return { data, error };
  }

  const zapiRes = addZapiChecks(data);
  if (zapiRes.data?.code === "FAILED") {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.data.message },
    };
  }

  if (zapiRes.error) {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.error.message },
    };
  }

  return { data: zapiRes.data, error: null };
};

export const zapiPatch = async (url, params = {}, body) => {
  const { data, error } = await apiPatch(url, params, body);
  if (error) {
    return { data, error };
  }

  const zapiRes = addZapiChecks(data);
  if (zapiRes.data.code === "FAILED") {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.data.message },
    };
  }

  return { data: zapiRes.data, error: null };
};

export const zapiPost = async (url, params = {}, body) => {
  const { data, error } = await apiPost(url, params, body);
  if (error) {
    return { data, error };
  }

  const zapiRes = addZapiChecks(data);
  if (zapiRes?.data?.code === "FAILED") {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.data.message },
    };
  }

  return { data: zapiRes.data, error: null };
};

export const zapiPut = async (url, params = {}, body) => {
  const { data, error } = await apiPut(url, params, body);
  if (error) {
    return { data, error };
  }

  const zapiRes = addZapiChecks(data);
  if (zapiRes.data.code === "FAILED") {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.data.message },
    };
  }

  return { data: zapiRes.data, error: null };
};

export const zapiDelete = async (url, params = {}, body) => {
  const { data, error } = await apiDelete(url, params, body);
  if (error) {
    return { data, error };
  }

  const zapiRes = addZapiChecks(data);
  if (zapiRes.data.code === "FAILED") {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.data.message },
    };
  }

  return { data: zapiRes.data, error: null };
};

export const zapiPostFile = async (url, params = {}, file, onUploadProgress = null) => {
  const { data, error } = await apiPostFile(url, params, file, onUploadProgress);
  if (error) {
    return { data, error };
  }

  const zapiRes = addZapiChecks(data);
  if (zapiRes.data.code === "FAILED") {
    return {
      data: null,
      error: { type: errorTypes.ZAPI_FAIL, message: zapiRes.data.message },
    };
  }

  return { data: zapiRes.data, error: null };
};
