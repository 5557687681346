import styled from "styled-components";
import { useEffect, useState } from "react";
import AreaChart from "components/charts/AreaChart";
import StackedAreaChart from "components/charts/StackedAreaChart";
import DateTimePicker from "components/ui/DateTimePicker";
import Button from "components/ui/Button";
import { getOnlineEvaluationReport } from "api/services/deploymentService";

const IndicatorDiv = styled.div`
  height: 90%;
  min-width: 180px;
  min-height: 80px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid rgb(4, 145, 255);
  background: ${props => props.theme.color.furthest};
`;

const WideIndicatorDiv = styled.div`
  width: 90%;
  height: 90%;
  min-width: 300px;
  min-height: 180px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid rgb(4, 145, 255);
  background: ${props => props.theme.color.furthest};
`;

const IndicatorTitle = styled.div`
  font-size: 16px;
  color: ${props => props.theme.color.cloesest};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const IndicatorValue = styled.div`
  font-size: 54px;
  font-weight: bold;
  color: ${props => props.theme.color.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ONE_HOUR = 60 * 60 * 1000;
const ONE_DAY = 24 * 60 * 60 * 1000;
const ONE_WEEK = 7 * 24 * 60 * 60 * 1000;

const getAllowedIntervalsFromTimestamps = (startTimeStamp, endTimeStamp) => {
  const startToEndIntervalMs = new Date(endTimeStamp) - new Date(startTimeStamp);
  let allowedIntervals = ["minute"];
  if (startToEndIntervalMs > ONE_HOUR) {
    allowedIntervals = ["minute", "hour"];
  }
  if (startToEndIntervalMs > ONE_DAY) {
    allowedIntervals = ["hour", "day"];
  }
  if (startToEndIntervalMs > ONE_WEEK) {
    allowedIntervals = ["day", "week"];
  }
  return allowedIntervals;
};

const getIntervalSizeInMinutesFromLabel = label => {
  if (label === "minute") {
    return 1;
  }
  if (label === "hour") {
    return 60;
  }
  if (label === "day") {
    return 24 * 60;
  }
  if (label === "week") {
    return 7 * 24 * 60;
  }
  return 60;
};

const ApiMonitor = ({ trainingJobId, tag }) => {
  const [startTimeStamp, setStartTimeStamp] = useState(null);
  const [endTimeStamp, setEndTimeStamp] = useState(null);

  const [latencyTotalValue, setLatencyTotalValue] = useState(0);
  const [scoreTotalValue, setScoreTotalValue] = useState(0);
  const [baselineScoreTotalValue, setBaselineScoreTotalValue] = useState(0);
  const [queryTotalValue, setQueryTotalValue] = useState(0);

  const [selectedIntervalLabel, setSelectedIntervalLabel] = useState("minute");
  const [latencyBinValues, setLatencyBinValues] = useState([]);
  const [scoreBinValues, setScoreBinValues] = useState([]);
  const [queryBinValues, setQueryBinValues] = useState([]);

  const allowedIntervals = getAllowedIntervalsFromTimestamps(startTimeStamp, endTimeStamp);

  useEffect(() => {
    const now = new Date();
    now.setSeconds(0, 0);
    setEndTimeStamp(now.toISOString().replace(/:00.000Z/, ""));

    const aHour = 60 * 60 * 1000;
    const aHourAgo = new Date(now - aHour);
    aHourAgo.setSeconds(0, 0);
    setStartTimeStamp(aHourAgo.toISOString().replace(/:00.000Z/, ""));
  }, []);

  useEffect(() => {
    if (!allowedIntervals.includes(selectedIntervalLabel)) {
      setSelectedIntervalLabel(allowedIntervals[0]);
    }
  }, [startTimeStamp, endTimeStamp]);

  useEffect(() => {
    if (!allowedIntervals.includes(selectedIntervalLabel)) {
      return;
    }
    doFetchMetrics();
  }, [startTimeStamp, endTimeStamp, selectedIntervalLabel]);

  const setStateFromMetricData = metricData => {
    setLatencyTotalValue(metricData["averageLatencyMs"]);
    setQueryTotalValue(metricData["totalQueries"]);
    setScoreTotalValue(metricData["performance"]["indicatorScore"]);
    setBaselineScoreTotalValue(metricData["performance"]["baselineIndicatorScore"]);

    const latencyVals = [];
    const queryVals = [];
    const scoreVals = [];

    metricData["intervals"]?.forEach((element, i) => {
      queryVals.push({ time: element["periodEnd"], queries: element["totalQueries"] });
      latencyVals.push({ time: element["periodEnd"], latency: element["averageLatencyMs"] });
      scoreVals.push({
        time: element["periodEnd"],
        score: element["performance"]["indicatorScore"],
        "baseline score": element["performance"]["baselineIndicatorScore"],
      });
    });

    setLatencyBinValues(latencyVals);
    setQueryBinValues(queryVals);
    setScoreBinValues(scoreVals);
  };

  const doFetchMetrics = async () => {
    const params = {
      start: `${startTimeStamp}:00`,
      end: `${endTimeStamp}:00`,
      interval_size_minutes: getIntervalSizeInMinutesFromLabel(selectedIntervalLabel),
      job_id: trainingJobId,
      tag,
    };
    const { data } = await getOnlineEvaluationReport(params);
    setStateFromMetricData(data);
  };

  const handleClickNowBtn = () => {
    const now = new Date();
    now.setSeconds(0, 0);
    setEndTimeStamp(now.toISOString().replace(/:00.000Z/, ""));
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="item">
            <div className="row text-center" style={{ marginTop: 20 }}></div>
            <div className="row" style={{ marginTop: 50 }}>
              <div className="col-2" style={{ textAlign: "right" }}>
                Specify the Time Window :
              </div>
              <div className="col-3">
                <DateTimePicker
                  label={"Start Time"}
                  dateTime={startTimeStamp}
                  handleChange={newVal => {
                    setStartTimeStamp(newVal);
                  }}
                />
              </div>
              <div className="col-3">
                <DateTimePicker
                  label={"End Time"}
                  dateTime={endTimeStamp}
                  handleChange={newVal => {
                    if (newVal < startTimeStamp) {
                      alert("The end time should be not before the start time!");
                    } else {
                      setEndTimeStamp(newVal);
                    }
                  }}
                />
              </div>
              <div className="col-2">
                <Button value={"End by Now"} onClick={handleClickNowBtn} />
              </div>
              <div className="col-2">
                interval by
                <select
                  name="interval"
                  onChange={e => setSelectedIntervalLabel(e.target.value)}
                  value={selectedIntervalLabel}
                >
                  {allowedIntervals.map(interval => (
                    <option value={interval} key={interval}>
                      {interval}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row" style={{ marginTop: 50 }}>
              <div className="col-3">
                <IndicatorDiv>
                  <IndicatorTitle>Average Latency (ms)</IndicatorTitle>
                  <IndicatorValue>{latencyTotalValue}</IndicatorValue>
                </IndicatorDiv>
              </div>
              <div className="col-3">
                <IndicatorDiv>
                  <IndicatorTitle>Number of Queries</IndicatorTitle>
                  <IndicatorValue>{queryTotalValue}</IndicatorValue>
                </IndicatorDiv>
              </div>
              <div className="col-6">
                <WideIndicatorDiv>
                  <IndicatorTitle>Performance v.s. Baseline</IndicatorTitle>
                  <IndicatorValue>
                    {scoreTotalValue.toString().slice(0, 5)} v.s.{" "}
                    <a style={{ fontSize: 45, color: "red" }}>{baselineScoreTotalValue.toString().slice(0, 5)}</a>{" "}
                  </IndicatorValue>
                </WideIndicatorDiv>
              </div>
            </div>

            <div className="row" style={{ marginTop: 50 }}>
              <div className="text-center">
                <h2> Latencies </h2>
              </div>
              <AreaChart
                data={latencyBinValues}
                xAxisKey={"time"}
                yAxisKey={"latency"}
                chartSize={{ width: 500, height: 300 }}
                color={"#0491FF"}
              />
            </div>
            <div className="row" style={{ marginTop: 50 }}>
              <div className="text-center">
                <h2> Number of Queries </h2>
              </div>
              <AreaChart
                data={queryBinValues}
                xAxisKey={"time"}
                yAxisKey={"queries"}
                chartSize={{ width: 500, height: 300 }}
                color={"#0491FF"}
              />
            </div>

            <div className="row" style={{ marginTop: 50 }}>
              <div className="text-center">
                <h2> Performance v.s. Baseline </h2>
              </div>
              <StackedAreaChart
                data={scoreBinValues}
                xAxisKey={"time"}
                yAxisKeyAndColors={[
                  {
                    key: "score",
                    color: "#0491FF",
                  },
                  {
                    key: "baseline score",
                    color: "red",
                  },
                ]}
                chartSize={{ width: 500, height: 300 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiMonitor;
