import styled from "styled-components";

import ModelDndEditor from "components/widgets/ModelDndEditor";
import ModelTextEditor from "components/widgets/ModelTextEditor";

const ModelEditorContainer = styled.div`
  height: 100%;
`;

const ModelEditView = ({ modelConfig, onModelConfigChange, modelView, datasetFeatureTypeDescriptors }) => {
  if (!modelConfig) {
    return null;
  }

  return (
    <ModelEditorContainer>
      {modelView === "Config" && (
        <ModelTextEditor
          modelConfig={modelConfig}
          onModelConfigChange={newConfig => {
            newConfig && onModelConfigChange({ ...modelConfig, ...newConfig });
          }}
        />
      )}

      {modelView === "Graph" && (
        <ModelDndEditor
          modelConfig={modelConfig}
          onModelConfigChange={newConfig => {
            newConfig && onModelConfigChange({ ...modelConfig, ...newConfig });
          }}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        />
      )}
    </ModelEditorContainer>
  );
};

export default ModelEditView;
