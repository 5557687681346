import { getChatbotGeneration } from "api/services/solutions/chatbotSolutionService";
import CustomCodeMirror from "components/ui/CustomCodeMirror";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { parseJson } from "utils/common";

const CodeMirrorContainer = styled.div`
  ${props => props.isDisabled && `pointer-events: none; opacity: 0.3;`}
`;

const CommandInterpretationView = ({ userInput, shouldRefreshCode, adminGoogleCalendarToken }) => {
  const codePlaceHolder =
    "// The interpretation of your commands will be displayed here" + Array(25).fill("\n").join("");

  const [code, setCode] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userInput && shouldRefreshCode) {
      setCode(codePlaceHolder);
      return;
    }
    userInput && doPopulateCode();
  }, [userInput]);

  const doPopulateCode = async () => {
    const { data, error } = await getChatbotGeneration({ text: userInput, token: adminGoogleCalendarToken });
    setError(error);
    const outputCode = parseJson(JSON.stringify(data?.code));
    const outputCodeLen = outputCode.split("\n").length;
    const paddedEmptyLines = outputCodeLen < 25 ? 25 - outputCodeLen : 0;
    setCode(outputCode + Array(paddedEmptyLines).fill("\n").join(""));
  };

  return (
    <CodeMirrorContainer>
      {error && <div>{JSON.stringify(error)}</div>}
      <CustomCodeMirror value={code} height={"520px"} />
    </CodeMirrorContainer>
  );
};

export default CommandInterpretationView;
