import { useState, useEffect } from "react";

import * as backend from "api/backend/deploymentServiceEndpoints";

const usePollPredictions = ({ cubeId }) => {
  const [predictions, setPredictions] = useState([]);

  useEffect(() => {
    doFetchPredicitons();
    const intervalId = setInterval(doFetchPredicitons, 2000);

    return () => clearInterval(intervalId);
  }, [cubeId]);

  const doFetchPredicitons = async () => {
    const { data } = await backend.getPredictions({ cubeId });
    setPredictions(data || []);
  };

  return predictions;
};

export default usePollPredictions;
