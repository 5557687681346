import styled from "styled-components";
import { Add, Close } from "@material-ui/icons";

const SearchValueInput = styled.input`
  padding: 5px;
  border-radius: 5px;
  border-bottom: 1px solid transparent;
  width: 80px;
`;

const AttrTitle = styled.div`
  width: 150px;
  padding: 0;
  font-size: 14px;
  font-weight: bold;
`;

const Container = styled.div``;

const InputsRow = styled.div`
  align-items: center;
  display: flex;
  padding-bottom: 15px;
`;

const AddButtonContainer = styled.div`
  cursor: pointer;
  width: max-content;
  border-radius: 20px;
  color: ${props => props.theme.color.primary};
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const CloseButtonContainer = styled.div`
  cursor: pointer;
  width: max-content;
  border-radius: 20px;
  margin-left: 5px;
  padding: 1px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }

  .MuiSvgIcon-root {
    width: 15px;
    height: 15px;
  }
`;

const SearchValueInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const Inputs = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const SearchValueInputGroup = ({
  trainingSearchValuesKey,
  trainingSearchValues,
  onSetTrainingSearchValues,
  min = 0,
  step = 0.01,
}) => {
  const values = trainingSearchValues[trainingSearchValuesKey];

  const setNewValue = (e, index) => {
    const newValue = parseFloat(e.target.value);
    const newValues = [...values.slice(0, index), newValue, ...values.slice(index + 1)];

    const newTrainingSearchValues = {
      ...trainingSearchValues,
      [trainingSearchValuesKey]: newValues,
    };
    onSetTrainingSearchValues(newTrainingSearchValues);
  };

  const addNewSearchValueInput = () => {
    const lastSearchValue =
      trainingSearchValues[trainingSearchValuesKey][trainingSearchValues[trainingSearchValuesKey].length - 1] || 0;
    const newTrainingSearchValues = {
      ...trainingSearchValues,
      [trainingSearchValuesKey]: [...trainingSearchValues[trainingSearchValuesKey], lastSearchValue],
    };
    onSetTrainingSearchValues(newTrainingSearchValues);
  };

  const removeInputAtIndex = index => {
    const newTrainingSearchValues = {
      ...trainingSearchValues,
      [trainingSearchValuesKey]: [
        ...trainingSearchValues[trainingSearchValuesKey].slice(0, index),
        ...trainingSearchValues[trainingSearchValuesKey].slice(index + 1),
      ],
    };
    onSetTrainingSearchValues(newTrainingSearchValues);
  };

  return (
    <Inputs>
      {values.map((value, index) => (
        <SearchValueInputContainer key={`${trainingSearchValuesKey}-${index}`}>
          <SearchValueInput
            key={index}
            min={min}
            step={step}
            type="number"
            value={value}
            onChange={e => setNewValue(e, index)}
          />
          {index > 0 &&  
            <CloseButtonContainer onClick={() => removeInputAtIndex(index)}>
              <Close />
            </CloseButtonContainer>
          }
        </SearchValueInputContainer>
      ))}
      <AddButtonContainer onClick={addNewSearchValueInput}>
        <Add />
      </AddButtonContainer>
    </Inputs>
  );
};

const TrainingConfigEditorAutotune = ({ trainingSearchValues, onSetTrainingSearchValues }) => {
  return (
    <Container>
      <InputsRow>
        <AttrTitle>Learning rate:</AttrTitle>
        <SearchValueInputGroup
          trainingSearchValuesKey="train.optimizer.learning_rate_init"
          trainingSearchValues={trainingSearchValues}
          onSetTrainingSearchValues={onSetTrainingSearchValues}
        />
      </InputsRow>
      <InputsRow>
        <AttrTitle>Batch Size: </AttrTitle>
        <SearchValueInputGroup
          trainingSearchValuesKey="batch_size"
          trainingSearchValues={trainingSearchValues}
          onSetTrainingSearchValues={onSetTrainingSearchValues}
        />
      </InputsRow>
      <InputsRow>
        <AttrTitle>Training Weight:</AttrTitle>
        <SearchValueInputGroup
          trainingSearchValuesKey="train.trn_weight"
          trainingSearchValues={trainingSearchValues}
          onSetTrainingSearchValues={onSetTrainingSearchValues}
        />
      </InputsRow>
      <InputsRow>
        <AttrTitle>Inference Likelihood Weight: </AttrTitle>
        <SearchValueInputGroup
          trainingSearchValuesKey="infer.lkh_weight"
          trainingSearchValues={trainingSearchValues}
          onSetTrainingSearchValues={onSetTrainingSearchValues}
        />
      </InputsRow>
      <InputsRow>
        <AttrTitle>Number of iterations: </AttrTitle>
        <SearchValueInputGroup
          trainingSearchValuesKey="train.max_iter"
          trainingSearchValues={trainingSearchValues}
          onSetTrainingSearchValues={onSetTrainingSearchValues}
        />
      </InputsRow>
    </Container>
  );
};

export default TrainingConfigEditorAutotune;
