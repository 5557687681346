import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { CenteredWithTopNavLayout } from "components/Layout";
import CubeListView from "components/views/CubeListView";
import { getLoggedInUserName } from "api/services/authenticationService";
import { SmallVerticalGap } from "components/Layout";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";

const TopButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  gap: 10px;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const CubeListPage = () => {
  const userName = getLoggedInUserName();
  const navigate = useNavigate();

  if (!userName) {
    return <CenteredWithTopNavLayout>Please log in first to see the content.</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <TopButtons>
        <div />
        <div />
        <ButtonCreateNewAsset
          onClick={() => navigate("/data?areCreateCubesBtnsHighlighted=true")}
          dataTutorialId="new-dataset-button"
        />
      </TopButtons>
      <SmallVerticalGap />
      <CubeListView />
    </CenteredWithTopNavLayout>
  );
};

export default CubeListPage;
