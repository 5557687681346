import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { getDataForOcrAnnotationTask, patchDataForOcrAnnotationTask } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import Button from "components/ui/Button";
import TextInput from "components/ui/TextInput";

const LabelArea = styled.input`
  width: 30px;
  height: 30px;
  border: 1px solid red;
  font-size: 20px;
`;

const Thumbnail = styled.div`
  position: relative;
  width: calc(950px / 6);
  height: calc(950px / 6);
  background-color: ${props => props.theme.color.closer0};
  ${props => props.base64Data && `background-image: url(data:image/png;base64,${props.base64Data})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  :hover {
    background-image: linear-gradient(to bottom, rgba(255, 255, 0, 0.5), rgba(0, 0, 255, 0.5)),
      url(data:image/png;base64,${props => props.base64Data});
  }
`;

const ImagesGrid = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const OcrAnnotationTaskPage = () => {
  const { ocrAnnotationTaskId } = useParams();
  const [ocrData, setOcrData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");

  useEffect(() => {
    doPopulateDataForTask();
  }, [ocrAnnotationTaskId]);

  const doPopulateDataForTask = async () => {
    const { data, error } = await getDataForOcrAnnotationTask(ocrAnnotationTaskId);
    setOcrData(data);
    setIsLoading(false);
    setError(error);
  };

  const doSaveDataForTask = async () => {
    const { error } = await patchDataForOcrAnnotationTask(ocrAnnotationTaskId, ocrData);
    if (!error) {
      alert("Success");
    } else {
      alert(JSON.stringify(error));
    }
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <Button value="Save" onClick={doSaveDataForTask} />
      <TextInput value={filterLabel} onNewInput={newFilterLabel => setFilterLabel(newFilterLabel)} />
      <Gap />
      <ImagesGrid>
        {ocrData
          .filter(instance => instance.label.includes(filterLabel))
          .map(instance => (
            <Thumbnail
              key={instance.id}
              base64Data={instance?.image}
              onMouseEnter={() => {
                const input = document.querySelector(`[id=input-${instance.id}]`);
                input?.select();
              }}
            >
              <LabelArea
                id={`input-${instance.id}`}
                value={instance?.label}
                onChange={e => {
                  const newLabel = e.target.value;
                  const newOcrData = [...ocrData];
                  newOcrData[instance.id] = { ...ocrData[instance.id], label: newLabel };
                  setOcrData(newOcrData);
                }}
              />
            </Thumbnail>
          ))}
      </ImagesGrid>
      <Gap />
      <Button value="Save" onClick={() => doSaveDataForTask()} />
    </CenteredWithTopNavLayout>
  );
};

export default OcrAnnotationTaskPage;
