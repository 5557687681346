import styled from "styled-components";
import { Gap } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import Button from "components/ui/Button";
import SelectInput from "components/ui/SelectInput";

const TitleAndStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const Description = styled.div``;

const QuestionContainer = styled.div``;

const QuestionTitle = styled.div`
  font-weight: bold;
`;

const PreviewButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 5px 10px;
`;

const BigTitleNoPadding = styled(BigTitle)`
  padding-top: 0;
`;

const SurveySubmitResponseView = ({
  survey,
  responseData,
  onSetResponseData,
  onClickSubmit,
  hasResponseBeenSubmitted,
  onClickEdit,
  isSubmittingResponse,
}) => {
  return (
    <>
      {survey?.status === "FINALISED" && <Gap />}
      <TitleAndStatus>
        <BigTitleNoPadding>{survey?.data?.title}</BigTitleNoPadding>
        {survey?.status !== "FINALISED" && <PreviewButton value="Edit" onClick={onClickEdit} />}
      </TitleAndStatus>
      <Gap height="10px" />
      <Description>{survey?.data?.description}</Description>
      <Gap height="40px" />
      {survey?.data?.questions.map((question, i) => (
        <QuestionContainer key={i}>
          <QuestionTitle>{question?.title}</QuestionTitle>
          <Gap height="10px" />
          {question?.type === "text" ? (
            <TextInput
              value={responseData?.[survey?.fields?.[i]] || ""}
              placeholder="Answer"
              onNewInput={answerText => {
                const newResponseData = { ...responseData, [survey?.fields?.[i]]: answerText };
                onSetResponseData(newResponseData);
              }}
            />
          ) : (
            <SelectInput
              value={responseData?.[survey?.fields?.[i]] || ""}
              onSetNewValue={newSelectedOption => {
                const newResponseData = { ...responseData, [survey?.fields?.[i]]: newSelectedOption };
                onSetResponseData(newResponseData);
              }}
            >
              {question?.options.map((option, optionInd) => (
                <option value={option} key={optionInd}>
                  {option}
                </option>
              ))}
            </SelectInput>
          )}
          <Gap height="20px" />
        </QuestionContainer>
      ))}
      <Gap />
      {hasResponseBeenSubmitted ? (
        <div>Thank you, your response has been submitted.</div>
      ) : (
        <Button
          isDisabled={survey?.status !== "FINALISED" || isSubmittingResponse}
          value="Submit"
          onClick={onClickSubmit}
        />
      )}
    </>
  );
};

export default SurveySubmitResponseView;
