import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { doAnyPipelinesHaveGptTypeModels, getCubeByCubeId } from "api/services/projectService";
import CubeView from "components/views/CubeView";
import CubeViewRL from "components/views/CubeViewRL";
import { CenteredWithTopNavLayout } from "components/Layout";
import CubeViewFineTune from "components/views/CubeViewFineTune";

const CubePage = () => {
  const { cubeId } = useParams();
  const [cube, setCube] = useState(null);
  const [cubeFetchError, setCubeFetchError] = useState(null);
  const [isFinetuneCube, setIsFinetuneCube] = useState(false);

  useEffect(() => {
    doFetchCube();
  }, [cubeId]);

  const doFetchCube = async () => {
    const { data, error: cubeFetchErr } = await getCubeByCubeId(cubeId);
    setCubeFetchError(cubeFetchErr);
    setCube(data);
  };

  useEffect(() => {
    doPopulateIsCubeAutotune();
  }, [cube?.pipelineIds]);

  const doPopulateIsCubeAutotune = async () => {
    const { data } = await doAnyPipelinesHaveGptTypeModels(cube?.pipelineIds);
    setIsFinetuneCube(data);
  };

  if (cubeFetchError) {
    return <CenteredWithTopNavLayout>{JSON.stringify(cubeFetchError)}</CenteredWithTopNavLayout>;
  }

  if (!cube) {
    return <CenteredWithTopNavLayout>Loading cube...</CenteredWithTopNavLayout>;
  }

  if (isFinetuneCube) {
    return (
      <CenteredWithTopNavLayout>
        <CubeViewFineTune cube={cube} />
      </CenteredWithTopNavLayout>
    );
  }

  return (
    <CenteredWithTopNavLayout>
      {cube.isRL ? <CubeViewRL cube={cube} /> : <CubeView cube={cube} />}
    </CenteredWithTopNavLayout>
  );
};

export default CubePage;
