import { CircularProgress } from "@material-ui/core";
import { demoUserSendMeetingRequestEmail, getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import Button from "components/ui/Button";
import { CopyToClipboardIcon } from "components/ui/Icons.js";
import NavWithTabs from "components/ui/NavWithTabs";
import ProgressBar from "components/ui/ProgressBar.js";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import CalendarIntegrate from "./CalendarIntegrate.js";
import Documentation from "./Documentation.js";
import GuestEmailConfigModal from "./GuestEmailConfigModal.js";
import LoggedInUserEmailConfigModal from "./LoggedInUserEmailConfigModal.js";
import Setting from "./Setting.js";
import SwtichTabRemindModal from "./SwitchTabRemindModal";
import TestEmailNotifyModal from "./TestEmailNotifyModal.js";

const AppHead = styled.div`
  display: grid;
  grid-template-columns: 200px 400px 1fr;
  background-color: ${props => props.theme.color.furthest};
  padding-left: 100px;
`;

const AppImgContainer = styled.div`
  img {
    border-radius: 8px;
    height: 50px;
    width: 50px;
  }

  display: flex;
  padding-top: 15px;
  justify-content: center;
`;

const AppHeadTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 10px;
`;

const AppHeadDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  overflow-y: auto;
  color: ${props => props.theme.color.closer3};
`;

const AppTabInfoContainer = styled.div`
  padding: 50px 20px 20px 100px;
  height: auto;
  min-height: calc(100vh - 250px);
  background-color: ${props => props.theme.color.closer0};
  border-top: 2px solid ${props => props.theme.color.closer1};
`;

const AppTabInfoTwoColLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 100px;
`;
const AppTabInfoTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 6px;
`;

const AppTabInfoDescription = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-top: 6px;
  color: ${props => props.theme.color.closer3};
`;

const NavTabContainer = styled.div`
  padding-left: 100px;
`;

const LeftAlignedButtons = styled.div`
  padding: 15px 0px 15px 0px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
`;

const MsgDiv = styled.div`
  color: ${props => props.theme.color.success};
`;

const RequestLinkText = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
`;

const CopyButtonContainer = styled.div`
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  :active {
    background-color: ${props => props.theme.color.closer1_5};
  }
  transition: background-color 0.1s;

  svg {
    fill: ${props => props.theme.color.closer2};
  }
`;

const CompleteProfileMessage = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 5px;
  column-gap: 10px;
  align-items: center;
`;

const StyledProgressBar = styled(ProgressBar)`
  background-color: ${props => props.theme.color.closer1_5};
`;

const CompleteButton = styled(Button)`
  min-height: 0;
  min-width: 0;
  padding: 2px 10px;
`;

const ProfileIncompleteText = styled.div`
  font-weight: bold;
  grid-column: span 2;
`;

const CalendarView = ({ tab }) => {
  const [selectedTab, setSelectedTab] = useState(tab || "Overview");
  const [userProfile, setUserProfile] = useState(null);
  const [newTab, setNewTab] = useState(null);
  const [showSwitchTabRemindModal, setShowSwitchTabRemindModal] = useState(false);
  const [showGuestEmailConfigModal, setShowGuestEmailConfigModal] = useState(false);
  const [showLoggedInUserEmailConfigModal, setShowLoggedInUserEmailConfigModal] = useState(false);
  const [showTestEmailNotifyModal, setShowTestEmailNotifyModal] = useState(false);
  const [testEmailNotifyType, setTestEmailNotifyType] = useState("success");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isEmailUpdated, setIsEmailUpdated] = useState(false);
  const [isProfileUpdated, setIsProfileUpdated] = useState(false);
  const [isPreferenceUpdated, setIsPreferenceUpdated] = useState(false);
  const [updatedSection, setUpdatedSection] = useState(null);
  const [fieldToFocus, setFieldToFocus] = useState("");

  const userName = getLoggedInUserName();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.has("post-oauth-callback")) {
      if (userName) {
        setShowGuestEmailConfigModal(false);
        setShowLoggedInUserEmailConfigModal(true);
      } else {
        setShowGuestEmailConfigModal(true);
        setShowLoggedInUserEmailConfigModal(false);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    doFetchUserProfile();
  }, [userName]);

  const doFetchUserProfile = async () => {
    const { data, error } = await getUserProfileByUserName(userName);

    if (!error) {
      setUserProfile(data);
    }
  };

  const tabs = ["Overview", "Documentation", "Setting"];

  const handleSwitchTabs = newTab => {
    if (selectedTab === "Setting") {
      if (isEmailUpdated || isProfileUpdated || isPreferenceUpdated) {
        isEmailUpdated && setUpdatedSection("Contact Emails");
        isProfileUpdated && setUpdatedSection("Profile");
        isPreferenceUpdated && setUpdatedSection("Preferences");
        setShowSwitchTabRemindModal(true);
      } else {
        setSelectedTab(newTab);
      }
    } else {
      setSelectedTab(newTab);
    }
  };

  const doSendAMeetingRequestEmail = async () => {
    const email = userProfile.email;
    let emailBody = `Hi ${userName}, \n\n Let's book a 1 hour meeting tomorrow at 7pm. \n\n warm regards \n\n demo-user`;
    setIsSendingEmail(true);
    const { error: requestError } = await demoUserSendMeetingRequestEmail({
      from: "me",
      to: [email],
      subject: "shall we have a meeting ?",
      bodyText: emailBody,
    });
    setIsSendingEmail(false);
    setShowTestEmailNotifyModal(true);
    if (requestError) {
      setTestEmailNotifyType("fail");
    } else {
      setTestEmailNotifyType("success");
    }
  };

  const numOfFilledProfileFields = Object.keys(userProfile || {})
    ?.filter(field => ["firstName", "lastName", "email", "organisation", "position"].includes(field))
    ?.filter(field => !!userProfile[field])?.length;

  return (
    <>
      <GuestEmailConfigModal
        open={showGuestEmailConfigModal}
        onOK={() => {
          setShowGuestEmailConfigModal(false);
        }}
        handleClose={() => {
          setShowGuestEmailConfigModal(false);
        }}
      />
      <LoggedInUserEmailConfigModal
        open={showLoggedInUserEmailConfigModal}
        userName={userName}
        handleClose={() => {
          setShowLoggedInUserEmailConfigModal(false);
        }}
      />
      <SwtichTabRemindModal
        open={showSwitchTabRemindModal}
        handleClose={() => setShowSwitchTabRemindModal(false)}
        handleConfirm={() => {
          setShowSwitchTabRemindModal(false);
          setSelectedTab(newTab);
        }}
        section={updatedSection}
      />
      <TestEmailNotifyModal
        open={showTestEmailNotifyModal}
        handleClose={() => setShowTestEmailNotifyModal(false)}
        email={userProfile?.email}
        type={testEmailNotifyType}
      />
      <AppHead>
        <AppImgContainer>
          <img src={"/images/market-place/calendar.png"} alt={"calendar"} />
        </AppImgContainer>
        <div>
          <AppHeadTitle>{"Calendar Assitant"}</AppHeadTitle>
          <AppHeadDescription>
            AI-powered scheduling technology to unlock you from searching in dense calendars, back-and-forth booking
            emails. Simple setup and free integration with Google Calendar.
          </AppHeadDescription>
          {isEnabled && (
            <MsgDiv
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://demo.platform.boltzbit.com/market-place/calendar/meeting-request?host=${userName}`
                )
              }
            >
              Your Calendar has been synced. Check out your&nbsp;
              <RequestLinkText>
                <a href={`https://demo.platform.boltzbit.com/market-place/calendar/meeting-request?host=${userName}`}>
                  public meeting request link
                </a>
                &nbsp;
                <CopyButtonContainer>
                  <CopyToClipboardIcon />
                </CopyButtonContainer>
              </RequestLinkText>
            </MsgDiv>
          )}
          {userName && numOfFilledProfileFields < 5 && (
            <CompleteProfileMessage>
              <ProfileIncompleteText>Your profile is incomplete ({numOfFilledProfileFields}/5):</ProfileIncompleteText>
              <StyledProgressBar currentValue={numOfFilledProfileFields} maxValue={5} />
              <CompleteButton
                value="Complete now"
                onClick={() => {
                  const emptyFields = Object.keys(userProfile || {})?.filter(field => !userProfile[field]);
                  const fieldToFocus = ["firstName", "lastName", "email", "organisation", "position"].find(field =>
                    emptyFields?.includes(field)
                  );
                  setFieldToFocus(fieldToFocus);
                  setSelectedTab("Setting");
                }}
              />
            </CompleteProfileMessage>
          )}
          <LeftAlignedButtons>
            <CalendarIntegrate
              platformRedirectUrl={"/market-place/calendar?post-oauth-callback"}
              onPostOatuh={() => setIsEnabled(true)}
              onRevokePostOauth={() => setIsEnabled(false)}
            />
            {isEnabled ? (
              isSendingEmail ? (
                <CircularProgress />
              ) : (
                <Button
                  value={"Try it"}
                  onClick={() => {
                    doSendAMeetingRequestEmail();
                  }}
                />
              )
            ) : (
              <></>
            )}
          </LeftAlignedButtons>
        </div>
        <div></div>
      </AppHead>
      <NavTabContainer>
        <NavWithTabs
          tabNames={tabs}
          selectedTabName={selectedTab}
          onTabSelect={newTab => {
            setNewTab(newTab);
            handleSwitchTabs(newTab);
          }}
        />
      </NavTabContainer>
      {selectedTab === "Documentation" && (
        <AppTabInfoContainer>
          <AppTabInfoDescription>
            <Documentation userProfile={userProfile} />
          </AppTabInfoDescription>
        </AppTabInfoContainer>
      )}
      {selectedTab === "Overview" && (
        <AppTabInfoContainer>
          <AppTabInfoTwoColLayout>
            <div>
              <AppTabInfoTitle>Overview</AppTabInfoTitle>
              <AppTabInfoDescription>
                AI-powered scheduling technology to unlock you from searching in dense calendars, back-and-forth booking
                emails. Simple setup and free integration with Google Calendar. Support multiple Email addresses to help
                you better manage your daily life and work.
              </AppTabInfoDescription>
            </div>
          </AppTabInfoTwoColLayout>
        </AppTabInfoContainer>
      )}
      {selectedTab === "Setting" && (
        <AppTabInfoContainer>
          <AppTabInfoTwoColLayout>
            <div>
              <Setting
                fieldToFocus={fieldToFocus}
                onUpdateProfile={newProfile => setUserProfile(newProfile)}
                isEnabled={isEnabled}
                userName={userName}
                onEmailsUpdated={() => {
                  setIsEmailUpdated(true);
                }}
                onEmailsUnchanged={() => {
                  setIsEmailUpdated(false);
                }}
                onUserProfileUpdated={() => {
                  setIsProfileUpdated(true);
                }}
                onUserProfileUnchanged={() => {
                  setIsProfileUpdated(false);
                }}
                onPreferencesUpdated={() => {
                  setIsPreferenceUpdated(true);
                }}
                onPreferencesUnchanged={() => {
                  setIsPreferenceUpdated(false);
                }}
              />
            </div>
          </AppTabInfoTwoColLayout>
        </AppTabInfoContainer>
      )}
    </>
  );
};

export default CalendarView;
