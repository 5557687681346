import { useState, useEffect } from "react";
import styled from "styled-components";
import { round } from "lodash";
import { getEvaluationJobById } from "api/services/jobService";
import ExpandableTaskEvaluation from "./ExpandableTaskEvaluation";
import { Gap } from "components/Layout";

const Container = styled.div`
  padding: 20px;
`;

const TrainingMetricsContainer = styled.div`
  padding: 15px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
  width: calc(33% - 10px);
`;

const PanelTitle = styled.div`
  padding: 20px 20px 20px 0;
  font-size: 20px;
  font-weight: bold;
`;

const TaskEvaluationsContainer = styled.div``;

const TrainingMetricName = styled.td`
  font-weight: bold;
  padding-bottom: 10px;
`;

const TrainingMetricValue = styled.td``;

const metricNamesToMetricLabels = {
  inferLoss: "Data loss",
  trainLoss: "Feature loss",
};

const EvaluationView = ({ evalJobId, pipelineOutput }) => {
  const [evaluationJob, setEvaluationJob] = useState(null);
  const [error, setError] = useState(null);

  const doFetchEvaluationJob = async () => {
    const { data, error } = await getEvaluationJobById(evalJobId);
    setEvaluationJob(data);
    setError(error);
  };

  useEffect(() => {
    doFetchEvaluationJob();
  }, [pipelineOutput?.evaluationJob?.status]);

  return (
    <Container>
      <PanelTitle>Training Job Metrics</PanelTitle>
      {evaluationJob?.result?.trainingMetrics && (
        <TrainingMetricsContainer>
          <table>
            <tbody>
              {Object.keys(evaluationJob?.result?.trainingMetrics)
                .filter(metricName => metricName === "inferLoss" || metricName === "trainLoss")
                .map(metricName => {
                  let metricValue = evaluationJob.result.trainingMetrics[metricName];
                  if (typeof metricValue === "number") {
                    metricValue = round(metricValue, 4);
                  }
                  if (metricValue.length) {
                    metricValue = metricValue.map(val => round(val, 4)).join(" , ");
                  }

                  return (
                    <tr key={metricName}>
                      <TrainingMetricName>{metricNamesToMetricLabels[metricName]}</TrainingMetricName>
                      <TrainingMetricValue>{metricValue}</TrainingMetricValue>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </TrainingMetricsContainer>
      )}
      <Gap height="40px" />
      <PanelTitle>Offline Task Evaluations</PanelTitle>
      {evaluationJob?.result?.taskEvaluations && (
        <TaskEvaluationsContainer>
          {evaluationJob.result.taskEvaluations.map(taskEvaluation => (
            <ExpandableTaskEvaluation key={taskEvaluation.taskName} taskEvaluation={taskEvaluation} />
          ))}
        </TaskEvaluationsContainer>
      )}
      {error && JSON.stringify(error)}
    </Container>
  );
};

export default EvaluationView;
