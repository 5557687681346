import styled from "styled-components";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

const TrainingDonePage = styled.div`
  width: 100%;
  height: 400px;
  padding-top: 100px;
  justify-content: center;
  display: flex;
  font-size: 18px;
  color: ${props =>  props.theme.color.primary}; 
`


const TrainingView = () => {
  return (
    <TrainingDonePage><DoneOutlineIcon/> Training successfully completed !</TrainingDonePage>
  )
}


export default TrainingView;