import { useState, useEffect } from "react";
import map from 'lodash/fp/map';
import flatMap from 'lodash/fp/flatMap';
import filter from 'lodash/fp/filter';
import range from 'lodash/fp/range';
import Grid from "./Grid";
import Stone from "./Stone";
import styled from "styled-components";
import { PlayCircleFilled, PauseCircleFilled } from "@material-ui/icons";

let range0 = range(0);
let compact = filter(Boolean);

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const Indicator = styled.div`
  height: 20px;
`

const WhiteSpan = styled.span`
  padding: 3px;
  bottom: 5px;
  background: white;
  border-radius: 8px;
  border: 1px black solid;
  color: black;
  margin-right: 5px;
`

const BlackSpan = styled.span`
  padding: 3px;
  bottom: 5px;
  background: black;
  border-radius: 8px;
  border: 1px black solid;
  color: lightgray;
`


const GoGameView = ({ game }) => {
  const [board, setBoard] = useState(null);
  const [stones, setStones] = useState(null);
  const [size, setSize] = useState(9);
  const [scale, setScale] = useState(20);
  const [cellSize, setCellSize] = useState(20);
  const [viewBox, setViewBox] = useState(null);
  const [isPaused, setisPaused] = useState(false);
  const [timeoutIds, setTimeoutIds] = useState([]);
  const [pausedId, setPausedId] = useState(0);

  useEffect(() => {
    initializeBoard();
    setViewBox(`0 0 ${size * scale} ${size * scale}`);
    game && game.forEach((arrayData, i) => {
      setTimeoutIds([...timeoutIds, 
          setTimeout(() => {
            setBoard(array2Matrix(arrayData));
            setPausedId(pausedId + 1)
          }, i * 500)
        ]
      );
    })
  }, []);
  
  useEffect(() => {
    board && updateStones();
  }, [board]);
  
  const initializeBoard = () => {
    let rows = [];
    for (let i = 0; i < size; ++i) {
      let cols = [];
      for (let j = 0; j < size; ++j) {
        cols[j] = 0;
      }
      rows[i] = cols;
    }
    setBoard(rows);
  };

  const updateStones = () =>  {
    let ret = compact(flatMap(y => {
      return map(x => {
        let boardValue = board[y][x]
        if (!boardValue) {
          return null;
        }
        let color = boardValue > 0 ? "white" : "black";
        let stone = {
          x: x,
          y: y,
          color: color
        };
        return stone;
      })(range0(size));
    })(range0(size)));
    setStones(ret)
  };

  const array2Matrix = (arrayData) => {
    const mat = map(y => {
      return map(x => {
        let index = y * size + x;
        return arrayData[index];
      })(range0(size));
    })(range0(size));
    return mat;
  }; 

  const pointToCoordinate = (x) => {
    let cs = cellSize;
    return x * cs + cs / 2.0;
  };

  const doPauseGame = () => {
    for (var i = 0; i < timeoutIds.length; i++) {
      clearTimeout(timeoutIds[i]);
    }
    setisPaused(true);
  }

  const doPlayGame = () => {
    const start = pausedId;
    game && game.slice(start, -1).forEach((arrayData, i) => {
      setTimeoutIds([...timeoutIds, 
          setTimeout(() => {
            setBoard(array2Matrix(arrayData));
            setPausedId(pausedId + 1)
          }, i * 500)
        ]
      );
    })
  }



  
  return (
    <Container>
       <Indicator>
        <WhiteSpan> Opponent</WhiteSpan>
      
        <BlackSpan> BoltzGo</BlackSpan>
      </Indicator>
      {/* { isPaused ? <PlayCircleFilled onClick={doPlayGame} />: <PauseCircleFilled onClick={doPauseGame} />} */}
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
            class="board"
            viewBox={viewBox} 
            preserveAspectRatio="xMidYMid meet">
          {  size && <Grid size={size} /> }
          {
              stones &&  
              <g>
                {
                    stones.map(stone => (
                      <Stone x={pointToCoordinate(stone.x)} y={pointToCoordinate(stone.y)} color={stone.color} key={stone.x + "|" + stone.y} />
                      )
                    )
                }
              </g>
          }
        </svg>
    </Container>    
  )
}

export default GoGameView;