import styled from "styled-components";

import NavHeader from "components/widgets/NavHeader";
import Navigation from "components/navigation/Navigation";
import UnLoggedInNavigation from "./navigation/UnLoggedInNavigation";
import { isUserLoggedIn } from "api/services/authenticationService";
import ItemListPopover from "components/ui/ItemListPopover";
import { Link } from "react-router-dom";
import { CubesIcon, DataIcon, FlowLogoInner, Logo, PlusIcon, TutorialsIcon } from "components/ui/Icons";

const ICON_SIZE = "20px";

const CenteredContentContainer = styled.div`
  display: grid;
  grid-template-columns: 62px 1fr ${props => props.centerColumnMaxWidth} 1fr;
  padding-bottom: 50px;
`;

const FullWidthContent = styled.div`
  width: 100%;
`;

const Content = styled.div`
  padding-top: 80px;
  grid-column: 3;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
`;

const SideNavContainer = styled.div`
  background-color: black;
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;

  display: grid;
  grid-auto-flow: row;
  justify-content: center;
  justify-items: center;
  align-content: start;
`;

const SideNavItem = styled(Link)`
  text-decoration: none;

  color: #a1a1a1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 10px;
  padding: 14px 0px;
  width: 100%;
  min-width: 62px;
  align-items: center;
  transition: background-color 0.1s, color 0.1s;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer2}44;
    color: ${props => props.theme.color.furthest};
  }

  border-left: 2px solid ${props => (props.isActive ? props.borderColor : "transparent")};

  svg {
    fill: ${props => (props.$isHighlighted ? "white" : "url(#SvgGradientPurpleToBlue)")};
  }
`;

const SideNavItemDiv = styled(SideNavItem).attrs({ as: "div" })``;

const StyledItemListPopover = styled(ItemListPopover)`
  margin-top: 0;
  margin-left: 0;
  border-radius: 0;

  transform: translate(100%, -40px);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2), 0 0 8px rgba(0, 0, 0, 0.2);
`;

const SideNavLogo = styled(Logo)`
  fill: ${props => props.theme.color.furthest};
  height: 28px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

// const TopNavLogo = styled(Logo)`
//   fill: ${props => props.theme.color.closest};
//   height: 28px;
// `;

const GreyDivider = styled.div`
  width: 70%;
  height: 1px;
  background-color: #3a3a3a;
`;

const CreationBtns = styled.div`
  background-color: ${props => props.theme.color.furthest};
  white-space: nowrap;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const NewItemBtn = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 12px;
  cursor: pointer;
  text-decoration: none;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

export const CenteredWithTopNavLayout = ({
  children,
  isHeaderEnabled = true,
  isDisabled,
  centerColumnMaxWidth = "1000px",
}) => {
  if (!isUserLoggedIn()) {
    <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
      <UnLoggedInNavigation />
      <Content isDisabled={isDisabled}>{children}</Content>
    </CenteredContentContainer>;
  }

  return (
    <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
      {/* <Navigation /> */}
      <SideNavContainer>
        <Link to="/">
          <SideNavLogo />
        </Link>
        <GreyDivider />

        <StyledItemListPopover
          iconComponent={
            <SideNavItemDiv>
              <PlusIcon style={{ fill: "white" }} />
            </SideNavItemDiv>
          }
        >
          <CreationBtns>
            <NewItemBtn to="/data/create">
              <DataIcon />
              <div>New Dataset</div>
            </NewItemBtn>
            <NewItemBtn to="/data?areCreateCubesBtnsHighlighted=true">
              <CubesIcon />
              <div>New Cube</div>
            </NewItemBtn>
          </CreationBtns>
        </StyledItemListPopover>
        <GreyDivider />
        <SideNavItem isActive={window.location.pathname?.includes("/cube")} to="/cubes">
          <CubesIcon style={{ height: ICON_SIZE }} />
          Cubes
        </SideNavItem>
        <SideNavItem isActive={window.location.pathname?.includes("/data")} to="/data">
          <DataIcon style={{ height: ICON_SIZE }} />
          Data
        </SideNavItem>
        <SideNavItem isActive={window.location.pathname === "/flow"} to="/flow">
          <FlowLogoInner style={{ height: ICON_SIZE }} />
          Flow
        </SideNavItem>
        {/* <SideNavItem isActive={window.location.pathname === "/tutorials"} to="/tutorials">
          <TutorialsIcon style={{ height: ICON_SIZE }} />
          Tutorials
        </SideNavItem> */}
      </SideNavContainer>
      {isHeaderEnabled && <NavHeader />}
      <Content isDisabled={isDisabled}>{children}</Content>
    </CenteredContentContainer>
  );
};

export const UnLoggedInCenteredWithTopNavLayout = ({ children, isDisabled, centerColumnMaxWidth = "1000px" }) => (
  <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
    <UnLoggedInNavigation />
    <Content isDisabled={isDisabled}>{children}</Content>
  </CenteredContentContainer>
);

export const NoSideNavWithTopNavLayout = ({ children }) => <FullWidthContent>{children}</FullWidthContent>;

export const TwoColumnLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftWidth || "300px"} ${props => props.rightWidth || "1fr"};
  gap: 40px;
`;

export const TwoRowLayout = styled.div``;

export const ThreeColumnLayout = styled.div`
  display: grid;
  grid-template-columns: ${props => props.leftWidth || "1fr"} ${props => props.middleWidth || "1fr"} ${props =>
      props.rightWidth || "1fr"};
  gap: 40px;
`;

export const CenterContent = styled.div`
  display: flex;
  justify-content: center;
`;

export const RightAlignContent = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LeftAlignContent = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const BigVerticalGap = styled.div`
  height: 200px;
  width: 100%;
`;

export const MediumVerticalGap = styled.div`
  height: 100px;
  width: 100%;
`;

export const SmallVerticalGap = styled.div`
  height: 50px;
  width: 100%;
`;

export const CustomizedVerticalGap = styled.div`
  height: ${props => props.height};
`;

export const Gap = styled.div`
  height: ${props => props.height || "20px"};
`;
