import { useState, useEffect } from "react";
import styled from "styled-components";
import { getRepoSlugOfCubeIfExists } from "api/services/githubService";
import CubeReadmeViewer from "components/widgets/CubeReadmeViewer";
import ReadmeViewer from "components/widgets/ReadmeViewer";
import { ForkIcon, HeartIcon, LicenseIcon, PytorchIcon, ReadmeIcon, TensorflowIcon } from "components/ui/Icons";
import { patchCube } from "api/services/projectService";
import { Edit } from "@material-ui/icons";
import Modal from "components/ui/Modal";
import EditCubeAboutInfoView from "components/views/EditCubeAboutInfoView";
import UserBadgePicOnly from "components/ui/UserBadgePicOnly";
import Button from "components/ui/Button";
import { useNavigate } from "react-router-dom";
import TryDeploymentModal from "components/views/TryDeploymentModal";

const SplitLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 5px;
`;

const ReadmeContainer = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.closer0_5};
`;

const SideInfoContainer = styled.div`
  height: max-content;
  border-left: 1px solid ${props => props.theme.color.furthest};
  background-color: ${props => props.theme.color.closer0};
  padding-bottom: 10px;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const SideInfoBlock = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.color.closer0};
  margin-left: 5px;
  margin-right: 5px;
`;

const SideInfoBlockSideBySide = styled(SideInfoBlock)`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
`;

const Tags = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  color: ${props => props.theme.color.closer2};
  font-size: 12px;
`;

const Tag = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SideInforTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
`;

const ContributorContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const CircleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.furthest};
  border-radius: 50%;
  border: 2px solid ${props => props.theme.color.closest};
  height: 25px;
  width: 25px;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  svg {
    font-size: 15px;
  }

  :hover {
    color: ${props => props.theme.color.primary};
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const ReadmeAnchor = styled.a`
  text-decoration: none;
`;

const ReadmeGreyIcon = styled(ReadmeIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 24px;
`;

const LicenseGreyIcon = styled(LicenseIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 22px;
`;

const GreyForkIcon = styled(ForkIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 24px;
`;

const GreyHeartIcon = styled(HeartIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 22px;
`;

const CornerRect = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const GoToDeploymentButton = styled(Button)`
  padding: 5px 20px;
`;

const CubeAboutView = ({ cube, modelId, inputName }) => {
  const [repoSlug, setRepoSlug] = useState("");
  const [editedCube, setEditedCube] = useState(cube);
  const [isSavingCube, setIsSavingCube] = useState(false);
  const [isEditAboutModalOpen, setIsEditAboutModalOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    doPopulateRepoSlug();
  }, [cube?.id]);

  const doPopulateRepoSlug = async () => {
    const { data: repoSlug } = await getRepoSlugOfCubeIfExists(cube?.id);
    setRepoSlug(repoSlug);
  };

  const doPatchCube = async newCube => {
    setIsSavingCube(true);
    const { data, error } = await patchCube(cube.id, newCube);
    setIsSavingCube(false);
    if (error) {
      alert(JSON.stringify(error));
      return;
    }
    setEditedCube(data);
    setIsEditAboutModalOpen(false);
  };

  let frameworkIcon = <div>{editedCube?.framework}</div>;
  if (editedCube?.framework === "Tensorflow") {
    frameworkIcon = <TensorflowIcon />;
  }
  if (editedCube?.framework === "Pytorch") {
    frameworkIcon = <PytorchIcon />;
  }

  return (
    <SplitLayout>
      <ReadmeContainer>
        <ReadmeAnchor id="readme" />
        {repoSlug ? (
          <ReadmeViewer repoSlug={repoSlug} branchName="main" showExpandToggle={false} />
        ) : (
          <CubeReadmeViewer
            cubeName={cube?.name}
            isSavingCube={isSavingCube}
            editedCube={editedCube}
            onSaveNewCube={doPatchCube}
            showExpandToggle={false}
          />
        )}
        <CornerRect style={{ top: "0", left: "0" }} />
        <CornerRect style={{ top: "0", right: "0" }} />
        <CornerRect style={{ bottom: "0", left: "0" }} />
        <CornerRect style={{ bottom: "0", right: "0" }} />
      </ReadmeContainer>
      <SideInfoContainer>
        <SideInfoBlock>
          <SideInforTitle>
            About
            <CircleButton onClick={() => setIsEditAboutModalOpen(true)}>
              <Edit />
            </CircleButton>
          </SideInforTitle>
          {editedCube?.description || "No description yet"}
          <Tags>
            <ReadmeAnchor href="#readme">
              <Tag>
                <ReadmeGreyIcon /> Readme
              </Tag>
            </ReadmeAnchor>
            <Tag>
              <LicenseGreyIcon /> {editedCube?.license}
            </Tag>
            <Tag>
              <GreyHeartIcon /> 0 Likes
            </Tag>
            <Tag>
              <GreyForkIcon /> 0 Forks
            </Tag>
          </Tags>
        </SideInfoBlock>
        <SideInfoBlock>
          <SideInforTitle>Contributors</SideInforTitle>
          <ContributorContainer>
            {cube?.sharedWith?.map(sharedWithUser => (
              <UserBadgePicOnly userId={sharedWithUser?.userGroupId} />
            ))}
          </ContributorContainer>
        </SideInfoBlock>
        <SideInfoBlockSideBySide>
          {/* <GoToDeploymentButton value="Try it" variant="highlighted" onClick={() => navigate("?tab=API")} /> */}
          <TryDeploymentModal inputName={inputName} modelId={modelId} />
        </SideInfoBlockSideBySide>
      </SideInfoContainer>
      <Modal title="Edit cube" open={isEditAboutModalOpen} handleClose={() => setIsEditAboutModalOpen(false)}>
        <EditCubeAboutInfoView isSavingCube={isSavingCube} editedCube={editedCube} onClickSaveNewCube={doPatchCube} />
      </Modal>
    </SplitLayout>
  );
};

export default CubeAboutView;
