import { CenteredWithTopNavLayout } from "components/Layout";
import CreateModelView from "components/views/CreateModelView";

const CreateModelPage = () => (
  <CenteredWithTopNavLayout>
    <CreateModelView />
  </CenteredWithTopNavLayout>
);

export default CreateModelPage;
