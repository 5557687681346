import { useState } from "react";
import styled from "styled-components";

import { SmallTitle as StandardSmallTitle } from "components/ui/Text";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import { postModelRecommendation } from "api/services/modelService";
import { patchPipelineConfig, postPipelineConfig } from "api/services/projectService";
import { useNavigate } from "react-router-dom";
import { sleep } from "utils/common";

const OuterContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 175px;
  width: calc(100% - 175px);
  height: 1000px;
  background-color: ${props => props.theme.color.furthest}FF;

  display: grid;
  padding-top: 40px;
  justify-content: center;
`;

const Container = styled.div`
  padding: 20px;
  width: 1000px;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;
`;

const FeaturesContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Selectable = styled.div`
  background-color: ${props => props.theme.color.closer1};
  border: 2px solid ${props => (props.isSelected ? props.theme.color.primary : "transparent")};
  padding: 5px 15px;
  border-radius: 50px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
`;

const ModelFeatureCardsContainer = styled.div`
  padding: 10px;
  border-radius: 5px;
  border: 1px dashed ${props => props.theme.color.closer1_5};
  display: flex;
  gap: 5px;
  min-height: 84px;
`;

const FeatureCardContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  background-color: ${props => props.theme.color.closer1};
  padding: 5px;
  border-radius: 5px;
  gap: 5px;
`;

const Bold = styled.div`
  font-weight: 600;
`;

const ErrorMsg = styled.div`
  padding-top: 10px;
  color: ${props => props.theme.color.error};
`;

const SmallTitle = styled(StandardSmallTitle)`
  padding: 0;
  padding-bottom: 10px;
  font-weight: 600;
`;

const SectionTitle = styled.div`
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 25px;
`;

const ModelFeatureCard = ({ feature }) => (
  <FeatureCardContainer>
    <Bold>name: </Bold>
    <div>{feature?.name}</div>
    <Bold>type: </Bold>
    <div>{feature?.type}</div>
    <Bold>shape: </Bold>
    <div>{JSON.stringify(feature?.shape)}</div>
  </FeatureCardContainer>
);

const ModelFeaturesEditor = ({ featureTypeDescriptors, modelFeatures, onNewModelFeatures }) => {
  return (
    <>
      <FeaturesContainer>
        {featureTypeDescriptors?.map(feature => {
          const isSelected = modelFeatures?.find(inputFeature => inputFeature?.name === feature?.key);

          return (
            <Selectable
              onClick={() => {
                const newInput = {
                  name: feature?.key,
                  type: feature?.type?.structure === "TEXT" ? "TEXT" : "CATEGORICAL",
                  shape: feature?.type?.shape || [feature?.type?.categories?.length],
                };
                const newInputs = isSelected
                  ? modelFeatures?.filter(inputFeature => inputFeature?.name !== feature?.key)
                  : [...modelFeatures, newInput];

                onNewModelFeatures(newInputs);
              }}
              isSelected={isSelected}
              key={feature?.key}
            >
              {feature?.key}
            </Selectable>
          );
        })}
      </FeaturesContainer>
      <Gap height="10px" />
      <ModelFeatureCardsContainer>
        {modelFeatures?.map(modelFeature => (
          <ModelFeatureCard key={modelFeature?.name} feature={modelFeature} />
        ))}
      </ModelFeatureCardsContainer>
    </>
  );
};

const BaseModelCards = styled.div`
  display: flex;
  gap: 10px;
`;

const BaseModelContainer = styled.div`
  width: 350px;
  background-color: ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 5px;

  display: grid;
  gap: 10px;
  align-content: space-between;

  cursor: pointer;
  transition: background-color 0.1s;
  border: 2px solid ${props => (props.isSelected ? props.theme.color.primary : "transparent")};
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
`;

const BaseModelTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const BaseModelDescription = styled.div``;

const BaseModelDetailsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const DetailTitle = styled.span`
  font-size: 10px;
  font-weight: 600;
`;

const DetailValue = styled.span`
  font-weight: 600;
  color: ${props => props.theme.color.primary};
`;

const StartTrainingButton = styled(Button)`
  margin: 0 auto;
`;

const baseModelOptions = [
  {
    baseModelId: null,
    title: "Transformer",
    description: "Model without pre-training",
    details: {
      parameters: "11M",
      layers: "12",
      source: "boltzbit",
    },
  },
  {
    baseModelId: "bert",
    title: "BERT",
    description: "Transformer-based model developed by Google.",
    details: {
      parameters: "110M",
      layers: "12",
      source: "Google",
    },
  },
  {
    baseModelId: "gpt",
    title: "GPT",
    description: "Produce text that continues a prompt.",
    details: {
      parameters: "120M",
      layers: "26",
      source: "OpenAI",
    },
  },
];

const RecommendedModelConfigEditor = ({ featureTypeDescriptors, onFetchRecommendedModelConfig, pipelineConfig }) => {
  const navigate = useNavigate();

  const [inputs, setInputs] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [baseModelId, setBaseModelId] = useState(null);

  const [isSubmittingModelRec, setIsSubmittingModelRec] = useState(false);
  const [error, setError] = useState(null);

  return (
    <OuterContainer>
      <Container>
        <SectionTitle>Create a new cube</SectionTitle>
        <SmallTitle>Base model</SmallTitle>
        <BaseModelCards>
          {baseModelOptions?.map(baseModelOption => (
            <BaseModelContainer
              isSelected={baseModelId === baseModelOption?.baseModelId}
              onClick={() => setBaseModelId(baseModelOption?.baseModelId)}
            >
              <BaseModelTitle>{baseModelOption?.title}</BaseModelTitle>
              <BaseModelDescription>{baseModelOption?.description}</BaseModelDescription>
              <BaseModelDetailsContainer>
                {Object.entries(baseModelOption?.details)?.map(([key, value]) => (
                  <div>
                    <DetailTitle>{key}: </DetailTitle>
                    <DetailValue>{value}</DetailValue>
                  </div>
                ))}
              </BaseModelDetailsContainer>
            </BaseModelContainer>
          ))}
        </BaseModelCards>
        <Gap />
        <SmallTitle>Inputs</SmallTitle>
        <ModelFeaturesEditor
          modelFeatures={inputs}
          onNewModelFeatures={newInputs => setInputs(newInputs)}
          featureTypeDescriptors={featureTypeDescriptors}
        />
        <Gap />
        <SmallTitle>Outputs</SmallTitle>
        <ModelFeaturesEditor
          modelFeatures={outputs}
          onNewModelFeatures={newOutputs => setOutputs(newOutputs)}
          featureTypeDescriptors={featureTypeDescriptors}
        />
        <Gap height="40px" />
        <StartTrainingButton
          variant="highlighted"
          isDisabled={isSubmittingModelRec || !inputs?.length || !outputs?.length}
          value="Set up cube"
          onClick={async () => {
            // postModelRecommendationAndPostPipelineConfig
            setError(null);
            setIsSubmittingModelRec(true);

            const type = featureTypeDescriptors?.some(descriptor => descriptor?.type?.typeName === "IMAGE")
              ? "IMAGE"
              : "LANGUAGE";
            const { data: modelConfig, error } = await postModelRecommendation({
              type,
              inputs,
              outputs,
              architecture: baseModelId
                ? {
                    base_model: baseModelId,
                  }
                : {},
            });
            if (error) {
              setError(error);
              return;
            }

            const newPipelineConfig = { ...pipelineConfig, modelConfig, status: "DRAFT" };
            const { data: dbPipelineConfig, error: pipelineSubmitErr } = await postPipelineConfig(newPipelineConfig);
            if (pipelineSubmitErr) {
              setError(pipelineSubmitErr);
              return;
            }

            await sleep(1000);
            const { error: pipelinePatchErr } = await patchPipelineConfig(dbPipelineConfig?.id, {
              status: "SUBMITTED",
            });
            if (pipelinePatchErr) {
              setError(pipelinePatchErr);
              return;
            }

            navigate(`?tab=Model&subTab="Training"`);
            window.location.reload();
          }}
        />
        {error && <ErrorMsg>{JSON.stringify(error)}</ErrorMsg>}
      </Container>
    </OuterContainer>
  );
};

export default RecommendedModelConfigEditor;
