import { getLoggedInUserName } from "api/services/authenticationService";
import { getUserProfileByUserName } from "api/services/projectService";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalContent = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.closer0};
  padding: 40px 80px;
  display: grid;
  grid-template-rows: 20px auto auto auto;
  justify-items: center;
  gap: 20px;
  border-radius: 10px;
  font-size: 18px;
`;

const AvatarImg = styled.div`
  position: absolute;
  top: -75px;
  width: 150px;
  height: 150px;
  background-color: ${props => props.theme.color.closer0};
  background-size: contain;
  background-image: url(data:image/png;base64,${props => props.src});
  border: 2px solid ${props => props.theme.color.primary};
  border-radius: 200px;
`;

const MessageText = styled.div`
  grid-row: 3;
`;

const RefreshBtn = styled(Button)`
  grid-row: 4;
`;

const UserName = styled.div`
  padding-top: 25px;
  grid-row: 2;
`;

const MS_UNTIL_INACTIVE = 1000 * 60 * 10;

const InactivityModal = ({ hasBeenManuallyOpened }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [msLeftUntilInactive, setMsLeftUntilInactive] = useState(MS_UNTIL_INACTIVE);

  const userName = getLoggedInUserName();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    doPopulateUserProfile();
  }, []);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    if (msLeftUntilInactive <= 0) {
      setIsOpen(true);
      return;
    }

    const timeoutId = setTimeout(() => {
      setMsLeftUntilInactive(msLeftUntilInactive - 1000);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [msLeftUntilInactive]);

  useEffect(() => {
    document.addEventListener("mousemove", () => setMsLeftUntilInactive(MS_UNTIL_INACTIVE));
    document.addEventListener("click", () => setMsLeftUntilInactive(MS_UNTIL_INACTIVE));
    document.addEventListener("scroll", () => setMsLeftUntilInactive(MS_UNTIL_INACTIVE));
  }, []);

  const doPopulateUserProfile = async () => {
    const { data } = await getUserProfileByUserName(userName);
    setUserProfile(data);
  };

  return (
    <Modal open={isOpen || hasBeenManuallyOpened} isBackdropBlurry>
      <ModalContent>
        <AvatarImg src={userProfile?.image} />
        {userProfile?.firstName && (
          <UserName>
            {userProfile?.firstName} {userProfile?.lastName}
          </UserName>
        )}
        <MessageText>Please refresh to continue.</MessageText>
        <RefreshBtn value="Refresh" onClick={() => window.location.reload()} />
      </ModalContent>
    </Modal>
  );
};

export default InactivityModal;
