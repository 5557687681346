import styled from "styled-components";
import { downloadPackgeFile } from "api/backend/packageServiceEndpoint";
import { CloudDownloadOutlined } from "@material-ui/icons";

const TableContainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 10px;
  border-radius: 5px;
  transition: opacity 0.2s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const IconContainer = styled.span`
  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const TableName = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

const Th = styled.th`
  padding-bottom: 5px;
`;

const Td = styled.td`
  padding: 5px 0;
  border-top: 1px solid ${props => props.theme.color.closer1}${props => (props.isHidden ? "0" : "FF")};
  color: ${props => props.theme.color.closest}CF;
  transition: opacity 0.2s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const TableViewerScanned = ({ packageName, table, annotations, isScanComplete }) => {
  const annotationsString = annotations.map(anno => anno?.label).join(" ");
  const hasNameBeenScanned = annotationsString.includes(table?.tableName);

  const doDownloadTableFile = async (pakcageName, tableId) => {
    await downloadPackgeFile(pakcageName, tableId);
  };

  const colNames = table?.tableColumns || [];

  return (
    <TableContainer isHidden={!hasNameBeenScanned && !isScanComplete}>
      <TableName>
        {table?.tableName}
        <IconContainer onClick={() => doDownloadTableFile(packageName, table?.id)}>
          <CloudDownloadOutlined />
        </IconContainer>
      </TableName>
      <table>
        <thead>
          <tr>
            {colNames.map(colName => (
              <Th key={colName}>{colName}</Th>
            ))}
          </tr>
        </thead>
        <tbody>
          {table?.tablePreview.map((row, i) => (
            <tr key={i}>
              {colNames?.map(colName => {
                const cellValue = row?.[colName];
                const isCellHidden = !isScanComplete && !annotationsString?.includes(cellValue);
                return <Td isHidden={isCellHidden}>{cellValue}</Td>;
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

export default TableViewerScanned;
