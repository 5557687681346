import { useState, useEffect } from "react";
import styled from "styled-components";
import JSZip from "jszip";

import {
  getFeaturetypeDescriptorsFromPipelineId,
  getSolutionAndFullApiConfigs,
  patchSolution,
  postSolutionSession,
} from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { SmallTitle, BigTitleEditable } from "components/ui/Text";
import ApiConfigPlay from "components/widgets/ApiConfigPlay";
import { Code } from "@material-ui/icons";
import Modal from "components/ui/Modal";
import CustomCodeMirror from "components/ui/CustomCodeMirror";
import MultiOptionSelector from "components/ui/MultiOptionSelector";
import NavWithTabs from "components/ui/NavWithTabs";
import SolutionStats from "components/widgets/SolutionStats";
import usePollDeploymentClusterById from "components/views/usePollDeploymentClusterById";
import { CircularProgress } from "@material-ui/core";

const CodeButtonContainer = styled.div`
  justify-self: end;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid ${props => props.theme.color.closer0};
  max-width: max-content;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const CodeButton = ({ onClick }) => (
  <CodeButtonContainer onClick={onClick}>
    <Code />
  </CodeButtonContainer>
);

const ApiConfigs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const ModalContent = styled.div`
  padding: 20px;
  width: 800px;
`;

const ArchiveLink = styled.a`
  color: ${props => props.theme.color.primary};
`;

const BlueLink = styled.a`
  color: ${props => props.theme.color.primary};
`;

const CodeSpan = styled.div`
  display: inline-block;
  width: max-content;
  border-radius: 5px;
  padding: 0 5px;
  font-family: monospace;
  background-color: ${props => props.theme.color.closer0};
`;

const Instructions = styled.div`
  line-height: 24px;
`;

const InstructionStep = styled.div`
  padding-left: 40px;
`;

const Container = styled.div`
  ${props => props.isDisabled && "opacity: 0.3;pointer-events: none"}
`;

const ViewCodeModal = ({ isOpen, onClose, codeToView = {} }) => {
  const [selectedOption, setSelectedOption] = useState("html");
  const [archiveUrl, setArchiveUrl] = useState("");

  useEffect(() => {
    if (!codeToView?.html) {
      return;
    }

    doPopulateArchiveUrl();
  }, [codeToView?.html]);

  const doPopulateArchiveUrl = async () => {
    const zip = new JSZip();
    zip.file("index.html", codeToView.html);
    zip.file("widget.js", codeToView.js);
    zip.file("widget.css", codeToView.css);
    const archiveBlob = await zip.generateAsync({ type: "blob" });
    setArchiveUrl(URL.createObjectURL(archiveBlob));
  };

  return (
    <Modal open={isOpen} handleClose={onClose} title="Embed solution">
      <ModalContent>
        <Instructions>
          To run this widget on your machine:
          <InstructionStep>
            <ArchiveLink href={archiveUrl} download="app.zip">
              Download web app
            </ArchiveLink>
            &nbsp;and unzip
          </InstructionStep>
          <InstructionStep>
            In directory where you unzipped the app, run <CodeSpan>python -m SimpleHTTPServer 3000</CodeSpan>
          </InstructionStep>
          <InstructionStep>
            Navigate to <BlueLink href="http://localhost:3000">http://localhost:3000</BlueLink>
          </InstructionStep>
        </Instructions>
        <Gap />
        <div>or copy code into your exiting app:</div>
        <Gap />
        <MultiOptionSelector
          selectedOption={selectedOption}
          options={["html", "js", "css"]}
          onOptionSelect={option => setSelectedOption(option)}
        />
        <CustomCodeMirror value={codeToView[selectedOption] || ""} />
      </ModalContent>
    </Modal>
  );
};

const ApiConfigContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 250px;
  left: calc(175px + (100% - 175px) / 2);
  transform: translateX(-50%);
`;

const apiConfigToCode = {};

const SolutionView = ({ solutionId }) => {
  const [solution, setSolution] = useState(null);
  const [apiConfigs, setApiConfigs] = useState([]);
  const [error, setError] = useState(null);
  const [featureTypeDescriptors, setFeatureTypeDescriptors] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [deploymentCluster, , , doTurnOnDeploymentCluster] = usePollDeploymentClusterById(
    solution?.deploymentClusterId
  );

  const [isCodeModalOpen, setIsCodeModalOpen] = useState(false);
  const [codeToView, setCodeToView] = useState("");

  const [selectedTab, setSelectedTab] = useState("API Configs");

  const [solutionName, setSolutionName] = useState("");

  useEffect(() => {
    doPopulateSolutionAndApiConfigs();
    doPopulateSessionId();
  }, [solutionId]);

  useEffect(() => {
    doPopulateFeatureTypeDescriptors();
  }, [solution?.deploymentClusterId]);

  useEffect(() => {
    !deploymentCluster?.readyToUse && doTurnOnDeploymentCluster();
  }, [deploymentCluster?.readyToUse]);

  const doPopulateSessionId = async () => {
    const { data, error } = await postSolutionSession(solutionId);
    setSessionId(data?.id);
    setError(error);
  };

  const doPopulateSolutionAndApiConfigs = async () => {
    const { data, error } = await getSolutionAndFullApiConfigs(solutionId);
    setError(error);
    setSolution(data?.solution);
    setSolutionName(data?.solution?.name);
    setApiConfigs(data?.fullApiConfigs);
  };

  const doPopulateFeatureTypeDescriptors = async () => {
    const { data } = await getFeaturetypeDescriptorsFromPipelineId(solution?.pipelineId);
    setFeatureTypeDescriptors(data);
  };

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <Container isDisabled={!deploymentCluster?.readyToUse}>
        <Gap />
        <BigTitleEditable
          value={solutionName}
          valueToResetOnCancel={solution?.name}
          onNewValue={newName => setSolutionName(newName)}
          hasBeenEdited={solutionName !== solution?.name}
          onClickSave={async () => {
            const { data: updatedSolution } = await patchSolution(solutionId, { name: solutionName });
            setSolution(updatedSolution);
            setSolutionName(updatedSolution.name);
          }}
        />
        <NavWithTabs
          tabNames={["API Configs", "Stats"]}
          selectedTabName={selectedTab}
          onTabSelect={newTab => setSelectedTab(newTab)}
        />
        <Gap />
        {selectedTab === "API Configs" && (
          <ApiConfigs>
            {apiConfigs.map(apiConfig => (
              <ApiConfigContainer key={apiConfig.id}>
                <SmallTitle>{apiConfig.name}</SmallTitle>
                <CodeButton
                  onClick={() => {
                    setCodeToView(apiConfigToCode[apiConfig.id]);
                    setIsCodeModalOpen(true);
                  }}
                />
                <ApiConfigPlay
                  solutionId={solutionId}
                  sessionId={sessionId}
                  deploymentClusterId={solution?.deploymentClusterId}
                  apiConfig={apiConfig}
                  featureTypeDescriptors={featureTypeDescriptors}
                  onChangeSolutionCode={({ html, js, css }) => {
                    apiConfigToCode[apiConfig.id] = { html, js, css };
                  }}
                />
              </ApiConfigContainer>
            ))}
          </ApiConfigs>
        )}
        {selectedTab === "Stats" && <SolutionStats apiConfigs={apiConfigs} />}
      </Container>

      <ViewCodeModal isOpen={isCodeModalOpen} onClose={() => setIsCodeModalOpen(false)} codeToView={codeToView} />
      {!deploymentCluster?.readyToUse && (
        <SpinnerContainer>
          <CircularProgress size={70} />
        </SpinnerContainer>
      )}
    </CenteredWithTopNavLayout>
  );
};

export default SolutionView;
