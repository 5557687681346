import styled from "styled-components";

import TextRewriting from "./TextRewriting";
import LabelPrediction from "./LabelPrediction";
import TextGeneration from "./TextGeneration";
import DataSynthesis from "./DataSynthesis";

const DataEnrichmentTasksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
`;

const TextDataEnrichmentView = ({
  inputTxtSrc,
  inputLabel,
  selectedTasks,
  onTaskSelect,
  deploymentClusterId,
  missingWordsIndices,
  trainingJobId,
  datasetFeatureTypeDescriptors,
  model,
}) => {
  const hasOnlyTextInput = model.inputs?.length === 1 && model.inputs?.find(input => input?.type === "TEXT");
  const hasOnlyCategoricalOutput =
    model.outputs?.length === 1 && model.outputs?.find(input => input?.type === "CATEGORICAL");
  if (hasOnlyTextInput && hasOnlyCategoricalOutput) {
    return (
      <DataEnrichmentTasksContainer>
        <LabelPrediction
          deploymentClusterId={deploymentClusterId}
          inputTxtSrc={inputTxtSrc}
          isSelected={selectedTasks.includes("Label prediction")}
          onSelect={() => onTaskSelect("Label prediction")}
          missingWordsIndices={missingWordsIndices}
          trainingJobId={trainingJobId}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
          modelConfig={model?.architecture}
        />
      </DataEnrichmentTasksContainer>
    );
  }

  return (
    <DataEnrichmentTasksContainer>
      <LabelPrediction
        deploymentClusterId={deploymentClusterId}
        inputTxtSrc={inputTxtSrc}
        isSelected={selectedTasks.includes("Label prediction")}
        onSelect={() => onTaskSelect("Label prediction")}
        missingWordsIndices={missingWordsIndices}
        trainingJobId={trainingJobId}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        modelConfig={model?.architecture}
      />
      <TextRewriting
        deploymentClusterId={deploymentClusterId}
        inputTxtSrc={inputTxtSrc}
        isSelected={selectedTasks.includes("Text rewriting")}
        onSelect={() => onTaskSelect("Text rewriting")}
        missingWordsIndices={missingWordsIndices}
        trainingJobId={trainingJobId}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        modelConfig={model?.architecture}
      />
      <TextGeneration
        deploymentClusterId={deploymentClusterId}
        inputTxtLabel={inputLabel}
        isSelected={selectedTasks.includes("Text generation")}
        onSelect={() => onTaskSelect("Text generation")}
        trainingJobId={trainingJobId}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        modelConfig={model?.architecture}
      />
      <DataSynthesis
        deploymentClusterId={deploymentClusterId}
        isSelected={selectedTasks.includes("Data synthesis")}
        onSelect={() => onTaskSelect("Data synthesis")}
        trainingJobId={trainingJobId}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        modelConfig={model?.architecture}
      />
    </DataEnrichmentTasksContainer>
  );
};

export default TextDataEnrichmentView;
