import TableViewerScanned from "components/views/application/OcrSolution/TableViewerScanned";
import useGraduallyFillArray from "components/views/application/OcrSolution/useGraduallyFillArray";
import ImageWithOCRAnnotations from "components/widgets/ImageWithOCRAnnotations";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import OcrNavWithTabs from "./OcrNavWithTabs";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import Button from "components/ui/Button";
import AnnotationModal from "./AnnotationModal";

const Container = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const TextContainer = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 10px;
  border-radius: 5px;
  transition: opacity 0.2s;
  opacity: ${props => (props.isHidden ? 0 : 1)};
`;

const Text = styled.div`
  margin: 10px 5px 10px 5px;
  background-color: ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 5px;
`;

const TablesContainer = styled.div``;

const StyledImageWithOCRAnnotations = styled(ImageWithOCRAnnotations)`
  border-top-right-radius: ${props => (props.noTopRightBorderRadius ? 0 : "5px")};
`;

const PackageView = ({ packageDetails, noTopRightBorderRadius, hasBeenScanned, index = 0 }) => {
  const annotationsToDisplay = useGraduallyFillArray(packageDetails?.annotations, hasBeenScanned);
  const [tablesView, setTablesView] = useState(true);
  const [selectedTableIdx, setSelectedTableIdx] = useState(null);
  const [selectedTable, setSelectedTable] = useState(null);
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);

  useEffect(() => {
    if (!isEmpty(packageDetails?.tables)) {
      setSelectedTableIdx(0);
    }
  }, [packageDetails]);

  useEffect(() => {
    if (selectedTableIdx !== null) {
      const targetTable = packageDetails?.tables[selectedTableIdx];
      setSelectedTable(targetTable);
    }
  }, [selectedTableIdx, packageDetails]);

  return (
    <div>
      <AnnotationModal
        open={showAnnotationModal}
        handleClose={() => setShowAnnotationModal(false)}
        base64Data={packageDetails?.image}
        ocrAnnotations={annotationsToDisplay}
        fileId={packageDetails?.packageName}
        pageNumber={index}
      />
      <Container>
        <StyledImageWithOCRAnnotations
          width={500}
          height={500}
          base64Data={packageDetails?.image}
          annotations={annotationsToDisplay}
          noTopRightBorderRadius={noTopRightBorderRadius}
        />
        <TablesContainer>
          <TwoNameSwitch
            leftName="Tables"
            rightName="Text"
            isOnLeft={tablesView}
            onSwitch={() => {
              setTablesView(!tablesView);
            }}
          />
          {tablesView ? (
            <>
              <OcrNavWithTabs
                tabs={packageDetails?.tables.map(e => e.tableName)}
                selectedTabIdx={selectedTableIdx}
                onTabIdxSelect={newIdx => setSelectedTableIdx(newIdx)}
              />
              <TableViewerScanned
                key={selectedTable?.tableName}
                packageName={packageDetails?.packageName}
                table={selectedTable}
                annotations={annotationsToDisplay}
                isScanComplete={annotationsToDisplay?.length === packageDetails?.annotations?.length}
              />
            </>
          ) : (
            <>
              <TextContainer>
                {packageDetails?.texts.map(e => (
                  <Text>{e.content}</Text>
                ))}
              </TextContainer>
            </>
          )}
        </TablesContainer>
      </Container>
      <Button
        value={"Add Annotations"}
        onClick={() => {
          setShowAnnotationModal(true);
        }}
      />
    </div>
  );
};

export default PackageView;
