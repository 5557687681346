export const getCssCode = () => `
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container {
  font-family: 'Montserrat', sans-serif;
  grid-column: span 2;
  padding: 0;
  border-radius: 5px;
  display: grid;
  gap: 20px;
  grid-template-columns: auto 1fr;
}

.table-container {
  padding: 10px 0;
  width: 100%;
  overflow-x: scroll;
  grid-column: span 2;
}

.table {
  display: grid;
  gap: 15px;
}
.table .column-name {
  font-weight: bold;
}
.table input {
  min-width: 70px;
  padding: 8px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  width: 100%;
  transition: border 0.2s;
}
.table input:focus {
  border: 1px solid #0191ff;
}

.outputs-section {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  width: max-content;
}
.outputs-section .column-name-output {
  font-weight: bold;
  color: #0191ff;
  text-align: end;
}

.blue-button {
  align-self: start;
  padding: 5px 20px;
  cursor: pointer;
  color: #0191ff;
  background-color: white;
  border-radius: 40px;
  border: 2px solid #0191ff;
}

.blue-button:hover {
  color: white;
  background-color: #0191ff;
}

.disabled {
  opacity: 0.2;
  pointer-events: none;
}
`;

export const getHtmlCode = () => `
<html>
  <head>
    <link rel="stylesheet" href="widget.css" />
  </head>
  <body>
    <h1>Your app</h1>
    <!-- AI widget -->
    <div class="container">
      <div class="table-container">
        <div class="table"></div>
      </div>
      <button class="blue-button" onclick="populatePredictions()">Predict</button>
      <div class="outputs-section"></div>
    </div>
    <!-- AI widget -->
    <script src="widget.js"></script>
  </body>
</html>
`;

export const getJsCode = (deploymentClusterId, apiConfig, featureTypeDescriptors, bearerToken, solutionId, origin) => `
const apiConfig = ${JSON.stringify(apiConfig)};
const deploymentClusterId = "${deploymentClusterId}";
const solutionId = "${solutionId}";
const bearerToken = "${bearerToken}";
const featureTypeDescriptors = ${JSON.stringify(featureTypeDescriptors)};

let sessionId = "";

const getSafeColName = colName => colName.replaceAll("/", "-");

const startNewSession = async () => {
  const sessionCreateRes = await fetch("${origin}/project-service/api/v1/solution-sessions", {
    method: "POST",
    body: JSON.stringify({ solutionId }),
    headers: {
      Authorization: bearerToken,
      "Content-Type": "application/json",
    },
  }).then(res => res.json());

  const newSession = JSON.parse(sessionCreateRes.objectJSON);
  sessionId = newSession.id;
};

const populatePredictions = async () => {
  const inputRow = {};
  apiConfig?.config?.inputs.forEach(inputCol => {
    const inputValue = document.querySelector(\`.container #\${getSafeColName(inputCol)}-input\`)?.value;
    inputRow[inputCol] = inputValue;
  });

  document.querySelector(".container").classList.add("disabled");

  const predictionResultRes = await fetch("${origin}/project-service/api/v1/solution-queries", {
    method: "POST",
    body: JSON.stringify({ sessionId, apiConfigId: apiConfig?.id, input: inputRow }),
    headers: {
      Authorization: bearerToken,
      "Content-Type": "application/json",
    },
  }).then(res => res.json());

  const predictionResult = JSON.parse(predictionResultRes.objectJSON);

  apiConfig?.config?.outputs.forEach(outputCol => {
    const outputEl = document.querySelector(\`.container #\${getSafeColName(outputCol)}-output\`);
    outputEl.innerHTML = predictionResult?.result?.[outputCol]?.winner || predictionResult?.result?.[outputCol]?.value;
  });

  document.querySelector(".container").classList.remove("disabled");
};

const populateInitialInputs = async () => {
  const datasetPreviewRes = await fetch(
    "${origin}/deployment-service/api/v1/deployment-clusters/${deploymentClusterId}/sample-data",
    {
      headers: {
        Authorization: bearerToken,
      },
    }
  ).then(res => res.json());

  const datasetPreview = JSON.parse(datasetPreviewRes.objectJSON);
  const inputRow = datasetPreview?.[0];

  Object.keys(inputRow).forEach(colName => {
    const input = document.querySelector(\`#\${getSafeColName(colName)}-input\`);
    if (input) {
      if (typeof inputRow[colName] === "number") {
        input.type = "number";
      }
      input.value = inputRow[colName];
    }
  });
};

const table = document.querySelector(".container .table");

table.innerHTML = apiConfig?.config?.inputs.map(colName => \`<div class="column-name">\${colName}</div>\`).join("");
table.style = "grid-template-columns: repeat(" + apiConfig?.config?.inputs.length + ", 1fr);";

table.innerHTML =
  table.innerHTML +
  apiConfig?.config?.inputs
    .map(colName => {
      const featureDescription = featureTypeDescriptors?.find(desc => desc.key === colName);
      if (featureDescription?.type?.typeName === "CATEGORICAL") {
        return \`
          <select id="\${getSafeColName(colName)}-input">
            \${featureDescription?.type?.categories?.map(cat => \`<option value="\${cat}">\${cat}</option>\`).join("")}
          </select>\`;
      }
      return \`<input id="\${getSafeColName(colName)}-input" type="text" />\`;
    })
    .join("");

const outputsSection = document.querySelector(\`.container .outputs-section\`);
outputsSection.innerHTML = apiConfig?.config?.outputs
  .map(
    colName => \`
    <div class="column-name-output">\${colName}</div>
    <div id="\${getSafeColName(colName)}-output"></div>
  \`
  )
  .join("");

startNewSession();
populateInitialInputs();
`;
