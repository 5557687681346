export const getCookieByName = cookieName => {
  try {
    const match = document.cookie.match(new RegExp("(^| )" + cookieName + "=([^;]+)"));
    return match?.[2];
  } catch {
    return "";
  }
};

export const deepCompare = (x, y) => {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!deepCompare(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const by = (attribute, ascOrDesc = "desc") => {
  return (resourceA, resourceB) => {
    if (ascOrDesc === "asc") {
      return new Date(resourceA[attribute]) > new Date(resourceB[attribute]) ? 1 : -1;
    }
    return new Date(resourceA[attribute]) > new Date(resourceB[attribute]) ? -1 : 1;
  };
};

export const getBase64FromImageFile = async file => {
  return await new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const getColorFromString = (s = "") => {
  const cyrb53 = function (str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };

  return `#${cyrb53(s)?.toString(16)?.substring(0, 6)}`;
};

export const parseJson = str => {
  let result = str;
  try {
    result = JSON.parse(str);
  } catch {
    result = [];
  }

  if (typeof result === "boolean") {
    return result;
  }

  return result || [];
};

export const getFileObjectFromURL = async (url, name) => {
  const response = await fetch(url);
  const blob = await response.blob();
  blob.lastModifiedDate = new Date();
  blob.name = name;
  const file = new File([blob], name);
  return file;
};
