import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import BannerLayout from "./BannerLayout";
import { SmallVerticalGap } from "components/Layout";

const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const Container = styled.div`
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

const AppCard = styled.div`
  text-decoration: none;
  border-radius: 3px;
  border: 2px solid ${props => props.theme.color.closer1};
  display: flex;
  flex-direction: column;
  min-height: 200px;

  box-shadow: rgb(100 100 100 / 20%) 0px 1px 1px 0px, rgb(100 100 100 / 14%) 0px 2px 2px 0px,
    rgb(100 100 100/ 12%) 0px 3px 1px -2px;
  padding: 16px 20px;
  transition: box-shadow 0.2s ease 0s;

  img {
    border-radius: 8px;
    height: 50px;
    width: 50px;
  }

  :hover {
    cursor: pointer;
    box-shadow: rgb(100 100 100 / 20%) 0px 5px 5px 5px, rgb(100 100 100 / 14%) 0px 5px 5px 5px,
      rgb(100 100 100 / 12%) 0px 5px 5px -5px;
  }
`;

const TutorialBlockTextContent = styled.div`
  padding: 10px;
`;

const GroupTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin-top: 6px;
  padding-left: 20px;
`;
const AppTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 6px;
`;

const AppDescription = styled.div`
  font-size: 11px;
  line-height: 18px;
  margin-top: 6px;
`;

const MarketPlacePage = () => {
  const navigate = useNavigate();

  return (
    <BannerLayout>
      <SmallVerticalGap />
      <RowContainer>
        <div>
          <GroupTitle>Smart Digital Assitant</GroupTitle>
          <Container>
            <AppCard onClick={() => navigate("/market-place/calendar")}>
              <img src={"./images/market-place/calendar.png"} alt={"calendar"} />
              <TutorialBlockTextContent>
                <AppTitle>Calendar Assitant </AppTitle>
                <AppDescription>
                  Manage your meetings smartly and promtly. Simplified scheduling without headeach.
                </AppDescription>
              </TutorialBlockTextContent>
            </AppCard>
          </Container>
        </div>
        <div>
          <GroupTitle>Document Processing</GroupTitle>
          <Container>
            <AppCard
              onClick={() => {
                window.location = "https://ocr.solutions.boltzbit.com";
              }}
            >
              <img src={"./images/market-place/ocr.png"} alt={"ocr"} />
              <TutorialBlockTextContent>
                <AppTitle>OCR Solution </AppTitle>
                <AppDescription>
                  AI extracts data from financial statements with human-like accuracy. Asset managers and banks require
                  precise and up-to-date financial data to make investment decisions and report on performance.
                </AppDescription>
              </TutorialBlockTextContent>
            </AppCard>
          </Container>
        </div>
      </RowContainer>
    </BannerLayout>
  );
};

export default MarketPlacePage;
