import { useNavigate, useSearchParams } from "react-router-dom";
import { parseJson } from "utils/common";

const useSearchParamsState = (
  { paramName: searchParamName, initialValue } = { paramName: "paramName", initialValue: [] }
) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const value = searchParams.get(searchParamName) ? parseJson(searchParams.get(searchParamName)) : initialValue;

  const setValue = newValue => {
    searchParams?.set(searchParamName, JSON.stringify(newValue));
    navigate(`?${searchParams.toString()}`);
  };

  return [value, setValue];
};

export default useSearchParamsState;
