import { Add, CloseOutlined } from "@material-ui/icons";
import { createSentenceLabelingTask, getSentenceLabelingTasks } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { BigTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { by } from "utils/common";

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  width: 700px;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 300px;
`;

const LabelRow = styled.div`
  display: flex;
  gap: 5px;
`;

const TaskCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const TaskTitle = styled.div`
  transition: color 0.2s;
  font-size: 18px;
  font-weight: bold;
`;

const Description = styled.div``;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

const TaskCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.2s;
  background-color: ${props => props.theme.color.closer0};
  :hover {
    background-color: ${props => props.theme.color.closer1};
    ${TaskTitle} {
      color: ${props => props.theme.color.primary};
    }
  }
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const LabelContainer = styled.div`
  gap: 5px;
  padding: 10px;
`;

const ErrMsgDiv = styled.div`
  color: ${props => props.theme.color.error};
`;

const SentenceLabelingTaskListPage = () => {
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [labels, setLabels] = useState([]);
  const [taskName, setTaskName] = useState("Untitled");
  const [modalErrorMsg, setModalErrorMsg] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    doPopulateTasks();
  }, []);

  const doPopulateTasks = async () => {
    const { data, error } = await getSentenceLabelingTasks();
    const sortedTasks = data.sort(by("createdAt", "desc"));
    setTasks(sortedTasks);
    setError(error);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading tasks...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  const doValidateTaskInfo = () => {
    if (isEmpty(taskName)) {
      setModalErrorMsg("The dataset name can not be empty.");
      return false;
    }
    return true;
  };

  const doCreateTask = async () => {
    if (doValidateTaskInfo()) {
      const labelSet = [...new Set(labels)].filter(e => !isEmpty(e));
      const { data, error } = await createSentenceLabelingTask(taskName, labelSet);
      if (error) {
        setModalErrorMsg(error);
      } else {
        setTaskName("Untitled");
        setLabels([]);
        setIsModalOpen(false);
        navigate(`/sentence-labeling-task/${data.id}`);
      }
    }
  };

  return (
    <CenteredWithTopNavLayout>
      <Modal
        title={"Set up a new task"}
        open={isModalOpen}
        handleClose={() => {
          setTaskName("Untitled");
          setLabels([]);
          setIsModalOpen(false);
          setModalErrorMsg("");
        }}
      >
        <ModalContent>
          <FormContainer>
            {modalErrorMsg && <ErrMsgDiv>{modalErrorMsg}</ErrMsgDiv>}
            <TextInput title={"Dataset Name"} value={taskName} onNewInput={newVal => setTaskName(newVal)} />
            <Gap />
            Add Labels for the task:
            <LabelContainer>
              {labels.map((label, i) => (
                <LabelRow>
                  <TextInput
                    title={`label ${i + 1}`}
                    value={label}
                    onNewInput={newVal => {
                      const newLabels = [...labels];
                      newLabels[i] = newVal;
                      setLabels(newLabels);
                    }}
                  />
                  <IconContainer
                    onClick={() => {
                      const newLabels = [...labels];
                      newLabels.splice(i);
                      setLabels(newLabels);
                    }}
                  >
                    <CloseOutlined />
                  </IconContainer>
                </LabelRow>
              ))}
              <IconContainer
                onClick={() => {
                  const newLabels = [...labels, ""];
                  setLabels(newLabels);
                }}
              >
                <Add />
              </IconContainer>
            </LabelContainer>
          </FormContainer>
          <Button
            value={"Create task"}
            onClick={() => {
              doCreateTask();
            }}
          />
        </ModalContent>
      </Modal>
      <BigTitle>Sentence Labeling Tasks</BigTitle>
      <Gap />
      <Button
        value="Add new"
        onClick={() => {
          setIsModalOpen(true);
        }}
      />
      <Gap />
      <TaskCards>
        {tasks.map(task => (
          <CardLink key={task.id} to={`/sentence-labeling-task/${task?.id}`}>
            <TaskCardContainer>
              <TaskTitle>{task?.name}</TaskTitle>
              <Description>{task?.createdAt}</Description>
            </TaskCardContainer>
          </CardLink>
        ))}
      </TaskCards>
    </CenteredWithTopNavLayout>
  );
};

export default SentenceLabelingTaskListPage;
