import { getHandlingFromError } from "api/error-handling";
import * as backend from "api/backend/jobServiceEndpoints";
import { zapiPost } from "api/zapi";
import { apiGetRawResponse } from "api/api-http-methods";

export const triggerDownloadOfTrainedModel = async jobId => {
  const res = await apiGetRawResponse(`/job-service/api/v1/jobs/${jobId}/tf-model-file`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "tf-model.tgz";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const downloadOutputTar = async jobId => {
  const res = await apiGetRawResponse(`/job-service/api/v1/jobs/${jobId}/outputs-tar`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "output.tar";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const getEvaluationJobById = async evalJobId => {
  const { data, error } = await backend.getEvaluationJobById(evalJobId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  if (!data?.result) {
    return { data, error };
  }
  return { data: { ...data, result: JSON.parse(data.result) }, error };
};

export const getEvaluationJobsByEvalJobIds = async evalJobIds => {
  const evalJobsResults = await Promise.all(evalJobIds.map(evalJobId => getEvaluationJobById(evalJobId)));

  if (evalJobsResults.find(evalJobResult => evalJobResult.error)) {
    return { data: null, error: evalJobsResults.find(evalJobResult => evalJobResult.error) };
  }

  return { data: evalJobsResults.map(evalJobResult => evalJobResult.data), error: null };
};

export const getGeneratedData = async jobId => {
  const { data, error } = await backend.getGeneratedData(jobId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getJobBundleEvalustions = async jobBundleId => {
  const { data, error } = await backend.getJobBundleEvalustions(jobBundleId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  const extractedRes = data.map(e => {
    const res = JSON.parse(e.result);
    return res.eloRating;
  });
  return { data: extractedRes, error };
};

export const getTrainingJobById = async jobId => {
  const { data, error } = await backend.getTrainingJobById(jobId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getRecommendedTrainingConfig = async () => {
  const { data, error } = await backend.getRecommendedTrainingConfig();
  return { data, error };
};

export const submitTrainingJob = async (datasetId, modelId, projectId) => {
  const apiUrl = "/job-service/api/v1/job/submit";
  const jobConfig = {
    ckpt_file: "ckpt.npz",
    dtype: "float32",
    batch_size: 100,
    infer: {
      lkh_weight: 10,
      min_eps: 0.001,
      nlayers: 10,
      lfsteps: 5,
      initial_log_eps: -3,
      initial_ei_prior_mean: 0,
      initial_ei_prior_std: 1,
    },
    train: {
      trn_weight: 10,
      optimizer: {
        learning_rate_init: 2e-4,
        learning_rate_change_points: [],
        learning_rate_change_values: [],
        learning_rate_inf_init: 2e-4,
        learning_rate_inf_change_points: [],
        learning_rate_inf_change_values: [],
      },
      max_iter: 2000,
      save_period: 200,
      logging_period: 10,
    },
    dataloader: {
      trn_tst_split: 1.0,
      shuffle: true,
      repeat: false,
    },
  };

  const body = {
    modelId: modelId,
    datasetId: datasetId,
    method: "EI",
    jobConfig: jobConfig,
  };

  try {
    const response = await zapiPost(apiUrl, null, body);
    const newTrainingJobId = response.data;
    // await addNewTrainingJob(projectId, newTrainingJobId);
    return newTrainingJobId;
  } catch (error) {
    return undefined;
  }
};

export const killJobById = async jobId => {
  const { data, error } = await backend.killJobById(jobId);
  return { data, error: getHandlingFromError(error) };
};

export const continueJobById = async jobId => {
  const { data, error } = await backend.continueJobById(jobId);
  return { data, error: getHandlingFromError(error) };
};

export const getCustomCodeTemplate = async () => {
  const { data, error } = await backend.getCustomCodeTemplate();
  return { data, error: getHandlingFromError(error) };
};

export const patchJobById = async (jobId, params = {}, body = {}) => {
  const { data, error } = await backend.patchJobById(jobId, params, body);
  return { data, error: getHandlingFromError(error) };
};

export const getJob = async id => {
  const { data, error } = await backend.getJob(id);
  return { data, error: getHandlingFromError(error) };
};
