import { useState } from "react";
import styled from "styled-components";

import WordDoc from "components/WordDoc";

const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
`;

const LinksContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.color.closer0};
`;

const ComponentContainer = styled.div`
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
`;

const ComponentBox = styled.div`
  border: 1px dashed grey;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  padding-bottom: 12px;
`;

const Description = styled.div`
  padding-bottom: 12px;
`;

const BLOCKS = [
  {
    text: "Hawaii consists of 137 volcanic islands that comprise almost the entire Hawaiian archipelago; spanning 1,500 miles (2,400 km), the state is physiographically and ethnologically part of the Polynesian subregion of Oceania.[9] Hawaii's ocean coastline is consequently the fourth-longest in the U.S., at about 750 miles (1,210 km).[d] The eight main islands, from northwest to southeast, are Niʻihau, Kauaʻi, Oʻahu, Molokaʻi, Lānaʻi, Kahoʻolawe, Maui, and Hawaiʻi, after which the state is named; it is often called the 'Big Island' or 'Hawaii Island' to avoid confusion with the state or archipelago. The uninhabited Northwestern Hawaiian Islands make up most of the Papahānaumokuākea Marine National Monument, the largest protected area in the U.S. and the fourth largest in the world.",
  },
  {
    text: "",
  },
  { text: "This is a sample text" },
];

const ComponentsPage = () => {
  const [blocks, setBlocks] = useState(BLOCKS);

  return (
    <Container>
      <LinksContainer />
      <ComponentContainer>
        <Title>WordDoc</Title>
        <Description>A text area with styling, labelling and cursor locating capabilities</Description>
        <ComponentBox style={{ height: "300px", overflow: "auto" }}>
          <WordDoc blocks={blocks} onChangeBlocks={newBlocks => setBlocks(newBlocks)} />
        </ComponentBox>
      </ComponentContainer>
    </Container>
  );
};

export default ComponentsPage;
