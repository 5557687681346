import { SmallTitle } from "components/ui/Text";
import BookResultsView from "components/views/BookResultsView";
import { books } from "components/views/application/BookSearch/books-data";
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import styled from "styled-components";

const ImportDataDonePage = styled.div`
  width: 100%;
  height: 400px;
  padding-top: 100px;
  justify-content: center;
  display: flex;
  font-size: 18px;
  color: ${props =>  props.theme.color.primary}; 
`

const ImportDataView = () => {
  return (
    <>
      {/* <SmallTitle>Dataset preview</SmallTitle> */}
      <ImportDataDonePage><DoneOutlineIcon/> Data Importing successfully completed !</ImportDataDonePage>
      {/* <BookResultsView books={books} /> */}
    </>
  );
};

export default ImportDataView;
