import styled from "styled-components";
import { useState } from "react";

const SelectContainer = styled.div`
  position: relative;
  font-size: 14px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  font-family: "Montserrat";
  transition: background-color 0.1s, color 0.1s;
  font-weight: 600;
  display: grid;
  width: max-content;
  grid-template-columns: auto;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  align-items: center;
  gap: 5px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  border: 2px solid ${props => props.theme.color.closer1};
  padding: 0 15px;
  svg {
    fill: ${props => props.theme.color.closest};
  }
`;

const Select = styled.select`
  color: ${props => props.theme.color.closest};
  background-color: transparent;
  width: max-content;
  border: none;
  ${props => props.fontSize && `font-size: ${props.fontSize};`}
  cursor: pointer;
  padding: 5px 0;
  color: ${props => props.theme.color.primary};
  font-weight: 600;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const InputTitle = styled.div``;

const CornerRect = styled.div`
  width: 2px;
  height: 2px;
  position: absolute;
  background-color: ${props => props.theme.color.primary};
`;

const SelectInput = ({ children, title, value, onSetNewValue, className, isDisabled, fontSize }) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <SelectContainer isFocused={isFocused} isDisabled={isDisabled} className={className}>
      <CornerRect style={{ top: "-2px", left: "-2px" }} />
      <CornerRect style={{ top: "-2px", right: "-2px" }} />
      <CornerRect style={{ bottom: "-2px", left: "-2px" }} />
      <CornerRect style={{ bottom: "-2px", right: "-2px" }} />

      {title && <InputTitle>{title}</InputTitle>}
      <Select
        fontSize={fontSize}
        value={value || ""}
        onChange={e => onSetNewValue(e.target.value)}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      >
        {children}
      </Select>
    </SelectContainer>
  );
};

export default SelectInput;
