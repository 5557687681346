import styled from "styled-components";

import { Gap } from "components/Layout";
import { BigTitle, SmallTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import Button from "components/ui/Button";
import NavWithTabs from "components/ui/NavWithTabs";
import SearchInput from "components/widgets/SearchInput";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import SelectInput from "components/ui/SelectInput";
import FileInput from "components/ui/FileInput";

const Container = styled.div`
  padding-top: 50px;
  padding-left: 200px;
  padding-right: 200px;
`;

const ElementContainer = styled.div`
  width: ${props => props.width || "100%"};
`;

const ElementsTitle = styled.div`
  color: rgba(0, 0, 0, 0.05);
  font-size: 36px;
`;

const Elements = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.05);
`;

const InputElements = styled(Elements)`
  flex-direction: row;
`;

const TextContainer = styled.div`
  background-color: aliceblue;
  font-size: 24px;
`;

const StickyDiv = styled.div`
  background-color: salmon;
  position: sticky;
  top: 0;
`;

const Test = () => (
  <TextContainer>
    <StickyDiv>Sticky div</StickyDiv>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae malesuada ex. Lorem ipsum dolor sit amet,
    consectetur adipiscing elit. Donec leo lorem, imperdiet et elementum id, egestas ut turpis. Nullam finibus, ipsum eu
    iaculis consequat, odio ante vulputate lorem, sed malesuada dolor neque ullamcorper dui. Nunc id nunc lacinia,
    faucibus erat in, feugiat enim. Aliquam ultrices vehicula venenatis. Nullam imperdiet urna sit amet mauris lacinia,
    ac mattis justo rutrum. Sed nec suscipit ligula. Sed dictum magna nec est mattis malesuada. Nunc dapibus elit quis
    facilisis dapibus. Vestibulum egestas vestibulum fringilla. Sed pretium quis libero a maximus. Aliquam consequat
    vehicula scelerisque. Vivamus eu mi ac urna rhoncus euismod. Cras sed mauris sit amet dolor auctor mattis. Maecenas
    dignissim justo in arcu consequat condimentum. Nulla elit erat, consectetur non dapibus ut, vehicula rhoncus nunc.
    Nulla facilisi. Nullam blandit nibh vitae iaculis facilisis. Donec pharetra elit ipsum. Ut vitae vehicula eros.
    Vestibulum blandit dui metus, id pharetra neque mollis sed. Aliquam malesuada, magna vitae malesuada malesuada,
    tortor quam dictum quam, a tristique elit justo sit amet ex. Nullam sed accumsan dui, eu finibus purus. Morbi
    finibus dui massa, ac dictum ipsum egestas ut. Nullam ligula mauris, fringilla sed lobortis vitae, dictum ut urna.
    Ut at est vehicula, vestibulum libero a, vulputate eros. Nulla rutrum, ligula vitae malesuada faucibus, tellus odio
    viverra ante, vel hendrerit sem diam id mi. Proin lobortis leo vel ligula congue placerat et nec mi. Ut tortor
    metus, porttitor sed purus quis, luctus dapibus sem. Aenean lacus nibh, congue nec justo nec, dapibus faucibus
    justo. Sed sodales at lectus nec scelerisque. Proin ultrices, urna sed pellentesque molestie, quam tortor gravida
    mi, in sagittis elit dui id arcu. Suspendisse felis urna, venenatis a turpis vitae, tincidunt rhoncus est. Mauris
    nec nunc sit amet sapien consequat posuere. Quisque felis dui, pharetra in arcu vel, volutpat fringilla dolor. Class
    aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc consequat mi massa, sit
    amet vehicula nisi lacinia tincidunt. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae
    malesuada ex. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec leo lorem, imperdiet et elementum id,
    egestas ut turpis. Nullam finibus, ipsum eu iaculis consequat, odio ante vulputate lorem, sed malesuada dolor neque
    ullamcorper dui. Nunc id nunc lacinia, faucibus erat in, feugiat enim. Aliquam ultrices vehicula venenatis. Nullam
    imperdiet urna sit amet mauris lacinia, ac mattis justo rutrum. Sed nec suscipit ligula. Sed dictum magna nec est
    mattis malesuada. Nunc dapibus elit quis facilisis dapibus. Vestibulum egestas vestibulum fringilla. Sed pretium
    quis libero a maximus. Aliquam consequat vehicula scelerisque. Vivamus eu mi ac urna rhoncus euismod. Cras sed
    mauris sit amet dolor auctor mattis. Maecenas dignissim justo in arcu consequat condimentum. Nulla elit erat,
    consectetur non dapibus ut, vehicula rhoncus nunc. Nulla facilisi. Nullam blandit nibh vitae iaculis facilisis.
    Donec pharetra elit ipsum. Ut vitae vehicula eros. Vestibulum blandit dui metus, id pharetra neque mollis sed.
    Aliquam malesuada, magna vitae malesuada malesuada, tortor quam dictum quam, a tristique elit justo sit amet ex.
    Nullam sed accumsan dui, eu finibus purus. Morbi finibus dui massa, ac dictum ipsum egestas ut. Nullam ligula
    mauris, fringilla sed lobortis vitae, dictum ut urna. Ut at est vehicula, vestibulum libero a, vulputate eros. Nulla
    rutrum, ligula vitae malesuada faucibus, tellus odio viverra ante, vel hendrerit sem diam id mi. Proin lobortis leo
    vel ligula congue placerat et nec mi. Ut tortor metus, porttitor sed purus quis, luctus dapibus sem. Aenean lacus
    nibh, congue nec justo nec, dapibus faucibus justo. Sed sodales at lectus nec scelerisque. Proin ultrices, urna sed
    pellentesque molestie, quam tortor gravida mi, in sagittis elit dui id arcu. Suspendisse felis urna, venenatis a
    turpis vitae, tincidunt rhoncus est. Mauris nec nunc sit amet sapien consequat posuere. Quisque felis dui, pharetra
    in arcu vel, volutpat fringilla dolor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per
    inceptos himenaeos. Nunc consequat mi massa, sit amet vehicula nisi lacinia tincidunt.
  </TextContainer>
);

const DesignSystemPage = () => {
  return (
    <Container>
      <ElementsTitle>Titles</ElementsTitle>
      <Elements>
        <BigTitle>Big Title</BigTitle>
        <SmallTitle>Small Title</SmallTitle>
      </Elements>
      <Gap />
      <ElementsTitle>Nav</ElementsTitle>
      <Elements>
        <NavWithTabs tabNames={["Tab one", "Tab two", "Tab three"]} selectedTabName="Tab two" />
      </Elements>
      <Gap />
      <ElementsTitle>Inputs</ElementsTitle>
      <InputElements>
        <ElementContainer width="300px">
          <TextInput value="an input" title="Example input title" />
        </ElementContainer>
        <SearchInput />
        <TwoNameSwitch leftName="Left option" rightName="Right option" isOnLeft />
        <ElementContainer width="300px">
          <SelectInput title="Select input title">
            <option value="1">Option 1</option>
            <option value="2">Option 2</option>
          </SelectInput>
        </ElementContainer>
        <FileInput />
      </InputElements>
      <Gap />
      <ElementsTitle>Buttons</ElementsTitle>
      <InputElements>
        <Button value="Do something" />
      </InputElements>

      <Test />
    </Container>
  );
};

export default DesignSystemPage;
