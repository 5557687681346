import * as backend from "api/backend/packageServiceEndpoint";
import { getHandlingFromError } from "api/error-handling";

export const getPackageById = async packageId => {
  const { data, error } = await backend.getPackageById(packageId);
  return { data, error: getHandlingFromError(error) };
};

export const getRecentPackages = async () => {
  const { data, error } = await backend.getAllPackages();
  return { data, error: getHandlingFromError(error) };
};

export const uploadPackage = async (dataFile, name) => {
  const formDataBody = new FormData();
  formDataBody.append("file", dataFile);
  const { data, error } = await backend.uploadPackage(name, formDataBody);
  return { data, error: getHandlingFromError(error) };
};

export const searchWithKeywords = async query => {
  const { data, error } = await backend.searchWithKeywords(query);
  return { data, error: getHandlingFromError(error) };
};

export const getAnnotationsByFileAndPage = async (fileId, pageNumber) => {
  const { data, error } = await backend.getAnnotationsByFileAndPage(fileId, pageNumber);
  return { data, error: getHandlingFromError(error) };
};

export const saveAnnotationsByFileAndPage = async (fileId, pageNumber, filePageAnnotations) => {
  const { data, error } = await backend.patchAnnotationsByFileAndPage(fileId, pageNumber, filePageAnnotations);
  return { data, error: getHandlingFromError(error) };
};


