import styled from "styled-components";

const TaskContainer = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 10px;
  transition: box-shadow 0.2s, border 0.2s;
  border: 1px solid ${props => (props.isSelected ? `${props.theme.color.primary}99` : `${props.theme.color.primary}00`)};
  word-wrap: break-word;
  cursor: pointer;
`;

export default TaskContainer;
