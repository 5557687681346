import styled from "styled-components";
import { useState, useEffect } from "react";
import { isEqual } from "lodash";

import { getSampleDataPointFromDeployment, getDataConfigFromDeployment } from "api/services/deploymentService";
import Button from "components/ui/Button";
import SelectInput from "components/ui/SelectInput";
import ExpandableOnTitleClick from "components/widgets/ExpandableOnTitleClick";
import TextDataEnrichmentView from "./TextDataEnrichmentView";
import TextContentMatchingView from "./TextContentMatchingView";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const Container = styled.div``;

const InputPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  border-top: 1px solid ${props => props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.closer1};
`;

const ButtonsContainer = styled.div``;

const TextAndLabelContainer = styled.div`
  justify-self: center;
  flex-direction: column;
  display: flex;
  gap: 10px;
  align-items: center;
  border: 1px solid white;
  padding: 10px;
  margin-left: 5px;
  border-radius: 10px;
`;

const Text = styled.div`
  ${props => props.isDisabled && "opacity: 0.1; pointer-events: none;"}
  font-size: 26px;
  line-height: 36px;
  overflow-x: auto;
`;

const LabelSelectInput = styled(SelectInput)`
  width: 350px;
  :disabled {
    opacity: 0.1;
  }
`;

const GetTestImageButton = styled(Button)`
  border: 2px solid ${props => props.theme.color.closer1_5};
`;

const TaskTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const TextContainer = styled.div`
  position: relative;
  width: 800px;
`;

const Word = styled.span`
  cursor: pointer;
  margin-left: 5px;
  padding: 5px;
  border-radius: 5px;
`;

const EditableWord = styled(Word)`
  transition: background-color 0.2s;
  background-color: ${props => props.theme.color.closer0};
  color: ${props => props.isMissing && `${props.theme.color.closer2}${(100).toString(16)}`};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const EyeContainer = styled.div`
  opacity: ${props => (props.isEnabled ? 1 : 0)};
`;

const SelectAndEye = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const enrichmentTasks = ["Label prediction", "Text rewriting", "Text generation", "Data synthesis"];
const tasksWhichCanBeSelectedTogether = ["Label prediction", "Text rewriting"];

const getSelectedTasks = (selectedTasks, clickedTask) => {
  if (tasksWhichCanBeSelectedTogether.includes(clickedTask)) {
    if (selectedTasks.includes("Label prediction") || selectedTasks.includes("Text rewriting")) {
      if (selectedTasks.includes("Label prediction") && selectedTasks.includes("Text rewriting")) {
        return selectedTasks.filter(task => task !== clickedTask);
      }
      return [...new Set([...selectedTasks, clickedTask])];
    }
    if (isEqual(selectedTasks, ["Label prediction"])) {
      if (clickedTask === "Text rewriting") {
        return tasksWhichCanBeSelectedTogether;
      }
    }
    if (isEqual(selectedTasks, ["Text rewriting"])) {
      if (clickedTask === "Label prediction") {
        return tasksWhichCanBeSelectedTogether;
      }
    }
  }
  return [clickedTask];
};

const removeValueFromArray = (value, array) => {
  const valuePosition = array.indexOf(value);
  return [...array.slice(0, valuePosition), ...array.slice(valuePosition + 1)];
};

const InputText = ({ isDisabled, isEditable, text, missingWordsIndices, setMissingWordsIndices }) => {
  if (isEditable && !isDisabled) {
    return (
      <TextContainer>
        <Text isDisabled={isDisabled}>
          {text?.map(
            (word, wordIndex) =>
              !["<START>", "<END>"].includes(word) && (
                <EditableWord
                  key={wordIndex}
                  isMissing={missingWordsIndices.includes(wordIndex)}
                  onClick={() => {
                    if (missingWordsIndices.includes(wordIndex)) {
                      setMissingWordsIndices(removeValueFromArray(wordIndex, missingWordsIndices));
                      return;
                    }
                    setMissingWordsIndices([...missingWordsIndices, wordIndex]);
                  }}
                >
                  {missingWordsIndices.includes(wordIndex) ? <del>{word}</del> : word}
                </EditableWord>
              )
          )}
        </Text>
      </TextContainer>
    );
  }

  return (
    <TextContainer>
      <Text isDisabled={isDisabled}>
        {text?.map((word, wordIndex) => !["<START>", "<END>"].includes(word) && <Word>{word}</Word>)}
      </Text>
    </TextContainer>
  );
};

const TextView = ({ pipelineOutput, deploymentClusterId, featureTypeDescriptors }) => {
  const [inputTxtSrc, setInputTxtSrc] = useState(null);
  const [inputLabel, setInputLabel] = useState(null);
  const [labelOptions, setLabelOptions] = useState([]);
  const [missingWordsIndices, setMissingWordsIndices] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([enrichmentTasks[0]]);
  const [error, setError] = useState(null);

  const [selectedTaskCategory, setSelectedTaskCategory] = useState("");

  const [isLabelVisible, setIsLabelVisible] = useState(true);

  const doFetchAllLabelOptions = async () => {
    const { data } = await getDataConfigFromDeployment(deploymentClusterId);
    const labels = data?.featureTypeDescriptors.find(d => d.key === "sentence_label")?.type.categories;
    setLabelOptions(labels);
  };

  useEffect(() => {
    doFetchTestInput();
    doFetchAllLabelOptions();
  }, []);

  const doFetchTestInput = async () => {
    setInputTxtSrc(null);
    setInputLabel(null);
    const { data, error } = await getSampleDataPointFromDeployment(deploymentClusterId);
    if (error) {
      setError(error);
      return;
    }
    const { sentence_text: text, sentence_label: label } = data;
    setInputTxtSrc(text);
    setInputLabel(label);
  };

  const onTaskSelect = selectedTask => {
    const newTasks = getSelectedTasks(selectedTasks, selectedTask);
    setSelectedTasks(newTasks);
  };

  if (error) {
    return JSON.stringify(error);
  }

  let isLabelInputDisabled = false;
  let isTxtInputDisabled = false;
  let isTxtEditable = false;
  if (selectedTasks.includes("Label prediction") || selectedTasks.includes("Text rewriting")) {
    isLabelInputDisabled = true;
    isTxtEditable = true;
  }
  if (isEqual(selectedTasks, ["Text generation"])) {
    isTxtInputDisabled = true;
  }
  if (isEqual(selectedTasks, ["Data synthesis"])) {
    isTxtInputDisabled = true;
    isLabelInputDisabled = true;
  }
  if (selectedTaskCategory === "Content matching") {
    isTxtInputDisabled = false;
    isLabelInputDisabled = false;
    isTxtEditable = true;
  }
  if (selectedTaskCategory === "") {
    isTxtInputDisabled = true;
    isLabelInputDisabled = true;
    isTxtEditable = false;
  }

  return (
    <Container>
      <InputPanel>
        <ButtonsContainer>
          <GetTestImageButton value="Get test data" onClick={doFetchTestInput} />
        </ButtonsContainer>
        <TextAndLabelContainer>
          <div>
            <InputText
              isDisabled={isTxtInputDisabled}
              isEditable={isTxtEditable}
              text={inputTxtSrc}
              missingWordsIndices={missingWordsIndices}
              setMissingWordsIndices={setMissingWordsIndices}
            />
          </div>
          <SelectAndEye>
            <LabelSelectInput
              value={inputLabel}
              isDisabled={isLabelInputDisabled || !isLabelVisible}
              onSetNewValue={newVal => setInputLabel(newVal)}
              fontSize="18px"
            >
              {labelOptions?.map(label => (
                <option key={label} value={label}>
                  {label}
                </option>
              ))}
            </LabelSelectInput>
            <EyeContainer
              onClick={() => setIsLabelVisible(!isLabelVisible)}
              isEnabled={selectedTaskCategory === "Content matching"}
            >
              {isLabelVisible ? <Visibility /> : <VisibilityOff />}
            </EyeContainer>
          </SelectAndEye>
        </TextAndLabelContainer>
      </InputPanel>
      <TaskTypesContainer>
        <ExpandableOnTitleClick
          title="Data enrichment"
          isInitiallyExpanded={selectedTaskCategory === "Data enrichment"}
          onClick={() => {
            if (selectedTaskCategory === "Data enrichment") {
              setSelectedTaskCategory("");
            } else {
              setSelectedTaskCategory("Data enrichment");
            }
          }}
        >
          <TextDataEnrichmentView
            inputTxtSrc={inputTxtSrc}
            inputLabel={inputLabel}
            selectedTasks={selectedTasks}
            pipelineOutput={pipelineOutput}
            onTaskSelect={onTaskSelect}
            deploymentClusterId={deploymentClusterId}
            missingWordsIndices={missingWordsIndices}
            trainingJobId={pipelineOutput?.trainingJob?.id}
            datasetFeatureTypeDescriptors={featureTypeDescriptors}
            model={pipelineOutput.model}
          />
        </ExpandableOnTitleClick>
        <ExpandableOnTitleClick
          title="Content matching"
          isInitiallyExpanded={selectedTaskCategory === "Content matching"}
          onClick={() => {
            if (selectedTaskCategory === "Content matching") {
              setSelectedTaskCategory("");
            } else {
              setSelectedTaskCategory("Content matching");
            }
          }}
        >
          {/* <TextContentMatchingView
            deploymentClusterId={deploymentClusterId}
            inputTxtSrc={inputTxtSrc}
            inputLabel={inputLabel}
            isLabelVisible={isLabelVisible}
          /> */}
        </ExpandableOnTitleClick>
      </TaskTypesContainer>
    </Container>
  );
};

export default TextView;
