import styled from "styled-components";
import { useState } from "react";

import ModelInspectionModal from "components/views/CreatePipelineView/ModelInpsectionModal";

const ContentWrapper = styled.div`
  padding: 10px;
  display: flex;
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.closer0};
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 10px;
  overflow: auto;
  height: 450px;
`;

const ModelList = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 10px;
`;

const Model = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 5px;

  color: ${props => props.isSelected && props.theme.color.furthest};
  background-color: ${props => props.isSelected ? props.theme.color.primary : props.theme.color.furthest};

  :hover {
    color: white;
    background-color: ${props => props.theme.color.primary};
  }
`;

const ViewOption = styled.div`
  color: ${props => props.theme.color.primary};
  margin-left: 5px;
  text-decoration: underline;
  cursor: pointer;
`;

const ModelOptionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const ModelListSelect = ({ models, onModelSelect, selectedModel, datasetFeatureTypeDescriptors }) => {
  const [showModelInpsectionModal, setShowModelInpsectionModal] = useState(false);
  const [modelToView, setModelToView] = useState(null);

  return (
    <>
      {modelToView && (
        <ModelInspectionModal
          modelId={modelToView.id}
          modelName={modelToView.name}
          open={showModelInpsectionModal}
          onClose={() => {
            setShowModelInpsectionModal(false);
          }}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        />
      )}
      <ContentWrapper>
        <ModelList data-tutorial-id="model-list">
          {models?.map(model => (
            <ModelOptionContainer key={model.name}>
              <Model isSelected={model.id === selectedModel?.id} onClick={() => onModelSelect(model)}>
                {model.name}
              </Model>
              <ViewOption
                onClick={() => {
                  setModelToView(model);
                  setShowModelInpsectionModal(true);
                }}
              >
                view
              </ViewOption>
            </ModelOptionContainer>
          ))}
        </ModelList>
      </ContentWrapper>
    </>
  );
};

export default ModelListSelect;
