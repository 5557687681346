import { getUserProfileByUserName } from "api/services/projectService";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { demoUserSendMeetingRequestEmail } from "api/services/authenticationService";
import { CircularProgress } from "@material-ui/core";

const ModalContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: start;
  padding: 50px;
  width: 600px;
  overflow-y: auto;
`;

const ProgressContainer = styled.div`
  grid-column: span 2;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const LoggedInUserEmailConfigModal = ({ userName, handleClose, open }) => {
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [hasEmailSucceeded, setHasEmailSucceeded] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    doFetchUserProfile();
  }, [userName]);

  const doFetchUserProfile = async () => {
    const { data, error: getProfileErr } = await getUserProfileByUserName(userName);
    setUserEmail(data?.email);
    setError(getProfileErr);
  };

  const doSendAMeetingRequestEmail = async () => {
    let emailBody = `Hi ${userName}, \n\n Let's book a 1 hour meeting tomorrow at 7pm. \n\n warm regards \n\n demo-user`;
    setIsSendingEmail(true);
    const { error: sendEmailErr } = await demoUserSendMeetingRequestEmail({
      from: "me",
      to: [userEmail],
      subject: "shall we have a meeting ?",
      bodyText: emailBody,
    });
    setIsSendingEmail(false);
    setError(sendEmailErr);
    if (!sendEmailErr) {
      setHasEmailSucceeded(true);
    }
  };

  let modalContent = (
    <>
      <Button
        value="Public link"
        onClick={() => navigate(`/market-place/calendar/meeting-request?host=${userName}&isTutorial=true`)}
      />
      <Button value="Email bot" isDisabled={!userEmail} onClick={doSendAMeetingRequestEmail} />
    </>
  );

  if (isSendingEmail) {
    modalContent = (
      <ProgressContainer>
        <CircularProgress />
      </ProgressContainer>
    );
  }

  if (error) {
    modalContent = <div>{JSON.stringify(error)}</div>;
  }

  if (hasEmailSucceeded) {
    modalContent = <div>Email sent successfully</div>;
  }

  return (
    <Modal title="Book a meeting with yourself via:" open={open} handleClose={handleClose}>
      <ModalContent>{modalContent}</ModalContent>
    </Modal>
  );
};

export default LoggedInUserEmailConfigModal;
