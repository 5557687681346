import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { getModelById } from "api/services/modelService";
import { CenteredWithTopNavLayout } from "components/Layout";
import ModelViewer from "components/widgets/ModelViewer";
import ModelTextEditor from "components/widgets/ModelTextEditor";
import NavWithTabs from "components/ui/NavWithTabs";
import { BigTitle } from "components/ui/Text";

const ModelViewerContainer = styled.div`
  width: 100%;
  height: 600px;
  overflow: hidden;
  background-color: ${props => props.theme.color.closer0};
`;

const TextEditorContainer = styled.div`
  overflow: hidden;
  border: 1px solid grey;
`;

const ModelPage = () => {
  const { modelId } = useParams();

  const [modelConfig, setModelConfig] = useState(null);
  const [error, setError] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Graph View");

  const doFetchModelConfig = async () => {
    const { data, error } = await getModelById(modelId);
    setError(error);
    setModelConfig(data);
  };

  useEffect(() => {
    doFetchModelConfig();
  }, [modelId]);

  if (!modelConfig) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>{modelConfig.name}</BigTitle>
      <NavWithTabs
        tabNames={["Graph View", "Config View"]}
        selectedTabName={selectedTab}
        onTabSelect={newTab => setSelectedTab(newTab)}
      />
      {selectedTab === "Config View" && (
        <TextEditorContainer>
          <ModelTextEditor modelConfig={modelConfig} />
        </TextEditorContainer>
      )}
      {selectedTab === "Graph View" && (
        <ModelViewerContainer>
          <ModelViewer modelConfig={modelConfig} />
        </ModelViewerContainer>
      )}
    </CenteredWithTopNavLayout>
  );
};

export default ModelPage;
