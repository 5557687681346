import styled from "styled-components";
import { Close } from "@material-ui/icons";

const TagContainer = styled.div`
  width: max-content;
  height: 34px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 50px;
  padding: 0 10px;
  gap: 5px;
  ${props => props.isActive && `background-color: ${props.theme.color.closer0}`};

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const TagCloseIconContainer = styled.div`
  width: max-content;
  border-radius: 10px;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const FilterTag = ({ children, isActive, onToggle }) => {
  if (!isActive) {
    return <TagContainer onClick={onToggle}>{children}</TagContainer>;
  }

  return (
    <TagContainer isActive>
      {children}
      <TagCloseIconContainer onClick={onToggle}>
        <Close />
      </TagCloseIconContainer>
    </TagContainer>
  );
};

export default FilterTag;
