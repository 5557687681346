import styled from "styled-components";
import { SalesforceIcon } from "components/ui/Icons";

const FakeButton = styled.div`
  display: flex;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  background-color: white;
  border-radius: 0;
  padding: 5px 12px;
  max-width: 250px;
  max-height: 50px;
  width: max-content;
  border: 2px solid ${props => props.theme.color.closer1};
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};
  :hover {
    color: white;
    background-color: ${props => props.theme.color.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SalesforceButton = ({ onClick, value, isAuthorised }) => {
  return (
    <ButtonContainer>
      <FakeButton onClick={onClick}>
        <SalesforceIcon height="20px" isAuthorised={isAuthorised} />
        {value}
      </FakeButton>
    </ButtonContainer>
  );
};

export default SalesforceButton;
