import { useState, useEffect } from "react";
import { getRLPipelineOutputsByIds } from "api/services/projectService";

export const useRLPipelineOutputsByIds = pipelineIds => {
  const [pipelineOutputs, setPipelineOutputs] = useState([]);
  const [error, setError] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const doFetchPipelineOutputs = async () => {
    const { data, error } = await getRLPipelineOutputsByIds(pipelineIds);
    setError(error);
    setPipelineOutputs(data);
  };

  const doStartPolling = () => {
    clearInterval(intervalId);
    doFetchPipelineOutputs();
    const fetchingIntervalId = setInterval(doFetchPipelineOutputs, 2000);
    setIntervalId(fetchingIntervalId);
  }

  useEffect(() => {
    doStartPolling();
    return () => clearInterval(intervalId);
  }, [pipelineIds]);

  useEffect(() => {
    const areAllPipelinesDoneOrAborted = pipelineOutputs.every(
      pipelineOutput => pipelineOutput.jobBundle.trainingJobs.every(job => job.status === "DONE" || job.status === "DONE_FAILED" || job.status === "ABORT")
    );

    if (areAllPipelinesDoneOrAborted) {
      clearInterval(intervalId);
    }
  }, [pipelineOutputs]);

  return [pipelineOutputs, doStartPolling, error];
};
