import styled from "styled-components";
import { useState, useEffect } from "react";
import { getModelCompatibleWarmStarts } from "api/services/projectService";

const AttributeInput = styled.input`
  color: ${props => props.theme.color.closest};
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => (props.isEnabled ? props.theme.color.furthest : "transparent")};
  border-bottom: 1px solid transparent;
  width: 150px;
  ${props => props.bold && "font-weight: bold;"}

  :not(:focus) {
    background-color: transparent;
  }
`;

const AttrTitle = styled.div`
  padding: 0;
  font-size: 14px;
  font-weight: bold;
`;

const DetailsContent = styled.div`
  padding-left: 20px;
  padding-bottom: 10px;
`;

const TrainingConfigEditor = ({ config, onConfigChange, modelId, modelBeUpdated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [warmStartOptions, setWarmStartOptions] = useState([]);

  const doGetWarmStartJobIds = async () => {
    const { data, error } = await getModelCompatibleWarmStarts(modelId);
    if (error) {
      return;
    }
    setWarmStartOptions(data);
  };

  useEffect(() => {
    //remove warm start by default
    const newConfig = { ...config };
    delete newConfig.initialise_from;
    onConfigChange(newConfig);

    !!modelId && doGetWarmStartJobIds();
  }, [modelId]);

  if (!config) {
    return null;
  }

  return (
    <table>
      <tbody>
        <tr>
          <td>
            <AttrTitle>Learning rate:</AttrTitle>
          </td>
          <td>
            <DetailsContent>
              <AttributeInput
                min={0}
                step="0.01"
                type="number"
                placeholder="0.5"
                isEnabled={isEditing}
                value={config?.train?.optimizer?.learning_rate_init}
                onChange={e => {
                  const newTrainOptimizer = {
                    ...config.train.optimizer,
                    learning_rate_init: parseFloat(e.target.value),
                  };
                  const newTrainingSpecs = { ...config.train, optimizer: newTrainOptimizer };
                  const newConfig = { ...config, train: newTrainingSpecs };
                  onConfigChange(newConfig);
                }}
                onClick={() => setIsEditing(true)}
              />
            </DetailsContent>
          </td>
          <td>
            <AttrTitle>Batch Size: </AttrTitle>
          </td>
          <td>
            <DetailsContent>
              <AttributeInput
                min={0}
                step="1"
                type="number"
                isEnabled={isEditing}
                value={config?.batch_size}
                onChange={e => {
                  const newConfig = { ...config, batch_size: Number(e.target.value) };
                  onConfigChange(newConfig);
                }}
                onClick={() => setIsEditing(true)}
              />
            </DetailsContent>
          </td>
        </tr>
        <tr>
          <td>
            <AttrTitle>Training Weigth:</AttrTitle>
          </td>
          <td>
            <DetailsContent>
              <AttributeInput
                min={0}
                step="1"
                type="number"
                isEnabled={isEditing}
                value={config?.train?.trn_weight}
                onChange={e => {
                  const newTrainingSpecs = { ...config.train, trn_weight: Number(e.target.value) };
                  const newConfig = { ...config, train: newTrainingSpecs };
                  onConfigChange(newConfig);
                }}
                onClick={() => setIsEditing(true)}
              />
            </DetailsContent>
          </td>
          <td>
            <AttrTitle>Inference Likelihood Weight: </AttrTitle>
          </td>
          <td>
            <DetailsContent>
              <AttributeInput
                min={0}
                step="1"
                type="number"
                isEnabled={isEditing}
                value={config?.infer?.lkh_weight}
                onChange={e => {
                  const newInferSpecs = { ...config.infer, lkh_weight: Number(e.target.value) };
                  const newConfig = { ...config, infer: newInferSpecs };
                  onConfigChange(newConfig);
                }}
                onClick={() => setIsEditing(true)}
              />
            </DetailsContent>
          </td>
        </tr>

        <tr>
          <td>
            <AttrTitle>Number of iterations: </AttrTitle>
          </td>
          <td>
            <DetailsContent>
              <AttributeInput
                min={0}
                step="1"
                type="number"
                isEnabled={isEditing}
                value={config?.train?.max_iter}
                onChange={e => {
                  const newTrainingSpecs = { ...config.train, max_iter: Number(e.target.value) };
                  const newConfig = { ...config, train: newTrainingSpecs };
                  onConfigChange(newConfig);
                }}
                onClick={() => setIsEditing(true)}
              />
            </DetailsContent>
          </td>
        </tr>
        <td>
          <AttrTitle>Warm Start: </AttrTitle>
        </td>
        {modelBeUpdated ? (
          <td>The model has been edited and therefore no compitable warmstart can be used.</td>
        ) : (
          <td>
            <select
              onChange={e => {
                if (e.target.value !== "None") {
                  const newConfig = {
                    ...config,
                    initialise_from: {
                      training_job_id: e.target.value,
                    },
                  };
                  onConfigChange(newConfig);
                } else {
                  const newConfig = { ...config };
                  delete newConfig.initialise_from;
                  onConfigChange(newConfig);
                }
              }}
            >
              <option>None</option>
              {warmStartOptions.map(option => (
                <option value={option.id} key={option.id}>
                  {option.cubeName}, pipeline: {option.pipelineId}
                </option>
              ))}
            </select>
          </td>
        )}
        <tr></tr>
      </tbody>
    </table>
  );
};

export default TrainingConfigEditor;
