import styled from "styled-components";
import { useState, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { postModelRecommendation } from "api/services/modelService";
import {
  createCube,
  getCubeReadMeStrFromDatasetId,
  patchPipelineConfig,
  postPipelineConfig,
} from "api/services/projectService";

import useClickOutside from "hooks/useClickOutside";
import { BigTitle } from "components/ui/Text";
import Dropdown from "components/ui/Dropdown";
import Button from "components/ui/Button";
import { sleep } from "utils/common";
import { CenteredWithTopNavLayout } from "components/Layout";
import { getDatasetInfoByDatasetId } from "api/services/dataService";
import { useEffect } from "react";

const OuterContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 175px;
  width: calc(100% - 175px);
  height: 1000px;
  background-color: ${props => props.theme.color.furthest}FF;

  display: grid;
  padding-top: 40px;
  justify-content: center;
`;

const Container = styled.div`
  padding: 20px;
  width: 1000px;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;

  display: grid;
  gap: 20px;
  grid-auto-flow: row;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const TextArea = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
  padding: 8px;
  resize: none;
  width: 100%;
  height: 150px;
  font-family: "Montserrat", sans-serif;
`;

const BlueSpan = styled.span`
  color: ${props => props.theme.color.primary};
`;

const ColumnsDropdown = styled.div`
  position: absolute;
  top: 28px;
  left: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 60px;
  justify-self: center;
`;

const CONTROL_KEYS = ["ArrowUp", "ArrowDown", "Enter"];

const CreateCubePage = () => {
  const navigate = useNavigate();

  const [dropdownCoords, setDropdownCoords] = useState({ left: null });
  const [isCreatingCube, setIsCreatingCube] = useState(false);
  const [error, setError] = useState(null);
  const [datasetInfo, setDatasetInfo] = useState(null);

  const dropdownContainerRef = useRef(null);
  const promptSpanRef = useRef(null);

  useClickOutside(dropdownContainerRef, () => setDropdownCoords({ left: null }));

  const [searchParams] = useSearchParams();
  const datasetName = searchParams.get("datasetName");
  const datasetId = searchParams.get("datasetId");

  useEffect(() => {
    doFetchDatasetInfo();
  }, [datasetId]);

  const onKeyDown = e => {
    if (e.key === "Tab") {
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      setDropdownCoords({ left: rect.width + 8 });
      return;
    }

    if (CONTROL_KEYS.includes(e.key)) {
      e.preventDefault();
      return;
    }

    setDropdownCoords({ left: null });
  };

  const doFetchDatasetInfo = async () => {
    const { data } = await getDatasetInfoByDatasetId(datasetId);
    setDatasetInfo(data);
  };

  const doCreateCube = async () => {
    setIsCreatingCube(true);
    setError(null);
    const { data: readMe } = await getCubeReadMeStrFromDatasetId(datasetId);
    const { data: cube, error: cubeCreateError } = await createCube({ name: `Cube for ${datasetName}`, readMe });
    if (cubeCreateError) {
      setError(cubeCreateError);
      setIsCreatingCube(false);
      return;
    }

    const { data: modelConfig, error: modelRecError } = await postModelRecommendation({
      prompt: promptSpanRef.current.innerHTML,
      featureTypeDescriptors,
    });
    if (modelRecError) {
      setError(modelRecError);
      setIsCreatingCube(false);
      return;
    }

    const pipelineConfg = {
      datasetId: datasetId,
      cubeId: cube.id,
      status: "DRAFT",
      modelConfig,
    };

    const { data: dbPipelineConfig, error: pipelineSubmitErr } = await postPipelineConfig(pipelineConfg);
    if (pipelineSubmitErr) {
      setError(pipelineSubmitErr);
      setIsCreatingCube(false);
      return;
    }
    await sleep(1000);
    const { error: pipelinePatchErr } = await patchPipelineConfig(dbPipelineConfig?.id, {
      status: "SUBMITTED",
    });
    if (pipelinePatchErr) {
      setError(pipelinePatchErr);
      setIsCreatingCube(false);
      return;
    }

    setIsCreatingCube(false);
    navigate(`/cube/${cube.id}?tab=Model&subTab="Training"`);
  };

  // const doGetModelRecommendationAndPostPipeline = async () => {
  //   setError(null);
  //   setIsCreatingPipeline(true);

  //   const { data: modelConfig, error } = await postModelRecommendation({
  //     prompt: promptSpanRef.current.innerHTML,
  //     featureTypeDescriptors,
  //   });
  //   if (error) {
  //     setError(error);
  //     return;
  //   }

  //   const newPipelineConfig = { ...pipelineConfig, modelConfig, status: "DRAFT" };
  //   const { data: dbPipelineConfig, error: pipelineSubmitErr } = await postPipelineConfig(newPipelineConfig);
  //   if (pipelineSubmitErr) {
  //     setError(pipelineSubmitErr);
  //     return;
  //   }

  //   await sleep(1000);
  //   const { error: pipelinePatchErr } = await patchPipelineConfig(dbPipelineConfig?.id, {
  //     status: "SUBMITTED",
  //   });
  //   if (pipelinePatchErr) {
  //     setError(pipelinePatchErr);
  //     return;
  //   }

  //   navigate(`?tab=Model&subTab="Training"`);
  //   window.location.reload();
  // };

  const featureTypeDescriptors = datasetInfo?.config?.featureTypeDescriptors || [];
  const { left } = dropdownCoords;

  return (
    <CenteredWithTopNavLayout>
      <OuterContainer>
        <Container isDisabled={isCreatingCube}>
          <BigTitle>
            Create cube with <BlueSpan>"{datasetName}"</BlueSpan>
          </BigTitle>
          <TextArea>
            <span ref={promptSpanRef} onKeyDown={onKeyDown} contentEditable suppressContentEditableWarning>
              Using dataset "{datasetName}", I want to predict
            </span>
            {left !== null && (
              <ColumnsDropdown ref={dropdownContainerRef} style={{ left }}>
                <Dropdown
                  options={[
                    { value: "COLUMNS", isCategory: true },
                    ...featureTypeDescriptors.map(descriptor => ({ value: descriptor.key })),
                    { value: "ARCHITECTURES", isCategory: true },
                    { value: "BERT" },
                    { value: "GPT" },
                  ]}
                  onOptionSelect={option => {
                    const isSpaceAtEnd = promptSpanRef.current.innerHTML.endsWith("&nbsp;");
                    promptSpanRef.current.innerHTML += isSpaceAtEnd ? option.value : ` ${option.value}`;

                    promptSpanRef.current.focus();
                    document.execCommand("selectAll", false, null);
                    document.getSelection().collapseToEnd();

                    setDropdownCoords({ left: null });
                  }}
                />
              </ColumnsDropdown>
            )}
          </TextArea>
          <StyledButton onClick={doCreateCube} value="Create cube" variant="highlighted" />
          {error && <div>{JSON.stringify(error, null, 2)}</div>}
        </Container>
      </OuterContainer>
    </CenteredWithTopNavLayout>
  );
};

export default CreateCubePage;
