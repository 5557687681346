import { AreaChart as ReAreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const StackedAreaChart = ({
  data,
  xAxisKey,
  yAxisKeyAndColors,
  chartSize,
}) => {
  return (
    <div style={{width: "100%", height: chartSize.height }}>
      <ResponsiveContainer width="100%" height="100%">
        <ReAreaChart
          width={chartSize.width}
          height={chartSize.height}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxisKey} />
          <YAxis />
          <Tooltip labelStyle={{ color: "black" }} />
          {
            yAxisKeyAndColors.map((elem, i) => {
              return <Area key={i} type="monotone" dataKey={elem["key"]} stroke={elem["color"]} fill={elem["color"]} />;
            })
          }
        </ReAreaChart>
      </ResponsiveContainer>
    </div>  
  );
};


export default StackedAreaChart;