import styled from "styled-components";
import { Link } from "react-router-dom";

import { CubeIcon, DatasetIcon, DeploymentIcon, ModelIcon, TrainingIcon } from "components/ui/Icons";
import { Gap } from "components/Layout";
import UserBadge from "components/ui/UserBadge";
import ForkCubeButton from "components/widgets/ForkCubeButton";
import Tooltip from "components/ui/Tooltip";
import useCreatedByAvatarSrc from "api/services/projectService/useCreatedByAvatarSrc";
import useLatestPipelineOutputByCubeId from "api/services/projectService/useLatestPipelineOutputByCubeId";
import usePollDeploymentClusterNonStop from "components/views/DeploymentTabViewNoTasks/usePollDeploymentClusterNonStop";

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: 10px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const SubTitle = styled.div`
  font-size: 11px;
  color: ${props => props.theme.color.closest};
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const CardContainer = styled.div`
  min-height: 120px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  background: linear-gradient(
    90deg,
    ${props => props.theme.color.closer0} 0%,
    ${props => props.theme.color.closer0} 100%
  );

  :hover {
    background: none;
    background-color: ${props => props.theme.color.closer1};
    ${Title} {
      color: ${props => props.theme.color.primary};
    }

    ${CardLink} {
      color: ${props => props.theme.color.primary};
    }
  }

  .MuiCircularProgress-root {
    color: ${props => props.theme.color.primary};
  }
`;

const IconAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const CardIcons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  gap: 20px;
`;

const IconLink = styled(Link)`
  font-size: 11px;
  :hover svg {
    fill: ${props => props.theme.color.primary};
  }
`;

export const CubeCard = ({ cube, showAuthorBadge = true }) => {
  const createdByAvatarSrc = useCreatedByAvatarSrc(cube?.createdBy);

  const latestPipelineOutput = useLatestPipelineOutputByCubeId(cube?.id);
  const trainingJobStatus = latestPipelineOutput?.trainingJob?.status;

  const [deploymentCluster] = usePollDeploymentClusterNonStop([latestPipelineOutput?.trainingJob?.id]);
  let deploymentStatus = "REGSITERED";
  if (deploymentCluster?.instanceCount > 0 && !deploymentCluster?.readyToUse) {
    deploymentStatus = "IN_PROGRESS";
  }
  if (deploymentCluster?.readyToUse) {
    deploymentStatus = "DONE";
  }

  return (
    <CardContainer>
      <div>
        <Title>
          <CardLink to={`/cube/${cube?.id}`}>{cube?.name}</CardLink>
        </Title>
        <SubTitle>Created at {new Date(`${cube?.createdAt} UTC`).toString()?.slice(0, 24)}</SubTitle>
        <Gap height="10px" />
        {showAuthorBadge && <UserBadge userName={cube?.createdBy} userAvatar={createdByAvatarSrc} />}
        <Gap height="20px" />
        <CardIcons>
          <IconLink to={`/cube/${cube?.id}?tab=Dataset`}>
            <Tooltip title="Dataset" translateY="8px">
              <DatasetIcon />
            </Tooltip>
          </IconLink>
          <IconLink to={`/cube/${cube?.id}?tab=Model`}>
            <Tooltip title="Model" translateY="8px">
              <ModelIcon />
            </Tooltip>
          </IconLink>
          <IconLink to={`/cube/${cube?.id}?tab=Training`}>
            <Tooltip title="Training" translateY="8px">
              <TrainingIcon status={trainingJobStatus} />
            </Tooltip>
          </IconLink>
          <IconLink to={`/cube/${cube?.id}?tab=Deployment`}>
            <Tooltip title="API" translateY="8px">
              <DeploymentIcon status={deploymentStatus} />
            </Tooltip>
          </IconLink>
        </CardIcons>
      </div>
      <IconAndButton>
        <CubeIcon height="25px" />
        {!showAuthorBadge && <Gap height="10px" />}
        {cube?.id && <ForkCubeButton cubeId={cube?.id} />}
      </IconAndButton>
    </CardContainer>
  );
};
