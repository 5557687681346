import { useParams, useSearchParams } from "react-router-dom";
import BookSearch from "components/views/application/BookSearch";
import ChatBot from "components/views/application/ChatBot";
import CsvStatsReport from "components/views/application/CsvStatsReport";
import GoGameBot from "components/views/application/GoGameBot";
import MoleculeGeneration from "components/views/application/MoleculeGeneration";
import SuperResolution from "components/views/application/SuperResolution";
import Text2CodeGeneration from "components/views/application/Text2CodeGeneration";
import OcrSolution from "components/views/application/OcrSolution";
import SolutionView from "components/views/SolutionView";
import { useState, useEffect } from "react";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getRegisteredInterestUserById } from "api/services/projectService";
import Modal from "components/ui/Modal";
import styled from "styled-components";
import CustomerFeedbackAssistant from "components/views/application/CustomerFeedbackAssistant";

const ModalContent = styled.div`
  padding: 20px;
  font-size: 24px;
`;

const Link = styled.a`
  color: ${props => props.theme.color.primary};
`;

const ApplicationPage = () => {
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();
  const [isCheckingUser, setIsCheckingUser] = useState(true);
  const [isUserOk, setIsUserOk] = useState(false);

  const uid = searchParams.get("uid") || "";

  useEffect(() => {
    doSetIsUserOk();
  }, [uid]);

  const doSetIsUserOk = async () => {
    if (getLoggedInUserName()) {
      setIsUserOk(true);
      setIsCheckingUser(false);
      return;
    }
    const { data } = await getRegisteredInterestUserById(uid);
    setIsUserOk(!!data);
    setIsCheckingUser(false);
  };

  const applicationIdToView = {
    book_search: <BookSearch />,
    0: <CustomerFeedbackAssistant />,
    1: <ChatBot />,
    2: <SuperResolution />,
    3: <Text2CodeGeneration />,
    4: <GoGameBot />,
    5: <MoleculeGeneration />,
    6: <CsvStatsReport />,
    7: <OcrSolution />,
  };

  const generalRegisterUrl = "https://register.boltzbit.com/#/customer-feedback";

  const applicationIdToRegisterLink = {
    book_search: generalRegisterUrl,
    0: "https://register.boltzbit.com/#/customer-feedback",
    1: "https://register.boltzbit.com/#/calendar",
    2: "https://register.boltzbit.com/#/cv",
    3: generalRegisterUrl,
    4: generalRegisterUrl,
    5: generalRegisterUrl,
    6: generalRegisterUrl,
    7: "https://register.boltzbit.com/#/ocr",
  };

  if (isCheckingUser) {
    return <div>Checking user...</div>;
  }

  if (applicationIdToView[applicationId]) {
    return (
      <>
        <Modal title="Register" open={!isUserOk}>
          <ModalContent>
            Please register your interest <Link href={applicationIdToRegisterLink[applicationId]}>here</Link> to see the
            demo!
          </ModalContent>
        </Modal>
        {applicationIdToView[applicationId]}
      </>
    );
  }

  return <SolutionView solutionId={applicationId} />;
};

export default ApplicationPage;
