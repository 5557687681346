import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { getSearchResults } from "api/services/projectService";
import useClickOutside from "hooks/useClickOutside";
import { DatabaseIcon, CubeIcon } from "components/ui/Icons";
import { isEmpty } from "lodash";
import { Search } from "@material-ui/icons";

const InputContainer = styled.div`
  position: relative;
  width: 400px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 375px;
`;

const Input = styled.input`
  font-family: "Montserrat";
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  width: 100%;
  background-color: transparent;
  border-bottom: none;
  color: ${props => props.theme.color.closest};
  border: 2px solid ${props => props.theme.color.closer1};
  padding: 10px 10px 10px 35px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
  background-color: ${props => props.theme.color.furthest};
`;

const OuterContainer = styled.div`
  position: absolute;
  top: 42px;
  left: 10px;
  cursor: pointer;
`;

const TooltipContainer = styled.div`
  z-index: 10;
  border-radius: 5px;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  position: absolute;
  overflow: hidden;
  background-color: ${props => props.theme.color.furthest};
  color: ${props => props.theme.color.closest};
  height: ${props => (props.isExpanded ? "auto" : 0)};
  border: ${props => props.isExpanded && `1px solid ${props.theme.color.closer1}`};
`;

const PopoverNavLink = styled(Link)`
  width: 375px;
  padding: 10px 100px 10px 10px;
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const GroupTitle = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
  font-weight: bold;
  text-decoration: none;
`;

const LinksGroup = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.closer0};
  border-top: 1px solid ${props => props.theme.color.closer0};
`;

const ModeButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  width: 16px;
  cursor: pointer;
`;

const IconContaiener = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.primary};
`;

const SearchIcon = () => (
  <IconContaiener>
    <Search fontSize="small" />
  </IconContaiener>
);

const SearchResultsPopover = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const outerContainerRef = useRef(null);

  useClickOutside(outerContainerRef, () => setIsExpanded(false));

  useEffect(() => {
    setIsExpanded(!!children);
  }, [children]);

  return (
    <OuterContainer ref={outerContainerRef}>
      <TooltipContainer isExpanded={isExpanded}>{children}</TooltipContainer>
    </OuterContainer>
  );
};

const NavLinksGroup = ({ title, children, groupUrl = "/" }) => (
  <LinksGroup>
    <GroupTitle to={groupUrl}>
      {title}
      {title === "Datasets" && <DatabaseIcon width="20px" opacity={0.3} />}
      {title === "Cubes" && <CubeIcon width="20px" opacity={0.3} />}
    </GroupTitle>
    {children}
  </LinksGroup>
);

const SearchInputEntitySearch = ({ onCickModeButton = () => {}, query, onSetQuery }) => {
  const [results, setResults] = useState([]);
  const [areResultsLoading, setAreResultsLoading] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    setResults([]);
    !isEmpty(query) && doFetchSearchResults();
  }, [query]);

  const doFetchSearchResults = async () => {
    setAreResultsLoading(true);
    const { data } = await getSearchResults(query);
    setResults(data.filter(result => !(result.type === "DATASET" && result.name === "go")));
    setAreResultsLoading(false);
  };

  const datasetResults = results.filter(result => result.type === "DATASET");
  const cubeResults = results.filter(result => result.type === "CUBE");

  let popoverContent = null;
  if (document.activeElement !== inputRef.current) {
    popoverContent = null;
  } else if (areResultsLoading) {
    popoverContent = (
      <LoadingContainer>
        <CircularProgress size={20} />
      </LoadingContainer>
    );
  } else if (results.length > 0) {
    popoverContent = (
      <>
        <NavLinksGroup title="Datasets" groupUrl="/data">
          {datasetResults.map(result => (
            <PopoverNavLink key={result.id} to={`/data/${result.id}`}>
              {result.name}
            </PopoverNavLink>
          ))}
        </NavLinksGroup>
        <NavLinksGroup title="Cubes" groupUrl="/cubes">
          {cubeResults.map(result => (
            <PopoverNavLink key={result.id} to={`/cube/${result.id}`}>
              {result.name}
            </PopoverNavLink>
          ))}
        </NavLinksGroup>
      </>
    );
  }

  return (
    <InputContainer>
      <ModeButton onClick={onCickModeButton}>
        <SearchIcon />
      </ModeButton>
      <Input ref={inputRef} type="text" placeholder="Search" value={query} onChange={e => onSetQuery(e.target.value)} />
      <SearchResultsPopover>{popoverContent}</SearchResultsPopover>
    </InputContainer>
  );
};

export default SearchInputEntitySearch;
