import styled from "styled-components";
import { useState, useEffect } from "react";
import { getTrainingJobById } from "api/services/jobService";
import useTrainingProgressForJobId from "api/services/jobService/useTrainingProgressForJobId";
import TrainingJobLineChart from "components/widgets/TrainingJobLineChart";

const Container = styled.div`
  height: 500px;
`;

const TrainingProgressViewNoPause = ({ jobId }) => {
  const [jobWorkerId, setJobWorkerId] = useState(null);
  const [iterationPoints, error] = useTrainingProgressForJobId(jobId);

  useEffect(() => {
    process.env.REACT_APP_IS_RESEARCH && jobId && doGetJobWorkerId();
  }, [jobId]);

  const doGetJobWorkerId = async () => {
    const { data, error } = await getTrainingJobById(jobId);
    if (error) {
      return;
    }
    setJobWorkerId(data.workerId);
  };

  if (error) {
    return <Container>{JSON.stringify(error)}</Container>;
  }

  return (
    <Container>
      {process.env.REACT_APP_IS_RESEARCH && (
        <div>
          Job Id: {jobId}, Worker Id: {jobWorkerId}
        </div>
      )}
      {iterationPoints?.length > 0 && <TrainingJobLineChart data={iterationPoints} />}
    </Container>
  );
};

export default TrainingProgressViewNoPause;
