import styled from "styled-components";
import { useState, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { postModelRecommendation } from "api/services/modelService";
import { patchPipelineConfig, postPipelineConfig } from "api/services/projectService";

import useClickOutside from "hooks/useClickOutside";
import { BigTitle } from "components/ui/Text";
import Dropdown from "components/ui/Dropdown";
import Button from "components/ui/Button";
import { sleep } from "utils/common";

const OuterContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 175px;
  width: calc(100% - 175px);
  height: 1000px;
  background-color: ${props => props.theme.color.furthest}FF;

  display: grid;
  padding-top: 40px;
  justify-content: center;
`;

const Container = styled.div`
  padding: 20px;
  width: 1000px;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;

  display: grid;
  gap: 20px;
  grid-auto-flow: row;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const TextArea = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
  padding: 8px;
  resize: none;
  width: 100%;
  height: 150px;
  font-family: "Montserrat", sans-serif;
`;

const BlueSpan = styled.span`
  color: ${props => props.theme.color.primary};
`;

const ColumnsDropdown = styled.div`
  position: absolute;
  top: 28px;
  left: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 60px;
  justify-self: center;
`;

const CONTROL_KEYS = ["ArrowUp", "ArrowDown", "Enter"];

const ModelRecommendationFromPrompt = ({ datasetName = "", featureTypeDescriptors = [], pipelineConfig = {} }) => {
  const navigate = useNavigate();

  const [dropdownCoords, setDropdownCoords] = useState({ left: null });
  const [isCreatingPipeline, setIsCreatingPipeline] = useState(false);
  const [error, setError] = useState(null);

  const dropdownContainerRef = useRef(null);
  const promptSpanRef = useRef(null);

  useClickOutside(dropdownContainerRef, () => setDropdownCoords({ left: null }));

  const onKeyDown = e => {
    if (e.key === "Tab") {
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      setDropdownCoords({ left: rect.width + 8 });
      return;
    }

    if (CONTROL_KEYS.includes(e.key)) {
      e.preventDefault();
      return;
    }

    setDropdownCoords({ left: null });
  };

  const doGetModelRecommendationAndPostPipeline = async () => {
    setError(null);
    setIsCreatingPipeline(true);

    const { data: modelConfig, error } = await postModelRecommendation({
      prompt: promptSpanRef.current.innerHTML,
      featureTypeDescriptors,
    });
    if (error) {
      setError(error);
      return;
    }

    const newPipelineConfig = { ...pipelineConfig, modelConfig, status: "DRAFT" };
    const { data: dbPipelineConfig, error: pipelineSubmitErr } = await postPipelineConfig(newPipelineConfig);
    if (pipelineSubmitErr) {
      setError(pipelineSubmitErr);
      return;
    }

    await sleep(1000);
    const { error: pipelinePatchErr } = await patchPipelineConfig(dbPipelineConfig?.id, {
      status: "SUBMITTED",
    });
    if (pipelinePatchErr) {
      setError(pipelinePatchErr);
      return;
    }

    navigate(`?tab=Model&subTab="Training"`);
    window.location.reload();
  };

  const { left } = dropdownCoords;

  return (
    <OuterContainer>
      <Container isDisabled={isCreatingPipeline}>
        <BigTitle>
          Create cube with <BlueSpan>"{datasetName}"</BlueSpan>
        </BigTitle>
        <TextArea>
          <span ref={promptSpanRef} onKeyDown={onKeyDown} contentEditable suppressContentEditableWarning>
            Using dataset "{datasetName}", I want to predict
          </span>
          {left !== null && (
            <ColumnsDropdown ref={dropdownContainerRef} style={{ left }}>
              <Dropdown
                options={[
                  { value: "COLUMNS", isCategory: true },
                  ...featureTypeDescriptors.map(descriptor => ({ value: descriptor.key })),
                  { value: "ARCHITECTURES", isCategory: true },
                  { value: "BERT" },
                  { value: "GPT" },
                ]}
                onOptionSelect={option => {
                  const isSpaceAtEnd = promptSpanRef.current.innerHTML.endsWith("&nbsp;");
                  promptSpanRef.current.innerHTML += isSpaceAtEnd ? option.value : ` ${option.value}`;

                  promptSpanRef.current.focus();
                  document.execCommand("selectAll", false, null);
                  document.getSelection().collapseToEnd();

                  setDropdownCoords({ left: null });
                }}
              />
            </ColumnsDropdown>
          )}
        </TextArea>
        <StyledButton onClick={doGetModelRecommendationAndPostPipeline} value="Create cube" variant="highlighted" />
        {error && <div>{JSON.stringify(error, null, 2)}</div>}
      </Container>
    </OuterContainer>
  );
};

export default ModelRecommendationFromPrompt;
