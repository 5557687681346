import styled from "styled-components";
import { useState } from "react";

import { SmallTitle } from "components/ui/Text";

const ContentTitle = styled(SmallTitle)`
  padding-top: 0;
  width: max-content;
  cursor: pointer;
`;

const ExpandableContainer = styled.div`
  height: ${props => (props.isExpanded ? "auto" : 0)};
  transition: height 0.2s;
  overflow: hidden;
`;

const ExpandableOnTitleClick = ({ title, children, isInitiallyExpanded = false, onClick }) => {
  const [isExpanded, setIsExpanded] = useState(isInitiallyExpanded);

  return (
    <>
      <ContentTitle
        onClick={() => {
          setIsExpanded(!isExpanded);
          onClick && onClick();
        }}
      >
        {(onClick ? isInitiallyExpanded : isExpanded) ? "-" : "+"} {title}
      </ContentTitle>
      <ExpandableContainer isExpanded={onClick ? isInitiallyExpanded : isExpanded}>{children}</ExpandableContainer>
    </>
  );
};

export default ExpandableOnTitleClick;
