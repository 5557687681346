import styled from "styled-components";

import { Gap } from "components/Layout";
import ProgressBar from "components/ui/ProgressBar";
import useTrainingProgressForJobId from "api/services/jobService/useTrainingProgressForJobId";
import { useEffect } from "react";

const Container = styled.div`
  padding: 20px;
  background-color: ${props => props.theme.color.closer0_5};
`;

const JobTitle = styled.div`
  font-weight: bold;
  padding-bottom: 5px;
`;

const JobProperty = styled.div`
  padding-left: 20px;
  padding-bottom: 5px;
`;

const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  width: 300px;
  white-space: nowrap;
`;

const StatusText = styled.span`
  padding-left: 5px;
  font-weight: bold;
  color: ${props => {
    if (props.status === "DONE") {
      return props.theme.color.status_done;
    }
    if (props.status === "IN_PROGRESS" || props.status === "REGISTERED" || props.status === "KILLED") {
      return props.theme.color.in_progress;
    }
    if (props.status === "ABORT" || props.status === "DONE_FAILED") {
      return props.theme.color.error;
    }
    if (props.status === "DRAFT") {
      return props.theme.color.in_progress;
    }
  }};
`;

const statusDisplayText = {
  DONE: "Successfully Finished",
  IN_PROGRESS: "In Progress",
  ABORT: "Failed",
  DONE_FAILED: "Failed",
  REGISTERED: "Waiting",
  KILLED: "Paused",
  AWAITING_WORKER: "Awaiting Worker",
};

const PipelineStatus = ({ pipelineOutput, showTrainingOnly, doRestartPolling = () => {} }) => {
  const [trainingJobProgressPoints, status] = useTrainingProgressForJobId(pipelineOutput?.trainingJob?.id);

  const currentIter = trainingJobProgressPoints?.[trainingJobProgressPoints?.length - 1]?.iter;
  const maxIter = trainingJobProgressPoints?.[trainingJobProgressPoints?.length - 1]?.max_iter;
  const progressLeft = `${currentIter || "none"} / ${maxIter || ""}`;
  const doesPipelineHaveCustomCode = pipelineOutput?.customCode;

  useEffect(() => {
    doRestartPolling();
  }, [status]);

  if (!pipelineOutput) {
    return null;
  }

  if (pipelineOutput?.status === "DRAFT") {
    if (
      pipelineOutput?.dataProcessingJob?.status === "REGISTERED" ||
      pipelineOutput?.dataProcessingJob?.status === "IN_PROGRESS" ||
      pipelineOutput?.dataProcessingJob?.status === "ABORT" ||
      pipelineOutput?.dataProcessingJob?.status === "KILLED" ||
      pipelineOutput?.dataProcessingJob?.status === "DONE_FAILED"
    ) {
      return (
        <Container>
          <StatusText status={"DRAFT"}>
            Building a new dataset, status: {statusDisplayText[pipelineOutput?.dataProcessingJob.status]}
          </StatusText>
        </Container>
      );
    } else {
      return (
        <Container>
          <StatusText status={"DRAFT"}>The pipeline has not been submitted yet.</StatusText>
        </Container>
      );
    }
  }

  return (
    <Container>
      <JobTitle>Training job:</JobTitle>
      <JobProperty>
        status:
        <StatusText status={pipelineOutput?.trainingJob?.status}>
          {statusDisplayText[pipelineOutput?.trainingJob?.status]}
        </StatusText>
      </JobProperty>
      {!doesPipelineHaveCustomCode && (
        <>
          <JobProperty>
            <ProgressContainer>
              <ProgressBar
                currentValue={currentIter}
                maxValue={pipelineOutput?.trainingJob?.jobConfig?.train?.max_iter}
              />
              <span>{progressLeft}</span>
            </ProgressContainer>
          </JobProperty>
          <Gap />
          <JobTitle>Eval job:</JobTitle>
          <JobProperty>
            status:
            {pipelineOutput?.trainingJob?.status === "KILLED" ? (
              <StatusText status="REGISTERED">{statusDisplayText["REGISTERED"]}</StatusText>
            ) : (
              <StatusText status={pipelineOutput?.evaluationJob?.status}>
                {statusDisplayText[pipelineOutput?.evaluationJob?.status]}
              </StatusText>
            )}
          </JobProperty>
          <Gap />
        </>
      )}
    </Container>
  );
};

export default PipelineStatus;
