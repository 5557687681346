import styled from "styled-components";
import { useEffect, useState } from "react";

import { AppIcon } from "components/ui/Icons";
import { getUserProfileByUserName } from "api/services/projectService";
import { Gap } from "components/Layout";
import UserBadge from "components/ui/UserBadge";

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
  padding-bottom: 10px;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SubTitle = styled.div`
  font-size: 11px;
  color: ${props => props.theme.color.closest};
`;

const CardContainer = styled.div``;

const CardImageBanner = styled.div`
  border-radius: 6px 6px 0px 0px;
  height: 100px;
`;

const CardInfoContainer = styled.div`
  height: 100px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 15px;
  border-radius: 0px 0px 6px 6px;
  border: 1px solid ${props => props.theme.color.closer1};
  background: linear-gradient(
    90deg,
    ${props => props.theme.color.furthest} 0%,
    ${props => props.theme.color.closer0} 100%
  );
  svg {
    fill: ${props => props.theme.color.closer1_5};
  }

  :hover {
    background: none;
    background-color: ${props => props.theme.color.closer0};
    ${Title} {
      color: ${props => props.theme.color.primary};
    }
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const ApplicationCard = ({ title, subTitle, assetType = "Cube", createdByUserName, viewByLoggedInUser = true }) => {
  const [createdByAvatar, setCreatedByAvatar] = useState(null);

  const doFetchCreatorAvatar = async () => {
    const { data } = await getUserProfileByUserName(createdByUserName);
    setCreatedByAvatar(data?.image.includes("/images/") ? data?.image : `data:image/png;base64,${data?.image}`);
  };

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [createdByUserName]);

  return (
    <CardContainer>
      <CardImageBanner style={{ backgroundImage: `url("/images/booksearch_bk.jpg")` }} />
      <CardInfoContainer>
        <div>
          <Title>{title}</Title>
          <SubTitle>{subTitle}</SubTitle>
          {(!createdByUserName || !viewByLoggedInUser) && <Gap height="10px" />}
          {createdByUserName && viewByLoggedInUser && (
            <UserBadge userName={createdByUserName} userAvatar={createdByAvatar} />
          )}
        </div>
        <AppIcon width="30px" />
      </CardInfoContainer>
    </CardContainer>
  );
};

export default ApplicationCard;
