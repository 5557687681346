import styled from "styled-components";
import { useState } from "react";
import { Code, Edit, ExitToApp, Save, VisibilityOutlined } from "@material-ui/icons";
import showdown from "showdown";
import { useEffect } from "react";
import { EditIcon } from "components/ui/Icons";

const Container = styled.div`
  ${props => props.isDisabled && "opacity: 0.3; pointer-events: none;"}
`;

const TopButtons = styled.div`
  min-height: 44px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: center;
  padding-right: 10px;
  justify-content: end;
  gap: 5px;
  background-color: ${props => props.theme.color.closer0};
`;

const CircleButton = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 1px solid ${props => (props.isHighlighted ? props.theme.color.feature : "transparent")};
  height: 25px;
  width: 25px;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  svg {
    fill: ${props => props.theme.color.closest};
    font-size: 15px;
  }

  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ReadmeHtml = styled.div`
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  color: ${props => props.theme.color.closest};
  background-color: transparent;
  ul {
    list-style: inherit;
  }
`;

const TextArea = styled.textarea`
  padding: 20px;
  border: none;
  width: 100%;
  min-height: 300px;
  height: calc(100% - 38px);
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.closer0_5};
  border: 1px solid ${props => props.theme.color.closer1};
`;

const TabsContainer = styled.div`
  display: flex;
`;

const TabOption = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  line-height: 20px;
  font-weight: ${props => (props.isSelected ? "bold" : "normal")};
  color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closest)};
  background-color: ${props => (props.isSelected ? props.theme.color.closer0_5 : props.theme.color.closer0)};
`;

const ReadmeViewerAndEditor = ({ isDisabled, markdownStr = "", onClickSave = newMdStr => {}, defaultTitle = "" }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedMdStr, setEditedMdStr] = useState(markdownStr);
  const [isShowingPreview, setIsShowingPreview] = useState(false);

  useEffect(() => {
    setEditedMdStr(markdownStr);
  }, [markdownStr]);

  if (isShowingPreview) {
    const converter = new showdown.Converter();
    const readmeHtml = converter.makeHtml(editedMdStr);

    return (
      <Container isDisabled={isDisabled}>
        <TopButtons>
          <TabsContainer>
            <TabOption onClick={() => setIsShowingPreview(false)}>
              <Code /> Edit README
            </TabOption>
            <TabOption isSelected>
              <VisibilityOutlined /> Preview README
            </TabOption>
          </TabsContainer>
          <CircleButton>
            <Save
              onClick={() => {
                onClickSave(editedMdStr);
                setIsShowingPreview(false);
                setIsEditing(false);
              }}
            />
          </CircleButton>
          <CircleButton>
            <ExitToApp
              onClick={() => {
                setIsShowingPreview(false);
                setIsEditing(false);
              }}
            />
          </CircleButton>
        </TopButtons>
        <ReadmeHtml className="markdown-body" dangerouslySetInnerHTML={{ __html: readmeHtml }} />
      </Container>
    );
  }

  if (isEditing) {
    return (
      <Container isDisabled={isDisabled}>
        <TopButtons>
          <TabsContainer>
            <TabOption isSelected>
              <Code />
              Edit README
            </TabOption>
            <TabOption onClick={() => setIsShowingPreview(true)}>
              <VisibilityOutlined /> Preview README
            </TabOption>
          </TabsContainer>
          <CircleButton>
            <Save
              onClick={() => {
                onClickSave(editedMdStr);
                setIsShowingPreview(false);
                setIsEditing(false);
              }}
            />
          </CircleButton>
          <CircleButton>
            <ExitToApp onClick={() => setIsEditing(false)} />
          </CircleButton>
        </TopButtons>
        <TextArea value={editedMdStr} onChange={e => setEditedMdStr(e.target?.value)} />
      </Container>
    );
  }

  const converter = new showdown.Converter();
  const readmeHtml = converter.makeHtml(markdownStr);

  return (
    <Container isDisabled={isDisabled}>
      <TopButtons>
        <div />
        <CircleButton onClick={() => setIsEditing(true)}>
          <EditIcon height="14px" />
        </CircleButton>
      </TopButtons>
      <ReadmeHtml className="markdown-body" dangerouslySetInnerHTML={{ __html: readmeHtml }} />
    </Container>
  );
};

export default ReadmeViewerAndEditor;
