import styled from "styled-components";
import { BigTitleEditable } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import { Gap } from "components/Layout";
import SelectInput from "components/ui/SelectInput";
import Button from "components/ui/Button";
import { Close } from "@material-ui/icons";

const Container = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const TitleAndStatus = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr auto auto;
  align-items: center;
  gap: 10px;
`;

const StatusText = styled.div`
  color: ${props => props.theme.color.in_progress};
  font-weight: bold;
`;

const Questions = styled.div`
  display: grid;
  gap: 20px;
`;

const QuestionContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 5px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 20px;
  padding: 10px;
`;

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  width: max-content;
  height: max-content;
  border-radius: 20px;
  :hover {
    background: ${props => props.theme.color.furthest};
  }
`;

const BottomButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const QuestionOptionsContainer = styled.div`
  padding-top: 20px;
  display: grid;
  gap: 5px;
`;

const OptionContainer = styled.div`
  position: relative;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const AddOptionButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 5px 20px;
`;

const RemoveOptionContainer = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
  border-radius: 20px;
  :hover {
    background: ${props => props.theme.color.furthest};
  }
`;

const PreviewButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 5px 10px;
`;

const getNewSurveyWithQuestions = (survey, questions) => {
  const newSurvey = {
    ...survey,
    data: { ...survey.data, questions: questions },
  };
  return newSurvey;
};

const SurveyEditView = ({ survey, onSurveyEdit, onClickSave, onClickPublish, onClickPreview, isDisabled }) => {
  return (
    <Container isDisabled={isDisabled}>
      <TitleAndStatus>
        <BigTitleEditable
          value={survey?.data?.title}
          onNewValue={newTitle => onSurveyEdit({ ...survey, data: { ...survey.data, title: newTitle } })}
        />
        {survey?.status && <StatusText>{survey?.status}</StatusText>}
        <PreviewButton value="Preview" onClick={onClickPreview} />
      </TitleAndStatus>
      <Gap />
      <TextInput
        placeholder="Survey description"
        value={survey?.data?.description}
        onNewInput={newDescription =>
          onSurveyEdit({ ...survey, data: { ...survey.data, description: newDescription } })
        }
      />
      <Gap />
      <Questions>
        {survey?.data?.questions?.map((question, i) => (
          <QuestionContainer key={i}>
            <CloseButtonContainer
              onClick={() => {
                const newQuestions = [...survey.data.questions];
                newQuestions.splice(i, 1);
                onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
              }}
            >
              <Close />
            </CloseButtonContainer>
            <TextInput
              title="Question"
              placeholder="What is your name?"
              value={question.title}
              onNewInput={newQuestionTitle => {
                const newQuestions = [...survey.data.questions];
                newQuestions[i] = { ...question, title: newQuestionTitle };
                onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
              }}
            />
            <SelectInput
              value={question.type}
              title="Answer type"
              onSetNewValue={newType => {
                const newQuestions = [...survey.data.questions];
                newQuestions[i] = { ...question, type: newType };
                onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
              }}
            >
              <option value="text">Text</option>
              <option value="single-select">Single select</option>
            </SelectInput>
            <TextInput
              title="Column name"
              value={survey?.fields?.[i]}
              onNewInput={newField => {
                const newFields = [...survey.fields];
                newFields[i] = newField;
                onSurveyEdit({ ...survey, fields: newFields });
              }}
            />
            {question?.type === "single-select" && (
              <QuestionOptionsContainer>
                <AddOptionButton
                  value="Add option"
                  onClick={() => {
                    const newQuestions = [...survey.data.questions];
                    newQuestions[i] = { ...question, options: [...(question.options || []), ""] };
                    onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
                  }}
                />
                {question?.options?.map((option, optionInd) => (
                  <OptionContainer key={optionInd}>
                    <TextInput
                      value={option}
                      onNewInput={newOption => {
                        const newQuestions = [...survey.data.questions];
                        const newOptions = [...question.options];
                        newOptions[optionInd] = newOption;
                        newQuestions[i] = { ...question, options: newOptions };
                        onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
                      }}
                    />
                    <RemoveOptionContainer
                      onClick={() => {
                        const newQuestions = [...survey.data.questions];
                        const newOptions = [...question.options];
                        newOptions.splice(optionInd, 1);
                        newQuestions[i] = { ...question, options: newOptions };
                        onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
                      }}
                    >
                      <Close />
                    </RemoveOptionContainer>
                  </OptionContainer>
                ))}
              </QuestionOptionsContainer>
            )}
          </QuestionContainer>
        ))}
      </Questions>
      <Gap />
      <Button
        value="Add question"
        onClick={() => {
          const newQuestions = [...survey?.data?.questions, { title: "", type: "text" }];
          onSurveyEdit(getNewSurveyWithQuestions(survey, newQuestions));
        }}
      />
      <Gap />
      <BottomButtons>
        <Button value="Save" onClick={onClickSave} />
        {survey?.id && <Button value="Publish" onClick={onClickPublish} />}
      </BottomButtons>
    </Container>
  );
};

export default SurveyEditView;
