import Modal from "components/ui/Modal";
import styled from "styled-components";
import Button from "components/ui/Button";

const ModalContent = styled.div`
  padding: 25px;
  width: 500px;
  height: 180px;
  overflow-y: auto;
  font-size: 16px;
  line-height: 25px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const SwtichTabRemindModal = ({ open, handleClose, handleConfirm, section }) => {
  return (
    <Modal open={open}>
      <ModalContent>
        {`There are some unsaved changes on the "${section}" section. Are you sure you want to leave ?`}
        <Buttons>
          <Button value={"Yes, I am leaving"} onClick={() => handleConfirm()} />
          <Button value={"No, I will save changes first"} onClick={handleClose} />
        </Buttons>
      </ModalContent>
    </Modal>
  );
};

export default SwtichTabRemindModal;
