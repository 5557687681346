import { useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  align-items: start;
  gap: 20px;
  grid-auto-columns: 1fr;
`;

const TableContainer = styled.div`
  overflow: auto;
  max-height: 300px;
`;

const StyledTable = styled.table``;

const Td = styled.td`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;
`;

const Th = styled.th`
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 8px 14px;

  background-color: ${props => props.theme.color.closer0};
  font-weight: 600;

  position: sticky;
  top: 30px;
`;

const TableTitle = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  padding: 8px 0;
  font-weight: 600;
  background-color: ${props => props.theme.color.furthest};
`;

const Table = ({ style, name, rows }) => {
  return (
    <TableContainer style={style}>
      <TableTitle>{name}</TableTitle>
      <StyledTable>
        <tr>
          {rows?.[0]?.map(cell => (
            <Th>{cell}</Th>
          ))}
        </tr>
        {rows?.slice(1)?.map((row, index) => (
          <tr key={index}>
            {row?.map(cell => (
              <Td>{cell}</Td>
            ))}
          </tr>
        ))}
      </StyledTable>
    </TableContainer>
  );
};

const PlotContainer = styled.div`
  height: 200px;
`;

const PlotTitle = styled.div`
  padding: 8px 0;
  font-weight: 600;
`;

const Plot = ({ style, name, points, xLabel = "", yLabel = "" }) => {
  return (
    <PlotContainer style={style}>
      <PlotTitle>{name}</PlotTitle>
      <ResponsiveContainer>
        <LineChart data={points}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" label={{ value: xLabel, angle: 0, position: "insideBottom", offset: -5 }} />
          <YAxis label={{ value: yLabel, angle: -90, position: "insideLeft" }} />
          <Line isAnimationActive={false} type="monotone" dataKey="y" stroke="#0191ff" />
        </LineChart>
      </ResponsiveContainer>
    </PlotContainer>
  );
};

const getGridString = gridList => {
  const grid = gridList
    .map(row => {
      return `"${row.join(" ")}"`;
    })
    .join("\n");

  return grid;
};

/*
pie chart
single number
geo map
*/

const DashboardConfigVisualiser = ({ config = {} }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setIsVisible(false);
    const timeoutId = setTimeout(() => {
      setIsVisible(true);
    }, 10);

    return () => clearTimeout(timeoutId);
  }, [JSON.stringify(config?._grid)]);

  if (!config?._grid) {
    return null;
  }

  const componentNames = config?._grid?.flat()?.filter(x => x && x !== ".");

  return (
    <Container style={{ display: isVisible ? "grid" : "none", gridTemplateAreas: getGridString(config?._grid) }}>
      {componentNames?.map((componentName, index) => {
        const component = config?.[componentName];

        if (component?.type === "plot") {
          return (
            <Plot
              style={{ gridArea: componentName }}
              key={index}
              gridArea={componentName}
              name={component?.name}
              points={component?.points}
              xLabel={component?.xLabel}
              yLabel={component?.yLabel}
            />
          );
        }

        if (component?.type === "table") {
          return (
            <Table
              style={{ gridArea: componentName }}
              key={index}
              gridArea={componentName}
              name={component?.name}
              rows={component?.rows}
            />
          );
        }

        return null;
      })}
    </Container>
  );
};

export default DashboardConfigVisualiser;
