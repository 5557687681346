import { zapiGet, zapiPost } from "api/zapi";

export const getEmails = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/calendar/emails");
  return { data, error };
};

export const postEmails = async emails => {
  const { data, error } = await zapiPost("/project-service/api/v1/calendar/emails", null, emails);
  return { data, error };
};

export const postMeetingRequest = async meetingRequest => {
  const { data, error } = await zapiPost("/project-service/api/v1/calendar/meeting-request", null, meetingRequest);
  return { data, error };
};
