import styled from "styled-components";
import { useEffect, useState } from "react";
import { sleep } from "utils/common";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { Close } from "@material-ui/icons";

export const showTutorialOverlay = () => {
  document.querySelector("[data-tutorial-overlay]").style = "display: block";
};

export const blurTutorialOverlay = () => {
  document.querySelector("[data-tutorial-overlay]")?.style?.setProperty("backdrop-filter", "blur(1px)");
};

const hideTutorialOverlay = () => {
  document.querySelectorAll("[data-tutorial-id").forEach(element => {
    element.style = "";
  });
  document.querySelector("[data-tutorial-overlay]").style = "display: none";
};

const getElementByTutorialId = async tutorialId => {
  let element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  if (!element) {
    await sleep(10);
    element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  }
  if (!element) {
    await sleep(100);
    element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  }
  if (!element) {
    await sleep(1000);
    element = document.querySelector(`[data-tutorial-id=${tutorialId}]`);
  }
  return element;
};

export const highlightElementByTutorialId = async tutorialId => {
  const element = await getElementByTutorialId(tutorialId);
  element.style = `position: relative; z-index: 12;`;
};

const hideStepElements = stepElements =>
  stepElements.forEach(prevStepElement => {
    const prevStepDomElement = document.querySelector(`[data-tutorial-id=${prevStepElement.elementTutorialId}]`);
    prevStepDomElement.style = "";
  });

const getTriggerFuncForStep = (config, stepId) => {
  const stepIdToUse = stepId === null ? config.firstStepId : stepId;
  let stepElements = config?.steps?.[stepIdToUse];

  if (!stepElements) {
    localStorage.setItem("isTutorialDone", true);
    return hideTutorialOverlay;
  }

  const attachListenerToStepElement = async stepElement => {
    const stepDomElement = await getElementByTutorialId(stepElement.elementTutorialId);
    stepDomElement.style = `position: relative; z-index: 12;background-color:${stepElement?.bgHighlightColor};`;

    if (stepElement.nextStepTriggerEvent) {
      stepDomElement.addEventListener(stepElement.nextStepTriggerEvent, () => {
        hideStepElements(stepElements);
        getTriggerFuncForStep(config, stepElement.nextStepId)();
      });
    }
  };

  return async () => {
    stepIdToUse === config.firstStepId && showTutorialOverlay();
    await Promise.all(stepElements.map(attachListenerToStepElement));
  };
};

const editFirstNameTutorial = {
  firstStepId: "CLICK_EDIT",
  steps: {
    CLICK_EDIT: [
      {
        elementTutorialId: "edit-profile-button",
        nextStepTriggerEvent: "click",
        nextStepId: "TYPE_FIRST_NAME",
      },
    ],
    TYPE_FIRST_NAME: [
      {
        elementTutorialId: "first-name-input",
      },
      {
        elementTutorialId: "edit-profile-button",
      },
      {
        elementTutorialId: "save-profile-button",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const addDatasetTutorial = {
  firstStepId: "CLICK_NEW_DATASET",
  steps: {
    CLICK_NEW_DATASET: [
      {
        elementTutorialId: "new-dataset-button",
        nextStepTriggerEvent: "click",
        nextStepId: "ENTER_DATASET_NAME",
      },
    ],
    ENTER_DATASET_NAME: [
      {
        elementTutorialId: "dataset-name-input",
        nextStepTriggerEvent: "input",
        nextStepId: "UPLOAD_DATASET_FILE",
      },
    ],
    UPLOAD_DATASET_FILE: [
      {
        elementTutorialId: "choose-file-button",
      },
      {
        elementTutorialId: "dataset-name-input",
      },
      {
        elementTutorialId: "dataset-file-upload",
        nextStepTriggerEvent: "change",
        nextStepId: "CREATE_DATASET",
      },
    ],
    CREATE_DATASET: [
      {
        elementTutorialId: "create-dataset-button",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const addCubeTutorial = {
  firstStepId: "CLICK_NEW_CUBE",
  steps: {
    CLICK_NEW_CUBE: [
      {
        elementTutorialId: "new-cube-button",
        nextStepTriggerEvent: "click",
        nextStepId: "ENTER_CUBE_NAME",
      },
    ],
    ENTER_CUBE_NAME: [
      {
        elementTutorialId: "cube-name-input",
        nextStepTriggerEvent: "input",
        nextStepId: "CLICK_NEXT",
      },
    ],
    CLICK_NEXT: [
      {
        elementTutorialId: "cube-name-input",
      },
      {
        elementTutorialId: "next-button",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_SELECT_DATASET",
      },
    ],
    CLICK_SELECT_DATASET: [
      {
        elementTutorialId: "select-dataset-button",
        nextStepTriggerEvent: "click",
        nextStepId: "SELECT_DATASET",
      },
    ],
    SELECT_DATASET: [
      {
        elementTutorialId: "dataset-list",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_SELECT_MODEL",
      },
    ],
    CLICK_SELECT_MODEL: [
      {
        elementTutorialId: "select-model-button",
        nextStepTriggerEvent: "click",
        nextStepId: "SELECT_MODEL",
      },
    ],
    SELECT_MODEL: [
      {
        elementTutorialId: "model-list",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_START",
      },
    ],
    CLICK_START: [
      {
        elementTutorialId: "start-pipeline-button",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const extendCubeWithMoreData = {
  firstStepId: "CLICK_EXTEND_CUBE",
  steps: {
    CLICK_EXTEND_CUBE: [
      {
        elementTutorialId: "extend-cube-button-on-cube-card",
        nextStepId: "SELECT_DATASET",
        nextStepTriggerEvent: "click",
      },
    ],
    SELECT_DATASET: [
      {
        elementTutorialId: "select-dataset-container",
      },
      {
        elementTutorialId: "dataset-list",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const bootstrapCubeFromDatasetTutorial = {
  firstStepId: "CLICK_NEW_CUBE",
  steps: {
    CLICK_NEW_CUBE: [
      {
        elementTutorialId: "new-cube-button-on-dataset-card",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const dropDatasetColumnTutorial = {
  firstStepId: "CLICK_EDIT_DATASET",
  steps: {
    CLICK_EDIT_DATASET: [
      {
        elementTutorialId: "edit-dataset-button",
        nextStepTriggerEvent: "click",
        bgHighlightColor: "#c7c7c7",
        nextStepId: "CLICK_DROP_COLUMN",
      },
    ],
    CLICK_DROP_COLUMN: [
      {
        elementTutorialId: "drop-column-button",
        nextStepTriggerEvent: "click",
        nextStepId: "SAVE_CHANGES",
      },
      {
        elementTutorialId: "data-table",
        bgHighlightColor: "#f1f1f1",
      },
    ],
    SAVE_CHANGES: [
      {
        elementTutorialId: "save-dataset-button",
        nextStepTriggerEvent: "click",
        bgHighlightColor: "#f1f1f1",
      },
    ],
  },
};

const changeDatasetColumnTypeTutorial = {
  firstStepId: "CLICK_EDIT_DATASET",
  steps: {
    CLICK_EDIT_DATASET: [
      {
        elementTutorialId: "edit-dataset-button",
        nextStepTriggerEvent: "click",
        bgHighlightColor: "#c7c7c7",
        nextStepId: "CLICK_CHANGE_TYPE",
      },
    ],
    CLICK_CHANGE_TYPE: [
      {
        elementTutorialId: "change-type-select",
        nextStepTriggerEvent: "change",
        nextStepId: "SAVE_CHANGES",
      },
      {
        elementTutorialId: "data-table",
        bgHighlightColor: "#f1f1f1",
      },
    ],
    SAVE_CHANGES: [
      {
        elementTutorialId: "save-dataset-button",
        nextStepTriggerEvent: "click",
        bgHighlightColor: "#f1f1f1",
      },
    ],
  },
};

const createDatasetFromGoogleDrive = {
  firstStepId: "CLICK_NEW_DATASET",
  steps: {
    CLICK_NEW_DATASET: [
      {
        elementTutorialId: "new-dataset-button",
        nextStepTriggerEvent: "click",
        nextStepId: "ENTER_DATASET_NAME",
      },
    ],
    ENTER_DATASET_NAME: [
      {
        elementTutorialId: "dataset-name-input",
        nextStepTriggerEvent: "input",
        nextStepId: "CLICK_DATA_STORAGE_RADIO",
      },
    ],
    CLICK_DATA_STORAGE_RADIO: [
      {
        elementTutorialId: "data-storage-radio",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_GOOGLE_DRIVE_ICON",
        bgHighlightColor: "#f1f1f1",
      },
    ],
    CLICK_GOOGLE_DRIVE_ICON: [
      {
        elementTutorialId: "google-drive-button-container",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const createDatasetFromDataPlatform = {
  firstStepId: "CLICK_NEW_DATASET",
  steps: {
    CLICK_NEW_DATASET: [
      {
        elementTutorialId: "new-dataset-button",
        nextStepTriggerEvent: "click",
        nextStepId: "ENTER_DATASET_NAME",
      },
    ],
    ENTER_DATASET_NAME: [
      {
        elementTutorialId: "dataset-name-input",
        nextStepTriggerEvent: "input",
        nextStepId: "CLICK_DATA_PLATFORMS_RADIO",
      },
    ],
    CLICK_DATA_PLATFORMS_RADIO: [
      {
        elementTutorialId: "data-platforms-radio",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_DATA_PLATFORMS_ICON",
        bgHighlightColor: "#f1f1f1",
      },
    ],
    CLICK_DATA_PLATFORMS_ICON: [
      {
        elementTutorialId: "data-platforms-container",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const switchToAutotuneModeTutorial = {
  firstStepId: "CLICK_EDIT",
  steps: {
    CLICK_EDIT: [
      {
        elementTutorialId: "edit-button",
        bgHighlightColor: "#f1f1f1",
        nextStepId: "CLICK_AUTOTUNE_MODE",
        nextStepTriggerEvent: "click",
      },
    ],
    CLICK_AUTOTUNE_MODE: [
      {
        bgHighlightColor: "#f1f1f1",
        elementTutorialId: "autotune-mode-switch",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_SAVE",
      },
    ],
    CLICK_SAVE: [
      {
        bgHighlightColor: "#f1f1f1",
        elementTutorialId: "save-button",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

const switchToCustomTrainingModeTutorial = {
  firstStepId: "CLICK_EDIT",
  steps: {
    CLICK_EDIT: [
      {
        elementTutorialId: "edit-button",
        bgHighlightColor: "#f1f1f1",
        nextStepId: "CLICK_CUSTOM_TRAINING_MODE",
        nextStepTriggerEvent: "click",
      },
    ],
    CLICK_CUSTOM_TRAINING_MODE: [
      {
        bgHighlightColor: "#f1f1f1",
        elementTutorialId: "custom-training-mode-switch",
        nextStepTriggerEvent: "click",
        nextStepId: "CLICK_SAVE",
      },
    ],
    CLICK_SAVE: [
      {
        bgHighlightColor: "#f1f1f1",
        elementTutorialId: "customized-training-editor",
      },
      {
        bgHighlightColor: "#f1f1f1",
        elementTutorialId: "save-button",
        nextStepTriggerEvent: "click",
      },
    ],
  },
};

export const doSwitchToCustomTrainingModeTutorial = () => {
  const startTutorial = getTriggerFuncForStep(switchToCustomTrainingModeTutorial, null);
  startTutorial();
};

export const doSwitchToAutotuneModeTutorial = () => {
  const startTutorial = getTriggerFuncForStep(switchToAutotuneModeTutorial, null);
  startTutorial();
};

export const doCreateDatasetFromDataPlatformTutorial = () => {
  const startTutorial = getTriggerFuncForStep(createDatasetFromDataPlatform, null);
  startTutorial();
};

export const doCreateDatasetFromGoogleDriveTutorial = () => {
  const startTutorial = getTriggerFuncForStep(createDatasetFromGoogleDrive, null);
  startTutorial();
};

export const doStartDropDatasetColumnTutorial = () => {
  const startTutorial = getTriggerFuncForStep(dropDatasetColumnTutorial, null);
  startTutorial();
};

export const doStartChangeDatasetColumnTypeTutorial = () => {
  const startTutorial = getTriggerFuncForStep(changeDatasetColumnTypeTutorial, null);
  startTutorial();
};

export const doStartEditFirstNameTutorial = () => {
  const startTutorial = getTriggerFuncForStep(editFirstNameTutorial, null);
  startTutorial();
};

export const doStartAddDatasetTutorial = () => {
  const startTutorial = getTriggerFuncForStep(addDatasetTutorial, null);
  startTutorial();
};

export const doStartAddCubeTutorial = () => {
  const startTutorial = getTriggerFuncForStep(addCubeTutorial, null);
  startTutorial();
};

export const doStartBootstrapCubeFromDataset = () => {
  const startTutorial = getTriggerFuncForStep(bootstrapCubeFromDatasetTutorial, null);
  startTutorial();
};

export const doStartExtendCubeTutorial = () => {
  const startTutorial = getTriggerFuncForStep(extendCubeWithMoreData, null);
  startTutorial();
};

const Container = styled.div`
  padding: 20px;
`;

const Buttons = styled.div`
  display: flex;
`;

export const TutorialModalEditFirstName = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isTutorialDone, setIsTutorialDone] = useState(false);

  useEffect(() => {
    const isTutorialDone = localStorage.getItem("isTutorialDone") === "true";
    setIsTutorialDone(isTutorialDone);
  }, []);

  if (isTutorialDone) {
    return null;
  }

  return (
    <Modal open={isOpen} handleClose={() => setIsOpen(false)} title="Tutorial">
      <Container>
        Do you want to start the tutorial?
        <Buttons>
          <Button
            value="yes"
            onClick={() => {
              setIsOpen(false);
              doStartEditFirstNameTutorial();
            }}
          />
          <Button
            value="no"
            onClick={() => {
              localStorage.setItem("isTutorialDone", true);
              setIsOpen(false);
            }}
          />
        </Buttons>
      </Container>
    </Modal>
  );
};

const TutorialOverlayContainer = styled.div`
  ${props => !props.isEnabled && "display: none;"};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
`;

const Overlay = styled.div`
  background-color: black;
  opacity: ${props => (props.theme.name === "light" ? 0.5 : 0.7)};
  width: 100%;
  height: 100%;
`;

const CloseContainer = styled.div`
  background-color: ${props => props.theme.color.furthest};
  border-radius: 50px;
  width: max-content;
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 12;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const TutorialOverlay = () => (
  <TutorialOverlayContainer data-tutorial-overlay>
    <CloseContainer onClick={hideTutorialOverlay}>
      <Close fontSize="large" />
    </CloseContainer>
    <Overlay />
  </TutorialOverlayContainer>
);

export default TutorialOverlay;
