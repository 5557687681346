import styled from "styled-components";

const RadiosContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
  color: ${props => props.theme.color.closest};
  cursor: pointer;
  input {
    margin: 0;
  }
`;

const RadiosList = ({ radioOptions, selectedOption, onOptionSelect }) => (
  <RadiosContainer>
    {radioOptions.map(optionName => (
      <RadioLabel
        key={optionName}
        data-tutorial-id={
          (optionName === "Data storage" && "data-storage-radio") ||
          (optionName === "Data platforms" && "data-platforms-radio")
        }
      >
        <input checked={selectedOption === optionName} type="radio" onChange={() => onOptionSelect(optionName)} />
        {optionName}
      </RadioLabel>
    ))}
  </RadiosContainer>
);

export default RadiosList;
