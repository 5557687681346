import { useState } from "react";

import CustomCodeMirror from "components/ui/CustomCodeMirror";
import MultiOptionSelector from "components/ui/MultiOptionSelector";
import { Gap } from "components/Layout";
import { getLoginTokenFromClientStorage } from "utils/auth-utils";

const hostAndProtocol = `${window.location.protocol}//${window.location.host}`;

const generateCurlCode = (accessToken, deploymentClusterId, taskInputs, tag) => {
  const dataRaw = {
    inputs: taskInputs,
  };
  if (tag) {
    dataRaw.tag = tag;
  }

  return (
    `curl --location --request POST '${hostAndProtocol}/deployment-service/api/v1/deployment-clusters/${deploymentClusterId}/predictions' \\\n` +
    `--header 'Authorization: Bearer ${accessToken}'  \\\n` +
    "--header 'Content-Type: application/json'  \\\n" +
    "--header 'unzapify: true'  \\\n" +
    `--data-raw '${JSON.stringify(dataRaw, null, 2)}' | jq`
  );
};

const generatePythonCode = (accessToken, deploymentClusterId, taskInputs, tag) => {
  const body = {
    inputs: taskInputs,
  };
  if (tag) {
    body.tag = tag;
  }
  const bodyStr = JSON.stringify(body, null, 2).replace("null", "None");

  return (
    "import requests\n" +
    "import json\n" +
    "#accessToken only lasts 7 days\n" +
    `accessToken = '${accessToken}'\n` +
    "headers = {'Authorization': f'Bearer {accessToken}', 'unzapify': 'true'}\n" +
    `body=${bodyStr}\n` +
    `response = requests.post('${hostAndProtocol}/deployment-service/api/v1/deployment-clusters/${deploymentClusterId}/predictions', headers=headers, json=body)\n` +
    "print(json.dumps(response.json(), indent=4))"
  );
};

const ApiCode = ({ deploymentClusterId, taskInput, tag }) => {
  const [language, setLanguage] = useState("curl");
  const accessToken = getLoginTokenFromClientStorage();

  const codeString =
    language === "Python"
      ? generatePythonCode(accessToken, deploymentClusterId, [taskInput], tag)
      : generateCurlCode(accessToken, deploymentClusterId, [taskInput], tag);

  return (
    <>
      <MultiOptionSelector
        options={["curl", "Python"]}
        selectedOption={language}
        onOptionSelect={() => {
          setLanguage(language === "curl" ? "Python" : "curl");
        }}
      />
      <Gap />
      <CustomCodeMirror value={codeString} height={"400px"} showLineNumber={false} />
    </>
  );
};

export default ApiCode;
