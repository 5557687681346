import { useState } from "react";
import styled from "styled-components";

import { generateImageFromLabel } from "api/services/deploymentService";
import Button from "components/ui/Button";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";
import ImageTrueSize from "components/widgets/ImageTrueSize";

const GenerateButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const GeneratedImgLoading = styled.div`
  width: 90px;
  height: 90px;
  background-color: ${props => props.theme.color.closer0};
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const ImageGeneration = ({
  deploymentClusterId,
  inputImgLabel,
  isSelected,
  onSelect,
  trainingJobId,
  modelConfig,
  datasetFeatureTypeDescriptors,
}) => {
  const [generationResult, setGenerationResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doGenerateImage = async () => {
    setIsFetchingPrediction(true);
    const { data, error } = await generateImageFromLabel(deploymentClusterId, inputImgLabel, "generate_image");
    setGenerationResult(data);
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} readOnly />
      <TitleText>Image generation</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{
          label: inputImgLabel,
        }}
        outputCols={["image"]}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        isEnabled={isSelected}
        trainingJobId={trainingJobId}
        tag="generate_image"
        modelConfig={modelConfig}
      />
    </LabelAndModals>
  );

  if (!generationResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <GenerateButton onClick={doGenerateImage} value="Generate" />
          <GeneratedImgLoading />
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <GenerateButton onClick={doGenerateImage} value="Generate" />
        <ImageTrueSize base64Data={generationResult} />
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default ImageGeneration;
