import styled from "styled-components";
import TextEditorWithSuggestions from "components/views/application/CustomerFeedbackAssistant/TextEditorWithSuggestions";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Gap } from "components/Layout";
import { Rating } from "@material-ui/lab";
import { Button } from "@material-ui/core";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 800px 1fr;
  grid-template-rows: auto 300px 300px;
  gap: 10px;
`;

const CentralColumn = styled.div`
  grid-column: 2;
`;

const Nav = styled.div`
  padding: 10px;
  grid-column: span 3;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px;
  align-items: center;
`;

const ShopTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
`;

const BackIconContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 15px;
  gap: 10px;
  cursor: pointer;
  width: max-content;
  height: max-content;
  border-radius: 50px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
  font-weight: bold;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ProductAndDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const ProductImage = styled.img``;

const ProductDetails = styled.div`
  display: grid;
  align-content: start;
  grid-template-rows: repeat(4, auto) 1fr auto;
  gap: 10px;
`;

const ProductTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

const ProductBrand = styled.div`
  font-size: 18px;
`;

const WriteReviewText = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const RatingContainer = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 10px;
  align-items: center;
`;

const Price = styled.div`
  align-self: end;
  font-size: 48px;
`;

const ButtonContainer = styled.div`
  width: max-content;
`;

const BackLink = () => (
  <StyledLink to="/demos">
    <BackIconContainer>
      <ArrowBack />
      Back to demos
    </BackIconContainer>
  </StyledLink>
);

const CustomerFeedbackAssistant = () => (
  <Container>
    <Nav>
      <BackLink />
      <ShopTitle>Air Trip Advisor</ShopTitle>
    </Nav>
    <CentralColumn>
      <Gap />
      <ProductAndDetails>
        <ProductImage src="/images/plane.png" />
        <ProductDetails>
          <ProductTitle>Flight to Berlin</ProductTitle>
          <ProductBrand>on Mon 27th of June</ProductBrand>
          <ProductBrand>Airline: British Airways</ProductBrand>
          <RatingContainer>
            <Rating value={4} readOnly />
            (50)
          </RatingContainer>
          <Price>£ 128.00</Price>
          <ButtonContainer>
            <Button variant="contained">Buy now</Button>
          </ButtonContainer>
        </ProductDetails>
      </ProductAndDetails>
      <Gap height="50px" />
      <WriteReviewText>Leave a review for this flight</WriteReviewText>
      <Gap />
      <TextEditorWithSuggestions />
      <Gap height="100px" />
    </CentralColumn>
  </Container>
);

export default CustomerFeedbackAssistant;
