import { CubesIcon, DataIcon, FlowLogoInner, HomeIcon, TutorialsIcon } from "components/ui/Icons";
import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

const Logo = styled.img`
  width: 25px;
  padding-bottom: 50px;
  padding-top: 25px;
  align-self: center;

  @media (min-width: 768px) {
    width: 50px;
  }
`;

const Link = styled(ReactRouterLink)`
  display: flex;
  align-items: center;
`;

const HeaderNavigation = styled.header`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  background-color: #1b1f26;
  height: 100%;
  transition: width 100ms linear;
`;

const NavLink = styled(Link)`
  padding: 15px 20px;
  display: flex;
  justify-content: start;
  gap: 10px;
  text-decoration: none;
  color: white;
  transition: color 0.1s;
  ${props =>
    props.$isHighlighted &&
    `
    background: linear-gradient(
      to right,
      ${props.theme.color.feature},
      ${props.theme.color.primary}
    );
  `}

  svg {
    fill: ${props => (props.$isHighlighted ? "white" : "url(#SvgGradientPurpleToBlue)")};
  }

  :hover {
    color: ${props => props.theme.color.primary};
  }

  @media (min-width: 1440px) {
    padding: 16px;
    padding-left: 30px;
    padding-right: 60px;
  }
`;

const NavText = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const Navigation = () => {
  let highlightedTab = "home";
  if (window?.location?.pathname?.includes("/cube")) {
    highlightedTab = "cubes";
  } else if (window?.location?.pathname?.includes("/data")) {
    highlightedTab = "data";
  } else if (window?.location?.pathname?.includes("/tutorials")) {
    highlightedTab = "tutorials";
  } else if (window?.location?.pathname?.includes("/flow")) {
    highlightedTab = "flow";
  }

  return (
    <HeaderNavigation>
      <Logo src="/images/logo-white.png" />
      <NavLink to="/home" $isHighlighted={highlightedTab === "home"}>
        <HomeIcon height="18px" />
        <NavText>Home</NavText>
      </NavLink>
      <NavLink to="/cubes" $isHighlighted={highlightedTab === "cubes"}>
        <CubesIcon height="18px" />
        <NavText>Cubes</NavText>
      </NavLink>
      <NavLink to="/data" $isHighlighted={highlightedTab === "data"}>
        <DataIcon height="18px" />
        <NavText>Data</NavText>
      </NavLink>
      <NavLink to="/flow" $isHighlighted={highlightedTab === "flow"}>
        <FlowLogoInner height="18px" />
        <NavText>Flow</NavText>
      </NavLink>
      <NavLink to="/tutorials" $isHighlighted={highlightedTab === "tutorials"}>
        <TutorialsIcon height="18px" />
        <NavText>Tutorials</NavText>
      </NavLink>
    </HeaderNavigation>
  );
};

export default Navigation;
