import styled from "styled-components";
import { formatDistanceToNow } from "date-fns";

import { useState, useEffect } from "react";
import { getPipelineConfigsByIds } from "api/services/projectService";

const Label = styled.label`
  border-top: 2px solid ${props => props.topColor};
  border-bottom: 2px solid ${props => props.bottomColor};
  background-color: ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
  padding: 8px;
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  color: ${props => props.theme.color.closest};
`;

const DatasetSelectorContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;

const CreatedAtLabel = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  font-size: 12px;
  white-space: nowrap;
  color: ${props => props.theme.color.closer2};
`;

const formatDate = dateString => {
  try {
    const createdAtDate = new Date(dateString);
    return formatDistanceToNow(createdAtDate, {
      addSuffix: true,
      includeSeconds: true,
    });
  } catch {
    return "";
  }
};

const PipelineIdCheckboxSelector = ({ pipelineIds, jobIdColorMap, pipelineOutputs, onPipelineIdHover }) => {
  const [pipelineConfigs, setPipelineConfigs] = useState([]);

  const doFetchPipelineConfigs = async () => {
    const { data } = await getPipelineConfigsByIds(pipelineIds);
    setPipelineConfigs(data);
  };

  useEffect(() => {
    if (pipelineIds.length === 0) {
      return;
    }
    doFetchPipelineConfigs();
  }, [pipelineIds]);

  return (
    <DatasetSelectorContainer>
      {pipelineIds.map((pipelineId, i) => {
        const pipeline = pipelineOutputs.find(p => p.pipelineId === pipelineId);
        const jobId = pipeline?.trainingJob?.id;

        const topColor = jobIdColorMap?.[`infer_loss_${jobId}`];
        const bottomColor = jobIdColorMap?.[`train_loss_${jobId}`];

        return (
          <Label
            key={pipelineId}
            topColor={topColor}
            bottomColor={bottomColor}
            onMouseOver={() => {
              onPipelineIdHover(jobId);
            }}
          >
            {`${pipelineId.slice(0, 3)}...${pipelineId.slice(-3)}`}
            <CreatedAtLabel>
              {formatDate(pipelineConfigs.find(pipelineConfig => pipelineConfig.id === pipelineId)?.createdAt)}
            </CreatedAtLabel>
          </Label>
        );
      })}
    </DatasetSelectorContainer>
  );
};

export default PipelineIdCheckboxSelector;
