import { useEffect, useState } from "react";
import styled from "styled-components";

import { getSurveys } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import Button from "components/ui/Button";
import { Link, useNavigate } from "react-router-dom";
import { BigTitle } from "components/ui/Text";

const SurveyCards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const SurveyTitle = styled.div`
  transition: color 0.2s;
  font-size: 18px;
  font-weight: bold;
`;

const SurveyCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  padding-bottom: 20px;
  border-radius: 5px;
  transition: background-color 0.2s;
  background-color: ${props => props.theme.color.closer0};
  :hover {
    background-color: ${props => props.theme.color.closer1};
    ${SurveyTitle} {
      color: ${props => props.theme.color.primary};
    }
  }
`;

const SurveyDescription = styled.div``;

const StatusText = styled.div`
  font-weight: bold;
  color: ${props => (props.status === "DRAFT" ? props.theme.color.in_progress : props.theme.color.status_done)};
`;

const CardLink = styled(Link)`
  text-decoration: none;
`;

const SurveysListPage = () => {
  const [surveys, setSurveys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    doPopulateSurveys();
  }, []);

  const doPopulateSurveys = async () => {
    const { data, error } = await getSurveys();
    setSurveys(data);
    setError(error);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading surveys...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>Surveys</BigTitle>
      <Gap />
      <Button
        value="Add new"
        onClick={() => {
          navigate("/survey/create");
        }}
      />
      <Gap />
      <SurveyCards>
        {surveys.map(survey => (
          <CardLink key={survey.id} to={`/survey/${survey?.id}`}>
            <SurveyCardContainer>
              <SurveyTitle>{survey?.data?.title}</SurveyTitle>
              <SurveyDescription>{survey?.data?.description}</SurveyDescription>
              <StatusText status={survey?.status}>{survey?.status}</StatusText>
            </SurveyCardContainer>
          </CardLink>
        ))}
      </SurveyCards>
    </CenteredWithTopNavLayout>
  );
};

export default SurveysListPage;
