import { useState } from "react";

import SearchInputCommands from "components/widgets/SearchInputCommands";
import SearchInputEntitySearch from "components/widgets/SearchInputEntitySearch";

const SearchInput = () => {
  const [isCommandMode, setIsCommandMode] = useState(localStorage.getItem("isCommandMode") === "true");
  const [query, setQuery] = useState("");

  return isCommandMode ? (
    <SearchInputCommands
      onCickModeButton={() => {
        localStorage.setItem("isCommandMode", false);
        setIsCommandMode(false);
      }}
      query={query}
      onSetQuery={newQuery => setQuery(newQuery)}
    />
  ) : (
    <SearchInputEntitySearch
      onCickModeButton={() => {
        localStorage.setItem("isCommandMode", true);
        setIsCommandMode(true);
      }}
      query={query}
      onSetQuery={newQuery => setQuery(newQuery)}
    />
  );
};

export default SearchInput;
