import styled from "styled-components";
import { useRef, useEffect, useState } from "react";

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const Canvas = styled.canvas`
  transform-origin: top left;
  transform: scale(calc(${props => props.containerWidth} / ${props => props.width}));
  image-rendering: pixelated;
  cursor: pointer;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 5px 5px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const ImageTrueSize = ({ width = 90, height = 90, base64Data }) => {
  const canvasRef = useRef(null);
  const [dataWidth, setDataWidth] = useState(1);
  const [dataHeight, setDataHeight] = useState(1);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");

    const image = new Image();
    image.onload = () => {
      setDataWidth(image.width);
      setDataHeight(image.height);
      ctx.drawImage(image, 0, 0, image.width, image.height);
    };
    image.src = `data:image/png;base64,${base64Data}`;
  }, [base64Data]);

  return (
    <ImageContainer width={width} height={height}>
      <Canvas ref={canvasRef} containerWidth={width} width={dataWidth} height={dataHeight} />
    </ImageContainer>
  );
};

export default ImageTrueSize;
