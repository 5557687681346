import { useState, useEffect } from "react";
import RadiosList from "components/ui/RadiosList";
import styled from "styled-components";
import CustomCodeMirror from "components/ui/CustomCodeMirror";
import { getModelCode } from "api/services/modelService";

const Container = styled.div`
  height: 100%;
  padding: 20px 20px 20px 20px;
  align-items: center;
  justify-content: center;
`;

const RadiosListContainer = styled.div`
  width: max-content;
  margin: 10px;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
`;

const ModelCodeViewer = ({ modelId }) => {
  const [modelCode, setModelCode] = useState(null);
  const [library, setLibrary] = useState("TENSORFLOW");

  const libraryOptions = ["TENSORFLOW", "PYTORCH"];

  useEffect(() => {
    doFetchModelCode();
  }, [library, modelId]);

  const doFetchModelCode = async () => {
    const { data, error } = await getModelCode(modelId, library);
    if (error) {
      return;
    }
    setModelCode(data);
  };

  return (
    <Container>
      <RadiosListContainer>
        <RadiosList
          selectedOption={library}
          radioOptions={libraryOptions}
          onOptionSelect={newLibrary => setLibrary(newLibrary)}
        />
      </RadiosListContainer>
      {modelCode && <CustomCodeMirror value={JSON.parse(modelCode)} height={"400px"} showLineNumber={false} />}
    </Container>
  );
};

export default ModelCodeViewer;
