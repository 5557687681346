import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { getLoggedInUserName } from "api/services/authenticationService";
import { getGithubCubesByUserName } from "api/services/githubService";
import { getAllVisibleCubes } from "api/services/projectService";
import Button from "components/ui/Button";
import NewCubeModal from "./NewCubeModal";
import GithubCubeCard from "components/ui/GithubCubeCard";
import { by } from "utils/common";
import { CubeCard } from "components/ui/CubeCard";
import { Gap } from "components/Layout";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";

const LongCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const NewButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

const CubesOfUser = ({ userName }) => {
  const loggedInUserName = getLoggedInUserName();
  const navigate = useNavigate();

  const [githubCubeIds, setGithubCubeIds] = useState([]);
  const [isNewCubeModalOpen, setIsNewCubeModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cubes, setCubes] = useState([]);

  const doFetchUserCubes = async () => {
    setIsLoading(true);
    const { data, error } = await getAllVisibleCubes({ createdBy: userName, pageSize: 4 });
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }
    setCubes(data?.items);
    setIsLoading(false);
  };

  const doFetchUserGithubCubes = async () => {
    setIsLoading(true);
    const { data, error } = await getGithubCubesByUserName(userName);
    setIsLoading(false);
    if (error) {
      return;
    }
    setGithubCubeIds(data.map(e => e.cubeId));
  };

  useEffect(async () => {
    doFetchUserGithubCubes();
    doFetchUserCubes();
  }, []);

  if (isLoading) {
    return (
      <>
        {loggedInUserName === userName && (
          <NewButtonContainer>
            <Button value="New" onClick={() => setIsNewCubeModalOpen(true)} />
          </NewButtonContainer>
        )}
        <Gap />
        <LongCardsContainer>Cubes loading...</LongCardsContainer>
        <NewCubeModal
          open={isNewCubeModalOpen}
          onClose={() => {
            setIsNewCubeModalOpen(false);
          }}
        />
      </>
    );
  }

  if (error) {
    return "Error in loading Cubes";
  }

  return (
    <>
      {loggedInUserName === userName && (
        <NewButtonContainer>
          <ButtonCreateNewAsset
            onClick={() => navigate("/data?areCreateCubesBtnsHighlighted=true")}
            dataTutorialId="new-dataset-button"
          />
        </NewButtonContainer>
      )}
      <Gap />
      <LongCardsContainer>
        {cubes
          ?.sort(by("createdAt", "desc"))
          .map(cube =>
            githubCubeIds.includes(cube.id) ? (
              <GithubCubeCard showAuthorBadge={false} key={cube?.id} cube={cube} />
            ) : (
              <CubeCard showAuthorBadge={false} key={cube?.id} cube={cube} />
            )
          )}
      </LongCardsContainer>
      <NewCubeModal
        open={isNewCubeModalOpen}
        onClose={() => {
          setIsNewCubeModalOpen(false);
        }}
      />
    </>
  );
};

export default CubesOfUser;
