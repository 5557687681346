import styled from "styled-components";
import { GoogleCalendarIcon } from "components/ui/Icons";

const FakeButton = styled.div`
  display: flex;
  gap: 5px;
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  background-color: white;
  border-radius: 40px;
  padding: 10px 25px;
  min-width: 140px;
  max-width: 250px;
  max-height: 50px;
  width: max-content;
  border: 2px solid ${props => props.theme.color.primary};
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};
  :hover {
    color: white;
    background-color: ${props => props.theme.color.primary};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const GoogleCalendarButton = ({ onClick, value }) => {
  return (
    <ButtonContainer>
      <FakeButton onClick={onClick}>
        <GoogleCalendarIcon />
        {value}
      </FakeButton>
    </ButtonContainer>
  );
};

export default GoogleCalendarButton;
