import styled from "styled-components";
import { formatDistanceToNow } from "date-fns";
import { useState, useEffect } from "react";

import { getPipelineConfigsByIds } from "api/services/projectService";

const Label = styled.label`
  background-color: ${props => props.theme.color.closer1};
  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
  padding: 8px;
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: row;
  gap: 5px;
  align-items: center;
`;

const DatasetSelectorContainer = styled.div`
  padding: 10px;
  display: flex;
  gap: 10px;
`;

const RadioInput = styled.input`
  display: block;
  width: unset;
  margin: 0;
  margin-right: 5px;
`;

const CreatedAtLabel = styled.div`
  font-size: 12px;
  white-space: nowrap;
  color: ${props => props.theme.color.closer2};
`;

const RadioAndSha = styled.div`
  display: flex;
`;

const formatDate = dateString => {
  try {
    const createdAtDate = new Date(dateString);
    return formatDistanceToNow(createdAtDate, {
      addSuffix: true,
      includeSeconds: true,
    });
  } catch {
    return "";
  }
};

const PipelineIdRadioSelector = ({ pipelineIds, selectedPipelineId, onPipelineIdSelect, pipelineIdsFromCommits }) => {
  const [pipelineConfigs, setPipelineConfigs] = useState([]);

  useEffect(() => {
    doFetchPipelineConfigs(pipelineIds);
  }, [pipelineIds]);

  useEffect(() => {
    doFetchPipelineConfigs(pipelineIdsFromCommits);
  }, [pipelineIdsFromCommits]);

  const doFetchPipelineConfigs = async pipelineIdsToFetchFor => {
    const { data } = await getPipelineConfigsByIds(pipelineIdsToFetchFor);
    setPipelineConfigs(data);
  };

  return (
    <DatasetSelectorContainer>
      {pipelineIds.map((pipelineId, idIndex) => {
        const pipelineCreatedAt = formatDate(pipelineConfigs[idIndex]?.createdAt);
        return (
          <Label key={pipelineId}>
            <RadioAndSha>
              <RadioInput
                type="radio"
                name="datasetId"
                value={pipelineId}
                checked={selectedPipelineId === pipelineId}
                onChange={() => {}}
                onClick={() => onPipelineIdSelect(pipelineId)}
              />
              {`${pipelineId.slice(0, 3)}...${pipelineId.slice(-3)}`}
            </RadioAndSha>
            <CreatedAtLabel>{pipelineCreatedAt}</CreatedAtLabel>
          </Label>
        );
      })}
    </DatasetSelectorContainer>
  );
};

export default PipelineIdRadioSelector;
