import * as backend from "api/backend/solutions/ocrSolutionServiceEndpoint";
import { getHandlingFromError } from "api/error-handling";
import { cachedHarResponse } from "./cached_data";

export const uploadPackage = async dataFile => {
  // const formDataBody = new FormData();
  // formDataBody.append("file", dataFile);
  // const { data, error } = await backend.uploadPackage(formDataBody);
  let data = cachedHarResponse;
  return { data, error: getHandlingFromError(null) };
};
