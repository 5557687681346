import { CenteredWithTopNavLayout } from "components/Layout";
import TutorialListView from "components/views/TutorialListView";

const TutorialListPage = () => {
  return (
    <CenteredWithTopNavLayout>
      <TutorialListView />
    </CenteredWithTopNavLayout>
  );
};

export default TutorialListPage;
