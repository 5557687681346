import styled from "styled-components";
import { useState, useEffect } from "react";

import { SmallTitle } from "components/ui/Text";
import { getFileListByPipelineId, downloadPipelieFile, getFileContentByPipelineIdAndFileName } from "api/services/projectService";
import { Visibility, CloudDownload } from "@material-ui/icons";
import Modal from "components/ui/Modal";
import CustomCodeMirror from "components/ui/CustomCodeMirror";

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const IconContainer = styled.span`
  visibility: hidden;
  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const File = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid ${props => props.theme.color.closer1};

  :hover {
    ${IconContainer} {
      visibility: visible
    }
    background-color: ${props => props.theme.color.closer0};
  }
`;

const FileListTitle = styled(SmallTitle)`
  cursor: pointer;
`;

const FileListContainer = styled.div`
  height: ${props => (props.isExpanded ? `${props.maxHeight}px` : 0)};
  transition: height 0.2s;
  overflow: auto;
`;

const FileSize = styled.div`
  color: ${props => props.theme.color.closer2};
  display: flex;
  gap: 10px;
`;


const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  display: flex;
  width: 700px;
  height: 400px;
`;


const getFileSizeString = numBytes => {
  if (numBytes > 1000) {
    return `${Math.round((numBytes / 1000) * 100) / 100} kb`;
  }
  return `${numBytes} bytes`;
};

const CubeFileList = ({ pipelineId }) => {
  const [fileList, setFileList] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [showFileContentModal, setShowFileContentModal] = useState(false);
  const [selectedFileContent, setSelectedFileContent] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const [error, setError] = useState(null);

  const doFetchFileList = async () => {
    setIsLoading(true);
    const { data, error } = await getFileListByPipelineId(pipelineId);
    data && setFileList(data);
    setError(error);
    setIsLoading(false);
  };
  
  const doDownloadPipelineFile = async (filePath) => {
    await downloadPipelieFile(pipelineId, filePath);
  };

  const doGetPipelineFileContent = async (filePath) => {
    const { data, error } = await getFileContentByPipelineIdAndFileName(pipelineId, filePath);
    if (error) {
      return;
    }
    typeof(data) === "object" 
      ? 
      setSelectedFileContent(JSON.stringify(data, null, 2))
      :
      setSelectedFileContent(data.toString());
  };

  useEffect(() => {
    doFetchFileList();
  }, [pipelineId]);

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <Container>
      {
        selectedFileContent && selectedFileName && 
        <Modal 
          handleClose={() => setShowFileContentModal(false)}
          open={showFileContentModal} 
          title={selectedFileName}
        >
          <ModalContent>
            <CustomCodeMirror value={selectedFileContent} showLineNumber={false} />
          </ModalContent>
          
        </Modal>
      }
      <FileListTitle onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? "-" : "+"} Cube files
      </FileListTitle>
      <FileListContainer
        isExpanded={isExpanded}
        maxHeight={fileList?.length * 40}
      >
        {fileList?.map(file => (
          <File key={file.path}>
            {file.path}
            
            <FileSize>
              <IconContainer
                onClick={() => {
                  doDownloadPipelineFile(file.path);
                }
              }>
                <CloudDownload/>
              </IconContainer>
              <IconContainer
                onClick={() => {
                  setSelectedFileContent(null);
                  setSelectedFileName(file.path);
                  doGetPipelineFileContent(file.path);
                  setShowFileContentModal(true);
                }}
                >
                <Visibility/>
              </IconContainer>
              {getFileSizeString(file.sizeKb)}
            </FileSize>
          </File>
        ))}
      </FileListContainer>
    </Container>
  );
};

export default CubeFileList;
