import styled from "styled-components";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

import { getLoggedInUserName } from "api/services/authenticationService";
import SearchInput from "components/widgets/SearchInput";
import { getUserProfileByUserName } from "api/services/projectService";
import ItemListPopover from "components/ui/ItemListPopover";
import { setLoginTokenInClientStorage } from "utils/auth-utils";
import { errorTypes } from "api/error-handling";

const NavBody = styled.div`
  position: fixed;
  top: 0;
  height: 40px;
  padding: 10px 0;
  box-sizing: content-box;
  width: 100%;
  z-index: 2;
  align-items: center;
  background-color: ${props => props.theme.color.furthest};
  border-bottom: 1px solid ${props => props.theme.color.closer1};

  display: grid;
  grid-template-columns: 62px 1fr 1000px 1fr;
`;

const SearchInputContainer = styled.div`
  grid-column: 3;
`;

const LinksContainer = styled.div`
  display: flex;
  gap: 20px;
  grid-column: 4;
  justify-self: end;
  padding-right: 40px;
`;

const NavLink = styled(Link)`
  display: block;
  font-weight: bold;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  :hover {
    color: #acacac;
  }
`;

const PopoverNavLink = styled(Link)`
  padding: 10px 100px 10px 10px;
  display: block;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  white-space: nowrap;
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const UserAvatar = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 20px;

  border: double 2px transparent;
  border-radius: 300px;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, ${props => props.theme.color.primary}, ${props => props.theme.color.feature});
  background-origin: border-box;
  background-clip: content-box, border-box;
  object-fit: cover;
`;

const LinksGroup = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.closer0};
  border-top: 1px solid ${props => props.theme.color.closer0};
`;

const GroupTitle = styled.div`
  padding: 10px 100px 10px 10px;
  font-weight: bold;
`;

const WhiteDiv = styled.div`
  color: ${props => props.theme.color.closest};
`;

const NavLinksGroup = ({ title, children }) => (
  <LinksGroup>
    <GroupTitle>{title}</GroupTitle>
    {children}
  </LinksGroup>
);

const NavHeader = () => {
  const userName = getLoggedInUserName();
  const [avatarSrc, setAvatarSrc] = useState(null);

  const doFetchCreatorAvatar = async () => {
    const { data, error } = await getUserProfileByUserName(userName);
    setAvatarSrc(data?.image?.includes("/images/") ? data?.image : `data:image/png;base64,${data?.image}`);

    if (error?.type === errorTypes?.UNAUTHORISED) {
      setLoginTokenInClientStorage("PUBLIC");
      window.location = "/login";
    }
  };

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [userName]);

  return (
    <NavBody>
      <SearchInputContainer>
        <SearchInput />
      </SearchInputContainer>
      <LinksContainer>
        {userName ? (
          <ItemListPopover iconComponent={<UserAvatar src={avatarSrc} />}>
            <NavLinksGroup title="About You">
              <PopoverNavLink to={`/${userName}`}>Profile</PopoverNavLink>
              <PopoverNavLink to={`/${userName}/account`}>Account</PopoverNavLink>
              <PopoverNavLink to={`/${userName}/billing`}>Billing</PopoverNavLink>
              <PopoverNavLink to="/settings">Settings</PopoverNavLink>
              <PopoverNavLink to="/login" onClick={() => setLoginTokenInClientStorage("PUBLIC")}>
                Logout
              </PopoverNavLink>
            </NavLinksGroup>
          </ItemListPopover>
        ) : (
          <NavLink to="/login">
            <WhiteDiv>Login</WhiteDiv>
          </NavLink>
        )}
      </LinksContainer>
    </NavBody>
  );
};

export default NavHeader;
