import { useState, useEffect } from "react";
import styled from "styled-components";
import Editor from "@monaco-editor/react";

import { stringifyModelConfig, isModelConfigValid } from "components/widgets/ModelTextEditor/model-editor-utils";
import { parseJson } from "utils/common";

const ModelStatus = styled.div`
  font-size: 16px;
  font-weight: bold;
  right: 0;
  color: ${props => props.theme.color.error};
  color: ${props => props.isJsonValid && props.theme.color.closer2};
  color: ${props => props.isModelValid && props.theme.color.status_done};
  padding: 10px;
  border-left: 1px dashed ${props => props.theme.color.closer1};
`;

const EditorContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.isEnabled ? "1fr 200px" : "1fr")};
  textarea {
    pointer-events: ${props => (props.isEnabled ? "all" : "none")};
  }
  position: relative;
  overflow: auto;
`;

const getModelStatusText = (isJsonValid, isModelValid) => {
  if (isJsonValid && isModelValid) {
    return "Model config is valid";
  }
  if (!isJsonValid) {
    return "Json is invalid";
  }
  if (isJsonValid && !isModelValid) {
    return "Json is ok, but invalid config";
  }
  return "";
};

const ModelTextEditor = ({ modelConfig, onModelConfigChange }) => {
  const [modelConfigString, setModelConfigString] = useState(stringifyModelConfig(modelConfig));

  const [isJsonValid, setIsJsonValid] = useState(true);
  const [isModelValid, setIsModelValid] = useState(false);

  // useEffect(() => {
  //   const newModelConfigString = stringifyModelConfig(modelConfig);
  //   if (newModelConfigString === modelConfigString) {
  //     return;
  //   }
  //   setModelConfigString(newModelConfigString);
  // }, [modelConfig]);

  useEffect(() => {
    try {
      const parsedModelConfig = JSON.parse(modelConfigString);
      setIsJsonValid(true);

      if (isModelConfigValid(parsedModelConfig)) {
        onModelConfigChange(parsedModelConfig);
        setIsModelValid(true);
      } else {
        setIsModelValid(false);
      }
    } catch {
      setIsJsonValid(false);
      setIsModelValid(false);
    }
  }, [modelConfigString]);

  const modelStatusText = getModelStatusText(isJsonValid, isModelValid);
  const isInEditMode = !!onModelConfigChange;

  return (
    <EditorContainer isEnabled={isInEditMode}>
      <Editor
        value={modelConfigString}
        height="500px"
        defaultLanguage="json"
        onChange={newVal => setModelConfigString(newVal)}
        options={{
          readOnly: !isInEditMode,
        }}
      />
      {onModelConfigChange && (
        <ModelStatus isJsonValid={isJsonValid} isModelValid={isModelValid}>
          {modelStatusText}
        </ModelStatus>
      )}
    </EditorContainer>
  );
};

export default ModelTextEditor;
