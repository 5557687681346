import { useState } from "react";
import styled from "styled-components";

import Carousel from "components/ui/Carousel";
import TextLabellingArea from "components/views/TextLabellingArea";
import TextInput from "components/ui/TextInput";
import { Gap } from "components/Layout";
import { parseJson } from "utils/common";
import Button from "components/ui/Button";
import { getDataProcessingJob, postDataProcessingJob } from "api/services/dataService";
import { useEffect } from "react";
import { Save } from "@material-ui/icons";

const Container = styled.div`
  display: grid;
`;

const TextThumbnail = styled.div`
  position: relative;
  border-radius: 5px;
  width: calc(545px / 4);
  height: calc(545px / 4);
  background-color: ${props => props.theme.color.closer1};
  color: ${props => props.theme.color.closer2};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  border: 1px solid ${props => (props.isHighlighted ? props.theme.color.primary : "transparent")};
  white-space: pre;
`;

const SaveButton = styled(Button)`
  margin-top: 10px;
  padding: 2px;
  justify-self: end;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  padding: 25px 0 10px 0;
`;

const SectionTitleTop = styled(SectionTitle)`
  padding-top: 0;
`;

const useDataProcessingJob = () => {
  const [dataProcessingJob, setDataProcessingJob] = useState(null);
  const [isPostingDataProcessingJob, setIsPostingDataProcessingJob] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (!dataProcessingJob?.id) {
      return;
    }

    const jobIntervalId = setInterval(doPopulateDataProcessingJob, 2000);
    setIntervalId(jobIntervalId);

    return () => clearInterval(jobIntervalId);
  }, [dataProcessingJob?.id]);

  useEffect(() => {
    if (dataProcessingJob?.status === "DONE" || dataProcessingJob?.status === "DONE_FAILED") {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [dataProcessingJob?.status]);

  const doPostDataProcessingJob = async dataProcessingJobBody => {
    setIsPostingDataProcessingJob(true);
    const { data } = await postDataProcessingJob(dataProcessingJobBody);
    setDataProcessingJob(data);
    setIsPostingDataProcessingJob(false);
  };

  const doPopulateDataProcessingJob = async () => {
    const { data } = await getDataProcessingJob(dataProcessingJob?.id);
    setDataProcessingJob(data);
  };

  return [dataProcessingJob, doPostDataProcessingJob, isPostingDataProcessingJob];
};

const TabularDatasetPreviewWithAnnotations = ({
  dataset,
  datasetPreview,
  doFetchDatasetPreview,
  isEditable = false,
}) => {
  const colNames = Object.keys(datasetPreview[0] || {});
  const dataColumnName = colNames?.[0];
  const labelColumnName = colNames?.[1];
  const annotationsColumnName = colNames?.[2];

  const texts = datasetPreview
    .map(dataPoint => dataPoint?.[dataColumnName])
    .map(text => {
      if (Array.isArray(text)) {
        return text?.filter(token => token !== null).join(" ");
      }
      return text;
    });
  const labels = datasetPreview.map(dataPoint => dataPoint?.[labelColumnName]);
  const annotations = datasetPreview
    .map(dataPoint => dataPoint?.[annotationsColumnName])
    .map(annotationsStr => parseJson(annotationsStr));

  const [selectedTextIndex, setSelectedTextIndex] = useState(0);
  const [selectedLabelStr, setSelectedLabelStr] = useState(labels[0]);
  const [newAnnotations, setNewAnnotations] = useState(annotations[0]);
  const [dataProcessingJob, doPostDataProcessingJob, isPostingDataProcessingJob] = useDataProcessingJob();

  useEffect(() => {
    setSelectedLabelStr(labels[selectedTextIndex]);
    setNewAnnotations(annotations[selectedTextIndex]);
  }, [datasetPreview]);

  useEffect(() => {
    if (dataProcessingJob?.status === "DONE") {
      doFetchDatasetPreview();
    }
  }, [dataProcessingJob?.status]);

  const onClickSavePostDataProcessingJob = () => {
    const dataProcessingJobBody = {
      outputDataFileStatus: {
        verified: false,
      },
      inPlace: true,
      config: {
        inputDatasetId: dataset?.id,
        ops: [
          {
            name: "ChangeValues",
            config: {
              dataFileName: dataset?.dataFiles?.[0]?.name,
              data: [
                {
                  index: selectedTextIndex,
                  [labelColumnName]: selectedLabelStr,
                  [annotationsColumnName]: JSON.stringify(newAnnotations),
                },
              ],
            },
          },
        ],
      },
    };

    doPostDataProcessingJob(dataProcessingJobBody);
  };

  return (
    <Container>
      <SectionTitleTop>Data preview:</SectionTitleTop>
      <Carousel
        numVisibleSlides={4}
        slides={texts.map((text, i) => (
          <TextThumbnail
            key={`text-${i}`}
            onClick={() => {
              setSelectedTextIndex(i);
              setSelectedLabelStr(labels[i]);
              setNewAnnotations(annotations[i]);
            }}
            isHighlighted={selectedTextIndex === i}
          >
            {text}
          </TextThumbnail>
        ))}
      />
      {isEditable && (
        <SaveButton
          isDisabled={isPostingDataProcessingJob || dataProcessingJob?.status === "IN_PROGRESS"}
          onClick={onClickSavePostDataProcessingJob}
          icon={<Save />}
        />
      )}
      <SectionTitleTop>Label:</SectionTitleTop>
      <TextInput
        title={labelColumnName}
        value={selectedLabelStr}
        onNewInput={newLabel => setSelectedLabelStr(newLabel)}
      />
      <SectionTitle>Annotations:</SectionTitle>
      <TextLabellingArea
        isOpen={!!selectedTextIndex}
        onClose={() => setSelectedTextIndex(null)}
        annotations={newAnnotations}
        onNewAnnotations={newAnnotationsInText => {
          setNewAnnotations(newAnnotationsInText);
        }}
        text={texts?.[selectedTextIndex]}
      />
    </Container>
  );
};

export default TabularDatasetPreviewWithAnnotations;
