import { getJob } from "api/services/jobService";
import { useState, useEffect } from "react";

const usePollJob = jobId => {
  const [job, setJob] = useState(null);

  const doFetchJob = async () => {
    if (!jobId) {
      return;
    }

    const { data } = await getJob(jobId);
    setJob(data);
  };

  useEffect(() => {
    const intervalId = setInterval(doFetchJob, 2000);

    if (job?.status === "DONE") {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [jobId, job?.status]);

  return job;
};

export default usePollJob;
