import { ArrowForwardIos, AssignmentTurnedInOutlined, Refresh } from "@material-ui/icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { enhanceImage } from "api/services/solutions/superResSolutionService";
import { CenteredWithTopNavLayout, Gap, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import { inputImages } from "components/views/application/SuperResolution/inputImages";
import ImageTrueSizeBoundingBoxEditor from "components/widgets/ImageTrueSizeBoundingBoxEditor";
import { round } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const SuperResContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  justify-items: center;
  align-items: center;
  gap: 10px;
`;

const CarouselContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 5px;
`;

const CarouselButton = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  display: flex;
  border-radius: 50px;
  align-self: center;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const Carousel = ({ slides, onSlideSelect = () => {}, onAddMaskingBoxes }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    onSlideSelect(currentSlide);
  }, [currentSlide]);

  return (
    <CarouselContainer>
      <CarouselButton
        isDisabled={currentSlide === 0}
        onClick={() => currentSlide > 0 && setCurrentSlide(currentSlide - 1)}
      >
        <div style={{ transform: "rotate(180deg)", transformOrigin: "center" }}>
          <ArrowForwardIos style={{ paddingLeft: "4px" }} />
        </div>
      </CarouselButton>
      {slides[currentSlide]}
      <CarouselButton
        isDisabled={currentSlide === slides.length - 1}
        onClick={() => currentSlide < slides.length - 1 && setCurrentSlide(currentSlide + 1)}
      >
        <ArrowForwardIos style={{ paddingLeft: "2px", paddingRight: "2px" }} />
      </CarouselButton>
    </CarouselContainer>
  );
};

/*
api:

POST /image-enhancement
{
  "image": "ujdbyfbjafh....base64...",
  "masking_boxes": [
    { x: 0, y: 0, w: 100, h: 100 },
    { x: 100, y: 100, w: 10, h: 27 },
  ],
}

===
{
  "recovered": "njknjbjafh....base64...",
  "segmented": "dasnjbjafh....base64...",
}
*/

const ButtonContainer = styled.div`
  border: 2px solid ${props => props.theme.color.primary};
  border-radius: 20px;
  color: ${props => props.theme.color.primary};
  width: max-content;
  padding: 2px;
  cursor: pointer;
  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.primary};
  }
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"};
`;

const PanelAndImage = styled.div`
  display: grid;
  grid-template-rows: 50px 1fr;
  align-items: start;
`;

const BorderBox = styled.div`
  border: 1px solid ${props => props.theme.color.primary};
`;

const SwitchAndLabelAndScore = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-items: start;
  gap: 10px;
`;

const Label = styled.div`
  font-size: 36px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
  padding: 2px;
`;

const Score = styled.div`
  color: ${props => props.theme.color.primary};
`;

const SuperResolutionView = () => {
  const [selectedImageIndex, setSelectedImageindex] = useState("");

  const [boundingBoxesOfImages, setBoundingBoxesOfImages] = useState([...Array(inputImages.length)]);
  const [isLoading, setIsLoading] = useState(false);

  const [outputImages, setOutputImages] = useState([...Array(inputImages.length)]);
  const [outputBoxesOfImages, setOutputBoxesOfImages] = useState([...Array(inputImages.length)]);
  const [isShowingRecovered, setIsShowingRecovered] = useState(false);

  const doEnhance = async () => {
    setIsLoading(true);

    const newOutputBoxesOfImages = [...outputBoxesOfImages];
    newOutputBoxesOfImages[selectedImageIndex] = boundingBoxesOfImages[selectedImageIndex];
    setOutputBoxesOfImages(newOutputBoxesOfImages);

    const { data } = await enhanceImage(
      inputImages[selectedImageIndex]?.image,
      boundingBoxesOfImages[selectedImageIndex]
    );
    const newOutputImages = [...outputImages];
    newOutputImages[selectedImageIndex] = data;
    setOutputImages(newOutputImages);

    setIsLoading(false);
  };

  const resetBoxesAndOutputs = () => {
    const newBoundingBoxesOfImages = [...boundingBoxesOfImages];
    newBoundingBoxesOfImages[selectedImageIndex] = [];
    setBoundingBoxesOfImages(newBoundingBoxesOfImages);

    const newOutputImages = [...outputImages];
    newOutputImages[selectedImageIndex] = null;
    setOutputImages(newOutputImages);

    const newOutputBoxesOfImages = [...outputBoxesOfImages];
    newOutputBoxesOfImages[selectedImageIndex] = [];
    setOutputBoxesOfImages(newOutputBoxesOfImages);
  };

  return (
    <>
      <Gap height="10px" />
      <SuperResContainer>
        <Carousel
          slides={inputImages.map((inputImage, i) => (
            <PanelAndImage>
              <ButtonContainer onClick={resetBoxesAndOutputs}>
                <Refresh />
              </ButtonContainer>
              <ImageTrueSizeBoundingBoxEditor
                base64Data={inputImage?.image}
                width={382}
                height={382}
                boundingBoxes={boundingBoxesOfImages[i]}
                onNewBoundingBoxes={newBoundingBoxes => {
                  const newBoundingBoxesOfImages = [...boundingBoxesOfImages];
                  newBoundingBoxesOfImages[i] = newBoundingBoxes;
                  setBoundingBoxesOfImages(newBoundingBoxesOfImages);
                }}
              />
            </PanelAndImage>
          ))}
          onSlideSelect={newIndex => setSelectedImageindex(newIndex)}
        />
        <ButtonContainer onClick={doEnhance} isDisabled={isLoading}>
          <ArrowForwardIos />
        </ButtonContainer>
        {outputImages[selectedImageIndex] && (
          <PanelAndImage>
            <SwitchAndLabelAndScore>
              <TwoNameSwitch
                isOnLeft={!isShowingRecovered}
                leftName="Focus"
                rightName="Recovered"
                onSwitch={() => setIsShowingRecovered(!isShowingRecovered)}
              />

              <Label>{outputImages[selectedImageIndex]?.prediction?.label}</Label>
              <Score>{round(outputImages[selectedImageIndex]?.prediction?.score, 2)}</Score>
            </SwitchAndLabelAndScore>

            <BorderBox>
              <ImageTrueSizeBoundingBoxEditor
                base64Data={outputImages[selectedImageIndex]?.[isShowingRecovered ? "recovered" : "segmented"]}
                width={382}
                height={382}
                boundingBoxes={isShowingRecovered ? outputBoxesOfImages[selectedImageIndex] : []}
              />
            </BorderBox>
          </PanelAndImage>
        )}
      </SuperResContainer>
      <Gap />
    </>
  );
};

const Steps = ({ stepNames, onStepSelect, selectedStep }) => {
  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const SuperResolution = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const loggedInUserName = getLoggedInUserName();

  return loggedInUserName ? (
    <CenteredWithTopNavLayout>
      <BigTitle>Explainable Image Labelling</BigTitle>
      <Gap />
      {/* <Steps
        stepNames={["Import data", "Train", "Deployment review"]}
        selectedStep={selectedStep}
        onStepSelect={newStep => setSelectedStep(newStep)}
      />
      <Gap /> */}
      {selectedStep === "Import data" && <ImportDataView />}
      {selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && <SuperResolutionView />}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <BigTitle>Explainable Image Labelling</BigTitle>
      <Gap />
      <SuperResolutionView />
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default SuperResolution;
