import { useState, useEffect } from "react"
import range from 'lodash/fp/range';


const Grid = ({ size }) => {
  const cellSize = 20; 

  const [boardSize, setBoardSize] = useState(null); 
  const [guides, setGuides] = useState(null);
  const [centers, setCenters] = useState(null);


  const pointToCoordinate = (x) =>  {
    return x * cellSize + cellSize / 2.0;
  };

  useEffect(() => {
    if (size) {
      setBoardSize(pointToCoordinate(size -1 ) - pointToCoordinate(0));
      setGuides(GUIDE_DOTS[size]);
      setCenters(range(0)(size));
    }
  }, [size]);
  

  const GUIDE_DOTS = {
    19: [
      { x: 3, y: 3 },
      { x: 9, y: 3 },
      { x: 15, y: 3 },
      { x: 3, y: 9 },
      { x: 9, y: 9 },
      { x: 15, y: 9 },
      { x: 3, y: 15 },
      { x: 9, y: 15 },
      { x: 15, y: 15 }
    ],
    13: [
      { x: 3, y: 3 },
      { x: 9, y: 3 },
      { x: 3, y: 9 },
      { x: 9, y: 9 },
      { x: 6, y: 6 }
    ],
    9: [
      { x: 2, y: 2 },
      { x: 6, y: 2 },
      { x: 2, y: 6 },
      { x: 6, y: 6 },
      { x: 4, y: 4 }
    ]
  };


  return (
      <g>
        {
          boardSize && 
          <rect rx={"2"} ry={"2"} x={pointToCoordinate(0 ) - 10} y={pointToCoordinate(0 ) - 10} width={boardSize + 20} height={boardSize + 20} style={{fill: "#d2712f"}} />
        }
        { centers && 
          centers.map(
            x => (
              <line key={"row " + x} x1={pointToCoordinate(x)} x2={pointToCoordinate(x)} y1={pointToCoordinate(0)} y2={pointToCoordinate(size-1)} stroke={"black"} stroke-width={"1"}></line>
            )
          )
        }
        {
          centers &&
          centers.map(
            y => (
              <line key={"col " + y} y1={pointToCoordinate(y)} y2={pointToCoordinate(y)} x1={pointToCoordinate(0)} x2={pointToCoordinate(size-1)} stroke={"black"} stroke-width={"1"}></line>
            )
          )
        }
        {
          guides && 
          guides.map(
            (dot, i) => (
              <circle key={"dot " + i} cx={pointToCoordinate(dot.x)} cy={pointToCoordinate(dot.y)} r={"3"}></circle>)
          )
        }
      </g>
  )


}
export default Grid; 