import MeetingRequestView from "components/views/market-place/calendar/MeetingRequestView";
import MeetingRequestViewTutorial from "components/views/market-place/calendar/MeetingRequestViewTutorial";
import { useSearchParams } from "react-router-dom";

const CalendarMeetingRequestPage = () => {
  const [searchParams] = useSearchParams();
  const userName = searchParams.get("host");
  const isTutorial = searchParams.get("isTutorial");

  if (isTutorial) {
    return <MeetingRequestViewTutorial userName={userName} />;
  }

  return <MeetingRequestView userName={userName} />;
};

export default CalendarMeetingRequestPage;
