import styled from "styled-components";
import { useEffect, useState } from "react";
import { getAllVisibleCubes } from "api/services/projectService";
import { by } from "utils/common";
import { Link } from "react-router-dom";

const Container = styled.div`
  height: auto;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 20px;
`;

const CubeLink = styled(Link)`
  width: max-content;
  color: ${props => props.theme.color.primary};
`;

const DataBuiltCubesView = ({ userName }) => {
  const [cubes, setCubes] = useState([]);

  useEffect(() => {
    doFetchUserCubes();
  }, []);

  const doFetchUserCubes = async () => {
    const { data, error } = await getAllVisibleCubes({ createdBy: userName, pageSize: 5 });
    if (error) {
      return;
    }
    setCubes(data?.items);
  };

  return (
    <Container>
      {cubes?.sort(by("createdAt", "desc")).map(cube => (
        <CubeLink to={`/cube/${cube?.id}`}>{cube?.name}</CubeLink>
      ))}
    </Container>
  );
};

export default DataBuiltCubesView;
