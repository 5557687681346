import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import {
  getImagesForAnnotationTask,
  getSuggestedAnnotations,
  patchImageOfAnnotationTask,
  triggerDownloadOfImageAnnotationTaskData,
} from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import ImageTrueSizeAnnotationEditor from "components/widgets/ImageTrueSizeAnnotationEditor";
import Button from "components/ui/Button";
import usePagination from "components/navigation/pagination/usePagination";
import PaginationPagesButtons from "components/navigation/pagination/PaginationPagesButtons";
import Carousel from "components/ui/Carousel";

const Thumbnail = styled.div`
  position: relative;
  width: calc(950px / 8);
  height: calc(950px / 8);
  background-color: ${props => props.theme.color.closer0};
  ${props => props.base64Data && `background-image: url(data:image/png;base64,${props.base64Data})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const ShowSuggestionsButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 2px 10px;
  justify-self: end;
  align-self: center;
  margin-right: 10px;
`;

const Status = styled.div`
  padding: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const ExportButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NumAnnotations = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.furthest};
  border: 1px solid ${props => props.theme.color.closer1};
  border-top-left-radius: 10px;
  padding: 5px;
`;

const ImageLabellingContainer = styled.div``;

const ImageLabellingArea = ({
  annotationTaskId,
  selectedImageId,
  base64Data,
  annotations,
  onNewAnnotations,
  onClose,
}) => {
  const [isSavingAnnotations, setIsSavingAnnotations] = useState(false);
  const [isFetchingSuggestions, setIsFeedingSuggestions] = useState(false);
  const [suggestedAnnotations, setSuggestedAnnotations] = useState([]);

  const doFetchSuggestedAnnotations = async () => {
    setIsFeedingSuggestions(true);
    const { data } = await getSuggestedAnnotations(base64Data);
    setSuggestedAnnotations(data || []);
    setIsFeedingSuggestions(false);
  };

  useEffect(() => {
    setSuggestedAnnotations([]);
  }, [selectedImageId]);

  const doPatchAnnotations = async newAnnotations => {
    setIsSavingAnnotations(true);
    const { error } = await patchImageOfAnnotationTask(annotationTaskId, selectedImageId, {
      annotations: newAnnotations,
    });
    setIsSavingAnnotations(false);
    if (error) {
      alert(JSON.stringify(error));
    }
  };

  return (
    <ImageLabellingContainer>
      <Status>{isSavingAnnotations && "Saving..."}</Status>
      {suggestedAnnotations?.length === 0 ? (
        <ShowSuggestionsButton
          isDisabled={isFetchingSuggestions}
          value="Show suggestions"
          onClick={doFetchSuggestedAnnotations}
        />
      ) : (
        <ShowSuggestionsButton value="Hide suggestions" onClick={() => setSuggestedAnnotations([])} />
      )}
      <Gap />
      <ImageTrueSizeAnnotationEditor
        annotations={annotations}
        onNewAnnotations={onNewAnnotations}
        doPatchAnnotations={doPatchAnnotations}
        suggestedAnnotations={suggestedAnnotations}
        onNewSuggestedAnnotations={newSuggestedAnnotations => setSuggestedAnnotations(newSuggestedAnnotations)}
        width={1000}
        height={600}
        base64Data={base64Data}
      />
    </ImageLabellingContainer>
  );
};

const ImageAnnotationTaskPage = () => {
  const { imageAnnotationTaskId } = useParams();
  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedImageId, setSelectedImageId] = useState(null);

  const [startInd, endInd, numPages, pageSize, , setPageNum] = usePagination(images?.length, 24);

  useEffect(() => {
    doPopulateImagesForTask();
  }, [imageAnnotationTaskId]);

  const doPopulateImagesForTask = async () => {
    const { data, error } = await getImagesForAnnotationTask(imageAnnotationTaskId);
    setError(error);
    setImages([...data, ...data]);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <ExportButtonContainer>
        <Button
          value="Export annotated data"
          onClick={() => triggerDownloadOfImageAnnotationTaskData(imageAnnotationTaskId)}
        />
      </ExportButtonContainer>
      <Gap />
      <Carousel
        numVisibleSlides={7}
        slides={images.slice(startInd, endInd).map((image, i) => (
          <Thumbnail key={`${image?.id}-${i}`} onClick={() => setSelectedImageId(image?.id)} base64Data={image?.image}>
            {image?.annotations?.length ? (
              <NumAnnotations>
                {image?.annotations?.length} annotation{image?.annotations?.length > 1 && "s"}
              </NumAnnotations>
            ) : null}
          </Thumbnail>
        ))}
      />
      {numPages > 1 && (
        <PaginationPagesButtons
          numPages={numPages}
          onClickPageNum={newPageNum => setPageNum(newPageNum)}
          currentPageNum={endInd / pageSize}
        />
      )}
      <ImageLabellingArea
        annotationTaskId={imageAnnotationTaskId}
        selectedImageId={selectedImageId}
        base64Data={images?.find(image => image?.id === selectedImageId)?.image}
        annotations={images?.find(image => image?.id === selectedImageId)?.annotations || []}
        onNewAnnotations={async newAnnotations => {
          const newImages = images.map(image => {
            if (image.id === selectedImageId) {
              return { ...image, annotations: newAnnotations };
            }
            return image;
          });
          setImages(newImages);
        }}
        onClose={() => setSelectedImageId(null)}
      />
    </CenteredWithTopNavLayout>
  );
};

export default ImageAnnotationTaskPage;
