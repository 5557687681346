import styled from "styled-components";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import FavoriteIcon from "@material-ui/icons/Favorite";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import {
  cloneCube,
  deleteLike,
  getActivitiesClones,
  getCubeByCubeId,
  getLikesActivties,
  putLike,
  submitPipelineConfig,
} from "api/services/projectService";
import Modal from "components/ui/Modal";
import UserBadgePicOnly from "components/ui/UserBadgePicOnly";
import { getLoggedInUserName } from "api/services/authenticationService";

const EngagementContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
`;

const Badge = styled.div`
  display: flex;
  gap: 0px;
`;

const LeftBadge = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 12px;
  height: 25px;
  width: 80px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer2};
  padding: 10px;

  svg {
    fill: ${props => (props.isLiked ? props.theme.color.primary : props.theme.color.closer2)};
    font-size: 15px;
  }
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }

  :hover svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const RightBadge = styled.div`
  height: 25px;
  width: 35px;
  font-size: 11px;
  font-weight: bold;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 5px 5px 5px;
  border: 1px solid ${props => props.theme.color.closer2};
  border-left: 0px;
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

const ModalContent = styled.div`
  position: relative;
  min-height: 400px;
  width: 600px;
  padding: 5px;
  align-items: start;
`;

const ClonedCubeActivityLink = ({ actvity }) => {
  const [cubeName, setCubeName] = useState("");

  useEffect(() => {
    doPopulateCubeName();
  }, [actvity]);

  const doPopulateCubeName = async () => {
    const { data } = await getCubeByCubeId(actvity?.cloneId);
    setCubeName(data?.name);
  };

  return (
    <UserLink key={actvity?.cloneId} to={`/cube/${actvity?.cloneId}`}>
      <UserBadgePicOnly userId={actvity?.userId} />
      {actvity?.userId}/{cubeName}
    </UserLink>
  );
};

const ClonedCubesModal = ({ clonedCubesActivities, isOpen, onClose }) => (
  <Modal open={isOpen} handleClose={onClose}>
    <ModalContent>
      {clonedCubesActivities?.map(cloneActvity => (
        <ClonedCubeActivityLink key={cloneActvity?.id} actvity={cloneActvity} />
      ))}
    </ModalContent>
  </Modal>
);

const UsersWhoLikedModalContent = styled.div`
  position: relative;
  min-height: 400px;
  width: 400px;
  padding: 5px;
`;

const UserLink = styled(Link)`
  padding: 5px;
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const UsersWhoLikedModal = ({ likesActivities, isOpen, onClose }) => (
  <Modal open={isOpen} handleClose={onClose}>
    <UsersWhoLikedModalContent>
      {likesActivities?.map(likeActivity => (
        <UserLink to={`/${likeActivity?.userId}`}>
          <UserBadgePicOnly userId={likeActivity?.userId} />
          {likeActivity?.userId}
        </UserLink>
      ))}
    </UsersWhoLikedModalContent>
  </Modal>
);

const EngagementBadges = ({ cubeId, datasetId, modelId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const [clonedCubesActivities, setClonedCubesActivities] = useState([]);
  const [isClonedCubesModelOpen, setIsClonedCubesModelOpen] = useState(false);

  const [likesActivties, setLikesActivties] = useState([]);
  const [isLikesModalOpen, setIsLikesModalOpen] = useState(false);

  const [doesLoggedInUserLikeCube, setDoesLoggedInUserLikeCube] = useState(false);

  useEffect(() => {
    doPopulateClonedCubesActivities();
    doPopulateLikesActivities();
  }, [cubeId]);

  const doPopulateClonedCubesActivities = async () => {
    const { data } = await getActivitiesClones({ originalId: cubeId, targetType: "CUBE" });
    setClonedCubesActivities(data);
  };

  const doPopulateLikesActivities = async () => {
    const { data } = await getLikesActivties({ targetId: cubeId, targetType: "CUBE" });
    setLikesActivties(data);

    setDoesLoggedInUserLikeCube(!!data?.find(activity => activity?.userId === getLoggedInUserName()));
  };

  return (
    <EngagementContainer>
      <Badge>
        <LeftBadge
          isDisabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const { data: clonedCube, error: cloneErr } = await cloneCube(cubeId, `cloned from ${cubeId}`);
            if (cloneErr) {
              alert(JSON.stringify(cloneErr));
              return;
            }

            const { error: pipelineErr } = await submitPipelineConfig(cubeId, {
              datasetId,
              modelId,
              cubeId: clonedCube?.id,
              status: "DRAFT",
            });
            if (pipelineErr) {
              alert(JSON.stringify(pipelineErr));
              return;
            }

            navigate(`/cube/${clonedCube?.id}`);
            setIsLoading(false);
          }}
        >
          <AccountTreeIcon />
          Fork
        </LeftBadge>
        <RightBadge onClick={() => setIsClonedCubesModelOpen(true)}>{clonedCubesActivities?.length}</RightBadge>
      </Badge>
      <Badge>
        <LeftBadge
          isLiked={doesLoggedInUserLikeCube}
          isDisabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            if (doesLoggedInUserLikeCube) {
              await deleteLike({ targetId: cubeId, targetType: "CUBE" });
              await doPopulateLikesActivities();
              setDoesLoggedInUserLikeCube(false);
            } else {
              await putLike({ targetId: cubeId, targetType: "CUBE" });
              await doPopulateLikesActivities();
              setDoesLoggedInUserLikeCube(true);
            }
            setIsLoading(false);
          }}
        >
          <FavoriteIcon />
          Likes
        </LeftBadge>
        <RightBadge onClick={() => setIsLikesModalOpen(true)}>{likesActivties?.length}</RightBadge>
      </Badge>
      <ClonedCubesModal
        clonedCubesActivities={clonedCubesActivities}
        isOpen={isClonedCubesModelOpen}
        onClose={() => setIsClonedCubesModelOpen(false)}
      />
      <UsersWhoLikedModal
        likesActivities={likesActivties}
        isOpen={isLikesModalOpen}
        onClose={() => setIsLikesModalOpen(false)}
      />
    </EngagementContainer>
  );
};

export default EngagementBadges;
