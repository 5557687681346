import { useState, useEffect } from "react";
import styled from "styled-components";
import { Add } from "@material-ui/icons";

import InputDatasetPreview from "components/views/DatasetPreviewWithEditing/InputDatasetPreview";
import JoinDatasetView from "components/views/DatasetPreviewWithEditing/JoinDatasetView";
import { useSearchParams } from "react-router-dom";

const JoinOpsTabs = styled.div`
  padding-top: 10px;
  display: flex;
  gap: 10px;
  background-color: ${props => props.theme.color.closer1_5};
  align-items: center;
`;

const DatasetTabOption = styled.div`
  cursor: pointer;
  background-color: ${props => (props.isSelected ? props.theme.color.closer0 : props.theme.color.closer1)};
  padding: 10px;
`;

const AddJoinOp = styled.div`
  cursor: pointer;
  display: flex;
  border-radius: 50px;
  height: max-content;
  :hover {
    background-color: ${props => props.theme.color.closer2};
  }
`;

const DatasetPreviewWithEditing = ({ datasetId, onChangeOperations, operations = [] }) => {
  const [joinOps, setJoinOps] = useState(operations.filter(op => op.name === "Join"));
  const [nonJoinOps, setNonJoinOps] = useState(operations.filter(op => op.name !== "Join"));
  const [selectedJoinOpInd, setSelectedJoinOpInd] = useState(null);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const shouldAddJoinOp = searchParams.get("should_extend_data") === "true";
    if (shouldAddJoinOp) {
      setJoinOps([...joinOps, { name: "Join", config: { dataset: null, group: [null] } }]);
      setSelectedJoinOpInd(0);
    }
  }, [searchParams]);

  useEffect(() => {
    const combinedOps = [...joinOps, ...nonJoinOps];
    onChangeOperations(combinedOps);
  }, [joinOps, nonJoinOps]);

  let datasetTabContent = (
    <InputDatasetPreview
      nonJoinOps={nonJoinOps}
      datasetId={datasetId}
      onSetNonJoinOps={newOps => setNonJoinOps(newOps)}
    />
  );

  const selectedJoinOp = joinOps[selectedJoinOpInd];
  if (selectedJoinOp) {
    datasetTabContent = (
      <JoinDatasetView
        joinOp={selectedJoinOp}
        onChangeOp={newOp => {
          const newJoinOps = [...joinOps];
          newJoinOps[selectedJoinOpInd] = newOp;
          setJoinOps(newJoinOps);
        }}
      />
    );
  }

  return (
    <>
      <JoinOpsTabs>
        <DatasetTabOption isSelected={!selectedJoinOp} onClick={() => setSelectedJoinOpInd(null)}>
          Input dataset
        </DatasetTabOption>
        {joinOps.map((joinOp, joinOpInd) => (
          <DatasetTabOption
            onClick={() => setSelectedJoinOpInd(joinOpInd)}
            key={joinOpInd}
            isSelected={joinOpInd === selectedJoinOpInd}
          >
            {joinOp.datasetId || "join op"}
          </DatasetTabOption>
        ))}
        <AddJoinOp
          onClick={() => {
            const newOp = {
              name: "Join",
              config: {
                dataset: null,
                group: [],
              },
            };
            const newOps = [...joinOps, newOp];
            setJoinOps(newOps);
            setSelectedJoinOpInd(newOps.length - 1);
          }}
        >
          <Add />
        </AddJoinOp>
      </JoinOpsTabs>
      {datasetTabContent}
    </>
  );
};

export default DatasetPreviewWithEditing;
