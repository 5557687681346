import styled from "styled-components";
import { useEffect, useState } from "react";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { getBillOfLoggedInUser } from "api/services/projectService";
import { BigTitle, SmallTitle } from "components/ui/Text";
import { range, round } from "lodash";
import PieChart from "components/widgets/PieChart";
import JobsTable from "components/widgets/JobsTable";
import BarChartOfBill from "components/widgets/BarChartOfBill";
import { getLoggedInUserName } from "api/services/authenticationService";

const PIE_CHART_WIDTH = 200;

const Select = styled.select`
  margin-left: 10px;
  font-size: 18px;
  font-weight: bold;
`;

const Panel = styled.div`
  width: 100%;
  min-height: 116px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.theme.color.closer0};
`;

const PanelTitle = styled(SmallTitle)`
  padding-top: 0;
`;

const PieChartPanel = styled.div`
  box-sizing: content-box;
  width: ${PIE_CHART_WIDTH}px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.theme.color.closer0};
`;

const TopPanels = styled.div`
  display: flex;
  gap: 20px;
`;

const Info = styled.div`
  padding: 5px 0;
`;

const BigNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 38px;
  height: 100%;
`;

const UserUserName = styled.div`
  width: max-content;
  padding: 5px;
  border-radius: 5px;
  font-size: 20px;
  font-family: monospace;
  background-color: ${props => props.theme.color.closer1};
`;

const MonthPeriodSelector = ({ onPeriodSelect = (from, to) => {} }) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const firstDayOfMonth = new Date(selectedYear, selectedMonth, 1);
    const lastDayOfMonth = new Date(selectedYear, selectedMonth + 1, 0);
    const now = new Date();
    const periodEnd = lastDayOfMonth > now ? now : lastDayOfMonth;

    onPeriodSelect(firstDayOfMonth, periodEnd);
  }, [selectedMonth, selectedYear]);

  const getMonthOptions = () => {
    const numMonths = selectedYear === new Date().getFullYear() ? new Date().getMonth() + 1 : 12;
    return range(0, numMonths).map(i => {
      const monthDate = new Date();
      monthDate.setMonth(monthDate.getMonth() - i, 1);
      return (
        <option key={monthDate.getMonth()} value={monthDate.getMonth()}>
          {monthDate.toLocaleString("default", { month: "long" })}
        </option>
      );
    });
  };

  return (
    <>
      <Select onChange={e => setSelectedMonth(parseInt(e.target.value))}>{getMonthOptions()}</Select>
      <Select onChange={e => setSelectedYear(parseInt(e.target.value))}>
        {range(0, 5).map(i => {
          const yearDate = new Date();
          yearDate.setYear(yearDate.getFullYear() - i);
          return (
            <option key={yearDate.getFullYear()} value={yearDate.getFullYear()}>
              {yearDate.toLocaleString("default", { year: "numeric" })}
            </option>
          );
        })}
      </Select>
    </>
  );
};

const BillingPage = () => {
  const [bill, setBill] = useState(null);
  const [error, setError] = useState(null);
  const [isBillLoading, setIsBillLoading] = useState(false);

  const userName = getLoggedInUserName();

  const doFetchBill = async (from, to) => {
    setIsBillLoading(true);
    const { data, error } = await getBillOfLoggedInUser(from, to);
    setError(error);
    setBill(data);
    setIsBillLoading(false);
  };

  if (!bill) {
    return (
      <CenteredWithTopNavLayout>
        <BigTitle>
          Jobs active in
          <MonthPeriodSelector onPeriodSelect={(from, to) => doFetchBill(from, to)} />
        </BigTitle>
        Loading bill...
      </CenteredWithTopNavLayout>
    );
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  const pieData = [
    { name: "Data", value: bill.dataJobs.cost },
    { name: "Training", value: bill.trainingJobs.cost },
    { name: "API", value: bill.deployments.cost },
    { name: "Jupyter", value: bill.jupyterSessions.cost },
    { name: "Evaluation", value: bill.evaluationJobs.cost },
  ];

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>
        Jobs active in
        <MonthPeriodSelector onPeriodSelect={(from, to) => doFetchBill(from, to)} />
      </BigTitle>
      <Gap />
      <TopPanels>
        <Panel>
          <PanelTitle>Account information</PanelTitle>
          <Info>
            <UserUserName>{userName}</UserUserName>
          </Info>
          <Info>xxxx-xxxx-xxxx-xxxx</Info>
        </Panel>
        <Panel>{isBillLoading ? "Loading hours..." : <BigNumber>{round(bill.totalHours, 2)} hrs</BigNumber>}</Panel>
        <Panel>{isBillLoading ? "Loading cost..." : <BigNumber>£ {round(bill.totalCost, 2)}</BigNumber>}</Panel>
        <Panel>
          <PanelTitle>Downloads</PanelTitle>
        </Panel>
      </TopPanels>
      <Gap />
      <TopPanels>
        <PieChartPanel>
          <PanelTitle>Cost by job type (£)</PanelTitle>
          <PieChart data={pieData} width={PIE_CHART_WIDTH} height={PIE_CHART_WIDTH + 100} radius={80} />
        </PieChartPanel>
        <Panel>
          <PanelTitle>Job cost per day (£)</PanelTitle>
          <BarChartOfBill bill={bill} />
        </Panel>
      </TopPanels>
      <Gap />
      <Panel>
        <PanelTitle>Training jobs</PanelTitle>
        <JobsTable jobs={bill.trainingJobs.items} />
      </Panel>
      <Gap />
      <Panel>
        <PanelTitle>Data jobs</PanelTitle>
        <JobsTable jobs={bill.dataJobs.items} />
      </Panel>
      <Gap />
      <Panel>
        <PanelTitle>Deployments</PanelTitle>
        <JobsTable jobs={bill.deployments.items} />
      </Panel>
      <Gap />
      <Panel>
        <PanelTitle>Jupyter sessions</PanelTitle>
        <JobsTable jobs={bill.jupyterSessions.items} isJupyter />
      </Panel>
      <Gap />
      <Panel>
        <PanelTitle>Evaluation jobs</PanelTitle>
        <JobsTable jobs={bill.evaluationJobs.items} />
      </Panel>
    </CenteredWithTopNavLayout>
  );
};

export default BillingPage;
