import styled from "styled-components";
import { useState, useEffect } from "react";

import {
  getLoggedInUserActiveAccountSubscription,
  getPaymentUrlForPlan,
  cancelCurrentPlanOfLoggedInUser,
  updateSubscriptionPlan,
} from "api/services/projectService";
import { CenteredWithTopNavLayout } from "components/Layout";
import { Gap } from "components/Layout";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";

const TrPanel = styled.tr`
  background-color: ${props => (props.isEven ? "rgb(170,204, 247, 0.25)" : "rgb(170,204, 247, 0.5)")};
`;

const TrPanel1 = styled.tr`
  background-color: ${props => (props.isEven ? "rgba(178, 84, 189, 0.25)" : "rgba(178, 84, 189, 0.12)")};
`;

const ButtonTd = styled.td`
  padding-top: 20px;
`;

const UpgradeButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
`;

const Tr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const Td = styled.td`
  vertical-align: middle;
  text-align: center;
`;

const TdService = styled.td`
  font-weight: bold;
  padding-left: 10px;
  padding-top: 26px;
  padding-bottom: 26px;
`;

const Th = styled.th`
  position: relative;
  color: ${props => props.theme.color.furthest};
  text-align: center;
  width: 190px;
  vertical-align: bottom;
`;

const TickIcon = styled.div`
  display: inline;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAMAAABgOjJdAAAA8FBMVEUBkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Ckf8Dkv8Ekv8HlP8Llf8Ol/8RmP8Wmv8cnf8dnf8upP83qP8+q/9Gr/9Qs/9at/9kvP9vwP97xv98xv+Eyv+Ozv+Pz/+Qz/+Z0/+i1/+j1/+n2f+t3P+34P+44P+/4//B5P/I5//Q6//X7v/s9//w+f/1+//5/P/6/f/8/v/+//////8iWwJrAAAAI3RSTlMAAQYHCQ42NzhPUFFrbG19gIGZmrW2vb7Cw9rb3N3e4fDx8gBaxB4AAAEYSURBVHjahdSDlitBEAbgim0bf3CxjJ2smdT7P81qPNO98x31QatEJsF0odFGu1FIB0nAH6/CUEv4ySbSglUrSma+LJyyPtIFShApBUjlLUKs6CVFFjJZ+hGF3M9z/S3ItfxEFMdvEkRUhcD57Rl+VIlCEPj/xPdQhCgFp3+P/HwBRYrycPj7wC+XUBWoCbs/d/x6BU2TOlAMZ0N1ccNv19B1Cao9Hwb4Mjjw+wgm+hnjI2/7QH/HxzFMusY7Jife9HobPk1g1jT9Zcq8XDJPYVEwx2POX+awSlliumBewCZkzctqDZuqe27d68O9xn7kIJMjhbcMsYrPvV/ce84k6ujbGNn4k3UY6kk/qezzo4NOo5AJe0j3CacefLde8OstAAAAAElFTkSuQmCC);
`;

const CrossIcon = styled.div`
  display: inline;
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAMAAABgOjJdAAAArlBMVEXm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubm5ubn5+fo6Ojp6enq6urr6+vs7Ozt7e3u7u7w8PDx8fHy8vLz8/P09PT19fX29vb4+Pj5+fn6+vr7+/v9/f3+/v7////wG4B6AAAAI3RSTlMAAQYHCQ42NzhPUFFrbG19gIGZmrW2vb7Cw9rb3N3e4fDx8gBaxB4AAAEZSURBVHjajdRVwqNAEATgjru7S8Xdk7r/wXZ/m6GJAN8LLtMmSrxY6w0x7NWKcXkjmm3D0clF5UlqALdBWrRIGa/KEbFiDbzTiMmfcB3v1cPyq4xPyvIjjc/S8iU6wGeD70Vn4SUnIjZQs/PsZQ9tkQSMC29TfJveeIGRkAKM+Z3XKYDplfc5jIJUYS0evE4wufKxgFWTPhzLBy/TCx9LOPoygrJ68M7HCspY4LImuYaL+x04kTzB/Y4+lCN5Io9Q+notOJBbbMkDHDUVD+zJHYAduYdVUDGdk3tz51zFVNowNubthw2MdqDc+teHX419q+CTiqn1Jt5rRQL3y+ee09IvfZt56f18F45uPiqamh8jjHq1UjIk1n8g/3nv1Nr/qQAAAABJRU5ErkJggg==);
`;

const CancelButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
`;

const CancelButton = styled(Button)`
  padding: 5px;
  min-width: 0;
  color: ${props => props.theme.color.closer2};
  border: 1px solid ${props => props.theme.color.closer2};

  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.closer2};
  }
`;

const PlanTitle = styled.div`
  font-weight: bold;
  background-color: ${props => props.theme.color.closest};
  margin: 0 10px;
  padding: 10px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
`;

const CurrentPlanTitle = styled.div`
  background-color: ${props => props.theme.color.primary};
  margin: 0 10px;
  padding: 10px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  height: 70px;
`;

const TdYes = () => (
  <Td>
    <TickIcon />
  </Td>
);

const TdNo = () => (
  <Td>
    <CrossIcon />
  </Td>
);

const ModalContent = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  padding: 20px;
  display: grid;
  gap: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
`;

const AreYouSureText = styled.div`
  font-weight: bold;
  grid-column: span 2;
`;

const ConfirmChangePlanModal = ({ isOpen, onClose, onClickYes, isDisabled, newPlan }) => {
  let planPrice = "£50";
  if (newPlan === "TEAM") {
    planPrice = "£150";
  }
  if (newPlan === "ENTERPRISE") {
    planPrice = "£500";
  }

  return (
    <Modal
      title={`Upgrade to ${newPlan?.[0] + newPlan?.toLowerCase()?.slice(1)} plan`}
      open={isOpen}
      handleClose={onClose}
    >
      <ModalContent isDisabled={isDisabled}>
        <AreYouSureText>Your plan price will be {planPrice} per month</AreYouSureText>
        <Button value="Yes, continue" onClick={onClickYes} />
        <Button value="No, cancel" onClick={onClose} />
      </ModalContent>
    </Modal>
  );
};

const AccountPage = () => {
  const [activeAccountSubscription, setActiveAccountSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [confirmModalPlan, setConfirmModalPlan] = useState(null);

  useEffect(() => {
    doPopulateUserPlan();
  }, []);

  const doPopulateUserPlan = async () => {
    const { data, error } = await getLoggedInUserActiveAccountSubscription();
    setError(error);
    setActiveAccountSubscription(data);
    setIsLoading(false);
  };

  const doCancelPlan = async () => {
    setIsLoading(true);
    const { error } = await cancelCurrentPlanOfLoggedInUser();
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    setActiveAccountSubscription(null);
    setIsLoading(false);
  };

  const onSubscribeClick = async newPlan => {
    if (!activeAccountSubscription || activeAccountSubscription.status === "AWAITING_PAYMENT") {
      setIsLoading(true);
      const { data, error } = await getPaymentUrlForPlan(newPlan);
      if (error) {
        setError(error);
        setIsLoading(false);
        return;
      }
      window.location = data.paymentUrl;
      setIsLoading(false);
      return;
    }
    setConfirmModalPlan(newPlan);
    setIsConfirmModalOpen(true);
  };

  const userPlan = activeAccountSubscription?.plan;
  const subscriptionStatus = activeAccountSubscription?.status;

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      {(isLoading || !userPlan || subscriptionStatus === "AWAITING_PAYMENT") && <Gap height="70px" />}
      <table>
        <thead>
          <Tr>
            <th></th>
            <Th>
              {userPlan === "PERSONAL" && subscriptionStatus !== "AWAITING_PAYMENT" && (
                <CurrentPlanTitle>Current Plan</CurrentPlanTitle>
              )}
              <PlanTitle>Community/Individual</PlanTitle>
            </Th>
            <Th>
              {userPlan === "TEAM" && subscriptionStatus !== "AWAITING_PAYMENT" && (
                <CurrentPlanTitle>Current Plan</CurrentPlanTitle>
              )}
              <PlanTitle>Team</PlanTitle>
            </Th>
            <Th>
              {userPlan === "ENTERPRISE" && subscriptionStatus !== "AWAITING_PAYMENT" && (
                <CurrentPlanTitle>Current Plan</CurrentPlanTitle>
              )}
              <PlanTitle>Enterprise</PlanTitle>
            </Th>
          </Tr>
        </thead>
        <tbody>
          <TrPanel1 isEven={true}>
            <TdService>Auto-Training</TdService>
            <TdYes />
            <TdYes />
            <TdYes />
          </TrPanel1>
          <TrPanel1>
            <TdService>Model Version Control</TdService>
            <TdYes />
            <TdYes />
            <TdYes />
          </TrPanel1>
          <TrPanel1 isEven={true}>
            <TdService>Auto Offline Evaluation</TdService>
            <TdYes />
            <TdYes />
            <TdYes />
          </TrPanel1>
          <TrPanel1>
            <TdService>CodeLab Suport</TdService>
            <TdYes />
            <TdYes />
            <TdYes />
          </TrPanel1>
          <TrPanel1 isEven={true}>
            <TdService>Generative Pre-Trained Model as API</TdService>
            <TdYes />
            <TdYes />
            <TdYes />
          </TrPanel1>

          <TrPanel1>
            <TdService>NN Architecture Search</TdService>
            <TdNo />
            <TdYes />
            <TdYes />
          </TrPanel1>

          <TrPanel1 isEven={true}>
            <TdService>Autotuning</TdService>
            <TdNo />
            <TdYes />
            <TdYes />
          </TrPanel1>

          <TrPanel1>
            <TdService>Data Science Support</TdService>
            <TdNo />
            <TdYes />
            <TdYes />
          </TrPanel1>

          <TrPanel1 isEven={true}>
            <TdService>On-Premise/Edge Deployment</TdService>
            <TdNo />
            <TdNo />
            <TdYes />
          </TrPanel1>

          <TrPanel1>
            <TdService>Customised Model Design</TdService>
            <TdNo />
            <TdNo />
            <TdYes />
          </TrPanel1>

          <TrPanel1 isEven={true}>
            <TdService>Technical Account Management</TdService>
            <TdNo />
            <TdNo />
            <TdYes />
          </TrPanel1>

          <TrPanel isEven={true}>
            <TdService>Maximum Users</TdService>
            <Td>1</Td>
            <Td>10</Td>
            <Td>Unlimited</Td>
          </TrPanel>

          <TrPanel>
            <TdService>Free Units (monthly) </TdService>
            <Td>1 (GPU) hours </Td>
            <Td>100 (GPU) hours </Td>
            <Td>Contact Us</Td>
          </TrPanel>

          <TrPanel isEven={true}>
            <TdService>CodeLab Credit (free monthly) </TdService>
            <Td>10 (GPU) hours </Td>
            <Td>168 (GPU) hours </Td>
            <Td>Contact Us</Td>
          </TrPanel>

          <TrPanel>
            <TdService>Prviate Model Repo </TdService>
            <Td>5 </Td>
            <Td>Unlimited</Td>
            <Td>Unlimited</Td>
          </TrPanel>

          <TrPanel isEven={true}>
            <td></td>
            <ButtonTd>
              <UpgradeButton
                onClick={() => {
                  onSubscribeClick("PERSONAL");
                }}
                isDisabled={(userPlan === "PERSONAL" && subscriptionStatus !== "AWAITING_PAYMENT") || isLoading}
                value="Subscribe"
              />
            </ButtonTd>
            <ButtonTd>
              <UpgradeButton
                onClick={() => {
                  onSubscribeClick("TEAM");
                }}
                isDisabled={(userPlan === "TEAM" && subscriptionStatus !== "AWAITING_PAYMENT") || isLoading}
                value="Subscribe"
              />
            </ButtonTd>
            <ButtonTd>
              <UpgradeButton
                onClick={() => {
                  onSubscribeClick("ENTERPRISE");
                }}
                isDisabled={(userPlan === "ENTERPRISE" && subscriptionStatus !== "AWAITING_PAYMENT") || isLoading}
                value="Subscribe"
              />
            </ButtonTd>
          </TrPanel>
        </tbody>
      </table>
      <CancelButtonContainer>
        <CancelButton isDisabled={isLoading || !userPlan} value="Cancel" onClick={doCancelPlan} />
      </CancelButtonContainer>
      <ConfirmChangePlanModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        newPlan={confirmModalPlan}
        onClickYes={async () => {
          const { data, error } = await updateSubscriptionPlan(activeAccountSubscription.id, confirmModalPlan);
          setActiveAccountSubscription(data);
          setError(error);
          setIsConfirmModalOpen(false);
          setIsLoading(false);
        }}
      />
    </CenteredWithTopNavLayout>
  );
};

export default AccountPage;
