import { useState } from "react";

export const usePagination = (numItems, initialPageSize = 10) => {
  const [startInd, setStartInd] = useState(0);
  const [endInd, setEndInd] = useState(initialPageSize);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const onSetPageNum = pageNum => {
    setStartInd((pageNum - 1) * pageSize);
    setEndInd(pageNum * pageSize);
  };

  const onSetPageSize = newPageSize => {
    setStartInd(0);
    setEndInd(newPageSize);
    setPageSize(newPageSize);
  };

  const numPages = Math.ceil(numItems / pageSize);

  return [startInd, endInd, numPages, pageSize, onSetPageSize, onSetPageNum];
};

export default usePagination;
