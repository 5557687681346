import { CenteredWithTopNavLayout } from "components/Layout";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const tutorialData = {
  "create-a-dataset-from-a-local-file": [
    "Click on [Data] at the side menu.",
    "Give your dataset a name and click [upload a file].",
    "Select your csv file from your computer.",
    "Once your file has been selected, click [Create dataset].",
    "Your file will be processed and create a dataset.",
    "Once your dataset has been created successfully, click [preview dataset].",
    "Your dataset will be available to preview on a popup window",
  ],
  "create-a-cube": [
    "Click on [Cubes] at the side menu.",
    "Click on [New] to create your cube.",
    "Give your cube a name and click on [next] to continue.",
    "Click [Select] to use the dataset you have created. ",
    "Your datasets will now load. Select and click on your dataset.",
    "Click [Select] to load recommended model.",
    "Click on one recommended model AND then [Start].",
    "Your new cube is available to view.",
  ],

  "create-a-dataset-from-google-drive": [
    "Click on [Data] at the side menu.",
    "Give your dataset a name and click [Data storage] AND GoogleDrive icon.",
    "Select your csv file from your GoogleDrive and click on “+” symbol button.",
    "Once your file has been selected, click [Create dataset].",
    "Your file will be processed and create a dataset.",
    "Once your dataset has been created successfully, click [Preview dataset].",
    "Your dataset will be available to preview on a popup window",
  ],

  "deploy-tabular-data-api-as-predictive-solutions": [
    "On the home screen, click on [Cubes] at the side menu.",
    "Click on the cube you created.",
    "When your cube loads, click on [Deployment] tab. Please note it may take a moment for the data to load.",
    "Select the columns you would like to predict. The selected columns will be highlighted in blue.",
    "Click  [Add a New Api].",
    "Name your new API and click [Add].",
    "Your api will appear. Click on [Add to solution].",
    "Your api has been added as a solution. Click on [View] icon to see the API.",
    "Click on [Solutions] on the left menu bar.",
    "Click on the solution you just built.",
    "Change your field's value and click [Predict].",
    "The predicted data will appear.",
    "You can rename your api solution by clicking the edit pencil icon.",
    "Click on [embed] icon to copy or export your code. ",
    "Click on [download web page] to export file as code or click on copy icon to copy and paste code your app or platform. Code is available in html, js and css formats.",
  ],
  "build-image-annotation-tasks": [
    "On the home dashboard click on [Image Annot. Tasks].",
    "Click” on add new.",
    "Name your task and click on [Upload a file].",
    "Find and select your file from your local drive. Upload your image file in a tar.file format and click [open].",
    "Your images will load. Select an image.",
    "Click on an image object, it will highlight - draw a square. You can label your object with names. You can also click on “show suggestions” as default recognised labels.",
    "When you have finished naming your objects, click on [Export annotated data] to export your annotation results.",
  ],
  "use-smart-ai-bar": [
    "On the home screen type into the search bar “create a cube from dataset <your dataset name>”.",
    "Your cube will be automatically created.",
  ],
};

const getTutorialNameFromId = id => {
  return id.split("-").join(" ").toUpperCase();
};

const Header = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: white;
  background-size: cover;
  background-color: #000000b3;
  background-image: url(${props => `/images/tutorial/${props.tutorialId}/cover.jpg`});
`;

const Step = styled.div`
  img {
    width: 80%;
  }
`;

const StepNarraitive = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
`;

const TutorialPage = () => {
  const { tutorialId } = useParams();

  return (
    <CenteredWithTopNavLayout>
      <Header tutorialId={tutorialId}>
        <h1>{getTutorialNameFromId(tutorialId)}</h1>
      </Header>
      {tutorialData?.[tutorialId].map((narrative, id) => (
        <Step>
          <StepNarraitive>{`${id + 1}. ${narrative}`}</StepNarraitive>
          <img src={`/images/tutorial/${tutorialId}/${id}.png`} />
        </Step>
      ))}
    </CenteredWithTopNavLayout>
  );
};

export default TutorialPage;
