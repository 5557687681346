import { AreaChart as ReAreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const AreaChart = ({
  data,
  xAxisKey,
  yAxisKey,
  chartSize,
  color = "#8884d8",
  legend = false
}) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";
  return (
    <div style={{width: "100%", height: chartSize.height }}>
      <ResponsiveContainer width="100%" height="100%">
        <ReAreaChart
          width={chartSize.width}
          height={chartSize.height}
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey={xAxisKey} 
            label={{ value: xAxisKey, position: "insideBottom", offset: -5, fill: isDarkTheme? "#ffffff": "" }} 
          />
          <YAxis label={{ value: yAxisKey, position: "insideLeft", angle: -90, fill: isDarkTheme? "#ffffff": "" }} />
          <Tooltip labelStyle={{ color: "black" }} />
          {
            legend
              ?
              <Legend />
              :
              <></>
          }
          <Area type="monotone" dataKey={yAxisKey} stroke={color} fill={color} />
        </ReAreaChart>
      </ResponsiveContainer>
    </div>  
  );
};


export default AreaChart;