import { CenteredWithTopNavLayout } from "components/Layout";
import GmailIntegration from "components/widgets/GmailIntegration";
import LinkedinIntegration from "components/widgets/LinkedinIntegration";
import GoogleDriveIntegration from "components/widgets/GoogleDriveIntegration";
import HubspotIntegration from "components/widgets/HubspotIntegration";
import SalesforceIntegration from "components/widgets/SalesforceIntegration";
import GoogleCalendarIntegration from "components/widgets/GoogleCalendarIntegration";

const OauthCallBackTest = () => {
  return (
    <CenteredWithTopNavLayout>
      <GmailIntegration platformRedirectUrl={"/oauth-callback-test"} />
      <LinkedinIntegration platformRedirectUrl={"/oauth-callback-test"} />
      <GoogleDriveIntegration platformRedirectUrl={"/oauth-callback-test"} />
      <HubspotIntegration platformRedirectUrl={"/oauth-callback-test"} />
      <SalesforceIntegration platformRedirectUrl={"/oauth-callback-test"} />
      <GoogleCalendarIntegration platformRedirectUrl={"/oauth-callback-test"} />
    </CenteredWithTopNavLayout>
  );
};

export default OauthCallBackTest;
