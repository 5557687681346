import styled from "styled-components";

const CloseButtonContainer = styled.div`
  position: absolute;
  top: -20px;
  left: 60px;
  cursor: pointer;
  width: max-content;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  transition: opacity 0.2s;
  opacity: 0;
`;

const LabelInputContainer = styled.div`
  position: absolute;
  top: ${props => props.pixelY}px;
  left: ${props => props.pixelX}px;
  width: ${props => props.pixelW}px;
  height: ${props => props.pixelH}px;
  :hover {
    ${CloseButtonContainer} {
      opacity: 1;
    }
  }
`;

const MaskRectangle = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => `${props.backgroundColor}BB` || props.theme.color.primary};
  border: 1px solid red;
`;

export const BoundingBox = ({ pixelX, pixelY, pixelW, pixelH, backgroundColor = "#000000" }) => {
  return (
    <LabelInputContainer pixelX={pixelX} pixelY={pixelY} pixelW={pixelW} pixelH={pixelH}>
      <MaskRectangle backgroundColor={backgroundColor} />
    </LabelInputContainer>
  );
};
