import * as backend from "api/backend/githubServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { saveModel } from "api/backend/modelServiceEndpoints";

export const getCubeByRepoSlug = async repositoryOwnerPlusName => {
  const { data, error } = await backend.getCubeByRepoSlug(repositoryOwnerPlusName);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getIsCubeAGithubCube = async cubeId => {
  // GET /github-service/is-cube-a-github-cube
  return { data: false, error: null };
};

export const getRepoSlugOfCubeIfExists = async cubeId => {
  const { data, error } = await backend.getGithubCubes();
  return {
    data: data?.find(result => result.cubeId === cubeId)?.repositoryOwnerPlusName,
    error: getHandlingFromError(error),
  };
};

export const getPipelineIdToCommitShaMap = async cubeId => {
  const { data, error } = await backend.getGithubCubes();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const ghResult = data.find(result => result.cubeId === cubeId);
  if (!ghResult) {
    return { data: null, error: null };
  }

  const repoSlug = ghResult.repositoryOwnerPlusName;
  const { data: commits, error: errCommits } = await backend.getCommitsForRepoSlugBySha(repoSlug, "main");
  if (errCommits) {
    return { data: null, error: getHandlingFromError(errCommits) };
  }

  const pipelineResultsOfCommits = await Promise.all(
    commits.map(commit => backend.getCubePipelineByCommit(commit.sha))
  );

  const pipelineIdToCommitShaMap = {};
  pipelineResultsOfCommits
    .filter(result => result.data)
    .forEach(result => {
      pipelineIdToCommitShaMap[result.data.pipelineId] = result.data.commitId;
    });

  return { data: pipelineIdToCommitShaMap, error: null };
};

export const getGithubCubesByUserName = async userName => {
  const { data, error } = await backend.getGithubCubes({ userName });
  return { data, error: getHandlingFromError(error) };
};

export const integrateRepoForCube = async repositoryOwnerPlusName => {
  const { data, error } = await backend.createCubeFromRepo(repositoryOwnerPlusName);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const ingegrateRepoWithDatasetForCube = async (datasetId, repositoryOwnerPlusName) => {
  const { data: pipelineConfig, error } = await backend.createCubeIntegrationFromDataset(
    datasetId,
    repositoryOwnerPlusName
  );

  return { data: pipelineConfig, error };
};

export const getCubePipelineByCommit = async commitId => {
  const { data, error } = await backend.getCubePipelineByCommit(commitId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getCubePipelineIdsByCommitShas = async commitIds => {
  const pipelineResults = await Promise.all(commitIds.map(commitId => backend.getCubePipelineByCommit(commitId)));

  if (pipelineResults.find(result => result.error)) {
    return pipelineResults.find(result => result.error);
  }
  return { data: pipelineResults.map(result => result.data), error: null };
};

export const createPipelineDraftWithNewModelAndProcessConfigAndTrainingConfig = async (
  cubeId,
  datasetId,
  dataProcessingConfig,
  trainingConfig,
  modelConfig,
  trainingSearchValues
) => {
  const { data: savedModel, error } = await saveModel({ config: modelConfig });
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const pipelineConfig = {
    cubeId,
    datasetId,
    dataProcessingConfig,
    modelId: savedModel.id,
    status: "DRAFT",
    trainingConfig,
  };
  if (trainingSearchValues) {
    pipelineConfig.trainingSearchValues = trainingSearchValues;
  }
  const { data: savedPipelineDraft, error: savePipelineDraftError } = await backend.postPipelineConfig(pipelineConfig);
  return {
    data: savedPipelineDraft,
    error: getHandlingFromError(savePipelineDraftError),
  };
};

export const updatePipelineDraftWithNewModelAndProcessConfigAndTrainingConfig = async (
  pipelineId,
  cubeId,
  datasetId,
  dataProcessingConfig,
  trainingConfig,
  modelConfig,
  trainingSearchValues
) => {
  const { data: savedModel, error } = await saveModel({ config: modelConfig });
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const pipelineConfig = {
    cubeId,
    datasetId,
    dataProcessingConfig,
    modelId: savedModel.id,
    status: "DRAFT",
    trainingConfig,
  };
  if (trainingSearchValues) {
    pipelineConfig.trainingSearchValues = trainingSearchValues;
  }
  const { data: updatedPipelineDraft, error: savePipelineDraftError } = await backend.patchPipelineConfig(
    pipelineId,
    pipelineConfig
  );
  return {
    data: updatedPipelineDraft,
    error: getHandlingFromError(savePipelineDraftError),
  };
};

export const getProjectByRepoSlug = async repoSlug => {
  const { data, error } = await backend.getProjectByRepoSlug(repoSlug);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  return { data, error };
};

export const getLoggedInUserRepositories = async () => {
  const { data, error } = await backend.getLoggedInUserRepositories();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const integrateRepo = async repoSlug => {
  const { data, error } = await backend.createProjectFromRepo(repoSlug);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getConnectedGithubAccountForLoggedInUser = async () => {
  const { data, error } = await backend.getConnectedGithubAccount();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const ingegrateRepoWithDataset = async (datasetId, repoSlug) => {
  const { data, error } = backend.createProjectIntegrationFromDataset(datasetId, repoSlug);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const connectGithubAccount = async (githubAccount, githubToken) => {
  const { data, error } = await backend.connectGithubAccount(githubAccount, githubToken);
  return { data, error: getHandlingFromError(error) };
};

export const patchGithubAccount = async (githubAccount, githubToken) => {
  const { data, error } = await backend.patchGithubAccount({ githubAccount, githubToken });
  return { data, error: getHandlingFromError(error) };
};

export const getBranchesForRepoSlug = async repoSlug => {
  const { data, error } = await backend.getBranchesForRepoSlug(repoSlug);
  return { data, error: getHandlingFromError(error) };
};

export const getFileListByRepoSlugAndBranchName = async (repoSlug, branchName) => {
  const { data, error } = await backend.getFileListByRepoSlugAndBranchName(repoSlug, branchName);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getFileContentByRepoSlugBranchNameAndFileName = async (repoSlug, branchName, filePath) => {
  const { data, error } = await backend.getFileContentByRepoSlugBranchNameAndFileName(repoSlug, branchName, filePath);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getCommitsForRepoSlugBySha = async (repoSlug, shaOrBranch) => {
  const { data, error } = await backend.getCommitsForRepoSlugBySha(repoSlug, shaOrBranch);
  return { data, error: getHandlingFromError(error) };
};

export const getCommitsForRepoSlugByShaWithPipelines = async (repoSlug, shaOrBranch) => {
  const { data: commits, error } = await backend.getCommitsForRepoSlugBySha(repoSlug, shaOrBranch);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const pipelineResultsOfCommits = await Promise.all(
    commits.map(commit => {
      return backend.getCubePipelineByCommit(commit.sha);
    })
  );

  const commitsWithPipeline = pipelineResultsOfCommits
    .filter(result => result.data)
    .map(pipelineResult => {
      return commits.find(commit => pipelineResult.data.commitId === commit.sha);
    });

  return { data: commitsWithPipeline, error: null };
};

export const checkRepoHasMainBranch = async repoSlugList => {
  const branchesOfRepoSlugsResponse = await Promise.all(
    repoSlugList.map(repoSlug => backend.getBranchesForRepoSlug(repoSlug))
  );

  const hasMainBranch = {};

  for (var i = 0; i < branchesOfRepoSlugsResponse.length; i++) {
    const branches = branchesOfRepoSlugsResponse[i]?.data.map(e => e.name);
    hasMainBranch[repoSlugList[i]] = branches.includes("main");
  }

  return { data: hasMainBranch };
};
