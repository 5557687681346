import { useState, useEffect } from "react";
import styled from "styled-components";
import { GitHub } from "@material-ui/icons";

import { getRepoSlugOfCubeIfExists } from "api/services/githubService";

const GithubAnchor = styled.a`
  width: max-content;
`;

const GithubLinkForCube = ({ cubeId }) => {
  const [repoSlug, setRepoSlug] = useState("");

  useEffect(() => {
    doFetchRepoSlug();
  }, []);

  const doFetchRepoSlug = async () => {
    const { data: repoSlug } = await getRepoSlugOfCubeIfExists(cubeId);
    setRepoSlug(repoSlug);
  };

  if (!repoSlug) {
    return <div />;
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <GithubAnchor href={`https://github.com/${repoSlug}`} target="_blank">
      <GitHub />
    </GithubAnchor>
  );
};

export default GithubLinkForCube;
