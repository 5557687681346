import styled from "styled-components";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import NavWithTabs from "components/ui/NavWithTabs";
import CubesOfUser from "components/views/UserProfileTabsView/CubesOfUser";
import OverviewOfUser from "components/views/UserProfileTabsView/OverviewOfUser";
import DatasetsOfUser from "components/views/UserProfileTabsView/DatasetsOfUser";
import ActivitiesOfUser from "components/views/UserProfileTabsView/ActivitiesOfUser";
import { useEffect } from "react";
import { Gap } from "components/Layout";

const NavWithTabsContainer = styled.div``;

const UserProfileTabsView = ({ userName }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("Overview");

  const selectTab = tabName => {
    setSearchParams({ tab: tabName });
    setSelectedTab(tabName);
  };

  useEffect(() => {
    setSelectedTab(searchParams.get("tab") || "Overview");
  }, [searchParams.get("tab")]);

  return (
    <NavWithTabsContainer>
      <NavWithTabs
        tabNames={["Overview", "Cubes", "Datasets", "Activities"]}
        selectedTabName={selectedTab}
        onTabSelect={newTab => selectTab(newTab)}
      />
      <Gap height="40px" />
      {selectedTab === "Overview" && <OverviewOfUser userName={userName} />}
      {selectedTab === "Cubes" && <CubesOfUser userName={userName} />}
      {selectedTab === "Datasets" && <DatasetsOfUser userName={userName} />}
      {selectedTab === "Activities" && <ActivitiesOfUser userName={userName} initialNumItems={20} />}
    </NavWithTabsContainer>
  );
};

export default UserProfileTabsView;
