import styled from "styled-components";
import {
  getSurveyResponses,
  getSurvey,
  patchSurvey,
  triggerDownloadOfSurveyResponseData,
} from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import SurveyEditView from "components/views/SurveyEditView";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { BigTitle, SmallTitle } from "components/ui/Text";
import Button from "components/ui/Button";
import SurveySubmitResponseView from "components/views/SurveySubmitResponseView";

const TitleAndStatus = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

const StatusText = styled.div`
  color: ${props => props.theme.color.status_done};
  font-weight: bold;
`;

const Description = styled.div``;

const SurveyPage = () => {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [error, setError] = useState(null);
  const [numberOfResponses, setNumberOfResponses] = useState(0);

  const [isPreviewingSurvey, setIsPreviewingSurvey] = useState(false);
  const [previewResponseData, setPreviewResponseData] = useState({});

  useEffect(() => {
    doPopulateSurvey();
    doPopulateNumberOfResponses();
  }, [surveyId]);

  const doPopulateSurvey = async () => {
    const { data, error } = await getSurvey(surveyId);
    setError(error);
    setSurvey(data);
    setIsLoading(false);
  };

  const doPopulateNumberOfResponses = async () => {
    const { data: responses } = await getSurveyResponses(surveyId);
    setNumberOfResponses(responses?.length || 0);
  };

  if (isLoading && !survey) {
    return <CenteredWithTopNavLayout>Loding survey...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  if (survey?.status === "DRAFT") {
    return (
      <CenteredWithTopNavLayout centerColumnMaxWidth="600px">
        {isPreviewingSurvey ? (
          <SurveySubmitResponseView
            survey={survey}
            responseData={previewResponseData}
            onSetResponseData={newData => setPreviewResponseData(newData)}
            onClickEdit={() => setIsPreviewingSurvey(false)}
          />
        ) : (
          <SurveyEditView
            isDisabled={isLoading}
            survey={survey}
            onSurveyEdit={newSurvey => setSurvey(newSurvey)}
            onClickPreview={() => setIsPreviewingSurvey(true)}
            onClickSave={async () => {
              setIsLoading(true);
              const { error } = await patchSurvey(surveyId, survey);
              if (error) alert(JSON.stringify(error));
              setIsLoading(false);
            }}
            onClickPublish={async () => {
              setIsLoading(true);
              const { data: updatedSurvey, error } = await patchSurvey(surveyId, { status: "FINALISED" });
              if (error) {
                alert(JSON.stringify(error));
              } else {
                setSurvey(updatedSurvey);
              }
              setIsLoading(false);
            }}
          />
        )}
      </CenteredWithTopNavLayout>
    );
  }

  const sumbitResponseLink = `/survey/${surveyId}/submit-response`;

  return (
    <CenteredWithTopNavLayout centerColumnMaxWidth="600px">
      <TitleAndStatus>
        <BigTitle>{survey?.data?.title}</BigTitle>
        <StatusText>{survey?.status}</StatusText>
      </TitleAndStatus>
      <Gap height="10px" />
      <Description>{survey?.data?.description}</Description>
      <Gap />
      <SmallTitle>Link:</SmallTitle>
      <Link to={sumbitResponseLink}>
        {window.location.origin}
        {sumbitResponseLink}
      </Link>
      <Gap />
      {numberOfResponses} responses
      <Gap />
      <Button value="Download csv responses" onClick={() => triggerDownloadOfSurveyResponseData(surveyId)} />
    </CenteredWithTopNavLayout>
  );
};

export default SurveyPage;
