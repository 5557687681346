import styled from "styled-components";
import { useState, useEffect } from "react";
import showdown from "showdown";

import { getFileContentByRepoSlugBranchNameAndFileName } from "api/services/githubService";
import { SmallTitle } from "components/ui/Text";

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 50px;
  background-color: ${props => props.theme.color.closer0};
`;

const FileListTitle = styled(SmallTitle)`
  cursor: pointer;
`;

const ReadmeHtml = styled.div`
  padding: 20px;
  border-radius: 10px;
`;

const TopButtonPanel = styled.div`
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const CircleButton = styled.div`
  padding: 2px 0px 0px 3px;
  cursor: pointer;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.furthest};
  border-radius: 50%;
  border: 2px solid ${props => props.theme.color.closest};
  height: 25px;
  width: 25px;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  svg {
    font-size: 15px;
  }

  :hover {
    color: ${props => props.theme.color.primary};
    border: 2px solid ${props => props.theme.color.primary};
  }
`;

const MarkDownEditor = styled.textarea`
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  border: none;
  color: ${props => props.theme.color.closest};
  padding: 10px 10px 10px 35px;
`;

const ReadmeContainer = styled.div`
  background-color: transparent;
  height: ${props => (props.isExpanded ? "auto" : 0)};
  transition: height 0.2s;
  overflow: hidden;
`;

const ReadmeViewer = ({ repoSlug, branchName, showExpandToggle = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [readmeHtml, setReadmeHtml] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const doFetchReadmeContent = async () => {
    if (!branchName) {
      return;
    }

    const { data, error } = await getFileContentByRepoSlugBranchNameAndFileName(repoSlug, branchName, "README.md");

    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }

    const converter = new showdown.Converter();
    const htmlString = converter.makeHtml(data);
    setReadmeHtml(htmlString);
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchReadmeContent();
  }, [branchName]);

  return (
    <Container>
      {showExpandToggle && (
        <FileListTitle onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "-" : "+"} README</FileListTitle>
      )}

      <ReadmeContainer isExpanded={isExpanded} maxHeight={300}>
        {!isLoading && readmeHtml && <ReadmeHtml dangerouslySetInnerHTML={{ __html: readmeHtml }} />}
        {isLoading && "Loading..."}
        {error && JSON.stringify(error)}
      </ReadmeContainer>
    </Container>
  );
};

export default ReadmeViewer;
