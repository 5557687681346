import { clamp } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";

const OPTIONS = [{ value: "COLORS", isCategory: true }, { value: "Red" }, { value: "Green" }, { value: "Blue" }];

const CONTROL_KEYS = ["ArrowUp", "ArrowDown", "Enter"];

const Container = styled.div`
  background-color: ${props => props.theme.color.furthest};
  box-shadow: 0 4px 12px ${props => (props.theme.name === "light" ? "rgba(140, 149, 159, 0.2)" : "rgba(0, 0, 0, 0.4)")};
  border: 1px solid ${props => props.theme.color.closer1};

  border-image: linear-gradient(to right, ${props => props.theme.color.feature}, ${props => props.theme.color.primary})
    1;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  padding-bottom: 8px;
`;

const Option = styled.div`
  padding: 2px;
  padding-left: 16px;
  min-width: 100px;
  ${props => props.isHighlighted && `background-color: ${props.theme.color.closer1};`}

  ${props =>
    props.isCategory &&
    `
    padding: 4px;
    font-weight: 600;
    color: ${props.theme.color.closer2};
  `};
`;

const Dropdown = ({ options = OPTIONS, onOptionSelect = () => {} }) => {
  const [highlightedOptionIndex, setHighlightedOptionIndex] = useState(-1);

  const onKeyDown = e => {
    if (!CONTROL_KEYS.includes(e.key)) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    if (e.key === "Enter") {
      if (highlightedOptionIndex === -1) {
        return;
      }
      onOptionSelect(options[highlightedOptionIndex]);
      return;
    }

    let newIndex = highlightedOptionIndex;
    if (e.key === "ArrowDown") {
      newIndex += 1;
      if (options?.[newIndex]?.isCategory) {
        newIndex += 1;
      }
    }
    if (e.key === "ArrowUp") {
      newIndex -= 1;
      if (options?.[newIndex]?.isCategory) {
        newIndex -= 1;
      }
    }
    const clampedIndex = clamp(newIndex, -1, options.length - 1);
    setHighlightedOptionIndex(clampedIndex);
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, [highlightedOptionIndex]);

  return (
    <Container>
      {options.map((option, i) => (
        <Option isCategory={option?.isCategory} key={i} isHighlighted={highlightedOptionIndex === i}>
          {option.value}
        </Option>
      ))}
    </Container>
  );
};

export default Dropdown;
