import { useState, useEffect } from "react";
import LinkedinButton from "components/ui/LinkedinButton";
import { getLinkedinOauthToken, linkedinLogin, getLinkedinProfile } from "api/services/authenticationService";
import ResourceModal from "components/widgets/LinkedinIntegration/ResourceModal";

const LinkedinIntegration = ({ platformRedirectUrl }) => {
  const [linkedinToken, setLinkedinToken] = useState(null);
  const [resourceTypeModalVisible, setResourceTypeModalVisible] = useState(false);
  const [resourceType, setResourceType] = useState("profile");
  const [profile, setProfile] = useState(null);

  const doLinkedinToken = async () => {
    const { data, error } = await getLinkedinOauthToken();
    if (error) {
      return;
    }
    setLinkedinToken(data);
  };

  const doFetchProfile = async () => {
    const { data, error } = await getLinkedinProfile();
    if (error) {
      return;
    }
    setProfile(data);
  };

  const onImportResourceData = () => {
    if (resourceType === "profile") {
      doFetchProfile();
    }
  };

  useEffect(() => {
    doLinkedinToken();
  }, []);

  const handelBtnClick = async () => {
    if (linkedinToken) {
      setResourceTypeModalVisible(true);
    } else {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { data: redirectUrl } = await linkedinLogin(`${baseUrl}/oauth-callback/linkedin`);
      sessionStorage.setItem("preOauthUrl", platformRedirectUrl);
      window.location = redirectUrl;
    }
  };

  return (
    <>
      <ResourceModal
        open={resourceTypeModalVisible}
        handleClose={() => setResourceTypeModalVisible(false)}
        resourceType={resourceType}
        onResourceTypeSelect={resourceType => {
          setResourceType(resourceType);
        }}
        onConfirm={() => {
          onImportResourceData();
          setResourceTypeModalVisible(false);
        }}
      />
      <LinkedinButton value={linkedinToken ? "Get resources" : "Authorize integration"} onClick={handelBtnClick} />
      {profile && <div>{JSON.stringify(profile, null, 2)}</div>}
    </>
  );
};

export default LinkedinIntegration;
