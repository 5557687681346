import styled from "styled-components";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { AssignmentTurnedInOutlined } from "@material-ui/icons";
import { useState } from "react";
import { BigTitle } from "components/ui/Text";
import { getLoggedInUserName } from "api/services/authenticationService";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import DeploymentReviewView from "components/views/application/BookSearch/DeploymentReviewView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import SolutionStatsView from "components/views/application/BookSearch/SolutionStatsView";

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const Steps = ({ stepNames, onStepSelect, selectedStep }) => {
  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const BookSearchApp = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const userName = getLoggedInUserName();

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>{"Book Auto-Neural Search"}</BigTitle>
      <Gap />
      {userName && selectedStep === "Import data" && <ImportDataView />}
      {userName && selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && <DeploymentReviewView />}
      {userName && selectedStep === "Stats" && <SolutionStatsView solutionId="book_search" />}
    </CenteredWithTopNavLayout>
  );
};

export default BookSearchApp;
