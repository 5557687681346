import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { SmallTitle } from "components/ui/Text";
import { getGithubCubesByUserName } from "api/services/githubService";
import { getAllVisibleCubes } from "api/services/projectService";
import { getDatasetsCreatedByUserName } from "api/services/dataService";
import ActivitiesOfUser from "components/views/UserProfileTabsView/ActivitiesOfUser";
import { DatasetCard } from "components/ui/cards";
import { CubeCard } from "components/ui/CubeCard";
import GithubCubeCard from "components/ui/GithubCubeCard";
import Button from "components/ui/Button";
import { Gap } from "components/Layout";

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const OverviewOfUser = ({ userName }) => {
  const [cubes, setCubes] = useState([]);
  const [githubCubeIds, setGithubCubeIds] = useState([]);
  const [datasets, setDatasets] = useState([]);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const doFetchUserCubes = async () => {
    setIsLoading(true);
    const { data, error } = await getAllVisibleCubes({ createdBy: userName, pageSize: 4 });
    if (error) {
      setError(error);
      setIsLoading(false);
      return;
    }
    setCubes(data?.items);
    setIsLoading(false);
  };

  const doFetchUserGithubCubes = async () => {
    setIsLoading(true);
    const { data, error } = await getGithubCubesByUserName(userName);
    setIsLoading(false);
    if (error) {
      return;
    }
    setGithubCubeIds(data.map(e => e.cubeId));
  };

  const doFetchUserDatasets = async () => {
    setIsLoading(true);
    const { data, error: datasetsError } = await getDatasetsCreatedByUserName(userName);

    if (datasetsError) {
      setError(datasetsError);
    }
    setDatasets(data.filter(e => e.name !== "go"));
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchUserCubes();
    doFetchUserGithubCubes();
    doFetchUserDatasets();
  }, []);

  if (isLoading) {
    return (
      <>
        <Gap />
        <SmallTitle>Popular Cubes</SmallTitle>
        <CardsContainer>loading cubes...</CardsContainer>
        <Gap />
        <SmallTitle>Popular Datasets</SmallTitle>
        <CardsContainer>loading datasets...</CardsContainer>
        <SmallTitle>Activities</SmallTitle>
        <CardsContainer>loading datasets...</CardsContainer>
      </>
    );
  }

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <>
      <SmallTitle>Recent Cubes</SmallTitle>
      <Gap height="10px" />
      <CardsContainer>
        {cubes
          ?.sort((cubeA, cubeB) => (new Date(cubeA?.createdAt) > new Date(cubeB?.createdAt) ? -1 : 1))
          .slice(0, 4)
          .map(cube =>
            githubCubeIds.includes(cube.id) ? (
              <GithubCubeCard showAuthorBadge={false} key={cube.id} cube={cube} />
            ) : (
              <CubeCard showAuthorBadge={false} key={cube.id} cube={cube} />
            )
          )}
      </CardsContainer>
      <Gap height="40px" />
      <Button value="See all" onClick={() => navigate(`/${userName}?tab=Cubes`)} />
      <Gap height="80px" />
      <SmallTitle>Recent Datasets</SmallTitle>
      <Gap height="10px" />
      <CardsContainer>
        {datasets
          ?.sort((datasetA, datasetB) => (new Date(datasetA?.createdAt) > new Date(datasetB?.createdAt) ? -1 : 1))
          .slice(0, 4)
          .map(dataset => (
            <DatasetCard
              key={dataset.id}
              title={dataset.name}
              datasetId={dataset.id}
              subTitle={`Created at: ${dataset.createdAt}`}
            />
          ))}
      </CardsContainer>
      <Gap height="40px" />
      <Button value="See all" onClick={() => navigate(`/${userName}?tab=Datasets`)} />
      <Gap height="80px" />
      <SmallTitle>Activities</SmallTitle>
      <Gap height="10px" />
      <ActivitiesOfUser userName={userName} showSeeAllLink />
    </>
  );
};

export default OverviewOfUser;
