import { useState } from "react";
import styled from "styled-components";

import { generateTextFromLabel } from "api/services/deploymentService";
import Button from "components/ui/Button";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";

const GenerateButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const GeneratedTxt = styled.div`
  padding: 10px;
  width: 400px;
  font-size: 18px;
  height: 100px;
  border-radius: 10px;
  overflow-y: auto;
  background-color: ${props => props.theme.color.closer0};
`;

const GeneratedTxtLoading = styled.div`
  width: 400px;
  height: 100px;
  background-color: ${props => props.theme.color.closer0};
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const TextGeneration = ({
  deploymentClusterId,
  inputTxtLabel,
  isSelected,
  onSelect,
  trainingJobId,
  modelConfig,
  datasetFeatureTypeDescriptors,
}) => {
  const [generationResult, setGenerationResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doGenerateText = async () => {
    setIsFetchingPrediction(true);
    const { data, error } = await generateTextFromLabel(deploymentClusterId, inputTxtLabel);
    setGenerationResult(data.filter(e => !(e === "<START>" || e === "<END>")).join(" "));
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} />
      <TitleText>Text generation</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{ sentence_label: inputTxtLabel }}
        outputCols={["sentence_text"]}
        isEnabled={isSelected}
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        trainingJobId={trainingJobId}
        tag="generate_text"
      />
    </LabelAndModals>
  );

  if (!generationResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <GenerateButton onClick={doGenerateText} value="Generate" />
          <GeneratedTxtLoading />
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <GenerateButton onClick={doGenerateText} value="Generate" />
        <GeneratedTxt>{generationResult}</GeneratedTxt>
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default TextGeneration;
