import styled from "styled-components";
import { getSurvey, postSurveyResponse } from "api/services/projectService";
import { CenteredWithTopNavLayout } from "components/Layout";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SurveySubmitResponseView from "components/views/SurveySubmitResponseView";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 600px 1fr;
`;

const CenterColumn = styled.div`
  grid-column: 2;
`;

const SurveySubmitResponsePage = () => {
  const { surveyId } = useParams();
  const [survey, setSurvey] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
  const [error, setError] = useState(null);
  const [hasResponseBeenSubmitted, setHasResponseBeenSubmitted] = useState(false);

  const [responseData, setResponseData] = useState({});

  useEffect(() => {
    doPopulateSurvey();
  }, [surveyId]);

  const doPopulateSurvey = async () => {
    const { data, error } = await getSurvey(surveyId);
    setError(error);
    setSurvey(data);
    setIsLoading(false);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading survey...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <Container>
      <CenterColumn>
        <SurveySubmitResponseView
          survey={survey}
          responseData={responseData}
          onSetResponseData={newResData => setResponseData(newResData)}
          hasResponseBeenSubmitted={hasResponseBeenSubmitted}
          isSubmittingResponse={isSubmittingResponse}
          onClickSubmit={async () => {
            setIsSubmittingResponse(true);
            const { error } = await postSurveyResponse(surveyId, responseData);
            if (error) {
              alert(JSON.stringify(error));
            } else {
              setHasResponseBeenSubmitted(true);
            }
            setIsSubmittingResponse(false);
          }}
        />
      </CenterColumn>
    </Container>
  );
};

export default SurveySubmitResponsePage;
