import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { lightTheme, darkTheme } from "App";
import { round, isEmpty } from "lodash";

const Container = styled.div`
  width: 100%;
  height: 100%;
  .recharts-cartesian-grid {
    line {
      stroke: ${props => props.theme.color.closer1};
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const TrainingJobLineChart = ({ data = [] }) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";
  const textColor = isDarkTheme ? darkTheme.color.closest : lightTheme.color.closest;
  const [dataKeys, setDataKeys] = useState([]);

  useEffect(() => {
    !isEmpty(data) && setDataKeys(Object.keys(data[0]));
  }, [data]);

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="10 10" />
          <XAxis
            type="number"
            domain={[0, data?.[0]?.max_iter || 1000]}
            allowDataOverflow={true}
            dataKey="iter"
            label={{
              stroke: textColor,
              value: "Training iterations",
              position: "bottomCenter",
              dy: 25,
            }}
          />
          <YAxis
            label={{
              stroke: textColor,
              value: "Loss",
              position: "center",
              angle: -90,
              dx: -20,
            }}
          />
          <Tooltip formatter={value => round(value, 3)} />
          <Legend verticalAlign="top" />
          {dataKeys.includes("infer_loss") && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="infer_loss"
              name="Feature loss"
              stroke="#9650ff"
              activeDot={{ r: 8 }}
              strokeWidth={1}
              dot={false}
            />
          )}
          {dataKeys.includes("train_loss") && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="train_loss"
              name="Data loss"
              stroke="#009933"
              strokeWidth={1}
              activeDot={{ r: 8 }}
              dot={false}
            />
          )}
          {dataKeys.includes("loss") && (
            <Line
              isAnimationActive={false}
              type="monotone"
              dataKey="loss"
              name="Trainng loss"
              stroke="#0191ff"
              activeDot={{ r: 8 }}
              strokeWidth={1}
              dot={false}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default TrainingJobLineChart;
