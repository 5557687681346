import styled from "styled-components";
import {
  BarChart as ReBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Container = styled.div`
  .recharts-cartesian-grid {
    line {
      stroke: ${props => props.theme.color.closer1};
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const BarChart = ({ data, xAxisKey, yAxisKeyAndColors, chartSize }) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";
  return (
    <Container style={{ width: "100%", height: chartSize.height }}>
      <ResponsiveContainer width="100%" height="100%">
        <ReBarChart
          data={data}
          margin={{
            top: 10,
            right: 10,
            left: 0,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={xAxisKey}
            label={{ value: xAxisKey, position: "insideBottom", offset: -5, fill: isDarkTheme ? "#ffffff" : "" }}
          />
          {
            (yAxisKeyAndColors.length === 1) && 
            <YAxis
              label={{ value: yAxisKeyAndColors[0].key, position:  "insideLeft", angle: -90, fill: isDarkTheme ? "#ffffff" : ""}}
            />
          }
          <Tooltip 
            labelStyle={{ color: "black" }}
          />
          {yAxisKeyAndColors.map((elem, i) => {
            return <Bar key={i} dataKey={elem["key"]} fill={elem["color"]} />;
          })}
        </ReBarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default BarChart;
