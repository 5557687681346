import {
  getSentenceLabelingTaskById,
  getTaskSentenceLabelPairs,
  saveSentenceLabelPairs,
  triggerDownloadOfSentenceLabelPairs,
} from "api/services/projectService";
import { CircularProgress } from "@material-ui/core";
import { getLoggedInUserName } from "api/services/authenticationService";
import { ingestDataFromSentenceLabelingTask, getDataIngestionJob } from "api/services/dataService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import SelectInput from "components/ui/SelectInput";
import { BigTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Add } from "@material-ui/icons";
import Button from "components/ui/Button";
import { isEmpty, set } from "lodash";
import Modal from "components/ui/Modal";
import { isEqual } from "lodash";

const CircularProgressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  width: 400px;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TaskInfoContainer = styled.div`
  padding: 10px;
`;

const PairListContainer = styled.div`
  display: grid;
  gap: 10px;
`;

const PairRow = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr 200px;
`;

const IconContainer = styled.div`
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

const SuccessMsg = styled.div`
  color: ${props => props.theme.color.closest};
`;

const ErrorMsg = styled.div`
  color: ${props => props.theme.color.error};
`;

const LinkMsg = styled.div`
  color: ${props => props.theme.color.primary};
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

const ButtonRow = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

const ColumnHeader = styled.div`
  font-weight: bold;
`;

const BottomButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const SentenceLabelingTaskPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [originalPairs, setOriginalPairs] = useState([]);
  const [pairs, setPairs] = useState([]);
  const [taskInfo, setTaskInfo] = useState(null);
  const [labels, setLabels] = useState([]);
  const [dataIngestJobId, setDataIngestJobId] = useState(null);
  const [dataIngestJob, setDataIngestJob] = useState(null);
  const [isIngestJobDone, setIsIngestJobDone] = useState(false);
  const [ingestModalShow, setIngestModalShow] = useState(false);
  const [intervalId, setIntervalId] = useState(null);

  const { taskId } = useParams();
  const navigate = useNavigate();
  const userName = getLoggedInUserName();

  const doFetchTaskInfo = async () => {
    const { data, error } = await getSentenceLabelingTaskById(taskId);
    if (error) {
      return;
    }
    setTaskInfo(data);
    setLabels(data?.labels);
  };

  const doFetchPairs = async () => {
    const { data, error } = await getTaskSentenceLabelPairs(taskId);
    if (error) {
      return;
    }
    setOriginalPairs(data);
    setPairs([
      ...data,
      { sentence: "", label: labels?.[0], sentenceLabelingTaskId: taskId, id: userName + Date.now() },
    ]);
  };

  const doSavePairs = async () => {
    const filterPairs = pairs.filter(e => !isEmpty(e.sentence));
    const { data, error } = await saveSentenceLabelPairs(filterPairs);
    if (!error) {
      alert("Updated Successfully!");
      doFetchPairs();
    }
  };

  const doFetchIngestJob = async () => {
    const { data, error } = await getDataIngestionJob(dataIngestJobId);
    if (error) {
      return;
    }
    if (data.status === "DONE" || data.status === "DONE_FAILED" || data.status === "ABORT") {
      setIsIngestJobDone(true);
      clearInterval(intervalId);
    }
    setDataIngestJob(data);
  };

  const doIngestSentenceLabelingTaskAsDataset = async () => {
    const { data, error } = await ingestDataFromSentenceLabelingTask(taskId, taskInfo.name);
    if (error) {
      alert(error.message);
    } else {
      setDataIngestJobId(data.id);
      setIngestModalShow(true);
    }
  };

  useEffect(() => {
    if (dataIngestJobId) {
      clearInterval(intervalId);
      const pollIntervalId = setInterval(doFetchIngestJob, 1000);
      setIntervalId(pollIntervalId);
    }

    return () => clearInterval(intervalId);
  }, [dataIngestJobId]);

  useEffect(() => {
    const filterPairs = pairs.filter(e => !isEmpty(e.sentence));
    setHasUpdated(!isEqual(filterPairs, originalPairs));
  }, [pairs]);

  useEffect(() => {
    if (taskId) {
      setIsLoading(true);
      doFetchTaskInfo();
      setIsLoading(false);
    }
  }, [taskId]);

  useEffect(() => {
    labels && doFetchPairs();
  }, [labels]);

  return (
    <CenteredWithTopNavLayout>
      <Modal open={ingestModalShow}>
        <ModalContent>
          Dataset <h3>{taskInfo?.name}</h3>
          {!isIngestJobDone && (
            <CircularProgressContainer>
              <CircularProgress />
            </CircularProgressContainer>
          )}
          {isIngestJobDone && dataIngestJob.status === "DONE" && (
            <SuccessMsg>
              Dataset created succesfully:{" "}
              <LinkMsg
                onClick={() => {
                  navigate(`/data/${dataIngestJob.outputDatasetId}`);
                }}
              >
                {dataIngestJob.outputDataset.name}
              </LinkMsg>
            </SuccessMsg>
          )}
          {isIngestJobDone && (dataIngestJob.status === "DONE_FAILED" || dataIngestJob.status === "ABORT") && (
            <ErrorMsg>Dataset Ingestion failed</ErrorMsg>
          )}
          {isIngestJobDone && (
            <ButtonRow>
              <Button value={"OK"} onClick={() => setIngestModalShow(false)} />
            </ButtonRow>
          )}
        </ModalContent>
      </Modal>
      <TaskInfoContainer>
        <BigTitle>{taskInfo?.name}</BigTitle>
      </TaskInfoContainer>
      <PairListContainer>
        <PairRow>
          <ColumnHeader>Sentence</ColumnHeader>
          <ColumnHeader>Label</ColumnHeader>
        </PairRow>
        {!isEmpty(pairs) &&
          pairs.map((pair, idx) => (
            <PairRow>
              <TextInput
                value={pair.sentence}
                onNewInput={newVal => {
                  const newPairs = [...pairs];
                  const newPair = { ...pair, sentence: newVal };
                  newPairs[idx] = newPair;
                  setPairs(newPairs);
                }}
              />
              {!isEmpty(labels) && (
                <SelectInput
                  value={pair.label}
                  onSetNewValue={newVal => {
                    const newPairs = [...pairs];
                    const newPair = { ...pair, label: newVal };
                    newPairs[idx] = newPair;
                    setPairs(newPairs);
                  }}
                >
                  {labels.map(label => (
                    <option value={label}>{label}</option>
                  ))}
                </SelectInput>
              )}
            </PairRow>
          ))}
        <IconContainer
          onClick={() => {
            const newPairs = [
              ...pairs,
              { sentence: "", label: labels?.[0], sentenceLabelingTaskId: taskId, id: userName + Date.now() },
            ];
            setPairs(newPairs);
          }}
        >
          <Add />
        </IconContainer>
      </PairListContainer>
      <Gap height="8px" />
      <BottomButtons>
        <Button isDisabled={!hasUpdated} value={"Save"} onClick={() => doSavePairs()} />
        <Button value={"Download Data"} onClick={() => triggerDownloadOfSentenceLabelPairs(taskId)} />
        <Button value={"Save as a dataset"} onClick={() => doIngestSentenceLabelingTaskAsDataset()} />
      </BottomButtons>
    </CenteredWithTopNavLayout>
  );
};

export default SentenceLabelingTaskPage;
