import styled from "styled-components";

import NavHeader from "components/widgets/NavHeader";
import SearchInput from "components/widgets/SearchInput";
import { ExternalNavigation, InternalNavigation } from "components/packageSideBars";

const CenteredContentContainer = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 1fr 1fr;
  @media (min-width: 1200px) {
    grid-template-columns: 150px 1fr ${props => props.centerColumnMaxWidth} 1fr;
  }
  padding-bottom: 50px;
`;

const SearchInputContainer = styled.div`
  padding-bottom: 30px;
`;

const hasStickySearchBar = true;

const Content = styled.div`
  padding-top: ${hasStickySearchBar ? "80px" : "10px"};
  grid-column: 3;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

export const CenteredWithTopNavLayoutExternal = ({
  children,
  isHeaderEnabled = true,
  isDisabled,
  centerColumnMaxWidth = "1000px",
}) => (
  <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
    <ExternalNavigation bgColor="#81af98" />
    <NavHeader bgColor="#81af98" hasStickySearchBar={false} />
    <Content isDisabled={isDisabled}>{children}</Content>
  </CenteredContentContainer>
);

export const CenteredWithTopNavLayoutInternal = ({
  children,
  isHeaderEnabled = true,
  isDisabled,
  centerColumnMaxWidth = "1000px",
}) => (
  <CenteredContentContainer centerColumnMaxWidth={centerColumnMaxWidth}>
    <InternalNavigation bgColor="#14204D" />
    <NavHeader bgColor="#14204D" hasStickySearchBar={false} />
    <Content isDisabled={isDisabled}>{children}</Content>
  </CenteredContentContainer>
);
