import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { SmallTitle } from "components/ui/Text";
const Container = styled.div`
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  gap: 30px;
`;

const TopContent = styled.div`
  height: 10px;
  @media (min-width: 1200px) {
    grid-column: span 2;
  }
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const TutorialBlock = styled.div`
  border-radius: 10px;
  border: 2px solid ${props => props.theme.color.closer1};
  img {
    border-radius: 8px;
  }
`;

const TutorialBlockTextContent = styled.div`
  padding: 10px;
`;

const CategoryBadge = styled.div`
  width: 30%;
  padding: 5px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 10px;
`;

const DescriptionBox = styled.div`
  padding: 5px 0px 0px 0px;
  margin-bottom: 10px;
`;

const ReadMoreLink = styled(Link)`
  font-weight: bold;
  color: ${props => props.theme.color.primary};
`;

const composeTutorialId = name => {
  const elems = name.toLowerCase().trim().split(/\s+/);
  return elems.join("-");
};

const TutorialsListView = () => {
  const [categories, setCategories] = useState(["basic operation", "case study"]);
  const [filteredTutorials, setFilteredTutorials] = useState([]);
  const tutorialList = [
    {
      title: "Create a dataset from a local file",
      category: "basic operation",
      description: "This tutorial shows how to create a dataset from a local file.",
    },
    {
      title: "Create a cube",
      category: "basic operation",
      description: "This tutorial shows how to create a cube.",
    },
    {
      title: "Create a dataset from Google Drive",
      category: "basic operation",
      description: "This tutorial shows how to create a dataset from Google Drive.",
    },
    {
      title: "Deploy tabular data API as predictive solutions",
      category: "basic operation",
      description: "This tutorial shows how to deploy tabular data API as predictive solutions.",
    },
    {
      title: "Build Image Annotation Tasks",
      category: "basic operation",
      description: "This tutorial shows how to build image annotation tasks.",
    },

    {
      title: "Use Smart AI bar",
      category: "basic operation",
      description: "This tutorial shows how to use Smart AI bar to creat cube.",
    },
  ];
  return (
    <Container>
      <TopContent></TopContent>
      {tutorialList.map(tutorial => (
        <TutorialBlock>
          <img src={`./images/tutorial/${composeTutorialId(tutorial.title)}/cover.jpg`} alt={"tutorial cover image"} />
          <TutorialBlockTextContent>
            <SmallTitle>{tutorial.title}</SmallTitle>
            <CategoryBadge>{tutorial.category}</CategoryBadge>
            <DescriptionBox>{tutorial.description}</DescriptionBox>
            <ReadMoreLink to={`/tutorial/${composeTutorialId(tutorial.title)}`}>read more >></ReadMoreLink>
          </TutorialBlockTextContent>
        </TutorialBlock>
      ))}
    </Container>
  );
};

export default TutorialsListView;
