import styled from "styled-components";
import { useState, useEffect } from "react";

import {
  getImageEmbeddingVec,
  getImageNearestNeighbours,
  getDeploymentClusterById,
} from "api/services/deploymentService";
import EmbeddingGridView from "components/widgets/EmbeddingGridView";
import Button from "components/ui/Button";
import ProgressBar from "components/ui/ProgressBar";

const DisplayButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const Container = styled.div`
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.6;"}
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  width: 500px;
  white-space: nowrap;
`;

const EmbeddingHolder = styled.div`
  background-color: ${props => props.theme.color.closer1};
  width: 90%;
  height: 100px;
  border-radius: 10px;
  margin: 10px;
`;

const SearchResults = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
`;

const SearchResultContainer = styled.div`
  position: relative;
  border: 2px solid ${props => props.theme.color.furthest};
`;

const Label = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  background-color: ${props => (props.isMatch ? props.theme.color.success : props.theme.color.error)};
  padding: 2px;
  border-radius: 2px;
  border: 1px solid ${props => props.theme.color.closer2};
`;

const SearchImg = styled.img`
  width: 100px;
`;

const SearchResult = ({ imgSrc, label, isMatch }) => {
  return (
    <SearchResultContainer>
      <SearchImg src={imgSrc} />
      <Label isMatch={isMatch}>{label}</Label>
    </SearchResultContainer>
  );
};

const ImageContentMatchingView = ({ deploymentClusterId, inputImgSrc, inputLabel, isLabelVisible }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [embeddingVec, setEmbeddingVec] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [deploymentCluster, setDeploymentCluster] = useState(null);
  const [readinessProgress, setRedinessProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    deploymentClusterId && doStartPollingReadiness();
  }, [deploymentClusterId]);

  useEffect(() => {
    if (deploymentCluster) {
      setRedinessProgress(deploymentCluster.embeddingCacheBuildProgress);
      if (deploymentCluster.embeddingCacheBuildProgress == 1) {
        clearInterval(intervalId);
      }
    }
  }, [deploymentCluster]);

  useEffect(() => {
    setEmbeddingVec(null);
    setSearchResults([]);
  }, [inputImgSrc, inputLabel, isLabelVisible]);

  const doStartPollingReadiness = () => {
    clearInterval(intervalId);
    doFetchDeploymentCluster();
    const fetchingIntervalId = setInterval(doFetchDeploymentCluster, 3000);
    setIntervalId(fetchingIntervalId);
  };

  const doFetchDeploymentCluster = async () => {
    const { data, error } = await getDeploymentClusterById(deploymentClusterId);
    setDeploymentCluster(data);
  };

  const doFetchEmbeddingOfData = async () => {
    setIsLoading(true);
    const { data, error } = await getImageEmbeddingVec(
      deploymentClusterId,
      inputImgSrc,
      isLabelVisible ? inputLabel : null
    );
    setIsLoading(false);
    setEmbeddingVec(data);
  };

  const doFetchNearestNeighbours = async () => {
    setIsLoading(true);
    const { data, error } = await getImageNearestNeighbours(
      deploymentClusterId,
      inputImgSrc,
      isLabelVisible ? inputLabel : null,
      30
    );
    setIsLoading(false);
    setSearchResults(data);
  };

  const doEmbeddingAndFetchNearestNeighbours = () => {
    doFetchNearestNeighbours();
    doFetchEmbeddingOfData();
  };

  return (
    <Container isEnabled={!isLoading}>
      {/* <DisplayButton value={"Show Data Embedding"} onClick={doFetchEmbeddingOfData}/> */}

      {embeddingVec && (
        <>
          Data Embedding: <EmbeddingGridView embeddingVec={embeddingVec} numSquaresRow={28} />{" "}
        </>
      )}
      {/* <MultiOptionSelector options={["Search", "Visualisation"]} selectedOption={"Search"} /> */}
      <DisplayButton
        isDisabled={readinessProgress < 1}
        value={"Find Similar Data"}
        onClick={doEmbeddingAndFetchNearestNeighbours}
      />
      <SearchResults>
        {readinessProgress < 1 ? (
          <ProgressContainer>
            <span> Preparing search in progress: {Math.ceil(readinessProgress * 100)} %</span>
            <ProgressBar currentValue={Math.ceil(readinessProgress * 100)} maxValue={100} />
          </ProgressContainer>
        ) : (
          searchResults.map((result, i) => (
            <SearchResult
              key={i}
              imgSrc={`data:image/png;base64,${result.image}`}
              label={result.label}
              isMatch={result.label === inputLabel}
            />
          ))
        )}
      </SearchResults>
    </Container>
  );
};

export default ImageContentMatchingView;
