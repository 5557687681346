import { useState, useEffect } from "react";
import {
  activateNotebookEnvironment,
  inActivateNotebookEnvironment,
  getJupyterEnvByPipelineAndTrainingJob,
  createNotebookEnv,
} from "api/services/jupyterService";

const usePollJupyterEnv = (pipelineId, trainingJobId) => {
  const [jupyterEnv, setJupyterEnv] = useState(null);
  const [error, setError] = useState(null);
  const [isDoingFirstFetch, setIsDoingFirstFetch] = useState(true);

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    doStartPolling();
  }, []);

  useEffect(() => {
    if (error || jupyterEnv?.status !== "REGISTERED") {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [jupyterEnv, error]);

  const doStartPolling = async () => {
    clearInterval(intervalId);
    const pollIntervalId = setInterval(doPopulateJupyterEnv, 2000);
    setIntervalId(pollIntervalId);
    doPopulateJupyterEnv();
  };

  const doPopulateJupyterEnv = async () => {
    const { data, error } = await getJupyterEnvByPipelineAndTrainingJob(pipelineId, trainingJobId);
    setJupyterEnv(data);
    setError(error);
    setIsDoingFirstFetch(false);
  };

  const doActivate = async () => {
    const { error } = await activateNotebookEnvironment(jupyterEnv?.id);
    if (error) {
      setError(error);
      return;
    }
    doStartPolling();
  };

  const doInactivate = async () => {
    const { error } = await inActivateNotebookEnvironment(jupyterEnv?.id);
    if (error) {
      setError(error);
      return;
    }
    doStartPolling();
  };

  const doCreate = async () => {
    const { error } = await createNotebookEnv(trainingJobId);
    if (error) {
      setError(error);
      return;
    }
    doStartPolling();
  };

  return [jupyterEnv, isDoingFirstFetch, error, doCreate, doActivate, doInactivate];
};

export default usePollJupyterEnv;
