import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { getUserProfileByUserName } from "api/services/projectService";

const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: inherit;
`;

const UserAvatar = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.color.primary};
  background-image: url(${props => props.src});
  background-size: cover;
`;

const UserBadgePicOnly = ({ userId }) => {
  const [userAvatarSrc, setUserAvatarSrc] = useState("");

  useEffect(() => {
    doPopulateUserAvatarSrc();
  }, [userId]);

  const doPopulateUserAvatarSrc = async () => {
    const { data } = await getUserProfileByUserName(userId);
    setUserAvatarSrc(data?.image.includes("/images/") ? data?.image : `data:image/png;base64,${data?.image}`);
  };

  return (
    <Link to={`/${userId}`}>
      <UserAvatar src={userAvatarSrc} alt={userId} />
    </Link>
  );
};

export default UserBadgePicOnly;
