import styled from "styled-components";
import { Ballot, Image, ShortText, Toc } from "@material-ui/icons";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { BigTitle } from "components/ui/Text";
import { useNavigate } from "react-router-dom";

const Panel = styled.div`
  border-radius: 5px;
  display: grid;
  grid-template-rows: 20px 1fr;
  width: max-content;
`;

const PanelTitle = styled.div`
  font-weight: bold;
`;

const PanelItems = styled.div`
  display: flex;
  gap: 10px;
`;

const CardLink = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 10px;
  border-radius: 5px;
  width: 200px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 5px;

  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
    color: ${props => props.theme.color.primary};
  }
`;

const PanelWithTitle = ({ title, children }) => (
  <Panel>
    <PanelTitle>{title}</PanelTitle>
    <PanelItems>{children}</PanelItems>
  </Panel>
);

const BuildDatasetPage = () => {
  const navigate = useNavigate();

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>Build dataset</BigTitle>
      <Gap />
      <PanelWithTitle title="Image">
        <CardLink onClick={() => navigate("/image-annotation-tasks")}>
          <Image fontSize="large" />
          Image annotation tasks
        </CardLink>
        <CardLink onClick={() => navigate("/data/build-dataset/image-labelling-tasks")}>
          <Image fontSize="large" />
          Image labelling tasks
        </CardLink>
      </PanelWithTitle>
      <Gap />
      <PanelWithTitle title="Text">
        <CardLink onClick={() => navigate("/text-annotation-tasks")}>
          <ShortText fontSize="large" />
          Text annotation tasks
        </CardLink>
        <CardLink onClick={() => navigate("/sentence-labeling-tasks")}>
          <Toc fontSize="large" />
          Text labelling tasks
        </CardLink>
      </PanelWithTitle>
      <Gap />
      {/* <PanelWithTitle title="Tabular">
        <CardLink onClick={() => navigate("/surveys")}>
          <Ballot fontSize="large" />
          Surveys
        </CardLink>
      </PanelWithTitle> */}
    </CenteredWithTopNavLayout>
  );
};

export default BuildDatasetPage;
