import styled from "styled-components";
import { Handle } from "react-flow-renderer";
import { CrossIcon } from "../Icons";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 25px;
  height: ${props => props.bodyHeight}px;
  background-color: ${props => (props.isOutputNode ? `${props.theme.color.data}55` : `${props.theme.color.feature}55`)};
  border-radius: ${props => (props.isOutputNode ? "5px" : "45px")};
  ${props => {
    if (!props.isOutputNode) {
      return "";
    }
    if (props.type === "input") {
      return `background-color: ${props.theme.color.in_progress};`;
    }
    if (props.type === "output") {
      return `background-color: ${props.theme.color.primary};`;
    }
    if (props.type === "both") {
      return `
        background-color: ${props.theme.color.primary}; 
        animation-name: pulsecolor; 
        animation-duration: 0.5s; 
        animation-timing-function: ease-out; 
        animation-iteration-count: infinite; 
        animation-direction: alternate; 
        animation-delay: ${props.delaySeconds}s;
      `;
    }
  }}

  @keyframes pulsecolor {
    0% {
      background-color: ${props => props.theme.color.primary};
    }
    40% {
      background-color: ${props => props.theme.color.primary};
    }
    60% {
      background-color: ${props => props.theme.color.in_progress};
    }
    100% {
      background-color: ${props => props.theme.color.in_progress};
    }
  }
`;

const EditableBody = styled(Body)`
  /* ${props =>
    props.isOutputNode &&
    `background: linear-gradient(120deg, ${props.theme.color.in_progress} 0%, ${props.theme.color.primary} 100%)`} */
`;

const Label = styled.div`
  cursor: text;
  display: flex;
  position: absolute;
  white-space: nowrap;
  top: -22px;
`;

const InvisibleHandle = styled(Handle)`
  opacity: 0;
`;

const LabelText = styled.div`
  position: absolute;
  text-align: center;
  ${props => (props.isOutputNode ? "bottom: 50%" : "bottom: -45px")};
  ${props => props.isOutputNode && "transform: translate(30px, 5px); left: 0;"};
`;

export const getScaledNodeHeight = (nodeDim, minModelDim = 0, maxModelDim = 0) => {
  const minHeight = 60;
  const maxHeight = 140;

  if (nodeDim === 0) {
    return minHeight;
  }

  const scaledDim0to1 = (nodeDim - minModelDim) / (maxModelDim - minModelDim);

  return minHeight + scaledDim0to1 * (maxHeight - minHeight);
};

export const DataNode = ({ id, data: { dims, minModelDim, maxModelDim, label, isOutputNode, type } }) => {
  const [delaySeconds] = useState(Math.random());
  const bodyHeight = getScaledNodeHeight(dims?.[0], minModelDim, maxModelDim);

  return (
    <Container>
      {!isOutputNode && <Label>{JSON.stringify(dims)}</Label>}
      <InvisibleHandle type="target" position="left" />
      <Body isOutputNode={isOutputNode} bodyHeight={bodyHeight} id={id} type={type} delaySeconds={delaySeconds} />
      <LabelText isOutputNode={isOutputNode} bodyHeight={bodyHeight}>
        {label}
      </LabelText>
      <InvisibleHandle type="source" position="right" />
    </Container>
  );
};

const BigHandle = styled(Handle)`
  transition: transform 0.1s;
  transform-origin: center;
  transform: scale(1);
`;

const CrossIconContainer = styled.div`
  position: absolute;
  top: 0px;
  right: -20px;

  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s;
  width: 12px;
  height: 12px;

  path {
    stroke: ${props => props.theme.color.closest};
  }
`;

const HoverArea = styled.div`
  top: -25px;
  left: -30px;
  z-index: -1;
  border-radius: 20px;
  position: absolute;
  width: calc(100% + 60px);
  height: calc(100% + 30px);

  background-color: rgb(0, 0, 0);
  opacity: 0;
  transition: opacity 0.1s;
`;

const ContainerWithHover = styled(Container)`
  :hover {
    ${BigHandle} {
      transform: scale(2.5);
    }
    ${CrossIconContainer} {
      opacity: 1;
    }
    ${HoverArea} {
      opacity: 0;
    }
  }
`;

const ExpandingInputContainer = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  border: none;
  color: transparent;
`;

const ExpandingInput = ({ onChange, value }) => (
  <ExpandingInputContainer>
    <span>{value}</span>
    <Input type="text" value={value} onChange={e => onChange(e.target.value)} />
  </ExpandingInputContainer>
);

export const DataNodeEditable = ({
  id,
  data: {
    dims,
    minModelDim,
    maxModelDim,
    onClickDelete = () => {},
    onTypeNewDimsForNode = () => {},
    label,
    isOutputNode,
  },
}) => {
  const bodyHeight = getScaledNodeHeight(dims?.[0], minModelDim, maxModelDim);

  const bodyColor = "#9c9c9c";
  const dimsNumbersOnly = dims.join();

  return (
    <ContainerWithHover>
      <HoverArea />
      <Label>
        <CrossIconContainer onClick={() => onClickDelete(id)}>
          <CrossIcon />
        </CrossIconContainer>
        [
        <ExpandingInput value={dimsNumbersOnly} onChange={newDimsString => onTypeNewDimsForNode(id, newDimsString)} />]
      </Label>
      <BigHandle type="target" position="left" />
      <EditableBody bodyHeight={bodyHeight} color={bodyColor} isOutputNode={isOutputNode} />
      <LabelText isOutputNode={isOutputNode} bodyHeight={bodyHeight}>
        {label}
      </LabelText>
      <BigHandle type="source" position="right" />
    </ContainerWithHover>
  );
};
