import styled from "styled-components";
import { useState, useEffect } from "react";

import { getModelById } from "api/services/modelService";
import ModelViewer from "components/widgets/ModelViewer";
import ModelTextEditor from "components/widgets/ModelTextEditor";
import ModelCodeViewer from "components/widgets/ModelCodeViewer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

const TextEditorContainer = styled.div`
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const ModelView = ({ modelId, modelView = "Graph", datasetFeatureTypeDescriptors, inputCols, outputCols }) => {
  const [modelConfig, setModelConfig] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const doFetchModelConfig = async () => {
    const { data, error } = await getModelById(modelId);

    setModelConfig(data?.config);
    setError(error);
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchModelConfig();
  }, [modelId]);

  if (isLoading) {
    return <Container>loading model</Container>;
  }

  if (error?.message) {
    return error.message;
  }

  return (
    <Container>
      {modelView === "Config" && (
        <TextEditorContainer>
          <ModelTextEditor modelConfig={modelConfig} />
        </TextEditorContainer>
      )}
      {modelView === "Graph" && (
        <ModelViewer
          modelConfig={modelConfig}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
          inputCols={inputCols}
          outputCols={outputCols}
        />
      )}
      {modelView === "Code" && <ModelCodeViewer modelId={modelId} />}
    </Container>
  );
};

export default ModelView;
