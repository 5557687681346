import { round } from "lodash";
import { chartColours } from "App";
import { PieChart as RechartsPieChart, Pie, Legend, Cell } from "recharts";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }, totalCost) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent === 0) {
    return null;
  }

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {round(percent * totalCost, 4)}
    </text>
  );
};

const PieChart = ({
  data = [{ name: "", value: 0 }],
  width,
  height,
  radius = 50,
  legendAlign = "center",
  legendVerticalAlign = "bottom",
}) => {
  const total = data.reduce((acc, { value }) => acc + value, 0);
  return (
    <RechartsPieChart width={width} height={height}>
      <Pie
        isAnimationActive={false}
        data={data}
        dataKey="value"
        cx="50%"
        cy="50%"
        outerRadius={radius}
        label={props => renderCustomizedLabel(props, total)}
        labelLine={false}
      >
        {data.map((_, i) => (
          <Cell key={`cell-${i}`} fill={chartColours[i % chartColours.length]} />
        ))}
      </Pie>
      <Legend align={legendAlign} verticalAlign={legendVerticalAlign} layout="vertical" />
    </RechartsPieChart>
  );
};

export default PieChart;
