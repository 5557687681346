import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from "recharts";
import { lightTheme, darkTheme } from "App";

const CurveAreaChart = ({ data, xAxisKey, yAxisKey, chartSize, color = "#8884d8", legend = false }) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";
  const textColor = isDarkTheme ? darkTheme.color.closest : lightTheme.color.closest;

  return (
    <div style={{ width: "100%", height: chartSize.height }}>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={550}
          height={400}
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="False Positive Rate"
            name="stature"
            label={{ value: "False Positive Rate", position: "insideBottom", offset: -5, fill: textColor }}
          />
          <YAxis
            type="number"
            dataKey="True Positive Rate"
            name="weight"
            label={{ value: "True Positive Rate", position: "insideLeft", angle: -90, fill: textColor }}
          />
          <Scatter data={data} fill="#8884d8" line shape={null} />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CurveAreaChart;
