import styled from "styled-components";
import { useState, useEffect } from "react";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  getLoggedInUserRepositories,
  getGithubCubesByUserName,
  integrateRepoForCube,
  ingegrateRepoWithDatasetForCube,
  checkRepoHasMainBranch,
} from "api/services/githubService";
import { errorTypes } from "api/error-handling";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { useNavigate, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import DatasetListSelect from "components/views/CreatePipelineView/DatasetListSelect";

const ReposList = styled.div`
  margin: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const DataListContainer = styled.div`
  padding: 10px;
`;

const Repo = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  cursor: pointer;
  padding: 10px;

  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 5px;

  color: ${props => (props.isSelected ? props.theme.color.furthest : props.theme.color.primary)};
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.furthest)};

  :hover {
    color: white;
    background-color: ${props => props.theme.color.primary};
  }
`;

const RepoInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const RepoInfoText = styled.div`
  font-size: 11px;
  margin-left: 5px;
  font-style: italic;
`;

const ModalContent = styled.div`
  overflow: auto;
  padding: 20px;
  display: flex;
  width: 700px;
  height: 400px;
`;

const ButtonContainer = styled.div`
  width: 90%;
  height: 200px;
  margin: 10px;
`;

const NewCubeModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const userName = getLoggedInUserName();

  const [hasGithubAccount, setHasGithubAccount] = useState(true);
  const [repositories, setRepositories] = useState([]);
  const [repo2HasMain, setRepo2HasMain] = useState(null);
  const [integratedRepoCubes, setIntegratedRepoCubes] = useState([]);
  const [selectedRepoSlug, setSelectedRepoSlug] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [integratedCubeId, setIntegratedCubeId] = useState(null);
  const [integrationError, setIntegrationError] = useState(null);

  useEffect(() => {
    doFetchIntegratedRepoCubes();
  }, []);

  useEffect(() => {
    !isEmpty(repositories) && doFetchRepo2HasMain();
  }, [repositories]);

  const doFetchRepositories = async () => {
    setIsLoading(true);
    const { data, error } = await getLoggedInUserRepositories();
    setIsLoading(false);
    if (error) {
      setError(error);
    }
    setRepositories(data);
  };

  const doFetchRepo2HasMain = async () => {
    setIsLoading(true);
    const { data, error } = await checkRepoHasMainBranch(repositories);
    setError(error);
    setIsLoading(false);
    setRepo2HasMain(data);
  };

  const doFetchIntegratedRepoCubes = async () => {
    setIsLoading(true);
    const { data, error } = await getGithubCubesByUserName(userName);
    setIsLoading(false);
    if (error) {
      if (error.type === errorTypes.NO_GITHUB_ACCOUNT) {
        setHasGithubAccount(false);
        return;
      }
      setError(error);
    }
    setIntegratedRepoCubes(data);
  };

  const doIntegrateRepository = async repoSlug => {
    const { error, data: cube } = await integrateRepoForCube(repoSlug);
    if (!error) {
      setIntegratedCubeId(cube.id);
    } else {
      setIntegrationError(
        "Integration faild because no or invalid BoltzbitFile.json. Select a valid dataset to start integration."
      );
    }
  };

  const doIntegrateRepositoryWithDataset = async (datasetId, repoSlug) => {
    setIsLoading(true);
    const { error, data: pipelineConfig } = await ingegrateRepoWithDatasetForCube(datasetId, repoSlug);
    setIsLoading(false);

    if (!error) {
      setIntegratedCubeId(pipelineConfig.cubeId);
    } else {
      setIntegrationError(
        "Integration faild because no or invalid BoltzbitFile.json. Select a valid dataset to start integration."
      );
    }
  };

  if (isLoading) {
    return (
      <Modal open={open} handleClose={onClose}>
        <ModalContent>Loading... </ModalContent>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal open={open} handleClose={onClose}>
        <ModalContent>{error.message}</ModalContent>
      </Modal>
    );
  }

  return (
    <Modal
      open={open}
      handleClose={() => {
        setSelectedRepoSlug(null);
        setRepositories([]);
        onClose();
      }}
    >
      <ModalContent>
        {repositories?.length === 0 && (
          <>
            <ButtonContainer>
              <Button
                isDisabled={!hasGithubAccount}
                onClick={doFetchRepositories}
                value="Integrate from a Github Repository"
              />
              {!hasGithubAccount ? (
                <p>
                  No Github Account is integrated. Click <Link to={"/home"}> here </Link> to add an account.
                </p>
              ) : (
                <></>
              )}
            </ButtonContainer>
            <ButtonContainer>
              <Button
                onClick={() => {
                  navigate("/data?areCreateCubesBtnsHighlighted=true");
                }}
                value="Start a New Cube with Flow"
              />
            </ButtonContainer>
          </>
        )}
        {selectedRepoSlug ? (
          [
            integratedCubeId ? (
              <div>
                Successfully Integrated.
                <Link to={`/cube/${integratedCubeId}`}> Check it out</Link>
              </div>
            ) : (
              <DataListContainer>
                {integrationError}
                <DatasetListSelect
                  onDatasetSelect={newDataset => doIntegrateRepositoryWithDataset(newDataset.id, selectedRepoSlug)}
                  showEngineSelect={false}
                />
              </DataListContainer>
            ),
          ]
        ) : (
          <ReposList>
            {!isEmpty(repositories) && <p>Please make sure there is a "main" branch in your selected repository. </p>}
            {repo2HasMain &&
              repositories?.map(repoSlug => {
                const isIncluded = integratedRepoCubes.map(e => e.repositoryOwnerPlusName).includes(repoSlug);
                const hasMainBranch = repo2HasMain[repoSlug];
                return (
                  <RepoInfoContainer key={repoSlug}>
                    <Repo
                      isDisabled={isIncluded || !hasMainBranch}
                      onClick={() => {
                        setSelectedRepoSlug(repoSlug);
                        doIntegrateRepository(repoSlug);
                      }}
                    >
                      {repoSlug}
                    </Repo>
                    {isIncluded && <RepoInfoText>This repo has been already integrated.</RepoInfoText>}
                    {!hasMainBranch && <RepoInfoText>There is no "main" branch in this repo.</RepoInfoText>}
                  </RepoInfoContainer>
                );
              })}
          </ReposList>
        )}
      </ModalContent>
    </Modal>
  );
};

export default NewCubeModal;
