import { useState } from "react";

import Editor from "@monaco-editor/react";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import styled from "styled-components";
import { Code } from "@material-ui/icons";

const ModalContent = styled.div`
  padding: 10px; ;
`;

const SmallButton = styled(Button)`
  padding: 2px;
  grid-template-columns: 0px auto 0px;
  align-self: start;
`;

const FormattedCodeModal = ({ textStr }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <SmallButton value="Pretty" onClick={() => setIsModalOpen(true)} />
      <Modal open={isModalOpen} handleClose={() => setIsModalOpen(false)}>
        <ModalContent>
          <Editor value={textStr} height="800px" width="1000px" />
        </ModalContent>
      </Modal>
    </>
  );
};

export default FormattedCodeModal;
