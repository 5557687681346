import { CircularProgress } from "@material-ui/core";
import { AssignmentTurnedInOutlined } from "@material-ui/icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { lightTheme } from "App";
import { CenteredWithTopNavLayout, Gap, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import Button from "components/ui/Button";
import { BigTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import { useState } from "react";
import styled from "styled-components";
import { sleep } from "utils/common";
import MoleculePlot from "./MoleculePlot";

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const Container = styled.div`
  justify-content: center;
`;

const TextInputContainer = styled.div`
  width: 50%;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px;
  width: ${props => props.width || "100%"};
`;

const CircularProgressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const MoleculeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  border-top: 1px solid ${props => props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.closer1};
  min-height: 500px;
  gap: 20px;
`;

const MoleculeInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
`;

const MoleculeStringSpan = styled.span`
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.theme.color.furthest};
  display: inline-block;
  border: 1px solid ${props => props.theme.color.primary};
`;

const MoleculePlotContainer = styled.div`
  grid-column: span 2;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  height: 200px;
`;

const StepCheckbox = styled.input``;

const Score = styled.span`
  color: ${props => `rgb(${(1 - props.score) * 255}, ${props.score * 200}, 0)`};
`;

const Steps = ({ stepNames, onStepSelect, selectedStep, latestStep }) => {
  const latestStepIndex = stepNames.indexOf(latestStep);

  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
          {/* <StepCheckbox type="checkbox" checked={i <= latestStepIndex} onChange={() => {}} /> */}
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const MoleculeGenerationInterface = () => {
  const [queryString, setQueryString] = useState("");
  const [generationResults, setGenerationResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const randomList = [
    "COc1cc(C=O)ccc1O",
    "CC(=O)NCCC1=CNc2c1cc(OC)cc2",
    "CCc(c1)ccc2[n+]1ccc3c2[nH]c4c3cccc4",
    "CN1CCC[C@H]1c2cccnc2",
    "CCC[C@@H](O)CC\\C=C\\C=C\\C#CC#C\\C=C\\CO",
    "CC(=O)OCCC(/C)=C\\C[C@H](C(C)=C)CCC=C",
  ];

  const randomScores = [0.11, 0.82, 0.98, 0.52, 0.34, 0.78];

  const generateMolecule = async () => {
    setIsLoading(true);
    await sleep(1000);
    randomList.sort(() => Math.random() - 0.5);
    setGenerationResults(randomList);
    setIsLoading(false);
  };

  const doGenerateMolecule = () => {
    generateMolecule();
  };

  return (
    <Container>
      <TextInputContainer>
        <TextInput
          value={queryString}
          title="Input Molecule SMILES String"
          placeholder={"e.g. CCCO"}
          onNewInput={newVal => setQueryString(newVal)}
        />
        <Button value={"Generate"} onClick={() => doGenerateMolecule()} />
      </TextInputContainer>

      {isLoading ? (
        <CircularProgressContainer>
          <CircularProgress size={100} style={{ color: lightTheme.color.primary }} />
        </CircularProgressContainer>
      ) : (
        <MoleculeGrid>
          {generationResults?.map((molecule, idx) => {
            return (
              <MoleculeInfo>
                <MoleculeStringSpan>{molecule}</MoleculeStringSpan>
                <Score score={randomScores[idx]}>{randomScores[idx]}</Score>
                <MoleculePlotContainer>
                  <MoleculePlot size={200} canvasId={`molecule-${idx}`} smileString={molecule} />
                </MoleculePlotContainer>
              </MoleculeInfo>
            );
          })}
        </MoleculeGrid>
      )}
    </Container>
  );
};

const MoleculeGeneration = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const [latestStep, setLatestStep] = useState("Train");

  const loggedInUserName = getLoggedInUserName();

  return loggedInUserName ? (
    <CenteredWithTopNavLayout>
      <BigTitle>{"Molecule Generation"}</BigTitle>
      <Gap />
      {/* <Steps
        stepNames={["Import data", "Train", "Deployment review"]}
        latestStep={latestStep}
        selectedStep={selectedStep}
        onStepSelect={newStep => setSelectedStep(newStep)}
      />
      <Gap /> */}
      {selectedStep === "Import data" && <ImportDataView />}
      {selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && <MoleculeGenerationInterface />}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <BigTitle>{"Molecule Generation"}</BigTitle>
      <Gap />
      <MoleculeGenerationInterface />
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default MoleculeGeneration;
