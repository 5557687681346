import styled from "styled-components";
import { PowerSettingsNew } from "@material-ui/icons";

const TopBarContainer = styled.div`
  min-height: 36px;
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.6;"}
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.closer0_5};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  justify-content: flex-end;
  padding: 0px 10px;
`;

const HoverButtonContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 0px solid ${props => props.theme.color.closest};
  border-radius: 60px;
  .MuiSvgIcon-root {
    fill: ${props => props.theme.color.closest};
    height: 24px;
    transition: none;
  }
  :hover {
    border: 0px solid ${props => props.theme.color.primary};
    .MuiSvgIcon-root {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const RightSideButtons = styled.div`
  display: flex;
  align-items: center;
`;

const OnOffText = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 10px;
`;

const StatusText = styled.div`
  padding: 0 10px;
`;

const DeploymentTopBar = ({ onPowerClick, isReadyToUse, isLoading }) => {
  let statusText = "";
  if (isLoading) {
    statusText = "Loading...";
  }
  if (!isReadyToUse && !isLoading) {
    statusText = "";
  }

  return (
    <TopBarContainer isEnabled={!isLoading}>
      <RightSideButtons>
        <StatusText>{statusText}</StatusText>
        <HoverButtonContainer onClick={onPowerClick}>
          <PowerSettingsNew />
        </HoverButtonContainer>
        <OnOffText>STATUS: {isReadyToUse ? "ON" : "OFF"}</OnOffText>
      </RightSideButtons>
    </TopBarContainer>
  );
};

export default DeploymentTopBar;
