import { zapiPost } from "api/zapi";

export const getChatbotExecution = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/chatbot/execute", {}, body);
  return { data, error };
};

export const getChatbotGeneration = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/chatbot/generate", {}, body);
  return { data, error };
};
