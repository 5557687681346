import * as backend from "api/backend/jupyterServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getLoggedInUserName } from "api/services/authenticationService";

export const getJupyterManagementConfig = async () => {
  const { data, error } = await backend.getJupyterManagementConfig();
  return { data, error: getHandlingFromError(error) };
};

export const updateJupyterManagementConfig = async config => {
  const { data, error } = await backend.putJupyterManagementConfig(config);
  return { data, error: getHandlingFromError(error) };
};

export const getNotebookEnvironmentsOfLoggedInUser = async trainingJobId => {
  const userName = getLoggedInUserName();
  if (!userName) {
    return { data: null, error: getHandlingFromError({ type: "Unauthorised", message: "Please log in" }) };
  }
  const params = { createdBy: userName };
  if (trainingJobId) {
    params.trainingJobIds = trainingJobId;
  }
  const { data, error } = await backend.getNotebookEnvironments(params);
  return { data, error: getHandlingFromError(error) };
};

export const getJupyterEnvByPipelineAndTrainingJob = async (pipelineId, trainingJobId) => {
  const { data: jupyterEnvs, error } = await getNotebookEnvironmentsOfLoggedInUser(trainingJobId);
  if (error) {
    return { data: null, error };
  }

  const jupyterEnv = jupyterEnvs.find(env => env.pipelineId === pipelineId);
  return { data: jupyterEnv, error: null };
};

export const createNotebookEnv = async trainingJobId => {
  const { data, error } = await backend.postNotebookEnvironment({ trainingJobId });
  return { data, error: getHandlingFromError(error) };
}

export const getNotebookEnvironmentById = async id => {
  const { data, error } = await backend.getNotebookEnvironmentById(id);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const activateNotebookEnvironment = async id => {
  const config = {
    status: "ACTIVE",
  };
  const { data, error } = await backend.patchNotebookEnvironmentById(id, config);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  return { data, error };
};

export const inActivateNotebookEnvironment = async id => {
  const config = {
    status: "INACTIVE",
  };
  const { data, error } = await backend.patchNotebookEnvironmentById(id, config);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  return { data, error };
};

export const createNotebookEnvironment = async (pipelineId, trainingJobId) => {};
