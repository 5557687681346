import Modal from "components/ui/Modal";
import styled from "styled-components";

const ModalContent = styled.div`
  padding: 25px;
  width: 600px;
  height: ${props => props.height};
  overflow-y: auto;
  font-size: 16px;
  line-height: 25px;
`;

const ModalImg = styled.img`
  margin: 30px;
  width: 300px;
  height: auto;
`;

const TestEmailNotifyModal = ({ open, handleClose, type, email }) => {
  return (
    <Modal open={open} handleClose={handleClose}>
      {type === "success" && (
        <ModalContent height={"380px"}>
          {`A meeting request Email has been sent to ${email}. Now you can forward it to calendar@boltzbit.com.`}
          <ModalImg src={"/images/market-place/documentation/email_forward.png"} />
        </ModalContent>
      )}
      {type === "fail" && (
        <ModalContent height={"250px"}>
          {`Opps, something wrong when sending and reqeust Email ${email}. Probably try later.`}
        </ModalContent>
      )}
    </Modal>
  );
};

export default TestEmailNotifyModal;
