import { useState } from "react";
import SequenceForecastChart from "components/widgets/SequenceForecastChart";
import minTemperatureSequence from "./cached-data/minTemperatureSequence";
import demandSequence from "./cached-data/demandSequence";
import maxTemperatureSequence from "./cached-data/maxTemperatureSequence";
import solarExposureSequence from "./cached-data/solarExposureSequence";
import styled from "styled-components";

const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
`;

const ChartContainer = styled.div`
  width: 500px;
  height: 500px;
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SequenceView = () => {
  // const cuttingPoints = ["no forecasting", "2015-04-02", "2015-07-15", "2015-10-27"];
  const [cuttingPoint, setCuttingPoint] = useState("no forecasting");

  const minTemperatureSequenceWithError = minTemperatureSequence.map(point => ({
    ...point,
    forecast_1_error_range: [point.forecast_1 - 5, point.forecast_1 + 5],
  }));
  const maxTemperatureSequenceWithError = maxTemperatureSequence.map(point => ({
    ...point,
    forecast_1_error_range: [point.forecast_1 - 5, point.forecast_1 + 5],
  }));
  const solarExposureSequenceWithError = solarExposureSequence.map(point => ({
    ...point,
    forecast_1_error_range: [point.forecast_1 - 8, point.forecast_1 + 8],
  }));
  const demandSequenceWithError = demandSequence.map(point => ({
    ...point,
    forecast_1_error_range: [point.forecast_1 - 15000, point.forecast_1 + 15000],
  }));

  return (
    <>
      {/* <DropdownContainer>
        <SelectInput
          title="Forecasting Point"
          value={cuttingPoint}
          onSetNewValue={newPoint => {       
              setCuttingPoint(newPoint);
          }}
        >
          {cuttingPoints.map(point => (
            <option key={point} value={point}>
              {point}
            </option>
          ))}
        </SelectInput>
      </DropdownContainer> */}
      <ChartGrid>
        <ChartContainer>
          <SequenceForecastChart
            data={minTemperatureSequenceWithError}
            title="Min Temperature Forecasting"
            groundTruthKey="groundTruth"
            forecastKeys={["forecast_1"]}
            sequenceIntervalKey="date"
            cuttingPoint={cuttingPoint}
          />
        </ChartContainer>
        <ChartContainer>
          <SequenceForecastChart
            data={maxTemperatureSequenceWithError}
            title="Maxn Temperature Forecasting"
            groundTruthKey="groundTruth"
            forecastKeys={["forecast_1"]}
            sequenceIntervalKey="date"
            cuttingPoint={cuttingPoint}
          />
        </ChartContainer>
        <ChartContainer>
          <SequenceForecastChart
            data={solarExposureSequenceWithError}
            title="Solar Exposure Forecasting"
            groundTruthKey="groundTruth"
            forecastKeys={["forecast_1"]}
            sequenceIntervalKey="date"
            cuttingPoint={cuttingPoint}
          />
        </ChartContainer>
        <ChartContainer>
          <SequenceForecastChart
            data={demandSequenceWithError}
            title="Demand Forecasting"
            groundTruthKey="groundTruth"
            forecastKeys={["forecast_1"]}
            sequenceIntervalKey="date"
            cuttingPoint={cuttingPoint}
          />
        </ChartContainer>
      </ChartGrid>
    </>
  );
};

export default SequenceView;
