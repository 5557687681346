import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { useState, useEffect } from "react";

import CssReset from "global-styles/css-reset";
import Fonts from "global-styles/fonts";
import "global-styles/github-markdown-styles.css";

import PageComponentOnRoute from "routes";
import TutorialOverlay from "components/widgets/TutorialOverlay";
import InactivityModal from "components/widgets/InactivityModal";
import ThemeSwitch from "components/ui/ThemeSwitch";
import { Lock } from "@material-ui/icons";

export const hiddenDatasetIds = ["6228b6e741ce324fc36d3be6"];

export const hiddenCubeIds = [
  "620e2d32a3c9c869c240e60b",
  "620e2d6aa3c9c869c240e60d",
  "620e2d9ea3c9c869c240e60f",
  "620e2e63a3c9c869c240e613",
  "620e32aea3c9c869c240e617",
  "620e361da3c9c869c240e623",
  "620e397ba3c9c869c240e628",
  "620e2d18a3c9c869c240e609",
  "620e38dda3c9c869c240e626",
  "620e2dcaa3c9c869c240e611",
  "620e2e65a3c9c869c240e615",
  "620e32baa3c9c869c240e619",
  "620e33c7a3c9c869c240e61d",
  "620e4848a3c9c869c240e634",
  "620e41d1a3c9c869c240e62d",
  "6228b779f4fb5b567175786f",
];

export const chartColours = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
  "#aec7e8",
  "#348686",
  "#c02d2d",
  "#25e092",
];

export const lightTheme = {
  name: "light",
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  color: {
    furthest: "#FFFFFF",
    closer0_5: "#f8f8f8",
    closer0: "#f1f1f1",
    closer1: "#e1e1e1",
    closer1_5: "#cacaca",
    closer2: "#737373",
    closest: "#24292F",
    highlightGrey: "#d7dbe0",

    in_progress: "#d4a600",
    status_done: "#00C853",

    primary: "#0191ff",
    error: "#d9534f",
    success: "#28a745",

    feature: "#9650ff",
    data: "#009933",
  },
};

export const darkTheme = {
  name: "dark",
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  color: {
    furthest: "#0c0e12",
    closer0: "#252a32",
    closer0_5: "#1B1F26",
    closer1: "#363b44",
    closer1_5: "#49566f",
    closer2: "#a8a8a8",
    closest: "#e8e8e8",
    highlightGrey: "#455c87",

    in_progress: "#d4a600",
    status_done: "#00C853",

    primary: "#0191ff",
    error: "#d9534f",
    success: "#a4b9a8",

    feature: "#9650ff",
    data: "#009933",
  },
};

const ThemeSwitchAndLockContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 10;
  position: fixed;
  font-weight: bold;
  top: 18px;
  right: 110px;
  color: ${props => props.theme.color.closest};
`;

const LockIcon = styled(Lock)`
  cursor: pointer;
  border-radius: 10px;
  padding: 2px;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const App = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isScreenLocked, setIsScreenLocked] = useState(false);

  const theme = isDarkTheme ? darkTheme : lightTheme;

  useEffect(() => {
    const themeName = localStorage.getItem("theme");
    setIsDarkTheme(themeName === "dark");
  }, []);

  return (
    <BrowserRouter>
      <CssReset bgColor={theme.color.furthest} />
      <Fonts textColor={theme.color.closest} />
      <ThemeProvider theme={theme}>
        <PageComponentOnRoute />
        <InactivityModal hasBeenManuallyOpened={isScreenLocked} />
        <TutorialOverlay />
        <ThemeSwitchAndLockContainer>
          <ThemeSwitch
            onSwitch={() => {
              localStorage.setItem("theme", isDarkTheme ? "light" : "dark");
              setIsDarkTheme(!isDarkTheme);
            }}
            isOnLeft={!isDarkTheme}
          />
          <LockIcon onClick={() => setIsScreenLocked(true)} />
        </ThemeSwitchAndLockContainer>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
