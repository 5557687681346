import * as backend from "api/backend/deploymentServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import { getLoggedInUserName } from "api/services/authenticationService";

export const getDeploymentManagementConfig = async () => {
  const { data, error } = await backend.getDeploymentManagementConfig();
  return { data, error: getHandlingFromError(error) };
};

export const updateDeploymentManagementConfig = async config => {
  const { data, error } = await backend.putDeploymentManagementConfig(config);
  return { data, error: getHandlingFromError(error) };
};

export const getDeploymentsOfLoggedInUser = async () => {
  const userName = getLoggedInUserName();
  if (!userName) {
    return { data: null, error: getHandlingFromError({ type: "Unauthorised", message: "Please log in" }) };
  }
  const { data, error } = await backend.getDeployments({ created_by: userName });
  return { data, error: getHandlingFromError(error) };
};

export const createDeploymentWithTrainingJobIdAndPipelineId = async (trainingJobId, pipelineId) => {
  const { data, error } = await backend.createDeployment({
    body: { jobId: trainingJobId, pipelineId: pipelineId },
  });
  return { data, error: getHandlingFromError(error) };
};

export const stopDeploymentById = async deploymentId => {
  const { data, error } = await backend.updateDeploymentById(deploymentId, {
    state: "STOPPED",
  });
  return { data, error: getHandlingFromError(error) };
};

export const getPredictionForColumns = async (deploymentId, taskId, inputValues) => {
  const { data, error } = await backend.getPredictionForColumns(deploymentId, taskId, inputValues);
  return { data, error: getHandlingFromError(error) };
};

export const getCsvPrediction = async (deploymentClusterId, inputValues, tag) => {
  const { data, error } = await backend.getDeploymentClusterPredictionForCsv(deploymentClusterId, {
    tag,
    inputs: [inputValues],
  });
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const { results } = data;

  const modelValues = {};
  Object.keys(results[0])
    .filter(columnName => !inputValues[columnName])
    .forEach(columnName => {
      const columnResult = results?.[0]?.[columnName];
      modelValues[columnName] = columnResult?.value || columnResult?.winner;
    });
  const predictedRow = { ...inputValues, ...modelValues };

  return { data: predictedRow, error: null };
};

export const getCsvEmbedding = async (deploymentClusterId, inputValues) => {
  const { data, error } = await backend.getDeploymentClusterPredictionForCsv(deploymentClusterId, {
    inputs: [inputValues],
  });
  return { data: data?.results[0]?._embeddings, error: getHandlingFromError(error) };
};

export const getCsvNearestNeighbours = async (deploymentClusterId, inputValues, n) => {
  const { data, error } = await backend.contentMatching(deploymentClusterId, {
    numberOfMatches: n,
    inputs: [inputValues],
  });

  return { data: data.results[0], error };
};

export const getLabelPredictionForImage = async (deploymentId, taskId, base64ImageString) => {
  const { data, error } = await backend.getPredictionForImage(deploymentId, taskId, base64ImageString);
  return { data: data?.results[0], error: getHandlingFromError(error) };
};

export const getDeploymentClusterOutliers = async (deploymentClusterId, inputRow) => {
  const { data, error } = await backend.getDeploymentClusterOutliers(deploymentClusterId, inputRow);
  return { data: data?.results?.[0] || {}, error };
};

const translateImageLabel = label => {
  const mapping = {
    Pullover: "Dress",
    Sandal: "Bag",
    Dress: "Shirt",
    Shirt: "Trouser",
    Sneaker: "Ankle boot",
    Bag: "T-shirt/top",
    Trouser: "Sandal",
    "Ankle boot": "Coat",
    "T-shirt/top": "Pullover",
    Coat: "Sneaker",
  };
  return mapping[label] || label;
};

const translateImageLabelOutput = labelOutput => {
  const mapping = {
    Pullover: "T-shirt/top",
    Sandal: "Trouser",
    Dress: "Pullover",
    Shirt: "Dress",
    Sneaker: "Coat",
    Bag: "Sandal",
    Trouser: "Shirt",
    "Ankle boot": "Sneaker",
    "T-shirt/top": "Bag",
    Coat: "Ankle boot",
  };
  if (labelOutput.type === "CATEGORICAL") {
    return {
      type: "CATEGORICAL",
      winner: mapping[labelOutput.winner] || labelOutput.winner,
      scores: Object.fromEntries(
        Object.entries(labelOutput.scores).map(([key, value]) => [mapping[key] || key, value])
      ),
    };
  } else {
    return labelOutput;
  }
};

export const getLabelPredictionFromImage = async (deploymentClusterId, base64ImageString, tag) => {
  const reqBody = {
    inputs: [
      {
        image: base64ImageString,
      },
    ],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForImageLabelData(deploymentClusterId, reqBody);
  return {
    data: translateImageLabelOutput(data?.results[0]?.label),
    error,
  };
};

export const getImageInpainting = async (deploymentClusterId, base64ImageString, tag) => {
  const reqBody = {
    inputs: [
      {
        image: base64ImageString,
      },
    ],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForImageLabelData(deploymentClusterId, reqBody);
  return {
    data: data?.results[0]?.image.value,
    error,
  };
};

export const generateImageFromLabel = async (deploymentClusterId, label, tag) => {
  const reqBody = {
    inputs: [
      {
        label,
      },
    ],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForImageLabelData(deploymentClusterId, reqBody);
  return {
    data: data?.results[0]?.image.value,
    error,
  };
};

export const synthesizeImageLabelDataPoint = async (deploymentClusterId, tag) => {
  const reqBody = {
    inputs: [{}],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForImageLabelData(deploymentClusterId, reqBody);
  return {
    data: {
      image: data?.results[0]?.image.value,
      label: translateImageLabelOutput(data?.results[0]?.label),
    },
    error,
  };
};

export const getImageEmbeddingVec = async (deploymentClusterId, base64ImageString, label) => {
  const reqBody = {
    inputs: [
      {
        image: base64ImageString,
        label: translateImageLabel(label),
      },
    ],
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForImageLabelData(deploymentClusterId, reqBody);
  return {
    data: data?.results[0]?._embeddings,
    error,
  };
};

export const getImageNearestNeighbours = async (deploymentClusterId, inputImgSrc, inputLabel, n) => {
  const { data, error } = await backend.contentMatching(deploymentClusterId, {
    numberOfMatches: n,
    inputs: [
      {
        image: inputImgSrc,
        label: inputLabel,
      },
    ],
  });

  return { data: data.results[0], error };
};

export const getLabelPredictionForText = async (deploymentId, text, tag) => {
  const reqBody = {
    deploymentId,
    inputs: [
      {
        sentence_text: text,
      },
    ],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForTextLabelData(reqBody);
  if (data?.results?.[0]?.status === "FAILED") {
    return { data: null, error: getHandlingFromError({ message: "Prediction has status FAILED" }) };
  }
  return { data: data?.results?.[0], error: getHandlingFromError(error) };
};

export const postDeploymentPrediction = async body => {
  const { data, error } = await backend.getDeploymentClusterPredictionForTextLabelData(body);
  return { data, error };
};

export const getTextRewriting = async (deploymentClusterId, text, tag) => {
  const reqBody = {
    inputs: [
      {
        sentence_text: text,
      },
    ],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForTextLabelData(deploymentClusterId, reqBody);
  return {
    data: data.results[0].sentence_text.value,
    error,
  };
};

export const generateTextFromLabel = async (deploymentClusterId, label, tag) => {
  const reqBody = {
    inputs: [
      {
        sentence_label: label,
      },
    ],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForTextGeneration(deploymentClusterId, reqBody);
  return {
    data: data.results[0].sentence_text,
    error,
  };
};

export const synthesizeTextLabelDataPoint = async (deploymentClusterId, tag) => {
  const reqBody = {
    inputs: [{}],
    tag,
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForTextGeneration(deploymentClusterId, reqBody);
  return {
    data: {
      text: data.results[0].sentence_text.value,
      label: data.results[0].sentence_label,
    },
    error,
  };
};

export const getTextLabelEmbeddingVec = async (deploymentClusterId, text, label) => {
  const reqBody = {
    inputs: [
      {
        sentence_text: text,
        sentence_label: label,
      },
    ],
  };
  const { data, error } = await backend.getDeploymentClusterPredictionForTextLabelData(deploymentClusterId, reqBody);
  return {
    data: data.results[0]._embeddings,
    error,
  };
};

export const getTextNearestNeighbours = async (deploymentClusterId, text, label, n) => {
  const { data, error } = await backend.contentMatching(deploymentClusterId, {
    numberOfMatches: n,
    inputs: [
      {
        sentence_text: text,
        sentence_label: label,
      },
    ],
  });

  return { data: data.results[0], error };
};

export const getImageEmbedding = async (deploymentId, taskId, base64ImageString) => {
  const { data, error } = await backend.getPredictionForImage(deploymentId, taskId, base64ImageString);
  return { data: data?.results[0]._embeddings, error: getHandlingFromError(error) };
};

export const getRecoveredImage = async (deploymentId, taskId, base64ImageString) => {
  const { data, error } = await backend.getPredictionForImage(deploymentId, taskId, base64ImageString);
  return { data: data?.results[0].image.value, error: getHandlingFromError(error) };
};

export const getGeneratedSentence = async (deploymentId, taskId, inputWordList) => {
  const { data, error } = await backend.getPredictionForText(deploymentId, taskId, inputWordList);
  return { data: data?.results[0].sentence_text.value, error: getHandlingFromError(error) };
};

export const getPredictionFromText = async (deploymentId, taskId, inputWordList) => {
  const { data, error } = await backend.getPredictionForText(deploymentId, taskId, inputWordList);
  return { data: data?.results[0].sentence_label.scores, error: getHandlingFromError(error) };
};

export const postDeployment = async body => {
  const { data, error } = await backend.postDeployment(body);
  return { data, error: getHandlingFromError(error) };
};

export const shutDownADeploymentCluster = async deploymentClusterId => {
  const { data, error } = await backend.patchDeploymentCluster(deploymentClusterId, 0);
  return { data, error: getHandlingFromError(error) };
};

export const turnOnADeploymentCluster = async deploymentClusterId => {
  const { data, error } = await backend.patchDeploymentCluster(deploymentClusterId, 1);
  return { data, error: getHandlingFromError(error) };
};

export const getDeploymentClustersOfLoggedInUser = async () => {
  const userName = getLoggedInUserName();
  if (!userName) {
    return { data: null, error: { type: "Unauthorised", message: "Please log in" } };
  }
  const { data, error } = await backend.getDeployments({ createdBy: userName, activeOnly: false });
  return { data, error: getHandlingFromError(error) };
};

export const getDeployments = async params => {
  const { data, error } = await backend.getDeployments(params);
  return { data, error: getHandlingFromError(error) };
};

export const getDeploymentClusterById = async deploymentClusterId => {
  const { data, error } = await backend.getDeploymentClusterById(deploymentClusterId);
  return { data, error: getHandlingFromError(error) };
};

export const getOnlineEvaluationReport = async params => {
  const { data, error } = await backend.getOnlineEvaluationReport(params);
  return { data, error: getHandlingFromError(error) };
};

export const shutDownDeploymentClusters = async depClustersIds => {
  const turnOffResults = await Promise.all(depClustersIds.map(id => shutDownADeploymentCluster(id)));
  if (turnOffResults.find(result => result.error)) {
    return turnOffResults.find(result => result.error);
  }
  return { data: null, error: null };
};

export const deployTrainingJobAndShutDownOthers = async (trainingJobId, trainingJobs) => {
  const { data: deploymentClusters, error: depFetchErr } = await getDeploymentClustersOfLoggedInUser();
  if (depFetchErr) {
    return { data: null, error: depFetchErr };
  }

  const trainingJobIds = trainingJobs.map(job => job.id);
  const clustersOfTrainingJobs = deploymentClusters.filter(cluster => trainingJobIds.includes(cluster.trainingJobId));
  const clusterIdsToTurnOff = clustersOfTrainingJobs.map(cluster => cluster.id);
  const { error: turnOffErr } = await shutDownDeploymentClusters(clusterIdsToTurnOff);
  if (turnOffErr) {
    return { data: null, error: turnOffErr };
  }

  const exisitngDeploymentCluster = deploymentClusters.find(cluster => cluster.trainingJobId === trainingJobId);
  if (exisitngDeploymentCluster) {
    const { data, error } = await turnOnADeploymentCluster(exisitngDeploymentCluster.id);
    return { data, error };
  }

  //const { data, error } = await createDeploymentCluster(trainingJobId);
  return { data: null, error: null };
};

export const getDeployedTrainingJobIdsOfLoggedInUser = async () => {
  const { data: deploymentClusters, error: depFetchErr } = await getDeploymentClustersOfLoggedInUser();
  if (depFetchErr) {
    return { data: null, error: depFetchErr };
  }
  return {
    data: deploymentClusters.filter(cluster => cluster.instanceCount > 0).map(cluster => cluster.trainingJobId),
    error: null,
  };
};

export const getFirstDeploymentClusterOfTrainingJobs = async trainingJobIds => {
  const { data: deploymentClusters, error } = await getDeploymentClustersOfLoggedInUser();
  if (error) {
    return { data: null, error };
  }

  const clustersOfJobs = deploymentClusters.filter(cluster => trainingJobIds.includes(cluster.trainingJobId));
  const activeCluster = clustersOfJobs.find(cluster => cluster.instanceCount > 0);

  return { data: activeCluster || clustersOfJobs?.[0], error: null };
};

export const getSampleDataPointFromDeployment = async deploymentClusterId => {
  const { data, error } = await backend.getSampleDataFromDeployment(deploymentClusterId, { n: 10 });
  const randomPoint = data?.[Math.floor(Math.random() * data.length)];
  return { data: randomPoint, error: getHandlingFromError(error) };
};

export const getDataConfigFromDeployment = async deploymentClusterId => {
  const { data, error } = await backend.getDataConfigFromDeployment(deploymentClusterId);
  return { data, error: getHandlingFromError(error) };
};

export const getFirstDeploymentOfModel = async modelId => {
  const { data: deployments, error } = await getDeployments({ modelIds: [modelId] });
  return { data: deployments?.[0], error: getHandlingFromError(error) };
};
