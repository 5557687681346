import DatasetPreview from "components/views/DatasetPreview";
import Modal from "components/ui/Modal";
import styled from "styled-components";

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  display: flex;
  width: 700px;
  height: 400px;
`;

const DataInspectionModal = ({
  datasetId,
  open,
  onClose
}) => {
  return (
    <Modal open={open} handleClose={onClose}>
      <ModalContent>
        <DatasetPreview datasetId={datasetId} />
      </ModalContent>
    </Modal>
  );  
};

export default DataInspectionModal;

