import styled from "styled-components";
import { useState, useEffect } from "react";

import { getManagementConfigOfCube, patchCube, putManagementConfigOfCube } from "api/services/projectService";
import { deleteCube } from "api/services/projectService";
import SelectInput from "components/ui/SelectInput";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { SmallTitle } from "components/ui/Text";
import TextInput from "components/ui/TextInput";
import { Gap } from "components/Layout";

const Container = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  gap: 20px;
  flex-direction: column;
  padding: 20px;
  width: 600px;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const ModalContent = styled.div`
  ${props => props.isDisabled && " opacity: 0.5; pointer-events: none;"}
  padding: 20px;
  display: grid;
  gap: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
`;

const AreYouSureText = styled.div`
  font-weight: bold;
  grid-column: span 2;
`;

const InputsGroup = styled.div`
  display: flex;
  gap: 10px;
`;

const RedBox = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.error}44;
`;

const GreyBox = styled.div`
  width: 100%;
  padding: 20px;
  padding-top: 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const RedButton = styled(Button)`
  color: ${props => props.theme.color.error};
  border: 2px solid ${props => props.theme.color.error};
  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.error};
  }
`;

const ConfigInputs = styled.div`
  padding-bottom: 25px;
`;

const SettingTitle = styled(SmallTitle)`
  font-weight: normal;
`;

const DeleteCubeConfirmModal = ({ isOpen, onClose, onClickYes, isDisabled }) => (
  <Modal title="Confirmation" open={isOpen} handleClose={onClose}>
    <ModalContent isDisabled={isDisabled}>
      <AreYouSureText>Are you sure you want to delete this cube?</AreYouSureText>
      <RedButton value="Yes, delete it" onClick={onClickYes} />
      <Button value="No, cancel" onClick={onClose} />
    </ModalContent>
  </Modal>
);

const ChangeVisibilityModal = ({ isOpen, onClose, onClickYes, isDisabled, isPublic }) => (
  <Modal title="Confirmation" open={isOpen} handleClose={onClose}>
    <ModalContent isDisabled={isDisabled}>
      <AreYouSureText>Are you sure you want to make this cube {isPublic ? "private" : "public"}?</AreYouSureText>
      <RedButton value={`Yes, make it ${isPublic ? "private" : "public"}`} onClick={onClickYes} />
      <Button value="No, cancel" onClick={onClose} />
    </ModalContent>
  </Modal>
);

const CubeSettingsView = ({ cubeId, sharedWith }) => {
  const [error, setError] = useState(null);
  const [cubeManagementConfig, setCubeManagementConfig] = useState(null);
  const [isDeletionConfirmModalOpen, setIsDeletionConfirmModalOpen] = useState(false);
  const [isChangeVisibilityModalOpen, setIsChangeVisibilityModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    doPopulatecubeManagementConfig();
  }, []);

  const doPopulatecubeManagementConfig = async () => {
    const { data, error } = await getManagementConfigOfCube(cubeId);
    setCubeManagementConfig(data);
    setError(error);
    setIsLoading(false);
  };

  const doSaveConfig = async () => {
    setIsLoading(true);
    const { data, error } = await putManagementConfigOfCube(cubeId, cubeManagementConfig);
    setCubeManagementConfig(data);
    setError(error);
    setIsLoading(false);
  };

  const isPublic = sharedWith.find(
    permission => permission.userGroupId === "PUBLIC" && permission.accessLevel === "READ_ONLY"
  );

  return (
    <Container isDisabled={isLoading}>
      {error && JSON.stringify(error)}
      <GreyBox>
        <ConfigInputs>
          <SettingTitle>Training management</SettingTitle>
          <InputsGroup>
            <SelectInput
              title="Default worker type"
              value={cubeManagementConfig?.trainingManagement?.defaultWorkerType}
              onSetNewValue={newValue => {
                setCubeManagementConfig({
                  ...cubeManagementConfig,
                  trainingManagement: {
                    ...cubeManagementConfig.trainingManagement,
                    defaultWorkerType: newValue,
                  },
                });
              }}
            >
              <option value="CPU_SMALL">CPU_SMALL</option>
              <option value="CPU_MEDIUM">CPU_MEDIUM</option>
              <option value="CPU_LARGE">CPU_LARGE</option>
              <option value="GPU">GPU</option>
            </SelectInput>
          </InputsGroup>
        </ConfigInputs>

        <ConfigInputs>
          <SettingTitle>Deployment management</SettingTitle>
          <InputsGroup>
            <TextInput
              title="Max instances"
              value={cubeManagementConfig?.deploymentManagement?.maxInstances}
              onNewInput={newValue => {
                setCubeManagementConfig({
                  ...cubeManagementConfig,
                  deploymentManagement: {
                    ...cubeManagementConfig.deploymentManagement,
                    maxInstances: newValue,
                  },
                });
              }}
            />
            <TextInput
              title="Timeout minutes"
              value={cubeManagementConfig?.deploymentManagement?.timeoutMinutes}
              onNewInput={newValue => {
                setCubeManagementConfig({
                  ...cubeManagementConfig,
                  deploymentManagement: {
                    ...cubeManagementConfig.deploymentManagement,
                    timeoutMinutes: newValue,
                  },
                });
              }}
            />
          </InputsGroup>
        </ConfigInputs>

        <ConfigInputs>
          <SettingTitle>Jupyter management</SettingTitle>
          <InputsGroup>
            <TextInput
              title="Max instances"
              value={cubeManagementConfig?.jupyterManagement?.maxInstances}
              onNewInput={newValue => {
                setCubeManagementConfig({
                  ...cubeManagementConfig,
                  jupyterManagement: {
                    ...cubeManagementConfig.jupyterManagement,
                    maxInstances: newValue,
                  },
                });
              }}
            />
            <TextInput
              title="Timeout minutes"
              value={cubeManagementConfig?.jupyterManagement?.timeoutMinutes}
              onNewInput={newValue => {
                setCubeManagementConfig({
                  ...cubeManagementConfig,
                  jupyterManagement: {
                    ...cubeManagementConfig.jupyterManagement,
                    timeoutMinutes: newValue,
                  },
                });
              }}
            />
          </InputsGroup>
        </ConfigInputs>
        <Button value="Save" onClick={doSaveConfig} />
      </GreyBox>
      <Gap />
      <RedBox>
        <SettingTitle>Change cube visibility</SettingTitle>
        <div>This cube is currently {isPublic ? "public" : "private"}.</div>
        <Gap />
        <RedButton
          value={`Make ${isPublic ? "private" : "public"}`}
          onClick={() => setIsChangeVisibilityModalOpen(true)}
        />
        <Gap height="50px" />
        <RedButton value="Delete cube" onClick={() => setIsDeletionConfirmModalOpen(true)} />
      </RedBox>
      <DeleteCubeConfirmModal
        isOpen={isDeletionConfirmModalOpen}
        onClose={() => setIsDeletionConfirmModalOpen(false)}
        isDisabled={isLoading}
        onClickYes={async () => {
          setIsLoading(true);
          const { error } = await deleteCube(cubeId);
          if (error) {
            setError(error);
            setIsLoading(false);
            setIsDeletionConfirmModalOpen(false);
            return;
          }
          window.location = "/cubes";
        }}
      />
      <ChangeVisibilityModal
        isPublic={isPublic}
        isOpen={isChangeVisibilityModalOpen}
        onClose={() => setIsChangeVisibilityModalOpen(false)}
        isDisabled={isLoading}
        onClickYes={async () => {
          setIsLoading(true);
          let newSharedWith = {};
          if (isPublic) {
            newSharedWith = sharedWith.filter(permission => permission.userGroupId !== "PUBLIC");
          } else {
            newSharedWith = [
              ...sharedWith.filter(permission => permission.userGroupId !== "PUBLIC"),
              { userGroupId: "PUBLIC", accessLevel: "READ_ONLY" },
            ];
          }
          const { error } = await patchCube(cubeId, { sharedWith: newSharedWith });
          if (error) {
            setError(error);
            setIsLoading(false);
            setIsDeletionConfirmModalOpen(false);
            return;
          }
          window.location.reload();
        }}
      />
    </Container>
  );
};

export default CubeSettingsView;
