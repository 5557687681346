import { useEffect } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import {
  gmailOauthCallBack,
  linkedinOauthCallBack,
  hubspotOauthCallBack,
  salesforceOauthCallBack,
  googleDriveOauthCallBack,
  googleCalendarCallBack,
} from "api/services/authenticationService";
import { isEmpty } from "lodash";

const sendGmailAccessCodeToBackendAndRelocation = async (code, relocationUrl) => {
  await gmailOauthCallBack(code);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  window.location = `${baseUrl}${relocationUrl}`;
};

const sendLinkedinAccessCodeToBackendAndRelocation = async (code, relocationUrl) => {
  await linkedinOauthCallBack(code);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  window.location = `${baseUrl}${relocationUrl}`;
};

const sendGoogleDriveAccessCodeToBackendAndRelocation = async (code, relocationUrl) => {
  await googleDriveOauthCallBack(code);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  window.location = `${baseUrl}${relocationUrl}`;
};

const sendGoogleCalendarAccessCodeToBackendAndRelocation = async (code, relocationUrl) => {
  const { data, error } = await googleCalendarCallBack(code);
  if (error) {
    alert(JSON.stringify(error));
    return;
  }
  if (!isEmpty(data)) {
    sessionStorage.setItem("tempUserGoogleCalendarRefreshToken", data?.token);
    sessionStorage.setItem("tempUserGoogleCalendarEmail", data?.email);
  }
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  window.location = `${baseUrl}${relocationUrl}`;
};

const sendHubspotAccessCodeToBackendAndRelocation = async (code, relocationUrl) => {
  await hubspotOauthCallBack(code);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  window.location = `${baseUrl}${relocationUrl}`;
};

const sendSalesforceAccessCodeToBackendAndRelocation = async (code, relocationUrl) => {
  await salesforceOauthCallBack(code);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  window.location = `${baseUrl}${relocationUrl}`;
};

const OauthCallBack = () => {
  const [searchParams] = useSearchParams();
  const { source } = useParams();

  useEffect(() => {
    const relocationUrl = sessionStorage.getItem("preOauthUrl") || "/";
    searchParams.get("code") &&
      source === "gmail" &&
      sendGmailAccessCodeToBackendAndRelocation(searchParams.get("code"), relocationUrl);

    searchParams.get("code") &&
      source === "linkedin" &&
      sendLinkedinAccessCodeToBackendAndRelocation(searchParams.get("code"), relocationUrl);

    searchParams.get("code") &&
      source === "googledrive" &&
      sendGoogleDriveAccessCodeToBackendAndRelocation(searchParams.get("code"), relocationUrl);

    searchParams.get("code") &&
      source === "googlecalendar" &&
      sendGoogleCalendarAccessCodeToBackendAndRelocation(searchParams.get("code"), relocationUrl);

    searchParams.get("code") &&
      source === "hubspot" &&
      sendHubspotAccessCodeToBackendAndRelocation(searchParams.get("code"), relocationUrl);

    searchParams.get("code") &&
      source === "salesforce" &&
      sendSalesforceAccessCodeToBackendAndRelocation(searchParams.get("code"), relocationUrl);
  }, [searchParams, source]);

  return null;
};

export default OauthCallBack;
