import {
  getAccessToken,
  registerUser,
  demoUserSendMeetingRequestEmail,
  addGoogleCalendaerUserRefereshToken,
} from "api/services/authenticationService";
import { updateEmails } from "api/services/market-place/calendar";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import TextInput from "components/ui/TextInput";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { setLoginTokenInClientStorage } from "utils/auth-utils";

const ModalContent = styled.div`
  padding: 15px;
  width: 600px;
  overflow-y: auto;
`;

const EmailRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const EmailBlock = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 90%;
  overflow-y: auto;
`;

const EmailInputContainer = styled.div`
  width: 300px;
`;

const Buttons = styled.div`
  border-top: 1px solid ${props => props.theme.color.closer1};
  padding-top: 15px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const validName = name => {
  if (typeof name === "string" && /^[a-zA-Z0-9-_]+$/.test(name)) {
    return true;
  }
  return false;
};

const generatePassword = () => {
  var length = 8,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    retVal = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

const GuestEmailConfigModal = ({ handleClose, open, onOK }) => {
  const [userName, setUserName] = useState("");
  const [message, setMessage] = useState("");
  const [isRegisteringUser, setIsRegisteringUser] = useState(false);

  const navigate = useNavigate();

  const doAddNewUserAndLogInAndRegister = async () => {
    if (!validName(userName)) {
      setMessage(
        "You name seems invalid. A valid name can only contain lower-case alphabet characters, numbers and dash, e.g. tom-hanks-2"
      );
      return false;
    }

    const email = sessionStorage.getItem("tempUserGoogleCalendarEmail");

    const password = generatePassword();
    const { error: registerError } = await registerUser({
      userName,
      email,
      password,
      repeatedPassword: password,
    });

    if (registerError) {
      if (registerError.message.includes("userName unavailable")) {
        setMessage(`The name ${userName}  has been occupied, please change another name`);
      } else {
        setMessage("Something wrong when registering the Email.");
      }

      return false;
    }

    const { data, error: logInError } = await getAccessToken(userName, password);
    if (logInError) {
      setMessage("Something wrong when registering the Email, maybe try later. ");
      return false;
    } else {
      setLoginTokenInClientStorage(data.accessToken);
    }

    // send an notification email
    let emailBody =
      `Hi ${userName}, \n\n` +
      "Thanks for using Boltzbit Smart Calendar Assistant. We have created an account for you: \n\n" +
      `user name: ${userName} \npassword:  ${password} \n\n` +
      "You can log in on https://demo.platform.boltzbit.com/login. \n\n" +
      "Boltzbit team";

    await demoUserSendMeetingRequestEmail({
      from: "me",
      to: [email],
      subject: "Welcome to use Boltzbit Smart Calendar Assistant",
      bodyText: emailBody,
    });

    const { error: emailBindingError } = await updateEmails([email]);
    if (emailBindingError) {
      setMessage("Something wrong when registering the Email, maybe try later.");
      return false;
    }

    const refreshToken = sessionStorage.getItem("tempUserGoogleCalendarRefreshToken");
    if (refreshToken) {
      await addGoogleCalendaerUserRefereshToken(refreshToken);
    }
    sessionStorage.setItem("tempUserGoogleCalendarRefreshToken", null);

    return true;
  };

  return (
    <Modal title="Provide your login information" open={open}>
      <ModalContent>
        <p>{message}</p>
        <EmailBlock>
          <EmailRow>
            <div>Your username: </div>
            <EmailInputContainer>
              <TextInput
                placeholder="sam-stanley"
                value={userName}
                onNewInput={newVal => {
                  let trimedText = newVal.trim();
                  setUserName(trimedText.toLowerCase());
                }}
              />
            </EmailInputContainer>
          </EmailRow>
        </EmailBlock>
        <Buttons>
          <Button
            isDisabled={isRegisteringUser}
            value={"Confirm"}
            onClick={async () => {
              setIsRegisteringUser(true);
              const res = await doAddNewUserAndLogInAndRegister();
              setIsRegisteringUser(false);
              if (res) {
                onOK();
                window.location = "/market-place/calendar?post-oauth-callback";
              }
            }}
          />
          <Button
            value={"Cancel"}
            onClick={() => {
              handleClose();
              navigate("/market-place/calendar");
            }}
          />
        </Buttons>
      </ModalContent>
    </Modal>
  );
};

export default GuestEmailConfigModal;
