import { useState, useEffect } from "react";
import styled from "styled-components";
import TextInput from "components/ui/TextInput";
import { sendMeetingRequest } from "api/services/market-place/calendar";
import { getUserProfileByUserName } from "api/services/projectService";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { CircularProgress } from "@material-ui/core";
import {
  blurTutorialOverlay,
  highlightElementByTutorialId,
  showTutorialOverlay,
} from "components/widgets/TutorialOverlay";
import { sleep } from "utils/common";
import { Gap } from "components/Layout";
import { ArrowBack } from "@material-ui/icons";

const Container = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr 175px;
  width: 100vh;
  height: 100vh;
`;

const VerticalLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FormPage = styled.div`
  width: 70vw;
  height: 80vh;

  text-decoration: none;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
  display: flex;

  box-shadow: rgb(100 100 100 / 20%) 1px 1px 1px 1px, rgb(100 100 100 / 14%) 2px 2px 2px 2px,
    rgb(100 100 100/ 12%) 1px 3px 1px 1px;
  padding: 25px 30px;
`;

const FormLeft = styled.div`
  width: 30%;
  border-right: 1px solid ${props => props.theme.color.closer1};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const UserProfileContainer = styled.div`
  padding-top: 35px;
  height: 300px;
`;

const UserAvatar = styled.img`
  height: 75px;
  width: 75px;
  border-radius: 100%;
  border: 2px solid ${props => props.theme.color.primary};
`;

const UserProfile = styled.div`
  display: flex;
  justify-content: space-even;
  align-items: center;
  gap: 15px;
`;

const ProfessionProfile = styled.div`
  padding-top: 35px;
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.color.closer2};
  gap: 15px;
  font-weight: bold;
`;

const Greeting = styled.div`
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
`;

const Name = styled.div`
  font-size: 18px;
  font-weight: bold;
`;

const FormLeftTop = styled.div`
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const CompanyLogo = styled.img`
  width: 85%;
  max-height: 85%;
`;

const FormLeftBottom = styled.div`
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignUpContainer = styled.div`
  margin-top: 35px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${props => props.theme.color.closer0};

  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    svg {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const FormRight = styled.div`
  width: 60%;
  padding: 15px;
  padding-left: 50px;
`;

const LogoColumn = styled.div`
  width: 10%;
`;

const FormRightTopBanner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

const LogoSquare = styled.img`
  height: 40px;
  width: 40px;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: bold;
  margin: 0px 15px 15px 0px;
  width: 400px;
`;

const Notification = styled.div`
  position: relative;
  z-index: 12;
  color: ${props => (props.type === "success" ? props.theme.color.success : props.theme.color.error)};
  height: 30px;
`;

const NotificationLink = styled.a`
  background-color: ${props => props.theme.color.furthest};
`;

const Subtitle = styled.div`
  font-weight: bold;
  margin: 10px 0px 10px 0px;
`;

const UserInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const NameContainer = styled.div`
  width: 200px;
`;

const EmailContainer = styled.div`
  width: 350px;
`;

const BodyContainer = styled.div`
  width: 350px;
  height: 150px;
`;

const BodyText = styled.textarea`
  margin-top: 15px;
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-family: "Montserrat";
  width: 100%;
  height: 100%;
  resize: none;
  background-color: white;
  border-bottom: none;
  color: black;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 10px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
`;

const ModalContent = styled.div`
  padding: 35px;
  width: 600px;
  height: 200px;
  overflow-y: auto;
  line-height: 18px;
`;

const StyledButton = styled(Button)`
  :hover {
    animation: none !important;
  }
  @keyframes pulsecolor {
    0% {
      background-color: ${props => props.theme.color.primary};
      border: 1px solid ${props => props.theme.color.primary};
    }
    100% {
      background-color: #2ea5ff;
      border: 1px solid #2ea5ff;
    }
  }
`;

const validEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validName = name => {
  if (typeof name === "string" && /[a-zA-Z-\s]+/g.test(name)) {
    return true;
  }
  return false;
};

const fillUpStateString = async (str, setStateString) => {
  let lastLetterIndex = 1;
  while (lastLetterIndex <= str.length) {
    setStateString(str.substring(0, lastLetterIndex));
    lastLetterIndex++;
    await sleep(40);
  }
};

const startButtonPulsingAnimation = () => {
  const button = document.querySelector("[data-tutorial-id='request-meeting-button'");
  button.style = "position: relative; z-index: 12; animation: pulsecolor 0.5s infinite alternate;";
};

const showClickMeText = () => {
  const button = document.querySelector("[data-tutorial-id='request-meeting-button'");
  const buttonRect = button.getBoundingClientRect();

  const textEl = document.querySelector("[data-tutorial-id='click-me-text'");
  textEl.style = `opacity: 1; top: ${buttonRect.top + 8}px; left: ${buttonRect.right + 10}px;`;
};

const hideClickMeText = () => {
  const textEl = document.querySelector("[data-tutorial-id='click-me-text'");
  textEl.style = `opacity: 0;`;
};

const ClickTextAndArrowContainer = styled.div`
  color: ${props => props.theme.color.furthest};
  position: absolute;
  z-index: 12;
  display: grid;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  grid-template-columns: auto auto;
  gap: 5px;
  opacity: 0;
  transition: opacity 0.5s;
`;

const ClickTextAndArrow = ({ dataTutorialId }) => (
  <ClickTextAndArrowContainer data-tutorial-id={dataTutorialId}>
    <ArrowBack />
    CLICK
  </ClickTextAndArrowContainer>
);

const MeetingRequestViewTutorial = ({ userName }) => {
  const MAX_BODYTEXT_CHAR_LEN = 140;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userProfile, setUserProfile] = useState(null);
  const [bodyText, setBodyText] = useState("");
  const [notification, setNotification] = useState(null);
  const [remainCharacterNumber, setRemainCharacterNumber] = useState(MAX_BODYTEXT_CHAR_LEN);
  const [showErrorMsgModal, setShowErrorMsgModal] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState("");
  const [isBookingMeeting, setIsBookingMeeting] = useState(false);

  useEffect(() => {
    doStartTutorial();
  }, []);

  const doStartTutorial = async () => {
    showTutorialOverlay();
    blurTutorialOverlay();
    await highlightElementByTutorialId("name-input");
    await sleep(500);
    await fillUpStateString("Bill Gates", setName);
    await highlightElementByTutorialId("email-input");
    await sleep(300);
    await fillUpStateString("bill@microsoft.com", setEmail);
    await highlightElementByTutorialId("body-input");
    await sleep(300);
    await fillUpStateString("Are you available for a 20 min meeting tomorrow morning?", setBodyText);
    await sleep(300);
    await highlightElementByTutorialId("request-meeting-button");
    startButtonPulsingAnimation();
    showClickMeText();
  };

  useEffect(() => {
    doFetchUserProfile();
  }, []);

  useEffect(() => {
    setNotification(null);
  }, [name, email, bodyText]);

  const doFetchUserProfile = async () => {
    const { data, error } = await getUserProfileByUserName(userName);
    if (error) {
      return;
    }
    setUserProfile(data);
  };

  const doSendMeetingRequest = async () => {
    hideClickMeText();
    if (!validName(name)) {
      setNotification({ type: "error", message: "You name seems invalid." });
      return;
    }

    if (!validEmail(email)) {
      setNotification({ type: "error", message: "You email address seems invalid." });
      return;
    }

    setIsBookingMeeting(true);
    const { data, error } = await sendMeetingRequest({
      hostUserName: userName,
      requestName: name,
      requestEmail: email,
      bodyText,
    });
    setIsBookingMeeting(false);

    if (error) {
      setModalErrorMsg(error.message);
      setShowErrorMsgModal(true);
      return;
    }

    setNotification({ type: "success", message: "Meeting booked successfully." });
  };

  const userAvatarSrc = userProfile?.image.includes("/images/")
    ? userProfile?.image
    : `data:image/png;base64,${userProfile?.image}`;

  return (
    <Container>
      <ClickTextAndArrow dataTutorialId="click-me-text" />
      <Modal title={"Meeting booking failed"} open={showErrorMsgModal} handleClose={() => setShowErrorMsgModal(false)}>
        <ModalContent>{modalErrorMsg}</ModalContent>
      </Modal>
      <div></div>
      <VerticalLayout>
        <FormPage>
          <FormLeft>
            <FormLeftTop>
              <CompanyLogo src={"/images/boltzbit_logo_h.png"} />
            </FormLeftTop>
            <UserProfileContainer>
              <UserProfile>
                <UserAvatar src={userAvatarSrc} />
                <Name>
                  {userProfile?.firstName} {userProfile?.lastName}
                </Name>
              </UserProfile>
              <ProfessionProfile>Position: {userProfile?.position}</ProfessionProfile>
            </UserProfileContainer>
            <FormLeftBottom>
              <Footer>
                <SignUpContainer>
                  <div>Sign up for Boltzbit AI Calendar</div>
                </SignUpContainer>
              </Footer>
            </FormLeftBottom>
          </FormLeft>
          <FormRight>
            <Title> Meeting Details </Title>
            <Notification type={notification?.type}>
              <NotificationLink href="https://calendar.google.com/calendar/u/0/r/week">
                {notification?.message}
              </NotificationLink>
            </Notification>
            <UserInputContainer>
              <NameContainer>
                <TextInput
                  dataTutorialId="name-input"
                  title={"Name"}
                  placeholder={"Sam Stanley"}
                  isRequired={true}
                  value={name}
                  onNewInput={newVal => {
                    setName(newVal);
                  }}
                />
              </NameContainer>
              <EmailContainer>
                <TextInput
                  dataTutorialId="email-input"
                  title={"Email"}
                  placeholder={"example@domain.com"}
                  isRequired={true}
                  value={email}
                  onNewInput={newVal => {
                    setEmail(newVal);
                  }}
                />
              </EmailContainer>
              <BodyContainer>
                <Subtitle>When and how long would like the meeting ? </Subtitle>
                <p>(limited to 140 characters, {`${remainCharacterNumber} characters left.`})</p>
                <BodyText
                  data-tutorial-id="body-input"
                  placeholder="e.g. can we have a 20 minutes meeting tomorrow after noon ? "
                  value={bodyText}
                  onChange={e => {
                    let newVal = e.target?.value;
                    let remain = MAX_BODYTEXT_CHAR_LEN - newVal.length;
                    if (remain >= 0) {
                      setBodyText(e.target?.value);
                      setRemainCharacterNumber(remain);
                    }
                  }}
                />
              </BodyContainer>
              <Gap height="60px" />
              {isBookingMeeting ? (
                <CircularProgress style={{ zIndex: 12 }} />
              ) : (
                <StyledButton
                  dataTutorialId="request-meeting-button"
                  isSolid={true}
                  value={"Request Meeting"}
                  onClick={doSendMeetingRequest}
                />
              )}
            </UserInputContainer>
          </FormRight>
          <LogoColumn>
            <FormRightTopBanner>
              <a href="https://boltzbit.com">
                <LogoSquare src={"/images/logo-black-no-text.png"} />
              </a>
              <p style={{ fontSize: "11px", fontWeight: "bold" }}>Powered by </p>
              <p style={{ fontSize: "11px", fontWeight: "bold" }}>Boltzbit AI</p>
            </FormRightTopBanner>
          </LogoColumn>
        </FormPage>
      </VerticalLayout>
      <div></div>
    </Container>
  );
};

export default MeetingRequestViewTutorial;
