import { CenteredWithTopNavLayoutExternal } from "components/PackageLayout";
import CreatePackageView from "components/views/CreatePackageView";
import { getLoggedInUserName } from "api/services/authenticationService";
import { Link } from "react-router-dom";

const CreatePackagePage = () => {
  const userName = getLoggedInUserName();

  if (!userName) {
    return (
      <div>
        Please log in first to see the content. <Link to={"/login"}>Click here to login.</Link>
      </div>
    );
  }
  return (
    <CenteredWithTopNavLayoutExternal>
      <CreatePackageView />
    </CenteredWithTopNavLayoutExternal>
  );
};

export default CreatePackagePage;
