import { useState, useEffect } from "react";
import styled from "styled-components";
import Button from "components/ui/Button";
import { getSampleDataPointFromDeployment } from "api/services/deploymentService";
import Modal from "components/ui/Modal";
import TextInput from "components/ui/TextInput";
import CustomCodeMirror from "components/ui/CustomCodeMirror";
import { isEmpty } from "lodash";
import { Gap } from "components/Layout";
import { postApiConfig } from "api/services/projectService";

const ColumnsContainer = styled.div`
  border-radius: 5px;
  background-color: ${props => props.theme.color.furthest};
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  margin: 10px 0;
`;

const InputColumn = styled.div`
  font-weight: bold;
  text-align: left;
  padding: 20px 14px;
  border-radius: 5px;
  cursor: pointer;
  ${props => props.isTarget && `color: ${props.theme.color.primary}; font-size: 18px;`}

  :hover {
    background-color: ${props => props.theme.color.closer1_5};
  }
`;

const Container = styled.div`
  padding: 20px 10px;
  background-color: ${props => props.theme.color.closer1};
`;

const ModalContent = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  ${props => props.greyBground && `background-color: ${props.theme.color.closer0};`};
  padding: 20px;
  width: 500px;
`;

const ApiModal = ({ onClose, onClickAddNewApiConfig, isOpen, inputs, outputs, isDisabled }) => {
  const [apiName, setApiName] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setApiName("");
    }
  }, [isOpen]);

  return (
    <Modal handleClose={onClose} open={isOpen} title={"Define an API"}>
      <ModalContent isDisabled={isDisabled}>
        <TextInput value={apiName} title="name" onNewInput={newVal => setApiName(newVal)} isRequired={true} />
        <Gap />
        <CustomCodeMirror value={JSON.stringify({ inputs, outputs }, null, 4)} height="300px" showLineNumber={false} />
        <Gap />
        <Button
          isDisabled={!apiName}
          value="Add"
          onClick={() => {
            const newApiConfig = {
              name: apiName,
              config: {
                inputs,
                outputs,
              },
            };
            onClickAddNewApiConfig(newApiConfig);
          }}
        ></Button>
      </ModalContent>
    </Modal>
  );
};

const ApiDesignPool = ({
  maskedCols,
  onSetInputMaskedCols,
  inputRow,
  onSetInputRow,
  deploymentClusterId,
  onAddApiConfig,
  pipelineId,
}) => {
  const [inputColumns, setInputColumns] = useState([]);
  const [showApiModal, setShowApiModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    deploymentClusterId && doPopulateInputRow();
  }, [deploymentClusterId]);

  useEffect(() => {
    inputRow && setInputColumns(Object.keys(inputRow));
  }, [inputRow]);

  const doPopulateInputRow = async () => {
    const { data } = await getSampleDataPointFromDeployment(deploymentClusterId);
    onSetInputRow(data);
  };

  return (
    <Container>
      <div>Select columns to predict:</div>
      <ColumnsContainer>
        {inputColumns.map(inputCol => (
          <InputColumn
            key={inputCol}
            onClick={() =>
              maskedCols.includes(inputCol)
                ? onSetInputMaskedCols(maskedCols.filter(col => col !== inputCol))
                : onSetInputMaskedCols([...maskedCols, inputCol])
            }
            isTarget={maskedCols.includes(inputCol)}
          >
            {inputCol}
          </InputColumn>
        ))}
      </ColumnsContainer>
      <Button isDisabled={isEmpty(maskedCols)} value="Add a New Api" onClick={() => setShowApiModal(true)} />
      <ApiModal
        isOpen={showApiModal}
        onClose={() => setShowApiModal(false)}
        inputs={inputColumns.filter(col => !maskedCols.includes(col))}
        outputs={inputColumns.filter(col => maskedCols.includes(col))}
        onClickAddNewApiConfig={async newApiConfig => {
          setIsLoading(true);
          const { data: createdApiConfig } = await postApiConfig({ ...newApiConfig, pipelineId });
          onAddApiConfig(createdApiConfig);

          onSetInputMaskedCols([]);
          setShowApiModal(false);
          setIsLoading(false);
        }}
        isDisabled={isLoading}
      />
    </Container>
  );
};

export default ApiDesignPool;
