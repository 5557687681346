import styled from "styled-components";
import { useState, useEffect } from "react";
import DataInspectionModal from "components/widgets/DataInspectionModal";
import { getDatasetsCreatedByUserName, getAllVisibleDatasets, getDataInfoByDataName } from "api/services/dataService";
import { getLoggedInUserName } from "api/services/authenticationService";
import NavWithTabs from "components/ui/NavWithTabs";

const ContentWrapper = styled.div`
  ${props => props.maxHeight && `max-height: ${props.maxHeight}; overflow-y: scroll;`}
  padding: 20px;
  display: flex;
  color: ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.closer0};
  border: 1px solid ${props => props.theme.color.closer1};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const DatasetList = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const Dataset = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 5px;

  color: ${props => props.isSelected && props.theme.color.furthest};
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.furthest)};

  :hover {
    color: white;
    background-color: ${props => props.theme.color.primary};
  }
`;

const ViewOption = styled.div`
  ${props => !props.isEnabled && "visibility: hidden;"}
  cursor: pointer;
  color: ${props => props.theme.color.primary};
  margin-left: 5px;
  text-decoration: underline;
`;

const DatasetOptionContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DatasetListSelect = ({
  selectedDatasetId,
  onDatasetSelect,
  showEngineSelect = true,
  showViewButtons = true,
  maxHeight,
  onSwitchTab = () => {},
  onFilterDatasets = dataset => true,
}) => {
  const userName = getLoggedInUserName();

  const [selectedTab, setSelectedTab] = useState("Your datasets");

  const [userDatasets, setUserDatasets] = useState([]);
  const [publicDatasets, setPublicDatasets] = useState([]);

  const [isDataInpsectionModalShowing, setIsDataInspectioModalShowing] = useState(false);
  const [datasetToInspect, setDatasetToInspect] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const [goDatasetId, setGoDatasetId] = useState(null);

  const doFetchDatasetOptions = async () => {
    const { data: allDatasets, error: allDatasetsError } = await getAllVisibleDatasets();
    setPublicDatasets(allDatasets?.filter(dataset => dataset.createdBy !== userName && dataset.name !== "go"));
    if (allDatasetsError) {
      setError(allDatasetsError);
      setIsLoading(false);
      return;
    }

    const { data, error } = await getDatasetsCreatedByUserName(userName);
    setError(error);
    setUserDatasets(data?.filter(dataset => dataset.name !== "go"));
    setIsLoading(false);
  };

  const doFetchGoDataset = async () => {
    const { data, error } = await getDataInfoByDataName("go");
    if (error || !data) {
      return;
    }
    setGoDatasetId(data[0]?.id);
  };

  useEffect(() => {
    doFetchDatasetOptions();
    doFetchGoDataset();
  }, []);

  if (isLoading) {
    return <ContentWrapper>Loading datasets...</ContentWrapper>;
  }

  if (error) {
    return JSON.stringify(error);
  }

  const selectedDataset = [...userDatasets, ...publicDatasets].find(dataset => dataset?.id === selectedDatasetId);

  const datasetOptionsUnfiltered = selectedTab === "Your datasets" ? userDatasets : publicDatasets;
  const datasetOptions = datasetOptionsUnfiltered.filter(onFilterDatasets);

  return (
    <>
      <NavWithTabs
        tabNames={
          showEngineSelect ? ["Your datasets", "Public datasets", "Engines"] : ["Your datasets", "Public datasets"]
        }
        selectedTabName={selectedTab}
        onTabSelect={newTab => {
          onSwitchTab();
          setSelectedTab(newTab);
        }}
      />
      <ContentWrapper maxHeight={maxHeight}>
        <DatasetList data-tutorial-id="dataset-list">
          {selectedTab === "Engines" && showEngineSelect && (
            <>
              <DatasetOptionContainer>
                <Dataset
                  isDisabled={goDatasetId}
                  onClick={() => onDatasetSelect({ name: "go", id: goDatasetId })}
                  isSelected={selectedDataset?.name === "go"}
                >
                  Go
                </Dataset>
              </DatasetOptionContainer>
              <DatasetOptionContainer isDisabled>
                <Dataset>Molecule</Dataset>
              </DatasetOptionContainer>
            </>
          )}
          {selectedTab !== "Engines" &&
            datasetOptions.map(ds => (
              <DatasetOptionContainer key={ds.id}>
                <Dataset onClick={() => onDatasetSelect(ds)} isSelected={ds.id === selectedDataset?.id}>
                  {ds.name}
                </Dataset>
                <ViewOption
                  onClick={() => {
                    setDatasetToInspect(ds);
                    setIsDataInspectioModalShowing(true);
                  }}
                  isEnabled={showViewButtons}
                >
                  view
                </ViewOption>
              </DatasetOptionContainer>
            ))}
        </DatasetList>
      </ContentWrapper>
      {datasetToInspect && (
        <DataInspectionModal
          datasetId={datasetToInspect.id}
          open={isDataInpsectionModalShowing}
          onClose={() => setIsDataInspectioModalShowing(false)}
        />
      )}
    </>
  );
};

export default DatasetListSelect;
