import styled from "styled-components";
import { useEffect } from "react";
import { getCustomCodeTemplate } from "api/services/jobService";
import Editor from "@monaco-editor/react";

const Container = styled.div`
  height: 100%;
`;

const CustomizedTrainingEditor = ({ pipelineOutputCustomCode, customCode, onCustomCodeChange }) => {
  useEffect(() => {
    if (!pipelineOutputCustomCode) {
      doFetchCustomCodeTemplate();
    }
  }, [pipelineOutputCustomCode]);

  const doFetchCustomCodeTemplate = async () => {
    const { data, error } = await getCustomCodeTemplate();
    if (error) {
      return;
    }
    onCustomCodeChange(data);
  };

  return (
    <Container data-tutorial-id="customized-training-editor">
      <Editor
        value={customCode}
        height="420px"
        defaultLanguage="python"
        onChange={newVal => onCustomCodeChange(newVal)}
      />
    </Container>
  );
};

export default CustomizedTrainingEditor;
