import { useState } from "react";
import styled from "styled-components";

import MultiOptionSelector from "components/ui/MultiOptionSelector";
import Modal from "components/ui/Modal";
import ApiCode from "components/views/DeploymentTabView/ApiCode";
import ApiMonitor from "components/views/DeploymentTabView/ApiMonitor";
import { Code, Timeline } from "@material-ui/icons";
import ModelViewer from "components/widgets/ModelViewer";

const Container = styled.div`
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.3"};
`;

const ModalContent = styled.div`
  ${props => props.greyBground && `background-color: ${props.theme.color.closer0};`};
  padding: 20px;
  width: 900px;
  height: 600px;
  overflow-y: scroll;
`;

const ModelIconsImg = styled.img`
  display: flex;
`;

const ApiCallAndMonitorsModals = ({
  deploymentClusterId,
  taskInput,
  isEnabled = true,
  trainingJobId,
  tag,
  modelConfig,
  datasetFeatureTypeDescriptors,
  outputCols,
  hideModelButton,
}) => {
  const [isApiCallModalOpen, setIsApiCallModalOpen] = useState(false);
  const [isMonitorModalOpen, setIsMonitorModalOpen] = useState(false);
  const [isModelFlowModalOpen, setIsModelFlowModalOpen] = useState(false);

  const options = [
    { icon: <Code />, value: "code" },
    { icon: <Timeline />, value: "monitor" },
  ];
  if (!hideModelButton) {
    options.push({ icon: <ModelIconsImg src="/images/icons/model.png" />, value: "model-flow" });
  }

  return (
    <Container isEnabled={isEnabled}>
      <MultiOptionSelector
        optionStyle={{ padding: "0 5px" }}
        options={options}
        onOptionSelect={newOption => {
          if (newOption === "code") {
            setIsApiCallModalOpen(true);
          } else if (newOption === "monitor") {
            setIsMonitorModalOpen(true);
          } else {
            setIsModelFlowModalOpen(true);
          }
        }}
      />
      <Modal open={isApiCallModalOpen} handleClose={() => setIsApiCallModalOpen(false)}>
        <ModalContent>
          <ApiCode deploymentClusterId={deploymentClusterId} taskInput={taskInput} tag={tag} />
        </ModalContent>
      </Modal>
      <Modal open={isMonitorModalOpen} handleClose={() => setIsMonitorModalOpen(false)}>
        <ModalContent>
          <ApiMonitor trainingJobId={trainingJobId} tag={tag} />
        </ModalContent>
      </Modal>
      <Modal open={isModelFlowModalOpen} handleClose={() => setIsModelFlowModalOpen(false)}>
        <ModalContent greyBground>
          <ModelViewer
            modelConfig={modelConfig}
            datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
            inputCols={Object.keys(taskInput)}
            outputCols={outputCols}
          />
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default ApiCallAndMonitorsModals;
