import { useState, useEffect } from "react";
import GoogleCalendarButton from "components/ui/GoogleCalendarButton";
import {
  getGoogleCalendarOauthToken,
  googleCalendarLogin,
  createGoogleCalendarEvent,
} from "api/services/authenticationService";

const GoogleCalendarIntegration = ({ platformRedirectUrl, }) => {
  const [googleCalendarToken, setGoogleCalendarToken] = useState(null);
  const [createdEvent, setCreatedEvent] = useState(null);

  const doGetGoogleCalendarToken = async () => {
    const { data, error } = await getGoogleCalendarOauthToken();
    if (error) {
      return;
    }
    setGoogleCalendarToken(data);
  };

  const doCreateAnEvent = async () => {
    const event = {
      startTime: new Date(new Date().setHours(19, 0, 0, 0)).toISOString().split(".")[0] + "Z",
      endTime: new Date(new Date().setHours(20, 0, 0, 0)).toISOString().split(".")[0] + "Z",
      location: "",
      title: "Meeting with Boltzbit",
      description: "An enjoyable one-hour meeting with Boltzbit team",
    };

    const { data, error } = await createGoogleCalendarEvent(event);
    if (error) {
      return;
    }
    setCreatedEvent(data);
  };

  useEffect(() => {
    doGetGoogleCalendarToken();
  }, []);

  const handelBtnClick = async () => {
    if (googleCalendarToken) {
      doCreateAnEvent();
    } else {
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const { data: redirectUrl } = await googleCalendarLogin(`${baseUrl}/oauth-callback/googlecalendar`);
      sessionStorage.setItem("preOauthUrl", platformRedirectUrl);
      window.location = redirectUrl;
    }
  };

  return (
    <>
      <GoogleCalendarButton
        value={googleCalendarToken ? "Create a meeting" : "Authorize integration"}
        onClick={handelBtnClick}
      />
      {createdEvent && <div>{"Congratulations, you have set a meeting with Boltzbit tonight"}</div>}
    </>
  );
};

export default GoogleCalendarIntegration;
