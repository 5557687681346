import { useState } from "react";
import styled from "styled-components";

import { synthesizeImageLabelDataPoint } from "api/services/deploymentService";
import Button from "components/ui/Button";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";
import ImageTrueSize from "components/widgets/ImageTrueSize";

const SynthButton = styled(Button)`
  margin: 0;
  padding: 5px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const GeneratedImgLoading = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${props => props.theme.color.closer0};
`;

const PredictedLabel = styled.div`
  padding: 10px;
  font-size: 18px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const ImgAndLabel = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const DataSynthesis = ({
  deploymentClusterId,
  isSelected,
  onSelect,
  trainingJobId,
  modelConfig,
  datasetFeatureTypeDescriptors,
}) => {
  const [synthesisResult, setSynthesisResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doSynthesizeDataPoint = async () => {
    setIsFetchingPrediction(true);
    const { data, error } = await synthesizeImageLabelDataPoint(deploymentClusterId, "synthesize_image_and_label");
    setSynthesisResult(data);
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} readOnly />
      <TitleText>Data synthesis</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{}}
        outputCols={["image", "label"]}
        isEnabled={isSelected}
        trainingJobId={trainingJobId}
        tag="synthesize_image_and_label"
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
      />
    </LabelAndModals>
  );

  if (!synthesisResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <SynthButton onClick={doSynthesizeDataPoint} value="Synthesize" />
          <ImgAndLabel>
            <GeneratedImgLoading />
            <PredictedLabel>&nbsp;&nbsp;</PredictedLabel>
          </ImgAndLabel>
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <SynthButton value="Synthesize" onClick={doSynthesizeDataPoint} />
        <ImgAndLabel>
          <ImageTrueSize base64Data={synthesisResult?.image} />
          <PredictedLabel>{synthesisResult?.label?.winner}</PredictedLabel>
        </ImgAndLabel>
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default DataSynthesis;
