import styled from "styled-components";
import { PowerSettingsNew } from "@material-ui/icons";

const TopBarContainer = styled.div`
  min-height: 36px;
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.3;"}
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.closer1};
  justify-content: space-between;
  padding: 0px 10px;
`;

const HoverButtonContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 0px solid ${props => props.theme.color.closest};
  border-radius: 60px;

  .MuiSvgIcon-root {
    fill: ${props => props.theme.color.closest};
    height: 24px;
    transition: none;
  }

  :hover {
    border: 0px solid ${props => props.theme.color.primary};
    .MuiSvgIcon-root {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const RightSideButtons = styled.div`
  display: flex;
`;

const OnOffText = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 10px;
`;

const StatusText = styled.div``;

const NotebookTopBar = ({ isEnabled, isNotebookActive, onClickTurnOn, onClickTurnOff }) => {
  return (
    <TopBarContainer isEnabled={isEnabled}>
      <StatusText>{!isNotebookActive && "Start the notebook."}</StatusText>
      <RightSideButtons>
        <HoverButtonContainer onClick={isNotebookActive ? onClickTurnOff : onClickTurnOn}>
          <PowerSettingsNew />
        </HoverButtonContainer>
        <OnOffText>STATUS: {isNotebookActive ? "ON" : "OFF"}</OnOffText>
      </RightSideButtons>
    </TopBarContainer>
  );
};

export default NotebookTopBar;
