import { Area, ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { round } from "lodash";

import { lightTheme, darkTheme, chartColours } from "App";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .recharts-cartesian-grid {
    line {
      stroke: ${props => props.theme.color.closer1};
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: ${props => props.theme.color.closest};
    }
  }

  .legend-item-1 {
    display: none !important;
  }
`;

const TooltipContainer = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 10px;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
`;

const TooltipText = styled.tr`
  color: ${props => props.color};
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <div>{label}</div>
        <TooltipText color={payload[0].stroke}>
          <td>{payload[0].name}:&nbsp;&nbsp;</td>
          <td>{round(payload[0].value, 3)}</td>
        </TooltipText>
        <TooltipText color={payload[2].stroke}>
          <td>{payload[2].name}:&nbsp;&nbsp;</td>
          <td>{round(payload[2].value, 3)}</td>
        </TooltipText>
      </TooltipContainer>
    );
  }

  return null;
};

const SequenceForecastChart = ({
  data,
  title,
  groundTruthKey = "groundTruth",
  forecastKeys = [],
  sequenceIntervalKey = "date",
  cuttingPoint,
}) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";
  const textColor = isDarkTheme ? darkTheme.color.closest : lightTheme.color.closest;

  if (cuttingPoint !== "no forecasting") {
    const processedData = data.map(e => {
      if (e[sequenceIntervalKey] < cuttingPoint) {
        forecastKeys.forEach(key => delete e[key]);
      }
      return e;
    });
    return (
      <Container>
        <h3>{title}</h3>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={processedData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="10 10" />
            <XAxis
              dataKey={sequenceIntervalKey}
              label={{
                stroke: textColor,
                value: "Sequence Range",
                position: "bottomCenter",
                dy: 25,
              }}
            />
            <YAxis
              label={{
                stroke: textColor,
                value: "Value",
                position: "center",
                angle: -90,
                dx: -20,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            <Line
              type="monotone"
              dataKey={groundTruthKey}
              name="Ground Truth"
              stroke="#9650ff"
              activeDot={{ r: 8 }}
              strokeWidth={1}
              dot={false}
            />
            {forecastKeys.map((key, i) => (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                name={key}
                stroke={chartColours[i]}
                strokeWidth={2}
                activeDot={{ r: 8 }}
                dot={false}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </Container>
    );
  } else {
    return (
      <Container>
        <h3>{title}</h3>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 20,
            }}
          >
            <CartesianGrid strokeDasharray="10 10" />
            <XAxis
              dataKey={sequenceIntervalKey}
              label={{
                stroke: textColor,
                value: "Sequence Range",
                position: "bottomCenter",
                dy: 25,
              }}
              tickMargin={10}
            />
            <YAxis
              label={{
                stroke: textColor,
                value: "Value",
                position: "center",
                angle: -90,
                dx: -32,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" />
            <Line
              type="monotone"
              dataKey={groundTruthKey}
              name="Ground Truth"
              stroke="#9650ff"
              activeDot={{ r: 8 }}
              strokeWidth={1}
              dot={false}
            />
            <Area type="monotone" dataKey="forecast_1_error_range" stroke="none" fill="#1f76b45f" />
            {forecastKeys.map((key, i) => (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                name={key}
                stroke={chartColours[i]}
                strokeWidth={2}
                activeDot={{ r: 8 }}
                dot={false}
              />
            ))}
          </ComposedChart>
        </ResponsiveContainer>
      </Container>
    );
  }
};

export default SequenceForecastChart;
