import { useState, useEffect } from "react";
import styled from "styled-components";

import Button from "components/ui/Button";
import TextInput from "components/ui/TextInput";

import {
  getConnectedGithubAccountForLoggedInUser,
  connectGithubAccount,
  patchGithubAccount,
} from "api/services/githubService";
import { GitHub } from "@material-ui/icons";

const BoldText = styled.div`
  font-weight: bold;
`;

const SmallButton = styled(Button)`
  min-height: 0;
  min-width: 0;
  padding: 4px 10px;
  margin: 0;
`;

const EditButton = styled(SmallButton)`
  opacity: 0;
  transition: opacity 0.2s;
`;

const EditingContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
  max-width: 400px;
`;

const HowToLink = styled.a`
  grid-column: span 2;
`;

const GhTextInput = styled(TextInput)`
  grid-column: span 2;
`;

const AccountConnected = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  border-radius: 20px;
  width: max-content;
  color: ${props => props.theme.color.closest};
  cursor: pointer;

  :hover {
    background-color: ${props => (props.noBgroundHover ? "inherit" : props.theme.color.closer1)};
    ${EditButton} {
      opacity: 1;
    }
  }
`;

const ConnectButton = styled(Button)`
  svg {
    height: 18px;
  }
`;

const GithubAccountIntegration = () => {
  const [connectedGithubUserName, setConnectedGithubUserName] = useState("");

  const [isEditing, setIsEditing] = useState(false);
  const [newGithubUserName, setNewGithubUserName] = useState("");
  const [githubToken, setGithubToken] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    doFetchConnectedUserName();
  }, []);

  const doFetchConnectedUserName = async () => {
    setIsLoading(true);
    const { data } = await getConnectedGithubAccountForLoggedInUser();

    setConnectedGithubUserName(data?.githubAccount);
    setNewGithubUserName(data?.githubAccount);
    setGithubToken(data?.githubToken);
    setIsLoading(false);
  };

  const doConnectGithubAccount = async () => {
    setIsLoading(true);

    let data, error;
    if (!connectedGithubUserName) {
      ({ data, error } = await connectGithubAccount(newGithubUserName, githubToken));
    } else {
      ({ data, error } = await patchGithubAccount(newGithubUserName, githubToken));
    }

    setError(error);
    setConnectedGithubUserName(data?.githubAccount);
    setNewGithubUserName(data?.githubAccount);
    setGithubToken(data?.githubToken);
    setIsEditing(false);
    setIsLoading(false);
  };

  if (isLoading) {
    return "Loading...";
  }

  if (error?.message) {
    return error.message;
  }

  if (connectedGithubUserName && !isEditing) {
    return (
      <AccountConnected>
        <GitHub />
        <BoldText>{connectedGithubUserName}</BoldText>
        <EditButton value="Edit" onClick={() => setIsEditing(true)} />
      </AccountConnected>
    );
  }

  if (isEditing) {
    return (
      <EditingContainer>
        <GhTextInput
          title={"account"}
          value={newGithubUserName}
          onNewInput={newUserName => setNewGithubUserName(newUserName)}
        />
        <GhTextInput title={"token"} value={githubToken} onNewInput={newToken => setGithubToken(newToken)} />
        <HowToLink
          href="https://docs.github.com/en/authentication/keeping-your-account-and-data-secure/creating-a-personal-access-token"
          target="_blank"
        >
          how to get a Github token ?
        </HowToLink>
        <Button onClick={() => doConnectGithubAccount(newGithubUserName, githubToken)} value="Submit" />
        <Button onClick={() => setIsEditing(false)} value="Cancel" />
      </EditingContainer>
    );
  }

  return (
    <AccountConnected noBgroundHover>
      <ConnectButton icon={<GitHub />} onClick={() => setIsEditing(true)} value="Connect" variant="highlighted" />
    </AccountConnected>
  );
};

export default GithubAccountIntegration;
