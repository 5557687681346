import { useState } from "react";
import { CenteredWithTopNavLayout, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import ApplicationCard from "components/ui/ApplicationCard";
import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";
import TextInput from "components/ui/TextInput";
import { by } from "utils/common";
import { getLoggedInUserName } from "api/services/authenticationService";

const HARDCODED_SOLUTIONS = [
  {
    id: "book_search",
    name: "Book search",
    createdAt: "Mar 13,2022 6:02:23 PM",
    createdBy: "bz_bot",
  },
  {
    id: 0,
    name: "Customer Feedback Assistant",
    createdAt: "Mar 13,2022 6:02:23 PM",
    createdBy: "bz_bot",
  },
  {
    id: 1,
    name: "Calendar AI",
    createdAt: "Created at: Mar 12,2022 2:23:12 PM",
    createdBy: "bz_bot",
  },
  {
    id: 2,
    name: "Explainable Image Labelling",
    createdAt: "Created at: Mar 10,2022 9:27:44 AM",
    createdBy: "bz_bot",
  },
  {
    id: 3,
    name: "Text to Code Generation",
    createdAt: "Created at: Mar 09,2022 4:11:23 PM",
    createdBy: "bz_bot",
  },
  {
    id: 4,
    name: "Go Game Bot",
    createdAt: "Created at: Mar 09,2022 9:38:56 AM",
    createdBy: "bz_bot",
  },
  {
    id: 5,
    name: "Molecule Generation",
    createdAt: "Created at: Mar 09,2022 9:38:56 AM",
    createdBy: "bz_bot",
  },
  {
    id: 6,
    name: "Credit Risk Data Analytics",
    createdAt: "Created at: Mar 09,2022 9:38:56 AM",
    createdBy: "bz_bot",
  },
  {
    id: 7,
    name: "OCR Solution",
    createdAt: "Created at: Jun 004,2022 12:43:23 AM",
    createdBy: "bz_bot",
  },
];

const NOT_LOGGED_IN_SOLUTIONS = [
  {
    id: 0,
    name: "Customer Feedback Assistant",
    createdAt: "Mar 13,2022 6:02:23 PM",
    createdBy: "bz_bot",
  },
  {
    id: 1,
    name: "Calendar AI",
    createdAt: "Created at: Mar 12,2022 2:23:12 PM",
    createdBy: "bz_bot",
  },
  {
    id: 2,
    name: "Explainable Image Labelling",
    createdAt: "Created at: Mar 10,2022 9:27:44 AM",
    createdBy: "bz_bot",
  },
  {
    id: 7,
    name: "OCR Solution",
    createdAt: "Created at: Jun 004,2022 12:43:23 AM",
    createdBy: "bz_bot",
  },
];

const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: inherit;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const TextInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  padding: 10px;
  width: max-content;
`;

const FilterTagsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const FilterNameInput = styled(TextInput)`
  padding: 8px 15px;
`;

const DemosPage = () => {
  const [filterBy, setFilterBy] = useState("");

  const userName = getLoggedInUserName();

  return userName ? (
    <CenteredWithTopNavLayout>
      <FilterTagsContainer>
        <TextInputContainer>
          <div>Filter name:</div>
          <FilterNameInput value={filterBy} onNewInput={newFilterBy => setFilterBy(newFilterBy)} />
        </TextInputContainer>
      </FilterTagsContainer>

      <CardsContainer>
        {HARDCODED_SOLUTIONS.filter(solution => solution?.name?.toLowerCase().includes(filterBy.toLowerCase()))
          .filter(solution => solution.createdBy === getLoggedInUserName() || solution.createdBy === "bz_bot")
          .sort(by("createdAt", "desc"))
          .map(solution => {
            return (
              <Link key={solution.id} to={`/applications/${solution.id}`}>
                <ApplicationCard
                  assetType="application"
                  key={solution.name}
                  title={solution.name}
                  subTitle={`Created at: ${solution.createdAt}`}
                  createdByUserName={solution.createdBy}
                />
              </Link>
            );
          })}
      </CardsContainer>
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <CardsContainer>
        {NOT_LOGGED_IN_SOLUTIONS.filter(solution => solution?.name?.toLowerCase().includes(filterBy.toLowerCase()))
          .filter(solution => solution.createdBy === getLoggedInUserName() || solution.createdBy === "bz_bot")
          .sort(by("createdAt", "desc"))
          .map(solution => {
            return (
              <Link key={solution.id} to={`/applications/${solution.id}`}>
                <ApplicationCard
                  assetType="application"
                  key={solution.name}
                  title={solution.name}
                  subTitle={`Created at: ${solution.createdAt}`}
                  createdByUserName={solution.createdBy}
                  viewByLoggedInUser={false}
                />
              </Link>
            );
          })}
      </CardsContainer>
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default DemosPage;
