import styled from "styled-components";
import { useEffect, useState } from "react";
import { getTrainingExamples } from "api/services/projectService";
import { round } from "lodash";

const Container = styled.div`
  padding: 20px;
  overflow-x: scroll;
`;

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 20px 14px;
  border-bottom: 1px solid ${props => props.theme.color.darkGrey};
`;

const ScoreTh = styled(Th)`
  color: ${props => props.theme.color.primary};
`;

const Td = styled.td`
  color: ${props => (props.isNull ? props.theme.color.closer1 : props.theme.color.closest)};
  padding: 14px 14px;
  border-bottom: 1px solid ${props => props.theme.color.closest};
`;

const ScoreTd = styled(Td)`
  color: ${props => `rgb(${(1 - props.score) * 255}, ${props.score * 200}, 0)`};
`;

const TrainingExamplesView = ({ trainingJobId, datasetId }) => {
  const [examples, setExamples] = useState([]);

  useEffect(() => {
    doPopulateExamples();
  }, [trainingJobId, datasetId]);

  const doPopulateExamples = async () => {
    const { data } = await getTrainingExamples(datasetId);
    setExamples(data);
  };

  const pointWithMostColumns =
    examples?.length &&
    examples?.reduce((prevPoint, currentPoint) => {
      return Object.keys(currentPoint).length >= Object.keys(prevPoint).length ? currentPoint : prevPoint;
    });
  const columnNames = Object.keys(pointWithMostColumns || {});

  return (
    <Container>
      <table>
        <thead>
          <tr>
            <ScoreTh>Score</ScoreTh>
            {columnNames && columnNames.map(columnName => <Th key={columnName}>{columnName}</Th>)}
          </tr>
        </thead>
        <tbody>
          {examples?.map((dataPoint, rowIndex) => {
            const score = round(Math.random(), 2);

            return (
              <tr key={rowIndex}>
                <ScoreTd score={score}>{score}</ScoreTd>
                {columnNames.map((columnName, colIndex) => {
                  const val = Array.isArray(dataPoint[columnName])
                    ? dataPoint[columnName].join(" ")
                    : dataPoint[columnName];
                  return (
                    <Td isNull={val === null} key={`${rowIndex}-${val}-${colIndex}`}>
                      {val === null ? "null" : val}
                    </Td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default TrainingExamplesView;
