import styled from "styled-components";
import { Link } from "react-router-dom";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import { useState } from "react";
import Button from "components/ui/Button";
import { getEmails } from "api/services/market-place/calendar";
import { getLoggedInUserName, demoUserSendMeetingRequestEmail } from "api/services/authenticationService";
import TestEmailNotifyModal from "./TestEmailNotifyModal.js";
import { isEmpty } from "lodash";
import { CircularProgress } from "@material-ui/core";

const StyledOl = styled.ol`
  list-style: none;
  counter-reset: item;
`;

const StyledLi = styled.li`
  counter-increment: item;
  margin-bottom: 5px;
  padding-top: 10px;
  margin-left: 40px;
  font-weight: bold;

  :before {
    margin-right: 10px;
    content: counter(item);
    background: ${props => props.theme.color.closest};
    border-radius: 50%;
    color: ${props => props.theme.color.furthest};
    width: 1.7em;
    height: 1.7em;
    font-size: 12px;
    margin-left: -40px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
  }
`;

const UnordedStyledLi = styled.li`
  margin-bottom: 5px;
  padding-top: 10px;
  margin-left: 40px;
  font-weight: bold;

  :before {
    margin-right: 10px;
    background: ${props => props.theme.color.closest};
    border-radius: 50%;
    color: ${props => props.theme.color.furthest};
    width: 1em;
    height: 1em;
    font-size: 12px;
    margin-left: -40px;
    font-weight: bold;
    text-align: center;
    display: inline-block;
  }
`;

const TutorialNarative = styled.div`
  display: flex;
  align-items: center;
  height: 120px;
  overflow-y: auto;
`;
const NoteParagraph = styled.p`
  font-style: italic;
`;

const ScreenshotContainer = styled.div`
  width: auto;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  font-weight: normal;
`;

const ScreenshotImgs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
`;

const ScreenshotImg = styled.img`
  width: 300px;
  height: auto;
  border-radius: 3px;
  box-shadow: ${props => props.theme.color.closest} 0px 5px 15px;
`;

const TutorialBlock = styled.div`
  width: 90%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

const TutorialArrowIcon = styled.div`
  svg {
    font-size: 45px;
    font-weight: bold;
  }
`;

const Documentation = ({ userProfile }) => {
  const userName = getLoggedInUserName();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [showTestEmailNotifyModal, setShowTestEmailNotifyModal] = useState(false);
  const [testEmailNotifyType, setTestEmailNotifyType] = useState("success");

  const doSendAMeetingRequestEmail = async () => {
    let emailBody = `Hi ${userName}, \n\nLet's book a 1 hour meeting tomorrow at 7pm.\n\nwarm regards\n\nBoltzbit calendar team`;
    setIsSendingEmail(true);
    const { error: requestError } = await demoUserSendMeetingRequestEmail({
      from: "me",
      to: [userProfile?.email],
      subject: "shall we have a meeting ?",
      bodyText: emailBody,
    });
    setIsSendingEmail(false);
    if (requestError) {
      setTestEmailNotifyType("fail");
    } else {
      setTestEmailNotifyType("success");
    }
  };

  return (
    <>
      <h2>Set up</h2>
      <TestEmailNotifyModal
        open={showTestEmailNotifyModal}
        handleClose={() => setShowTestEmailNotifyModal(false)}
        email={userProfile?.email}
        type={testEmailNotifyType}
      />
      <StyledOl>
        <StyledLi>
          Enable the Application by connecting Google Calendar
          <TutorialBlock>
            <ScreenshotContainer>
              <TutorialNarative>
                <div>
                  Click [Enable] above and you will be asked to bind with a Google Calendar account. Processed the
                  authorization process the second step is to provide your contact Email addresses.
                </div>
              </TutorialNarative>
              <ScreenshotImg src={`/images/market-place/documentation/google_calendar_authorize.gif`} />
            </ScreenshotContainer>
            <ScreenshotContainer>
              <TutorialNarative></TutorialNarative>
              {/* <TutorialArrowIcon>
                <ArrowForwardOutlinedIcon />
              </TutorialArrowIcon> */}
            </ScreenshotContainer>
            <ScreenshotContainer>
              <TutorialNarative>
                If you are a logged-in user, you can add more contact Email addresses on a pop-up windown.
              </TutorialNarative>
              <ScreenshotImg src={`/images/market-place/documentation/loggedin_user_email_config.png`} />
            </ScreenshotContainer>
            {/* <ScreenshotContainer>
              <TutorialNarative></TutorialNarative>
              <TutorialArrowIcon>
                <ArrowForwardOutlinedIcon />
              </TutorialArrowIcon>
            </ScreenshotContainer> */}
            <ScreenshotContainer>
              <TutorialNarative>
                If you are a guest, you can register an account with your name and primary contact Email.
              </TutorialNarative>
              <ScreenshotImg src={`/images/market-place/documentation/guest_email_config.png`} />
            </ScreenshotContainer>
          </TutorialBlock>
        </StyledLi>
        <StyledLi>
          Receive and Forward Meeting Request Emails
          <TutorialBlock>
            <ScreenshotContainer>
              <TutorialNarative style={{ width: "430px", height: "160px" }}>
                <div>
                  When receiving a meeting request Email, forward it to calendar@boltzbit.com.
                  {userProfile ? (
                    <div style={{ padding: "15px", borderRadius: "5px", border: "1px dashed", marginTop: "15px" }}>
                      <NoteParagraph>Click the following button to generate a request Email.</NoteParagraph>{" "}
                      {isSendingEmail ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          value={"Try it"}
                          onClick={() => {
                            doSendAMeetingRequestEmail();
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </TutorialNarative>
              <ScreenshotImg src={`/images/market-place/documentation/email_forward.png`} />
            </ScreenshotContainer>
          </TutorialBlock>
        </StyledLi>
        <StyledLi>
          Meeting booked
          <TutorialBlock>
            <ScreenshotContainer>
              <div>
                {"You should receive an reply from "}
                <b>{"calendar@boltzbit.com"}.</b>
              </div>
              <ScreenshotImgs>
                <ScreenshotImg src={`/images/market-place/documentation/booking_confirmation.png`} />
                <ScreenshotImg src={`/images/market-place/documentation/calendar_booking_confirmation.png`} />
              </ScreenshotImgs>
            </ScreenshotContainer>
          </TutorialBlock>
        </StyledLi>
      </StyledOl>
      <h2>Config Admin</h2>
      <ul>
        <UnordedStyledLi>
          Mangae Your Emails
          <TutorialBlock>
            <ScreenshotContainer>
              <div>
                You can manage your contact Email addresses at{" "}
                <Link to={"/market-place/calendar?tab=Setting"}>Setting</Link>.
              </div>
              <ScreenshotImg src={`/images/market-place/documentation/email_setting.png`} />
            </ScreenshotContainer>
          </TutorialBlock>
        </UnordedStyledLi>
      </ul>
    </>
  );
};

export default Documentation;
