import { useState } from "react";

import { postPaymentSession } from "api/services/projectService";
import { CenteredWithTopNavLayout } from "components/Layout";
import Button from "components/ui/Button";
import { getLoggedInUserName } from "api/services/authenticationService";

const baseUrl = `${window.location.protocol}//${window.location.host}`;

const PaymentPage = () => {
  const [isLoading, setisLoading] = useState(false);
  const [error, setError] = useState(false);

  const userName = getLoggedInUserName();

  return (
    <CenteredWithTopNavLayout>
      <Button
        isDisabled={isLoading}
        value={isLoading ? "Loading..." : "Subscribe"}
        onClick={async () => {
          setisLoading(true);
          const { data, error } = await postPaymentSession({
            amount: 5000,
            successUrl: `${baseUrl}/`,
            cancelUrl: `${baseUrl}/${userName}/payment`,
          });
          if (error) {
            setisLoading(false);
            setError(error);
            return;
          }

          window.location = data.paymentUrl;
        }}
      />
      {error && JSON.stringify(error)}
    </CenteredWithTopNavLayout>
  );
};

export default PaymentPage;
