import { useState } from "react";
import styled from "styled-components";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { useEffect } from "react";
import { getFirstDeploymentOfModel, postDeploymentPrediction } from "api/services/deploymentService";
import TextArea from "components/ui/TextArea";
import Editor from "@monaco-editor/react";
import SelectInput from "components/ui/SelectInput";
import { ArrowUpward } from "@material-ui/icons";

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding: 10px;
  height: 600px;
  width: 800px;
  display: grid;
  grid-template-rows: 1fr auto 320px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "input input input"
    "copy-to-input-button output-type-select send-button"
    "output output output";

  justify-items: end;
  grid-auto-flow: column;
  gap: 20px;
`;

const TriggerButton = styled(Button)`
  padding: 5px 20px;
`;

const CopyToInputButton = styled(Button)`
  grid-area: copy-to-input-button;
  padding: 5px;
  height: max-content;
  align-self: center;
`;

const SendButton = styled(Button)`
  grid-area: send-button;
`;

const InputTextArea = styled(TextArea)`
  grid-area: input;
  height: 100%;
  width: 100%;
`;

const ResponseViewerText = styled.div`
  grid-area: output;
  width: 100%;
  border: 1px solid ${props => props.theme.color.closer1_5};
  padding: 10px;
  color: ${props => props.theme.color.primary};
  white-space: pre-wrap;

  .input-span {
    color: ${props => props.theme.color.closest};
  }
`;

const ResponseViewerCodeContainer = styled.div`
  grid-area: output;
  border: 1px solid ${props => props.theme.color.closer1_5};
  width: 100%;
`;

const ResponseViewerCode = styled(Editor)``;

const OutputTypeSelect = styled(SelectInput)`
  grid-area: output-type-select;
`;

const TryDeploymentModal = ({ modelId, inputName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deployment, setDeployment] = useState(null);

  const [textInput, setTextInput] = useState("");
  const [textInputToHighlight, setTextInputToHighlight] = useState("");
  const [isPostingPrediction, setIsPostingPrediction] = useState(false);
  const [generatedText, setGeneratedText] = useState("");

  const [outputType, setOutputType] = useState("text");

  useEffect(() => {
    if (!modelId) return;

    doPopulateDeploymentId();
  }, [modelId]);

  const doPopulateDeploymentId = async () => {
    const { data: deployment } = await getFirstDeploymentOfModel(modelId);
    setDeployment(deployment);
  };

  const doPopulatePredictionOutput = async () => {
    setGeneratedText("");
    setIsPostingPrediction(true);
    const { data } = await postDeploymentPrediction({
      deploymentId: deployment.id,
      inputs: [{ [inputName]: textInput }],
    });
    setGeneratedText(data?.results?.[0]?.generated_text);
    setTextInputToHighlight(textInput);
    setIsPostingPrediction(false);
  };

  if (!deployment?.id) {
    return (
      <>
        <TriggerButton value="Try it" variant="highlighted" onClick={() => setIsOpen(true)} />
        <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
          <ModalContent>Loading...</ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <>
      <TriggerButton value="Try it" variant="highlighted" onClick={() => setIsOpen(true)} />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          <InputTextArea value={textInput} onNewInput={newTextInput => setTextInput(newTextInput)} />
          <CopyToInputButton icon={<ArrowUpward />} onClick={() => setTextInput(generatedText)} />
          <OutputTypeSelect value={outputType} onSetNewValue={newValue => setOutputType(newValue)}>
            <option value="text">text</option>
            <option value="code">code</option>
          </OutputTypeSelect>
          <SendButton
            isDisabled={isPostingPrediction}
            onClick={doPopulatePredictionOutput}
            value="Send"
            variant="highlighted"
          />
          {outputType === "text" && (
            <ResponseViewerText
              dangerouslySetInnerHTML={{
                __html: generatedText?.replace(
                  textInputToHighlight,
                  `<span class="input-span">${textInputToHighlight}</span>`
                ),
              }}
            />
          )}
          {outputType === "code" && (
            <ResponseViewerCodeContainer>
              <ResponseViewerCode value={generatedText || ""} language="javascript" options={{ readOnly: true }} />
            </ResponseViewerCodeContainer>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default TryDeploymentModal;
