import styled from "styled-components";
import { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { postModelRecommendation } from "api/services/modelService";
import { patchPipelineConfig, postPipelineConfig } from "api/services/projectService";

import useClickOutside from "hooks/useClickOutside";
import { BigTitle } from "components/ui/Text";
import Dropdown from "components/ui/Dropdown";
import Button from "components/ui/Button";
import { sleep } from "utils/common";
import ProgressBar from "components/ui/ProgressBar";

import Editor from "@monaco-editor/react";
import { useEffect } from "react";
import { PYTHON_CODE } from "api/fixtures";
import WordDoc from "components/WordDoc";

const OuterContainer = styled.div`
  position: absolute;
  top: 60px;
  left: 175px;
  width: calc(100% - 175px);
  background-color: ${props => props.theme.color.furthest}FF;

  display: grid;
  padding-top: 40px;
  justify-content: center;
`;

const Container = styled.div`
  padding: 20px;
  width: 1000px;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  border-radius: 5px;

  display: grid;
  gap: 10px;
  grid-auto-flow: row;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const TextArea = styled.div`
  position: relative;
  border: 1px solid ${props => props.theme.color.closer1_5};
  border-radius: 0;
  padding: 8px;
  resize: none;
  width: 100%;
  height: 150px;
  font-family: "Montserrat", sans-serif;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const BlueSpan = styled.span`
  color: ${props => props.theme.color.primary};
`;

const ColumnsDropdown = styled.div`
  position: absolute;
  top: 28px;
  left: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 15px;
  justify-self: end;
`;

const Message = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.color.primary};
`;

const Code = styled.div`
  position: relative;
  opacity: 0.7;
`;

const CodeOverlay = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.08);
`;

const CornerRect = styled.div`
  position: absolute;
  z-index: 1;
  width: 4px;
  height: 4px;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const AnimatedContent = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  ${props => props.isVisible && "opacity: 1;"}

  display: grid;
  gap: 14px;
`;

const StyledProgressBar = styled(ProgressBar)`
  height: 10px;
`;

const CONTROL_KEYS = ["ArrowUp", "ArrowDown", "Enter"];

const MESSAGES = [
  "Generating code...",
  "Generating code...",
  "Preparing dataset...",
  "Running checks...",
  "Starting training...",
  "Almost there...",
  "Redirecting you to your AI...",
];

const PROGRESS_VALUES = [0, 0.1, 0.2, 0.5, 0.7, 0.8, 1];

const ModelRecommendationFromPromptAnimated = ({
  datasetName = "",
  featureTypeDescriptors = [],
  pipelineConfig = {},
}) => {
  const navigate = useNavigate();

  const [dropdownCoords, setDropdownCoords] = useState({ left: null });
  const [isCreatingPipeline, setIsCreatingPipeline] = useState(false);
  const [error, setError] = useState(null);

  const dropdownContainerRef = useRef(null);
  const promptSpanRef = useRef(null);

  useClickOutside(dropdownContainerRef, () => setDropdownCoords({ left: null }));

  const [codeIndex, setCodeIndex] = useState(0);
  const [progressIndex, setProgresIndex] = useState(0);

  useEffect(() => {
    if (progressIndex >= PROGRESS_VALUES.length - 1) {
      doGetModelRecommendationAndPostPipeline();
      return;
    }

    if (progressIndex === 0) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setProgresIndex(prev => prev + 1);
    }, 10);

    return () => clearTimeout(timeoutId);
  }, [progressIndex]);

  useEffect(() => {
    if (codeIndex === 0 || codeIndex >= PYTHON_CODE.length) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setCodeIndex(prev => prev + 5);
    }, 20);

    return () => clearTimeout(timeoutId);
  }, [codeIndex]);

  const onKeyDown = e => {
    if (e.key === "Tab") {
      e.preventDefault();
      const rect = e.target.getBoundingClientRect();
      setDropdownCoords({ left: rect.width + 8 });
      return;
    }

    if (CONTROL_KEYS.includes(e.key)) {
      e.preventDefault();
      return;
    }

    setDropdownCoords({ left: null });
  };

  const doStartAnimation = async () => {
    await sleep(200);
    setIsCreatingPipeline(true);
    setCodeIndex(1);
    setProgresIndex(1);
  };

  const doGetModelRecommendationAndPostPipeline = async () => {
    setError(null);
    setIsCreatingPipeline(true);

    const { data: modelConfig, error } = await postModelRecommendation({
      prompt: promptSpanRef.current.innerHTML,
      featureTypeDescriptors,
    });
    if (error) {
      setError(error);
      return;
    }

    const newPipelineConfig = { ...pipelineConfig, modelConfig, status: "DRAFT" };
    const { data: dbPipelineConfig, error: pipelineSubmitErr } = await postPipelineConfig(newPipelineConfig);
    if (pipelineSubmitErr) {
      setError(pipelineSubmitErr);
      return;
    }

    await sleep(1000);
    const { error: pipelinePatchErr } = await patchPipelineConfig(dbPipelineConfig?.id, {
      status: "SUBMITTED",
    });
    if (pipelinePatchErr) {
      setError(pipelinePatchErr);
      return;
    }

    navigate(`?tab=Model`);
    window.location.reload();
  };

  const { left } = dropdownCoords;

  return (
    <OuterContainer>
      <Container>
        <BigTitle>
          Create cube with <BlueSpan>"{datasetName}"</BlueSpan>
        </BigTitle>
        {/* <WordDoc /> */}
        <TextArea isDisabled={isCreatingPipeline}>
          <span ref={promptSpanRef} onKeyDown={onKeyDown} contentEditable suppressContentEditableWarning>
            Using dataset "{datasetName}", I want to predict
          </span>
          {left !== null && (
            <ColumnsDropdown ref={dropdownContainerRef} style={{ left }}>
              <Dropdown
                options={[
                  { value: "COLUMNS", isCategory: true },
                  ...featureTypeDescriptors.map(descriptor => ({ value: descriptor.key })),
                  { value: "ARCHITECTURES", isCategory: true },
                  { value: "BERT" },
                  { value: "GPT" },
                ]}
                onOptionSelect={option => {
                  const isSpaceAtEnd = promptSpanRef.current.innerHTML.endsWith("&nbsp;");
                  promptSpanRef.current.innerHTML += isSpaceAtEnd ? option.value : ` ${option.value}`;

                  promptSpanRef.current.focus();
                  document.execCommand("selectAll", false, null);
                  document.getSelection().collapseToEnd();

                  setDropdownCoords({ left: null });
                }}
              />
            </ColumnsDropdown>
          )}
        </TextArea>
        <StyledButton
          onClick={doStartAnimation}
          isDisabled={isCreatingPipeline}
          value="Create cube"
          variant="highlighted"
        />
        {error && <div>{JSON.stringify(error, null, 2)}</div>}

        <AnimatedContent isVisible={codeIndex > 0}>
          <Message>{MESSAGES?.[progressIndex]}</Message>
          <StyledProgressBar currentValue={PROGRESS_VALUES?.[progressIndex]} maxValue={1} />
          <Code>
            <CornerRect style={{ top: 0, left: 0 }} />
            <CornerRect style={{ top: 0, right: 0 }} />
            <CornerRect style={{ bottom: 0, left: 0 }} />
            <CornerRect style={{ bottom: 0, right: 0 }} />
            <CodeOverlay />
            <Editor
              value={PYTHON_CODE?.slice(0, codeIndex)}
              line={codeIndex}
              language="python"
              height="200px"
              width="100%"
            />
          </Code>
        </AnimatedContent>
      </Container>
    </OuterContainer>
  );
};

export default ModelRecommendationFromPromptAnimated;
