import styled from "styled-components";
import { useState } from "react";

import Editor from "@monaco-editor/react";
import Button from "components/ui/Button";
import { getBase64FromImageFile, parseJson } from "utils/common";
import FileInput from "components/ui/FileInput";
import { postDeploymentPrediction } from "api/services/deploymentService";
import { useEffect } from "react";
import { getDatasetPreviewByDatasetId } from "api/services/dataService";
import FormattedCodeModal from "components/ui/FormattedCodeModal";

const Container = styled.div`
  padding: 10px;
  background-color: ${props => props.theme.color.closer0_5};
`;

const RequestContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  grid-template-areas:
    "url url"
    "query-params body"
    "view-code-button send-button"
    "response response";
`;

const UrlContainer = styled.div`
  grid-area: url;
  font-size: 28px;
  letter-spacing: 1px;
  color: ${props => props.theme.color.closer2};
`;

const GreySpan = styled.span`
  color: ${props => props.theme.color.closer1_5};
`;

const StyledEditor = styled(Editor)`
  height: 100px;
  border: 1px solid ${props => props.theme.color.closer1};
  grid-area: editor;
`;

const EditorTitle = styled.div`
  color: ${props => props.theme.color.closer1_5};
`;

const QueryParamsEditorContainer = styled.div`
  grid-area: query-params;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 40px 1fr;
  gap: 10px;
  align-items: center;
`;

const BodyEditorContainer = styled.div`
  grid-area: body;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 40px 1fr;
  gap: 10px;
  align-items: center;
`;

const EditorContainer = styled.div`
  grid-column: span 2;
`;

const ResponseViewerEdtitor = styled(Editor)`
  height: 320px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const ResponseViewer = styled.div`
  grid-area: response;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const SendButton = styled(Button)`
  justify-self: end;
`;

const ButtonsContainer = styled.div`
  grid-area: send-button;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
  justify-content: end;
`;

const urlToServiceFunction = {
  "/api/v1/solutions/boltzflow/image": () => {},
  "/api/v1/solutions/assistant/code-generate": () => {},
};

const Request = ({ inputName, url, initialBodyValue = '{ "image": "" }' }) => {
  const [bodyStr, setBodyStr] = useState(initialBodyValue);

  const [responseStr, setResponseStr] = useState("");
  const [isGettingResponse, setIsGettingResponse] = useState(false);

  const [formattedTextToView, setFormattedTextToView] = useState("");

  useEffect(() => {
    setBodyStr(initialBodyValue);
  }, [initialBodyValue]);

  return (
    <RequestContainer>
      <UrlContainer>
        <GreySpan>POST</GreySpan> {url}
      </UrlContainer>
      <QueryParamsEditorContainer isDisabled={isGettingResponse}>
        <EditorTitle>query params</EditorTitle>
        <EditorContainer>
          <div />
          <StyledEditor />
        </EditorContainer>
      </QueryParamsEditorContainer>
      <BodyEditorContainer isDisabled={isGettingResponse}>
        <EditorTitle>body</EditorTitle>
        {url?.includes("image") && (
          <FileInput
            buttonText="Upload image"
            onFileSelect={async imgFile => {
              const base64String = await getBase64FromImageFile(imgFile);
              const base64StringData = base64String.split(",")[1];
              setBodyStr(`{ "image": "${base64StringData}" }`);
            }}
          />
        )}
        <EditorContainer>
          <StyledEditor value={bodyStr} language="json" onChange={newValue => setBodyStr(newValue)} />
        </EditorContainer>
      </BodyEditorContainer>
      <ButtonsContainer>
        {formattedTextToView && <FormattedCodeModal textStr={formattedTextToView} />}
        <SendButton
          value="Send"
          variant="highlighted"
          onClick={async () => {
            setIsGettingResponse(true);
            const bodyJson = parseJson(bodyStr);
            const { data, error } = await postDeploymentPrediction(bodyJson);
            setIsGettingResponse(false);
            if (error) {
              setResponseStr(JSON.stringify(error, null, 2));
              return;
            }

            if (data?.results?.[0]?.generated_text) {
              const generatedText = data?.results?.[0]?.generated_text;
              const inputStr = parseJson(bodyStr)?.inputs?.[0]?.[inputName] || "";
              setFormattedTextToView(`${inputStr}\n===================\n${generatedText?.replaceAll(inputStr, "")}`);
            }

            if (url?.includes("image")) {
              setResponseStr(JSON.stringify(data, null, 2));
              return;
            }

            setResponseStr(JSON.stringify(data, null, 2));
          }}
        />
      </ButtonsContainer>
      <ResponseViewer isDisabled={isGettingResponse || !responseStr}>
        <ResponseViewerEdtitor
          value={responseStr}
          options={{ readOnly: true }}
          language={url?.includes("image") ? "json" : "python"}
        />
      </ResponseViewer>
    </RequestContainer>
  );
};

const DeploymentApiView = ({ deploymentId, inputNames = ["sentence"], datasetId }) => {
  const [initialInputsFromDatasetPreview, setInitialInputsFromDatasetPreview] = useState([]);

  useEffect(() => {
    if (!datasetId) return;
    const doPopulateInitialInputFromDatasetPreview = async () => {
      const { data } = await getDatasetPreviewByDatasetId(datasetId);
      const firstRow = data?.[0];
      const input = {};
      inputNames.forEach(inputName => {
        input[inputName] = firstRow?.[inputName];
      });
      setInitialInputsFromDatasetPreview([input]);
    };

    doPopulateInitialInputFromDatasetPreview();
  }, [datasetId]);

  const initialBody = {
    deploymentId,
    inputs: initialInputsFromDatasetPreview,
  };

  return (
    <Container>
      <Request
        inputName={inputNames?.[0]}
        initialBodyValue={JSON.stringify(initialBody, null, 2)}
        url="/deployment-service/api/v1/predictions"
      />
    </Container>
  );
};

export default DeploymentApiView;
