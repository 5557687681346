import styled from "styled-components";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip } from "recharts";
import { chartColours } from "App";

const PlotContainer = styled.div`
  white-space: pre;
`;

const TableTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  padding: 5px;
  padding-left: 60px;
`;

const PlotForTables = ({ data = [], selectedCol }) => {
  if (data?.length === 0) {
    return null;
  }
  const dataKeys = Object.keys(data?.[0])?.filter(dataKey => dataKey !== "xLabel");

  return (
    <PlotContainer>
      <TableTitle>{selectedCol}</TableTitle>
      <LineChart width={400} height={300} data={data}>
        {dataKeys.map((dataKey, i) => (
          <Line key={dataKey} dataKey={dataKey} stroke={chartColours[i]} isAnimationActive={false} />
        ))}
        <CartesianGrid stroke="#ccc" />
        <XAxis dataKey="xLabel" tickFormatter={() => ""} />
        <YAxis />
        <Tooltip labelStyle={{ color: "black" }} />
        <Legend layout="vertical" />
      </LineChart>
    </PlotContainer>
  );
};

export default PlotForTables;
