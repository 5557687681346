import { useEffect, useState } from "react";
import NotebookTabView from "components/views/NotebookTabView";
import PipelineIdRadioSelector from "components/widgets/PipelineIdRadioSelector";

const MultiNotebookTabView = ({ pipelineOutputs, pipelineIdsToCompare }) => {
  const [selectedPipelineId, setSelectedPipelineId] = useState(null);

  useEffect(() => {
    setSelectedPipelineId(pipelineIdsToCompare[0]);
  }, [pipelineIdsToCompare]);

  const selectedPipelineOutput = pipelineOutputs.find(
    pipelineOutput => pipelineOutput.pipelineId === selectedPipelineId
  );

  if (selectedPipelineOutput?.trainingBundle) {
    return "Notebook not available in autotuning pipelines";
  }

  return (
    <>
      {pipelineOutputs.length > 1 && (
        <PipelineIdRadioSelector
          pipelineIds={pipelineOutputs.map(output => output.pipelineId)}
          selectedPipelineId={selectedPipelineId}
          onPipelineIdSelect={newPipelineId => setSelectedPipelineId(newPipelineId)}
        />
      )}
      {selectedPipelineId && (
        <NotebookTabView
          isTopBarShowing={pipelineOutputs.length === 1}
          pipelineOutput={selectedPipelineOutput}
        />
      )}
    </>
  );
};

export default MultiNotebookTabView;
