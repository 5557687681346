import { useState, useEffect } from "react";
import styled from "styled-components";
import { getRecentPackages } from "api/services/packageService";
import { triggerDownloadOfAnnotationData } from "api/backend/packageServiceEndpoint";
import { SimplePackageCard } from "components/ui/cards";
import { BigTitle } from "components/ui/Text";
import { isEmpty } from "lodash";
import Button from "components/ui/Button";

const Container = styled.div``;

const RecentPackageCardsContainers = styled.div`
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  width: 100%;
`;

const usePollRecentPackages = () => {
  const [recentPackages, setRecentPacakges] = useState([]);

  useEffect(() => {
    doFetchRecentPackages();
    const intId = setInterval(doFetchRecentPackages, 2000);
    return () => clearInterval(intId);
  }, []);

  const doFetchRecentPackages = async () => {
    const { data } = await getRecentPackages();
    setRecentPacakges(data);
  };

  return recentPackages;
};

const PackagesDashboardView = () => {
  const recentPackages = usePollRecentPackages();

  return (
    <Container>
      <BigTitle>Recent Packages</BigTitle>
      <Button
        value={"Export Annotations"}
        onClick={() => {
          triggerDownloadOfAnnotationData();
        }}
      />
      <RecentPackageCardsContainers>
        {!isEmpty(recentPackages) &&
          recentPackages.map(pkg => {
            var regExPostColon = /:([-\w\W]+)/;
            var postColon = pkg.fileId.match(regExPostColon);
            var postColonString = postColon !== null ? postColon[1] : pkg.fileId;
            return (
              <SimplePackageCard
                title={postColonString}
                packageId={pkg?.fileId}
                isReady={pkg?.isReady}
                key={pkg?.fileId}
              />
            );
          })}
      </RecentPackageCardsContainers>
    </Container>
  );
};

export default PackagesDashboardView;
