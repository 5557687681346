import { useEffect, useState } from "react";
import { getUserProfileByUserName } from "api/services/projectService";

const useCreatedByAvatarSrc = createdByUserName => {
  const [createdByAvatar, setCreatedByAvatar] = useState(null);

  useEffect(() => {
    doFetchCreatorAvatar();
  }, [createdByUserName]);

  const doFetchCreatorAvatar = async () => {
    const { data } = await getUserProfileByUserName(createdByUserName);
    setCreatedByAvatar(data?.image.includes("/images/") ? data?.image : `data:image/png;base64,${data?.image}`);
  };

  return createdByAvatar;
};

export default useCreatedByAvatarSrc;
