import styled from "styled-components";
import { range } from "lodash";
import { format } from "date-fns";

const RECT_Y_OFFSET = 15;
const RECT_X_OFFSET = 40;
const RECT_WIDTH = 920 / 52;
const RECT_HEIGHT = 920 / 52;
const WIDTH = 52 * RECT_WIDTH;
const HEIGHT = 7 * RECT_HEIGHT;
const DAY_MS = 24 * 60 * 60 * 1000;

const getDateFromWeekAndDay = (weekNum, dayNum) => {
  const map = {};

  const today = new Date();

  let countingDate = new Date(today.getTime() + (7 - today.getDay()) * DAY_MS);
  range(51, -1, -1).forEach(week => {
    range(6, -1, -1).forEach(day => {
      map[`${week},${day}`] = countingDate;
      countingDate = new Date(countingDate.getTime() - DAY_MS);
    });
  });

  return map[`${weekNum},${dayNum}`];
};

const getAlphaHexFromNumOfActivities = numberOfActivities => {
  if (numberOfActivities > 10) {
    return "ff";
  }

  return Math.round(63 + (numberOfActivities / 10) * 192).toString(16);
};

const Text = styled.text`
  fill: ${props => props.theme.color.closest};
`;

const Svg = styled.svg`
  padding: 20px;
  border: 1px solid ${props => props.theme.color.closer0};
  box-sizing: content-box;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  background-color: ${props => props.theme.color.closer0};
`;

const Rect = styled.rect`
  fill: ${props =>
    props.numberOfActivities === 0
      ? props.theme.color.closer1
      : `${props.theme.color.primary}${getAlphaHexFromNumOfActivities(props.numberOfActivities)}`};
  fill: ${props => props.isInFuture && "none"};
  stroke-width: 4px;
  stroke: ${props => props.theme.color.closer0};
`;

const DayRect = ({ date, x, y, width, height, activitiesByDay }) => {
  const isInFuture = new Date() < date;
  const numberOfActivities = activitiesByDay[format(date, "MMM d, yyyy")]?.length || 0;

  return (
    <Rect
      numberOfActivities={numberOfActivities}
      isInFuture={isInFuture}
      x={x}
      y={y}
      width={width}
      height={height}
      rx={4}
    />
  );
};

const ActivityDaysGrid = ({ activitiesByDay }) => {
  return (
    <Svg width={WIDTH + RECT_X_OFFSET} height={HEIGHT + RECT_Y_OFFSET}>
      <Text x={0} y={RECT_Y_OFFSET + RECT_HEIGHT - 4}>
        Mon
      </Text>
      <Text x={0} y={RECT_Y_OFFSET + RECT_HEIGHT * 3 - 4}>
        Wed
      </Text>
      <Text x={0} y={RECT_Y_OFFSET + RECT_HEIGHT * 5 - 4}>
        Fri
      </Text>

      {range(0, 52).map(weekNum => {
        const weekStartDate = getDateFromWeekAndDay(weekNum, 0);
        const isFirstWeekOfMonth = weekStartDate.getMonth() !== getDateFromWeekAndDay(weekNum - 1, 0)?.getMonth();
        return (
          isFirstWeekOfMonth && (
            <Text key={weekNum} x={1 + RECT_X_OFFSET + weekNum * RECT_WIDTH} y={10}>
              {format(weekStartDate, "MMM")}
            </Text>
          )
        );
      })}
      {range(0, 52).map(weekNum =>
        range(0, 7).map(dayNum => (
          <DayRect
            activitiesByDay={activitiesByDay}
            key={`${weekNum},${dayNum}`}
            date={getDateFromWeekAndDay(weekNum, dayNum)}
            x={RECT_X_OFFSET + weekNum * RECT_WIDTH}
            y={RECT_Y_OFFSET + dayNum * RECT_HEIGHT}
            height={RECT_HEIGHT}
            width={RECT_WIDTH}
          />
        ))
      )}
    </Svg>
  );
};

export default ActivityDaysGrid;
