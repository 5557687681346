import styled from "styled-components";
import { useState, useRef, useEffect } from "react";
import { range } from "lodash";
import StandardButton from "components/ui/Button";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const getTensorFromImageData = imageData => {
  const { width, height, data } = imageData;
  return range(0, height).map(rowNum => {
    return range(0, width * 4, 4).map(colNum => {
      const ind = rowNum * width * 4 + colNum;
      return [data[ind], data[ind + 1], data[ind + 2]];
    });
  });
};

const getMaskTensorFromImageData = imageData => {
  const { width, height, data } = imageData;
  return range(0, height).map(rowNum => {
    return range(0, width * 4, 4).map(colNum => {
      const ind = rowNum * width * 4 + colNum;
      if (data[ind + 3] === 255) {
        return [1, 1, 1];
      }
      return [0, 0, 0];
    });
  });
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  gap: 10px;
`;

const MaskDrawingCanvas = styled.canvas`
  position: absolute;
  left: 0;
  opacity: 0;
  border: 1px dashed grey;
  ${props => props.isHighlighting && "cursor: pointer;"}
`;

const ImageAndCanvasContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const MaskAppearanceCanvas = styled.canvas`
  position: absolute;
  left: 0;
`;

const ImagePlaceholder = styled.div`
  width: 128px;
  height: 192px;
  background-color: ${props => props.theme.color.closer0};
`;

const Button = styled(StandardButton)`
  min-height: 0;
  min-width: 0;
  margin: 0;
  width: 100%;
  padding: 5px 10px;
`;

const EyeContainer = styled.div`
  cursor: pointer;
  grid-column: span 2;
  justify-self: center;
  min-height: 24px;
`;

const FakeButton = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 50px;
  background-color: ${props => props.theme.color.closer0};
`;

const ImageCanvasForModel = ({ base64Img, onSetInputImageTensor, isImageHidden }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!canvasRef.current || isImageHidden) {
      return;
    }

    const img = new Image();
    img.src = `data:image/png;base64,${base64Img}`;

    const ctx = canvasRef.current.getContext("2d");
    img.onload = () => {
      canvasRef.current.width = 32;
      canvasRef.current.height = 48;
      ctx.drawImage(img, 0, 0, 32, 48);

      const imageData = ctx.getImageData(0, 0, 32, 48);
      const tensor = getTensorFromImageData(imageData);
      onSetInputImageTensor(tensor);
    };
  }, [base64Img, isImageHidden]);

  return <canvas style={{ display: "none" }} ref={canvasRef} />;
};

const MaskCanvasForModel = ({ canvasRef }) => {
  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    canvasRef.current.width = 32;
    canvasRef.current.height = 48;
  }, []);

  return <canvas style={{ display: "none" }} ref={canvasRef} />;
};

const ImageMaskingInput = ({ base64Img, onSetInputImageTensor, onSetInputMaskTensor }) => {
  const maskDrawingCanvasRef = useRef();
  const maskCanvasRef = useRef();
  const maskAppearanceCanvasRef = useRef();

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [isHighlighting, setIsHighlighting] = useState(false);
  const [isImageHidden, setIsImageHidden] = useState(false);

  useEffect(() => {
    if (!maskDrawingCanvasRef.current) {
      return;
    }

    const img = new Image();
    img.src = `data:image/png;base64,${base64Img}`;

    img.onload = () => {
      maskDrawingCanvasRef.current.width = img.width;
      maskDrawingCanvasRef.current.height = img.height;

      maskAppearanceCanvasRef.current.width = img.width;
      maskAppearanceCanvasRef.current.height = img.height;
    };
    setIsHighlighting(false);
  }, [base64Img, isImageHidden]);

  const updateMaskTensor = () => {
    const maskCanvasCtx = maskCanvasRef.current.getContext("2d");
    maskCanvasCtx.drawImage(maskDrawingCanvasRef.current, 0, 0, 32, 48);

    const imageData = maskCanvasCtx.getImageData(0, 0, 32, 48);
    const maskTensor = getMaskTensorFromImageData(imageData);
    onSetInputMaskTensor(maskTensor);
  };

  const applyMaskEverywhere = () => {
    const maskDrawingCtx = maskDrawingCanvasRef.current.getContext("2d");
    maskDrawingCtx.clearRect(0, 0, maskDrawingCanvasRef.current.width, maskDrawingCanvasRef.current.height);

    const maskAppearanceCtx = maskAppearanceCanvasRef.current.getContext("2d");
    maskAppearanceCtx.fillStyle = "rgba(255, 255, 255, 0.7)";
    maskAppearanceCtx.fillRect(0, 0, maskDrawingCanvasRef.current.width, maskDrawingCanvasRef.current.height);

    updateMaskTensor();
  };

  const clearMaskEverywhere = () => {
    const maskDrawingCtx = maskDrawingCanvasRef.current.getContext("2d");
    maskDrawingCtx.fillRect(0, 0, maskDrawingCanvasRef.current.width, maskDrawingCanvasRef.current.height);

    const maskAppearanceCtx = maskAppearanceCanvasRef.current.getContext("2d");
    maskAppearanceCtx.clearRect(0, 0, maskDrawingCanvasRef.current.width, maskDrawingCanvasRef.current.height);

    onSetInputMaskTensor(null);
  };

  const drawMaskRectAtMouseEventPosition = e => {
    const canvasContainerRect = maskDrawingCanvasRef.current.getBoundingClientRect();
    const x = e.clientX - canvasContainerRect.left;
    const y = e.clientY - canvasContainerRect.top;

    const ctx = maskDrawingCanvasRef.current.getContext("2d");
    ctx.fillRect(x - 10, y - 10, 20, 20);

    const maskAppearanceCtx = maskAppearanceCanvasRef.current.getContext("2d");
    maskAppearanceCtx.clearRect(x - 10, y - 10, 20, 20);

    updateMaskTensor();
  };

  if (!base64Img) {
    return (
      <ImageAndCanvasContainer>
        <EyeContainer />
        <ImagePlaceholder />
        <FakeButton />
      </ImageAndCanvasContainer>
    );
  }

  const eyeButton = (
    <EyeContainer
      onClick={() => {
        if (isImageHidden) {
          setIsImageHidden(false);
          return;
        }
        setIsHighlighting(false);
        onSetInputImageTensor(null);
        onSetInputMaskTensor(null);
        setIsImageHidden(true);
      }}
    >
      {isImageHidden ? <VisibilityOff /> : <Visibility />}
    </EyeContainer>
  );

  if (isImageHidden) {
    return (
      <ImageAndCanvasContainer>
        {eyeButton}
        <ImagePlaceholder />
        <FakeButton />
      </ImageAndCanvasContainer>
    );
  }

  return (
    <Container>
      {eyeButton}
      <ImageAndCanvasContainer>
        <img src={`data:image/png;base64,${base64Img}`} alt="input book cover" />
        <MaskAppearanceCanvas ref={maskAppearanceCanvasRef} />
        <MaskDrawingCanvas
          isHighlighting={isHighlighting}
          ref={maskDrawingCanvasRef}
          onMouseDown={() => isHighlighting && setIsMouseDown(true)}
          onMouseUp={() => setIsMouseDown(false)}
          onMouseLeave={() => setIsMouseDown(false)}
          onMouseMove={e => isMouseDown && drawMaskRectAtMouseEventPosition(e)}
        />
        <div>
          <Button
            value={isHighlighting ? "Cancel" : "Highlight regions"}
            onClick={() => {
              if (!isHighlighting) {
                applyMaskEverywhere();
                setIsHighlighting(true);
                return;
              }
              clearMaskEverywhere();
              setIsHighlighting(false);
            }}
          />
        </div>
      </ImageAndCanvasContainer>

      {/* invisble canvases for tensor formation */}
      <MaskCanvasForModel canvasRef={maskCanvasRef} />
      <ImageCanvasForModel
        base64Img={base64Img}
        onSetInputImageTensor={onSetInputImageTensor}
        isImageHidden={isImageHidden}
      />
    </Container>
  );
};

export default ImageMaskingInput;