import styled from "styled-components";

import { Code, Edit, ExitToApp, Save, Telegram } from "@material-ui/icons";
import MultiOptionSelector from "components/ui/MultiOptionSelector";
import Tooltip from "components/ui/Tooltip";
import { DownloadIcon, PlotIcon, SaveIcon } from "components/ui/Icons";
import { triggerDownloadOfModelFile } from "api/backend/projectServiceEndpoints";
import { useParams } from "react-router-dom";

const TopBarContainer = styled.div`
  min-height: 36px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.color.closer0_5};
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  justify-content: space-between;
  padding: 0px 10px;
`;

const HoverButtonContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  border: 0px solid ${props => props.theme.color.closest};
  border-radius: 60px;

  .MuiSvgIcon-root {
    fill: ${props => props.theme.color.closest};
    height: 24px;
    transition: none;
  }

  :hover {
    border: 0px solid ${props => props.theme.color.primary};
    .MuiSvgIcon-root {
      fill: ${props => props.theme.color.primary};
    }
  }
`;

const ModelNameInput = styled.input`
  padding: 0px;
  font-size: 18px;
  padding: 0;
  border: none;
  font-family: "Montserrat", sans-serif;
  width: 250px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.color.closer1_5};
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
`;

const ModelName = styled.div`
  font-size: 18px;
`;

const RightSideButtons = styled.div`
  display: flex;
  align-items: center;
`;

const ModelTopBar = ({
  hasModelId,
  modelName,
  onModelNameEdit,
  isEditMode,
  onClickEdit,
  onClickCancel,
  onClickSave,
  onClickExecute,
  hasUserEditedPipeline,
  isSubmitDisabled,
  modelView,
  onSetModelView,
  onClickTrainingLog = () => {},
  onClickCode = () => {},
}) => {
  const { cubeId } = useParams();

  if (isEditMode) {
    return (
      <TopBarContainer>
        {modelName ? <ModelNameInput value={modelName} onChange={e => onModelNameEdit(e.target.value)} /> : <div />}
        <RightSideButtons>
          {/* <MultiOptionSelector
            options={hasModelId ? ["Graph", "Config", "Code"] : ["Graph", "Config"]}
            selectedOption={modelView}
            onOptionSelect={newView => onSetModelView(newView)}
          /> */}
          <Tooltip title="Download model">
            <HoverButtonContainer onClick={() => triggerDownloadOfModelFile(cubeId)}>
              <DownloadIcon />
            </HoverButtonContainer>
          </Tooltip>
          <Tooltip title="Training Log">
            <HoverButtonContainer onClick={onClickTrainingLog}>
              <PlotIcon height="18px" />
            </HoverButtonContainer>
          </Tooltip>
          <Tooltip title="Model Code">
            <HoverButtonContainer onClick={onClickCode}>
              <Code />
            </HoverButtonContainer>
          </Tooltip>
          <Tooltip title="Save Changes">
            <HoverButtonContainer isDisabled={!hasUserEditedPipeline} onClick={onClickSave}>
              <Save />
            </HoverButtonContainer>
          </Tooltip>
          <Tooltip title="Submit">
            <HoverButtonContainer isDisabled={isSubmitDisabled} onClick={onClickExecute}>
              <Telegram />
            </HoverButtonContainer>
          </Tooltip>
        </RightSideButtons>
      </TopBarContainer>
    );
  }

  return (
    <TopBarContainer>
      <ModelName>{modelName}</ModelName>
      <RightSideButtons>
        {/* <MultiOptionSelector
          options={["Graph", "Config", "Code"]}
          selectedOption={modelView}
          onOptionSelect={newView => onSetModelView(newView)}
        /> */}
        <Tooltip title="Download model">
          <HoverButtonContainer onClick={() => triggerDownloadOfModelFile(cubeId)}>
            <DownloadIcon />
          </HoverButtonContainer>
        </Tooltip>
        <Tooltip title="Training Log">
          <HoverButtonContainer onClick={onClickTrainingLog}>
            <PlotIcon height="18px" />
          </HoverButtonContainer>
        </Tooltip>
        <Tooltip title="Model Code">
          <HoverButtonContainer onClick={onClickCode}>
            <Code />
          </HoverButtonContainer>
        </Tooltip>
        <Tooltip title="Save Changes">
          <HoverButtonContainer isDisabled={!hasUserEditedPipeline} onClick={onClickSave}>
            <SaveIcon />
          </HoverButtonContainer>
        </Tooltip>
        <Tooltip title="Submit">
          <HoverButtonContainer isDisabled={isSubmitDisabled} onClick={onClickExecute}>
            <Telegram />
          </HoverButtonContainer>
        </Tooltip>
      </RightSideButtons>
    </TopBarContainer>
  );
};

export default ModelTopBar;
