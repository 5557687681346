import { getChatbotExecution } from "api/services/solutions/chatbotSolutionService";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sleep } from "utils/common";
import Button from "components/ui/Button";

const GreyBground = styled.div`
  background-color: ${props => props.theme.color.closer0};
  padding-top: 20px;
  border-radius: 5px;
  min-height: 500px;
  display: grid;
`;

const ChatBubbles = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
`;

const ChatBubbleWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: 400px;
  overflow-y: scroll;
`;

const BotAvatar = styled.img`
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.color.primary};
  background-color: black;
`;

const UserAndTime = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  color: ${props => props.theme.color.closer2};
`;

const Input = styled.textarea`
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-family: "Montserrat";
  width: 100%;
  background-color: white;
  border-bottom: none;
  color: black;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 10px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
  height: 100px;
`;

const SendButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 5px 20px;
  margin: 0px;
`;

const BotBubble = styled.div`
  width: max-content;
  padding: 10px;
  background-color: white;
  color: black;
  border-radius: 10px;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.1);
`;

const BubbleContainer = styled.div`
  align-self: start;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const InputAndsendButton = styled.div`
  align-self: end;
  display: flex;
  gap: 5px;
  align-items: center;
  padding-right: 10px;
`;

const ChatContainer = styled.div`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
`;

const ItemLink = styled(Link)`
  display: block;
  color: ${props => props.theme.color.primary};
`;

const Bubble = ({ text, author, sentDate, itemArray }) => {
  return (
    <BubbleContainer style={author === "You" ? { alignSelf: "end" } : {}}>
      <UserAndTime>
        <BotAvatar src={author === "You" ? "/images/avatar-placeholder.jpeg" : "/images/logo-white.png"} />
        <div>{author}</div>
        {format(sentDate, "HH:mm:ss")}
      </UserAndTime>
      <BotBubble>
        {itemArray
          ? itemArray?.map(item => (
              <ItemLink key={item.id} to={item.type === "CUBE" ? `/cube/${item.id}` : `/data/${item.id}`}>
                {item.name}
              </ItemLink>
            ))
          : text}
      </BotBubble>
    </BubbleContainer>
  );
};

const LoadingBotBubble = () => {
  return (
    <BubbleContainer>
      <UserAndTime>
        <BotAvatar src="/images/logo-white.png" />
        <div>BoltzBot</div>
      </UserAndTime>
      <BotBubble>...</BotBubble>
    </BubbleContainer>
  );
};

const ChatbotInterface = ({ userInput, onSetUserInput, onSendBtn, adminUserGoogleCalendarToken }) => {
  const [bubbles, setBubbles] = useState([]);
  const [isLoadingResponse, setIsLoadingResponse] = useState(true);

  const addBotBubble = text => {
    const newBubbles = [...bubbles, { text, author: "BoltzBot", sentDate: new Date() }];
    setBubbles(newBubbles);
  };

  const addUserBubble = text => {
    const newBubbles = [...bubbles, { text, author: "You", sentDate: new Date() }];
    setBubbles(newBubbles);
  };

  const addBotBubbleWithArray = itemArray => {
    const newBubbles = [...bubbles, { itemArray, author: "BoltzBot", sentDate: new Date() }];
    setBubbles(newBubbles);
  };

  useEffect(() => {
    setTimeout(() => {
      addBotBubble("Hi, I'm Boltzbot! What can I do for you?");
      setIsLoadingResponse(false);
    }, 1000);
  }, []);

  const numOfUserBubbles = bubbles?.filter(b => b.author === "You").length;

  useEffect(() => {
    numOfUserBubbles && doRespondToUser();
  }, [numOfUserBubbles]);

  const doRespondToUser = async () => {
    setIsLoadingResponse(true);
    await sleep(500);
    const userBubbles = bubbles.filter(b => b.author === "You");
    const latestUserText = userBubbles[userBubbles.length - 1]?.text;

    const { data, error } = await getChatbotExecution({ text: latestUserText, token: adminUserGoogleCalendarToken });
    setIsLoadingResponse(false);
    addBotBubble(data.message);

    if (error?.message) {
      addBotBubble(error.message);
      return;
    }

    if (error) {
      addBotBubble("Sorry, I'm having trouble understanding you. Please try again.");
      return;
    }

    if (data?.redirectUrl) {
      addBotBubble("Cube created, redirecting...");
      await sleep(500);
      window.location.href = data.redirectUrl;
      return;
    }

    if (data?.results?.length) {
      addBotBubbleWithArray(data.results?.slice(0, 10));
      return;
    }

    if (!data) {
      addBotBubble("Sorry, I'm having trouble understanding you. Please try again.");
    }
  };

  return (
    <ChatContainer>
      <GreyBground>
        <ChatBubbleWrapper>
          <ChatBubbles>
            {bubbles.map((bubble, i) => (
              <Bubble
                key={`${bubble.author}-${i}`}
                text={bubble.text}
                author={bubble.author}
                sentDate={bubble.sentDate}
                itemArray={bubble.itemArray}
              />
            ))}
            {isLoadingResponse && <LoadingBotBubble />}
          </ChatBubbles>
        </ChatBubbleWrapper>

        <InputAndsendButton>
          <Input placeholder="Type your command..." value={userInput} onChange={e => onSetUserInput(e.target.value)} />
          <SendButton
            value="Send"
            onClick={() => {
              addUserBubble(userInput);
              onSetUserInput("");
              onSendBtn();
            }}
          />
        </InputAndsendButton>
      </GreyBground>
    </ChatContainer>
  );
};

export default ChatbotInterface;
