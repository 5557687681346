/* eslint-disable no-loop-func */
import { chunk, groupBy, isNil, last, range, sum, uniq } from "lodash";

const CHAR_WIDTH = 9;
const PAGE_LEFT_X = 0;
const LINE_SPACING = 4;
const PAGE_TOP_Y = 0;
const TOP_MARGIN = 20;
const LINE_HEIGHT = 14;
const CELL_PADDING = 0;

export const SF = 4;
export const LEFT_MARGIN = 20;
export const MAX_CHARS_PER_LINE = 80;
export const PAGE_WIDTH_PX = MAX_CHARS_PER_LINE * CHAR_WIDTH;
export const START_X = PAGE_LEFT_X + LEFT_MARGIN;

/* 
// BE representation
blocks = [
  { text: "This could be very very long ..."}
  { tableContent: 
    [
      ["a", "b"], 
      ["c", "d"]
    ] 
  }
]

// canvas representation
boxes = [
  { text: "This could be", blockIndex: 0, blockStartIndex: 0 },
  { text: "very very long ...", blockIndex: 0, blockStartIndex: 14 },
  ...
]
*/

const getFirstTextSegmentCutAtWord = ({ text, ctx, fontSize = 14, maxBoxWidth = PAGE_WIDTH_PX }) => {
  if (!text) {
    return "";
  }
  if (typeof text !== "string") {
    text = `${text}`;
  }

  const prevFontStr = ctx.font;
  ctx.font = `normal ${fontSize}px Montserrat`;

  const words = text.split(" ");
  let line = "";
  let lineWidthPx = 0;
  let wordIndex = 0;
  while (lineWidthPx < maxBoxWidth && wordIndex < words.length) {
    if (wordIndex === words.length - 1) {
      line += words[wordIndex];
      break;
    }
    line += (words[wordIndex] || "") + " ";
    lineWidthPx = ctx.measureText(line).width;
    wordIndex++;
  }

  ctx.font = prevFontStr;
  return line;
};

const getBoxStyles = (blockStartIndex, blockStyles) => {
  const stylesWithAdjustedIndices = blockStyles?.map(style => ({
    ...style,
    start: style.start - blockStartIndex,
    end: style.end - blockStartIndex,
  }));

  if (!stylesWithAdjustedIndices) {
    return [];
  }

  const stylesWithValidIndices = stylesWithAdjustedIndices
    .filter(s => s.start >= 0 || s.end >= 0)
    .map(s => ({
      ...s,
      start: Math.max(s.start, 0),
    }));

  return stylesWithValidIndices;
};

const getBoxWidth = (boxText, ctx, fontSize = 14) => {
  const prevFontStr = ctx.font;
  ctx.font = `normal ${fontSize * SF}px Montserrat`;

  const width = ctx.measureText(boxText).width / SF;

  ctx.font = prevFontStr;

  return width;
};

const getBoxesForBlock = ({ block, ctx, startY = 0, startX, blockIndex, maxBoxWidth = PAGE_WIDTH_PX }) => {
  let blockStartIndex = 0;
  let lineHeight = block?.blockStyles?.fontSize || LINE_HEIGHT;
  const leftIndent = block?.blockStyles?.leftIndent || 0;
  let y = startY;
  let boxes = [];

  if (!block || block.text === "") {
    boxes.push({
      ...block,
      text: "",
      blockIndex,
      blockStartIndex,
      y,
      x: startX + leftIndent,
      w: maxBoxWidth,
      h: lineHeight + LINE_SPACING,
      lineHeight,
      styles: block?.styles || [],
    });
    y += lineHeight + LINE_SPACING;

    return [boxes, y];
  }

  let boxText = getFirstTextSegmentCutAtWord({
    text: block?.text,
    ctx,
    fontSize: block?.blockStyles?.fontSize,
    maxBoxWidth: maxBoxWidth - leftIndent,
  });
  // split block text into boxes
  while (boxText) {
    const styles = getBoxStyles(blockStartIndex, block?.styles);

    boxes.push({
      ...block,
      text: boxText,
      blockIndex,
      blockStartIndex,
      y,
      x: startX + leftIndent,
      w: getBoxWidth(boxText, ctx, block?.blockStyles?.fontSize),
      h: lineHeight + LINE_SPACING,
      lineHeight,
      styles,
    });
    y += lineHeight + LINE_SPACING;

    blockStartIndex += boxText.length;
    boxText = getFirstTextSegmentCutAtWord({
      text: `${block?.text}`?.slice(blockStartIndex),
      ctx,
      fontSize: block?.blockStyles?.fontSize,
      maxBoxWidth: maxBoxWidth - leftIndent,
    });
  }

  return [boxes, y];
};

const addRowHeightToBoxes = boxes => {
  // build tableId -> rowHeights map
  const tableIdToBoxes = groupBy(
    boxes?.filter(box => !!box.tableId),
    "tableId"
  );
  const tableIdToRowHeights = {};
  Object.entries(tableIdToBoxes).forEach(([tableId, tableBoxes]) => {
    const rowHeights = [];
    const tableBoxesGroupedByRow = groupBy(tableBoxes, "rowIndex");

    Object.entries(tableBoxesGroupedByRow).forEach(([rowIndex, rowBoxes]) => {
      const rowBoxesGroupedByColumn = groupBy(rowBoxes, "columnIndex");
      const columnHeights = [];
      Object.entries(rowBoxesGroupedByColumn).forEach(([columnIndex, columnBoxes]) => {
        const columnHeight = sum(columnBoxes.map(b => b.h));
        columnHeights.push(columnHeight);
      });

      rowHeights.push(Math.max(...columnHeights));
    });

    tableIdToRowHeights[tableId] = rowHeights;
  });

  boxes.forEach((box, boxIndex) => {
    if (box?.isTableCell) {
      box.rowHeight = tableIdToRowHeights[box.tableId][box.rowIndex];
    }
  });

  return boxes;
};

export const getBoxes = ({ blocks = [], ctx, pageTopY = PAGE_TOP_Y }) => {
  let boxes = [];

  let y = pageTopY + TOP_MARGIN;
  let blockIndex = 0;

  while (blockIndex < blocks.length) {
    const block = blocks?.[blockIndex];

    if (block?.imageBase64) {
      const imageObj = new Image();
      imageObj.src = `data:image/png;base64,${block?.imageBase64}`;

      const imageBox = {
        x: START_X,
        y,
        w: block?.w ?? 100,
        h: block?.h ?? 100,
        image: imageObj,
        blockIndex,
        blockStartIndex: 0,
      };
      boxes.push(imageBox);
      y += imageBox.h;
      blockIndex += 1;
      continue;
    }

    if (!block?.isTableCell) {
      const [blockBoxes, endY] = getBoxesForBlock({
        block,
        ctx,
        startY: y,
        startX: START_X,
        blockIndex,
        maxBoxWidth: PAGE_WIDTH_PX,
      });
      boxes.push(...blockBoxes);
      y = endY;
      blockIndex += 1;
      continue;
    }

    // if table cell block
    const cellWidth = PAGE_WIDTH_PX / block?.numberOfColumns;
    range(0, block?.numberOfRows).forEach(rowIndex => {
      let maxRowHeight = 0;
      range(0, block?.numberOfColumns).forEach(columnIndex => {
        let startX = START_X + columnIndex * cellWidth;

        const blocksInCell = blocks.filter(
          b => b?.tableId === block?.tableId && b.rowIndex === rowIndex && b.columnIndex === columnIndex
        );

        let cellY = y;
        blocksInCell.forEach(cellBlock => {
          const [cellBlockBoxes, endY] = getBoxesForBlock({
            block: cellBlock,
            ctx,
            startY: cellY,
            startX,
            blockIndex,
            maxBoxWidth: cellWidth - 50,
          });
          boxes.push(...cellBlockBoxes);
          cellY = endY;
          blockIndex += 1;
        });

        maxRowHeight = Math.max(maxRowHeight, cellY - y);
      });
      y += maxRowHeight;
    });
  }

  // TODO: inefficient function, fix
  boxes = addRowHeightToBoxes(boxes);

  return boxes;
};

const getFontStrForCharIndex = (styles, charIndex, fontSize = 14) => {
  const matchedStyles = styles.filter(style => style.start <= charIndex && style.end > charIndex);

  const fontWeight = matchedStyles?.find(style => style.fontWeight)?.fontWeight || "normal";
  const fontStyle = matchedStyles?.find(style => style.fontStyle)?.fontStyle || "normal";

  return `${fontStyle} ${fontWeight} ${fontSize * SF}px Montserrat`.trim();
};

const getFontColorForCharIndex = (styles, charIndex) => {
  const matchedStyles = styles.filter(style => style.start <= charIndex && style.end > charIndex);

  const fontColor = matchedStyles?.find(style => style.fontColor)?.fontColor;

  return fontColor || "#000000";
};

const getMetaForCharIndex = (styles, charIndex) => {
  const matchedStyles = styles.filter(style => style.start <= charIndex && style.end > charIndex);

  const meta = matchedStyles?.find(style => style.meta)?.meta;

  return meta || null;
};

// ensures entire text is covered by style decalarations
/**
segment = {
  text, x, w, fontStr,
}
 */
const getStyleSegments = ({ fontSize = 14, styles = [], text = "", ctx, upToIndex = null }) => {
  const segments = [];
  let charIndex = 0;
  let x = 0;

  const upToIndexChecked = upToIndex ?? text?.length;
  let currentSegment = {
    text: "",
    x,
    w: 0,
    fontStr: `normal normal ${(fontSize || 14) * SF}px Montserrat`,
    fontColor: "#000000",
    meta: null,
  };
  while (charIndex < upToIndexChecked) {
    const fontStr = getFontStrForCharIndex(styles, charIndex, fontSize);
    const fontColor = getFontColorForCharIndex(styles, charIndex);
    const meta = getMetaForCharIndex(styles, charIndex);
    if (charIndex === 0) {
      currentSegment.fontStr = fontStr;
      currentSegment.fontColor = fontColor;
      currentSegment.meta = meta;

      currentSegment.text += text[charIndex] || "";
      charIndex++;
      continue;
    }

    if (
      fontStr === currentSegment.fontStr &&
      fontColor === currentSegment.fontColor &&
      JSON.stringify(meta) === JSON.stringify(currentSegment.meta)
    ) {
      currentSegment.text += text[charIndex] || "";
      charIndex++;
      continue;
    }

    ctx.font = currentSegment.fontStr;
    currentSegment.w = ctx.measureText(currentSegment.text).width / SF;
    x += currentSegment.w;
    segments.push(currentSegment);

    currentSegment = {
      text: text[charIndex] || "",
      x,
      w: 0,
      fontStr,
      fontColor,
      meta,
    };
    charIndex++;
  }
  ctx.font = currentSegment.fontStr;
  currentSegment.w = ctx.measureText(currentSegment.text).width / SF;
  segments.push(currentSegment);

  return segments;
};

export const drawBoxes = ({ ctx, boxes }) => {
  ctx.clearRect(0, 0, 10000, 10000);

  ctx.strokeStyle = "rgba(0, 0, 0, 1)";
  ctx.fillStyle = "black";
  ctx.beginPath();

  // draw text
  boxes
    ?.filter(box => box.y > 0 && box.y < 1000)
    ?.forEach(box => {
      if (box?.image) {
        ctx.drawImage(box?.image, box?.x * SF, box?.y * SF, box?.w * SF, box?.h * SF);
        return;
      }

      // ctx.rect(box?.x * SF, box?.y * SF, box?.w * SF, box?.h * SF);
      if (box?.blockStartIndex === 0 && box?.blockStyles?.prefix) {
        ctx.font = `normal normal ${box?.blockStyles?.fontSize * SF}px Montserrat`;
        ctx.fillText(box?.blockStyles?.prefix, (box?.x - 20) * SF, (box?.y + box?.lineHeight) * SF);
      }

      const segments = getStyleSegments({
        fontSize: box?.blockStyles?.fontSize,
        styles: box?.styles,
        text: box?.text,
        ctx,
      });

      segments.forEach(segment => {
        const { x, text, fontStr, fontColor } = segment;
        ctx.font = fontStr;
        const prevFillStyle = ctx.fillStyle;
        ctx.fillStyle = fontColor;
        ctx.fillText(text, (box?.x + x) * SF, (box?.y + box?.lineHeight) * SF);
        ctx.fillStyle = prevFillStyle;

        if (segment?.meta) {
          ctx.fillStyle = segment?.meta?.isUserLabel ? "#00993320" : "#0191ff20";
          ctx.fillRect((box?.x + x) * SF, box?.y * SF, segment?.w * SF, box?.h * SF);
          ctx.fillStyle = prevFillStyle;
        }
      });
    });

  // draw table cell borders
  const cellIdToBoxes = groupBy(
    boxes?.filter(box => box?.isTableCell),
    box => `${box?.tableId}-${box?.rowIndex}-${box?.columnIndex}`
  );
  Object.entries(cellIdToBoxes).forEach(([cellId, boxes]) => {
    const x = Math.min(...boxes.map(box => box?.x));
    const y = Math.min(...boxes.map(box => box?.y));
    const w = PAGE_WIDTH_PX / boxes?.[0]?.numberOfColumns;
    const h = boxes?.[0]?.rowHeight;

    ctx.rect(x * SF, y * SF, w * SF, h * SF);
  });

  ctx.stroke();
};

export const getMouseEventLocation = ({ e, ctx, boxes }) => {
  let { offsetX, offsetY } = e.nativeEvent;

  // get box
  let clickedBoxIndex = boxes?.length - 1;
  for (let i = 0; i < boxes?.length; i++) {
    const box = boxes?.[i];
    const isXWithinBox = offsetX >= box?.x && offsetX <= box?.x + box?.w;
    const isYWithinBox = offsetY >= box?.y && offsetY <= box?.y + box?.h;
    if (isXWithinBox && isYWithinBox) {
      clickedBoxIndex = i;
      break;
    }

    if (isYWithinBox) {
      clickedBoxIndex = i;
    }
  }

  ctx.stroke();

  // get letter
  const clickedBox = boxes?.[clickedBoxIndex];
  let clickedLetterIndex = 0;
  let letterX = clickedBox?.x;
  const fontSize = clickedBox?.blockStyles?.fontSize || 14;

  // set font for ctx.measureText
  ctx.font = `${fontSize * SF}px Montserrat`;

  while (letterX < offsetX && clickedLetterIndex <= clickedBox?.text?.length + 1) {
    const segments = getStyleSegments({
      fontSize,
      styles: clickedBox?.styles,
      text: clickedBox?.text,
      ctx,
      upToIndex: clickedLetterIndex,
    });
    letterX = clickedBox.x + last(segments)?.x + last(segments)?.w;
    clickedLetterIndex++;
  }

  clickedLetterIndex -= 2;
  if (clickedBoxIndex >= boxes?.length) {
    clickedBoxIndex = boxes?.length - 1;
  }
  clickedLetterIndex = Math.max(0, clickedLetterIndex) || 0;

  return [clickedBoxIndex, clickedLetterIndex];
};

export const getXandYForLineAndLetterIndex = ({ ctx, boxIndex, letterIndex, boxes }) => {
  const box = boxes?.[boxIndex];
  const segments = getStyleSegments({
    fontSize: box?.blockStyles?.fontSize,
    styles: box?.styles,
    text: box?.text,
    ctx,
    upToIndex: letterIndex,
  });
  const x = box?.x + last(segments)?.x + last(segments)?.w;
  const y = box?.y;

  return { x, y };
};

export const drawCursorAtLineAndLetterIndex = ({
  ctx,
  boxIndex,
  letterIndex,
  boxes,
  cursorStrokeStyle = "rgba(0, 0, 0, 1)",
  cursorLineWidth = 8,
  cursorMsg = "",
}) => {
  if (boxIndex === null || letterIndex === null) {
    return;
  }

  ctx.clearRect(0, 0, 10000, 10000);

  const box = boxes?.[boxIndex];
  const boxText = box?.text || "";
  const fontSize = box?.blockStyles?.fontSize || 14;

  ctx.font = `${fontSize * SF}px Montserrat`;

  const segments = getStyleSegments({
    fontSize,
    styles: box?.styles,
    text: box?.text,
    ctx,
    upToIndex: letterIndex,
  });
  const x = box?.x + last(segments)?.x + last(segments)?.w;
  const y = box?.y;

  if (!boxText && box?.type !== "table-cell") {
    ctx.fillStyle = "#bbbaba";
    ctx.fillText("Press “/” for AI", x * SF, (y + box?.lineHeight) * SF);
  }

  if (cursorMsg) {
    ctx.fillStyle = "#0191ff";
    ctx.fillText(cursorMsg, x * SF, (y + box?.lineHeight) * SF);
  }

  ctx.lineWidth = cursorLineWidth;
  ctx.strokeStyle = cursorStrokeStyle;
  ctx.beginPath();
  ctx.moveTo(x * SF, y * SF);
  ctx.lineTo(x * SF, (y + box?.lineHeight + LINE_SPACING) * SF);
  ctx.stroke();
};

export const drawBlueSelectionBoxes = ({
  ctx,
  boxes,
  startBoxIndex,
  startLetterIndex,
  endBoxIndex,
  endLetterIndex,
}) => {
  if (
    isNil(endBoxIndex) ||
    isNil(endLetterIndex) ||
    (startBoxIndex === endBoxIndex && startLetterIndex === endLetterIndex)
  ) {
    return;
  }

  let isEndBeforeStart = false;
  if (endBoxIndex < startBoxIndex) {
    isEndBeforeStart = true;
  }
  if (endBoxIndex === startBoxIndex && endLetterIndex < startLetterIndex) {
    isEndBeforeStart = true;
  }
  if (isEndBeforeStart) {
    const tempEndBoxIndex = endBoxIndex;
    const tempEndLetterIndex = endLetterIndex;
    endBoxIndex = startBoxIndex;
    endLetterIndex = startLetterIndex;
    startBoxIndex = tempEndBoxIndex;
    startLetterIndex = tempEndLetterIndex;
  }

  ctx.clearRect(0, 0, 10000, 10000);
  ctx.fillStyle = "rgba(0, 0, 255, 0.2)";

  const { x: startX, y: startY } = getXandYForLineAndLetterIndex({
    ctx,
    boxIndex: startBoxIndex,
    letterIndex: startLetterIndex,
    boxes,
  });
  const { x: endX } = getXandYForLineAndLetterIndex({
    ctx,
    boxIndex: endBoxIndex,
    letterIndex: endLetterIndex,
    boxes,
  });

  if (startBoxIndex === endBoxIndex) {
    ctx.fillRect(startX * SF, startY * SF, (endX - startX) * SF, boxes?.[startBoxIndex]?.h * SF);
    return;
  }

  const firstBox = boxes?.[startBoxIndex];
  const firstBoxEndX = firstBox?.x + firstBox?.w;
  ctx.fillRect(startX * SF, startY * SF, (firstBoxEndX - startX) * SF, firstBox?.h * SF);

  boxes?.slice(startBoxIndex + 1, endBoxIndex).forEach(box => {
    ctx.fillRect(box?.x * SF, box?.y * SF, box?.w * SF, box?.h * SF);
  });

  const lastBox = boxes?.[endBoxIndex];
  ctx.fillRect(lastBox?.x * SF, lastBox?.y * SF, (endX - lastBox?.x) * SF, lastBox?.h * SF);
};

export const deleteCharAt = ({ blocks, boxes, boxIndex, letterIndex }) => {
  const newBlocks = [...blocks];
  const box = boxes?.[boxIndex];
  const block = newBlocks?.[box?.blockIndex];

  if (letterIndex === 0 && box?.blockStartIndex === 0 && box?.type !== "table-cell") {
    const prevBlock = newBlocks?.[box?.blockIndex - 1];
    if (prevBlock?.tableContent) {
      return newBlocks;
    }
    const newBlockText = prevBlock?.text + block?.text;

    const mergedStyles = mergeBlockStyles(prevBlock, block, newBlockText.length);
    prevBlock.styles = mergedStyles;
    prevBlock.text = newBlockText;

    newBlocks.splice(box?.blockIndex, 1);

    return newBlocks;
  }

  if (box?.type === "table-cell") {
    const cellText = block.tableContent?.[box?.rowIndex][box?.columnIndex];
    const indexInCellText = box?.cellStartIndex + letterIndex;
    const newCellText = cellText.slice(0, Math.max(0, indexInCellText - 1)) + cellText.slice(indexInCellText);

    const newBlock = { ...block };
    newBlock.tableContent[box?.rowIndex][box?.columnIndex] = newCellText;
    newBlocks[box?.blockIndex] = newBlock;

    return newBlocks;
  }

  const newBlockText =
    block?.text.slice(0, box?.blockStartIndex + letterIndex - 1) +
    block?.text.slice(box?.blockStartIndex + letterIndex);

  const blockLetterIndex = box?.blockStartIndex + letterIndex;
  const newBlockStyles = block?.styles
    ?.map(style => {
      let newStyle = style;
      if (style.start <= blockLetterIndex && style.end > blockLetterIndex) {
        newStyle = {
          ...style,
          end: style.end - 1,
        };
      }

      if (style.start > blockLetterIndex) {
        newStyle = {
          ...style,
          start: style.start - 1,
          end: style.end - 1,
        };
      }

      if (newStyle.start === newStyle.end) {
        newStyle = null;
      }

      return newStyle;
    })
    ?.filter(style => !!style);

  newBlocks[box?.blockIndex] = {
    ...block,
    text: newBlockText,
    styles: newBlockStyles,
  };

  return newBlocks;
};

export const insertCharAt = ({ blocks, boxes, boxIndex, letterIndex, char }) => {
  const newBlocks = [...blocks];
  const box = boxes?.[boxIndex];
  const block = newBlocks?.[box?.blockIndex];

  if (box?.type === "table-cell") {
    const cellText = block.tableContent?.[box?.rowIndex][box?.columnIndex];
    const indexInCellText = box?.cellStartIndex + letterIndex;
    const newCellText = cellText.slice(0, indexInCellText) + char + cellText.slice(indexInCellText);

    const newBlock = { ...block };
    newBlock.tableContent[box?.rowIndex][box?.columnIndex] = newCellText;
    newBlocks[box?.blockIndex] = newBlock;
    return newBlocks;
  }

  const newBlockText =
    block?.text.slice(0, box?.blockStartIndex + letterIndex) +
    char +
    block?.text.slice(box?.blockStartIndex + letterIndex);

  const blockLetterIndex = box?.blockStartIndex + letterIndex;
  const newBlockStyles = block?.styles?.map(style => {
    if (style.start <= blockLetterIndex && style.end > blockLetterIndex) {
      return {
        ...style,
        end: style.end + 1,
      };
    }

    if (style.start > blockLetterIndex) {
      return {
        ...style,
        start: style.start + 1,
        end: style.end + 1,
      };
    }

    return style;
  });
  newBlocks[box?.blockIndex] = {
    ...block,
    text: newBlockText,
    styles: newBlockStyles,
  };

  return newBlocks;
};

export const splitBlockAt = ({ blocks, boxes, boxIndex, letterIndex }) => {
  let newBlocks = [...blocks];
  const line = boxes?.[boxIndex];
  const block = newBlocks?.[line?.blockIndex];

  if (line?.cells) {
    return [...blocks, { text: "" }];
  }

  const blockLetterIndex = line?.blockStartIndex + letterIndex;
  const upperBlockText = block?.text?.slice(0, blockLetterIndex);
  const lowerBlockText = block?.text?.slice(blockLetterIndex);

  const upperBlockStyles = block.styles
    ?.filter(style => style.start < blockLetterIndex)
    ?.map(style => {
      if (style.end > blockLetterIndex) {
        return {
          ...style,
          end: blockLetterIndex,
        };
      }
      return style;
    });
  const lowerBlockStyles = block.styles
    ?.filter(style => style.end >= blockLetterIndex)
    ?.map(style => {
      if (style.start < blockLetterIndex) {
        return {
          ...style,
          start: 0,
          end: style.end - blockLetterIndex,
        };
      }
      return {
        ...style,
        start: style.start - blockLetterIndex,
        end: style.end - blockLetterIndex,
      };
    });

  newBlocks = [
    ...blocks.slice(0, line?.blockIndex),
    { ...block, text: upperBlockText, styles: upperBlockStyles },
    { ...block, text: lowerBlockText, styles: lowerBlockStyles },
    ...blocks.slice(line?.blockIndex + 1),
  ];

  return newBlocks;
};

const TABLE_ROWS = [
  [
    "Property Address",
    "Purchase Year",
    "Property Purchase Price",
    "Current Value",
    "Rental Income (Annual)",
    "Vacancy Rate",
  ],
  ["12 Riggindale Road", "2020", "£1,683,000.00", "£2,288,880.00", " £ 200,000.00", "9800.00%"],
  ["5 Ambleside Avenue", "2018", "£1,678,713.00", "£2,283,049.68", " £ 190,870.00 ", "9920.00%"],
  ["9 Rydal Road", "2020", "£1,674,426.00", "£2,009,311.20", " £ 181,928.00 ", "9800.00%"],
  ["Flat 3, 18 Mitcham Lane", "2021", "£1,670,139.00", "£1,970,764.02", " £ 172,986.00", "9950.00%"],
];

export const insertTableBlockAt = ({ blocks, boxes, boxIndex, letterIndex }) => {
  let newBlocks = [...blocks];
  const line = boxes?.[boxIndex];
  const block = newBlocks?.[line?.blockIndex];

  const upperBlockText = block?.text?.slice(0, line?.blockStartIndex + letterIndex);
  const lowerBlockText = block?.text?.slice(line?.blockStartIndex + letterIndex);

  newBlocks = [
    ...blocks.slice(0, line?.blockIndex),
    { ...block, text: upperBlockText },
    {
      tableContent: TABLE_ROWS,
    },
    { ...block, text: lowerBlockText },
    ...blocks.slice(line?.blockIndex + 1),
  ];

  return newBlocks;
};

const getNewCellTextFromBlockBoxesForRow = (cellText, columnInd, blockBoxes, rowInd) => {
  const boxesInCell = blockBoxes.filter(box => box?.rowIndex === rowInd && box?.columnIndex === columnInd);
  const startIndex = Math.min(...boxesInCell.map(box => box?.cellStartIndex));
  const numCharsToDelete = sum(boxesInCell?.map(box => box?.text?.length));
  const endIndex = startIndex + numCharsToDelete;
  const newCellText = cellText.slice(0, startIndex) + cellText.slice(endIndex);

  return newCellText;
};

export const deleteCharsInBlocksInRange = ({
  blocks,
  boxes,
  startBoxIndex,
  startLetterIndex,
  endBoxIndex,
  endLetterIndex,
}) => {
  let isEndBeforeStart = false;
  if (endBoxIndex < startBoxIndex) {
    isEndBeforeStart = true;
  }
  if (endBoxIndex === startBoxIndex && endLetterIndex < startLetterIndex) {
    isEndBeforeStart = true;
  }
  if (isEndBeforeStart) {
    const tempEndBoxIndex = endBoxIndex;
    const tempEndLetterIndex = endLetterIndex;
    endBoxIndex = startBoxIndex;
    endLetterIndex = startLetterIndex;
    startBoxIndex = tempEndBoxIndex;
    startLetterIndex = tempEndLetterIndex;
  }

  const selectedBoxes = boxes?.slice(startBoxIndex, endBoxIndex + 1);
  const blockIndexToBoxes = groupBy(selectedBoxes, "blockIndex");
  const blockIndices = Object.keys(blockIndexToBoxes)?.map(i => parseInt(i));
  let newBlocks = [...blocks];

  const blockIndexToDeletionParams = {};

  blockIndices.forEach(blockIndex => {
    const block = newBlocks[blockIndex];
    const blockBoxes = blockIndexToBoxes[blockIndex];

    if (block?.tableContent) {
      const newtableContent = block.tableContent.map((row, rowInd) => {
        return row.map((cellText, columnInd) =>
          getNewCellTextFromBlockBoxesForRow(cellText, columnInd, blockBoxes, rowInd)
        );
      });
      newBlocks[blockIndex].tableContent = newtableContent;
      if (newtableContent.every(row => row.every(cell => !cell))) {
        newBlocks[blockIndex] = {
          text: "",
          styles: [],
        };
      }

      return;
    }

    let start = 0;
    if (blockIndex === selectedBoxes[0]?.blockIndex) {
      start = selectedBoxes[0]?.blockStartIndex + startLetterIndex;
    }

    let end = block?.text?.length;
    if (blockIndex === selectedBoxes[selectedBoxes.length - 1]?.blockIndex) {
      end = selectedBoxes[selectedBoxes.length - 1]?.blockStartIndex + endLetterIndex;
    }

    blockIndexToDeletionParams[blockIndex] = {
      start,
      end,
    };
  });

  Object.keys(blockIndexToDeletionParams).forEach(blockIndex => {
    const block = newBlocks[blockIndex];
    const { start, end } = blockIndexToDeletionParams[blockIndex];
    const newBlockText = block?.text.slice(0, start) + block?.text.slice(end);
    newBlocks[blockIndex] = { ...block, text: newBlockText };
  });

  newBlocks = newBlocks.filter((block, blockIndex) => {
    if (blockIndices?.includes(blockIndex.toString()) && !block?.tableContent) {
      return block?.text?.length > 0;
    }
    return true;
  });

  return [newBlocks, startBoxIndex, startLetterIndex];
};

export const addStyle = (styles = [], newStyle, textLength) => {
  const allStyles = [...(styles || []), newStyle];
  const innerEdges = uniq(
    allStyles
      .map(({ start, end }) => [start, end ?? textLength])
      .flat(Infinity)
      .sort((a, b) => a - b)
  )?.filter(edge => edge !== 0 && edge !== textLength);
  const allEdges = uniq([0, ...innerEdges, textLength]);

  const pairedEdges = [...allEdges, ...innerEdges].sort((a, b) => a - b);
  const edgePairs = chunk(pairedEdges, 2);
  const newStyles = [];

  edgePairs.forEach(([start, end]) => {
    const doesNewStyleApply = start >= newStyle.start && end <= newStyle.end;
    const style = allStyles.find(style => start >= style.start && end <= style.end);
    if (doesNewStyleApply) {
      newStyles.push({
        ...style,
        ...newStyle,
        start,
        end,
        fontWeight: newStyle?.fontWeight || style?.fontWeight || "normal",
        fontStyle: newStyle?.fontStyle || style?.fontStyle || "normal",
        fontColor: newStyle?.fontColor || style?.fontColor || "#000000",
      });
      return;
    }

    newStyles.push({
      ...style,
      start,
      end,
      fontWeight: style?.fontWeight || "normal",
      fontStyle: style?.fontStyle || "normal",
      fontColor: style?.fontColor || "#000000",
    });
  });

  // merge adjacent styles with same properties
  const mergedStyles = newStyles.reduce((acc, curr) => {
    const lastStyle = acc[acc.length - 1];
    if (
      lastStyle?.fontWeight === curr.fontWeight &&
      lastStyle?.fontStyle === curr.fontStyle &&
      lastStyle?.fontColor === curr.fontColor &&
      JSON.stringify(lastStyle?.meta) === JSON.stringify(curr?.meta)
    ) {
      return [
        ...acc.slice(0, acc.length - 1),
        {
          ...curr,
          meta: lastStyle.meta,
          start: lastStyle.start,
          end: curr.end,
          fontWeight: curr.fontWeight,
          fontStyle: curr.fontStyle,
          fontColor: curr.fontColor,
        },
      ];
    }

    return [...acc, curr];
  }, []);

  return mergedStyles;
};

const mergeBlockStyles = (prevBlock, block, textLength) => {
  let mergedStyles = [
    ...(prevBlock?.styles || []),
    ...(block?.styles?.map(style => {
      return {
        ...style,
        start: style.start + (prevBlock?.text?.length || 0),
        end: style.end + (prevBlock?.text?.length || 0),
      };
    }) || []),
  ];

  const maxFontSize = Math.max(...(prevBlock?.styles || []).map(style => style.fontSize));

  if (maxFontSize > 0) {
    mergedStyles = addStyle(
      mergedStyles,
      {
        start: 0,
        end: prevBlock.text.length,
        fontSize: maxFontSize,
      },
      textLength
    );
  }

  return mergedStyles;
};

export const isEndBeforeStart = (
  selection = {
    startBlockIndex: null,
    startLetterIndex: null,
    endBlockIndex: null,
    endLetterIndex: null,
  }
) => {
  const { startBlockIndex, startLetterIndex, endBlockIndex, endLetterIndex } = selection;
  if (endBlockIndex === null || endLetterIndex === null) {
    return false;
  }

  if (startBlockIndex < endBlockIndex) {
    return false;
  }
  if (startBlockIndex === endBlockIndex) {
    return startLetterIndex > endLetterIndex;
  }

  return true;
};

export const getSelectionTopBarState = (blocks, selection) => {
  const { startBlockIndex, startLetterIndex, endBlockIndex, endLetterIndex } = selection;

  let selectionFontSize = blocks?.[startBlockIndex]?.blockStyles?.fontSize || 14;
  let isSelectionList =
    blocks?.[startBlockIndex]?.blockStyles?.leftIndent > 0 && !!blocks?.[startBlockIndex]?.blockStyles?.prefix;

  if (endBlockIndex === null || endLetterIndex === null || isNaN(endLetterIndex) || isNaN(endBlockIndex)) {
    return {
      isSelectionBold: false,
      isSelectionItalic: false,
      selectionFontColor: "#000000",
      selectionFontSize,
      isSelectionList,
    };
  }

  if (startBlockIndex === endBlockIndex && startLetterIndex === endLetterIndex) {
    return {
      isSelectionBold: false,
      isSelectionItalic: false,
      selectionFontColor: "#000000",
      selectionFontSize,
      isSelectionList,
    };
  }

  let isSelectionBold = true;
  let isSelectionItalic = true;
  let selectionFontColor = "#000000";

  let blockIndex = startBlockIndex;
  let charIndex = startLetterIndex;
  while (blockIndex <= endBlockIndex) {
    if (blockIndex >= endBlockIndex && charIndex >= endLetterIndex) {
      break;
    }
    const block = blocks?.[blockIndex];

    if (block.text.length === 0) {
      blockIndex += 1;
      continue;
    }

    if (!block?.blockStyles?.leftIndent || !block?.blockStyles?.prefix) {
      isSelectionList = false;
    }

    const charStyle = block?.styles?.find(style => style.start <= charIndex && style.end > charIndex);
    if (!charStyle || charStyle?.fontWeight !== "bold") {
      isSelectionBold = false;
    }
    if (!charStyle || charStyle?.fontStyle !== "italic") {
      isSelectionItalic = false;
    }
    if (charStyle?.fontColor) {
      selectionFontColor = charStyle.fontColor;
    }

    charIndex += 1;
    if (charIndex >= block.text.length) {
      blockIndex += 1;
      charIndex = 0;
    }
  }

  return {
    isSelectionBold,
    isSelectionItalic,
    selectionFontColor,
    selectionFontSize,
    isSelectionList,
  };
};

export const getBoxAndLetterIndexFromExternalCursor = ({ blockIndex = null, blockLetterIndex = null, boxes = [] }) => {
  let boxIndex = 0;
  while (boxIndex < boxes?.length) {
    const box = boxes?.[boxIndex];
    if (box?.blockIndex !== blockIndex) {
      boxIndex += 1;
      continue;
    }

    if (blockLetterIndex >= box?.blockStartIndex && blockLetterIndex <= box?.blockStartIndex + box?.text?.length) {
      return { boxIndex, letterIndex: blockLetterIndex - box?.blockStartIndex };
    }

    boxIndex += 1;
  }

  return { boxIndex: null, letterIndex: null };
};
