import { Chip as MuChip } from "@material-ui/core";

const Chip = ({
  label,
  color, 
  onClick
}) => {
  return <MuChip label={label} color={color}  onClick={onClick}/>;
}


export default Chip