import styled from "styled-components";

import ModelViewer from "components/widgets/ModelViewer";
import ModelTextEditor from "components/widgets/ModelTextEditor";
import ModelCodeViewer from "components/widgets/ModelCodeViewer";

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

const TextEditorContainer = styled.div`
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const ModelArchView = ({
  architecture,
  modelId,
  modelView = "Config",
  datasetFeatureTypeDescriptors,
  inputCols,
  outputCols,
}) => {
  if (!architecture) {
    return null;
  }

  return (
    <Container>
      {modelView === "Config" && (
        <TextEditorContainer>
          <ModelTextEditor modelConfig={architecture} />
        </TextEditorContainer>
      )}
      {modelView === "Graph" && (
        <ModelViewer
          modelConfig={architecture}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
          inputCols={inputCols}
          outputCols={outputCols}
        />
      )}
      {modelView === "Code" && <ModelCodeViewer modelId={modelId} />}
    </Container>
  );
};

export default ModelArchView;
