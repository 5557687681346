import { useState } from "react";
import styled from "styled-components";

import { synthesizeTextLabelDataPoint } from "api/services/deploymentService";
import Button from "components/ui/Button";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";

const SynthButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const GeneratedTxt = styled.div`
  padding: 10px;
  width: 400px;
  font-size: 18px;
  height: 100px;
  border-radius: 10px;
  overflow-y: auto;
  background-color: ${props => props.theme.color.closer0};
`;

const GeneratedTxtLoading = styled.div`
  width: 400px;
  height: 100px;
  background-color: ${props => props.theme.color.closer0};
`;

const PredictedLabel = styled.div`
  padding: 10px;
  font-size: 18px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const TextAndLabel = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-direction: column;
`;

const TitleText = styled.label`
  color: ${props => props.theme.color.closest};
`;

const DataSynthesis = ({
  deploymentClusterId,
  isSelected,
  onSelect,
  trainingJobId,
  modelConfig,
  datasetFeatureTypeDescriptors,
}) => {
  const [synthesisResult, setSynthesisResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doSynthesizeDataPoint = async () => {
    setIsFetchingPrediction(true);
    const { data, error } = await synthesizeTextLabelDataPoint(deploymentClusterId, "synthesize_text_and_label");
    setSynthesisResult({
      ...data,
      text: data.text.filter(e => !(e === "<START>" || e === "<END>")).join(" "),
    });
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <TitleText>
        <input type="checkbox" checked={isSelected} readOnly />
        Data synthesis
      </TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{}}
        outputCols={["sentence_text", "sentence_label"]}
        isEnabled={isSelected}
        trainingJobId={trainingJobId}
        tag="synthesize_text_and_label"
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
      />
    </LabelAndModals>
  );

  if (!synthesisResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <SynthButton onClick={doSynthesizeDataPoint} value="Synthesize" />
          <TextAndLabel>
            <GeneratedTxtLoading />
            <PredictedLabel>&nbsp;&nbsp;</PredictedLabel>
          </TextAndLabel>
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <SynthButton value="Synthesize" onClick={doSynthesizeDataPoint} />
        <TextAndLabel>
          <GeneratedTxt>{synthesisResult?.text}</GeneratedTxt>
          <PredictedLabel>{synthesisResult?.label.winner}</PredictedLabel>
        </TextAndLabel>
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default DataSynthesis;
