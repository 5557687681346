import styled from "styled-components";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

const Buttons = styled.div`
  padding-top: 20px;
  justify-content: end;
  display: flex;
`;

const ButtonContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;

  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PrevNextPageButtons = ({ isPrevDisabled, onClickPrev, isNextDisabled, onClickNext }) => (
  <Buttons>
    <ButtonContainer onClick={onClickPrev} isDisabled={isPrevDisabled}>
      <ArrowBack />
    </ButtonContainer>
    <ButtonContainer onClick={onClickNext} isDisabled={isNextDisabled}>
      <ArrowForward />
    </ButtonContainer>
  </Buttons>
);

export default PrevNextPageButtons;
