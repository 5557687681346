import styled from "styled-components";
import { useState, useEffect } from "react";
import { CloudDownloadOutlined } from "@material-ui/icons";

import { getTrainingJobById, downloadOutputTar } from "api/services/jobService";
import useTrainingProgressForJobId from "api/services/jobService/useTrainingProgressForJobId";
import TrainingJobLineChart from "components/widgets/TrainingJobLineChart";

const Container = styled.div`
  height: 500px;
`;

const DownloadBlock = styled.div`
  display: flex;
  justify-content: center;
`;

const DownloadButton = styled.div`
  ${props => props.isDisabled && "opacity: 0.1; pointer-events: none;"}
  margin: 10px;
  display: flex;
  padding: 10px;
  height: 30px;
  width: 200px;
  size: 14px;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.color.closest};
  border: 1px solid ${props => props.theme.color.closest};
  border-radius: 50px;

  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    border-color: ${props => props.theme.color.primary};
  }
`;

const DownloadIcon = styled.div``;

const TrainingProgressViewCustom = ({ pipelineOutput }) => {
  const [jobWorkerId, setJobWorkerId] = useState(null);

  const jobId = pipelineOutput?.trainingJob?.id;

  const [trainingJobProgressPoints, jobStatus] = useTrainingProgressForJobId(jobId);

  useEffect(() => {
    process.env.REACT_APP_IS_RESEARCH && jobId && doGetJobWorkerId();
  }, [jobId]);

  const doGetJobWorkerId = async () => {
    const { data, error } = await getTrainingJobById(jobId);
    if (error) {
      return;
    }
    setJobWorkerId(data.workerId);
  };

  if (trainingJobProgressPoints?.length === 0) {
    return "Waiting for plot data...";
  }

  const doDownload = async () => {
    await downloadOutputTar(jobId);
  };

  return (
    <Container>
      <DownloadBlock>
        <DownloadButton isDisabled={jobStatus !== "DONE"} onClick={doDownload}>
          Download
          <DownloadIcon>
            <CloudDownloadOutlined />
          </DownloadIcon>
        </DownloadButton>
      </DownloadBlock>
      {process.env.REACT_APP_IS_RESEARCH && (
        <div>
          Job Id: {jobId}, Worker Id: {jobWorkerId}
        </div>
      )}
      <TrainingJobLineChart data={trainingJobProgressPoints} />
    </Container>
  );
};

export default TrainingProgressViewCustom;
