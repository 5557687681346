import { useEffect } from "react";

const DocumentationPage = () => {
  useEffect(() => {
    window.location.href = "http://35.246.13.175:8080/";
  }, []);

  return (<></>)
}

export default DocumentationPage;