import { zapiPost, zapiPatch, zapiGet, zapiPut } from "api/zapi";

export const getDeploymentManagementConfig = async () => {
  const { data, error } = await zapiGet("/deployment-service/api/v1/management-config");
  return { data, error };
};

export const putDeploymentManagementConfig = async config => {
  const { data, error } = await zapiPut("/deployment-service/api/v1/management-config", {}, config);
  return { data, error };
};

export const getDeployments = async params => {
  const { data, error } = await zapiGet("/deployment-service/api/v1/deployments", params);
  return { data, error };
};

export const createDeployment = async ({ body }) => {
  const { data, error } = await zapiPost("/deployment-service/api/v1/deployments", {}, body);
  return { data, error };
};

export const updateDeploymentById = async (deploymentId, body) => {
  const { data, error } = await zapiPatch(`/deployment-service/api/v1/deployments/${deploymentId}`, {}, body);
  return { data, error };
};

export const getPredictions = async params => {
  const { data, error } = await zapiGet("/deployment-service/api/v1/predictions", params);
  return { data, error };
};

export const getPredictionForColumns = async (deploymentId, taskId, inputValues) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentId}/predictions`,
    {},
    {
      taskId,
      samples: 1,
      inputs: [inputValues],
    }
  );
  return { data, error };
};

export const getPredictionForImage = async (deploymentId, taskId, base64ImageString) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentId}/predictions`,
    {},
    {
      taskId,
      inputs: [
        {
          image: base64ImageString,
          label: null,
        },
      ],
    }
  );
  return { data, error };
};

export const getPredictionForText = async (deploymentId, taskId, inputWordList) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentId}/predictions`,
    {},
    {
      sample: 1,
      taskId: taskId,
      inputs: [{ sentence_text: inputWordList }],
    }
  );
  return { data, error };
};

export const postDeployment = async body => {
  const { data, error } = await zapiPost("/deployment-service/api/v1/deployments", {}, body);
  return { data, error };
};

export const patchDeploymentCluster = async (deploymentClusterId, numInstances) => {
  const { data, error } = await zapiPatch(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}`,
    {},
    {
      instanceCount: numInstances,
    }
  );
  return { data, error };
};

export const getDeploymentClusters = async params => {
  const { data, error } = await zapiGet("/deployment-service/api/v1/deployments", params);
  return { data, error };
};

export const getDeploymentClusterOutliers = async (deploymentClusterId, inputValues) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}/outlier-detection`,
    {},
    {
      inputs: [inputValues],
    }
  );
  return { data, error };
};

export const getDeploymentClusterPredictionForCsv = async (deploymentClusterId, body) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}/predictions`,
    {},
    body
  );
  return { data, error };
};

export const getDeploymentClusterPredictionForImageLabelData = async (deploymentClusterId, body) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}/predictions`,
    {},
    body
  );
  return { data, error };
};

export const getDeploymentClusterPredictionForTextGeneration = async (deploymentClusterId, body) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}/text-generation`,
    {},
    body
  );
  return { data, error };
};

export const getDeploymentClusterPredictionForTextLabelData = async body => {
  const { data, error } = await zapiPost(`/deployment-service/api/v1/predictions`, {}, body);
  return { data, error };
};

export const contentMatching = async (deploymentClusterId, query) => {
  const { data, error } = await zapiPost(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}/content-matching`,
    {},
    query
  );
  return { data, error };
};

export const getDeploymentClusterById = async deploymentClusterId => {
  const { data, error } = await zapiGet(`/deployment-service/api/v1/deployments/${deploymentClusterId}`);
  return { data, error };
};

export const getOnlineEvaluationReport = async params => {
  const { data, error } = await zapiGet("/deployment-service/api/v1/online-evaluation-report", params);
  return { data, error };
};

export const getSampleDataFromDeployment = async (deploymentClusterId, params = {}) => {
  const { data, error } = await zapiGet(
    `/deployment-service/api/v1/deployments/${deploymentClusterId}/sample-data`,
    params
  );
  return { data, error };
};

export const getDataConfigFromDeployment = async deploymentClusterId => {
  const { data, error } = await zapiGet(`/deployment-service/api/v1/deployments/${deploymentClusterId}/data-config`);
  return { data, error };
};
