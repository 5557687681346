import { format, subYears } from "date-fns";
import { isEqual, range } from "lodash";

import * as backend from "api/backend/projectServiceEndpoints";
import * as githubBackend from "api/backend/githubServiceEndpoints";
import * as dataBackend from "api/backend/dataServiceEndpoints";
import * as githubService from "api/services/githubService";
import { saveModel } from "api/backend/modelServiceEndpoints";
import { registerRecommendedModels } from "api/backend/modelServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";
import * as dataService from "api/services/dataService";
import { apiGetRawResponse } from "api/api-http-methods";
import { sleep, parseJson } from "utils/common";
import * as jobService from "api/services/jobService";
import { hiddenCubeIds, hiddenDatasetIds } from "App";
import { getLoggedInUserName } from "api/services/authenticationService";
import { zapiPost } from "api/zapi";
import { getCustomCodeTemplate } from "api/backend/jobServiceEndpoints";

const placeholderUserProfile = {
  image: "/images/avatar-placeholder.jpeg",
};

export const getLatestPipelineOutputFromCubeId = async cubeId => {
  const { data, error } = await backend.getLatestPipelineOutputByCubeId(cubeId);
  return { data, error };
};

export const getFeaturetypeDescriptorsFromPipelineId = async pipelineId => {
  const { data: pipelineOutput, error } = await backend.getPipelineConfigOuputById(pipelineId);
  return { data: pipelineOutput?.dataset?.config?.featureTypeDescriptors, error: getHandlingFromError(error) };
};

export const getUserProfileByUserName = async userName => {
  const { data, error } = await backend.getUserProfileByUserName(userName);
  if (error) {
    if (error?.message?.includes("UserProfile not found")) {
      return { data: placeholderUserProfile, error: null };
    }

    return { data: null, error: getHandlingFromError(error) };
  }
  return { data: data.image ? data : { ...data, image: "/images/avatar-placeholder.jpeg" }, error };
};

export const updateUserProfile = async profileData => {
  const { data, error } = await backend.updateUserProfile(profileData);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const isLoggedInUserTokenValid = async () => {
  const { error } = await backend.getLoggedInUserProfile();
  if (error) {
    return false;
  }
  return true;
};

export const getAllVisibleProject = async () => {
  const { data, error } = await backend.getAllVisibleProjects();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getProjectByProjectId = async projectId => {
  const { data, error } = await backend.getProjectByProjectId(projectId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getProjectOwnedByUsername = async userName => {
  const { data, error } = await backend.getProjectOwnedByUsername(userName);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const createProject = async projectConfig => {
  const { data, error } = await backend.createCube(projectConfig);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const updateProjectByProjectId = async (projectId, fieldsToUpdate) => {
  const { data, error } = await backend.updateProject(projectId, fieldsToUpdate);
  return { data, error: getHandlingFromError(error) };
};

export const bootstrapProjectWithDataset = async dataset => {
  // register recommended models
  const { data, error } = registerRecommendedModels(dataset.id);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  } else {
    const cubeConfig = {
      projectName: `Project from ${dataset.name}`,
      datasetId: dataset.id,
      models: [data],
      trainingJobs: [],
    };
    return createProject(cubeConfig);
  }
};

export const getBillOfLoggedInUser = async (from, to) => {
  const { data, error } = await backend.getBillOfLoggedInUser(from, to);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

// cubes

export const getAllVisibleCubes = async params => {
  const { data, error } = await backend.getAllVisibleCubes(params);
  return { data, error: getHandlingFromError(error) };
};

export const getCubeByCubeId = async cubeId => {
  const { data, error } = await backend.getCubeByCubeId(cubeId);
  return { data, error: getHandlingFromError(error) };
};

export const createCube = async cubeConfig => {
  const { data, error } = await backend.createCube(cubeConfig);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const patchCube = async (cubeId, fieldsToUpdate) => {
  const { data, error } = await backend.patchCube(cubeId, fieldsToUpdate);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const deleteCube = async cubeId => {
  const { data, error } = await backend.deleteCube(cubeId);
  return { data, error: getHandlingFromError(error) };
};

export const getCubeByPipelineId = async pipelineId => {
  const { data, error } = await getAllVisibleCubes();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const cube = data.find(cube => cube.pipelineIds.includes(pipelineId));
  return { data: cube, error: null };
};

// pipelines
export const getPipelineConfigById = async pipelineId => {
  const { data, error } = await backend.getPipelineConfigById(pipelineId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getPipelineConfigOuputById = async pipelineId => {
  const { data, error } = await backend.getPipelineConfigOuputById(pipelineId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getPipelineConfigsByIds = async pipelineIds => {
  if (!pipelineIds?.length) {
    return { data: [], error: { message: "getPipelineConfigsByIds : no pipeline IDs given" } };
  }

  const pipelineConfigResults = await Promise.all(
    pipelineIds.map(pipelineId => backend.getPipelineConfigById(pipelineId))
  );
  if (pipelineConfigResults.find(result => result.error)) {
    return {
      data: [],
      error: getHandlingFromError(pipelineConfigResults.find(result => result.error)),
    };
  }

  return { data: pipelineConfigResults.map(result => result.data), error: null };
};

export const getPipelineEnrichedOutputsByIds = async pipelineIds => {
  if (!pipelineIds) {
    return { data: [], error: { message: "getPipelineEnrichedOutputsByIds : no pipeline IDs given" } };
  }

  if (pipelineIds.includes(undefined)) {
    return { data: [], error: { message: "getPipelineEnrichedOutputsByIds: a pipelineId is undefined" } };
  }

  const pipelineOutputResults = await Promise.all(
    pipelineIds.map(async pipelineId => {
      const pipelineOutput = await backend.getPipelineConfigOuputById(pipelineId);
      const pipelineConfig = await backend.getPipelineConfigById(pipelineId);
      return {
        data: { ...pipelineConfig.data, ...pipelineOutput.data },
        error: pipelineOutput.error || pipelineConfig.error,
      };
    })
  );

  if (pipelineOutputResults.find(result => result.error)) {
    return {
      data: [],
      error: getHandlingFromError(pipelineOutputResults.find(result => result.error)?.error),
    };
  }

  const pipelineOutputs = pipelineOutputResults.map(result => result.data);

  try {
    pipelineOutputs.forEach(pipelineOutput => {
      if (!pipelineOutput.trainingBundle) {
        return;
      }

      pipelineOutput.trainingBundle.evaluationJobs.forEach(evalJob => {
        if (!evalJob.result) {
          return;
        }
        evalJob.result = JSON.parse(evalJob.result);
      });
    });
  } catch {
    return {
      data: [],
      error: getHandlingFromError({
        type: "JSON_PARSE_FAIL",
        message: "Failed to parse evaluationJob.result in pipelineOutput.trainingBundle",
      }),
    };
  }

  return { data: pipelineOutputs, error: null };
};

export const getRLPipelineOutputsByIds = async pipelineIds => {
  if (!pipelineIds) {
    return { data: [], error: { message: "getRLPipelineOutputsByIds : no pipeline IDs given" } };
  }

  if (pipelineIds.includes(undefined)) {
    return { data: [], error: { message: "getRLPipelineOutputsByIds: pipeline id is undefined" } };
  }

  const pipelineOutputResults = await Promise.all(
    pipelineIds.map(pipelineId => backend.getRLPipelineConfigOuputById(pipelineId))
  );

  const resultWithError = pipelineOutputResults.find(result => result.error);
  if (resultWithError) {
    return {
      data: [],
      error: getHandlingFromError(resultWithError?.error),
    };
  }

  return { data: pipelineOutputResults.map(result => result.data), error: null };
};

export const createNewPipelineOrSubmitDraft = async (cubeId, pipelineId) => {
  const { data: pipelineConfig, error: fetchConfigErr } = await getPipelineConfigById(pipelineId);
  if (fetchConfigErr) {
    return { data: null, error: fetchConfigErr };
  }

  const { data: isGithubCube } = await githubService.getIsCubeAGithubCube(cubeId);
  if (isGithubCube) {
    if (pipelineConfig.status === "DRAFT") {
      const { error } = await githubBackend.patchPipelineConfig(pipelineId, { status: "SUBMITTED" });
      return { data: null, error };
    }

    const { error } = await githubBackend.postPipelineConfig(pipelineConfig);
    return { data: null, error };
  }

  if (pipelineConfig.status === "DRAFT") {
    const { error } = await patchPipelineConfig(pipelineId, { status: "SUBMITTED" });
    return { data: null, error };
  }

  const { error } = await submitPipelineConfig(cubeId, pipelineConfig);
  return { data: null, error };
};

export const postPipelineConfig = async pipelineConfg => {
  const { data, error } = await backend.postPipelineConfig(pipelineConfg);
  return { data, error: getHandlingFromError(error) };
};

export const submitPipelineConfig = async (cubeId, pipelineConfig) => {
  const { data: isGithubCube } = await githubService.getIsCubeAGithubCube(cubeId);
  if (isGithubCube) {
    const { data, error } = await githubBackend.postPipelineConfig({ ...pipelineConfig, status: "SUBMITTED" });
    return { data, error: getHandlingFromError(error) };
  } else {
    const { data, error } = await backend.postPipelineConfig({ ...pipelineConfig, status: "SUBMITTED" });
    return { data, error: getHandlingFromError(error) };
  }
};

export const submitDraftPipelineConfig = async (cubeId, pipelineId, pipelineConfig) => {
  const { data: isGithubCube } = await githubService.getIsCubeAGithubCube(cubeId);
  if (isGithubCube) {
    const { data, error } = await githubBackend.patchPipelineConfig(pipelineId, {
      ...pipelineConfig,
      status: "SUBMITTED",
    });
    return { data: data, error: getHandlingFromError(error) };
  }

  const { data, error } = await backend.patchPipelineConfig(pipelineId, { ...pipelineConfig, status: "SUBMITTED" });
  return { data: data, error: getHandlingFromError(error) };
};

export const submitRLPipelineConfig = async pipelineConfig => {
  const { data, error } = await backend.postRLPipelineConfig(pipelineConfig);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const patchPipelineConfig = async (pipelineId, pipelineConfig) => {
  const { data, error } = await backend.patchPipelineConfig(pipelineId, pipelineConfig);
  return { data, error };
};

export const getPipelineOutputByCommitSha = async commitSha => {
  let { data, error } = await githubBackend.getPipelineIdFromCommitSha(commitSha);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  ({ data, error } = await backend.getPipelineConfigOuputById(data.pipelineId));
  return { data, error };
};

export const getPipelineOutputsByCommitShas = async commitShas => {
  const pipelineResults = await Promise.all(
    commitShas.map(commitSha => githubBackend.getPipelineIdFromCommitSha(commitSha))
  );
  const pipelineIds = pipelineResults.filter(result => result.data).map(result => result.data.pipelineId);

  const { data, error } = await getPipelineEnrichedOutputsByIds(pipelineIds);
  return { data, error };
};

export const launchNewDataJobAndSavePipelineDraft = async (cubeId, dataProcessConfig) => {
  const { data: processingJob, error } = await dataBackend.postDataProcessingJob(dataProcessConfig);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const jobId = processingJob.id;
  const pipelineConfig = {
    datasetId: null,
    modelId: null,
    dataProcessingJobId: jobId,
    cubeId: cubeId,
    status: "DRAFT",
  };
  const { data: isGithubCube } = await githubService.getIsCubeAGithubCube(cubeId);
  const { data, error: pipelineErr } = isGithubCube
    ? await githubBackend.postPipelineConfig(pipelineConfig)
    : await backend.postPipelineConfig(pipelineConfig);

  return { data, error: getHandlingFromError(pipelineErr) };
};

export const createPipelineDraftWithNewModelAndTrainingConfig = async (
  cubeId,
  datasetId,
  trainingConfig,
  model,
  pipelineOutputModel,
  trainingSearchValues,
  customerCode
) => {
  const pipelineConfig = {
    cubeId,
    datasetId,
    modelConfig: {
      ...pipelineOutputModel,
      trainingConfig,
    },
    status: "DRAFT",
  };

  if (trainingSearchValues) {
    pipelineConfig.trainingSearchValues = trainingSearchValues;
  }

  if (customerCode) {
    pipelineConfig.customCode = customerCode;
  }

  const { data: isGithubCube } = await githubService.getIsCubeAGithubCube(cubeId);
  if (isGithubCube) {
    const { data: savedPipelineDraft, error: savePipelineDraftError } = await githubBackend.postPipelineConfig(
      pipelineConfig
    );
    return {
      data: savedPipelineDraft,
      error: getHandlingFromError(savePipelineDraftError),
    };
  }

  const { data: savedPipelineDraft, error: savePipelineDraftError } = await backend.postPipelineConfig(pipelineConfig);
  return {
    data: savedPipelineDraft,
    error: getHandlingFromError(savePipelineDraftError),
  };
};

export const updatePipelineDraftWithNewModelAndTrainingConfig = async (
  cubeId,
  pipelineId,
  datasetId,
  trainingConfig,
  model,
  pipelineOutputModel,
  trainingSearchValues,
  customerCode
) => {
  const pipelineConfig = {
    cubeId,
    datasetId,
    modelConfig: {
      ...pipelineOutputModel,
      trainingConfig,
    },
    status: "DRAFT",
  };

  if (trainingSearchValues) {
    pipelineConfig.trainingSearchValues = trainingSearchValues;
  }

  if (customerCode) {
    pipelineConfig.customCode = customerCode;
  }

  const { data: isGithubCube } = await githubService.getIsCubeAGithubCube(cubeId);
  if (isGithubCube) {
    const { data: savedPipelineDraft, error: savePipelineDraftError } = await githubBackend.patchPipelineConfig(
      pipelineId,
      pipelineConfig
    );
    return {
      data: savedPipelineDraft,
      error: getHandlingFromError(savePipelineDraftError),
    };
  }

  const { data: updatedPipelineDraft, error: savePipelineDraftError } = await backend.patchPipelineConfig(
    pipelineId,
    pipelineConfig
  );
  return {
    data: updatedPipelineDraft,
    error: getHandlingFromError(savePipelineDraftError),
  };
};

const getCubeConfig = (cubeName, isPublic, isRL) => {
  const config = {
    name: cubeName,
    isRL,
  };
  if (isPublic) {
    config.sharedWith = [
      {
        userGroupId: "PUBLIC",
        accessLevel: "READ_ONLY",
      },
    ];
  }
  return config;
};

export const createCubeAndSubmitPipeline = async (cubeName, isPublic, isRL, datasetId, modelId) => {
  const cubeConfig = getCubeConfig(cubeName, isPublic, isRL);

  const { data: cube, error: cubeCreateError } = await createCube(cubeConfig);
  if (cubeCreateError) {
    return { data: null, error: getHandlingFromError(cubeCreateError) };
  }

  const { data: trainingConfig, error: reccConfigErr } = await jobService.getRecommendedTrainingConfig();
  if (reccConfigErr) {
    return { data: null, error: reccConfigErr };
  }

  const trainingConfigWithWorkerType = {
    ...trainingConfig,
    workerType: process.env.REACT_APP_IS_RESEARCH ? "GPU" : "CPU_LARGE",
  };

  const pipelineConfg = {
    datasetId,
    modelId,
    cubeId: cube.id,
    trainingConfig: trainingConfigWithWorkerType,
    status: "SUBMITTED",
  };

  const { error: pipelineSubmitErr } = cube.isRL
    ? await submitRLPipelineConfig(pipelineConfg)
    : await submitPipelineConfig(cube.id, pipelineConfg);
  if (pipelineSubmitErr) {
    return { data: null, error: getHandlingFromError(pipelineSubmitErr) };
  }

  return { data: cube, error: null };
};

const getTargetUrlAndNameFromActivity = activity => {
  const targetTypeToPathMap = {
    DATASET: "data",
    MODEL: "model",
    CUBE: "cube",
  };

  return { name: activity.targetId, url: `/${targetTypeToPathMap[activity?.targetType]}/${activity.targetId}` };
};

export const getUserActivitiesOfUserGroupedByDay = async userId => {
  const oneYearAgo = subYears(new Date(), 1);
  const { data: activities, error } = await backend.getRecentActivities({ userId, from: oneYearAgo });
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const activitiesByDay = {};
  activities
    .filter(
      activity => activity.targetType === "CUBE" || activity.targetType === "MODEL" || activity.targetType === "DATASET"
    )
    .forEach(activity => {
      const date = new Date(activity.timestamp);
      const formattedDay = format(date, "MMM d, yyyy");

      const { url, name } = getTargetUrlAndNameFromActivity(activity);
      activity.targetUrl = url;
      activity.targetName = name;

      activitiesByDay[formattedDay] = activitiesByDay[formattedDay]
        ? [...activitiesByDay[formattedDay], activity]
        : [activity];
    });

  return { data: activitiesByDay, error: getHandlingFromError(error) };
};

export const getSearchResults = async query => {
  const { data, error } = await backend.getSearchResults(query);
  const filteredResults = data
    .filter(cube => !hiddenCubeIds.includes(cube.id))
    .filter(dataset => !hiddenDatasetIds.includes(dataset.id));
  if (error) {
    return [];
  }
  return { data: filteredResults, error: null };
};

export const getNaturalLanguageSearchResults = async query => {
  const { data, error } = await backend.postSearchNlpCommand(query);
  return { data, error: getHandlingFromError(error) };
};

export const getNaturalLanguageSearchCommandCode = async query => {
  const { data, error } = await backend.postSearchNlpCommandCode(query);
  return { data, error: getHandlingFromError(error) };
};

export const getFileListByPipelineId = async pipelineId => {
  const { data, error } = await backend.getFileListByPipelineId(pipelineId);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const getFileContentByPipelineIdAndFileName = async (pipelineId, filePath) => {
  const { data, error } = await backend.getFileContentByPipelineIdAndFileName(pipelineId, filePath);
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error };
};

export const downloadPipelieFile = async (pipelineId, filePath) => {
  const res = await apiGetRawResponse(`/project-service/api/v1/pipeline-configs/${pipelineId}/file-content`, {
    filePath,
  });
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = filePath.split("/").at(-1);
  anchor.click();
  window.URL.revokeObjectURL(objectUrl);
};

export const getBooksSearchResult = async (
  textQuery = "",
  inputDescription = "",
  imageTensor = null,
  maskTensor = null,
  number = 10
) => {
  const reqBody = {
    number,
    query: textQuery
      .replaceAll("\n", " ")
      .split(" ")
      .filter(word => word.length > 0),
    text: inputDescription.split(" "),
    cover_image: imageTensor,
    "cover_image-mask": maskTensor,
  };
  const { data, error } = await backend.getBooksSearchResult(reqBody);
  return { data, error };
};

export const fetchProposalsForDatapoint = async datapoint => {
  await sleep(500);
  return {
    data: [
      {
        Age: {
          score: 0.4,
          value: 44,
        },
        Sex: {
          score: 0.5,
          value: "male",
        },
        Job: {
          score: -0.1,
          value: 2,
        },
        Housing: {
          score: 0.9,
          value: "own",
        },
        Saving_accounts: {
          score: -0.7,
          value: "little",
        },
        Checking_account: {
          score: 0.1,
          value: "moderate",
        },
        Credit_amount: {
          score: 0.1,
          value: 1169,
        },
        Duration: {
          score: 0.3,
          value: 12,
        },
        Purpose: {
          score: 0.4,
          value: "radio/TV",
        },
        Risk: {
          score: null,
          value: "good",
        },
      },
      {
        Age: {
          score: 0.1,
          value: 38,
        },
        Sex: {
          score: 0.3,
          value: "male",
        },
        Job: {
          score: -0.1,
          value: 2,
        },
        Housing: {
          score: 0.9,
          value: "own",
        },
        Saving_accounts: {
          score: -0.1,
          value: "moderate",
        },
        Checking_account: {
          score: 0.5,
          value: "moderate",
        },
        Credit_amount: {
          score: 0.2,
          value: 2021,
        },
        Duration: {
          score: 0.5,
          value: 24,
        },
        Purpose: {
          score: 0.2,
          value: "radio/TV",
        },
        Risk: {
          score: null,
          value: "good",
        },
      },
    ],
    error: null,
  };
};

export const getCubeReadMeStrFromDatasetId = async datasetId => {
  const { data: dataset, error } = await dataBackend.getDatasetById(datasetId);
  if (error || !dataset?.name) {
    return { data: "" };
  }

  const readMeStr = `# Cube for ${dataset?.name}
    
### Dataset
columns:<br/>${dataset?.config?.featureTypeDescriptors
    .map(col => `&nbsp;&nbsp;&nbsp;&nbsp;${col.key} <b>${col?.type?.typeName}</b>`)
    .join("<br />")}

### Model
 -
### Training
 -
### Evaluation
 -`;
  return { data: readMeStr };
};

export const createCubeAndPipelineConfigForDataset = async (datasetId, title) => {
  const { data: readMe } = await getCubeReadMeStrFromDatasetId(datasetId);
  const { data: cube, error } = await createCube({ name: `Cube for ${title}`, readMe });
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  const pipelineConfg = {
    datasetId: datasetId,
    cubeId: cube.id,
    status: "DRAFT",
    modelConfig: {},
  };
  const { data: pipelineConfig, error: pipelineErr } = await postPipelineConfig(pipelineConfg);
  if (pipelineErr) {
    return { data: null, error: getHandlingFromError(pipelineErr) };
  }

  return { data: { cube, pipelineConfig }, error: null };
};

export const postPaymentSession = async ({ amount, successUrl, cancelUrl }) => {
  const { data, error } = await backend.postPaymentSession({
    amount,
    successUrl,
    cancelUrl,
  });
  return { data, error: getHandlingFromError(error) };
};

export const getLoggedInUserActiveAccountSubscription = async () => {
  const { data, error } = await backend.getActiveAccountSubscriptionOfLoggedInUser();
  if (error && error.message !== "No active account subscription") {
    return { data: null, error: getHandlingFromError(error) };
  }
  return { data, error: null };
};

const baseUrl = `${window.location.protocol}//${window.location.host}`;

export const updateSubscriptionPlan = async (subscriptionId, newPlan) => {
  const { data: updatedActiveSub, error: subscriptonPatchErr } = await backend.patchAccountSubscription(
    subscriptionId,
    {
      plan: newPlan,
    }
  );
  return { data: updatedActiveSub, error: getHandlingFromError(subscriptonPatchErr) };
};

export const getPaymentUrlForPlan = async plan => {
  await cancelCurrentPlanOfLoggedInUser();
  const { data: newSubscription, error: subscriptonCreateErr } = await backend.postAccountSubscription({ plan });
  if (subscriptonCreateErr) {
    return { data: null, error: getHandlingFromError(subscriptonCreateErr) };
  }
  const userName = getLoggedInUserName();
  const { data, error } = await backend.postPaymentSession({
    subscriptionId: newSubscription.id,
    successUrl: `${baseUrl}/${userName}/account`,
    cancelUrl: `${baseUrl}/${userName}/account`,
  });
  return { data, error: getHandlingFromError(error) };
};

export const cancelCurrentPlanOfLoggedInUser = async () => {
  const { data: activeSubscription, error } = await backend.getActiveAccountSubscriptionOfLoggedInUser();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }
  if (!activeSubscription) {
    return { data: null, error: { message: "No active subscription to cancel" } };
  }

  const { error: subscriptonPatchErr } = await backend.patchAccountSubscription(activeSubscription.id, {
    status: "CANCELLED",
  });
  if (subscriptonPatchErr) {
    return { data: null, error: getHandlingFromError(subscriptonPatchErr) };
  }
  return { data: null, error: null };
};

export const getModelCompatibleWarmStarts = async modelId => {
  const { data, error } = await backend.getEnrichedTrainingJob({ modelId: modelId, status: "DONE" });
  return { data: data, error: getHandlingFromError(error) };
};

export const getManagementConfigOfLoggedInUser = async () => {
  const { data, error } = await backend.getManagementConfigOfLoggedInUser();
  return { data, error: getHandlingFromError(error) };
};

export const putManagementConfigOfLoggedInUser = async newConfig => {
  const { data, error } = await backend.putManagementConfigOfLoggedInUser(newConfig);
  return { data, error: getHandlingFromError(error) };
};

export const getManagementConfigOfCube = async cubeId => {
  const { data, error } = await backend.getManagementConfigOfCube(cubeId);
  return { data, error: getHandlingFromError(error) };
};

export const putManagementConfigOfCube = async (cubeId, newConfig) => {
  const { data, error } = await backend.putManagementConfigOfCube(cubeId, newConfig);
  return { data, error: getHandlingFromError(error) };
};

export const postApiConfig = async newConfig => {
  const { data, error } = await backend.postApiConfig(newConfig);
  return { data, error: getHandlingFromError(error) };
};

export const patchApiConfig = async (id, fieldsToUpdate) => {
  const { data, error } = await backend.patchApiConfig(id, fieldsToUpdate);
  return { data, error: getHandlingFromError(error) };
};

export const deleteApiConfig = async id => {
  const { data, error } = await backend.deleteApiConfig(id);
  return { data, error: getHandlingFromError(error) };
};

export const postSolution = async solution => {
  const { data, error } = await backend.postDeploymentBasedSolution(solution);
  return { data, error };
};

export const patchSolution = async (id, fieldsToUpdate) => {
  const { data, error } = await backend.patchSolution(id, fieldsToUpdate);
  return { data, error };
};

export const createSolutionWithApiConfig = async (apiConfig, deploymentClusterId) => {
  const { data: newSolution, error: solCreateErr } = await postSolution({ deploymentClusterId, apiConfig });
  if (solCreateErr) {
    return { data: null, error: getHandlingFromError(solCreateErr) };
  }

  const { data: updatedApiConfig, error: updateConfigErr } = await patchApiConfig({ solutionId: newSolution.id });
  if (updateConfigErr) {
    return { data: null, error: getHandlingFromError(updateConfigErr) };
  }

  return { data: updatedApiConfig, error: null };
};

export const removeApiConfigFromSolution = async apiConfig => {};

export const getSolutions = async () => {
  const { data, error } = await backend.getSolutions();
  return { data, error };
};

export const getSolution = async solutionId => {
  const { data, error } = await backend.getSolution(solutionId);
  return { data, error };
};

export const getSolutionAndFullApiConfigs = async solutionId => {
  const { data: solution, error: solutionFetchErr } = await backend.getSolution(solutionId);
  if (solutionFetchErr) {
    return { data: null, error: getHandlingFromError(solutionFetchErr) };
  }

  const apiConfigResults = await Promise.all(solution.apiConfigIds.map(id => backend.getApiConfig(id)));
  const apiConfigFetchErr = apiConfigResults.find(result => result.error);
  if (apiConfigFetchErr) {
    return { data: null, error: getHandlingFromError(apiConfigFetchErr) };
  }

  const apiConfigs = apiConfigResults.map(result => result.data);
  return { data: { solution, fullApiConfigs: apiConfigs }, error: null };
};

export const getSolutionMetrics = async (solutionId, queryParams) => {
  const { data, error } = await backend.getSolutionMetrics(solutionId, queryParams);
  return { data, error };
};

export const getApiConfigMetrics = async (apiConfigId, queryParams) => {
  const { data, error } = await backend.getApiConfigMetrics(apiConfigId, queryParams);
  return { data, error };
};

export const getSurveys = async () => {
  const { data, error } = await backend.getSurveys();
  return { data, error };
};

export const getSurvey = async surveyId => {
  const { data, error } = await backend.getSurvey(surveyId);
  return { data, error };
};

export const postSurvey = async survey => {
  const { data, error } = await backend.postSurvey(survey);
  return { data, error };
};

export const patchSurvey = async (surveyId, fieldsToUpdate) => {
  const { data, error } = await backend.patchSurvey(surveyId, fieldsToUpdate);
  return { data, error };
};

export const postSurveyResponse = async (surveyId, responseData) => {
  const { data, error } = await backend.postSurveyResponse({ surveyId, data: responseData });
  return { data, error };
};

export const getSurveyResponses = async surveyId => {
  const { data, error } = await backend.getSurveyResponses({ surveyId });
  return { data, error };
};

export const triggerDownloadOfSurveyResponseData = async surveyId => {
  const res = await apiGetRawResponse(`/project-service/api/v1/surveys/${surveyId}/response-csv-data`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "survey-responses.csv";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const getOcrAnnotationTasks = async () => {
  const { data: ocrTasks, error: ocrTasksError } = await backend.getOcrAnnotationTasks();
  if (ocrTasksError) {
    return { data: [], error: ocrTasksError };
  }

  const { data: ocrPatchedTasks } = await backend.getOcrAnnotationPatchedTasks();
  const formatedTasks = ocrTasks.map(task => {
    return {
      id: task,
      name: task,
      isPatched: ocrPatchedTasks?.includes(task),
    };
  });

  return { data: formatedTasks, error: null };
};

export const getDataForOcrAnnotationTask = async ocrAnnotationTaskId => {
  const { data: ocrDataString, error: ocrError } = await backend.getDataForOcrAnnotationTask(ocrAnnotationTaskId);
  const ocrData = parseJson(ocrDataString)?.data;
  if (!ocrData) {
    return { data: [], error: { message: "Ocr data string could not be parsed" } };
  }

  const { data: patchedLabelsDataString, error: patchedLabelsError } =
    await backend.getPatchedLabelsForOcrAnnotationTask(ocrAnnotationTaskId);
  if (patchedLabelsError || patchedLabelsDataString === "") {
    return { data: ocrData, error: ocrError };
  }
  const patchedLabelsData = JSON.parse(patchedLabelsDataString);

  patchedLabelsData.imageLabelList.forEach((labelList, i) => {
    const imageId = patchedLabelsData.imageIdList[i];
    ocrData[imageId].label = labelList[0];
  });
  return { data: ocrData, error: null };
};

export const patchDataForOcrAnnotationTask = async (ocrAnnotationTaskId, ocrData) => {
  const dataToPatch = {
    id: ocrAnnotationTaskId,
    imageIdList: Array.from(Array(ocrData.length).keys()),
    imageLabelList: ocrData.map(instance => [instance.label]),
  };

  const { data, error } = await backend.patchDataForOcrAnnotationTask(ocrAnnotationTaskId, dataToPatch);
  return { data, error };
};

export const getImageAnnotationTasks = async () => {
  const { data, error } = await backend.getImageAnnotationTasks();
  return { data, error };
};

export const getImageAnnotationTasksWithDefault = async () => {
  const { data: tasks, error } = await backend.getImageAnnotationTasks();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  if (!tasks?.find(task => task.id?.includes("default"))) {
    const { data: defaultTask } = await createDefaultImageAnnotationTask();
    return { data: [defaultTask, ...tasks], error: null };
  }

  return { data: tasks?.sort(task => (task?.id?.includes("default") ? -1 : 1)), error: null };
};

export const postImageAnnotationTask = async (name, imagesArchiveFile) => {
  const { data, error } = await backend.postImageAnnotationTaskImagesFile(name, imagesArchiveFile);
  return { data, error: getHandlingFromError(error) };
};

export const getImagesForAnnotationTask = async annotationTaskId => {
  const { data, error } = await backend.getImagesForAnnotationTask(annotationTaskId);
  return { data, error: getHandlingFromError(error) };
};

export const patchImageOfAnnotationTask = async (taskId, imageId, fieldsToUpdate) => {
  const { data, error } = await backend.patchImageOfAnnotationTask(taskId, imageId, fieldsToUpdate);
  return { data, error: getHandlingFromError(error) };
};

export const createDefaultImageAnnotationTask = async () => {
  const { data, error } = await backend.createDefaultImageAnnotationTask();
  return { data, error };
};

export const getSuggestedAnnotations = async base64Data => {
  await sleep(500);
  return {
    data: [
      {
        x: 10 + Math.floor(Math.random() * 100),
        y: 10 + Math.floor(Math.random() * 100),
        w: 10 + Math.floor(Math.random() * 100),
        h: 10 + Math.floor(Math.random() * 100),
        labels: ["apple", "banana", "orange"],
      },
    ],
    error: null,
  };
};

export const triggerDownloadOfImageAnnotationTaskData = async taskId => {
  const res = await apiGetRawResponse(`/project-service/api/v1/image-annotation-tasks/${taskId}/json-data`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "annotated-images.json";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const getTextAnnotationTasks = async () => {
  const { data, error } = await backend.getTextAnnotationTasks();
  return { data, error };
};

export const getTextAnnotationTasksWithDefault = async () => {
  const { data: tasks, error } = await backend.getTextAnnotationTasks();
  if (error) {
    return { data: null, error: getHandlingFromError(error) };
  }

  if (!tasks?.find(task => task.id?.includes("default"))) {
    const { data: defaultTask } = await createDefaultTextAnnotationTask();
    return { data: [defaultTask, ...tasks], error: null };
  }

  return { data: tasks?.sort(task => (task?.id?.includes("default") ? -1 : 1)), error: null };
};

export const postTextAnnotationTask = async (name, textsJsonFile) => {
  const { data, error } = await backend.postTextAnnotationTaskTextsFile(name, textsJsonFile);
  return { data, error: getHandlingFromError(error) };
};

export const getTextsForTextAnnotationTask = async taskId => {
  const { data, error } = await backend.getTextsForTextAnnotationTask(taskId);
  return { data, error };
};

export const patchTextOfTextAnnotationTask = async (taskId, textId, fieldsToUpdate) => {
  const { data, error } = await backend.patchTextOfTextAnnotationTask(taskId, textId, fieldsToUpdate);
  return { data, error };
};

export const triggerDownloadOfTextAnnotationTaskData = async taskId => {
  const res = await apiGetRawResponse(`/project-service/api/v1/text-annotation-tasks/${taskId}/json-data`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = "annotated-texts.json";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const createDefaultTextAnnotationTask = async () => {
  const { data, error } = await backend.createDefaultTextAnnotationTask();
  return { data, error };
};

export const getSuggestedAnnotationsForText = async text => {
  await sleep(200);
  const suggestedAnnotations = range(0, 2 + Math.floor(Math.random() * 5)).map(() => ({
    pos: Math.floor(Math.random() * text.length),
    label: ["country", "person", "location"][Math.floor(Math.random() * 3)],
    span: 4 + Math.floor(Math.random() * 10),
  }));
  return {
    data: suggestedAnnotations,
    error: null,
  };
};

export const postSolutionSession = async solutionId => {
  const { data, error } = await backend.postSolutionSession({ solutionId });
  return { data, error: getHandlingFromError(error) };
};

export const postSolutionQuery = async (sessionId, apiConfigId, input) => {
  const { data, error } = await backend.postSolutionQuery({ sessionId, apiConfigId, input });
  return { data, error: getHandlingFromError(error) };
};

export const getTrainingExamples = async datasetId => {
  const { data, error } = await dataService.getDatasetPreviewByDatasetId(datasetId);
  return { data: data?.slice(0, 5), error };
};

export const postSentenceLabelingTasks = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/sentence-labeling-tasks", {}, body);
  return { data, error };
};

export const createSentenceLabelingTask = async (name, labels) => {
  const { data, error } = await backend.postSentenceLabelingTasks(name, labels);
  return { data, error: getHandlingFromError(error) };
};

export const getSentenceLabelingTasks = async () => {
  const { data, error } = await backend.getSentenceLabelingTasks();
  return { data, error: getHandlingFromError(error) };
};

export const getSentenceLabelingTaskById = async taskId => {
  const { data, error } = await backend.getSentenceLabelingTaskById(taskId);
  return { data, error: getHandlingFromError(error) };
};

export const getTaskSentenceLabelPairs = async taskId => {
  const { data, error } = await backend.getTaskSentenceLabelPairs(taskId);
  return { data, error: getHandlingFromError(error) };
};

export const saveSentenceLabelPairs = async pairs => {
  const { data, error } = await backend.postTaskSentenceLabelPairs(pairs);
  return { data, error: getHandlingFromError(error) };
};

export const triggerDownloadOfSentenceLabelPairs = async taskId => {
  const res = await apiGetRawResponse(`/project-service/api/v1/sentence-labeling-tasks/${taskId}/json-data`);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = `${taskId}.json`;
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

export const getRegisteredInterestUserById = async uid => {
  await sleep(200);
  if (uid === "0" || uid.includes("bzv")) {
    return { data: {}, error: null };
  }
  return { data: null, error: null };
};

export const createCubeWithFinetuningPipeline = async (datasetId, modelId) => {
  const { data: cube, error } = await createCube({ name: `Finetuning cube for ${datasetId}` });
  if (error) {
    return { data: null, error };
  }

  const { data: customCode } = await getCustomCodeTemplate();

  const pipelineConfig = {
    cubeId: cube.id,
    modelId,
    datasetId,
    status: "SUBMITTED",
    customCode,
  };
  const { error: pipelineErr } = await backend.postPipelineConfig(pipelineConfig);
  if (pipelineErr) {
    return { data: null, error: getHandlingFromError(pipelineErr) };
  }

  return { data: cube, error: null };
};

export const doAnyPipelinesHaveGptTypeModels = async pipelineIds => {
  if (!pipelineIds) {
    return { data: false, error: null };
  }

  const pipelineOutputsResults = await Promise.all(
    pipelineIds.map(pipelineId => getPipelineConfigOuputById(pipelineId))
  );
  const modelTypes = pipelineOutputsResults.map(result => result.data?.model?.type);

  return { data: modelTypes.includes("GPT"), error: null };
};

export const saveUserPreferences = async (user, preferences) => {
  return backend.saveUserPreferences(user, preferences);
};

export const getUserPreferences = async user => {
  return backend.getUserPreferences(user);
};

export const cloneCube = async (cubeId, name) => {
  const { data, error } = await backend.cloneCube(cubeId, name);
  return { data, error: getHandlingFromError(error) };
};

export const putLike = async likeFields => {
  const { data, error } = await backend.putLike(likeFields);
  return { data, error: getHandlingFromError(error) };
};

export const deleteLike = async params => {
  const { data, error } = await backend.deleteLike(params);
  return { data, error: getHandlingFromError(error) };
};

export const getActivitiesClones = async params => {
  const { data, error } = await backend.getActivitiesClones(params);
  return { data, error: getHandlingFromError(error) };
};

export const getLikesActivties = async params => {
  const { data, error } = await backend.getLikesActivties(params);
  return { data, error: getHandlingFromError(error) };
};

export const postContinuePipelineTraining = async pipelineId => {
  const { data, error } = await backend.postContinuePipelineTraining(pipelineId);
  return { data, error: getHandlingFromError(error) };
};

export const getCubesFindByKey = async params => {
  const { data, error } = await backend.getCubesFindByKey(params);
  return { data, error: getHandlingFromError(error) };
};
