import styled from "styled-components";
import { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";

import { getPipelineIdToCommitShaMap } from "api/services/githubService";
import { getPipelineConfigsByIds } from "api/services/projectService";
import ItemListPopover from "components/ui/ItemListPopover";
import Button from "components/ui/Button";

const Container = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 20px;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.closer0_5};
  justify-content: space-between;
  align-items: center;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
`;

const LatestPipeline = styled.div`
  display: flex;
  gap: 10px;
  justify-self: start;
`;

const PipelineInfoAndHistory = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
`;

const OptionContainer = styled.div`
  cursor: pointer;
  display: flex;
  padding: 10px;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const OptionLabel = styled.label`
  color: ${props => props.theme.color.closest};
`;

const TickBox = styled.input`
  margin: 0;
  display: block;
  width: unset;
  margin-right: 5px;
`;

const CompareButton = styled(Button)`
  min-width: 100px;
  padding: 5px;
`;

const CompareButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const Msg = styled.div`
  text-align: center;
  display: flex;
  color: ${props => props.theme.color.in_progress};
  padding-bottom: 20px;
`;

const SelectedVersion = styled.div`
  margin-left: auto;
`;

const CreatedAtLabel = styled.div`
  font-size: 12px;
  padding-left: ${props => !props.noPadding && "10px"};
  white-space: nowrap;
  color: ${props => props.theme.color.closer2};
`;

const AutoTuneText = styled.span`
  padding-left: 5px;
  color: ${props => props.theme.color.primary};
`;

const DraftText = styled.span`
  padding-left: 5px;
  color: ${props => props.theme.color.in_progress};
`;

const PipelineIdsContainer = styled.div`
  height: 200px;
  overflow-y: scroll;
`;

const LoadingState = () => <Container>Loading...</Container>;

const ErrorState = ({ error }) => <Container>{JSON.stringify(error)}</Container>;

const NoPipelinesState = () => (
  <Container>
    <PipelineInfoAndHistory>
      <LatestPipeline>No pipeline version found in this Cube yet.</LatestPipeline>
    </PipelineInfoAndHistory>
  </Container>
);

const formatDate = dateString => {
  try {
    const createdAtDate = new Date(dateString);
    return formatDistanceToNow(createdAtDate, {
      addSuffix: true,
      includeSeconds: true,
    });
  } catch {
    return "";
  }
};

const BranchName = styled.div`
  font-weight: bold;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: ${props => props.theme.color.closest};
  background-color: ${props => props.theme.color.closer1};
`;

const isAutotuningPipelineSelected = (tickedPipelineIds, pipelineConfigs) => {
  const tickedConfigs = pipelineConfigs.filter(config => tickedPipelineIds.includes(config.id));
  return tickedConfigs.some(config => config.trainingSearchValues);
};

const isDraftPipelineSelected = (tickedPipelineIds, pipelineConfigs) => {
  const tickedConfigs = pipelineConfigs.filter(config => tickedPipelineIds.includes(config.id));
  return tickedConfigs.some(config => config?.status === "DRAFT");
};

const isCustomPipelineSelected = (tickedPipelineIds, pipelineConfigs) => {
  const tickedConfigs = pipelineConfigs.filter(config => tickedPipelineIds.includes(config.id));
  return tickedConfigs.some(config => config?.customCode);
};

const isAutotuningPipeline = (pipelineId, pipelineConfigs) =>
  pipelineConfigs.find(config => config.id === pipelineId && config.trainingSearchValues);

const isPipelineDraft = (pipelineId, pipelineConfigs) =>
  pipelineConfigs.find(config => config.id === pipelineId && config?.status === "DRAFT");

const isPipelineCustom = (pipelineId, pipelineConfigs) =>
  pipelineConfigs.find(config => config.id === pipelineId && config?.customCode);

const getVersionLabel = pipelineId => {
  return `${pipelineId.slice(0, 3)}...${pipelineId.slice(-3)}`;
};

const CubeVersionInfo = ({ cube, onPipelineIdsSelect, selectedPipelineIds }) => {
  const [pipelineConfigs, setPipelineConfigs] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tickedPipelineIds, setTickedPipelineIds] = useState(selectedPipelineIds);
  const [pipelineIdToCommitSha, setPipelineIdToCommitSha] = useState(null);

  const isGithubCube = !!pipelineIdToCommitSha;
  const pipelineIds = cube?.pipelineIds;

  useEffect(() => {
    const firstPipelineId = cube?.pipelineIds?.[cube?.pipelineIds?.length - 1];
    onPipelineIdsSelect(firstPipelineId ? [firstPipelineId] : []);
    doPopulatePipelineIdToCommitSha();
    doFetchPipelineConfigs();
  }, [cube?.pipelineIds]);

  useEffect(() => {
    setTickedPipelineIds(selectedPipelineIds);
  }, [selectedPipelineIds]);

  const doPopulatePipelineIdToCommitSha = async () => {
    const { data: pipelineIdToCommitShaMap } = await getPipelineIdToCommitShaMap(cube.id);
    setPipelineIdToCommitSha(pipelineIdToCommitShaMap);
  };

  const doFetchPipelineConfigs = async () => {
    setIsLoading(true);
    const { data, error } = await getPipelineConfigsByIds(pipelineIds);

    setError(error);
    setPipelineConfigs(data);
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState error={error} />;
  }

  if (pipelineIds?.length === 0) {
    return <NoPipelinesState />;
  }

  return (
    <Container>
      <PipelineInfoAndHistory>
        {isGithubCube && <BranchName>main</BranchName>}
        {selectedPipelineIds?.length === 1 && (
          <>
            <SelectedVersion>
              {isGithubCube ? pipelineIdToCommitSha[selectedPipelineIds[0]] : selectedPipelineIds[0]}
            </SelectedVersion>
            <CreatedAtLabel noPadding>
              {formatDate(pipelineConfigs.find(({ id }) => selectedPipelineIds[0] === id)?.createdAt)}
            </CreatedAtLabel>
          </>
        )}
        <ItemListPopover title={isGithubCube ? "Commit history" : "versions"}>
          <PipelineIdsContainer>
            {pipelineIds?.map(pipelineId => (
              <OptionContainer key={pipelineId}>
                <TickBox
                  id={pipelineId}
                  type="checkbox"
                  checked={tickedPipelineIds.includes(pipelineId)}
                  onChange={e => {
                    const newIds = e.target.checked
                      ? [...tickedPipelineIds, pipelineId]
                      : tickedPipelineIds.filter(id => id !== pipelineId);
                    setTickedPipelineIds(newIds);
                  }}
                />
                <OptionLabel htmlFor={pipelineId}>{getVersionLabel(pipelineId)}</OptionLabel>
                <CreatedAtLabel>
                  {formatDate(pipelineConfigs.find(({ id }) => pipelineId === id)?.createdAt)}
                </CreatedAtLabel>
                {isAutotuningPipeline(pipelineId, pipelineConfigs) && <AutoTuneText>auto</AutoTuneText>}
                {isPipelineDraft(pipelineId, pipelineConfigs) && <DraftText>draft</DraftText>}
                {isPipelineCustom(pipelineId, pipelineConfigs) && <DraftText>custom</DraftText>}
              </OptionContainer>
            ))}
          </PipelineIdsContainer>
          <CompareButtonContainer>
            <CompareButton
              onClick={() => onPipelineIdsSelect(tickedPipelineIds)}
              value={tickedPipelineIds?.length > 1 ? "Compare" : "Select"}
              isDisabled={
                tickedPipelineIds?.length > 1 &&
                (isAutotuningPipelineSelected(tickedPipelineIds, pipelineConfigs) ||
                  isDraftPipelineSelected(tickedPipelineIds, pipelineConfigs) ||
                  isCustomPipelineSelected(tickedPipelineIds, pipelineConfigs))
              }
            />
          </CompareButtonContainer>
          {tickedPipelineIds?.length > 1 && isAutotuningPipelineSelected(tickedPipelineIds, pipelineConfigs) && (
            <Msg>Can't compare autotuning pipelines</Msg>
          )}
          {tickedPipelineIds?.length > 1 && isDraftPipelineSelected(tickedPipelineIds, pipelineConfigs) && (
            <Msg>Can't compare draft pipelines</Msg>
          )}
          {tickedPipelineIds?.length > 1 && isCustomPipelineSelected(tickedPipelineIds, pipelineConfigs) && (
            <Msg>Can't compare customized training pipelines</Msg>
          )}
        </ItemListPopover>
      </PipelineInfoAndHistory>
    </Container>
  );
};

export default CubeVersionInfo;
