import { useState, useEffect } from "react";
import styled from "styled-components";
import TextInput from "components/ui/TextInput";
import { searchWithKeywords } from "api/services/packageService";
import { CenteredWithTopNavLayoutInternal } from "components/PackageLayout";
import FilterTag from "components/ui/FilterTag";
import { isEmpty, isEqual, uniq } from "lodash";
import TablePreviewWithPlots from "components/widgets/TablePreviewWithPlots";
import PlotForTables from "components/widgets/PlotForTables";
import { Gap } from "components/Layout";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import { getLoggedInUserName } from "api/services/authenticationService";
import { Link } from "react-router-dom";

const getPlotData = (selectedCol, tables, isXAxisRows) => {
  if (!selectedCol || tables?.length === 0) {
    return [];
  }

  const tableNames = tables.map(table => `${table?.packageName}-${table?.name}`);
  const rowIdColName = tables?.[0]?.columns?.[0];

  if (!isXAxisRows) {
    const plotData = tables.map(table => {
      const point = {};
      point["xLabel"] = `${table?.packageName} ${table?.name}`;
      table?.rows.forEach(row => {
        const rawCellVal = row?.[selectedCol]?.replace(/[^0-9.-]+/g, "");
        const cellVal = parseFloat(rawCellVal);
        point[row[rowIdColName]] = cellVal;
      });

      return point;
    });

    return plotData;
  }

  const xAxisTicks = tables?.[0]?.rows.map(row => row[rowIdColName]);

  const plotData = xAxisTicks.map(xAxisTick => {
    const point = {};
    point["xLabel"] = xAxisTick;
    tableNames.forEach(tableName => {
      const table = tables.find(table => `${table?.packageName}-${table?.name}` === tableName);
      const row = table?.rows?.find(row => row[rowIdColName] === xAxisTick);
      const rawCellVal = row?.[selectedCol]?.replace(/[^0-9.-]+/g, "");
      const cellVal = parseFloat(rawCellVal);

      point[tableName] = cellVal;
    });

    return point;
  });

  return plotData;
};

const getComparableTableIds = (tableId, tables) => {
  let compatibleTableIds = [];

  const hoveredTable = tables?.find(table => table?.id === tableId);
  const hoveredColNames = Object.keys(hoveredTable?.rows[0]);
  const hoveredRowNames = hoveredTable?.rows.map(row => row?.["1"]);

  tables?.forEach(table => {
    const tableColNames = Object.keys(table?.rows[0]);
    const tableRowNames = table?.rows.map(row => row?.["1"]);
    if (isEqual(tableColNames, hoveredColNames) && isEqual(hoveredRowNames, tableRowNames)) {
      compatibleTableIds.push(table?.id);
    }
  });

  return compatibleTableIds;
};

const FilterTagsContainer = styled.div`
  display: flex;
  gap: 5px;
  padding-bottom: 20px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  padding-bottom: 30px;
`;

const TablesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  overflow-x: scroll;
`;

const ResultsAndPlot = styled.div`
  display: grid;
  grid-template-columns: 600px 400px;
`;

const PackageTableSearchPage = () => {
  const [query, setQuery] = useState("bed");
  const [results, setResults] = useState(null);
  const [tagsActiveMap, setTagsActiveMap] = useState({});
  const [packages, setPackages] = useState([]);
  const [tables, setTables] = useState([]);

  const [highlightedCol, setHighlightedCol] = useState(null);
  const [selectedCol, setSelectedCol] = useState(null);

  const [highlightedTableIds, setHighlightedTableIds] = useState([]);
  const [selectedTableIds, setSelectedTableIds] = useState([]);

  const [isXAxisRows, setIsXAxisRows] = useState(true);

  const userName = getLoggedInUserName();

  useEffect(() => {
    if (query === "") {
      setTables([]);
      setPackages([]);
      setTagsActiveMap({});
      setSelectedCol(null);
    } else {
      doSearchWithKeywords();
    }
  }, [query]);

  useEffect(() => {
    if (!isEmpty(results)) {
      const filtered = results.filter(e => tagsActiveMap[e.packageName]);
      setTables(filtered);
    } else {
      setTables(results);
    }
  }, [tagsActiveMap]);

  const doSearchWithKeywords = async () => {
    const { data, error } = await searchWithKeywords(query);
    if (error) {
      return;
    }
    setResults(data);
    setPackages([...new Set(data?.map(e => e.packageName))]);
    const tupleArray = data?.map(e => {
      return [e.packageName, true];
    });
    setTagsActiveMap(Object.fromEntries(tupleArray));
  };

  const plotData = getPlotData(
    selectedCol,
    tables?.filter(table => selectedTableIds?.includes(table?.id)),
    isXAxisRows
  );

  if (!userName) {
    return (
      <div>
        Please log in first to see the content. <Link to={"/login"}>Click here to login.</Link>
      </div>
    );
  }

  return (
    <CenteredWithTopNavLayoutInternal>
      <SearchInputContainer>
        <TextInput placeholder="all" title="Search" value={query} onNewInput={newQuery => setQuery(newQuery)} />
      </SearchInputContainer>
      <FilterTagsContainer>
        {packages.map(p => (
          <FilterTag
            key={p}
            isActive={tagsActiveMap[p]}
            onToggle={() => {
              const tagsActiveMapCP = { ...tagsActiveMap, [p]: !tagsActiveMap[p] };
              setTagsActiveMap(tagsActiveMapCP);
            }}
          >
            {p}
          </FilterTag>
        ))}
      </FilterTagsContainer>
      <Gap />
      <ResultsAndPlot>
        <TablesContainer>
          {tables?.map(table => (
            <TablePreviewWithPlots
              onMouseOver={() => {
                const tablesIdToHighlight = getComparableTableIds(table?.id, tables);
                setHighlightedTableIds(tablesIdToHighlight);
              }}
              onMouseOut={() => setHighlightedTableIds([])}
              isTableHighlighted={highlightedTableIds?.includes(table?.id)}
              isTableSelected={selectedTableIds?.includes(table?.id)}
              packageName={table.packageName}
              tableName={table.name}
              columnNames={uniq(table?.columns)}
              key={table?.id}
              tableRows={table.rows}
              highlightedCol={highlightedCol}
              onHighlightCol={newCol => setHighlightedCol(newCol)}
              selectedCol={selectedCol}
              onSelectCol={newCol => {
                setSelectedTableIds(highlightedTableIds);
                setSelectedCol(newCol);
              }}
            />
          ))}
        </TablesContainer>
        {selectedCol && (
          <div>
            <PlotForTables data={plotData} selectedCol={selectedCol} />
            <TwoNameSwitch
              leftName="Rows"
              rightName="Tables"
              isOnLeft={isXAxisRows}
              onSwitch={() => setIsXAxisRows(!isXAxisRows)}
            />
          </div>
        )}
      </ResultsAndPlot>
    </CenteredWithTopNavLayoutInternal>
  );
};

export default PackageTableSearchPage;
