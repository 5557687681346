import styled from "styled-components";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core";

import { CubeIcon } from "components/ui/Icons";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";
import { createCubeAndPipelineConfigForDataset } from "api/services/projectService";
import Button from "components/ui/Button";

const BottomButtons = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.8;"}
  display: flex;
  gap: 5px;
  align-items: center;
`;

const PlusContainer = styled.div`
  position: absolute;
  right: -8px;
  bottom: -2px;
  transform: scale(0.4);
  background-color: ${props => props.theme.color.closer0};
  border-radius: 50%;
`;

const HelpText = styled.div`
  transition: opacity 0.2s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
`;

const AddCubeIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const AddCubeIcon = () => (
  <AddCubeIconContainer>
    <PlusContainer>
      <Add />
    </PlusContainer>
    <CubeIcon height="14px" isHighlighted />
  </AddCubeIconContainer>
);

const AddCubeButton = styled(ButtonCreateNewAsset)`
  width: 24px;
  height: 24px;
  border: 1px solid ${props => props.theme.color.primary};

  ${props => props.isHighlighted && `animation: pulseborder 0.5s infinite alternate;`}
  @keyframes pulseborder {
    0% {
      border: 1px solid ${props => props.theme.color.primary};
    }
    100% {
      border: 1px solid ${props => props.theme.color.feature};
    }
  }

  :hover {
    ${PlusContainer} {
      background-color: ${props => props.theme.color.primary};
    }
  }
`;

const CreateCubeButtonStandard = ({ datasetId, datasetTitle, className, isHelpTextDisabled }) => {
  const [isCreatingCube, setIsCreatingCube] = useState(false);
  const navigate = useNavigate();

  if (!datasetId) {
    return null;
  }

  if (isCreatingCube) {
    return (
      <Button
        isDisabled
        icon={<CubeIcon height="22px" />}
        className={className}
        onClick={async () => {
          setIsCreatingCube(true);
          const { data, error } = await createCubeAndPipelineConfigForDataset(datasetId, datasetTitle);
          setIsCreatingCube(false);
          if (error) {
            alert(JSON.stringify(error));
            return;
          }
          navigate(`/cube/${data?.cube?.id}`);
        }}
        value="Create cube"
        variant="highlighted"
      />
    );
  }

  return (
    <Button
      icon={<CubeIcon height="22px" />}
      className={className}
      onClick={async () => {
        setIsCreatingCube(true);
        const { data, error } = await createCubeAndPipelineConfigForDataset(datasetId, datasetTitle);
        setIsCreatingCube(false);
        if (error) {
          alert(JSON.stringify(error));
          return;
        }
        navigate(`/cube/${data?.cube?.id}`);
      }}
      value="Create cube"
      variant="highlighted"
    />
  );
};

export default CreateCubeButtonStandard;
