import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import styled from "styled-components";
import { round } from "lodash";

import { lightTheme, darkTheme } from "App";

const Container = styled.div`
  width: 100%;
  height: 100%;
  .recharts-cartesian-grid {
    line {
      stroke: ${props => props.theme.color.closer1};
    }
  }
  .recharts-cartesian-axis-tick {
    text {
      fill: ${props => props.theme.color.closest};
    }
  }
`;

const TrainingJobLineChartMultiLine = ({ data, lineIds, highlightedJobId, lineIdColorMap }) => {
  const isDarkTheme = localStorage.getItem("theme") === "dark";
  const textColor = isDarkTheme ? darkTheme.color.closest : lightTheme.color.closest;
  const greyedOutColor = isDarkTheme ? darkTheme.color.closer2 : lightTheme.color.closer2;

  return (
    <Container>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="1000 100" />
          <Legend verticalAlign="top" />
          <XAxis
            dataKey="iter"
            label={{
              stroke: textColor,
              value: "Training iterations",
              position: "bottomCenter",
              dy: 25,
            }}
          />
          <YAxis
            label={{
              stroke: textColor,
              value: "Loss",
              position: "center",
              angle: -90,
              dx: -20,
            }}
          />
          <Tooltip formatter={value => round(value, 3)} />
          {lineIds.map(lineId => (
            <>
              <Line
                key={`${lineId}-infer-loss`}
                type="monotone"
                dataKey={`infer_loss_${lineId}`}
                name="Feature loss"
                opacity={lineId === highlightedJobId ? 1 : 0.2}
                stroke={lineId === highlightedJobId ? lineIdColorMap[`infer_loss_${lineId}`] : greyedOutColor}
                activeDot={{ r: 8 }}
                strokeWidth={1}
                dot={false}
              />
              <Line
                key={`${lineId}-train-loss`}
                type="monotone"
                dataKey={`train_loss_${lineId}`}
                name="Data loss"
                opacity={lineId === highlightedJobId ? 1 : 0.2}
                stroke={lineId === highlightedJobId ? lineIdColorMap[`train_loss_${lineId}`] : greyedOutColor}
                strokeWidth={1}
                activeDot={{ r: 8 }}
                dot={false}
              />
            </>
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default TrainingJobLineChartMultiLine;
