import Modal from "components/ui/Modal";
import styled from "styled-components";
import EvaluationReport from "./EvaluationReport";
const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  display: flex;
  width: 700px;
  height: 400px;
`;


const EvalJobInspectionModal = ({ evalJob, isOpen, onClose }) => {
  return (
    <Modal title="Inspect evaluation" open={isOpen} handleClose={onClose}>
      <ModalContent>
        <EvaluationReport evaluationJobs={[evalJob]}></EvaluationReport>
      </ModalContent>
    </Modal>
  );
};

export default EvalJobInspectionModal;