import styled from "styled-components";
import { useState, useEffect } from "react";

import { SmallTitle } from "components/ui/Text";
import { getFileListByRepoSlugAndBranchName } from "api/services/githubService";

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const File = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const FileListTitle = styled(SmallTitle)`
  cursor: pointer;
`;

const FileListContainer = styled.div`
  height: ${props => (props.isExpanded ? `${props.maxHeight}px` : 0)};
  transition: height 0.2s;
  overflow: hidden;
`;

const FileSize = styled.div`
  color: ${props => props.theme.color.closer2};
`;

const getFileSizeString = numBytes => {
  if (numBytes > 1000) {
    return `${Math.round((numBytes / 1000) * 100) / 100} kb`;
  }
  return `${numBytes} bytes`;
};

const RepoFileListView = ({ repoSlug, branchName = "main" }) => {
  const [fileList, setFileList] = useState([]);

  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const doFetchFileList = async () => {
    setIsLoading(true);
    const { data, error } = await getFileListByRepoSlugAndBranchName(
      repoSlug,
      branchName
    );
    setFileList(data);
    setError(error);
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchFileList();
  }, [branchName]);

  if (isLoading) {
    return <Container>Loading...</Container>;
  }

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <Container>
      <FileListTitle onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? "-" : "+"} Repository files
      </FileListTitle>
      <FileListContainer
        isExpanded={isExpanded}
        maxHeight={fileList?.length * 26}
      >
        {fileList?.map(file => (
          <File key={file.name}>
            {file.name}
            <FileSize>{getFileSizeString(file.size)}</FileSize>
          </File>
        ))}
      </FileListContainer>
    </Container>
  );
};

export default RepoFileListView;
