import { zapiGet, zapiPatch, zapiPut, zapiPost } from "api/zapi";

export const getJupyterManagementConfig = async () => {
  const { data, error } = await zapiGet("/jupyter-service/api/v1/management-config");
  return { data, error };
};

export const putJupyterManagementConfig = async config => {
  const { data, error } = await zapiPut("/jupyter-service/api/v1/management-config", {}, config);
  return { data, error };
};

export const getNotebookEnvironmentById = async id => {
  const { data, error } = await zapiGet(`/jupyter-service/api/v1/environments/${id}`);
  return { data, error };
};

export const patchNotebookEnvironmentById = async (id, config) => {
  const { data, error } = await zapiPatch(`/jupyter-service/api/v1/environments/${id}`, {}, config);
  return { data, error };
};

export const postNotebookEnvironment = async config => {
  const { data, error } = await zapiPost("/jupyter-service/api/v1/environments/", {}, config);
  return { data, error };
};

export const getNotebookEnvironments = async params => {
  const { data, error } = await zapiGet(`/jupyter-service/api/v1/environments`, params);
  return { data, error };
};
