import Modal from "components/ui/Modal";
import styled from "styled-components";
import GoGameView from "./GoGameView";

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  width: 1000px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
`;



const SampleGoGameModal  = ({
  games,
  open,
  onClose
}) => {
  return (
    <Modal open={open} handleClose={onClose}>
      <ModalContent>
        {
          games.map(
            (game, i) => (
              <div key={"game-" + i}>
                <GoGameView game={game}/>
              </div>
            )
          )
        }
      </ModalContent>
    </Modal>
  );  
};


export default SampleGoGameModal;