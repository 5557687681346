import styled from "styled-components";
import { useState } from "react";

import ModelDndEditor from "components/widgets/ModelDndEditor";
import ModelTextEditor from "components/widgets/ModelTextEditor";
import NavWithTabs from "components/ui/NavWithTabs";
import { BigTitle } from "components/ui/Text";

const initialConfig = {
  name: "recommended_small_latent_small_layers_1",
  latentDims: [2],
  outputDims: [],
  layerConfig: [
    {
      activeFun: "RELU",
      useBias: true,
      resIndices: [0, 8],
      inputDims: [2],
      outputDims: [27],
      type: "FC",
    },
  ],
};

const TextEditorContainer = styled.div`
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const ModelEditorContainer = styled.div`
  height: 800px;
  background-color: ${props => props.theme.color.closer0};
`;

const CreateModelView = () => {
  const [selectedTab, setSelectedTab] = useState("Graph View");

  const [modelConfig, setModelConfig] = useState(initialConfig);

  return (
    <>
      <BigTitle>{modelConfig.name}</BigTitle>
      <NavWithTabs
        tabNames={["Graph View", "Config View"]}
        selectedTabName={selectedTab}
        onTabSelect={newTab => setSelectedTab(newTab)}
      />
      {selectedTab === "Config View" && (
        <TextEditorContainer>
          <ModelTextEditor modelConfig={modelConfig} />
        </TextEditorContainer>
      )}
      {selectedTab === "Graph View" && (
        <ModelEditorContainer>
          <ModelDndEditor
            modelConfig={modelConfig}
            onModelConfigChange={newConfig => setModelConfig({ ...newConfig, name: initialConfig.name })}
          />
        </ModelEditorContainer>
      )}
    </>
  );
};

export default CreateModelView;
