import {useState, useEffect } from "react";
import styled from "styled-components";

const BlackStone = styled.circle`
  fill: black; 
  stroke: black;
`;

const WhiteStone = styled.circle`
  fill: white;
  stroke: gray;
`;


const Stone = ({ x, y, color }) => {
  const [isBlack, setIsBlack] = useState(false);  
  
  useEffect(() => {
    color === "black" && setIsBlack(true);
  }, [color])
  
  const size = 20 / 2 -2;

  return x && y && isBlack ? 
    <BlackStone r={size}  cx={x} cy={y} />
    :
    <WhiteStone r={size}  cx={x} cy={y} />
};

export default Stone;

