import { zapiGet, zapiPatch, zapiPost, zapiPut, zapiDelete, zapiPostFile } from "api/zapi";
import { apiGet, apiGetRawResponse } from "api/api-http-methods";

export const getUserProfileByUserName = async userName => {
  const { data, error } = await zapiGet(`/project-service/api/v1/user-profiles/${userName}`);
  return { data, error };
};

export const updateUserProfile = async profileData => {
  const { data, error } = await zapiPut(`/project-service/api/v1/user-profile`, {}, profileData);
  return { data, error };
};

export const getLoggedInUserProfile = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/user-profile");
  return { data, error };
};

export const getAllVisibleProjects = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/project-configs");
  return { data, error };
};

export const getProjectByProjectId = async projectId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/project-configs/${projectId}`);
  return { data, error };
};

export const getProjectOwnedByUsername = async userName => {
  const { data, error } = await zapiGet("/project-service/api/v1/projects", {
    created_by: userName,
  });
  return { data, error };
};

export const createProject = async projectConfig => {
  const { data, error } = await zapiPost("/project-service/api/v1/projects", {}, projectConfig);
  return { data, error };
};

export const updateProject = async (projectId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/projects/${projectId}`, {}, fieldsToUpdate);
  return { data, error };
};

export const getBillOfLoggedInUser = async (from, to) => {
  const { data, error } = await zapiGet("/project-service/api/v1/bill", { from, to });
  return { data, error };
};

// cubes

export const getAllVisibleCubes = async params => {
  const { data, error } = await zapiGet("/project-service/api/v1/cubes", params);
  return { data, error };
};

export const getCubeByCubeId = async cubeId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/cubes/${cubeId}`);
  return { data, error };
};

export const createCube = async cubeConfig => {
  const { data, error } = await zapiPost("/project-service/api/v1/cubes", {}, cubeConfig);
  return { data, error };
};

export const patchCube = async (cubeId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/cubes/${cubeId}`, {}, fieldsToUpdate);
  return { data, error };
};

export const deleteCube = async cubeId => {
  const { data, error } = await zapiDelete(`/project-service/api/v1/cubes/${cubeId}`);
  return { data, error };
};

// pipelines
export const getLatestPipelineOutputByCubeId = async cubeId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/cubes/${cubeId}/latest-pipeline-outputs`);
  return { data, error };
};

export const getPipelineConfigById = async pipelineId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/pipeline-configs/${pipelineId}`);
  return { data, error };
};

export const getPipelineConfigOuputById = async pipelineId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/pipeline-configs/${pipelineId}/outputs`);
  return { data, error };
};

export const getRLPipelineConfigOuputById = async pipelineId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/rl-pipeline-configs/${pipelineId}/outputs`);
  return { data, error };
};

export const postPipelineConfig = async pipelineConfig => {
  const { data, error } = await zapiPost("/project-service/api/v1/pipeline-configs", {}, pipelineConfig);
  return { data, error };
};

export const postRLPipelineConfig = async pipelineConfig => {
  const { data, error } = await zapiPost(
    "/project-service/api/v1/rl-pipeline-configs",
    {},
    { ...pipelineConfig, jobBundleConfig: null }
  );
  return { data, error };
};

export const patchPipelineConfig = async (pipelineId, pipelineConfig) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/pipeline-configs/${pipelineId}`, {}, pipelineConfig);
  return { data, error };
};

export const getRecentActivities = async params => {
  const { data, error } = await zapiGet("/project-service/api/v1/recent-activities", params);
  return { data, error };
};

export const getSearchResults = async queryString => {
  const { data, error } = await zapiGet("/project-service/api/v1/search", { query: queryString });
  return { data, error };
};

export const postSearchNlpCommand = async text => {
  const { data, error } = await zapiPost("/project-service/api/v1/search-nlp/execution", {}, { text });
  return { data, error };
};

export const postSearchNlpCommandCode = async text => {
  const { data, error } = await zapiPost("/project-service/api/v1/search-nlp/generation", {}, { text });
  return { data, error };
};

export const getFileListByPipelineId = async pipelineId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/pipeline-configs/${pipelineId}/files`);
  return { data, error };
};

export const getFileContentByPipelineIdAndFileName = async (pipelineId, filePath) => {
  const { data, error } = await apiGet(`/project-service/api/v1/pipeline-configs/${pipelineId}/file-content`, {
    filePath,
  });
  return { data, error };
};

export const getBooksSearchResult = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/booksearch/search", {}, body);
  return { data, error };
};

export const postPaymentSession = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/payment-sessions", {}, body);
  return { data, error };
};

export const getAccountSubscriptionsOfLoggedInUser = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/account-subscriptions");
  return { data, error };
};

export const getActiveAccountSubscriptionOfLoggedInUser = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/account-subscriptions/active");
  return { data, error };
};

export const postAccountSubscription = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/account-subscriptions/", {}, body);
  return { data, error };
};

export const patchAccountSubscription = async (subscriptionId, body) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/account-subscriptions/${subscriptionId}`, {}, body);
  return { data, error };
};
export const getEnrichedTrainingJob = async params => {
  const { data, error } = await zapiGet("/project-service/api/v1/enriched-training-jobs", params);
  return { data, error };
};

export const getManagementConfigOfLoggedInUser = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/management-config");
  return { data, error };
};

export const putManagementConfigOfLoggedInUser = async newConfig => {
  const { data, error } = await zapiPut("/project-service/api/v1/management-config", {}, newConfig);
  return { data, error };
};

export const getManagementConfigOfCube = async cubeId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/cube-management-configs/${cubeId}`);
  return { data, error };
};

export const putManagementConfigOfCube = async (cubeId, newConfig) => {
  const { data, error } = await zapiPut(`/project-service/api/v1/cube-management-configs/${cubeId}`, {}, newConfig);
  return { data, error };
};

export const getApiConfig = async id => {
  const { data, error } = await zapiGet(`/project-service/api/v1/api-configs/${id}`);
  return { data, error };
};

export const postApiConfig = async apiConfig => {
  const { data, error } = await zapiPost("/project-service/api/v1/api-configs", {}, apiConfig);
  return { data, error };
};

export const patchApiConfig = async (id, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/api-configs/${id}`, {}, fieldsToUpdate);
  return { data, error };
};

export const deleteApiConfig = async id => {
  const { data, error } = await zapiDelete(`/project-service/api/v1/api-configs/${id}`);
  return { data, error };
};

export const getSolution = async solutionId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/solutions/${solutionId}`);
  return { data, error };
};

export const getSolutions = async () => {
  const { data, error } = await zapiGet(`/project-service/api/v1/solutions`);
  return { data, error };
};

export const postDeploymentBasedSolution = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/deployment-based-solutions", {}, body);
  return { data, error };
};

export const patchSolution = async (solutionId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/solutions/${solutionId}`, {}, fieldsToUpdate);
  return { data, error };
};

export const getSolutionMetrics = async (solutionId, queryParams) => {
  const { data, error } = await zapiGet(`/project-service/api/v1/solutions/${solutionId}/metrics`, queryParams);
  return { data, error };
};

export const getApiConfigMetrics = async (apiConfigId, queryParams) => {
  const { data, error } = await zapiGet(`/project-service/api/v1/api-configs/${apiConfigId}/metrics`, queryParams);
  return { data, error };
};

export const getSurveys = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/surveys");
  return { data, error };
};

export const getSurvey = async surveyId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/surveys/${surveyId}`);
  return { data, error };
};

export const postSurvey = async survey => {
  const { data, error } = await zapiPost("/project-service/api/v1/surveys", {}, survey);
  return { data, error };
};

export const patchSurvey = async (surveyId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(`/project-service/api/v1/surveys/${surveyId}`, {}, fieldsToUpdate);
  return { data, error };
};

export const postSurveyResponse = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/surveys-responses", {}, body);
  return { data, error };
};

export const getSurveyResponses = async params => {
  const { data, error } = await zapiGet("/project-service/api/v1/survey-responses", params);
  return { data, error };
};

export const postImageAnnotationTaskImagesFile = async (name, file) => {
  const { data, error } = await zapiPostFile("/project-service/api/v1/image-annotation-tasks", { name }, file);
  return { data, error };
};

export const postTextAnnotationTaskTextsFile = async (name, file) => {
  const { data, error } = await zapiPostFile("/project-service/api/v1/text-annotation-tasks", { name }, file);
  return { data, error };
};

export const patchTextOfTextAnnotationTask = async (taskId, textId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(
    `/project-service/api/v1/text-annotation-tasks/${taskId}/texts/${textId}`,
    {},
    fieldsToUpdate
  );
  return { data, error };
};

export const getOcrAnnotationTasks = async taskId => {
  const { data, error } = await zapiGet("/project-service/api/v1/data-annotation-tasks/cv/list");
  return { data, error };
};

export const getOcrAnnotationPatchedTasks = async taskId => {
  const { data, error } = await zapiGet("/project-service/api/v1/data-annotation-tasks/cv/list-patched");
  return { data, error };
};

export const getDataForOcrAnnotationTask = async taskId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/data-annotation-tasks/cv/${taskId}/images`);
  return { data, error };
};

export const getPatchedLabelsForOcrAnnotationTask = async taskId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/data-annotation-tasks/cv/${taskId}/patched-labels`);
  return { data, error };
};

export const patchDataForOcrAnnotationTask = async (taskId, dataToPatch) => {
  const { data, error } = await zapiPatch(
    `/project-service/api/v1/data-annotation-tasks/cv/${taskId}/labels`,
    null,
    dataToPatch
  );
  return { data, error };
};

export const getImageAnnotationTasks = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/image-annotation-tasks");
  return { data, error };
};

export const getImagesForAnnotationTask = async taskId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/image-annotation-tasks/${taskId}/images`);
  return { data, error };
};

export const patchImageOfAnnotationTask = async (taskId, imageId, fieldsToUpdate) => {
  const { data, error } = await zapiPatch(
    `/project-service/api/v1/image-annotation-tasks/${taskId}/images/${imageId}`,
    {},
    fieldsToUpdate
  );
  return { data, error };
};

export const createDefaultImageAnnotationTask = async () => {
  const { data, error } = await zapiPut("/project-service/api/v1/image-annotation-task-default", {}, {});
  return { data, error };
};

export const getTextAnnotationTasks = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/text-annotation-tasks");
  return { data, error };
};

export const getTextsForTextAnnotationTask = async taskId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/text-annotation-tasks/${taskId}/texts`);
  return { data, error };
};

export const createDefaultTextAnnotationTask = async () => {
  const { data, error } = await zapiPut("/project-service/api/v1/text-annotation-task-default", {}, {});
  return { data, error };
};

export const postSolutionSession = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/solution-sessions", {}, body);
  return { data, error };
};

export const postSolutionQuery = async body => {
  const { data, error } = await zapiPost("/project-service/api/v1/solution-queries", {}, body);
  return { data, error };
};

export const postSentenceLabelingTasks = async (name, labels) => {
  const { data, error } = await zapiPost("/project-service/api/v1/sentence-labeling-tasks", { name, labels }, null);
  return { data, error };
};

export const getSentenceLabelingTasks = async () => {
  const { data, error } = await zapiGet("/project-service/api/v1/sentence-labeling-tasks");
  return { data, error };
};

export const getSentenceLabelingTaskById = async taskId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/sentence-labeling-tasks/${taskId}`);
  return { data, error };
};

export const getTaskSentenceLabelPairs = async taskId => {
  const { data, error } = await zapiGet(
    `/project-service/api/v1/sentence-labeling-tasks/${taskId}/sentence-label-pairs`
  );
  return { data, error };
};

export const postTaskSentenceLabelPairs = async pairs => {
  const { data, error } = await zapiPost(
    `/project-service/api/v1/sentence-labeling-tasks/sentence-label-pairs`,
    null,
    pairs
  );
  return { data, error };
};

export const saveUserPreferences = async (user, preferences) => {
  const { data, error } = await zapiPut(`/project-service/api/v1/calendar/${user}/preference`, null, preferences);
  return { data, error };
};

export const getUserPreferences = async user => {
  const { data, error } = await zapiGet(`/project-service/api/v1/calendar/${user}/preference`);
  return { data, error };
};

export const cloneCube = async (cubeId, name) => {
  const { data, error } = await zapiPost(`/project-service/api/v1/cubes/${cubeId}/clone`, { name }, null);
  return { data, error };
};

export const putLike = async likeFields => {
  const { data, error } = await zapiPut("/project-service/api/v1/activities/likes", {}, likeFields);
  return { data, error };
};

export const deleteLike = async params => {
  const { data, error } = await zapiDelete("/project-service/api/v1/activities/likes", params);
  return { data, error };
};

export const getActivitiesClones = async params => {
  const { data, error } = await zapiGet("/project-service/api/v1/activities/clones", params);
  return { data, error };
};

export const getLikesActivties = async params => {
  const { data, error } = await zapiGet("/project-service/api/v1/activities/likes", params);
  return { data, error };
};

export const postContinuePipelineTraining = async pipelineId => {
  const { data, error } = await zapiPost(
    `/project-service/api/v1/pipeline-configs/${pipelineId}/continue-training`,
    {},
    null
  );
  return { data, error };
};

export const getPipelineTrainingLog = async pipelineId => {
  const { data, error } = await zapiGet(`/project-service/api/v1/pipeline-configs/${pipelineId}/training-log`);
  return { data, error };
};

export const getCubesFindByKey = async (params = {}) => {
  const { data, error } = await zapiGet("/project-service/api/v1/cubes/find-by-key", params);
  return { data, error };
};

export const triggerDownloadOfModelFile = async (cubeId, params = {}) => {
  const res = await apiGetRawResponse(`/project-service/api/v1/cubes/${cubeId}/download-model`, params);
  const blob = await res.blob();
  const objectUrl = window.URL.createObjectURL(blob);

  const contentDispositionHeader = res?.headers.get("Content-Disposition");
  const fileName = contentDispositionHeader?.split("filename=")?.[1];

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  anchor.href = objectUrl;
  anchor.download = fileName || "file.pdf";
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};
