import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";

const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: inherit;
`;

const UserAvatar = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.color.primary};
  object-fit: cover;
`;

const CreatedBy = styled.span`
  padding-right: 8px;
  border-radius: 100px;
  width: max-content;
  display: flex;
  align-items: center;
  font-size: 11px;
  color: ${props => props.theme.color.closest};
  gap: 5px;
  text-decoration: none;
  background-color: ${props => props.theme.color.closer1_5};

  :hover {
    background-color: ${props => props.theme.color.closer2};
  }
`;

const UserBadge = ({ userName, userAvatar }) => (
  <Link to={`/${userName}`}>
    <CreatedBy>
      <UserAvatar src={userAvatar} alt={userName} />
      {userName}
    </CreatedBy>
  </Link>
);

export default UserBadge;
