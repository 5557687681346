import { useState, useEffect } from "react";
import styled from "styled-components";
import { chunk } from "lodash";

const Svg = styled.svg`
  padding: 20px;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.color.closer0};
  box-sizing: content-box;
`;

const Square = styled.rect`
  fill: ${props => `${props.theme.color.primary}${getAlphaHexFromNumOfActivities(props.value)}`};
  stroke-width: 4px;
  stroke: white;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
`;

const SVGContainer = styled.div``;

const HoveredContainer = styled.div`
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  display: flex;
  padding-top: 5px;
  gap: 5px;
  align-items: center;
  height: 35px;
`;

const HoveredLabel = styled.div`
  padding: 8px;
  font-size: 14px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const HoveredProb = styled.div`
  font-size: 16px;
`;

const getAlphaHexFromNumOfActivities = value => {
  return Math.round(255 * value).toString(16);
};

const EmbeddingGridView = ({ embeddingVec, classLabels, numSquaresRow = 10, squareSize = 30 }) => {
  const [normalizedVec, setNormalizedVec] = useState(null);
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const [hoveredLabel, setHoveredLabel] = useState(null);
  const [hoveredProb, setHoveredProb] = useState(null);

  const doNormalization = () => {
    const max = Math.max(...embeddingVec);
    const min = Math.min(...embeddingVec);
    const interval = max - min;

    interval > 0
      ? setNormalizedVec(
          chunk(
            embeddingVec.map(e => (e - min) / interval),
            numSquaresRow
          )
        )
      : setNormalizedVec(chunk(embeddingVec, numSquaresRow));
  };

  const doSetGridWidthAndHeight = () => {
    const embeddingSize = embeddingVec.length;
    setWidth(numSquaresRow * squareSize);
    setHeight(Math.ceil(embeddingSize / numSquaresRow) * squareSize);
  };

  useEffect(() => {
    embeddingVec && doNormalization();
    embeddingVec && doSetGridWidthAndHeight();
  }, [embeddingVec]);

  return (
    <Container>
      {normalizedVec && width && height && (
        <SVGContainer>
          <Svg width={width} height={height}>
            {normalizedVec.map((rowChunck, rowIdx) =>
              rowChunck.map((col, colIdx) => (
                <Square
                  onMouseOver={() => {
                    if (classLabels) {
                      const val = embeddingVec[rowIdx * numSquaresRow + colIdx];
                      setHoveredLabel(classLabels[rowIdx * numSquaresRow + colIdx]);
                      setHoveredProb(val);
                      return;
                    }
                  }}
                  onMouseOut={() => {
                    setHoveredLabel(null);
                    setHoveredProb(null);
                  }}
                  value={col}
                  key={`${rowIdx},${colIdx}`}
                  y={rowIdx * squareSize}
                  x={colIdx * squareSize}
                  width={squareSize}
                  height={squareSize}
                />
              ))
            )}
          </Svg>
        </SVGContainer>
      )}
      <HoveredContainer isVisible={hoveredLabel && hoveredProb}>
        <HoveredLabel>{hoveredLabel}</HoveredLabel>
        <HoveredProb>{hoveredProb}</HoveredProb>
      </HoveredContainer>
    </Container>
  );
};

export default EmbeddingGridView;
