import { useEffect, useState } from "react";
import styled from "styled-components";
import Modal from "components/ui/Modal";
import { getAnnotationsByFileAndPage, saveAnnotationsByFileAndPage } from "api/services/packageService";
import ImageWithOCRAnnotationsAndHumanAnnottions from "components/widgets/ImageWithOCRAnnotationsAndHumanAnnotations";

const ModalContent = styled.div`
  width: 800px;
  height: 600px;
  padding: 35px;
`;

const AnnotationModal = ({ open, handleClose, base64Data, ocrAnnotations, fileId, pageNumber }) => {
  const [humanAnnotations, setHumanAnnotations] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    open && doFetchHumanAnnotations();
  }, [open]);

  const doFetchHumanAnnotations = async () => {
    const { data, error } = await getAnnotationsByFileAndPage(fileId, pageNumber);
    if (!error) {
      setHumanAnnotations(data);
    }
  };

  const doPatchHumanAnnotations = async newAnnotations => {
    const filePageAnnotation = {
      fileId,
      pageNumber,
      annotations: newAnnotations,
    };
    const { error } = await saveAnnotationsByFileAndPage(fileId, pageNumber, filePageAnnotation);
    if (error) {
      setMessage("Failed while saving the annoation changes");
    } else {
      setMessage("Annotaitons have been succesfully updated.");
    }
  };

  console.log(humanAnnotations);

  return (
    <Modal open={open} handleClose={handleClose}>
      <ModalContent>
        {/* <p>{message}</p> */}
        <ImageWithOCRAnnotationsAndHumanAnnottions
          width={500}
          height={500}
          base64Data={base64Data}
          annotations={ocrAnnotations}
          humanAnnotations={humanAnnotations}
          onNewHumanAnnotations={newAnnotations => setHumanAnnotations(newAnnotations)}
          doPatchHumanAnnotations={newAnnotations => {
            doPatchHumanAnnotations(newAnnotations);
          }}
        />
      </ModalContent>
    </Modal>
  );
};

export default AnnotationModal;
