import { useEffect, useState } from "react";
import ModelView from "components/views/ModelView";
import PipelineIdRadioSelector from "components/widgets/PipelineIdRadioSelector";

const MultiModelView = ({ pipelineOutputs }) => {
  const [selectedPipelineId, setSelectedPipelineId] = useState(null);

  const selectePipelineOutput = pipelineOutputs?.find(
    pipelineOutput => pipelineOutput.pipelineId === selectedPipelineId
  );
  const selectedModelId = selectePipelineOutput?.model?.id;

  useEffect(() => {
    setSelectedPipelineId(pipelineOutputs[0]?.pipelineId);
  }, []);

  return (
    <>
      <PipelineIdRadioSelector
        pipelineIds={pipelineOutputs.map(output => output.pipelineId)}
        selectedPipelineId={selectedPipelineId}
        onPipelineIdSelect={newPipelineId => setSelectedPipelineId(newPipelineId)}
      />
      {selectedModelId && (
        <ModelView
          modelId={selectedModelId}
          modelView={"Graph"}
          inputCols={selectePipelineOutput?.dataset?.config?.featureTypeDescriptors?.map(feature => feature.key)}
          outputCols={selectePipelineOutput?.dataset?.config?.featureTypeDescriptors?.map(feature => feature.key)}
          datasetFeatureTypeDescriptors={selectePipelineOutput?.dataset?.config?.featureTypeDescriptors}
        />
      )}
    </>
  );
};

export default MultiModelView;
