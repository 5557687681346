import { useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { uploadPackage } from "api/services/solutions/ocrSolutionService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import FileInput from "components/ui/FileInput";
import { BigTitle } from "components/ui/Text";
import PackageView from "components/views/application/OcrSolution/PackageView";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";

const PrevNextButtons = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  align-items: center;
  background-color: ${props => props.theme.color.closer0};
  padding: 5px;
  padding-left: 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const ArrowButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  border-radius: 20px;
  width: max-content;
  ${props => props.isDisabled && "opacity: 0.5; poinyer-events: none;"}
  cursor: ${props => !props.isDisabled && "pointer"};
  :hover {
    background-color: ${props => !props.isDisabled && props.theme.color.closer1};
  }
`;

const GridContainer = styled.div`
  justify-items: end;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const OcrSolution = () => {
  const [packagesOutputs, setPackagesOutputs] = useState([]);
  const [isPackageBeingPrepared, setIsPackageBeingPrepared] = useState(false);
  const [currentPackageInd, setCurrentPackageInd] = useState(0);
  const [scannedDocInds, setScannedDocInds] = useState([]);

  return (
    <CenteredWithTopNavLayout centerColumnMaxWidth="calc(100% - 175px - 100px)">
      <BigTitle>Document OCR</BigTitle>
      <Gap />
      <FileInput
        isDisabled={isPackageBeingPrepared}
        onFileSelect={async file => {
          setPackagesOutputs([]);
          setIsPackageBeingPrepared(true);

          const { data } = await uploadPackage(file);

          setPackagesOutputs(data);
          setIsPackageBeingPrepared(false);
        }}
      />
      {packagesOutputs?.length === 1 && <Gap />}
      {isPackageBeingPrepared && <CircularProgress />}
      {packagesOutputs?.length > 1 && (
        <GridContainer>
          <PrevNextButtons>
            {currentPackageInd + 1} / {packagesOutputs.length}
            <ArrowButtonContainer
              isDisabled={currentPackageInd === 0}
              onClick={() => {
                let newInds = [...scannedDocInds];
                newInds[currentPackageInd] = true;
                setScannedDocInds(newInds);
                setCurrentPackageInd(ind => ind - 1);
              }}
            >
              <ArrowLeft />
            </ArrowButtonContainer>
            <ArrowButtonContainer
              isDisabled={currentPackageInd === packagesOutputs.length - 1}
              onClick={() => {
                let newInds = [...scannedDocInds];
                newInds[currentPackageInd] = true;
                setScannedDocInds(newInds);
                setCurrentPackageInd(ind => ind + 1);
              }}
            >
              <ArrowRight />
            </ArrowButtonContainer>
          </PrevNextButtons>
        </GridContainer>
      )}
      {packagesOutputs?.length > 0 && (
        <PackageView
          hasBeenScanned={scannedDocInds?.[currentPackageInd]}
          noTopRightBorderRadius={packagesOutputs?.length > 1}
          packageDetails={packagesOutputs[currentPackageInd]}
        />
      )}
    </CenteredWithTopNavLayout>
  );
};

export default OcrSolution;
