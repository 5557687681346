import { useNavigate } from "react-router-dom";
import { useState } from "react";
import styled from "styled-components";

import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { AnimatedLogo } from "components/ui/Icons";
import { useEffect } from "react";

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.furthest};
  padding: 20px;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );

  font-size: 18px;

  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const GoButton = styled(Button)`
  grid-area: top;
  justify-self: center;
  padding: 4px;

  opacity: 0;
  ${props => props.isVisible && "opacity: 1;"}
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
`;

const MessageModalTrigger = ({ isReady, isVisible, isDisabled, onClickLaunch = () => {} }) => {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [hasPeriodPassed, setHasPeriodPassed] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const timeoutId = setTimeout(() => {
      setHasPeriodPassed(true);
    }, 4000);

    return () => clearTimeout(timeoutId);
  }, [isOpen]);

  useEffect(() => {
    if (!hasPeriodPassed) {
      return;
    }

    if (isReady) {
      setIsOpen(false);
    }
  }, [hasPeriodPassed, isReady]);

  return (
    <>
      <GoButton
        isDisabled={isDisabled}
        variant="highlighted"
        onClick={() => {
          setIsOpen(true);
          onClickLaunch();
        }}
        isVisible={isVisible}
        value={isDisabled ? "Launching..." : "Launch"}
      />
      <Modal open={isOpen} handleClose={() => setIsOpen(false)}>
        <ModalContent>
          Deploying to the cloud...
          <AnimatedLogo height="48px" />
        </ModalContent>
      </Modal>
    </>
  );
};

export default MessageModalTrigger;
