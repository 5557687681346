import { useState, useEffect } from "react";
import styled from "styled-components";
import { isEdge, isNode } from "react-flow-renderer";
import { cloneDeep } from "lodash";

import { ReactFlowModelEditor } from "components/widgets/ModelViewer";
import { getReactFlowElementsFromModelConfig } from "components/widgets/ModelViewer/react-flow-utils";
import { isNewEdgeValid, getModelConfigFromReactFlowElements } from "./dnd-editor-utils";
import { SidebarNodeIcon } from "components/widgets/ModelViewer/Icons";

const Container = styled.div`
  display: flex;
  height: 100%;
`;

const ViewerContainer = styled.div`
  width: 100%;
`;

const Sidebar = styled.div`
  padding: 10px;
  width: 120px;
  background-color: #d7d7d7;
`;

const NodeIconContainer = styled.div`
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  right: 150px;
  position: absolute;
`;

const getNewNode = (dropEvent, reactFlowInstance, deleteElementWithId, onTypeNewDimsForNode) => {
  const allNodeIds = reactFlowInstance
    .getElements()
    .filter(element => isNode(element))
    .map(element => parseInt(element.id));

  const newNodeId = String(Math.max(...allNodeIds) + 1);

  const newNodePosition = reactFlowInstance.project({
    x: dropEvent.clientX - dropEvent.target.getBoundingClientRect().left,
    y: dropEvent.clientY - dropEvent.target.getBoundingClientRect().top,
  });

  return {
    id: newNodeId,
    data: {
      dims: [0],
      onClickDelete: deleteElementWithId,
      onTypeNewDimsForNode,
    },
    position: newNodePosition,
    type: "dataNode",
  };
};

const getNewEdge = (sourceId, targetId, deleteElementWithId, updateActiveFunOfEdge) => {
  const newEdgeId = `${sourceId}-${targetId}`;

  return {
    id: newEdgeId,
    source: sourceId,
    target: targetId,
    type: "operationEdge",
    data: {
      activeFun: "LINEAR",
      onClickDelete: deleteElementWithId,
      updateActiveFunOfEdge: updateActiveFunOfEdge,
    },
  };
};

const attachOnClickDeleteFuncs = (reactFlowElements, deleteElementWithId) => {
  const newElements = reactFlowElements.map(element => {
    const newElement = cloneDeep(element);
    newElement.data.onClickDelete = elementId => deleteElementWithId(elementId);
    return newElement;
  });

  return newElements;
};

const attachUpdateActiveFunFuncs = (reactFlowElements, updateActiveFunOfEdge) => {
  const newElements = reactFlowElements.map(element => {
    const newElement = cloneDeep(element);
    newElement.data.updateActiveFunOfEdge = updateActiveFunOfEdge;
    return newElement;
  });

  return newElements;
};

const attachOnDimsEditFuncs = (reactFlowElements, onTypeNewDimsForNode) => {
  const newNodeElements = reactFlowElements
    .filter(element => isNode(element))
    .map(element => {
      const newElement = cloneDeep(element);
      newElement.data.onTypeNewDimsForNode = onTypeNewDimsForNode;
      return newElement;
    });

  return [...newNodeElements, ...reactFlowElements.filter(element => isEdge(element))];
};

const ModelDndEditor = ({ modelConfig, onModelConfigChange, datasetFeatureTypeDescriptors }) => {
  const [reactFlowElements, setReactFlowElements] = useState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const reactFlowElementsFromModelConfig = getReactFlowElementsFromModelConfig(
      modelConfig,
      datasetFeatureTypeDescriptors
    );
    setReactFlowElements(reactFlowElementsFromModelConfig);
  }, []);

  const deleteElementWithId = elementId => {
    const elementsWithoutDeleted = reactFlowInstance.getElements().filter(element => !element.id.includes(elementId));
    setReactFlowElements(elementsWithoutDeleted);
  };

  const updateActiveFunOfEdge = (edgeId, newActiveFun, newType = "FC") => {
    const elements = reactFlowInstance.getElements();
    const edgeToUpdate = elements.find(element => element.id === edgeId);
    edgeToUpdate.data.activeFun = newActiveFun;
    edgeToUpdate.data.type = newType;
    setReactFlowElements(elements);
  };

  const onTypeNewDimsForNode = (nodeId, newDimsString) => {
    const newDims = newDimsString.split(",").map(dimString => {
      const parsedDim = parseInt(dimString);
      if (isNaN(parsedDim)) {
        return "";
      }
      return parsedDim;
    });

    const newElements = reactFlowInstance.getElements().map(element => {
      if (element.id === nodeId) {
        element.data.dims = newDims;
      }
      return element;
    });

    setReactFlowElements(rescaleNodeHeights(newElements));
  };

  const rescaleNodeHeights = () => {
    const currentElements = reactFlowInstance.getElements();
    let [minModelDim, maxModelDim] = [0, 0];

    currentElements
      .filter(el => isNode(el))
      .forEach(node => {
        minModelDim = Math.min(minModelDim, ...node.data.dims);
        maxModelDim = Math.max(maxModelDim, ...node.data.dims);
      });

    const rescaledElements = currentElements.map(nodeEl => {
      if (isNode(nodeEl)) {
        nodeEl.data.minModelDim = minModelDim;
        nodeEl.data.maxModelDim = maxModelDim;
      }
      return nodeEl;
    });

    return rescaledElements;
  };

  useEffect(() => {
    if (!reactFlowInstance) {
      return;
    }

    const elementWithDeleteFuncs = attachOnClickDeleteFuncs(reactFlowElements, deleteElementWithId);
    const elementsWithDeleteAndDimEditFuncs = attachOnDimsEditFuncs(elementWithDeleteFuncs, onTypeNewDimsForNode);
    const finalElements = attachUpdateActiveFunFuncs(elementsWithDeleteAndDimEditFuncs, updateActiveFunOfEdge);

    setReactFlowElements(finalElements);
    setTimeout(() => reactFlowInstance.fitView(), 100);
  }, [reactFlowInstance]);

  useEffect(() => {
    if (reactFlowElements?.length === 0) {
      return;
    }
    const newModelConfig = getModelConfigFromReactFlowElements(reactFlowElements);
    onModelConfigChange(newModelConfig);
  }, [reactFlowElements]);

  const onNodeDrop = e => {
    const newNodeElement = getNewNode(e, reactFlowInstance, deleteElementWithId, onTypeNewDimsForNode);
    setReactFlowElements([...reactFlowElements, newNodeElement]);
  };

  const onEdgeConnect = ({ source: sourceId, target: targetId }) => {
    const newEdgeElement = getNewEdge(sourceId, targetId, deleteElementWithId, updateActiveFunOfEdge);

    const { isValid, message } = isNewEdgeValid(reactFlowElements, newEdgeElement);
    if (!isValid) {
      setErrorMessage(message);
      setTimeout(() => setErrorMessage(""), 2000);
      return;
    }

    setReactFlowElements([...reactFlowElements, newEdgeElement]);
  };

  return (
    <Container>
      <Sidebar>
        <NodeIconContainer draggable>
          <SidebarNodeIcon />
        </NodeIconContainer>
      </Sidebar>
      <ViewerContainer onDrop={onNodeDrop} onDragOver={e => e.preventDefault()}>
        <ReactFlowModelEditor
          onLoad={reactFlowInstance => setReactFlowInstance(reactFlowInstance)}
          reactFlowElements={reactFlowElements}
          onEdgeConnect={onEdgeConnect}
        />
      </ViewerContainer>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Container>
  );
};

export default ModelDndEditor;
