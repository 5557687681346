import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import TopBarLayout from "../TopBarLayout";
import CalendarView from "components/views/market-place/calendar";

const GoBackContainer = styled.div`
  padding-left: 100px;
  :hover {
    cursor: pointer;
    color: ${props => props.theme.color.primary};
  }
`;

const MsgContainer = styled.div`
  padding-left: 100px;
  margin-top: 50px;
  font-size: 22px;
  font-weight: bold;
`;

const appName2View = tab => {
  return {
    calendar: <CalendarView tab={tab} />,
  };
};

const defaultView = () => (
  <MsgContainer>
    <h4>The Application is not found !</h4>
  </MsgContainer>
);

const AppViewPage = () => {
  const { appName } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedTab = searchParams?.get("tab") || "Overview";

  return (
    <TopBarLayout>
      <GoBackContainer onClick={() => navigate("/market-place")}>
        <ReplyOutlinedIcon /> Go back to Marketplace
      </GoBackContainer>
      {appName2View(selectedTab)[appName] || defaultView()}
    </TopBarLayout>
  );
};

export default AppViewPage;
