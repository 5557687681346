import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { getDataForOcrAnnotationTask } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import Button from "components/ui/Button";
import PrevNextPageButtons from "components/ui/PrevNextPageButtons";

const LabelArea = styled.input`
  width: 30px;
  height: 30px;
  border: 1px solid red;
  font-size: 20px;
`;

const Thumbnail = styled.div`
  position: relative;
  width: calc(950px / 6);
  height: calc(950px / 6);
  background-color: ${props => props.theme.color.closer0};
  ${props => props.base64Data && `background-image: url(data:image/png;base64,${props.base64Data})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  :hover {
    background-image: linear-gradient(to bottom, rgba(255, 255, 0, 0.5), rgba(0, 0, 255, 0.5)),
      url(data:image/png;base64,${props => props.base64Data});
  }
`;

const ImagesGrid = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

const SaveButtonAndPagination = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const ImageLabellingTaskPage = () => {
  const { imageLabellingTaskId } = useParams();
  const [ocrData, setOcrData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [firstItemInd, setFirstItemInd] = useState(0);

  useEffect(() => {
    doPopulateDataForTask();
  }, [imageLabellingTaskId]);

  const doPopulateDataForTask = async () => {
    const { data, error } = await getDataForOcrAnnotationTask(imageLabellingTaskId);
    setOcrData(data);
    setIsLoading(false);
    setError(error);
  };

  if (isLoading) {
    return <CenteredWithTopNavLayout>Loading...</CenteredWithTopNavLayout>;
  }

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <Button value="Save" />
      <Gap />
      <ImagesGrid>
        {ocrData
          .filter(instance => instance.label.includes(filterLabel))
          .slice(firstItemInd, firstItemInd + 24)
          .map(instance => (
            <Thumbnail
              key={instance.id}
              base64Data={instance?.image}
              onMouseEnter={() => {
                const input = document.querySelector(`[id=input-${instance.id}]`);
                input?.select();
              }}
            >
              <LabelArea
                id={`input-${instance.id}`}
                value={instance?.label}
                onChange={e => {
                  const newLabel = e.target.value;
                  const newOcrData = [...ocrData];
                  newOcrData[instance.id] = { ...ocrData[instance.id], label: newLabel };
                  setOcrData(newOcrData);
                }}
              />
            </Thumbnail>
          ))}
      </ImagesGrid>
      <Gap />
      <SaveButtonAndPagination>
        <Button value="Save" />
        <PrevNextPageButtons
          isPrevDisabled={firstItemInd === 0}
          isNextDisabled={firstItemInd >= ocrData?.length - 24}
          onClickNext={() => setFirstItemInd(firstItemInd + 24)}
          onClickPrev={() => setFirstItemInd(firstItemInd - 24)}
        />
      </SaveButtonAndPagination>
    </CenteredWithTopNavLayout>
  );
};

export default ImageLabellingTaskPage;
