import styled from "styled-components";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { getPackageById } from "api/services/packageService";
import { Gap } from "components/Layout";
import { CenteredWithTopNavLayoutInternal } from "components/PackageLayout";
import PackageView from "components/views/application/OcrSolution/PackageView";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { Link } from "react-router-dom";

const PrevNextButtons = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  align-items: center;
  background-color: ${props => props.theme.color.closer0};
  padding: 5px;
  padding-left: 10px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const ArrowButtonContainer = styled.div`
  display: grid;
  justify-content: center;
  border-radius: 20px;
  width: max-content;
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  cursor: ${props => !props.isDisabled && "pointer"};
  :hover {
    background-color: ${props => !props.isDisabled && props.theme.color.closer1};
  }
`;

const GridContainer = styled.div`
  justify-items: end;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const PackagePage = () => {
  const { packageId } = useParams();

  const [packagesOutputs, setPackagesOutputs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPackageInd, setCurrentPackageInd] = useState(0);
  const [scannedDocInds, setScannedDocInds] = useState([]);

  const userName = getLoggedInUserName();

  useEffect(() => {
    doPopulatePackageDetails();
  }, [packageId]);

  const doPopulatePackageDetails = async () => {
    const { data } = await getPackageById(packageId);
    const packageOutputs = data?.content?.map(page => ({
      image: page.image,
      annotations: page.annotations,
      tables: page?.output?.filter(out => out.type === "table"),
      texts: page?.output?.filter(out => out.type === "text"),
      packageName: packageId,
    }));
    setPackagesOutputs(packageOutputs);
    setIsLoading(false);
  };

  if (!userName) {
    return (
      <div>
        Please log in first to see the content. <Link to={"/login"}>Click here to login.</Link>
      </div>
    );
  }

  return (
    <CenteredWithTopNavLayoutInternal>
      <Gap />
      {isLoading && "Loading..."}
      {packagesOutputs?.length > 1 && (
        <GridContainer>
          <PrevNextButtons>
            {currentPackageInd + 1} / {packagesOutputs.length}
            <ArrowButtonContainer
              isDisabled={currentPackageInd === 0}
              onClick={() => {
                let newInds = [...scannedDocInds];
                newInds[currentPackageInd] = true;
                setScannedDocInds(newInds);
                setCurrentPackageInd(ind => ind - 1);
              }}
            >
              <ArrowLeft />
            </ArrowButtonContainer>
            <ArrowButtonContainer
              isDisabled={currentPackageInd === packagesOutputs.length - 1}
              onClick={() => {
                let newInds = [...scannedDocInds];
                newInds[currentPackageInd] = true;
                setScannedDocInds(newInds);
                setCurrentPackageInd(ind => ind + 1);
              }}
            >
              <ArrowRight />
            </ArrowButtonContainer>
          </PrevNextButtons>
        </GridContainer>
      )}
      {packagesOutputs?.length > 0 && (
        <PackageView
          hasBeenScanned
          noTopRightBorderRadius={packagesOutputs?.length > 1}
          packageDetails={packagesOutputs[currentPackageInd]}
          index={currentPackageInd}
        />
      )}
    </CenteredWithTopNavLayoutInternal>
  );
};

export default PackagePage;
