import styled from "styled-components";
import Chip from "components/ui/Chip";

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  padding: 10px;
  margin: 10px;
`;

const ImageLabelDataPreview = ({ datasetPreview, imageField, labelFields }) => {
  return (
    <GridWrapper>
      {datasetPreview?.map((instance, i) => {
        const sqrt = parseInt(Math.sqrt(datasetPreview.length));
        if (i < sqrt * sqrt) {
          return (
            <div key={i} style={{ width: `${100 / sqrt}%` }}>
              {labelFields.map((field, j) => (
                <Chip key={`${i}-${j}`} label={instance[field]} color="primary" />
              ))}
              <Image src={`data:image/png;base64,${instance[imageField]}`} />
            </div>
          );
        }
      })}
    </GridWrapper>
  );
};

export default ImageLabelDataPreview;
