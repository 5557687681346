import { useState, useEffect } from "react";
import styled from "styled-components";

import {
  generateDatasetProcessedPreview,
  getDatasetPreviewByDatasetId,
  getDatasetInfoByDatasetId,
  deleteDataset,
} from "api/services/dataService";
import { getLoggedInUserName } from "api/services/authenticationService";
import TabularDatasetPreview from "components/widgets/TabularDatasetPreview";
import ImageDatasetPreview from "components/widgets/ImageLabelDataPreview";
import { SmallTitle } from "components/ui/Text";
import { CenterContent } from "components/Layout";
import Button from "components/ui/Button";
import Modal from "components/ui/Modal";
import { Link } from "react-router-dom";
import CreateCubeButton from "components/widgets/CreateCubeButton";
import Delete from "@material-ui/icons/Delete";
import Tooltip from "components/ui/Tooltip";
import { isImageDataset, isTabularDataset } from "utils/dataset";
import TabularDatasetPreviewWithAnnotations from "components/views/TabularDatasetPreviewWithAnnotations";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${props => props.theme.color.closer0_5};
`;

const ModalContent = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}
  padding: 20px;
  display: grid;
  gap: 10px;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
`;

const AreYouSureText = styled.div`
  font-weight: bold;
  grid-column: span 2;
`;

const TopButtons = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-end;
  padding: 5px 0;
`;

const RedButton = styled(Button)`
  padding: 4px 0;
  color: ${props => (props.isDisabled ? props.theme.color.closer2 : props.theme.color.error)};
  border: 2px solid ${props => (props.isDisabled ? props.theme.color.closer2 : props.theme.color.error)};
  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.error};
  }
`;

const CreateCubeFromDatasetButton = styled(CreateCubeButton)`
  border: 2px solid ${props => props.theme.color.primary};
`;

const SuccessText = styled.span``;

const CubeLink = styled(Link)`
  font-weight: bold;
  color: ${props => props.theme.color.primary};
`;

const DeleteDatasetButton = styled.div`
  ${props => props.isDisabled && "pointer-events: none;"}
  color: ${props => (props.isDisabled ? props.theme.color.closer1 : props.theme.color.error)};
  border: 2px solid ${props => (props.isDisabled ? props.theme.color.closer1 : props.theme.color.error)};
  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.error};
  }
  border-radius: 20px;
  cursor: pointer;
  padding: 1px 10px;
`;

const DeleteDatasetConfirmModal = ({ isOpen, onClose, onClickYes, isDisabled }) => (
  <Modal title="Confirmation" open={isOpen} handleClose={onClose}>
    <ModalContent isDisabled={isDisabled}>
      <AreYouSureText>Are you sure you want to delete this dataset?</AreYouSureText>
      <RedButton value="Yes, delete it" onClick={onClickYes} />
      <Button value="No, cancel" onClick={onClose} />
    </ModalContent>
  </Modal>
);

const getImageAndLabelField = datasetInfo => {
  const labelFieldTypes = ["CATEGORICAL"];
  const labelFields = datasetInfo?.config?.featureTypeDescriptors
    .filter(e => labelFieldTypes.includes(e.type.typeName))
    .map(e => e.key);

  const imageField = datasetInfo?.config?.featureTypeDescriptors.filter(
    descriptor => descriptor.type?.structure === "IMAGE"
  )[0].key;

  return { imageField, labelFields };
};

const DatasetPreview = ({
  datasetId,
  dataProcessingConfig,
  isShowingTopButtons,
  isShowingTitle = true,
  isDatasetEditable,
}) => {
  const [datasetInfo, setDatasetInfo] = useState(null);
  const [datasetPreview, setDatasetPreview] = useState(null);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isDeletionConfirmModalOpen, setIsDeletionConfirmModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [createdCubeId, setCreatedCubeId] = useState(null);

  const doFetchDatasetProcessedPreview = async () => {
    const { data, error } = await generateDatasetProcessedPreview(datasetId, dataProcessingConfig);
    setError(error);
    setDatasetPreview(data);
    setIsLoading(false);
  };

  const doFetchDatasetPreview = async () => {
    const { data, error } = await getDatasetPreviewByDatasetId(datasetId);
    setError(error);
    setDatasetPreview(data);
    setIsLoading(false);
  };

  const doFetchDatasetInfo = async () => {
    const { data, error } = await getDatasetInfoByDatasetId(datasetId);
    setError(error);
    setDatasetInfo(data);
  };

  useEffect(() => {
    if (!datasetId) {
      return;
    }

    doFetchDatasetInfo();
    if (dataProcessingConfig) {
      doFetchDatasetProcessedPreview();
      return;
    }
    doFetchDatasetPreview();
  }, [datasetId, dataProcessingConfig]);

  if (!datasetId) {
    return <Container>You no longer have access to this dataset</Container>;
  }

  if (isLoading || !datasetInfo) {
    return <Container>Loading dataset...</Container>;
  }

  if (error?.redirectUrl) {
    window.location = error.redirectUrl;
    return null;
  }

  if (error?.message) {
    return `Error while fetching dataset: ${error.message}`;
  }

  const doesDatasetBelongToLoggedInUser = datasetInfo?.createdBy === getLoggedInUserName();

  return (
    <Container>
      {isShowingTitle && (
        <CenterContent>
          <SmallTitle>Dataset: {datasetInfo?.name}</SmallTitle>
        </CenterContent>
      )}

      {isShowingTopButtons && (
        <TopButtons>
          {createdCubeId ? (
            <SuccessText>
              Cube created successfully,&nbsp;<CubeLink to={`/cube/${createdCubeId}`}>check it out</CubeLink>
            </SuccessText>
          ) : (
            <Tooltip title="New cube">
              <CreateCubeFromDatasetButton
                datasetId={datasetId}
                datasetTitle={datasetInfo?.name}
                onCubeCreated={newCubeId => setCreatedCubeId(newCubeId)}
                isHelpTextDisabled
              />
            </Tooltip>
          )}
          <Tooltip title="Delete dataset">
            <DeleteDatasetButton
              onClick={() => setIsDeletionConfirmModalOpen(true)}
              isDisabled={!doesDatasetBelongToLoggedInUser}
            >
              <Delete fontSize="small" />
            </DeleteDatasetButton>
          </Tooltip>
        </TopButtons>
      )}

      {isTabularDataset(datasetInfo) && (
        // <TabularDatasetPreviewWithAnnotations
        //   dataset={datasetInfo}
        //   datasetPreview={datasetPreview}
        //   doFetchDatasetPreview={doFetchDatasetPreview}
        //   isEditable={isDatasetEditable}
        // />
        <TabularDatasetPreview
          columns={datasetInfo?.config?.featureTypeDescriptors?.map(e => e.key)}
          datasetPreview={datasetPreview}
          setDatasetPreview={setDatasetPreview}
        />
      )}
      {isImageDataset(datasetInfo) && (
        <ImageDatasetPreview
          datasetPreview={datasetPreview}
          imageField={getImageAndLabelField(datasetInfo).imageField}
          labelFields={getImageAndLabelField(datasetInfo).labelFields}
        />
      )}
      <DeleteDatasetConfirmModal
        isOpen={isDeletionConfirmModalOpen}
        onClose={() => setIsDeletionConfirmModalOpen(false)}
        isDisabled={isDeleting}
        onClickYes={async () => {
          setIsDeleting(true);
          const { error } = await deleteDataset(datasetId);
          if (error) {
            setError(error);
            setIsDeleting(false);
            setIsDeletionConfirmModalOpen(false);
            return;
          }
          window.location = "/data";
        }}
      />
    </Container>
  );
};

export default DatasetPreview;
