import styled from "styled-components";
import { useEffect, useState } from "react";
import Button from "components/ui/Button";
import { Gap } from "components/Layout";

import { books } from "components/views/application/BookSearch/books-data";
import { sleep } from "utils/common";
import BookResultsView from "components/views/BookResultsView";
import { postSolutionQuery, postSolutionSession } from "api/services/projectService";
import ImageMaskingInput from "components/widgets/ImageMaskingInput";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import EmbeddingGridView from "components/widgets/EmbeddingGridView";

const sampleBook = async () => {
  await sleep(500);
  const randomBook = books[Math.floor(Math.random() * books.length)];
  return { data: randomBook };
};

const Input = styled.textarea`
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-family: "Montserrat";
  width: 100%;
  background-color: transparent;
  border-bottom: none;
  color: ${props => props.theme.color.closest};
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 10px 10px 10px 35px;
  border-radius: 10px;
  height: 100px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
`;

const searchIconSrc =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAWCAMAAAAcqPc3AAABNVBMVEUAAAABkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8Bkf8hMXPYAAAAZnRSTlMASJXL5vLjwokuKrD8tY+DkrrwlhI43OgomvjDGd/RNVnqwBG3MPle/n+oJqVCfdSt16vrvO25xNqnz67dLfqFa26X9SzZFzqh7L029uVdWHtmSs6QcWWBm7T3aeBfb0yApv2EiqAJErTuAAAA40lEQVR42lyJA5oDQRQGe2ysN7Ztc2KfIve/QdAY1Yf6Xz0AoWiG5XhBlIAbWVFZTTfMj0/u69vJP79/Bjr/A8FQmGQpEo0BQjyRTOGZZjPQ+JHV0cjlC8BNMVGCLrPAQyVfha7VgZdGE6pl+npbgOrovt7tQfUHvj5UoEZjb55MZ9Dz+8LTl9YKDWa9ceUtt8OL2h+cx/F0to9Lp3HF85awgs8FwhUFBacxh4SGhUdYaES6W0dFI0wNjVRMS9OIsYxlYFA2sotDdodovDaEoWxpl8CAFSQmJWOXkExKwS6RagEAxDUcSj0xwvQAAAAASUVORK5CYII=";
const SearchIcon = styled.img`
  position: absolute;
  top: 11px;
  left: 10px;
  width: 16px;
`;

const ImageInputPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SearchInputContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const QueryInputsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  gap: 20px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const TextSearchBarContainer = styled.div`
  position: relative;
  grid-column: span 2;
`;

const SearchButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SampleBookCoverFromDataset = styled(Button)`
  min-height: 0;
  min-width: 0;
  margin: 0;
`;

const SearchButton = styled(Button)`
  min-height: 0;
  min-width: 0;
  margin: 0;
`;

const Container = styled.div``;

const DescriptionText = styled.div`
  margin-top: 10px;
  width: 400px;
  min-height: 100px;
  border-radius: 5px;
  height: 250px;
  overflow-y: auto;
  ${props => props.isHidden && `background-color: ${props.theme.color.closer0};`}
`;

const EyeContainer = styled.div`
  cursor: pointer;
  min-height: 24px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const QueryInputAndEmbeddingContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const EmbeddingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DescriptionInput = ({ description, isDescriptionHidden, setIsDescriptionHidden }) => {
  if (!description) {
    return (
      <DescriptionContainer>
        <EyeContainer />
        <DescriptionText isHidden />
      </DescriptionContainer>
    );
  }

  return (
    <DescriptionContainer>
      <EyeContainer onClick={() => setIsDescriptionHidden(!isDescriptionHidden)}>
        {isDescriptionHidden ? <VisibilityOff /> : <Visibility />}
      </EyeContainer>
      <DescriptionText isHidden={isDescriptionHidden}>{!isDescriptionHidden && description}</DescriptionText>
    </DescriptionContainer>
  );
};

const DeploymentReviewView = () => {
  const [query, setQuery] = useState("");
  const [sampledBase64Img, setSampledBase64Img] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [booksResult, setBooksResult] = useState([]);
  const [inputDescription, setInputDescription] = useState("");
  const [isDescriptionHidden, setIsDescriptionHidden] = useState(false);
  const [error, setError] = useState(null);
  const [embeddingVec, setEmbeddingVec] = useState(null);
  const [sessionId, setSessionId] = useState(null);

  const [inputImageTensor, setInputImageTensor] = useState(null);
  const [inputMaskTensor, setInputMaskTensor] = useState(null);

  useEffect(() => {
    doPopulateSessionId();
  }, []);

  const doPopulateSessionId = async () => {
    const { data, error } = await postSolutionSession("book_search");
    setSessionId(data?.id);
    setError(error);
  };

  const doPopulateSampleBook = async () => {
    setIsLoading(true);
    const { data } = await sampleBook();
    setSampledBase64Img(data.image);
    setInputDescription(data.tags.description);
    setBooksResult([]);
    setEmbeddingVec(null);
    setIsLoading(false);
  };

  const doPopulateBooksResult = async () => {
    setIsLoading(true);
    const { data, error } = await postSolutionQuery(sessionId, null, {
      query: query
        ?.replaceAll("\n", " ")
        .split(" ")
        .filter(word => word.length > 0),
      text: isDescriptionHidden ? "" : inputDescription.split(" "),
      cover_image: inputImageTensor,
      "cover_image-mask": inputMaskTensor,
      number: 10,
    });
    setError(error);
    setBooksResult(data?.result?.results || []);
    setEmbeddingVec(data?.result?.embedding);
    setIsLoading(false);
  };

  return (
    <Container>
      <Gap />
      <SearchInputContainer isDisabled={isLoading}>
        <SearchButtonContainer>
          <SampleBookCoverFromDataset value="Sample book from dataset" onClick={doPopulateSampleBook} />
        </SearchButtonContainer>
        <QueryInputAndEmbeddingContainer>
          <QueryInputsContainer>
            <ImageInputPanel>
              <ImageMaskingInput
                base64Img={sampledBase64Img}
                onSetInputImageTensor={newTensor => setInputImageTensor(newTensor)}
                onSetInputMaskTensor={newTensor => setInputMaskTensor(newTensor)}
              />
            </ImageInputPanel>
            <DescriptionInput
              isDescriptionHidden={isDescriptionHidden}
              setIsDescriptionHidden={setIsDescriptionHidden}
              description={inputDescription}
            />
            <TextSearchBarContainer>
              <SearchIcon src={searchIconSrc} />
              <Input
                type="text"
                placeholder="Title, author, partial description..."
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </TextSearchBarContainer>
          </QueryInputsContainer>
        </QueryInputAndEmbeddingContainer>
        <SearchButtonContainer>
          <SearchButton value="Search" onClick={doPopulateBooksResult} />
        </SearchButtonContainer>
      </SearchInputContainer>
      <Gap height="40px" />
      {error && JSON.stringify(error)}
      {/* {embeddingVec && (
        <EmbeddingContainer>
          <EmbeddingGridView
            title={"Query's Embedding"}
            embeddingVec={embeddingVec}
            numSquaresRow={40}
            squareSize={10}
          />
        </EmbeddingContainer>
      )} */}
      <BookResultsView books={booksResult} showEmbeddings={true} />
    </Container>
  );
};

export default DeploymentReviewView;
