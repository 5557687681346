import { useEffect } from "react";
import { getLoggedInUserName } from "api/services/authenticationService";
import { useNavigate } from "react-router-dom"

const HomePage = () => {
  const navigate = useNavigate();
  const userName = getLoggedInUserName();

  useEffect(() => {
    userName
      ?
      navigate(`/${userName}`)
      :
      navigate("/login")
  }, []);

  return (<></>)
};

export default HomePage;
