import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Help } from "@material-ui/icons";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import DataListView from "components/views/DataListView";
import { getLoggedInUserName } from "api/services/authenticationService";
import {
  doStartAddDatasetTutorial,
  doStartBootstrapCubeFromDataset,
  doCreateDatasetFromGoogleDriveTutorial,
  doCreateDatasetFromDataPlatformTutorial,
} from "components/widgets/TutorialOverlay";
import ItemListPopover from "components/ui/ItemListPopover";
import ButtonCreateNewAsset from "components/ui/ButtonCreateNewAsset";

const TopButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  gap: 10px;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const TutorialOption = styled.div`
  width: 250px;
  cursor: pointer;
  display: flex;
  padding: 10px;
  ${props => !props.noBorder && `border-bottom: 1px solid ${props.theme.color.closer1}`};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const ClickCreateMsg = styled.div`
  font-weight: 600;
  color: ${props => props.theme.color.feature};
  align-self: center;
  font-size: 22px;
`;

const DataListPage = () => {
  const userName = getLoggedInUserName();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const msgText =
    searchParams?.get("areCreateCubesBtnsHighlighted") === "true" ? "Choose dataset to create cube from:" : "";

  return (
    <CenteredWithTopNavLayout>
      {userName ? (
        <>
          <TopButtons>
            <ClickCreateMsg>{msgText}</ClickCreateMsg>
            <ButtonCreateNewAsset onClick={() => navigate("/data/create")} dataTutorialId="new-dataset-button" />
            <ItemListPopover iconComponent={<Help fontSize="large" />}>
              <TutorialOption onClick={doStartAddDatasetTutorial}>
                How to create a dataset by uploading a file?
              </TutorialOption>
              <TutorialOption onClick={doCreateDatasetFromGoogleDriveTutorial}>
                How to create a dataset from Google Drive?
              </TutorialOption>
              <TutorialOption onClick={doCreateDatasetFromDataPlatformTutorial}>
                How to create a dataset from Hubspot, or another data platform?
              </TutorialOption>
              <TutorialOption noBorder onClick={doStartBootstrapCubeFromDataset}>
                How to bootstrap a cube from dataset?
              </TutorialOption>
            </ItemListPopover>
          </TopButtons>
          <Gap />
          <DataListView />
        </>
      ) : (
        <>Please log in first to see the content. </>
      )}
    </CenteredWithTopNavLayout>
  );
};

export default DataListPage;
