import { useEffect, useState } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import { ArrowDownward } from "@material-ui/icons";

import { registerRecommendedModels } from "api/services/modelService";
import { createCubeAndSubmitPipeline } from "api/services/projectService";
import { TwoColumnLayout } from "components/Layout";
import DatasetListSelect from "components/views/CreatePipelineView/DatasetListSelect";
import ModelListSelect from "components/views/CreatePipelineView/ModelListSelect";
import DataInspectionModal from "components/widgets/DataInspectionModal";
import ModelInspectionModal from "components/views/CreatePipelineView/ModelInpsectionModal";
import Button from "components/ui/Button";

const SelectionCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  background-color: ${props => props.theme.color.closer0};
  color: ${props => props.theme.color.closest};
  text-align: center;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.color.closer1};
  height: 150px;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-overflow: ellipsis;
`;
const OptionButton = styled.div`
  opacity: ${props => (props.isDisabled ? 0.5 : 1)};
  pointer-events: ${props => (props.isDisabled ? "none" : "auto")};
  border: 1px solid ${props => props.theme.color.primary};
  background-color: ${props => props.theme.color.furthest};
  border-radius: 14px;
  padding: 10px;
  cursor: pointer;

  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const ArrowIconContainer = styled.div`
  padding-top: 25px;
  height: 150px;
  text-align: center;
  color: ${props => props.theme.color.primary};
`;

const StartButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  height: 108px;
`;

const Container = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"};
`;

const CreatePipelineView = ({ cubeName, isPublic }) => {
  const [models, setModels] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [showDataListSelect, setShowDataListSelect] = useState(false);
  const [showModelListSelect, setShowModelListSelect] = useState(false);
  const [showModelInpsectionModal, setShowModelInpsectionModal] = useState(false);
  const [showDataInpsectionModal, setShowDataInpsectionModal] = useState(false);

  const [isCubeBeingCreated, setIsCubeBeingCreated] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    selectedDataset && doFetchRecommendModels();
  }, [selectedDataset]);

  const doFetchRecommendModels = async () => {
    const { data, error } = await registerRecommendedModels(selectedDataset.id);
    if (error) {
      setError(error);
      return;
    }
    setModels(data);
  };

  const doCreateCubeAndAddPipeline = async () => {
    setIsCubeBeingCreated(true);
    const isRL = selectedDataset.name === "go";
    const { data: cube, error } = await createCubeAndSubmitPipeline(
      cubeName,
      isPublic,
      isRL,
      selectedDataset.id,
      selectedModel.id
    );

    if (error) {
      setError(error);
      setIsCubeBeingCreated(false);
      return;
    }
    window.location = `/cube/${cube.id}`;
  };

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <Container isDisabled={isCubeBeingCreated}>
      <TwoColumnLayout>
        <div>
          <SelectionCard>
            <Title>{selectedDataset ? selectedDataset?.name : "Select dataset or engine"}</Title>
            <OptionButton onClick={() => setShowDataInpsectionModal(true)} isDisabled={!selectedDataset}>
              View
            </OptionButton>
            <OptionButton
              onClick={() => {
                setShowDataListSelect(true);
                setShowModelListSelect(false);
              }}
              isDisabled={showDataListSelect}
              data-tutorial-id="select-dataset-button"
            >
              Select
            </OptionButton>
          </SelectionCard>

          {selectedDataset && (
            <>
              <ArrowIconContainer>
                <ArrowDownward style={{ fontSize: 100 }} />
              </ArrowIconContainer>
              <SelectionCard>
                <Title>
                  {selectedModel
                    ? `${selectedModel?.name.slice(0, 4)}...${selectedModel?.name.slice(-18)}`
                    : "No Model"}
                </Title>
                <OptionButton isDisabled={!selectedModel} onClick={() => setShowModelInpsectionModal(true)}>
                  View
                </OptionButton>
                <OptionButton
                  isDisabled={!selectedDataset}
                  onClick={() => {
                    setShowModelListSelect(true);
                    setShowDataListSelect(false);
                  }}
                  data-tutorial-id="select-model-button"
                >
                  Select
                </OptionButton>
              </SelectionCard>
            </>
          )}
        </div>
        <div>
          {showDataListSelect && (
            <DatasetListSelect
              selectedDatasetId={selectedDataset?.id}
              onDatasetSelect={newDataset => setSelectedDataset(newDataset)}
            />
          )}
          {showModelListSelect && models && (
            <ModelListSelect
              models={models}
              selectedModel={selectedModel}
              onModelSelect={model => setSelectedModel(model)}
              datasetFeatureTypeDescriptors={selectedDataset?.config?.featureTypeDescriptors}
            />
          )}
        </div>
        <DataInspectionModal
          datasetId={selectedDataset?.id}
          open={showDataInpsectionModal}
          onClose={() => setShowDataInpsectionModal(false)}
        />
        <ModelInspectionModal
          modelName={selectedModel?.name}
          modelId={selectedModel?.id}
          open={showModelInpsectionModal}
          onClose={() => setShowModelInpsectionModal(false)}
          datasetFeatureTypeDescriptors={selectedDataset?.config?.featureTypeDescriptors}
        />
      </TwoColumnLayout>
      {selectedDataset && selectedModel && (
        <StartButtonContainer>
          {isCubeBeingCreated ? (
            <CircularProgress />
          ) : (
            <Button dataTutorialId="start-pipeline-button" value="START" onClick={doCreateCubeAndAddPipeline} />
          )}
        </StartButtonContainer>
      )}
    </Container>
  );
};

export default CreatePipelineView;
