import { useState, useEffect } from "react";

import { getPipelineEnrichedOutputsByIds, patchPipelineConfig } from "api/services/projectService";
import { registerRecommendedModels } from "api/services/modelService";
import { isEmpty } from "lodash";

export const usePipelineOutputsByIds = pipelineIds => {
  const [pipelineOutputs, setPipelineOutputs] = useState([]);
  const [error, setError] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const doFetchPipelineOutputs = async () => {
    const { data, error } = await getPipelineEnrichedOutputsByIds(pipelineIds);
    setError(error);
    setPipelineOutputs(data);
  };

  const doStartPolling = () => {
    clearInterval(intervalId);
    doFetchPipelineOutputs();
    const fetchingIntervalId = setInterval(doFetchPipelineOutputs, 2000);
    setIntervalId(fetchingIntervalId);
  };

  useEffect(() => {
    doStartPolling();
    return () => clearInterval(intervalId);
  }, [pipelineIds]);

  useEffect(() => {
    // check pipeline outputs for finished data processing jobs
    // patch them with datasetId + modelId if needed
    pipelineOutputs.forEach(async pipelineOutput => {
      const { dataset, status, dataProcessingJob } = pipelineOutput;

      if (status === "DRAFT" && !dataset && dataProcessingJob && dataProcessingJob.status === "DONE") {
        const datasetId = pipelineOutput.dataProcessingJob.outputDatasetId;

        const { data: models, error: recModelsError } = await registerRecommendedModels(datasetId);
        if (recModelsError) {
          setError(recModelsError);
          return;
        }

        const pipelineConfig = {
          datasetId,
          cubeId: pipelineOutput.cubeId,
          modelId: models?.[0]?.id,
          status: "DRAFT",
        };

        const { error: patchPipelineErr } = await patchPipelineConfig(pipelineOutput.id, pipelineConfig);
        setError(patchPipelineErr);
      }
    });
  }, [pipelineOutputs]);

  useEffect(() => {
    if (isEmpty(pipelineOutputs)) {
      return;
    }
    const areAllPipelinesDoneOrAborted = pipelineOutputs.every(pipelineOutput => {
      const { dataset, trainingBundle, evaluationJob, trainingJob, status, dataProcessingJob } = pipelineOutput;

      if (status === "DRAFT" && dataset) {
        return true;
      }

      if (
        status === "DRAFT" &&
        !dataset &&
        dataProcessingJob &&
        (dataProcessingJob.status === "DONE_FAILED" ||
          dataProcessingJob.status === "ABORT" ||
          dataProcessingJob.status === "KILLED")
      ) {
        return true;
      }

      if (
        status === "SUBMITTED" &&
        trainingJob &&
        (trainingJob.status === "DONE_FAILED" || trainingJob.status === "ABORT" || trainingJob.status === "KILLED")
      ) {
        return true;
      }

      if (status === "SUBMITTED" && trainingBundle) {
        return trainingBundle.trainingJobs.every(
          job =>
            job.status === "DONE" || job.status === "DONE_FAILED" || job.status === "ABORT" || job.status === "KILLED"
        );
      }

      if (status === "SUBMITTED") {
        return (
          evaluationJob?.status === "DONE" ||
          evaluationJob?.status === "DONE_FAILED" ||
          evaluationJob?.status === "ABORT" ||
          !evaluationJob
        );
      }

      return false;
    });

    if (areAllPipelinesDoneOrAborted) {
      clearInterval(intervalId);
    }
  }, [pipelineOutputs]);

  return [pipelineOutputs, doStartPolling, error];
};
