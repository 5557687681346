import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isEqual, isEmpty } from "lodash";
import { Dashboard, Help } from "@material-ui/icons";

import * as projectService from "api/services/projectService";
import NavWithTabsExtended from "components/ui/NavWithTabsExtended";
import { BigTitle } from "components/ui/Text";
import CubeVersionInfo from "components/widgets/CubeVersionInfo";
import DatasetPreview from "components/views/DatasetPreview";
import { usePipelineOutputsByIds } from "api/services/projectService/usePipelineOutputsByIds";
import { getLoggedInUserName } from "api/services/authenticationService";
import MultiDeplymentTabView from "components/views/MultiDeploymentTabView";
import MultiNotebookTabView from "components/views/MultiNotebookTabView";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import CubeFileListView from "components/views/CubeView/CubeFileListView";
import DatasetTopBar from "components/widgets/TopBars/DatasetTopBar";
import CubeSettingsView from "components/views/CubeSettingsView";
import displayPipelineStatusUntilContentReady from "components/widgets/PipelineStatus/displayPipelineStatusUntilContentReady";
import DatasetPreviewWithEditing from "components/views/DatasetPreviewWithEditing";
import GithubLinkForCube from "components/ui/GithubLinkForCube";
import ItemListPopover from "components/ui/ItemListPopover";
import {
  doStartDropDatasetColumnTutorial,
  doStartChangeDatasetColumnTypeTutorial,
  doSwitchToAutotuneModeTutorial,
  doSwitchToCustomTrainingModeTutorial,
} from "components/widgets/TutorialOverlay";
import CubeAboutView from "../CubeAboutView";
import EngagementBadges from "components/widgets/EngagementBadges";
import { BigTitleEditable } from "components/ui/Text";
import { patchCube } from "api/services/projectService";
import ModelTabView from "components/views/ModelTabView";
import useSearchParamsState from "hooks/useSearchParamsState";
import DashboardTabView from "components/views/DasboardTabView";
import ModelTabViewAnimated from "components/views/ModelTabViewAnimated";
import DashboardTabViewConfigurable from "components/views/DashboardViewConfigurable";

const ViewUnderTab = styled.div`
  ${props => props.isPositionRelative && "position: relative"};
  margin-top: 30px;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3"};
`;

const TitleAndSwitch = styled.div`
  padding: 20px 0;
  display: grid;
  align-items: center;
  grid-template-columns: max-content 1fr max-content;
  gap: 10px;
`;

const CubeTitle = styled(BigTitle)`
  display: flex;
  padding: 0;
`;

const SwitchAndHelpButton = styled.div`
  display: flex;
  gap: 10px;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const CornerRect = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const TutorialOption = styled.div`
  width: 250px;
  cursor: pointer;
  display: flex;
  padding: 10px;
  ${props => !props.noBorder && `border-bottom: 1px solid ${props.theme.color.closer1}`};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const isDataProcessConfigValid = dataProcessConfig => {
  if (!dataProcessConfig?.config?.ops?.length) {
    return false;
  }

  const isEveryJoinOpValid = dataProcessConfig?.config?.ops
    .filter(op => op.name === "Join")
    .every(joinOp => joinOp.config.dataset && joinOp.config.group?.[0] !== null);

  const isThereOnlyJoinOps = dataProcessConfig?.config?.ops.filter(op => op.name !== "Join")?.length === 0;
  if (!isEveryJoinOpValid && isThereOnlyJoinOps) {
    return false;
  }

  return true;
};

const areThereTwoIdColumnsToJoinBy = dataProcessConfig => {
  const ops = dataProcessConfig?.config?.ops;
  if (!ops.find(op => op.name === "Join")) {
    return true;
  }
  return (
    ops?.find(op => op.name === "SetIndex") &&
    ops.filter(op => op.name === "Join").every(joinOp => joinOp.config.dataset && joinOp.config.group?.[0] !== null)
  );
};

const useHasUserEditedPipeline = (
  pipelineOutput,
  editedModel,
  editedTrainingConfig,
  editedTrainingSearchValues,
  editedCustomCode,
  editedDataProcessConfig,
  isAutotuningMode,
  isCustomizedTrainingMode
) => {
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    if (!pipelineOutput) {
      return;
    }

    const hasSwitchedCustomizedTrainingMode = isCustomizedTrainingMode === isEmpty(pipelineOutput?.customCode);
    const hasSwitchedAutoTuneMode = isAutotuningMode === isEmpty(pipelineOutput?.trainingBundle);

    const hasRegularPipelineConfigBeenModified =
      !isEqual(editedModel, pipelineOutput.model) ||
      isDataProcessConfigValid(editedDataProcessConfig) ||
      !isEqual(editedTrainingConfig, pipelineOutput.trainingConfig);

    const hasAutoTunePiplineConfigBeenModified =
      isAutotuningMode &&
      !isEqual(editedTrainingSearchValues, pipelineOutput?.trainingSearchValues) &&
      !isEqual(editedTrainingSearchValues, pipelineOutput?.trainingBundle?.searchValues);

    const hasCustomizedTrainingBeenModified =
      isCustomizedTrainingMode && !isEqual(editedCustomCode, pipelineOutput?.customCode);

    const hasPipelineConfigBeenModified =
      hasSwitchedCustomizedTrainingMode ||
      hasSwitchedAutoTuneMode ||
      hasRegularPipelineConfigBeenModified ||
      hasAutoTunePiplineConfigBeenModified ||
      hasCustomizedTrainingBeenModified;

    setHasEdited(hasPipelineConfigBeenModified);
  }, [
    editedModel,
    editedTrainingConfig,
    pipelineOutput,
    editedTrainingSearchValues,
    editedCustomCode,
    editedDataProcessConfig,
    isAutotuningMode,
    isCustomizedTrainingMode,
  ]);
  return hasEdited;
};

const CubeView = ({ cube }) => {
  const initialSearchValues = {
    "train.optimizer.learning_rate_init": [0.005],
    batch_size: [100],
    "train.trn_weight": [10],
    "infer.lkh_weight": [10],
    "train.max_iter": [200],
  };

  const [selectedPipelineIds, setSelectedPipelineIds] = useState([]);
  const [isEditMode, setIsEditMode] = useSearchParamsState({ paramName: "isEditMode", initialValue: false });
  const [editedModel, setEditedModel] = useState(null);
  const [editedDataProcessConfig, setEditedDataProcessConfig] = useState(null);
  const [editedTrainingConfig, setEditedTrainingConfig] = useState(null);
  const [editedCustomCode, setEditedCustomCode] = useState(null);
  const [editedTrainingSearchValues, setEditedTrainingSearchValues] = useState(initialSearchValues);
  const [isShowingFiles, setIsShowingFiles] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") || "About");
  const [pipelineOutputs, doRestartPolling, pipelinesFetchErr] = usePipelineOutputsByIds(selectedPipelineIds);
  const [isAutotuningMode, setIsAutotuningMode] = useState(pipelineOutputs?.[0]?.trainingBundle);
  const [isCustomizedTrainingMode, setIsCustomizedTrainingMode] = useState(!!pipelineOutputs?.[0]?.customCode);
  const [isSavingPipeline, setIsSavingPipeline] = useState(false);
  const [modelView, setModelView] = useState("Config"); // graph, config
  const [isShowingTrainingPlot, setIsShowingTrainingPlot] = useState(true);
  const [cubeName, setCubeName] = useState(cube?.name);

  const [pipelineConfig, setPipelineConfig] = useState({});

  const navigate = useNavigate();

  const hasUserEditedPipeline = useHasUserEditedPipeline(
    pipelineOutputs[0],
    editedModel,
    editedTrainingConfig,
    editedTrainingSearchValues,
    editedCustomCode,
    editedDataProcessConfig,
    isAutotuningMode,
    isCustomizedTrainingMode
  );

  useEffect(() => {
    doPopulatePipelineConfig();
  }, [selectedPipelineIds?.[0], selectedTab]);

  const doPopulatePipelineConfig = async () => {
    if (!selectedPipelineIds?.[0]) {
      return;
    }

    const { data } = await projectService.getPipelineConfigById(selectedPipelineIds?.[0]);
    setPipelineConfig(data);

    if (!data?.modelConfig?.architecture) {
      navigate("?tab=Model");
    }
  };

  useEffect(() => {
    setSelectedTab(searchParams.get("tab") || "About");
    if (searchParams.get("should_extend_data")) {
      setIsEditMode(searchParams.get("should_extend_data") === "true");
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedPipelineIds.length === 1) {
      setEditedTrainingConfig(null);
      setEditedModel(null);
    }
  }, [selectedPipelineIds]);

  useEffect(() => {
    setIsCustomizedTrainingMode(!!pipelineOutputs?.[0]?.customCode);
  }, [pipelineOutputs[0]?.customCode]);

  useEffect(() => {
    if (pipelineOutputs.length === 1) {
      setEditedTrainingConfig(editedTrainingConfig || pipelineConfig?.modelConfig?.trainingConfig);
      setEditedModel(editedModel || pipelineConfig?.modelConfig);
    }

    // auto-tune pipeline comparison is not supported, so pipelineOutputs.lenght is always 1
    if (pipelineOutputs?.[0]?.trainingBundle || pipelineOutputs?.[0]?.trainingSearchValues) {
      pipelineOutputs?.[0]?.trainingBundle
        ? setEditedTrainingSearchValues(pipelineOutputs[0].trainingBundle?.searchValues)
        : setEditedTrainingSearchValues(pipelineOutputs[0].trainingSearchValues);
    }

    // customerized training pipeline comparison is not supported, so pipelineOutputs.lenght is always 1
    if (pipelineConfig?.modelConfig?.trainingScript) {
      setEditedCustomCode(pipelineConfig?.modelConfig?.trainingScript);
    }
  }, [pipelineOutputs, pipelineConfig]);

  useEffect(() => {
    isEditMode && setIsAutotuningMode(!!pipelineOutputs?.[0]?.trainingBundle);
  }, [isEditMode]);

  const doSavePipelineDraft = async () => {
    setIsSavingPipeline(true);

    if (isDataProcessConfigValid(editedDataProcessConfig)) {
      if (!areThereTwoIdColumnsToJoinBy(editedDataProcessConfig)) {
        alert("Please assign ID columns to join by, in both datasets");
        setIsSavingPipeline(false);
        return;
      }

      const { error } = await projectService.launchNewDataJobAndSavePipelineDraft(cube.id, editedDataProcessConfig);
      if (error) {
        alert("Failed when saving a new dataset version: " + JSON.stringify(error));
        return;
      }
      window.location = `/cube/${cube.id}`;
      return;
    }

    if (!pipelineOutputs[0]?.dataset?.id && !pipelineOutputs[0].dataProcessingJobId) {
      alert("Cannot submit pipeline since the dataset is unavailable.");
      return;
    }

    if (pipelineConfig.status === "DRAFT") {
      const { error } = await projectService.patchPipelineConfig(pipelineConfig?.id, {
        ...pipelineConfig,
        modelConfig: {
          ...editedModel,
          trainingConfig: editedTrainingConfig,
          trainingScript: editedCustomCode,
        },
      });

      if (error) {
        alert("Could not save the draft pipeline: ", JSON.stringify(error));
        return;
      }

      window.location = `/cube/${cube.id}`;
      return;
    }

    const { error } = await projectService.postPipelineConfig({
      ...pipelineConfig,
      status: "DRAFT",
      modelConfig: {
        ...editedModel,
        trainingConfig: editedTrainingConfig,
        trainingScript: editedCustomCode,
      },
    });
    if (error) {
      alert("Could not create a new pipeline draft: ", JSON.stringify(error));
      return;
    }
    window.location = `/cube/${cube.id}`;
    return;
  };

  const doExecutePipeline = async () => {
    setIsSavingPipeline(true);
    if (!pipelineOutputs[0]?.dataset?.id) {
      alert("Cannot submit pipeline since there is no dataset.");
      return;
    }
    const pipelineId = pipelineOutputs[0].pipelineId;

    if (pipelineOutputs[0].status === "DRAFT") {
      const { error } = await projectService.submitDraftPipelineConfig(cube.id, pipelineId, pipelineConfig);
      if (error) {
        alert("Could not execute pipeline: " + JSON.stringify(error));
        return;
      }
    } else {
      const { error } = await projectService.submitPipelineConfig(cube.id, pipelineConfig);
      if (error) {
        alert("Could not execute pipeline: " + JSON.stringify(error));
        return;
      }
    }

    document.location.reload();
    // window.location = `/cube/${cube.id}?tab=Model`;
  };

  // ui logics
  const selectTab = tabName => {
    setSearchParams({ tab: tabName });
    setSelectedTab(tabName);
  };

  const doesCubeBelongToLoggedInUser = cube?.createdBy === getLoggedInUserName();
  const isAutotunePipeline = pipelineOutputs[0]?.trainingBundle;

  const pipelineModelId = pipelineOutputs?.[0]?.model?.id;

  const selectedPipelineOutput = pipelineOutputs?.find(p => p.pipelineId === selectedPipelineIds[0]);

  const isDataJobInProgress =
    pipelineOutputs[0]?.dataProcessingJob?.status === "REGISTERED" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "IN_PROGRESS" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "ABORT" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "KILLED" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "DONE_FAILED";

  if (pipelinesFetchErr) {
    return "Pipeline fetch error: " + JSON.stringify(pipelinesFetchErr);
  }

  return (
    <>
      <TitleAndSwitch>
        <CubeTitle>
          {cube.createdBy}/
          <BigTitleEditable
            value={cubeName}
            onNewValue={newName => setCubeName(newName)}
            valueToResetOnCancel={cube?.name}
            hasBeenEdited={cubeName !== cube.name}
            onClickSave={async () => {
              const { data, error } = await patchCube(cube.id, { name: cubeName });
              if (error) {
                setCubeName(cube.name);
                return;
              }
              setCubeName(data.name);
            }}
          />
        </CubeTitle>
        <GithubLinkForCube cubeId={cube.id} />
        <EngagementBadges
          cubeId={cube?.id}
          datasetId={pipelineOutputs[0]?.dataset?.id}
          modelId={pipelineOutputs[0]?.model?.id}
        />
      </TitleAndSwitch>

      <CubeVersionInfo
        cube={cube}
        selectedPipelineIds={selectedPipelineIds}
        onPipelineIdsSelect={ids => setSelectedPipelineIds(ids)}
      />
      {isShowingFiles && <CubeFileListView cubeId={cube?.id} pipelineId={pipelineOutputs[0]?.pipelineId} />}
      {pipelineOutputs[0] && !isShowingFiles && (
        <>
          <NavWithTabsExtended
            tabNames={
              isCustomizedTrainingMode
                ? ["About", "Dataset", "Model", "API", "Dashboard", "Settings"]
                : isAutotuningMode
                ? ["About", "Dataset", "Model", "API", "Settings"]
                : ["About", "Dataset", "Model", "API", "Dashboard", "Settings"]
            }
            disabledTabs={doesCubeBelongToLoggedInUser ? [] : ["Settings"]}
            selectedTabName={selectedTab}
            onTabSelect={newTab => selectTab(newTab)}
          />
          <ViewUnderTab
            isPositionRelative={selectedTab !== "Model"}
            isDisabled={isSavingPipeline}
            allowExpansion={selectedTab === "API"}
            areScrollbarsDisabled={selectedTab === "Model"}
          >
            {selectedTab === "About" && (
              <CubeAboutView
                inputName={selectedPipelineOutput?.model?.inputs?.[0]?.name}
                cube={cube}
                modelId={selectedPipelineOutput?.model?.id}
              />
            )}
            {selectedTab === "Settings" && <CubeSettingsView cubeId={cube.id} sharedWith={cube.sharedWith} />}

            {selectedTab === "Dataset" && !isEditMode && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <DatasetTopBar
                    isEditMode={isEditMode}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                {displayPipelineStatusUntilContentReady({
                  selectedTab,
                  pipelineOutputs,
                  pipelineStatus: pipelineConfig?.status,
                }) || <DatasetPreview datasetId={pipelineOutputs[0]?.dataset?.id} dataProcessingConfig={null} />}
              </>
            )}

            {selectedTab === "Dataset" && isEditMode && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <DatasetTopBar
                    isEditMode={isEditMode}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickCancel={() => {
                      setIsEditMode(false);
                      setEditedDataProcessConfig(null);
                    }}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                <DatasetPreviewWithEditing
                  datasetId={pipelineOutputs[0]?.dataset?.id}
                  operations={editedDataProcessConfig?.config?.ops}
                  onChangeOperations={newOps => {
                    const newDataProcessConfig = {
                      config: {
                        inputDatasetId: pipelineOutputs[0]?.dataset?.id,
                        name: "recommended-processing",
                        ops: newOps,
                      },
                    };
                    setEditedDataProcessConfig(newDataProcessConfig);
                  }}
                />
              </>
            )}
            {selectedTab === "Model" && (
              // <ModelTabViewAnimated pipelineConfig={pipelineConfig} pipelineOutput={pipelineOutputs?.[0]} />
              <ModelTabView
                hasUserEditedPipeline={hasUserEditedPipeline}
                isDataJobInProgress={isDataJobInProgress}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
                editedModel={editedModel}
                setEditedModel={setEditedModel}
                modelView={modelView}
                setModelView={setModelView}
                selectedPipelineIds={selectedPipelineIds}
                pipelineOutputs={pipelineOutputs}
                doesCubeBelongToLoggedInUser={doesCubeBelongToLoggedInUser}
                doSavePipelineDraft={doSavePipelineDraft}
                doExecutePipeline={doExecutePipeline}
                doRestartPolling={doRestartPolling}
                isCustomizedTrainingMode={isCustomizedTrainingMode}
                setIsCustomizedTrainingMode={setIsCustomizedTrainingMode}
                isShowingTrainingPlot={isShowingTrainingPlot}
                setIsShowingTrainingPlot={setIsShowingTrainingPlot}
                editedTrainingConfig={editedTrainingConfig}
                setEditedTrainingConfig={setEditedTrainingConfig}
                editedCustomCode={editedCustomCode}
                setEditedCustomCode={setEditedCustomCode}
                pipelineModelId={pipelineModelId}
                pipelineConfig={pipelineConfig}
                onNewPipelineConfig={newConfig => setPipelineConfig(newConfig)}
              />
            )}

            {selectedTab === "API" &&
              (displayPipelineStatusUntilContentReady({
                selectedTab,
                pipelineOutputs,
                doRestartPolling,
                pipelineStatus: pipelineConfig?.status,
              }) || (
                <MultiDeplymentTabView
                  pipelineOutputs={pipelineOutputs}
                  pipelineIdsToCompare={selectedPipelineIds}
                  cubeName={cube?.name}
                />
              ))}

            {selectedTab === "Notebook" &&
              (displayPipelineStatusUntilContentReady({
                selectedTab,
                pipelineOutputs,
                pipelineStatus: pipelineConfig?.status,
              }) || (
                <MultiNotebookTabView pipelineOutputs={pipelineOutputs} pipelineIdsToCompare={selectedPipelineIds} />
              ))}

            {selectedTab === "Dashboard" && <DashboardTabViewConfigurable cubeId={cube?.id} />}
            {/* {selectedTab === "Dashboard" && <DashboardTabView />} */}

            {selectedTab !== "About" && (
              <>
                <CornerRect style={{ top: "0", left: "0" }} />
                <CornerRect style={{ top: "0", right: "0" }} />
                <CornerRect style={{ bottom: "0", left: "0" }} />
                <CornerRect style={{ bottom: "0", right: "0" }} />
              </>
            )}
          </ViewUnderTab>
        </>
      )}
    </>
  );
};

export default CubeView;
