import styled from "styled-components";
import { useRef, useState } from "react";
import Button from "components/ui/Button";
import useClickOutside from "hooks/useClickOutside";
import { EditIcon } from "components/ui/Icons";

export const BigTitle = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  padding-top: 20px;
  font-size: 26px;
  font-weight: bold;
`;

export const SmallTitle = styled.div`
  font-size: 18px;
  padding: 10px 0;
  font-weight: bold;
`;

const BigTitleInput = styled.input`
  width: 100%;
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  padding: 0;
  font-size: 26px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  border: 1px solid ${props => props.theme.color.closer1};
  color: ${props => props.theme.color.closest};
  background-color: transparent;
  border-radius: 5px;
  height: 26px;
`;

const BigTitleForEditing = styled(BigTitle)`
  padding: 0;
`;

const EditableTitleContainer = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3;"}
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
`;

const SaveButton = styled(Button)`
  padding: 2px 0;
`;

const CancelButton = styled(SaveButton)``;

export const BigTitleEditable = ({ valueToResetOnCancel = "", value = "", onNewValue, hasBeenEdited, onClickSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const shouldShowButtons = !!onClickSave;

  const outerContainerRef = useRef(null);
  useClickOutside(outerContainerRef, () => setIsEditing(false));

  if (isEditing) {
    return (
      <EditableTitleContainer isDisabled={isLoading} ref={outerContainerRef}>
        <BigTitleInput value={value} onChange={e => onNewValue(e.target.value)} />
        {shouldShowButtons && (
          <>
            <SaveButton
              value="Save"
              onClick={async () => {
                setIsLoading(true);
                await onClickSave();
                setIsEditing(false);
                setIsLoading(false);
              }}
              isDisabled={!hasBeenEdited}
            />
            <CancelButton
              value="Cancel"
              onClick={() => {
                valueToResetOnCancel && onNewValue(valueToResetOnCancel);
                setIsEditing(false);
              }}
            />
          </>
        )}
      </EditableTitleContainer>
    );
  }
  return (
    <EditableTitleContainer onClick={() => setIsEditing(true)}>
      <BigTitleForEditing>{value}</BigTitleForEditing>
      <EditIcon height="14px" />
    </EditableTitleContainer>
  );
};
