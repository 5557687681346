import styled from "styled-components";

import Button from "components/ui/Button";
import TwoNameSwitch from "components/ui/TwoNameSwitch";

const SmallButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 2px 20px;
`;

const ColumnDetailsPanel = styled.div`
  display: flex;
  gap: 40px;
  align-items: center;
`;

const upsertOpAndGetNewOps = (existingOps, opName, columnName, newDataType = "") => {
  const existingOp = existingOps.find(op => op.name === opName);

  if (existingOp && opName === "SetType" && existingOp.config.groups.find(group => group.name === columnName)) {
    // modify existing group
    return existingOps.map(op => {
      if (op.name !== opName) {
        return op;
      }

      const newGroups = op.config.groups.map(group => {
        if (group.name !== columnName) {
          return group;
        }
        return {
          ...group,
          type: newDataType,
        };
      });
      return {
        ...op,
        config: {
          ...op.config,
          groups: newGroups,
        },
      };
    });
  }

  if (existingOp && opName === "SetType") {
    // add new group
    return existingOps.map(op => {
      if (op.name !== opName) {
        return op;
      }

      return {
        ...op,
        config: {
          ...op.config,
          groups: [...op.config.groups, { name: columnName, type: newDataType }],
        },
      };
    });
  }

  if (existingOp) {
    const modifiedOps = existingOps.map(op => {
      if (op.name === opName) {
        return {
          ...op,
          config: {
            ...op.config,
            groups: [...op.config.groups, columnName],
          },
        };
      }
      return op;
    });
    return modifiedOps;
  }

  if (opName === "SetType") {
    return [
      ...existingOps,
      {
        name: opName,
        config: {
          groups: [{ name: columnName, type: newDataType }],
        },
      },
    ];
  }

  return [
    ...existingOps,
    {
      name: opName,
      config: {
        groups: [columnName],
      },
      params: {},
    },
  ];
};

const removeOpForColumnAndGetNewOps = (existingOps, opName, columnName) => {
  const newNonJoinOps = existingOps.map(op => {
    if (op.name === opName) {
      return {
        ...op,
        config: {
          ...op.config,
          groups: op.config.groups.filter(group => group !== columnName),
        },
      };
    }
    return op;
  });

  return newNonJoinOps.filter(op => op.config.groups.length > 0);
};

const EditColumnDetails = ({ columnName, columnOriginalType, nonJoinOps, onSetNonJoinOps }) => {
  const existingSetIndexOp = nonJoinOps.find(op => op.name === "SetIndex");
  const isColumnId = existingSetIndexOp?.config?.groups?.includes(columnName);
  const isAnotherColumnId = !isColumnId && existingSetIndexOp;

  const isColumnDropped = nonJoinOps.find(op => op.name === "Drop")?.config?.groups?.includes(columnName);

  const columnDataType =
    nonJoinOps.find(op => op.name === "SetType")?.config?.groups?.find(group => group.name === columnName)?.type ||
    columnOriginalType;

  return (
    <ColumnDetailsPanel>
      <TwoNameSwitch
        leftName="not ID"
        rightName="ID"
        isOnLeft={!isColumnId}
        onSwitch={
          isColumnId
            ? () => onSetNonJoinOps(removeOpForColumnAndGetNewOps(nonJoinOps, "SetIndex", columnName))
            : () => onSetNonJoinOps(upsertOpAndGetNewOps(nonJoinOps, "SetIndex", columnName))
        }
        isDisabled={isAnotherColumnId}
      />
      <SmallButton
        dataTutorialId="drop-column-button"
        value={isColumnDropped ? "Restore" : "Drop"}
        onClick={
          isColumnDropped
            ? () => onSetNonJoinOps(removeOpForColumnAndGetNewOps(nonJoinOps, "Drop", columnName))
            : () => onSetNonJoinOps(upsertOpAndGetNewOps(nonJoinOps, "Drop", columnName))
        }
      />
      <div>
        type
        <select
          data-tutorial-id="change-type-select"
          value={columnDataType}
          onChange={e => onSetNonJoinOps(upsertOpAndGetNewOps(nonJoinOps, "SetType", columnName, e.target.value))}
        >
          <option>INTEGER</option>
          <option>FLOAT</option>
          <option>CATEGORICAL</option>
        </select>
      </div>
    </ColumnDetailsPanel>
  );
};

export default EditColumnDetails;
