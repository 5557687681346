import { AssignmentTurnedInOutlined } from "@material-ui/icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { fetchProposalsForDatapoint } from "api/services/projectService";
import { CenteredWithTopNavLayout, Gap, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import Button from "components/ui/Button";
import { BigTitle } from "components/ui/Text";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import { isEmpty } from "lodash";
import { useState } from "react";
import styled from "styled-components";
import data from "./csv.json";

const TableContainer = styled.div``;

const Th = styled.th`
  text-align: left;
  font-weight: bold;
  padding: 10px 10px;
  color: ${props => (props.isControl || props.isTarget) && props.theme.color.primary};
  font-size: ${props => props.isTarget && "1.5rem"};
  line-break: strict;
  word-spacing: 100vw;
  text-align: center;
`;

const Td = styled.td`
  color: ${props => (props.isNull ? props.theme.color.closer2 : props.theme.color.closest)};
  padding: 15px 5px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  border-top: 1px solid ${props => props.theme.color.closer1};
  text-align: center;
  background-color: ${props => generateColourFromScore(props.score)};
`;

const ButtonTd = styled.td`
  padding: 30px 0;
  text-align: center;
`;

const contextColumns = ["Age", "Sex"];
const controlColumns = [
  "Job",
  "Housing",
  "Saving_accounts",
  "Checking_account",
  "Credit_amount",
  "Duration",
  "Purpose",
];

const getRandomPoint = dataset => {
  const randomIndex = Math.floor(Math.random() * dataset.length);
  return dataset[randomIndex];
};

const GenButton = styled(Button)`
  min-width: 0;
  padding: 5px 10px;
  margin: 0;
`;

const generateColourFromScore = score => {
  if (!score) {
    return "transparent";
  }

  const opacity = Math.round(Math.abs(score) * 255).toString(16);

  if (score < 0) {
    return `#d66d6d${opacity}`;
  }
  return `#53a761${opacity}`;
};

const ValueAndArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

const Arrow = styled.div`
  transform: scaleY(
    ${props => {
      if (props.score < 0.5) {
        return 0.7;
      }
      return props.score * 2.5;
    }}
  );
`;

const ScoreArrow = ({ score }) => {
  if (score < 0) {
    return <Arrow score={score * -1}>&#x2B07;</Arrow>;
  }
  return <Arrow score={score}>&#x2B06;</Arrow>;
};

const TabularDatasetPreview = ({ datasetPreview }) => {
  const [sampledDatapoint, setSampledDatapoint] = useState(getRandomPoint(datasetPreview));
  const [isLoading, setIsLoading] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [error, setError] = useState(null);

  if (isEmpty(datasetPreview)) {
    return "The dataset is empty !";
  }

  if (error) {
    return JSON.stringify(error);
  }

  const pointWithMostColumns = datasetPreview.reduce((prevPoint, currentPoint) => {
    return Object.keys(currentPoint).length >= Object.keys(prevPoint).length ? currentPoint : prevPoint;
  });
  const columnNames = Object.keys(pointWithMostColumns);

  return (
    <TableContainer>
      <Button value="Sample data point" onClick={() => setSampledDatapoint(getRandomPoint(datasetPreview))} />
      <table>
        <thead>
          <tr>
            {!isEmpty(columnNames) &&
              columnNames.map(columnName => (
                <Th key={columnName} isControl={controlColumns.includes(columnName)} isTarget={columnName === "Risk"}>
                  {columnName.replaceAll("_", " ")}
                </Th>
              ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {columnNames.map((columnName, i) => (
              <Td key={i}>{sampledDatapoint[columnName] || "null"}</Td>
            ))}
          </tr>
          <tr>
            <ButtonTd colSpan={10}>
              <GenButton
                isDisabled={isLoading}
                value="Generate proposals"
                onClick={async () => {
                  setIsLoading(true);
                  const { data, error } = await fetchProposalsForDatapoint(sampledDatapoint);
                  setProposals(data);
                  setError(error);
                  setIsLoading(false);
                }}
              />
            </ButtonTd>
          </tr>
          {proposals.map((dataPoint, rowIndex) => (
            <tr key={rowIndex}>
              {columnNames.map((columnName, colIndex) => {
                const val = dataPoint[columnName]?.value;

                if (columnName === "Risk" || contextColumns.includes(columnName)) {
                  return (
                    <Td isNull={!val} key={`${rowIndex}-${val}-${colIndex}`}>
                      {val || "null"}
                    </Td>
                  );
                }

                const score = dataPoint[columnName]?.score;
                return (
                  <Td isNull={!val} key={`${rowIndex}-${val}-${colIndex}`} score={score}>
                    <ValueAndArrow>
                      {val || "null"}
                      <ScoreArrow score={score} />
                    </ValueAndArrow>
                  </Td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};

const CsvReportView = () => {
  return (
    <>
      <TabularDatasetPreview datasetPreview={data} />
    </>
  );
};

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const Steps = ({ stepNames, onStepSelect, selectedStep, latestStep }) => {
  const latestStepIndex = stepNames.indexOf(latestStep);

  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
          {/* <StepCheckbox type="checkbox" checked={i <= latestStepIndex} onChange={() => {}} /> */}
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const CsvStatsReport = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const [latestStep, setLatestStep] = useState("Train");

  const loggedInUserName = getLoggedInUserName();

  return loggedInUserName ? (
    <CenteredWithTopNavLayout>
      <BigTitle>Credit Risk Data Anlytics</BigTitle>
      <Gap />
      {/* <Steps
        stepNames={["Import data", "Train", "Deployment review"]}
        latestStep={latestStep}
        selectedStep={selectedStep}
        onStepSelect={newStep => setSelectedStep(newStep)}
      />
      <Gap /> */}
      {selectedStep === "Import data" && <ImportDataView />}
      {selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && <CsvReportView />}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <BigTitle>Credit Risk Data Anlytics</BigTitle>
      <Gap />
      <CsvReportView />
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default CsvStatsReport;
