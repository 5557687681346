import styled from "styled-components";
import { useState, useEffect } from "react";

import { getModelById } from "api/services/modelService";
import ModelViewer from "components/widgets/ModelViewer";
import ModelTextEditor from "components/widgets/ModelTextEditor";
import ModelCodeViewer from "components/widgets/ModelCodeViewer";
import ModelViewerCustomTrainingPipeline from "components/widgets/ModelViewerCustomTrainingPipeline";

const modelConfig = {
  id: "62a7566b2403bf13da944f8c",
  createdBy: "test-user",
  createdAt: "Jun 13, 2022 3:23:23 PM",
  sharedWith: [
    {
      userGroupId: "test-user",
      accessLevel: "OWNER",
    },
  ],
  name: "recommended_small",
  latentDims: [5],
  outputDims: [[4], [1], [6], [7], [6], [1], [1], [1], [1], [1], [1], [1], [1], [1]],
  layerConfig: [
    {
      index: 1,
      inputDims: [33],
      outputDims: [4],
      resIndices: [15, 1],
      type: "Tranformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 2,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 2],
      type: "Tranformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 3,
      inputDims: [33],
      outputDims: [6],
      resIndices: [15, 3],
      type: "Tranformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 4,
      inputDims: [33],
      outputDims: [7],
      resIndices: [15, 4],
      type: "Tranformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 5,
      inputDims: [33],
      outputDims: [6],
      resIndices: [15, 5],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 6,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 6],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 7,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 7],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 8,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 8],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 9,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 9],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 10,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 10],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 11,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 11],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 12,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 12],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 13,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 13],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 14,
      inputDims: [33],
      outputDims: [1],
      resIndices: [15, 14],
      type: "Transformer",
      useBias: true,
      activeFun: "LINEAR",
    },
    {
      index: 15,
      inputDims: [33],
      outputDims: [33],
      resIndices: [16, 15],
      type: "Transformer",
      useBias: true,
      activeFun: "RELU",
    },
    {
      index: 16,
      inputDims: [5],
      outputDims: [33],
      resIndices: [0, 16],
      type: "Transformer",
      useBias: true,
      activeFun: "RELU",
    },
  ],
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
`;

const TextEditorContainer = styled.div`
  overflow: hidden;
  border: 1px solid ${props => props.theme.color.closer1};
`;

const ModelViewCustomTrainingPipeline = ({
  modelId,
  modelView = "Graph",
  datasetFeatureTypeDescriptors,
  inputCols,
  outputCols,
}) => {
  return (
    <Container>
      {modelView === "Config" && (
        <TextEditorContainer>
          <ModelTextEditor modelConfig={modelConfig} />
        </TextEditorContainer>
      )}
      {modelView === "Graph" && (
        <ModelViewerCustomTrainingPipeline
          modelConfig={modelConfig}
          datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
          inputCols={inputCols}
          outputCols={outputCols}
        />
      )}
      {modelView === "Code" && <ModelCodeViewer modelId={modelId} />}
    </Container>
  );
};

export default ModelViewCustomTrainingPipeline;
