import { Link as ReactRouterLink } from "react-router-dom";
import styled from "styled-components";

const Logo = styled.img`
  margin-bottom: 30px;
  margin-left: 20px;
  width: 50px;
`;

const Link = styled(ReactRouterLink)`
  display: flex;
  align-items: center;
`;

const HeaderNavigation = styled.header`
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  background-color: ${props => props.bgColor || "#141414"};
  border-right: 1px solid ${props => props.theme.color.closer1};
  height: 100vh;
  padding: 25px;
  transition: width 100ms linear;
  width: 175px;

  ul {
    padding-left: 0;
    text-align: center;
  }
  ul li {
    padding-left: 0;
    margin-bottom: 30px;
  }
  ul li:before {
    content: none;
  }
  ul li a span {
    opacity: 1;
    display: inline;
  }
  ul {
    padding: 0;
    text-align: left;
  }
  ul li {
    padding: 0;
  }
  ul li ul {
    display: block;
  }
  ul li ul {
    padding: 0;
    padding-top: 10px;
    padding-left: 26px;
    margin: 0;
    display: none;
  }
  ul li ul li {
    padding: 0;
    margin: 0;
    margin: 5px 0;
  }
  ul li ul li a span {
    color: #acacac;
  }
  ul li img {
    filter: brightness(2) grayscale(1);
  }
  ul li a {
    white-space: pre;
  }
  ul li a:hover img {
    filter: none;
  }
  ul li a:hover span {
    color: #0191ff;
    font-weight: bold;
  }
  ul li a span {
    transition: opacity 100ms linear;
    transition-delay: 50ms;
    color: white;
    font-size: 14px;
    margin-left: 10px;
    vertical-align: text-bottom;
  }
  ul li a:link {
    text-decoration: none;
  }
`;

export const ExternalNavigation = ({ bgColor }) => {
  return (
    <HeaderNavigation bgColor={bgColor}>
      <a href="/market-place">
        <Logo src={"/images/logo-white.png"} />
      </a>
      <ul>
        <li>
          <Link to="/packages-dashboard">
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/packages-search">
            <span>Search</span>
          </Link>
        </li>
        <li>
          <Link to="/create-package">
            <span>Upload files</span>
          </Link>
        </li>
      </ul>
    </HeaderNavigation>
  );
};

export const InternalNavigation = ({ bgColor }) => {
  return (
    <HeaderNavigation bgColor={bgColor}>
      <a href="/market-place">
        <Logo src={"/images/logo-white.png"} />
      </a>
      <ul>
        <li>
          <Link to="/packages-dashboard">
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/packages-search">
            <span>Search</span>
          </Link>
        </li>
        <li>
          <Link to="/create-package">
            <span>Upload files</span>
          </Link>
        </li>
      </ul>
    </HeaderNavigation>
  );
};
