import { range } from "lodash";

import { chartColours } from "App";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";

const getJobCostsForPeriod = (job, periodStartMs, periodEndMs) => {
  const jobStartMs = new Date(job.startedAt).getTime();
  const jobEndMs = new Date(job.stoppedAt).getTime();

  if (jobEndMs < periodStartMs || jobStartMs > periodEndMs) {
    return 0;
  }

  if (!job.stoppedAt) {
    const now = new Date().getTime();
    return ((now - Math.max(periodStartMs, jobStartMs)) / (now - jobStartMs)) * job.cost;
  }

  return ((Math.min(periodEndMs, jobEndMs) - Math.max(periodStartMs, jobStartMs)) / (jobEndMs - jobStartMs)) * job.cost;
};

const getJobCostsInTimePeriod = (periodStartMs, periodEndMs, bill) => {
  const jobTypes = ["dataJobs", "trainingJobs", "deployments", "jupyterSessions", "evaluationJobs"];

  const jobCosts = {};

  jobTypes.forEach(jobType => {
    bill[jobType].items.forEach(job => {
      if (jobCosts[jobType]) {
        jobCosts[jobType] = jobCosts[jobType] += getJobCostsForPeriod(job, periodStartMs, periodEndMs);
      } else {
        jobCosts[jobType] = getJobCostsForPeriod(job, periodStartMs, periodEndMs, jobType);
      }
    });
  });

  return jobCosts;
};

const getBarDataFromBill = bill => {
  const data = [];

  let day = new Date(bill.periodEnd);
  let dayStartMs = new Date(day.getTime()).setHours(0, 0, 0, 0);
  let dayEndMs = new Date(day.getTime()).setHours(23, 59, 59, 999);

  const dayLabel = day.toString().slice(4, 10);
  const { dataJobs, trainingJobs, deployments, jupyterSessions, evaluationJobs } = getJobCostsInTimePeriod(
    dayStartMs,
    dayEndMs,
    bill
  );
  data.push({ name: dayLabel, dataJobs, trainingJobs, deployments, jupyterSessions, evaluationJobs });

  range(0, 10).forEach(() => {
    day.setDate(day.getDate() - 1);
    dayStartMs = new Date(day.getTime()).setHours(0, 0, 0, 0);
    dayEndMs = new Date(day.getTime()).setHours(23, 59, 59, 999);

    const dayLabel = day.toString().slice(4, 10);
    data.push({ name: dayLabel, ...getJobCostsInTimePeriod(dayStartMs, dayEndMs, bill) });
  });
  return data;
};

const BarChartOfBill = ({ bill }) => (
  <BarChart width={740} height={290} data={getBarDataFromBill(bill)} barCategoryGap={0} barGap={0}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend height={5} />
    <Bar dataKey="dataJobs" fill={chartColours[0]} />
    <Bar dataKey="trainingJobs" fill={chartColours[1]} />
    <Bar dataKey="deployments" fill={chartColours[2]} />
    <Bar dataKey="jupyterSessions" fill={chartColours[3]} />
    <Bar dataKey="evaluationJobs" fill={chartColours[4]} />
  </BarChart>
);

export default BarChartOfBill;
