import { useState } from "react";
import styled from "styled-components";

import PipelineIdCheckboxSelector from "components/widgets/PipelineIdCheckboxSelector";
import MultiLineTrainingProgressView from "components/views/MultiLineTrainingProgressView";

const Container = styled.div`
  height: 500px;
`;

const fixedColors = [
  "#1f77b4",
  "#ff7f0e",
  "#2ca02c",
  "#d62728",
  "#9467bd",
  "#8c564b",
  "#e377c2",
  "#7f7f7f",
  "#bcbd22",
  "#17becf",
  "#aec7e8",
  "#348686",
  "#c02d2d",
  "#25e092",
];

const getColorMapFromIds = ids => {
  const colorMap = {};
  ids.forEach((id, i) => {
    colorMap[`infer_loss_${id}`] = fixedColors[i * 2];
    colorMap[`train_loss_${id}`] = fixedColors[i * 2 + 1];
  });
  return colorMap;
};

const MultiTrainingProgressView = ({ pipelineOutputs }) => {
  const [highlightedJobId, setHighlightedJobId] = useState(pipelineOutputs[0].trainingJob.id);

  const lineIdColorMap = getColorMapFromIds(
    pipelineOutputs.map(p => p.trainingJob.id)
  );

  return (
    <>
      <PipelineIdCheckboxSelector
        pipelineIds={pipelineOutputs.map(output => output.pipelineId)}
        selectedPipelineIds={pipelineOutputs.map(output => output.pipelineId)}
        onPipelineIdHover={trainingJobId => setHighlightedJobId(trainingJobId)}
        jobIdColorMap={lineIdColorMap}
        pipelineOutputs={pipelineOutputs}
      />
      <Container>
        <MultiLineTrainingProgressView
          highlightedJobId={highlightedJobId}
          jobIds={pipelineOutputs.map(output => output.trainingJob.id)}
          lineIdColorMap={lineIdColorMap}
        />
      </Container>
    </>
  );
};

export default MultiTrainingProgressView;
