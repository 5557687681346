import { useState } from "react";
import styled from "styled-components";

import { SmallTitle } from "components/ui/Text";
import ReadmeViewerAndEditor from "components/widgets/ReadmeViewerAndEditor";

const Container = styled.div``;

const FileListTitle = styled(SmallTitle)`
  cursor: pointer;
`;

const CubeReadmeViewer = ({ cubeName = "", isSavingCube, editedCube, onSaveNewCube, showExpandToggle = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <Container>
      {showExpandToggle && (
        <FileListTitle onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "-" : "+"} README</FileListTitle>
      )}
      <ReadmeViewerAndEditor
        isDisabled={isSavingCube}
        markdownStr={editedCube?.readMe}
        onClickSave={newMdStr => onSaveNewCube({ ...editedCube, readMe: newMdStr })}
        defaultTitle={cubeName}
      />
    </Container>
  );
};

export default CubeReadmeViewer;
