import { useState, useEffect } from "react";
import { CenteredWithTopNavLayout, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import ApplicationCard from "components/ui/ApplicationCard";
import styled from "styled-components";
import { Link as ReactRouterLink } from "react-router-dom";
import { getSolutions } from "api/services/projectService";
import TextInput from "components/ui/TextInput";
import { by } from "utils/common";
import { getLoggedInUserName } from "api/services/authenticationService";
import FilterTag from "components/ui/FilterTag";

const HARDCODED_SOLUTIONS = [
  {
    id: 0,
    name: "NLP Multi-task Solution",
    createdAt: "Mar 13,2022 6:02:23 PM",
    createdBy: "bz_bot",
  },
  {
    id: 1,
    name: "Chat Bot",
    createdAt: "Created at: Mar 12,2022 2:23:12 PM",
    createdBy: "bz_bot",
  },
  {
    id: 2,
    name: "Explainable Image Labelling",
    createdAt: "Created at: Mar 10,2022 9:27:44 AM",
    createdBy: "bz_bot",
  },
  {
    id: 3,
    name: "Text to Code Generation",
    createdAt: "Created at: Mar 09,2022 4:11:23 PM",
    createdBy: "bz_bot",
  },
  {
    id: 4,
    name: "Go Game Bot",
    createdAt: "Created at: Mar 09,2022 9:38:56 AM",
    createdBy: "bz_bot",
  },
  {
    id: 5,
    name: "Molecule Generation",
    createdAt: "Created at: Mar 09,2022 9:38:56 AM",
    createdBy: "bz_bot",
  },
  {
    id: 6,
    name: "Credit Risk Data Analytics",
    createdAt: "Created at: Mar 09,2022 9:38:56 AM",
    createdBy: "bz_bot",
  },
  {
    id: 7,
    name: "OCR Solution",
    createdAt: "Created at: Jun 004,2022 12:43:23 AM",
    createdBy: "bz_bot",
  },
];

const Link = styled(ReactRouterLink)`
  text-decoration: none;
  color: inherit;
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const TextInputContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  padding: 10px;
  width: max-content;
`;

const FilterTagsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-bottom: 20px;
`;

const FilterNameInput = styled(TextInput)`
  padding: 8px 15px;
`;

const ApplicationListPage = () => {
  const [solutions, setSolutions] = useState([]);
  const [error, setError] = useState(null);

  const [filterBy, setFilterBy] = useState("");
  const [isFilteringMySolutions, setIsFilteringMySolutions] = useState(false);

  const doFetchSolutions = async () => {
    const { data, error } = await getSolutions();
    setError(error);
    setSolutions(data);
  };

  const userName = getLoggedInUserName();

  useEffect(() => {
    doFetchSolutions();
  }, []);

  return userName ? (
    <CenteredWithTopNavLayout>
      <FilterTagsContainer>
        <TextInputContainer>
          <div>Filter name:</div>
          <FilterNameInput value={filterBy} onNewInput={newFilterBy => setFilterBy(newFilterBy)} />
        </TextInputContainer>
        {/* <FilterTag
          isActive={isFilteringMySolutions}
          onToggle={() => setIsFilteringMySolutions(!isFilteringMySolutions)}
        >
          Created by me
        </FilterTag> */}
      </FilterTagsContainer>

      <CardsContainer>
        {[...(solutions || []), ...HARDCODED_SOLUTIONS]
          .filter(solution => solution?.name?.toLowerCase().includes(filterBy.toLowerCase()))
          .filter(solution => solution.createdBy === getLoggedInUserName())
          .sort(by("createdAt", "desc"))
          .map(solution => {
            return (
              <Link key={solution.id} to={`/applications/${solution.id}`}>
                <ApplicationCard
                  assetType="application"
                  key={solution.name}
                  title={solution.name}
                  subTitle={`Created at: ${solution.createdAt}`}
                  createdByUserName={solution.createdBy}
                />
              </Link>
            );
          })}
      </CardsContainer>
      {error && <div>{JSON.stringify(error)}</div>}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <CardsContainer>
        {[...(solutions || []), ...HARDCODED_SOLUTIONS]
          .filter(solution => solution?.name?.toLowerCase().includes(filterBy.toLowerCase()))
          .filter(solution => solution.createdBy === getLoggedInUserName())
          .filter(solution => {
            if (isFilteringMySolutions) {
              return solution.createdBy === getLoggedInUserName();
            }
            return true;
          })
          .sort(by("createdAt", "desc"))
          .map(solution => {
            return (
              <Link key={solution.id} to={`/applications/${solution.id}`}>
                <ApplicationCard
                  assetType="application"
                  key={solution.name}
                  title={solution.name}
                  subTitle={`Created at: ${solution.createdAt}`}
                  createdByUserName={solution.createdBy}
                  viewByLoggedInUser={false}
                />
              </Link>
            );
          })}
      </CardsContainer>
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default ApplicationListPage;
