import styled from "styled-components";

import { SmallTitle } from "components/ui/Text";
import DatasetListSelect from "components/views/CreatePipelineView/DatasetListSelect";
import DatasetPreviewWithSetIndex from "components/views/DatasetPreviewWithEditing/DatasetPreviewSetIndex";

const Container = styled.div`
  padding: 20px;
`;

const JoinDatasetView = ({ joinOp, onChangeOp }) => {
  return (
    <Container data-tutorial-id="select-dataset-container">
      <SmallTitle>Select dataset</SmallTitle>
      <DatasetListSelect
        selectedDatasetId={joinOp?.config?.dataset}
        onDatasetSelect={newDs => {
          const newOp = {
            ...joinOp,
            config: {
              ...joinOp.config,
              dataset: newDs?.id,
              group: [null],
            },
          };
          onChangeOp(newOp);
        }}
        showEngineSelect={false}
        showViewButtons={false}
        maxHeight="200px"
        onSwitchTab={() => {
          const newOp = {
            ...joinOp,
            config: {
              ...joinOp.config,
              dataset: null,
            },
          };
          onChangeOp(newOp);
        }}
      />
      {joinOp?.config?.dataset && (
        <DatasetPreviewWithSetIndex
          isIndexSettingDisabled={false}
          datasetId={joinOp?.config?.dataset}
          idColumn={joinOp?.config?.group?.[0]}
          onSetIdColumn={newColumn => {
            const newOp = {
              ...joinOp,
              config: {
                ...joinOp.config,
                group: [newColumn],
              },
            };
            onChangeOp(newOp);
          }}
        />
      )}
    </Container>
  );
};

export default JoinDatasetView;
