import styled from "styled-components";
import { isEmpty } from "lodash";
import { useState, useEffect } from "react";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getDataConfigFromDeployment } from "api/services/deploymentService";
import usePollDeploymentCluster from "components/views/DeploymentTabViewNoTasks/usePollDeploymentCluster";
import ImageView from "components/views/DeploymentTabViewNoTasks/ImageView";
import TextView from "components/views/DeploymentTabViewNoTasks/TextView";
import CsvViewApiDesign from "components/views/DeploymentTabViewNoTasks/CsvView/CsvApiDesign";
import SequenceView from "components/views/DeploymentTabViewNoTasks/SequenceView";
import DeploymentTopBar from "components/widgets/TopBars/DeploymentTopBar";
import TrainingAutotuneView from "components/views/TrainingAutotuneView";
import { DockerIcon } from "components/ui/Icons";
import DeploymentApiView from "components/views/DeploymentApiView";
import { CircularProgress } from "@material-ui/core";
import MessageModalTrigger from "components/views/MessageModalTrigger";

const Container = styled.div`
  ${props => !props.isEnabled && "pointer-events: none; opacity: 0.3;"}
`;

const ChooseJobText = styled.div`
  padding: 20px;
  padding-bottom: 10px;
`;

const DockerButtonContainer = styled.div`
  width: max-content;
  background-color: ${props => props.theme.color.furthest};
  padding: 2px 15px;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 20px;
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.primary};
    svg path {
      fill: ${props => props.theme.color.furthest};
    }
  }
  svg path {
    fill: #2395ec;
  }
`;

const ButtonSection = styled.div`
  background-color: ${props => props.theme.color.closer1};
  display: grid;
  grid-template-columns: 1fr;
  padding: 0 15px;
  justify-items: end;
`;

const LoadingContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 20px;
  grid-template-columns: auto auto;
  svg circle {
    stroke: ${props => props.theme.color.primary};
  }
  font-size: 16px;
  font-weight: 500;
  background-color: ${props => props.theme.color.closer0_5};
  height: 500px;
`;

const LaunchBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0;
  background-color: ${props => props.theme.color.closer0_5};
`;

const isDataAboutImage = featureTypeDescriptors => {
  const filteredFilters = featureTypeDescriptors?.filter(descriptor => descriptor.type?.structure === "IMAGE");
  return !isEmpty(filteredFilters) && featureTypeDescriptors?.length < 3;
};

const isDataAboutText = featureTypeDescriptors => {
  const filteredFilters = featureTypeDescriptors?.filter(descriptor => descriptor.type?.structure === "TEXT");
  return !isEmpty(filteredFilters) && featureTypeDescriptors?.length < 3;
};

const isDataAboutSequence = featureTypeDescriptors => {
  const filteredFilters = featureTypeDescriptors?.filter(descriptor => descriptor.type?.structure === "SEQUENCE");
  return !isEmpty(filteredFilters);
};

const DeploymentTabViewNoTasks = ({ pipelineOutput, cubeName }) => {
  const [deploymentCluster, isLoading, error, doCreateCluster, doTurnOn, doTurnOff] = usePollDeploymentCluster(
    pipelineOutput?.model?.id
  );

  const [pipelineCreatedByLoggedInUser, setPipelineCreatedByLoggedInUser] = useState(false);
  const [featureTypeDescriptors, setFeatureTypeDescriptors] = useState(null);
  const [descriptorsErr, setDescriptorsErr] = useState(null);
  const [hasUserClickedTurnOff, setHasUserClickedTurnOff] = useState(false);

  useEffect(() => {
    if (!!pipelineOutput) {
      setPipelineCreatedByLoggedInUser(pipelineOutput?.createdBy === getLoggedInUserName());
    }
  }, [pipelineOutput]);

  useEffect(() => {
    doPopulateFeatureTypeDescriptors();
  }, [deploymentCluster?.id]);

  const doPopulateFeatureTypeDescriptors = async () => {
    if (!deploymentCluster?.id) {
      return;
    }
    const { data: dataConfig, error } = await getDataConfigFromDeployment(deploymentCluster?.id);
    setFeatureTypeDescriptors(dataConfig?.featureTypeDescriptors);
    setDescriptorsErr(error);
  };

  if (pipelineOutput?.trainingBundle && !deploymentCluster) {
    return (
      <>
        <ChooseJobText>Choose job to deploy:</ChooseJobText>
        <TrainingAutotuneView
          trainingJobs={pipelineOutput?.trainingBundle?.trainingJobs}
          evaluationJobs={pipelineOutput?.trainingBundle?.evaluationJobs}
          onSuccessfulDeploy={() => window.location.reload()}
        />
      </>
    );
  }

  let onPowerClick = doCreateCluster;
  if (deploymentCluster?.readyToUse) {
    onPowerClick = () => {
      setHasUserClickedTurnOff(true);
      doTurnOff();
    };
  }
  if (deploymentCluster?.instanceCount === 0) {
    onPowerClick = doTurnOn;
  }

  let deploymentInteractionView = (
    <CsvViewApiDesign pipelineOutput={pipelineOutput} deploymentClusterId={deploymentCluster?.id} cubeName={cubeName} />
  );
  if (isDataAboutImage(featureTypeDescriptors)) {
    deploymentInteractionView = (
      <ImageView
        pipelineOutput={pipelineOutput}
        deploymentClusterId={deploymentCluster?.id}
        featureTypeDescriptors={featureTypeDescriptors}
      />
    );
  }

  if (isDataAboutText(featureTypeDescriptors)) {
    deploymentInteractionView = (
      <TextView
        pipelineOutput={pipelineOutput}
        deploymentClusterId={deploymentCluster?.id}
        featureTypeDescriptors={featureTypeDescriptors}
      />
    );
  }

  if (isDataAboutSequence(featureTypeDescriptors)) {
    deploymentInteractionView = isLoading || !deploymentCluster?.readyToUse ? null : <SequenceView />;
  }

  // if (isLoading) {
  //   return (
  //     <LoadingContainer>
  //       <CircularProgress />
  //       Deployment starting...
  //     </LoadingContainer>
  //   );
  // }

  return (
    <>
      <DeploymentTopBar
        pipelineCreatedByLoggedInUser={pipelineCreatedByLoggedInUser}
        isDataAboutImageOrText={isDataAboutText(featureTypeDescriptors) || isDataAboutImage(featureTypeDescriptors)}
        isLoading={isLoading}
        isReadyToUse={deploymentCluster?.readyToUse}
        onPowerClick={onPowerClick}
      />
      {/* <ButtonSection>
        <DockerButtonContainer>
          <DockerIcon />
        </DockerButtonContainer>
      </ButtonSection> */}
      {error && JSON.stringify(error)}
      {descriptorsErr && JSON.stringify(descriptorsErr)}
      <LaunchBtnContainer>
        <MessageModalTrigger
          isDisabled={isLoading}
          isVisible={!deploymentCluster?.readyToUse}
          isReady={deploymentCluster?.readyToUse}
          onClickLaunch={() => {
            if (deploymentCluster === undefined) {
              doCreateCluster();
              return;
            }
            if (deploymentCluster?.readyToUse) {
              setHasUserClickedTurnOff(true);
              doTurnOff();
              return;
            }
            if (deploymentCluster?.instanceCount === 0) {
              doTurnOn();
              return;
            }
          }}
        />
      </LaunchBtnContainer>
      <Container isEnabled={deploymentCluster?.readyToUse}>
        <DeploymentApiView
          deploymentId={deploymentCluster?.id}
          inputNames={pipelineOutput?.model?.inputs?.map(input => input?.key)}
          datasetId={pipelineOutput?.dataset?.id}
        />
      </Container>
    </>
  );
};

export default DeploymentTabViewNoTasks;
