import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { DatasetCard } from "components/ui/cards";
import { getLoggedInUserName } from "api/services/authenticationService";
import { getDatasetsCreatedByUserName } from "api/services/dataService";
import Button from "components/ui/Button";
import { by } from "utils/common";

const Gap = styled.div`
  height: 10px;
`;

const LongCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;

const NewButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
`;

const DatasetsOfUser = ({ userName }) => {
  const navigate = useNavigate();
  const loggedInUserName = getLoggedInUserName();

  const [datasets, setDatasets] = useState([]);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const doFetchDatasets = async () => {
    setIsLoading(true);
    const { data, error } = await getDatasetsCreatedByUserName(userName);
    setDatasets(data.filter(e => e.name !== "go"));
    setError(error);
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchDatasets();
  }, []);

  if (isLoading) {
    return "Loading datasets...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <>
      {loggedInUserName === userName && (
        <NewButtonContainer>
          <Button
            value="New"
            onClick={() => {
              navigate("/data/create");
            }}
          />
        </NewButtonContainer>
      )}
      <Gap />
      <LongCardsContainer>
        {datasets?.sort(by("createdAt", "desc")).map(dataset => (
          <DatasetCard
            key={dataset.name}
            title={dataset.name}
            subTitle={`Created at: ${dataset.createdAt}`}
            datasetId={dataset.id}
          />
        ))}
      </LongCardsContainer>
    </>
  );
};

export default DatasetsOfUser;
