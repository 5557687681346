import styled from "styled-components";
import { getColorFromString } from "utils/common";
import LegendLabelInput from "components/views/TextLabellingArea/LegendLabelInput";
import AnnotationSpan from "components/views/TextLabellingArea/AnnotationSpan";
import Button from "components/ui/Button";
import { getTextSegmentConfig } from "utils/text-annotate-utils";

const getIndicesOfSubstring = (sourceStr, searchStr) =>
  [...sourceStr.matchAll(new RegExp(searchStr, "gi"))].map(a => a.index);

const ModalContent = styled.div`
  background-color: ${props => props.theme.color.closer1};
  min-height: 200px;
  width: 100%;
  max-width: 660px;
  padding: 20px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  overflow-y: auto;
  white-space: pre;
`;

const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  gap: 5px;
  padding: 10px 0;
  min-height: 38px;
`;

const SuggestionsButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const ShowSuggestionsButton = styled(Button)`
  min-width: 0;
  min-height: 0;
  padding: 2px 10px;
  justify-self: end;
  align-self: center;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextLabellingArea = ({ isOpen, onClose, text, annotations = [], onNewAnnotations }) => {
  if (!annotations?.map) {
    annotations = [];
  }
  const annotationLabels = [...new Set(annotations?.map(annotation => annotation?.label))];
  const textSegmentsConfig = getTextSegmentConfig(text, annotations);

  return (
    <Container>
      <ModalContent
        onMouseUp={() => {
          const selectedString = getSelection()?.toString();
          if (!selectedString) {
            return;
          }

          const newAnnotations = getIndicesOfSubstring(text, selectedString).map(pos => ({
            pos,
            span: selectedString.length,
            label: "",
          }));
          onNewAnnotations([...annotations, ...newAnnotations]);
        }}
      >
        {textSegmentsConfig?.map(segment => {
          if (segment.annotation) {
            return (
              <AnnotationSpan
                key={segment?.start}
                bgColor={getColorFromString(segment?.annotation?.label)}
                onClick={() => {
                  const newAnnotations = annotations.filter(annotation => annotation?.pos !== segment?.annotation?.pos);
                  onNewAnnotations(newAnnotations);
                }}
              >
                {text?.slice(segment.start, segment.end)}
              </AnnotationSpan>
            );
          }
          return text?.slice(segment.start, segment.end);
        })}
      </ModalContent>
      <Legend>
        {annotationLabels.map((label, i) => (
          <LegendLabelInput
            key={`${label}-${i}`}
            label={label}
            annotations={annotations}
            onNewAnnotations={onNewAnnotations}
          />
        ))}
      </Legend>
    </Container>
  );
};

export default TextLabellingArea;
