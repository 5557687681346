import { apiPost } from "api/api-http-methods";
import { zapiPost } from "api/zapi";

export const enhanceImage = async (base64Data, boundingBoxes) => {
  const { data, error } = await zapiPost(
    "/project-service/api/v1/cv",
    {},
    { image: base64Data, masking_boxes: boundingBoxes || [] }
  );

  return { data, error };
};

// export const enhanceImage = async (base64Data, boundingBoxes) => {
//   const { data, error } = await apiPost(
//     "http://35.230.153.222:8080/api/v1/cv",
//     {},
//     { image: base64Data, masking_boxes: boundingBoxes || [] }
//   );

//   return { data, error };
// };
