import * as backend from "api/backend/modelServiceEndpoints";
import { getHandlingFromError } from "api/error-handling";

export const getModelById = async modelId => {
  const { data, error } = await backend.getModelById(modelId);
  return { data, error: getHandlingFromError(error) };
};

export const getModels = async params => {
  const { data, error } = await backend.getModels(params);
  return { data, error: getHandlingFromError(error) };
};

export const registerRecommendedModels = async datasetId => {
  const { data, error } = await backend.registerRecommendedModels(datasetId);
  return { data, error: getHandlingFromError(error) };
};

export const getModelCode = async (modelId, codeLibrary) => {
  const { data, error } = await backend.getModelCode(modelId, codeLibrary);
  return { data, error: getHandlingFromError(error) };
};

export const postModelRecommendation = async partialModelConfig => {
  const { data, error } = await backend.postModelRecommendation(partialModelConfig);
  return { data, error: getHandlingFromError(error) };
};
