import styled from "styled-components";
import { useState } from "react";
import Button from "components/ui/Button";

import { getLabelPredictionFromImage } from "api/services/deploymentService";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "components/views/DeploymentTabViewNoTasks/TextView/TextDataEnrichmentView/TaskContainer";
import EmbeddingGridView from "components/widgets/EmbeddingGridView";

const PredictButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const PredictedLabel = styled.div`
  padding: 10px;
  font-size: 18px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const EmbeddingGridLoading = styled.div`
  width: 342px;
  height: 72px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const LabelPrediction = ({
  deploymentClusterId,
  inputImgSrc,
  isSelected,
  onSelect,
  trainingJobId,
  modelConfig,
  datasetFeatureTypeDescriptors,
}) => {
  const [predictionResult, setPredictionResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doPredictLabel = async e => {
    e.stopPropagation();
    setIsFetchingPrediction(true);
    const { data, error } = await getLabelPredictionFromImage(deploymentClusterId, inputImgSrc, "predict_image_label");
    setPredictionResult(data);
    setError(error);
    setIsFetchingPrediction(false);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} readOnly />
      <TitleText>Label prediction</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{
          image: inputImgSrc,
        }}
        outputCols={["label"]}
        isEnabled={isSelected}
        trainingJobId={trainingJobId}
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
        tag="predict_image_label"
      />
    </LabelAndModals>
  );

  if (!predictionResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <PredictButton onClick={doPredictLabel} value="Predict">
            Predict
          </PredictButton>
          <PredictedLabel>&nbsp;&nbsp;</PredictedLabel>
          <EmbeddingGridLoading />
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <PredictButton onClick={doPredictLabel} value="Predict">
          Predict
        </PredictButton>
        <PredictedLabel>{predictionResult?.winner}</PredictedLabel>
        <EmbeddingGridView
          embeddingVec={Object.values(predictionResult?.scores)}
          classLabels={Object.keys(predictionResult?.scores)}
        />
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default LabelPrediction;
