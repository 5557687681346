import { useState, useEffect } from "react";
import { getDeploymentClusterById } from "api/services/deploymentService";
import { shutDownADeploymentCluster, turnOnADeploymentCluster } from "api/services/deploymentService";

const usePollDeploymentClusterById = deploymentClusterId => {
  const [deploymentCluster, setDeploymentCluster] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (!deploymentClusterId) {
      return;
    }
    doStartPolling();
  }, [deploymentClusterId]);

  useEffect(() => {
    if (!deploymentCluster?.readyToUse && deploymentCluster?.instanceCount > 0 && !error) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
    clearInterval(intervalId);

    return () => clearInterval(intervalId);
  }, [deploymentCluster, error]);

  const doStartPolling = async () => {
    clearInterval(intervalId);
    const pollIntervalId = setInterval(doPopulateDeploymentCluster, 2000);
    setIntervalId(pollIntervalId);
    doPopulateDeploymentCluster();
  };

  const doPopulateDeploymentCluster = async () => {
    const { data: deploymentCluster, error } = await getDeploymentClusterById(deploymentClusterId);
    setError(error);
    setDeploymentCluster(deploymentCluster);
  };

  const doTurnOn = async () => {
    if (!deploymentCluster?.id) {
      return;
    }
    setIsLoading(true);
    const { error } = await turnOnADeploymentCluster(deploymentCluster.id);
    setError(error);
    doStartPolling();
  };

  const doTurnOff = async () => {
    setIsLoading(true);
    const { error } = await shutDownADeploymentCluster(deploymentCluster.id);
    setError(error);
    doStartPolling();
  };

  return [deploymentCluster, isLoading, error, doTurnOn, doTurnOff];
};

export default usePollDeploymentClusterById;
