import { useState, useEffect } from "react";
import Modal from "components/ui/Modal";
import styled from "styled-components";
import { Add } from "@material-ui/icons";
import TextInput from "components/ui/TextInput";
import { isEmpty } from "lodash";
import { CircularProgress } from "@material-ui/core";

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  width: 700px;
  height: 400px;
`;

const QueryBox = styled.div`
  margin: 20px;
`;

const IconContainer = styled.span`
  visibility: hidden;
  :hover {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
  }
`;

const FileListContainer = styled.div`
  height: 100%;
`;

const File = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid ${props => props.theme.color.closer1};

  :hover {
    ${IconContainer} {
      visibility: visible;
    }
    background-color: ${props => props.theme.color.closer0};
  }
`;

const FileAction = styled.div`
  color: ${props => props.theme.color.closer2};
  display: flex;
  gap: 10px;
`;

const FileListModal = ({ isLoadingFiles, open, handleClose, fileList, onFileSelect }) => {
  const [query, setQuery] = useState("");
  const [filteredFilelist, setFilteredFileList] = useState();

  useEffect(() => {
    setFilteredFileList(fileList);
  }, [fileList]);

  useEffect(() => {
    if (isEmpty(query)) {
      setFilteredFileList(fileList);
    } else {
      setFilteredFileList(fileList.filter(file => file.name.includes(query)));
    }
  }, [query]);

  return (
    <Modal handleClose={handleClose} open={open} title={"Google Drive Files"}>
      {isLoadingFiles ? (
        <ModalContent>
          <CircularProgress />
        </ModalContent>
      ) : (
        <ModalContent>
          <QueryBox>
            <TextInput
              title="Search"
              value={query}
              onNewInput={newValue => {
                setQuery(newValue);
              }}
            />
          </QueryBox>

          <FileListContainer>
            {filteredFilelist?.map(file => (
              <File key={file.id}>
                {file.name}

                <FileAction>
                  <IconContainer
                    onClick={() => {
                      onFileSelect(file.id, file.name);
                    }}
                  >
                    <Add />
                  </IconContainer>
                </FileAction>
              </File>
            ))}
          </FileListContainer>
        </ModalContent>
      )}
    </Modal>
  );
};

export default FileListModal;
