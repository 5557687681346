import styled from "styled-components";

import ImageInpainting from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ImageInpainting";
import LabelPrediction from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/LabelPrediction";
import ImageGeneration from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ImageGeneration";
import DataSynthesis from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/DataSynthesis";

const DataEnrichmentTasksContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
`;

const ImageDataEnrichmentView = ({
  inputImgSrc,
  inputLabel,
  selectedTasks,
  onTaskSelect,
  deploymentClusterId,
  trainingJobId,
  modelConfig,
  featureTypeDescriptors,
}) => {
  return (
    <DataEnrichmentTasksContainer>
      <LabelPrediction
        deploymentClusterId={deploymentClusterId}
        inputImgSrc={inputImgSrc}
        isSelected={selectedTasks.includes("Label prediction")}
        onSelect={() => onTaskSelect("Label prediction")}
        trainingJobId={trainingJobId}
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={featureTypeDescriptors}
      />
      <ImageInpainting
        deploymentClusterId={deploymentClusterId}
        inputImgSrc={inputImgSrc}
        isSelected={selectedTasks.includes("Image inpainting")}
        onSelect={() => onTaskSelect("Image inpainting")}
        trainingJobId={trainingJobId}
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={featureTypeDescriptors}
      />
      <ImageGeneration
        deploymentClusterId={deploymentClusterId}
        inputImgLabel={inputLabel}
        isSelected={selectedTasks.includes("Image generation")}
        onSelect={() => onTaskSelect("Image generation")}
        trainingJobId={trainingJobId}
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={featureTypeDescriptors}
      />
      <DataSynthesis
        deploymentClusterId={deploymentClusterId}
        isSelected={selectedTasks.includes("Data synthesis")}
        onSelect={() => onTaskSelect("Data synthesis")}
        trainingJobId={trainingJobId}
        modelConfig={modelConfig}
        datasetFeatureTypeDescriptors={featureTypeDescriptors}
      />
    </DataEnrichmentTasksContainer>
  );
};

export default ImageDataEnrichmentView;
