import styled from "styled-components";
import { useState, useEffect } from "react";

import { getTrainingOrganicProgressDataForJobId } from "api/services/jobService/useTrainingProgressForJobId";
import { triggerDownloadOfTrainedModel } from "api/services/jobService";
import { Gap } from "components/Layout";
import ProgressBar from "components/ui/ProgressBar";
import { CloudDownload, Visibility } from "@material-ui/icons";
import { ModelIcon, DatabaseIcon } from "components/ui/Icons";

const JobCardContainer = styled.div`
  padding: 10px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 5px;
`;

const ProgressContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  white-space: nowrap;
`;

const JobProgressBar = styled(ProgressBar)`
  background-color: ${props => props.theme.color.closer0};
  width: 150px;
`;

const StatusText = styled.span`
  padding-left: 5px;
  font-weight: bold;
  color: ${props => {
    if (props.status === "DONE") {
      return props.theme.color.status_done;
    }
    if (props.status === "IN_PROGRESS") {
      return props.theme.color.in_progress;
    }
    if (props.status === "ABORT" || props.status === "FAILED") {
      return props.theme.color.error;
    }
  }};
`;

const IconContaner = styled.div`
  place-self: center;
  cursor: pointer;
`;

const IdAndDownload = styled.div`
  justify-content: space-between;
  display: flex;
`;

const JobCard = ({ job, showSampleData = null, showIcon = true, showProgress = true, inspectButton = null }) => {
  const [trainingJobProgressPoints, setTrainingJobProgressPointes] = useState([]);

  const doFetchTrainingJobProgress = async () => {
    const { data } = await getTrainingOrganicProgressDataForJobId(job?.id);
    setTrainingJobProgressPointes(data || []);
  };

  useEffect(() => {
    doFetchTrainingJobProgress();
  }, [job]);

  const currentIter = trainingJobProgressPoints[trainingJobProgressPoints.length - 1]?.iter;
  const progressLeft = `${currentIter || "none"} / ${job?.jobConfig?.train?.max_iter || 5000}`;

  if (job.jobType === "TRAINING") {
    return (
      <JobCardContainer>
        <IdAndDownload>
          {job?.id}
          {job?.status === "DONE" && showIcon && (
            <IconContaner onClick={() => triggerDownloadOfTrainedModel(job.id)}>
              <ModelIcon />
            </IconContaner>
          )}
        </IdAndDownload>
        <Gap height="5px" />
        <ProgressContainer>
          <JobProgressBar currentValue={currentIter} maxValue={job?.jobConfig?.train?.max_iter || 5000} />
          <span>{progressLeft}</span>
          <StatusText status={job?.status}>{job?.status}</StatusText>
        </ProgressContainer>
      </JobCardContainer>
    );
  }

  return (
    <JobCardContainer>
      <IdAndDownload>
        {job?.id}
        {job?.status === "DONE" && showIcon && (
          <IconContaner onClick={() => showSampleData(job.id)}>
            <DatabaseIcon />
          </IconContaner>
        )}
      </IdAndDownload>
      <Gap height="10px" />
      <ProgressContainer>
        {showProgress && (
          <JobProgressBar currentValue={currentIter} maxValue={job?.jobConfig?.train?.max_iter || 5000} />
        )}
        {showProgress && <span>{progressLeft}</span>}
        <StatusText status={job?.status}>{job?.status}</StatusText>
        {inspectButton}
      </ProgressContainer>
    </JobCardContainer>
  );
};

export default JobCard;
