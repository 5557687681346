import styled from "styled-components";
import { useEffect, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { getSuggestionsForKeywordsAndText } from "api/services/solutions/nlpSolutionService";

const CONTEXT_LENGTH = 20;

const PopoverContainer = styled.div`
  position: absolute;
  left: ${props => props.left + 5}px;
  top: ${props => props.top}px;
  background-color: ${props => props.theme.color.furthest};
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const SuggestionContainer = styled.div`
  padding: 5px 10px;
  ${props => props.isHighlighted && `background-color: ${props.theme.color.closer0}`}
`;

const SuggestionText = styled.span`
  color: ${props => props.theme.color.primary};
`;

const SuggestionsPopover = ({ x, y, inputText, onPressEnterNewEditorText }) => {
  const [isFetchingSuggestions, setIsFetchingSuggestions] = useState(true);
  const [suggestions, setSuggestions] = useState([]);
  const [highlightedSuggestionIndex, setHighlightedSuggestionIndex] = useState(0);

  const highlightedSuggestion = suggestions[highlightedSuggestionIndex];

  useEffect(() => {
    doPopulateSuggestions();
  }, [inputText]);

  useEffect(() => {
    document.addEventListener("keydown", addSuggestionToEditorText);
    return () => document.removeEventListener("keydown", addSuggestionToEditorText);
  }, [highlightedSuggestion, inputText]);

  useEffect(() => {
    document.addEventListener("keydown", changeHighlightedSuggestionOnArrow);
    return () => document.removeEventListener("keydown", changeHighlightedSuggestionOnArrow);
  }, [highlightedSuggestionIndex, suggestions]);

  const changeHighlightedSuggestionOnArrow = e => {
    if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
      return;
    }
    e.preventDefault();
    let newIndex = highlightedSuggestionIndex;
    if (e.key === "ArrowDown") {
      newIndex += 1;
    }
    if (e.key === "ArrowUp") {
      newIndex -= 1;
    }
    if (newIndex >= suggestions.length) {
      newIndex = suggestions.length - 1;
    }
    if (newIndex < 0) {
      newIndex = 0;
    }
    setHighlightedSuggestionIndex(newIndex);
  };

  const addSuggestionToEditorText = e => {
    if (e.key !== "Enter") {
      return;
    }
    e.preventDefault();

    let newInputText = `${inputText?.trim()} ${highlightedSuggestion?.text}`;
    if (highlightedSuggestion?.direction === "LEFT") {
      newInputText = `${highlightedSuggestion?.text} ${inputText?.trim()}`;
    }

    onPressEnterNewEditorText(newInputText);
  };

  const doPopulateSuggestions = async () => {
    const { data } = await getSuggestionsForKeywordsAndText([], inputText);
    setSuggestions(data);
    setIsFetchingSuggestions(false);
  };

  if (isFetchingSuggestions) {
    return (
      <PopoverContainer left={x} top={y}>
        <CircularProgress fontSize="small" />
      </PopoverContainer>
    );
  }

  if (suggestions?.length === 0) {
    return (
      <PopoverContainer left={x} top={y}>
        <SuggestionContainer>No suggestions, try typing more</SuggestionContainer>
      </PopoverContainer>
    );
  }

  return (
    <PopoverContainer left={x} top={y}>
      {suggestions?.map((suggestion, i) => {
        const textLength = inputText?.length;
        let startInd = textLength - CONTEXT_LENGTH;
        if (startInd < 0) {
          startInd = 0;
        }
        let suggestionContent = (
          <>
            {inputText.length > CONTEXT_LENGTH && "..."}
            <span>{inputText.slice(startInd, textLength).trim()} </span>
            <SuggestionText>{suggestion?.text}</SuggestionText>
          </>
        );
        if (suggestion?.direction === "LEFT") {
          suggestionContent = (
            <>
              <SuggestionText>{suggestion?.text}</SuggestionText>
              <span> {inputText.slice(0, CONTEXT_LENGTH).trim()}</span>
              {inputText.length > CONTEXT_LENGTH && "..."}
            </>
          );
        }

        return (
          <SuggestionContainer key={i} isHighlighted={highlightedSuggestionIndex === i}>
            {suggestionContent}
          </SuggestionContainer>
        );
      })}
    </PopoverContainer>
  );
};

export default SuggestionsPopover;
