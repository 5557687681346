import { getDatasetInfoByDatasetId, patchDataset } from "api/services/dataService";
import { CenteredWithTopNavLayout } from "components/Layout";
import { CubeIcon, EditIcon, ForkIcon, HeartIcon, LicenseIcon, ReadmeIcon } from "components/ui/Icons";
import DatasetPreview from "components/views/DatasetPreview";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import NavWithTabs from "components/ui/NavWithTabs";
import DataBuiltCubesView from "components/views/DataBuiltCubesView";
import ReadmeViewerAndEditor from "components/widgets/ReadmeViewerAndEditor";
import Modal from "components/ui/Modal";
import EditDatasetAboutInfoView from "components/views/EditDatasetAboutInfoView";
import UserBadgePicOnly from "components/ui/UserBadgePicOnly";
import EngagementBadgesDataset from "components/widgets/EngagementButtonsDataset";
import { BigTitleEditable } from "components/ui/Text";
import useSearchParamsState from "hooks/useSearchParamsState";
import CreateCubeButton from "components/widgets/CreateCubeButton";
import Button from "components/ui/Button";
import CreateCubeButtonStandard from "components/widgets/CreateCubeButtonStandard";

const SplitLayout = styled.div`
  display: grid;
  grid-template-columns: 700px 300px;
  grid-gap: 10px;
`;

const SideInfoContainer = styled.div`
  overflow: auto;
  background-color: ${props => props.theme.color.closer0_5};
  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  height: max-content;
`;

const SideInfoBlock = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  margin-left: 5px;
  margin-right: 5px;
  line-height: 18px;
`;

const Tags = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  color: ${props => props.theme.color.closer2};
  font-size: 12px;
`;

const Tag = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const SideInforTitle = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
`;

const ContributorContainer = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`;

const DatasetHead = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const DatasetTitle = styled.div`
  display: flex;
  font-size: 28px;
  font-weight: bold;
`;

const DataPreviewContainer = styled.div`
  background-color: ${props => props.theme.color.closer0_5};
  position: relative;
`;

const CornerRect = styled.div`
  width: 4px;
  height: 4px;
  position: absolute;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const DataPreviewHeader = styled.div`
  padding: 0px 10px 0px 10px;
  color: ${props => props.theme.color.closer1};
  background-color: ${props => props.theme.color.closer0};
  height: 53px;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const DataPreviewBody = styled.div`
  overflow-y: auto;
  min-height: 600px;
`;

const CircleButton = styled.div`
  cursor: pointer;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 1px solid ${props => (props.isHighlighted ? props.theme.color.feature : "transparent")};
  height: 25px;
  width: 25px;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.5};

  svg {
    fill: ${props => props.theme.color.closest};
    font-size: 15px;
  }

  :hover {
    svg {
      fill: ${props => props.theme.color.primary};
    }
    border: 1px solid ${props => props.theme.color.primary};
  }
`;

const ReadmeAnchor = styled.a`
  text-decoration: none;
`;

const ReadmeGreyIcon = styled(ReadmeIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 24px;
`;

const LicenseGreyIcon = styled(LicenseIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 22px;
`;

const GreyForkIcon = styled(ForkIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 24px;
`;

const GreyHeartIcon = styled(HeartIcon)`
  fill: ${props => props.theme.color.closer2};
  width: 22px;
`;

const CreateCubeButtonLarge = styled(CreateCubeButtonStandard)`
  padding: 5px 20px;
  svg {
    fill: ${props => props.theme.color.closer2};
  }
  margin: 15px;
  margin-bottom: 25px;
`;

const DatasetPage = () => {
  const { datasetId } = useParams();

  const [datasetInfo, setDatasetInfo] = useState(null);
  const [selectedTab, setSelectedTab] = useSearchParamsState({ paramName: "selectedTab", initialValue: "About" });

  const [editedDataset, setEditedDataset] = useState(null);
  const [isSavingDataset, setIsSavingCube] = useState(false);
  const [isEditAboutModalOpen, setIsEditAboutModalOpen] = useState(false);

  const allTabs = ["About", "Preview"];

  useEffect(() => {
    datasetId && doFetchDatasetInfo();
  }, [datasetId]);

  const doFetchDatasetInfo = async () => {
    const { data } = await getDatasetInfoByDatasetId(datasetId);
    setDatasetInfo(data);
    setEditedDataset(data);
  };

  const doPatchDataset = async newDataset => {
    setIsSavingCube(true);
    const { data, error } = await patchDataset(datasetId, newDataset);
    setIsSavingCube(false);
    if (error) {
      alert(JSON.stringify(error));
      return;
    }
    setEditedDataset(data);
  };

  return (
    <>
      <CenteredWithTopNavLayout>
        <DatasetHead>
          <DatasetTitle>
            {datasetInfo?.createdBy}/{" "}
            <BigTitleEditable
              value={editedDataset?.name}
              onNewValue={newName => setEditedDataset({ ...editedDataset, name: newName })}
              valueToResetOnCancel={datasetInfo?.name}
              hasBeenEdited={editedDataset?.name !== datasetInfo?.name}
              onClickSave={() => doPatchDataset(editedDataset)}
            />
          </DatasetTitle>
          <EngagementBadgesDataset datasetId={datasetId} />
        </DatasetHead>
        <SplitLayout>
          <DataPreviewContainer>
            <DataPreviewHeader>
              <NavWithTabs
                tabNames={allTabs}
                selectedTabName={selectedTab}
                onTabSelect={newTab => setSelectedTab(newTab)}
              />
            </DataPreviewHeader>
            <DataPreviewBody>
              {selectedTab === "Preview" && (
                <DatasetPreview
                  datasetId={datasetId}
                  dataProcessingConfig={null}
                  isShowingTopButtons={false}
                  isShowingTitle={false}
                  isDatasetEditable
                />
              )}
              <ReadmeAnchor id="readme" />
              {selectedTab === "About" && (
                <ReadmeViewerAndEditor
                  defaultTitle={datasetInfo?.name}
                  isDisabled={isSavingDataset}
                  markdownStr={editedDataset?.readMe}
                  onClickSave={newMdStr => doPatchDataset({ readMe: newMdStr })}
                />
              )}
              {selectedTab === "Cubes" && <DataBuiltCubesView userName={datasetInfo?.createdBy} />}
            </DataPreviewBody>
            <CornerRect style={{ top: "0", left: "0" }} />
            <CornerRect style={{ top: "0", right: "0" }} />
            <CornerRect style={{ bottom: "0", left: "0" }} />
            <CornerRect style={{ bottom: "0", right: "0" }} />
          </DataPreviewContainer>
          <SideInfoContainer>
            <SideInfoBlock>
              <SideInforTitle>
                About
                <CircleButton onClick={() => setIsEditAboutModalOpen(true)}>
                  <EditIcon />
                </CircleButton>
              </SideInforTitle>
              {editedDataset?.description || "No description yet"}
              <Tags>
                <ReadmeAnchor href="#readme">
                  <Tag>
                    <ReadmeGreyIcon /> Readme
                  </Tag>
                </ReadmeAnchor>
                <Tag>
                  <LicenseGreyIcon /> {editedDataset?.license}
                </Tag>
                <Tag>
                  <GreyHeartIcon /> 0 Likes
                </Tag>
                <Tag>
                  <GreyForkIcon /> 0 Forks
                </Tag>
              </Tags>
            </SideInfoBlock>
            <SideInfoBlock>
              <SideInforTitle>Author</SideInforTitle>
              <ContributorContainer>
                <UserBadgePicOnly userId={datasetInfo?.createdBy} />
              </ContributorContainer>
            </SideInfoBlock>
            <CreateCubeButtonLarge datasetId={datasetId} datasetTitle={datasetInfo?.name} isHelpTextDisabled />
          </SideInfoContainer>
          <Modal
            title="Edit dataset About"
            open={isEditAboutModalOpen}
            handleClose={() => setIsEditAboutModalOpen(false)}
          >
            <EditDatasetAboutInfoView
              isSavingDataset={isSavingDataset}
              editedDataset={editedDataset}
              onClickSaveNewDataset={doPatchDataset}
            />
          </Modal>
        </SplitLayout>
      </CenteredWithTopNavLayout>
    </>
  );
};

export default DatasetPage;
