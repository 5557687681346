import styled from "styled-components";
import { useRef, useEffect, useState } from "react";
import { AnnotationLabelInput } from "components/widgets/ImageTrueSizeAnnotationEditor/annotationInputs";
const getScaleFactor = (dataWidth, dataHeight, containerWidth, containerHeight) => {
  if (dataHeight > dataWidth) {
    return containerHeight / dataHeight;
  }
  return containerWidth / dataWidth;
};

const ImageContainer = styled.div`
  position: relative;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  ${({ dataWidth, dataHeight, width, height, scaleFactor, noCentering }) => {
    if (noCentering) {
      return;
    }
    if (dataWidth > dataHeight) {
      return `
        transform: translateY(${height / 2 - (dataHeight * scaleFactor) / 2}px);
      `;
    }
    return `transform: translateX(${width / 2 - (dataWidth * scaleFactor) / 2}px)`;
  }}
`;

const CenteringWrapper = styled.div`
  overflow: hidden;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
  display: grid;
  justify-content: center;
`;

const Canvas = styled.canvas`
  transform-origin: top left;
  transform: scale(${props => props.scaleFactor});
  image-rendering: pixelated;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  opacity: 0.5;
`;

const OcrAnnoCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
  transform: scale(${props => props.scaleFactor});
  image-rendering: pixelated;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
`;

const LabelCanvas = styled(Canvas)`
  position: absolute;
  top: 0;
  left: 0;
  background-image: none;
  pointer-events: none;
  opacity: 0.5;
`;

const RectCanvas = styled(LabelCanvas)``;

const ImageWithOCRAnnotationsAndHumanAnnottions = ({
  width = 90,
  height = 90,
  base64Data,
  annotations = [],
  humanAnnotations = [],
  doPatchHumanAnnotations,
  onNewHumanAnnotations,
  className,
}) => {
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const annoCanvasRef = useRef(null);
  const rectCanvasRef = useRef(null);

  const [dataWidth, setDataWidth] = useState(1);
  const [dataHeight, setDataHeight] = useState(1);
  const [isMouseDown, setIsMouseDown] = useState(false);

  const [topLeft, setTopLeft] = useState({ x: 0, y: 0 });
  const [rectWidth, setRectWidth] = useState(0);
  const [rectHeight, setRectHeight] = useState(0);

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.current?.width, canvasRef.current?.height);

    const image = new Image();
    image.onload = () => {
      setDataWidth(image.width);
      setDataHeight(image.height);
      ctx.drawImage(image, 0, 0, image.width, image.height);
    };
    image.src = `data:image/png;base64,${base64Data}`;
  }, [base64Data]);

  const drawAnnotations = () => {
    const annoCtx = annoCanvasRef.current.getContext("2d");
    annoCtx.clearRect(0, 0, annoCanvasRef.current?.width, annoCanvasRef.current?.height);

    annoCtx.fillStyle = "#00C85355";
    annotations.forEach(annotation => {
      annoCtx.fillRect(annotation?.x, annotation?.y, annotation?.w, annotation?.h);
    });
  };

  const getXandYOfEvent = e => {
    const canvasContainerRect = canvasContainerRef.current.getBoundingClientRect();
    const containerX = e.clientX - canvasContainerRect.left;
    const containerY = e.clientY - canvasContainerRect.top;

    const x = Math.floor(containerX / scaleFactor);
    const y = Math.floor(containerY / scaleFactor);
    return [x, y];
  };

  useEffect(() => {
    const rectCtx = rectCanvasRef.current.getContext("2d");
    rectCtx.clearRect(0, 0, dataWidth, dataHeight);
    rectCtx.fillStyle = "rgb(1, 145, 255)";
    rectCtx.fillRect(topLeft.x, topLeft.y, rectWidth, rectHeight);
  }, [topLeft, rectWidth, rectHeight]);

  useEffect(() => {
    drawAnnotations();
  }, [annotations, annoCanvasRef?.current, dataWidth, dataHeight, base64Data]);

  const scaleFactor = getScaleFactor(dataWidth, dataHeight, width, height);

  console.log(humanAnnotations);

  return (
    <CenteringWrapper className={className}>
      <ImageContainer
        dataWidth={dataWidth}
        dataHeight={dataHeight}
        width={width}
        height={height}
        scaleFactor={scaleFactor}
        ref={canvasContainerRef}
      >
        <OcrAnnoCanvas ref={annoCanvasRef} scaleFactor={scaleFactor} width={dataWidth} height={dataHeight} />
        <Canvas
          ref={canvasRef}
          scaleFactor={scaleFactor}
          width={dataWidth}
          height={dataHeight}
          onMouseDown={e => {
            setIsMouseDown(true);
            const [x, y] = getXandYOfEvent(e);
            console.log(x, y);
            setTopLeft({ x, y });
          }}
          onMouseMove={e => {
            if (!isMouseDown) {
              return;
            }
            const [x1, y1] = getXandYOfEvent(e);
            setRectWidth(x1 - topLeft.x);
            setRectHeight(y1 - topLeft.y);
          }}
          onMouseUp={() => {
            setIsMouseDown(false);

            const newHumanAnnotation = { x: topLeft.x, y: topLeft.y, w: rectWidth, h: rectHeight };
            rectWidth > 0 && rectHeight > 0 && onNewHumanAnnotations([...humanAnnotations, newHumanAnnotation]);

            setRectWidth(0);
            setRectHeight(0);
          }}
        />

        <RectCanvas ref={rectCanvasRef} scaleFactor={scaleFactor} width={dataWidth} height={dataHeight} />
        {humanAnnotations?.map((annotation, annotationIndex) => {
          return (
            <AnnotationLabelInput
              key={annotationIndex}
              value={annotation?.label || ""}
              onChange={e => {
                const newLabel = e.target.value;
                const newAnnotations = humanAnnotations.map((a, i) => {
                  if (i === annotationIndex) {
                    return { ...a, label: newLabel };
                  }
                  return a;
                });
                onNewHumanAnnotations(newAnnotations);
              }}
              pixelX={annotation?.x * scaleFactor}
              pixelY={annotation?.y * scaleFactor}
              pixelW={annotation?.w * scaleFactor}
              pixelH={annotation?.h * scaleFactor}
              onBlur={() => doPatchHumanAnnotations(humanAnnotations)}
              onClickClose={async () => {
                const newAnnotations = humanAnnotations.filter((_, i) => i !== annotationIndex);
                onNewHumanAnnotations(newAnnotations);
                doPatchHumanAnnotations(newAnnotations);
              }}
            />
          );
        })}
      </ImageContainer>
    </CenteringWrapper>
  );
};

export default ImageWithOCRAnnotationsAndHumanAnnottions;
