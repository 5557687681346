import { CircularProgress } from "@material-ui/core";
import { AssignmentTurnedInOutlined, KeyboardVoice } from "@material-ui/icons";
import { getLoggedInUserName } from "api/services/authenticationService";
import { lightTheme } from "App";
import { CenteredWithTopNavLayout, Gap, UnLoggedInCenteredWithTopNavLayout } from "components/Layout";
import Button from "components/ui/Button";
import CustomCodeMirror from "components/ui/CustomCodeMirror";
import { BigTitle } from "components/ui/Text";
import ImportDataView from "components/views/application/BookSearch/ImportDataView";
import TrainingView from "components/views/application/BookSearch/TrainingView";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { sleep } from "utils/common";

const StepsContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`;

const StepButton = styled.div`
  width: 600px;
  text-align: center;
  white-space: nowrap;
  font-size: 18px;
  padding: 10px 40px;
  background-color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer0)};
  color: ${props => (props.isSelected ? props.theme.color.furthest : "auto")};
  border-radius: 10px;
  cursor: pointer;
  :hover {
    background-color: ${props => (props.isSelected ? "auto" : props.theme.color.closer1)};
  }
`;

const Input = styled.textarea`
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.04);
  font-family: "Montserrat";
  width: 100%;
  height: 100%;
  background-color: white;
  border-bottom: none;
  color: black;
  border: 1px solid ${props => props.theme.color.closer1};
  padding: 10px;
  border-radius: 10px;
  :focus {
    border-color: ${props => props.theme.color.primary};
  }
  transition: border-color 0.2s;
`;

const TextToCodeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
`;

const GenerateButton = styled(Button)`
  align-self: center;
`;

const CodeMirrorContainer = styled.div`
  ${props => props.isDisabled && `opacity: 0.5;`}
`;

const CodeMirroPlaceHolder = styled.div`
  heigth: 300px;
  background-color: ${props => props.theme.color.closer1};
  border-radius: 10px;
  padding: 100px;
`;

const twoButtonsCode = `<body>
    <style>
        .container {
            display: flex;
            gap: 10px;
            padding: 10px;
        }
    </style>

    <div class="container">  
        <button class="button">Button 1</button>
        <button class="button">Button 2</button>
    </div>
</body>`;

const InputAreaContainer = styled.div`
  position: relative;
  height: 288px;
`;

const MicContainer = styled.div`
  color: ${props => (props.isRecording ? props.theme.color.error : props.theme.color.closest)};
  transition: color 0.2s;
  position: absolute;
  bottom: 10px;
  left: 5px;
  padding: 5px;
  cursor: pointer;
`;

const exampleInput = "two buttons side by side";

const TextToCodeView = () => {
  const [userInput, setUserInput] = useState("");
  const [code, setCode] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const [letterIndex, setLetterIndex] = useState(-1);

  useEffect(() => {
    if (!isRecording || letterIndex === -1) {
      return;
    }

    if (letterIndex === exampleInput.length) {
      return;
    }

    setTimeout(() => {
      setUserInput(userInput + exampleInput[letterIndex]);
      setLetterIndex(letterIndex + 1);
    }, 150);
  }, [isRecording, letterIndex]);

  useEffect(() => {
    setCode(null);
  }, [userInput]);

  return (
    <TextToCodeContainer>
      <InputAreaContainer>
        <Input
          placeholder="What do you want to build?"
          value={userInput}
          onChange={e => setUserInput(e.target.value)}
        />
        <MicContainer
          isRecording={isRecording}
          onClick={async () => {
            setIsRecording(!isRecording);
            await sleep(500);
            setLetterIndex(0);
          }}
        >
          <KeyboardVoice />
        </MicContainer>
      </InputAreaContainer>
      <GenerateButton
        isDisabled={isLoading}
        value="Generate HTML + CSS"
        onClick={() => {
          setIsLoading(true);
          setTimeout(() => {
            setCode(twoButtonsCode);
            setIsLoading(false);
          }, 500);
        }}
      />
      {code ? (
        <CodeMirrorContainer isDisabled={isLoading}>
          <CustomCodeMirror isDisabled={isLoading} value={code} height="300px" />
        </CodeMirrorContainer>
      ) : (
        <CodeMirroPlaceHolder>
          {isLoading && <CircularProgress size={50} style={{ color: lightTheme.color.primary }} />}
        </CodeMirroPlaceHolder>
      )}
    </TextToCodeContainer>
  );
};

const Steps = ({ stepNames, onStepSelect, selectedStep, latestStep }) => {
  return (
    <StepsContainer>
      {stepNames.map((stepName, i) => (
        <StepButton key={stepName} onClick={() => onStepSelect(stepName)} isSelected={stepName === selectedStep}>
          {stepName}
          <AssignmentTurnedInOutlined />
        </StepButton>
      ))}
    </StepsContainer>
  );
};

const Text2CodeGeneration = () => {
  const [selectedStep, setSelectedStep] = useState("Deployment review");
  const loggedInUserName = getLoggedInUserName();

  return loggedInUserName ? (
    <CenteredWithTopNavLayout>
      <BigTitle>{"Text to code generation"}</BigTitle>
      <Gap />
      {/* <Steps
        stepNames={["Import data", "Train", "Deployment review"]}
        latestStep={"Train"}
        selectedStep={selectedStep}
        onStepSelect={newStep => setSelectedStep(newStep)}
      />
      <Gap /> */}
      {selectedStep === "Import data" && <ImportDataView />}
      {selectedStep === "Train" && <TrainingView />}
      {selectedStep === "Deployment review" && <TextToCodeView />}
    </CenteredWithTopNavLayout>
  ) : (
    <UnLoggedInCenteredWithTopNavLayout>
      <BigTitle>{"Text to code generation"}</BigTitle>
      <Gap />
      <TextToCodeView />
    </UnLoggedInCenteredWithTopNavLayout>
  );
};

export default Text2CodeGeneration;
