import { useState, useEffect } from "react";

import { getSolutionMetrics } from "api/services/projectService";

const usePollSolutionMetrics = (solutionId, startDate, endDate, intervalSizeMinutes) => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateMetrics();
    const intervalId = setInterval(doPopulateMetrics, 2000);
    return () => clearInterval(intervalId);
  }, [solutionId, startDate?.toString(), endDate?.toString(), intervalSizeMinutes]);

  const doPopulateMetrics = async () => {
    const { data, error } = await getSolutionMetrics(solutionId, {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      intervalSizeMinutes,
    });
    setError(error);
    setMetrics(data);
    setIsLoading(false);
  };

  return [metrics, isLoading, error];
};

export default usePollSolutionMetrics;
