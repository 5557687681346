import { useState, useEffect } from "react";
import styled from "styled-components";

import { getTrainingJobById } from "api/services/jobService";
import TrainingJobLineChartMultiLine from "components/widgets/TrainingJobLineChartMultiLine";

const Container = styled.div`
  height: 500px;
`;

const MultiLineTrainingProgressView = ({ jobIds, lineIdColorMap, highlightedJobId }) => {
  const [highlightedJobWorkerId, setHighlightedJobWorkerId] = useState(null);

  useEffect(() => {
    process.env.REACT_APP_IS_RESEARCH && highlightedJobId && doGetHighlightedJobWorkerId();
  }, [highlightedJobId]);

  const doGetHighlightedJobWorkerId = async () => {
    const { data, error } = await getTrainingJobById(highlightedJobId);
    if (error) {
      return;
    }
    setHighlightedJobWorkerId(data.workerId);
  };

  // const [iterationPoints, error] = useTrainingProgressForJobIds(jobIds);
  const iterationPoints = null;
  const error = null;

  if (error) {
    return `Error fetching training data: ${JSON.stringify(error)}`;
  }

  if (iterationPoints?.length === 0) {
    return "No training data available";
  }

  return (
    <Container>
      {process.env.REACT_APP_IS_RESEARCH && (
        <div>
          Job Id: {highlightedJobId}, Worker Id: {highlightedJobWorkerId}
        </div>
      )}
      {iterationPoints?.length > 0 && (
        <TrainingJobLineChartMultiLine
          highlightedJobId={highlightedJobId}
          data={iterationPoints}
          lineIds={jobIds}
          lineIdColorMap={lineIdColorMap}
        />
      )}
    </Container>
  );
};

export default MultiLineTrainingProgressView;
