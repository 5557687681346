import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Edit, Visibility, VisibilityOff } from "@material-ui/icons";

import { getSampleDataPointFromDeployment, getDataConfigFromDeployment } from "api/services/deploymentService";
import Button from "components/ui/Button";
import SelectInput from "components/ui/SelectInput";

const InputPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  border-top: 1px solid ${props => props.theme.color.closer1_5};
  background-color: ${props => props.theme.color.closer1};
`;

const ButtonsContainer = styled.div``;

const ImageAndLabelContainer = styled.div`
  justify-self: center;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const LabelSelectInput = styled(SelectInput)`
  width: 150px;
`;

const GetTestImageButton = styled(Button)`
  margin: 0;
`;

const ImageContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.1; pointer-events: none;"}
  position: relative;
  background-color: aliceblue;
  width: 90px;
  height: 90px;
`;

const PencilContainer = styled.div`
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
  width: max-content;
  padding: 2px;
  background-color: white;
  border: 1px solid ${props => props.theme.color.primary};
  border-radius: 20px;
`;

const Canvas = styled.canvas`
  transform-origin: top left;
  transform: scale(calc(90 / 28));
  image-rendering: pixelated;
  cursor: pointer;
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 5px 5px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

const EyeContainer = styled.div`
  opacity: ${props => (props.isEnabled ? 1 : 0)};
`;

const EyesContainer = styled.div`
  display: flex;
`;

const EyesAndInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputImagePanel = ({
  inputBase64Data,
  inputLabel,
  onSetNewBase64Data,
  onSetNewLabel,
  isImgEditable,
  isImgInputDisabled,
  isLabelInputDisabled,
  areEyesShowing,
  isLabelVisible,
  onSetIsLabelVisible,
  deploymentClusterId,
}) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [labelOptions, setLabelOptions] = useState([]);

  const canvasRef = useRef(null);
  const canvasContainerRef = useRef(null);

  useEffect(() => {
    doFetchTestInput();
    doFetchAllLabelOptions();
  }, []);

  useEffect(() => {
    resetCanvas();
  }, [inputBase64Data]);

  const doFetchTestInput = async () => {
    const { data } = await getSampleDataPointFromDeployment(deploymentClusterId);
    const { image, label } = data;
    onSetNewBase64Data(image);
    onSetNewLabel(label);
  };

  const doFetchAllLabelOptions = async () => {
    const { data } = await getDataConfigFromDeployment(deploymentClusterId);
    const labels = data?.featureTypeDescriptors.find(d => d.key === "label")?.type.categories;
    setLabelOptions(labels);
  };

  const resetCanvas = () => {
    const ctx = canvasRef.current.getContext("2d");

    const image = new Image();
    image.onload = () => ctx.drawImage(image, 0, 0, 28, 28);
    image.src = `data:image/png;base64,${inputBase64Data}`;
  };

  const clearRectAtMouseEventPosition = e => {
    const canvasContainerRect = canvasContainerRef.current.getBoundingClientRect();
    const containerX = e.clientX - canvasContainerRect.left;
    const containerY = e.clientY - canvasContainerRect.top;

    const x = (28 / 90) * containerX;
    const y = (28 / 90) * containerY;

    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(x, y, 2, 2);
  };

  return (
    <InputPanel>
      <ButtonsContainer>
        <GetTestImageButton value="Get test image" onClick={doFetchTestInput} />
      </ButtonsContainer>
      <EyesAndInputsContainer>
        <EyesContainer>
          <div style={{ width: "90px" }} />
          <EyeContainer onClick={() => onSetIsLabelVisible(!isLabelVisible)} isEnabled={areEyesShowing}>
            {isLabelVisible ? <Visibility /> : <VisibilityOff />}
          </EyeContainer>
        </EyesContainer>
        <ImageAndLabelContainer>
          <ImageContainer
            isDisabled={isImgInputDisabled}
            ref={canvasContainerRef}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseUp={() => {
              setIsMouseDown(false);
              const userCorruptedImage = canvasRef.current.toDataURL().split(",")[1];
              onSetNewBase64Data(userCorruptedImage);
            }}
            onMouseLeave={() => setIsMouseDown(false)}
            onMouseMove={e => isMouseDown && clearRectAtMouseEventPosition(e)}
          >
            {isImgEditable && !isImgInputDisabled && (
              <PencilContainer>
                <Edit />
              </PencilContainer>
            )}
            <Canvas ref={canvasRef} onClick={clearRectAtMouseEventPosition} width="28" height="28" />
          </ImageContainer>
          <LabelSelectInput
            value={inputLabel}
            isDisabled={isLabelInputDisabled || !isLabelVisible}
            onSetNewValue={newVal => onSetNewLabel(newVal)}
            fontSize="18px"
          >
            {labelOptions?.map(label => (
              <option key={label} value={label}>
                {label}
              </option>
            ))}
          </LabelSelectInput>
        </ImageAndLabelContainer>
      </EyesAndInputsContainer>
    </InputPanel>
  );
};

export default InputImagePanel;
