export const CrossIcon = () => (
  <svg viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 54L54 4M4 4L54 54" stroke="black" strokeWidth="10" />
  </svg>
);

export const SidebarNodeIcon = () => (
  <svg viewBox="0 0 164 210">
    <rect x="57" y="33.61" width="50" height="176.39" rx="25" fill="#9C9C9C" />
    <path
      d="M71.1 22h-2.27L60.24 8.85V22h-2.26V4.94h2.26l8.62 13.2V4.94h2.23V22Zm3.08-6.46c0-1.24.24-2.36.72-3.35.5-1 1.17-1.76 2.04-2.3.88-.53 1.87-.8 2.99-.8 1.73 0 3.12.6 4.18 1.79a6.88 6.88 0 0 1 1.6 4.77v.15c0 1.24-.23 2.34-.7 3.33a5.34 5.34 0 0 1-5.06 3.1c-1.72 0-3.11-.6-4.18-1.79a6.88 6.88 0 0 1-1.6-4.74v-.16Zm2.18.26c0 1.4.32 2.54.97 3.39a3.15 3.15 0 0 0 2.62 1.27c1.1 0 1.98-.42 2.63-1.28.65-.87.97-2.08.97-3.64 0-1.39-.33-2.51-1-3.37a3.13 3.13 0 0 0-2.62-1.3c-1.07 0-1.93.42-2.59 1.27-.66.86-.98 2.07-.98 3.66Zm11.55-.25c0-1.94.46-3.5 1.38-4.68a4.37 4.37 0 0 1 3.62-1.78c1.49 0 2.66.5 3.53 1.52V4h2.17v18h-2l-.1-1.36a4.42 4.42 0 0 1-3.62 1.6c-1.47 0-2.67-.6-3.6-1.81a7.53 7.53 0 0 1-1.38-4.71v-.17Zm2.17.25c0 1.44.3 2.56.89 3.38.6.8 1.41 1.21 2.46 1.21 1.37 0 2.38-.61 3.01-1.85v-5.82a3.19 3.19 0 0 0-2.99-1.8 2.9 2.9 0 0 0-2.48 1.24c-.6.82-.9 2.03-.9 3.64Zm17.17 6.43a5.55 5.55 0 0 1-4.2-1.68 6.3 6.3 0 0 1-1.62-4.54v-.4c0-1.25.24-2.38.72-3.36.48-1 1.16-1.77 2.01-2.32.87-.56 1.8-.84 2.82-.84 1.64 0 2.93.54 3.84 1.62.91 1.1 1.37 2.65 1.37 4.67v.9h-8.59c.03 1.25.4 2.26 1.1 3.04.7.76 1.58 1.14 2.66 1.14.77 0 1.42-.15 1.95-.46.53-.32 1-.73 1.4-1.25l1.32 1.04a5.32 5.32 0 0 1-4.78 2.44Zm-.27-11.36c-.88 0-1.61.32-2.2.96-.6.63-.97 1.52-1.1 2.67h6.34v-.16a4.1 4.1 0 0 0-.89-2.56 2.71 2.71 0 0 0-2.15-.91ZM73.58 111.66h-1.31v11.43h1.3v1.04H71v-13.5h2.58v1.03Zm5.76 2.94.05.93a2.7 2.7 0 0 1 2.21-1.06c1.56 0 2.35.88 2.37 2.64V122H82.7v-4.9c0-.53-.13-.92-.37-1.18-.23-.25-.6-.38-1.11-.38-.41 0-.77.1-1.08.33-.31.22-.55.5-.73.86V122h-1.26v-7.4h1.2Zm9.11-3.97h2.58v13.5h-2.58v-1.04h1.32v-11.43h-1.32v-1.03Z"
      fill="#000"
    />
    <path
      d="M165.77 118.47a2.5 2.5 0 0 0 0-3.53l-15.91-15.91a2.5 2.5 0 1 0-3.54 3.54l14.14 14.14-14.14 14.14a2.5 2.5 0 1 0 3.54 3.54l15.9-15.92Zm-50.77 1.1c14.07 0 19.57-.09 24.94-.18 5.35-.1 10.56-.18 24.06-.18v-5c-13.54 0-18.77.09-24.14.18-5.35.1-10.82.18-24.86.18v5ZM50.77 118.47a2.5 2.5 0 0 0 0-3.53L34.86 99.03a2.5 2.5 0 0 0-3.54 3.54l14.14 14.14-14.14 14.14a2.5 2.5 0 1 0 3.54 3.54l15.9-15.92ZM0 119.57c14.07 0 19.57-.09 24.94-.18 5.35-.1 10.56-.18 24.06-.18v-5c-13.54 0-18.77.09-24.14.18-5.35.1-10.82.18-24.86.18v5Z"
      fill="#C9C9C9"
    />
  </svg>
);