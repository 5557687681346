import { useState, useEffect } from "react";

import { getApiConfigMetrics } from "api/services/projectService";

const usePollApiConfigMetrics = (apiConfigId, startDate, endDate, intervalSizeMinutes) => {
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    doPopulateMetrics();
    const intervalId = setInterval(doPopulateMetrics, 2000);
    return () => clearInterval(intervalId);
  }, [apiConfigId, startDate?.toString(), endDate?.toString(), intervalSizeMinutes]);

  const doPopulateMetrics = async () => {
    const { data, error } = await getApiConfigMetrics(apiConfigId, {
      start: startDate.toISOString(),
      end: endDate.toISOString(),
      intervalSizeMinutes,
    });
    setError(error);
    setMetrics(data);
    setIsLoading(false);
  };

  return [metrics, isLoading, error];
};

export default usePollApiConfigMetrics;
