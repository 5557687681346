import { useState } from "react";
import Modal from "components/ui/Modal";
import styled from "styled-components";
import SelectInput from "components/ui/SelectInput";
import Button from "components/ui/Button";

const ModalContent = styled.div`
  overflow: scroll;
  padding: 20px;
  width: 700px;
  height: 200px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  justify-content: center;
`;

const ResourceModal = ({ open, handleClose, resourceType, onResourceTypeSelect, onConfirm }) => {
  const [resourceTypes] = useState(["accounts"]);

  return (
    <Modal handleClose={handleClose} open={open} title={"Salesforce Resources Integration"}>
      <ModalContent>
        <SelectInput
          title="Select a resource type"
          value={resourceType}
          onSetNewValue={newResourceType => onResourceTypeSelect(newResourceType)}
        >
          {resourceTypes.map(type => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </SelectInput>
        <ButtonContainer>
          <Button value={"Confirm"} onClick={onConfirm} />
        </ButtonContainer>
      </ModalContent>
    </Modal>
  );
};

export default ResourceModal;
