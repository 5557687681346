import styled from "styled-components";
import { useState } from "react";
import Button from "components/ui/Button";

import { getLabelPredictionForText } from "api/services/deploymentService";
import ApiCallAndMonitorsModals from "components/views/DeploymentTabViewNoTasks/ImageView/ImageDataEnrichmentView/ApiCallAndMontiorModals";
import TaskContainer from "./TaskContainer";
import EmbeddingGridView from "components/widgets/EmbeddingGridView";

const PredictButton = styled(Button)`
  margin: 0;
  padding: 5px 20px;
  min-width: 0;
`;

const TitleText = styled.div`
  color: ${props => props.theme.color.closest};
`;

const LabelAndModals = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  font-size: 22px;
`;

const ButtonAndResultsContainer = styled.div`
  ${props => props.isDisabled && "opacity: 0.2; pointer-events: none;"}
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const PredictedLabel = styled.div`
  padding: 10px;
  font-size: 18px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const EmbeddingGridLoading = styled.div`
  width: 342px;
  height: 72px;
  background-color: ${props => props.theme.color.closer0};
  border-radius: 5px;
`;

const getInputTextWithMissing = (inputTxtSrc, missingWordsIndices) => {
  if (!inputTxtSrc) {
    return inputTxtSrc;
  }
  const inputTextWithMissing = [...inputTxtSrc];
  missingWordsIndices.forEach(index => {
    inputTextWithMissing[index] = null;
  });
  return inputTextWithMissing;
};

const LabelPrediction = ({
  deploymentClusterId,
  inputTxtSrc,
  isSelected,
  onSelect,
  missingWordsIndices,
  trainingJobId,
  datasetFeatureTypeDescriptors,
  modelConfig,
}) => {
  const [predictionResult, setPredictionResult] = useState(null);
  const [isFetchingPrediction, setIsFetchingPrediction] = useState(false);
  const [error, setError] = useState(null);

  const doPredictLabel = async e => {
    e.stopPropagation();
    setPredictionResult(null);
    setIsFetchingPrediction(true);

    const { data, error } = await getLabelPredictionForText(
      deploymentClusterId,
      inputTxtSrc?.join(" "),
      "predict_text_label"
    );
    setIsFetchingPrediction(false);
    setPredictionResult(data);
    setError(error);
  };

  if (error) {
    return JSON.stringify(error);
  }

  const taskTitleWithCodeAndMonitorButtons = (
    <LabelAndModals>
      <input type="checkbox" checked={isSelected} />
      <TitleText>Label prediction</TitleText>
      <ApiCallAndMonitorsModals
        deploymentClusterId={deploymentClusterId}
        taskInput={{ sentence_text: getInputTextWithMissing(inputTxtSrc, missingWordsIndices) }}
        outputCols={["sentence_label"]}
        isEnabled={isSelected}
        modelConfig={modelConfig}
        trainingJobId={trainingJobId}
        tag="predict_text_label"
        datasetFeatureTypeDescriptors={datasetFeatureTypeDescriptors}
      />
    </LabelAndModals>
  );

  if (!isSelected) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
      </TaskContainer>
    );
  }

  if (!predictionResult) {
    return (
      <TaskContainer isSelected={isSelected} onClick={onSelect}>
        {taskTitleWithCodeAndMonitorButtons}
        <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
          <PredictButton onClick={doPredictLabel} value="Predict">
            Predict
          </PredictButton>
          <PredictedLabel>&nbsp;&nbsp;</PredictedLabel>
          <EmbeddingGridLoading />
        </ButtonAndResultsContainer>
      </TaskContainer>
    );
  }

  return (
    <TaskContainer isSelected={isSelected} onClick={onSelect}>
      {taskTitleWithCodeAndMonitorButtons}
      <ButtonAndResultsContainer isDisabled={isFetchingPrediction || !isSelected}>
        <PredictButton onClick={doPredictLabel} value="Predict">
          Predict
        </PredictButton>
        <PredictedLabel>{predictionResult.winner}</PredictedLabel>
        <EmbeddingGridView
          embeddingVec={Object.values(predictionResult.scores)}
          classLabels={Object.keys(predictionResult?.scores)}
          hideHoveredValue
        />
      </ButtonAndResultsContainer>
    </TaskContainer>
  );
};

export default LabelPrediction;
