import { useEffect, useState } from "react";

import { getRepoSlugOfCubeIfExists } from "api/services/githubService";
import RepoFileListView from "components/widgets/RepoFileListView";
import CubeFileList from "components/widgets/CubeFileList";
import CubeReadmeViewer from "components/widgets/CubeReadmeViewer";
import ReadmeViewer from "components/widgets/ReadmeViewer";

const CubeFileListView = ({ cubeId, pipelineId }) => {
  const [repoSlug, setRepoSlug] = useState("");

  useEffect(() => {
    doPopulateRepoSlug();
  }, [cubeId]);

  const doPopulateRepoSlug = async () => {
    const { data: repoSlug } = await getRepoSlugOfCubeIfExists(cubeId);
    setRepoSlug(repoSlug);
  };

  if (repoSlug) {
    return (
      <>
        <RepoFileListView repoSlug={repoSlug} />
        <ReadmeViewer repoSlug={repoSlug} branchName="main" />
      </>
    );
  }

  return (
    <>
      <CubeFileList pipelineId={pipelineId} />
      <CubeReadmeViewer pipelineId={pipelineId} />
    </>
  );
};

export default CubeFileListView;
