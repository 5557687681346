import styled from "styled-components";
import NavHeader from "components/widgets/NavHeader";

const CenteredContentContainer = styled.div`
  margin-top: 150px;
  height: auto;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`;

const TopBar = styled.div`
  position: fixed;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(8, 8, 96, 0.3), rgba(10, 10, 10, 0.5)), url("/images/market-place/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 50px;
  z-index: 1;
`;

const Logo = styled.img`
  position: fixed;
  top: 20px;
  left: 20px;
  width: 50px;
  z-index: 2;
`;

const TopBarLayout = ({ children }) => {
  return (
    <Container>
      <a href={"/market-place"}>
        <Logo src={"/images/logo-white.png"} />
      </a>
      <NavHeader hasStickySearchBar={false} bgColor={"transparent"} />
      <TopBar />
      <CenteredContentContainer>{children}</CenteredContentContainer>
    </Container>
  );
};

export default TopBarLayout;
