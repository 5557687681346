import styled from "styled-components";
import { useState } from "react";
import { Close, Done } from "@material-ui/icons";
import { getColorFromString } from "utils/common";

const CloseButtonContainer = styled.div`
  cursor: pointer;
  width: max-content;
  height: max-content;
  background-color: ${props => props.theme.color.furthest};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  transition: opacity 0.2s;
  opacity: 0;
`;

const LabelInputContainer = styled.div`
  position: absolute;
  top: ${props => props.pixelY}px;
  left: ${props => props.pixelX}px;
  width: ${props => props.pixelW}px;
  height: ${props => props.pixelH}px;
  :hover {
    ${CloseButtonContainer} {
      opacity: 1;
    }
  }
`;

const InputAndCloseButtonContainer = styled.div`
  position: absolute;
  display: flex;
  top: -20px;
`;

const MaskRectangle = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor || props.theme.color.primary};
  border: 1px solid ${props => props.theme.color.closest};
  opacity: 0.5;
`;

const LabelInput = styled.input`
  border: 1px solid ${props => props.theme.color.closer2};
  border-bottom: none;
  height: 20px;
  padding: 0;
`;

const SelectInput = styled.select`
  position: absolute;
  top: -20px;
  width: 60px;
  border: none;
  height: 20px;
  padding: 0;
`;

const SuggestionCloseButtonContainer = styled(CloseButtonContainer)`
  left: 80px;
  opacity: 1;
`;

const AcceptButtonContainer = styled(SuggestionCloseButtonContainer)`
  left: 60px;
`;

export const AnnotationLabelInput = ({ value, onChange, onBlur, pixelX, pixelY, pixelW, pixelH, onClickClose }) => {
  const [backgroundColor, setBackgroundColor] = useState(getColorFromString(value));

  return (
    <LabelInputContainer pixelX={pixelX} pixelY={pixelY} pixelW={pixelW} pixelH={pixelH}>
      <InputAndCloseButtonContainer>
        <LabelInput
          value={value}
          size={value?.length + 2}
          onChange={onChange}
          onBlur={() => {
            setBackgroundColor(getColorFromString(value));
            onBlur();
          }}
        />
        <CloseButtonContainer onClick={onClickClose} backgroundColor={backgroundColor}>
          <Close fontSize="small" />
        </CloseButtonContainer>{" "}
      </InputAndCloseButtonContainer>

      <MaskRectangle backgroundColor={backgroundColor} />
    </LabelInputContainer>
  );
};

export const SuggestedAnnotation = ({ x, y, w, h, scaleFactor, labels, onClickClose, onClickAccept }) => {
  const [selectedLabel, setSelectedLabel] = useState(labels?.[0] || "");
  const backgroundColor = getColorFromString(selectedLabel);
  return (
    <LabelInputContainer
      pixelX={x * scaleFactor}
      pixelY={y * scaleFactor}
      pixelW={w * scaleFactor}
      pixelH={h * scaleFactor}
    >
      <SelectInput value={selectedLabel} onChange={e => setSelectedLabel(e.target.value)}>
        {labels?.map(label => (
          <option key={label} value={label}>
            {label}
          </option>
        ))}
      </SelectInput>

      <AcceptButtonContainer
        onClick={() => {
          const newAnnotation = { x, y, w, h, label: selectedLabel };
          onClickAccept(newAnnotation);
        }}
        backgroundColor={backgroundColor}
      >
        <Done fontSize="small" />
      </AcceptButtonContainer>
      <SuggestionCloseButtonContainer onClick={onClickClose} backgroundColor={backgroundColor}>
        <Close fontSize="small" />
      </SuggestionCloseButtonContainer>
      <MaskRectangle backgroundColor={backgroundColor} />
    </LabelInputContainer>
  );
};
