import { useEffect, useState } from "react";
import styled from "styled-components";

import { DatasetCard } from "components/ui/cards";
import { getAllVisibleDatasets } from "api/services/dataService";
import { getLoggedInUserName } from "api/services/authenticationService";
import usePagination from "components/navigation/pagination/usePagination";
import PaginationPagesButtons from "components/navigation/pagination/PaginationPagesButtons";
import PageSizeSelect from "components/navigation/pagination/PageSizeSelect";
import { by } from "utils/common";
import FilterTag from "components/ui/FilterTag";
import TextInput from "components/ui/TextInput";

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  gap: 10px;
  min-height: calc(100 * ${props => props.pageSize / 2}px + 10 * ${props => props.pageSize / 2 - 1}px);
`;

const FilterTagsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  padding-bottom: 20px;
`;

const FiltersAndPageSize = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
`;

const FilterTextInput = styled(TextInput)`
  padding: 7px 10px;
`;

const DataListView = () => {
  const [datasets, setDatasets] = useState([]);
  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFilteringMyDatasets, setIsFilteringMyDatsets] = useState(true);
  const [textToFilterBy, setTextToFilterBy] = useState("");

  const loggedInUserName = getLoggedInUserName();

  const sortedAndFilteredDatasets = datasets
    .sort(by("createdAt", "desc"))
    .filter(dataset => dataset?.name?.includes(textToFilterBy))
    .filter(dataset => {
      if (!isFilteringMyDatasets) {
        return true;
      }
      return dataset.createdBy === loggedInUserName;
    });

  const [startInd, endInd, numPages, pageSize, setPageSize, setPageNum] = usePagination(
    sortedAndFilteredDatasets.length
  );

  const doFetchDatasets = async () => {
    setIsLoading(true);
    const { data, error } = await getAllVisibleDatasets();
    setDatasets(data.filter(e => e.name !== "go"));
    setError(error);
    setIsLoading(false);
  };

  useEffect(() => {
    doFetchDatasets();
  }, []);

  if (isLoading) {
    return "Loading datasets...";
  }

  if (error) {
    return JSON.stringify(error);
  }

  return (
    <>
      <FiltersAndPageSize>
        <FilterTagsContainer>
          <FilterTag isActive={isFilteringMyDatasets} onToggle={() => setIsFilteringMyDatsets(!isFilteringMyDatasets)}>
            Created by me
          </FilterTag>
          <FilterTextInput
            value={textToFilterBy}
            placeholder="Filter by Name"
            onNewInput={newText => setTextToFilterBy(newText)}
          />
        </FilterTagsContainer>
        <PageSizeSelect pageSize={pageSize} onSelectNewPageSize={newPageSize => setPageSize(newPageSize)} />
      </FiltersAndPageSize>

      <CardsContainer pageSize={pageSize}>
        {sortedAndFilteredDatasets.slice(startInd, endInd).map(dataset => (
          <DatasetCard
            assetType="dataset"
            key={dataset?.id}
            title={dataset.name}
            subTitle={dataset.createdAt}
            createdByUserName={dataset.createdBy}
            datasetId={dataset.id}
          />
        ))}
      </CardsContainer>
      <PaginationPagesButtons
        currentPageNum={endInd / pageSize}
        numPages={numPages}
        onClickPageNum={newPageNum => setPageNum(newPageNum)}
      />
    </>
  );
};

export default DataListView;
