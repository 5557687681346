import styled from "styled-components";

const SelectorOption = styled.div`
  cursor: pointer;
  padding: 5px 15px;
  border-right: 1px solid ${props => props.theme.color.closer1_5};

  ${props =>
    props.isSelected &&
    `background-color: ${props.theme.color.closer1};
    color: ${props.theme.color.primary};`};

  :hover {
    background-color: ${props => props.theme.color.closer1};
    color: ${props => props.theme.color.primary};
  }
`;

const SelectorContainer = styled.div`
  width: max-content;
  background-color: ${props => props.theme.color.closer0};
  border: 1px solid ${props => props.theme.color.closer1_5};
  display: flex;
  align-items: center;
  height: fit-content;
  border-radius: 8px;

  margin-right: 10px;

  ${SelectorOption}:first-of-type {
    border-radius: 8px 0 0 8px;
  }

  ${SelectorOption}:last-of-type {
    border-right: none;
    border-radius: 0 8px 8px 0;
  }
`;

const MultiOptionSelector = ({ options = [], selectedOption, onOptionSelect, optionStyle }) => {
  if (options.every(option => typeof option === "string")) {
    return (
      <SelectorContainer>
        {options.map(option => (
          <SelectorOption
            key={option}
            isSelected={selectedOption === option}
            onClick={() => onOptionSelect(option)}
            style={optionStyle}
          >
            {option}
          </SelectorOption>
        ))}
      </SelectorContainer>
    );
  }

  return (
    <SelectorContainer>
      {options.map(option => (
        <SelectorOption
          key={option.value}
          isSelected={selectedOption?.value === option.value}
          onClick={() => onOptionSelect(option.value)}
          style={optionStyle}
        >
          {option.icon}
        </SelectorOption>
      ))}
    </SelectorContainer>
  );
};

export default MultiOptionSelector;
