import { useState, useEffect } from "react";
import styled from "styled-components";
import { cloneDeep } from "lodash";

import DashboardConfigVisualiser from "components/DashboardConfigVisualiser";
import usePollPredictions from "api/services/deploymentService/usePollPredictions";
import { applyQuery } from "api/services/chatService";

const Container = styled.div`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const INITIAL_CONFIG = {
  _grid: [["all_predictions"], ["latency_vs_time"]],
  all_predictions: {
    name: "All Predictions",
    type: "table",
    rows: [["id", "timestamp", "latency", "status", "inputs", "results"]],
  },
  latency_vs_time: {
    name: "Latency vs Time",
    type: "plot",
    xLabel: "time",
    yLabel: "latency",
    points: [],
  },
  total_predictions: {
    name: "Total Predictions",
    type: "single_value",
    value: 0,
  },
};

const fillUpConfigWithPredictions = (config, predictions) => {
  if (!predictions?.length) {
    return config;
  }

  if (!config?.all_predictions?.rows?.length) {
    return config;
  }

  const filledConfig = cloneDeep(config);
  predictions
    .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
    .forEach(prediction => {
      filledConfig.all_predictions.rows.push([
        prediction.id?.slice(0, 8) + "...",
        prediction.timestamp,
        prediction.latencyMs,
        prediction.status,
        JSON.stringify(prediction.inputs?.[0]),
        JSON.stringify(prediction.results?.[0]),
      ]);
      filledConfig.latency_vs_time.points.push({ x: prediction.timestamp, y: prediction.latencyMs });
    });

  return filledConfig;
};

const StyledInput = styled.input`
  border: 1px solid ${props => props.theme.color.primary};
  margin-left: 10px;
  width: 400px;
  font-family: "Montserrat", sans-serif;
  padding: 4px;
`;

const DashboardTabViewConfigurable = ({ cubeId }) => {
  const [schemaConfig, setSchemaConfig] = useState(INITIAL_CONFIG);
  const [filledConfig, setFilledConfig] = useState(INITIAL_CONFIG);
  const [isLoading, setIsLoading] = useState(false);

  const [commandStr, setCommandStr] = useState("");

  const predictions = usePollPredictions({ cubeId });

  const onKeyDown = async e => {
    if (e.key !== "Enter") {
      return;
    }

    setIsLoading(true);
    const { data, error } = await applyQuery({ query: commandStr }, schemaConfig);
    if (error) {
      return;
    }

    setCommandStr("");
    setSchemaConfig(data);
    setIsLoading(false);
  };

  useEffect(() => {
    const configWithData = fillUpConfigWithPredictions(schemaConfig, predictions);
    setFilledConfig(configWithData);
  }, [JSON.stringify(predictions), JSON.stringify(schemaConfig)]);

  return (
    <Container isDisabled={isLoading}>
      <StyledInput value={commandStr} onChange={e => setCommandStr(e.target.value)} onKeyDown={onKeyDown} />
      <DashboardConfigVisualiser config={filledConfig} />
    </Container>
  );
};

export default DashboardTabViewConfigurable;
