import { useState, useEffect } from "react";
import styled from "styled-components";
import { PowerSettingsNew } from "@material-ui/icons";

import { CenteredWithTopNavLayout, Gap } from "components/Layout";
import { BigTitle, SmallTitle } from "components/ui/Text";
import {
  getDeploymentsOfLoggedInUser,
  getDeploymentManagementConfig,
  updateDeploymentManagementConfig,
  stopDeploymentById,
} from "api/services/deploymentService";
import {
  getNotebookEnvironmentsOfLoggedInUser,
  getJupyterManagementConfig,
  updateJupyterManagementConfig,
  activateNotebookEnvironment,
  inActivateNotebookEnvironment,
} from "api/services/jupyterService";
import { getCubeByPipelineId } from "api/services/projectService";
import TextInput from "components/ui/TextInput";
import StandardButton from "components/ui/Button";
import { Link } from "react-router-dom";

const Th = styled.th`
  font-weight: bold;
  padding: 5px 0;
`;

const Td = styled.td`
  ${props => props.width && `width: ${props.width}`};
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 8px 0;
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.3; pointer-events: none;"}
  transition: background-color 0.2s;
  :hover {
    background-color: ${props => props.theme.color.closer0};
  }
`;

const PowerButtonContainer = styled.div`
  ${props => !props.isEnabled && "opacity: 0.3; pointer-events: none;"}
  cursor: pointer;
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
  width: max-content;
  padding: 2px;
  border-radius: 20px;
`;

const PanelTitle = styled(SmallTitle)`
  padding-top: 0;
  padding-bottom: 20px;
`;

const Inputs = styled.div`
  display: flex;
  width: 70%;
  gap: 20px;
  align-items: flex-end;
`;

const Button = styled(StandardButton)`
  margin: 0;
`;

const ButtonContainer = styled.div`
  ${props => !props.isEnabled && "opacity: 0.3; pointer-events: none;"}
`;

const CubeLink = styled(Link)`
  color: ${props => props.theme.color.primary};
`;

const formatId = id => (id ? `${id?.slice(0, 3)}...${id?.slice(-3)}` : "-");

const DeploymentRow = ({ deployment }) => {
  const [updatedDeployment, setUpdatedDeployment] = useState(deployment);
  const [isTurningOff, setIsTurningOff] = useState(false);
  const [error, setError] = useState(null);
  const [cube, setCube] = useState(null);

  useEffect(() => {
    doFetchCubeOfDeployment();
  }, []);

  const doFetchCubeOfDeployment = async () => {
    const { data } = await getCubeByPipelineId(updatedDeployment.pipelineId);
    setCube(data);
  };

  if (error) {
    return <tr>{JSON.stringify(error)}</tr>;
  }

  const cubeUrl = cube?.name?.includes("/") ? `/cubes/${cube?.name}` : `/cube/${cube?.id}`;

  return (
    <Tr key={updatedDeployment.id} isDisabled={isTurningOff}>
      <Td>{formatId(updatedDeployment.id)}</Td>
      <Td>{formatId(updatedDeployment.pipelineId)}</Td>
      <Td width="250px">{cube && <CubeLink to={cubeUrl}>{cube?.name}</CubeLink>}</Td>
      <Td>{updatedDeployment.startedAt}</Td>
      <Td>{updatedDeployment.stoppedAt}</Td>
      <Td>{updatedDeployment.state}</Td>
      <td>
        <PowerButtonContainer isEnabled={updatedDeployment.state === "SERVING"}>
          <PowerSettingsNew
            onClick={async () => {
              setIsTurningOff(true);
              const { data, error } = await stopDeploymentById(updatedDeployment?.id);
              setUpdatedDeployment(data);
              setError(error);
              setIsTurningOff(false);
            }}
          />
        </PowerButtonContainer>
      </td>
    </Tr>
  );
};

const JupyterEnvRow = ({ jupyterEnv }) => {
  const [updatedJupyterEnv, setUpdatedJupyterEnv] = useState(jupyterEnv);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cube, setCube] = useState(null);

  useEffect(() => {
    doFetchCubeOfDeployment();
  }, []);

  const doFetchCubeOfDeployment = async () => {
    const { data } = await getCubeByPipelineId(updatedJupyterEnv.pipelineId);
    setCube(data);
  };

  if (error) {
    return <tr>{JSON.stringify(error)}</tr>;
  }

  const doInactivateEnvironment = async () => {
    setIsLoading(true);
    const { data, error } = await inActivateNotebookEnvironment(updatedJupyterEnv.id);
    setUpdatedJupyterEnv(data);
    setError(error);
    setIsLoading(false);
  };

  const doActivateEnvironment = async () => {
    setIsLoading(true);
    const { data, error } = await activateNotebookEnvironment(updatedJupyterEnv.id);
    setUpdatedJupyterEnv(data);
    setError(error);
    setIsLoading(false);
  };

  const cubeUrl = cube?.name?.includes("/") ? `/cubes/${cube?.name}` : `/cube/${cube?.id}`;

  return (
    <Tr key={updatedJupyterEnv.id} isDisabled={isLoading}>
      <Td>{formatId(updatedJupyterEnv.id)}</Td>
      <Td>{formatId(updatedJupyterEnv.pipelineId)}</Td>
      <Td width="250px">{cube && <CubeLink to={cubeUrl}>{cube?.name}</CubeLink>}</Td>
      <Td>{updatedJupyterEnv.status}</Td>
      <td>
        <PowerButtonContainer isEnabled>
          <PowerSettingsNew
            onClick={() =>
              updatedJupyterEnv.status === "ACTIVE" ? doInactivateEnvironment() : doActivateEnvironment()
            }
          />
        </PowerButtonContainer>
      </td>
    </Tr>
  );
};

const AllJobsPage = () => {
  const [deployments, setDeployments] = useState([]);
  const [notebookEnvs, setNotebookEnvs] = useState([]);

  const [deploymentConfig, setDeploymentConfig] = useState(null);
  const [hasEditedDeploymentConfig, setHasEditedDeploymentConfig] = useState(false);
  const [isUpdatingDeploymentConfig, setIsUpdatingDeploymentConfig] = useState(false);

  const [jupyterConfig, setJupyterConfig] = useState(null);
  const [hasEditedJupyterConfig, setHasEditedJupyterConfig] = useState(false);
  const [isUpdatingJupyterConfig, setIsUpdatingJupyterConfig] = useState(false);

  const [error, setComponentError] = useState(null);
  const setError = err => err && setComponentError(err);

  useEffect(() => {
    doFetchDeploymentsOfLoggedInUser();
    doFetchNotebooksOfLoggedInUser();
    doFetchDeploymentManagementConfig();
    doFetchJupyterManagementConfig();
  }, []);

  const doFetchDeploymentsOfLoggedInUser = async () => {
    const { data, error } = await getDeploymentsOfLoggedInUser();
    setError(error);
    setDeployments(data);
  };

  const doFetchNotebooksOfLoggedInUser = async () => {
    const { data, error } = await getNotebookEnvironmentsOfLoggedInUser();
    setError(error);
    setNotebookEnvs(data);
  };

  const doFetchDeploymentManagementConfig = async () => {
    const { data, error } = await getDeploymentManagementConfig();
    setError(error);
    setDeploymentConfig(data);
  };

  const doFetchJupyterManagementConfig = async () => {
    const { data, error } = await getJupyterManagementConfig();
    setError(error);
    setJupyterConfig(data);
  };

  const doUpdateDeploymentConfig = async () => {
    setHasEditedDeploymentConfig(false);
    setIsUpdatingDeploymentConfig(true);
    const { error } = await updateDeploymentManagementConfig(deploymentConfig);
    setError(error);
    setIsUpdatingDeploymentConfig(false);
  };

  const doUpdateJupyterConfig = async () => {
    setHasEditedJupyterConfig(false);
    setIsUpdatingJupyterConfig(true);
    const { error } = await updateJupyterManagementConfig(jupyterConfig);
    setError(error);
    setIsUpdatingJupyterConfig(false);
  };

  if (error) {
    return <CenteredWithTopNavLayout>{JSON.stringify(error)}</CenteredWithTopNavLayout>;
  }

  return (
    <CenteredWithTopNavLayout>
      <BigTitle>All jobs</BigTitle>
      <Gap />
      <PanelTitle>Deployments</PanelTitle>
      <Inputs>
        <TextInput
          title="Max active instances"
          value={deploymentConfig?.maxInstances}
          type="number"
          min={1}
          onNewInput={maxInstances => {
            setHasEditedDeploymentConfig(true);
            setDeploymentConfig({ ...deploymentConfig, maxInstances: parseFloat(maxInstances) });
          }}
        />
        <TextInput
          title="Idle time before turn off (mins)"
          value={deploymentConfig?.timeoutMinutes}
          type="number"
          min={1}
          onNewInput={timeoutMinutes => {
            setHasEditedDeploymentConfig(true);
            setDeploymentConfig({ ...deploymentConfig, timeoutMinutes: parseFloat(timeoutMinutes) });
          }}
        />
        <ButtonContainer isEnabled={hasEditedDeploymentConfig && !isUpdatingDeploymentConfig}>
          <Button value={isUpdatingDeploymentConfig ? "Loading..." : "Save"} onClick={doUpdateDeploymentConfig} />
        </ButtonContainer>
      </Inputs>
      <Gap />
      <table>
        <thead>
          <tr>
            <Th>id</Th>
            <Th>pipelineId</Th>
            <Th>cube</Th>
            <Th>startedAt</Th>
            <Th>stoppedAt</Th>
            <Th>state</Th>
            <Th></Th>
          </tr>
        </thead>
        <tbody>
          {deployments.map(deployment => (
            <DeploymentRow deployment={deployment} />
          ))}
        </tbody>
      </table>
      <Gap height="40px" />
      <PanelTitle>Notebooks</PanelTitle>
      <Inputs>
        <TextInput
          title="Max active instances"
          value={jupyterConfig?.maxInstances}
          type="number"
          min={1}
          onNewInput={maxInstances => {
            setHasEditedJupyterConfig(true);
            setJupyterConfig({ ...jupyterConfig, maxInstances: parseFloat(maxInstances) });
          }}
        />
        <TextInput
          title="Idle time before turn off (mins)"
          value={jupyterConfig?.timeoutMinutes}
          type="number"
          min={1}
          onNewInput={timeoutMinutes => {
            setHasEditedJupyterConfig(true);
            setJupyterConfig({ ...jupyterConfig, timeoutMinutes: parseFloat(timeoutMinutes) });
          }}
        />
        <ButtonContainer isEnabled={hasEditedJupyterConfig && !isUpdatingJupyterConfig}>
          <Button value={isUpdatingJupyterConfig ? "Loading..." : "Save"} onClick={doUpdateJupyterConfig} />
        </ButtonContainer>
      </Inputs>
      <Gap />
      <table>
        <thead>
          <tr>
            <Th>id</Th>
            <Th>pipelineId</Th>
            <Th>cube</Th>
            <Th>status</Th>
          </tr>
        </thead>
        <tbody>
          {notebookEnvs.map(jupyterEnv => (
            <JupyterEnvRow jupyterEnv={jupyterEnv} />
          ))}
        </tbody>
      </table>
    </CenteredWithTopNavLayout>
  );
};

export default AllJobsPage;
