import styled from "styled-components";

const Container = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.color.closer1};
`;

const TabOption = styled.div`
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  line-height: 20px;
  font-weight: ${props => (props.isSelected ? "bold" : "normal")};
  color: ${props => props.isSelected ? props.theme.color.primary: props.theme.color.closest};
  border-bottom: ${props => (props.isSelected ? "2px solid "+props.theme.color.primary : "none")};
`;

const NavWithTabs = ({ tabNames, selectedTabName, onTabSelect, className }) => (
  <Container className={className}>
    {tabNames.map(tabName => (
      <TabOption onClick={() => onTabSelect(tabName)} key={tabName} isSelected={selectedTabName === tabName}>{tabName}</TabOption>
    ))}
  </Container>
);

export default NavWithTabs;
