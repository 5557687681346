import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { isEqual, isEmpty } from "lodash";
import { Help } from "@material-ui/icons";

import * as projectService from "api/services/projectService";
import EvaluationView from "components/views/EvaluationView";
import NavWithTabsExtended from "components/ui/NavWithTabsExtended";
import { BigTitle } from "components/ui/Text";
import ModelView from "components/views/ModelView";
import CubeVersionInfo from "components/widgets/CubeVersionInfo";
import DatasetPreview from "components/views/DatasetPreview";
import TrainingProgressView from "components/views/TrainingProgressView";
import TrainingProgressViewCustom from "components/views/TrainingProgressViewCustom";
import TrainingEditView from "components/views/TrainingEditView";
import MultiModelView from "components/views/MultiModelView";
import MultiTrainingProgressView from "components/widgets/MultiTrainingProgressView";
import MultiEvaluationView from "components/views/MultiEvaluationView";
import { usePipelineOutputsByIds } from "api/services/projectService/usePipelineOutputsByIds";
import { getLoggedInUserName } from "api/services/authenticationService";
import MultiDeplymentTabView from "components/views/MultiDeploymentTabView";
import MultiNotebookTabView from "components/views/MultiNotebookTabView";
import TwoNameSwitch from "components/ui/TwoNameSwitch";
import CubeFileListView from "components/views/CubeView/CubeFileListView";
import ModelTopBar from "components/widgets/TopBars/ModelTopBar";
import TrainingTopBar from "components/widgets/TopBars/TrainingTopBar";
import DatasetTopBar from "components/widgets/TopBars/DatasetTopBar";
import EvaluationTopBar from "components/widgets/TopBars/EvaluationTopBar";
import TrainingAutotuneView from "components/views/TrainingAutotuneView";
import CubeSettingsView from "components/views/CubeSettingsView";
import displayPipelineStatusUntilContentReady from "components/widgets/PipelineStatus/displayPipelineStatusUntilContentReady";
import GithubLinkForCube from "components/ui/GithubLinkForCube";
import ItemListPopover from "components/ui/ItemListPopover";
import {
  doStartDropDatasetColumnTutorial,
  doStartChangeDatasetColumnTypeTutorial,
  doSwitchToAutotuneModeTutorial,
  doSwitchToCustomTrainingModeTutorial,
} from "components/widgets/TutorialOverlay";
import TrainingExamplesView from "components/views/TrainingExamplesView";
import ModelViewCustomTrainingPipeline from "components/views/ModelViewCustomTrainingPipeline";
import CubeAboutView from "../CubeAboutView";
import EngagementBadges from "components/widgets/EngagementBadges";
import DatasetPreviewFinetune from "components/views/DatasetPreviewFinetune";

const ViewUnderTab = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.3"};
  padding: 0px 0px 30px 0px;
  background-color: ${props => props.theme.color.closer0};
  width: 100%;
  ${props => !props.allowExpansion && "height: 600px"};
  overflow-y: ${props => (props.areScrollbarsDisabled ? "hidden" : "auto")};
  border: 1px solid ${props => props.theme.color.closer1};
  border-radius: 0px 0px 5px 5px;
`;

const TitleAndSwitch = styled.div`
  padding: 20px 0;
  display: grid;
  align-items: center;
  grid-template-columns: max-content auto auto max-content;
  gap: 10px;
`;

const CubeTitle = styled(BigTitle)`
  padding: 0;
`;

const SwitchAndHelpButton = styled.div`
  display: flex;
  gap: 10px;
  svg {
    color: ${props => props.theme.color.primary};
  }
`;

const TutorialOption = styled.div`
  width: 250px;
  cursor: pointer;
  display: flex;
  padding: 10px;
  ${props => !props.noBorder && `border-bottom: 1px solid ${props.theme.color.closer1}`};
  :hover {
    background-color: ${props => props.theme.color.closer1};
  }
`;

const isDataProcessConfigValid = dataProcessConfig => {
  if (!dataProcessConfig?.config?.ops?.length) {
    return false;
  }

  const isEveryJoinOpValid = dataProcessConfig?.config?.ops
    .filter(op => op.name === "Join")
    .every(joinOp => joinOp.config.dataset && joinOp.config.group?.[0] !== null);

  const isThereOnlyJoinOps = dataProcessConfig?.config?.ops.filter(op => op.name !== "Join")?.length === 0;
  if (!isEveryJoinOpValid && isThereOnlyJoinOps) {
    return false;
  }

  return true;
};

const areThereTwoIdColumnsToJoinBy = dataProcessConfig => {
  const ops = dataProcessConfig?.config?.ops;
  if (!ops.find(op => op.name === "Join")) {
    return true;
  }
  return (
    ops?.find(op => op.name === "SetIndex") &&
    ops.filter(op => op.name === "Join").every(joinOp => joinOp.config.dataset && joinOp.config.group?.[0] !== null)
  );
};

const useHasUserEditedPipeline = (
  pipelineOutput,
  editedModel,
  editedTrainingConfig,
  editedTrainingSearchValues,
  editedCustomCode,
  editedDataProcessConfig,
  isAutotuningMode,
  isCustomizedTrainingMode
) => {
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    if (!pipelineOutput) {
      return;
    }

    const hasSwitchedCustomizedTrainingMode = isCustomizedTrainingMode === isEmpty(pipelineOutput?.customCode);
    const hasSwitchedAutoTuneMode = isAutotuningMode === isEmpty(pipelineOutput?.trainingBundle);

    const hasRegularPipelineConfigBeenModified =
      !isEqual(editedModel, pipelineOutput.model?.config) ||
      isDataProcessConfigValid(editedDataProcessConfig) ||
      !isEqual(editedTrainingConfig, pipelineOutput.trainingConfig);

    const hasAutoTunePiplineConfigBeenModified =
      isAutotuningMode &&
      !isEqual(editedTrainingSearchValues, pipelineOutput?.trainingSearchValues) &&
      !isEqual(editedTrainingSearchValues, pipelineOutput?.trainingBundle?.searchValues);

    const hasCustomizedTrainingBeenModified =
      isCustomizedTrainingMode && !isEqual(editedCustomCode, pipelineOutput?.customCode);

    const hasPipelineConfigBeenModified =
      hasSwitchedCustomizedTrainingMode ||
      hasSwitchedAutoTuneMode ||
      hasRegularPipelineConfigBeenModified ||
      hasAutoTunePiplineConfigBeenModified ||
      hasCustomizedTrainingBeenModified;

    setHasEdited(hasPipelineConfigBeenModified);
  }, [
    editedModel,
    editedTrainingConfig,
    pipelineOutput,
    editedTrainingSearchValues,
    editedCustomCode,
    editedDataProcessConfig,
    isAutotuningMode,
    isCustomizedTrainingMode,
  ]);
  return hasEdited;
};

const CubeViewFineTune = ({ cube }) => {
  const initialSearchValues = {
    "train.optimizer.learning_rate_init": [0.005],
    batch_size: [100],
    "train.trn_weight": [10],
    "infer.lkh_weight": [10],
    "train.max_iter": [200],
  };

  const [selectedPipelineIds, setSelectedPipelineIds] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedModel, setEditedModel] = useState(null);
  const [editedDataProcessConfig, setEditedDataProcessConfig] = useState(null);
  const [editedTrainingConfig, setEditedTrainingConfig] = useState(null);
  const [editedCustomCode, setEditedCustomCode] = useState(null);
  const [editedTrainingSearchValues, setEditedTrainingSearchValues] = useState(initialSearchValues);
  const [isShowingFiles, setIsShowingFiles] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") || "About");
  const [pipelineOutputs, doRestartPolling, pipelinesFetchErr] = usePipelineOutputsByIds(selectedPipelineIds);
  const [isAutotuningMode, setIsAutotuningMode] = useState(pipelineOutputs?.[0]?.trainingBundle);
  const [isCustomizedTrainingMode, setIsCustomizedTrainingMode] = useState(!!pipelineOutputs?.[0]?.customCode);
  const [isSavingPipeline, setIsSavingPipeline] = useState(false);
  const [modelView, setModelView] = useState("Graph"); // graph, config
  const [isShowingTrainingPlot, setIsShowingTrainingPlot] = useState(true);

  const navigate = useNavigate();

  const hasUserEditedPipeline = useHasUserEditedPipeline(
    pipelineOutputs[0],
    editedModel,
    editedTrainingConfig,
    editedTrainingSearchValues,
    editedCustomCode,
    editedDataProcessConfig,
    isAutotuningMode,
    isCustomizedTrainingMode
  );

  useEffect(() => {
    setSelectedTab(searchParams.get("tab") || "About");
    if (searchParams.get("should_extend_data")) {
      setIsEditMode(searchParams.get("should_extend_data") === "true");
    }
  }, [searchParams]);

  useEffect(() => {
    if (selectedPipelineIds.length === 1) {
      setEditedTrainingConfig(null);
      setEditedModel(null);
    }
  }, [selectedPipelineIds]);

  useEffect(() => {
    if (pipelineOutputs.length === 1) {
      setEditedTrainingConfig(editedTrainingConfig || pipelineOutputs[0]?.trainingConfig);
      setEditedModel(editedModel || pipelineOutputs[0].model?.config);
    }

    // auto-tune pipeline comparison is not supported, so pipelineOutputs.lenght is always 1
    if (pipelineOutputs?.[0]?.trainingBundle || pipelineOutputs?.[0]?.trainingSearchValues) {
      pipelineOutputs?.[0]?.trainingBundle
        ? setEditedTrainingSearchValues(pipelineOutputs[0].trainingBundle?.searchValues)
        : setEditedTrainingSearchValues(pipelineOutputs[0].trainingSearchValues);
    }

    // customerized training pipeline comparison is not supported, so pipelineOutputs.lenght is always 1
    if (pipelineOutputs?.[0]?.customCode) {
      setEditedCustomCode(pipelineOutputs?.[0]?.customCode);
    }
    setIsCustomizedTrainingMode(!isEmpty(pipelineOutputs?.[0]?.customCode));
  }, [pipelineOutputs]);

  useEffect(() => {
    isEditMode && setIsAutotuningMode(!!pipelineOutputs?.[0]?.trainingBundle);
  }, [isEditMode]);

  const doSavePipelineDraft = async () => {
    setIsSavingPipeline(true);

    if (isDataProcessConfigValid(editedDataProcessConfig)) {
      if (!areThereTwoIdColumnsToJoinBy(editedDataProcessConfig)) {
        alert("Please assign ID columns to join by, in both datasets");
        setIsSavingPipeline(false);
        return;
      }

      const { error } = await projectService.launchNewDataJobAndSavePipelineDraft(cube.id, editedDataProcessConfig);
      if (error) {
        alert("Failed when saving a new dataset version: " + JSON.stringify(error));
        return;
      }
      window.location = `/cube/${cube.id}`;
      return;
    }

    if (!pipelineOutputs[0]?.dataset?.id && !pipelineOutputs[0].dataProcessingJobId) {
      alert("Cannot submit pipeline since the dataset is unavailable.");
      return;
    }

    if (pipelineOutputs[0].status === "DRAFT") {
      const pipelineId = pipelineOutputs[0].pipelineId;
      const { error } = await projectService.updatePipelineDraftWithNewModelAndTrainingConfig(
        cube.id,
        pipelineId,
        pipelineOutputs[0].dataset.id,
        editedTrainingConfig,
        editedModel,
        pipelineOutputs[0].model?.config,
        isAutotuningMode ? editedTrainingSearchValues : null,
        isCustomizedTrainingMode ? editedCustomCode : null
      );

      if (error) {
        alert("Could not save the draft pipeline: ", JSON.stringify(error));
        return;
      }

      window.location = `/cube/${cube.id}`;
      return;
    } else {
      const { error } = await projectService.createPipelineDraftWithNewModelAndTrainingConfig(
        cube.id,
        pipelineOutputs[0].dataset.id,
        editedTrainingConfig,
        editedModel,
        pipelineOutputs[0].model,
        isAutotuningMode ? editedTrainingSearchValues : null,
        isCustomizedTrainingMode ? editedCustomCode : null
      );
      if (error) {
        alert("Could not create a new pipeline draft: ", JSON.stringify(error));
        return;
      }
      window.location = `/cube/${cube.id}`;
      return;
    }
  };

  const doExecutePipeline = async () => {
    if (!pipelineOutputs[0]?.dataset?.id) {
      alert("Cannot submit pipeline since there is no dataset.");
      return;
    }
    setIsSavingPipeline(true);
    const pipelineId = pipelineOutputs[0].pipelineId;
    const { data: pipelineConfig } = await projectService.getPipelineConfigById(pipelineId);

    if (pipelineOutputs[0].status === "DRAFT") {
      const { error } = await projectService.submitDraftPipelineConfig(cube.id, pipelineId, pipelineConfig);
      if (error) {
        alert("Could not execute pipeline: " + JSON.stringify(error));
        return;
      }
    } else {
      const { error } = await projectService.submitPipelineConfig(cube.id, pipelineConfig);
      if (error) {
        alert("Could not execute pipeline: " + JSON.stringify(error));
        return;
      }
    }
    window.location = `/cube/${cube.id}?tab=Training`;
  };

  // ui logics
  const selectTab = tabName => {
    setSearchParams({ tab: tabName });
    setSelectedTab(tabName);
  };

  const doesCubeBelongToLoggedInUser = cube?.createdBy === getLoggedInUserName();
  const isAutotunePipeline = pipelineOutputs[0]?.trainingBundle;

  const isDataJobInProgress =
    pipelineOutputs[0]?.dataProcessingJob?.status === "REGISTERED" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "IN_PROGRESS" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "ABORT" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "KILLED" ||
    pipelineOutputs[0]?.dataProcessingJob?.status === "DONE_FAILED";

  if (pipelinesFetchErr) {
    return "Pipeline fetch error: " + JSON.stringify(pipelinesFetchErr);
  }

  return (
    <>
      <TitleAndSwitch>
        <CubeTitle>
          {cube.createdBy}/{cube.name}
        </CubeTitle>
        <GithubLinkForCube cubeId={cube.id} />
        <div></div>
        <SwitchAndHelpButton>
          <TwoNameSwitch
            leftName="Cube view"
            rightName="File view"
            isOnLeft={!isShowingFiles}
            onSwitch={() => setIsShowingFiles(!isShowingFiles)}
          />
          <ItemListPopover iconComponent={<Help fontSize="large" />}>
            <TutorialOption
              onClick={() => {
                navigate("?tab=Dataset");
                doStartDropDatasetColumnTutorial();
              }}
            >
              How to drop a column from cube's dataset?
            </TutorialOption>
            <TutorialOption
              onClick={() => {
                navigate("?tab=Dataset");
                doStartChangeDatasetColumnTypeTutorial();
              }}
            >
              How to change a column's type in cube's dataset?
            </TutorialOption>
            <TutorialOption
              onClick={() => {
                navigate("?tab=Training");
                doSwitchToAutotuneModeTutorial();
              }}
            >
              How to use autotuning?
            </TutorialOption>
            <TutorialOption
              onClick={() => {
                navigate("?tab=Training");
                doSwitchToCustomTrainingModeTutorial();
              }}
            >
              How to do training with custom script?
            </TutorialOption>
            <TutorialOption noBorder onClick={() => {}}>
              How to create an API?
            </TutorialOption>
          </ItemListPopover>
        </SwitchAndHelpButton>
        <></>
        {/* <CubePopularityPlot /> */}
      </TitleAndSwitch>
      <EngagementBadges />
      <CubeVersionInfo
        cube={cube}
        selectedPipelineIds={selectedPipelineIds}
        onPipelineIdsSelect={ids => setSelectedPipelineIds(ids)}
      />
      {isShowingFiles && <CubeFileListView cubeId={cube?.id} pipelineId={pipelineOutputs[0]?.pipelineId} />}
      {pipelineOutputs[0] && !isShowingFiles && (
        <>
          <NavWithTabsExtended
            tabNames={
              isCustomizedTrainingMode
                ? ["About", "Dataset", "Training", "Model", "Settings"]
                : isAutotuningMode
                ? ["About", "Dataset", "Model", "Training", "API", "Notebook", "Settings"]
                : ["About", "Dataset", "Model", "Training", "Evaluation", "API", "Notebook", "Settings"]
            }
            disabledTabs={doesCubeBelongToLoggedInUser ? [] : ["Settings"]}
            selectedTabName={selectedTab}
            onTabSelect={newTab => selectTab(newTab)}
          />
          <ViewUnderTab
            isDisabled={isSavingPipeline}
            allowExpansion={selectedTab === "API"}
            areScrollbarsDisabled={selectedTab === "Model"}
          >
            {selectedTab === "About" && <CubeAboutView cube={cube} pipelineOutput={pipelineOutputs[0]} />}
            {selectedTab === "Settings" && <CubeSettingsView cubeId={cube.id} sharedWith={cube.sharedWith} />}

            {selectedTab === "Dataset" && !isEditMode && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <DatasetTopBar
                    isEditMode={isEditMode}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                  <DatasetPreview datasetId={pipelineOutputs[0]?.dataset?.id} dataProcessingConfig={null} />
                )}
              </>
            )}

            {selectedTab === "Dataset" && isEditMode && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <DatasetTopBar
                    isEditMode={isEditMode}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickCancel={() => {
                      setIsEditMode(false);
                      setEditedDataProcessConfig(null);
                    }}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                <DatasetPreviewFinetune
                  datasetId={pipelineOutputs[0]?.dataset?.id}
                  onChooseReplaceDataset={async newDatasetId => {
                    const { error } = await projectService.createPipelineDraftWithNewModelAndTrainingConfig(
                      cube.id,
                      newDatasetId,
                      editedTrainingConfig,
                      editedModel,
                      pipelineOutputs[0].model,
                      isAutotuningMode ? editedTrainingSearchValues : null,
                      isCustomizedTrainingMode ? editedCustomCode : null
                    );
                    if (error) {
                      alert("Could not create a new pipeline draft: ", JSON.stringify(error));
                      return;
                    }
                    window.location = `/cube/${cube.id}`;
                    return;
                  }}
                />
              </>
            )}
            {selectedTab === "Model" && pipelineOutputs?.[0]?.customCode && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <ModelTopBar
                    modelName={editedModel?.name}
                    onModelNameEdit={newName => setEditedModel({ ...editedModel, name: newName })}
                    isEditMode={isEditMode}
                    onClickCancel={() => {
                      setIsEditMode(false);
                    }}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    modelView={modelView}
                    onSetModelView={newView => setModelView(newView)}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                  <ModelViewCustomTrainingPipeline
                    modelView={modelView}
                    model={editedModel}
                    onModelEditing={m => setEditedModel(m)}
                    datasetFeatureTypeDescriptors={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors}
                  />
                )}
              </>
            )}

            {selectedTab === "Model" && selectedPipelineIds.length === 1 && !pipelineOutputs?.[0]?.customCode && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <ModelTopBar
                    isEditMode={isEditMode}
                    onClickCancel={() => {
                      setEditedModel(pipelineOutputs[0].model?.config);
                      setIsEditMode(false);
                    }}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    modelView={modelView}
                    onSetModelView={newView => setModelView(newView)}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                  <ModelView
                    modelId={pipelineOutputs[0]?.model?.id}
                    modelView={modelView}
                    inputCols={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors?.map(feature => feature.key)}
                    outputCols={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors?.map(
                      feature => feature.key
                    )}
                    datasetFeatureTypeDescriptors={pipelineOutputs[0]?.dataset?.config?.featureTypeDescriptors}
                  />
                )}
              </>
            )}
            {selectedTab === "Model" && !isEditMode && selectedPipelineIds.length > 1 && (
              <MultiModelView pipelineOutputs={pipelineOutputs} />
            )}

            {selectedTab === "Training" &&
              !isEditMode &&
              !pipelineOutputs[0]?.customCode &&
              selectedPipelineIds.length === 1 &&
              !isAutotunePipeline &&
              !isCustomizedTrainingMode && (
                <>
                  {doesCubeBelongToLoggedInUser && (
                    <TrainingTopBar
                      isEditMode={false}
                      onClickEdit={() => setIsEditMode(true)}
                      hasUserEditedPipeline={hasUserEditedPipeline}
                      onClickSave={doSavePipelineDraft}
                      onClickExecute={doExecutePipeline}
                      plotOrExamplesView={isShowingTrainingPlot ? "Plot" : "Examples"}
                      onSetPlotOrExamplesView={newView => setIsShowingTrainingPlot(newView === "Plot")}
                      isSubmitDisabled={
                        hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress
                      }
                    />
                  )}
                  {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) ||
                    (isShowingTrainingPlot ? (
                      <TrainingProgressView
                        pipelineOutput={pipelineOutputs[0]}
                        doRestartPipelineOutputPolling={doRestartPolling}
                      />
                    ) : (
                      <TrainingExamplesView trainingJobId={null} datasetId={pipelineOutputs[0]?.dataset?.id} />
                    ))}
                </>
              )}

            {selectedTab === "Training" && isEditMode && selectedPipelineIds.length === 1 && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <TrainingTopBar
                    isEditMode
                    onClickCancel={() => {
                      setEditedTrainingConfig(pipelineOutputs[0]?.trainingConfig);
                      setIsEditMode(false);
                    }}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                <TrainingEditView
                  trainingSearchValues={editedTrainingSearchValues}
                  onSetTrainingSearchValues={newSearchValues => setEditedTrainingSearchValues(newSearchValues)}
                  trainingConfig={editedTrainingConfig}
                  onTrainingConfigEditing={t => setEditedTrainingConfig(t)}
                  customCode={editedCustomCode}
                  onCustomCodeEditing={newCode => {
                    setEditedCustomCode(newCode);
                  }}
                  isAutotuningMode={isAutotuningMode}
                  onSetIsAutotuningMode={newAutotuningMode => setIsAutotuningMode(newAutotuningMode)}
                  isCustomizedTrainingMode={isCustomizedTrainingMode}
                  onSetIsCustomizedTrainingMode={newCustomizedTrainingMode => {
                    setIsCustomizedTrainingMode(newCustomizedTrainingMode);
                  }}
                  modelId={pipelineOutputs?.[0]?.modelId}
                  modelBeUpdated={!isEqual(editedModel, pipelineOutputs?.[0]?.model?.config)}
                />
              </>
            )}
            {selectedTab === "Training" && !isEditMode && selectedPipelineIds.length === 1 && isAutotunePipeline && (
              <>
                {doesCubeBelongToLoggedInUser && (
                  <TrainingTopBar
                    isEditMode={isEditMode}
                    onClickEdit={() => setIsEditMode(true)}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                    isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
                  />
                )}
                <TrainingAutotuneView
                  trainingJobs={pipelineOutputs[0]?.trainingBundle?.trainingJobs}
                  evaluationJobs={pipelineOutputs[0]?.trainingBundle?.evaluationJobs}
                />
              </>
            )}

            {selectedTab === "Training" &&
              !isEditMode &&
              selectedPipelineIds.length === 1 &&
              isCustomizedTrainingMode && (
                <>
                  {doesCubeBelongToLoggedInUser && (
                    <TrainingTopBar
                      isEditMode={isEditMode}
                      onClickEdit={() => setIsEditMode(true)}
                      hasUserEditedPipeline={hasUserEditedPipeline}
                      onClickSave={doSavePipelineDraft}
                      onClickExecute={doExecutePipeline}
                      isSubmitDisabled={
                        hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress
                      }
                    />
                  )}
                  {displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                    <TrainingProgressViewCustom pipelineOutput={pipelineOutputs[0]} />
                  )}
                </>
              )}
            {selectedTab === "Training" &&
              selectedPipelineIds.length > 1 &&
              (displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                <MultiTrainingProgressView pipelineOutputs={pipelineOutputs} />
              ))}

            {selectedTab === "Evaluation" &&
              selectedPipelineIds.length === 1 &&
              !pipelineOutputs[0].trainingBundle &&
              (displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                <>
                  <EvaluationTopBar
                    isEditMode={isEditMode}
                    hasUserEditedPipeline={hasUserEditedPipeline}
                    onClickCancel={() => setIsEditMode(false)}
                    onClickEdit={() => setIsEditMode(true)}
                    onClickSave={doSavePipelineDraft}
                    onClickExecute={doExecutePipeline}
                  />
                  <EvaluationView
                    evalJobId={pipelineOutputs[0]?.evaluationJob?.id}
                    pipelineOutput={pipelineOutputs[0]}
                  />
                </>
              ))}
            {selectedTab === "Evaluation" && selectedPipelineIds.length === 1 && pipelineOutputs[0].trainingBundle && (
              <EvaluationTopBar
                isEditMode={isEditMode}
                hasUserEditedPipeline={hasUserEditedPipeline}
                onClickCancel={() => setIsEditMode(false)}
                onClickEdit={() => setIsEditMode(true)}
                onClickSave={doSavePipelineDraft}
                onClickExecute={doExecutePipeline}
                isSubmitDisabled={hasUserEditedPipeline || !pipelineOutputs[0]?.dataset?.id || isDataJobInProgress}
              />
            )}
            {selectedTab === "Evaluation" &&
              selectedPipelineIds.length > 1 &&
              (displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                <MultiEvaluationView pipelineOutputs={pipelineOutputs} />
              ))}

            {selectedTab === "API" &&
              (displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                <MultiDeplymentTabView
                  pipelineOutputs={pipelineOutputs}
                  pipelineIdsToCompare={selectedPipelineIds}
                  cubeName={cube?.name}
                />
              ))}

            {selectedTab === "Notebook" &&
              (displayPipelineStatusUntilContentReady({ selectedTab, pipelineOutputs }) || (
                <MultiNotebookTabView pipelineOutputs={pipelineOutputs} pipelineIdsToCompare={selectedPipelineIds} />
              ))}
          </ViewUnderTab>
        </>
      )}
    </>
  );
};

export default CubeViewFineTune;
