import TwoNameSwitch from "components/ui/TwoNameSwitch";
import TrainingConfigEditor from "components/widgets/TrainingConfigEditor";
import TrainingConfigEditorAutotune from "components/widgets/TrainingConfigEditorAutotune";
import CustomizedTrainingEditor from "components/widgets/CustomizedTrainingEditor";
import styled from "styled-components";

const Container = styled.div`
  height: 500px;
  background-color: ${props => props.theme.color.closer1};
`;

const ProcessConfigPanel = styled.div`
  overflow-y: auto;
  background-color: ${props => props.theme.color.closer1};
  padding: 20px;
  padding-top: 0;
`;

const SwtichContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
`;

const TrainingEditView = ({
  trainingConfig,
  onTrainingConfigEditing,
  trainingSearchValues,
  onSetTrainingSearchValues,
  customCode,
  onCustomCodeEditing,
  isAutotuningMode,
  onSetIsAutotuningMode,
  isCustomizedTrainingMode,
  onSetIsCustomizedTrainingMode,
  modelId,
  modelBeUpdated,
  pipelineOutputCustomCode,
}) => {
  return (
    <Container>
      <ProcessConfigPanel>
        {isAutotuningMode ? (
          <TrainingConfigEditorAutotune
            trainingSearchValues={trainingSearchValues}
            onSetTrainingSearchValues={onSetTrainingSearchValues}
          />
        ) : isCustomizedTrainingMode ? (
          <CustomizedTrainingEditor
            customCode={customCode}
            onCustomCodeChange={onCustomCodeEditing}
            pipelineOutputCustomCode={pipelineOutputCustomCode}
          />
        ) : (
          <TrainingConfigEditor
            config={trainingConfig}
            onConfigChange={newConfig => onTrainingConfigEditing(newConfig)}
            modelId={modelId}
            modelBeUpdated={modelBeUpdated}
          />
        )}
      </ProcessConfigPanel>

      {!isAutotuningMode && (
        <SwtichContainer>
          <TwoNameSwitch
            dataTutorialId="custom-training-mode-switch"
            rightName="Enable Customized Training"
            isOnLeft={!isCustomizedTrainingMode}
            onSwitch={() => onSetIsCustomizedTrainingMode(!isCustomizedTrainingMode)}
          />
        </SwtichContainer>
      )}

      {!isCustomizedTrainingMode && (
        <SwtichContainer>
          <TwoNameSwitch
            isDisabled
            dataTutorialId="autotune-mode-switch"
            rightName="Enable Auto-tuning"
            isOnLeft={!isAutotuningMode}
            onSwitch={() => onSetIsAutotuningMode(!isAutotuningMode)}
          />
        </SwtichContainer>
      )}
    </Container>
  );
};

export default TrainingEditView;
