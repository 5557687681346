import styled from "styled-components";

const StyledTextArea = styled.textarea`
  grid-column: span 2;
  resize: none;

  font-size: 16px;
  padding: 10px 15px;
  height: 100px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  border: 2px solid ${props => props.theme.color.closer1_5};
  font-family: "Montserrat";
`;

const TextArea = ({ value, onNewInput, className }) => (
  <StyledTextArea className={className} value={value} onChange={e => onNewInput(e.target.value)} />
);

export default TextArea;
