import styled from "styled-components";
import { useState, useEffect } from "react";
import { round } from "lodash";
import { Link as ReactRouterLink } from "react-router-dom";

import { getCubeByCubeId } from "api/services/projectService";
import Button from "components/ui/Button";

const Th = styled.th`
  font-weight: bold;
  padding: 5px 0;
`;

const Td = styled.td`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  border-top: 1px solid ${props => props.theme.color.closer1};
  padding: 5px 0;
`;

const Link = styled(ReactRouterLink)`
  color: ${props => props.theme.color.primary};
`;

const SeeMoreButton = styled(Button)`
  padding: 5px 20px;
  min-width: 0;
`;

const formatId = id => (id ? `${id?.slice(0, 3)}...${id?.slice(-3)}` : "-");

const CubeNameLink = ({ cubeId }) => {
  const [cubeName, setCubeName] = useState("");

  const doFetchCubeName = async () => {
    const { data } = await getCubeByCubeId(cubeId);
    setCubeName(data?.name);
  };

  useEffect(() => {
    doFetchCubeName();
  }, [cubeId]);

  if (!cubeName) {
    return "-";
  }
  return <Link to={`/cube/${cubeId}`}>{cubeName}</Link>;
};

const JobsTable = ({ jobs, isJupyter }) => {
  const [maxJobIndex, setMaxJobIndex] = useState(10);

  if (jobs.length === 0) {
    return "No jobs";
  }

  return (
    <table>
      <thead>
        <tr>
          <Th>id</Th>
          <Th>started at</Th>
          <Th>stopped at</Th>
          {!isJupyter && <Th>worker type</Th>}
          {!isJupyter && <Th>pipeline id</Th>}
          {!isJupyter && <Th>cube name</Th>}
          <Th>cost</Th>
        </tr>
      </thead>
      <tbody>
        {jobs.slice(0, maxJobIndex).map(job => (
          <tr key={job.id}>
            <Td>{formatId(job.id)}</Td>
            <Td>{job.startedAt}</Td>
            <Td>{job.stoppedAt || "-"}</Td>
            {!isJupyter && <Td>{job.workerType || "-"}</Td>}
            {!isJupyter && <Td>{formatId(job.pipelineId)}</Td>}
            {!isJupyter && (
              <Td>
                <CubeNameLink cubeId={job.cubeId} />
              </Td>
            )}
            <Td>{round(job.cost, 4)}</Td>
          </tr>
        ))}
        {maxJobIndex < jobs.length && (
          <SeeMoreButton onClick={() => setMaxJobIndex(maxJobIndex + 10)} value="See more" />
        )}
      </tbody>
    </table>
  );
};

export default JobsTable;
