import { useState } from "react";

import { CenteredWithTopNavLayout } from "components/Layout";
import SurveyEditView from "components/views/SurveyEditView";
import { useNavigate } from "react-router-dom";
import { postSurvey } from "api/services/projectService";
import SurveySubmitResponseView from "components/views/SurveySubmitResponseView";

const BLANK_SURVEY = {
  id: "",
  status: "",
  fields: [],
  data: {
    title: "Untitled survey",
    description: "",
    questions: [],
  },
};

const CreateSurveyPage = () => {
  const [survey, setSurvey] = useState(BLANK_SURVEY);
  const [isCreatingSurvey, setIsCreatingSurvey] = useState(false);

  const [isPreviewingSurvey, setIsPreviewingSurvey] = useState(false);
  const [previewResponseData, setPreviewResponseData] = useState({});
  const navigate = useNavigate();

  return (
    <CenteredWithTopNavLayout centerColumnMaxWidth="600px">
      {isPreviewingSurvey ? (
        <SurveySubmitResponseView
          survey={survey}
          responseData={previewResponseData}
          onSetResponseData={newData => setPreviewResponseData(newData)}
          onClickEdit={() => setIsPreviewingSurvey(false)}
        />
      ) : (
        <SurveyEditView
          isDisabled={isCreatingSurvey}
          survey={survey}
          onSurveyEdit={newSurvey => setSurvey(newSurvey)}
          onClickPreview={() => setIsPreviewingSurvey(true)}
          onClickSave={async () => {
            setIsCreatingSurvey(true);
            const { data: newSurvey, error } = await postSurvey(survey);
            if (error) {
              alert(JSON.stringify(error));
              setIsCreatingSurvey(false);
              return;
            }
            navigate(`/survey/${newSurvey.id}`);
          }}
        />
      )}
    </CenteredWithTopNavLayout>
  );
};

export default CreateSurveyPage;
