import styled from "styled-components";
import { Settings, Timeline, PlaylistAddCheck, PlayArrow, MenuBook, InfoOutlined } from "@material-ui/icons";
import { DashboardIcon, DatabaseIcon, ModelIcon } from "components/ui/Icons";
import Tooltip from "components/ui/Tooltip";

const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.color.closer1};
  display: flex;
  justify-content: space-between;
`;

const LeftTabOptions = styled.div`
  display: flex;
`;

const TabOption = styled.div`
  ${props => props.isDisabled && "opacity: 0.3; pointer-events: none;"}
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  padding: 15px 15px 15px 15px;
  line-height: 20px;
  font-weight: ${props => (props.isSelected ? "bold" : "normal")};
  color: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closest)};
  border-bottom: ${props => (props.isSelected ? "2px solid " + props.theme.color.primary : "none")};
  transition: color 0.2s;
  img {
    transition: filter 0.2s;
    ${props => props.isSelected && "filter: none"};
  }
  svg {
    fill: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closer1_5)};
  }
`;

const SettingsTabOption = styled(TabOption)`
  svg {
    fill: ${props => (props.isSelected ? props.theme.color.primary : props.theme.color.closest)};
  }
`;

const TabOptionIcon = ({ tabName }) => {
  if (tabName === "About") {
    return <InfoOutlined />;
  }
  if (tabName === "Dataset") {
    return <DatabaseIcon height="1.2em" />;
  }
  if (tabName === "Model") {
    return <ModelIcon height="1.2em" />;
  }
  if (tabName === "Training") {
    return <Timeline />;
  }
  if (tabName === "Evaluation") {
    return <PlaylistAddCheck />;
  }
  if (tabName === "API") {
    return <PlayArrow />;
  }
  if (tabName === "Notebook") {
    return <MenuBook />;
  }
  if (tabName === "Dashboard") {
    return <DashboardIcon />;
  }
  return null;
};

const NavWithTabsExtended = ({ tabNames, selectedTabName, onTabSelect, className, disabledTabs = [] }) => {
  const tabNamesNoSettings = tabNames.filter(tabName => tabName !== "Settings");

  return (
    <Container className={className}>
      <LeftTabOptions>
        {tabNamesNoSettings.map(tabName => (
          <TabOption
            onClick={() => onTabSelect(tabName)}
            key={tabName}
            isSelected={selectedTabName === tabName}
            isDisabled={disabledTabs?.includes(tabName)}
          >
            <TabOptionIcon tabName={tabName} />
            {tabName}
          </TabOption>
        ))}
      </LeftTabOptions>
      <Tooltip title="Cube settings">
        <SettingsTabOption
          isSelected={selectedTabName === "Settings"}
          isDisabled={disabledTabs?.includes("Settings")}
          onClick={() => onTabSelect("Settings")}
        >
          <Settings />
        </SettingsTabOption>
      </Tooltip>
    </Container>
  );
};

export default NavWithTabsExtended;
