import { ForkIcon } from "components/ui/Icons";
import { useNavigate } from "react-router-dom";
import Tooltip from "components/ui/Tooltip";
import styled from "styled-components";

const BottomButtons = styled.div`
  ${props => props.isDisabled && "pointer-events: none; opacity: 0.8;"}
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

const AddCubeButton = styled.div`
  height: 15px;
  width: 25px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const ForkCubeButton = ({ cubeId }) => {
  const navigate = useNavigate();

  return (
    <BottomButtons>
      <Tooltip title="Fork the cube">
        <AddCubeButton
          data-tutorial-id="extend-cube-button-on-cube-card"
          onClick={() => {
            alert("Fork the cube");
          }}
        >
          <ForkIcon />
        </AddCubeButton>
      </Tooltip>
    </BottomButtons>
  );
};

export default ForkCubeButton;
