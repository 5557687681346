import styled from "styled-components";
import { useState, useEffect } from "react";
import Modal from "components/ui/Modal";
import TrainingProgressViewNoPause from "components/views/TrainingProgressViewNoPause";
import MultiOptionSelector from "components/ui/MultiOptionSelector";
import CustomCodeMirror from "components/ui/CustomCodeMirror";

const ModalContent = styled.div`
  width: 800px;
  height: 500px;
`;

const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;
  justify-content: center;
`

const TrainingJobInspectionModal = ({ trainingJob, isOpen, onClose }) => {
  const [view, setView] = useState("plot")

  return (
    <Modal title="Inspect training" open={isOpen} handleClose={onClose}>
      <OptionContainer>
        <MultiOptionSelector options={["plot", "config"]} selectedOption={view} onOptionSelect={newVal => setView(newVal)} />
      </OptionContainer>
      <ModalContent>
        { view ===  "plot" && <TrainingProgressViewNoPause jobId={trainingJob?.id} /> }
        { view === "config" && <CustomCodeMirror value={JSON.stringify(trainingJob.jobConfig, null, 4)} height={"500px"} showLineNumber={false} />}
      </ModalContent>
    </Modal>

  );
};

export default TrainingJobInspectionModal;
