import { useState, useEffect, useRef } from "react";
import map from "lodash/fp/map";
import flatMap from "lodash/fp/flatMap";
import filter from "lodash/fp/filter";
import range from "lodash/fp/range";
import Grid from "./Grid";
import Stone from "./Stone";
import mapValues from "lodash/fp/mapValues";
import { zapiPost } from "api/zapi";
import initialState from "./goInitialState.json";
import styled from "styled-components";
import Button from "components/ui/Button";

let range0 = range(0);
let compact = filter(Boolean);

const SVGContainer = styled.div`
  width: 450px;
  height: 450px;
`;

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  pointer-events: ${props => props.isDisabled && "none"};
  opacity: ${props => props.isDisabled && 0.2};
`;

const GiveUpBtn = styled.span`
  cursor: pointer;
  padding: 5px;
  bottom: 5px;
  background: gray;
  border-radius: 3px;
  border: 1px black solid;
  margin-right: 5px;

  :hover {
    background: white;
  }
`;

const Indicator = styled.div`
  height: 50px;
`;

const WhiteSpan = styled.span`
  padding: 10px;
  bottom: 5px;
  background: white;
  border-radius: 8px;
  border: 1px black solid;
  color: black;
  margin-right: 5px;
`;

const BlackSpan = styled.span`
  padding: 10px;
  bottom: 5px;
  background: black;
  border-radius: 8px;
  border: 1px black solid;
  color: lightgray;
`;

const Prompt = styled.div`
  padding: 20px;
  height: 150px;
  width: 400px;
  text-align: center;
  color: ${props => props.theme.color.closest};
  background: white;
`;

const StartButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GoGamePlay = ({ playerServiceUrlObj }) => {
  const [board, setBoard] = useState(null);
  const [stones, setStones] = useState(null);
  const [size, setSize] = useState(9);
  const [scale, setScale] = useState(20);
  const [cellSize, setCellSize] = useState(20);
  const [viewBox, setViewBox] = useState(null);
  const [isGameDone, setIsGameDone] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showGamePrompt, setShowGamePrompt] = useState(true);
  const [hover, setHover] = useState(null);

  const [status, setStatus] = useState({
    playerId: null,
    strings: null,
    newPrisoners: null,
    cumPrisoners: null,
  });

  const [proposals, setProposals] = useState(null);
  const [botScore, setBotScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const svgRef = useRef();

  useEffect(() => {
    if (playerServiceUrlObj) {
      initializeBoard();
      setViewBox(`0 0 ${size * scale} ${size * scale}`);
      setShowGamePrompt(true);
    }
  }, [playerServiceUrlObj]);

  useEffect(() => {
    board && updateStones();
  }, [board]);

  useEffect(async () => {
    if (status.playerId === 0) {
      await requestPlayer();
    } else {
      setIsDisabled(false);
    }
  }, [status]);

  useEffect(async () => {
    if (proposals) {
      const isValid = await requestEngine();
      if (!isValid) {
        setIsDisabled(false);
      }
    }
  }, [proposals]);

  const flatBoardRecover = flatBoard => {
    let rows = [];
    for (let row = 0; row < size; ++row) {
      let cols = [];
      for (let col = 0; col < size; ++col) {
        let index = row * size + col;
        cols[col] = flatBoard[index];
      }
      rows[row] = cols;
    }
    return rows;
  };

  const requestPlayer = async () => {
    setIsDisabled(true);
    const api_url = "/project-service/api/v1/go/player";
    const body = {
      signature_name: "serving_default",
      inputs: {
        player_id: status.playerId,
        rule_type: 2,
        top_move_or_inv_temp: -10,
        strings: status.strings,
        new_prisoners: status.newPrisoners,
        cum_prisoners: status.cumPrisoners,
      },
    };
    return zapiPost(api_url, playerServiceUrlObj, body)
      .then(response => {
        setProposals(response.data.outputs);
        return;
      })
      .catch(error => console.log(`error: ${api_url}`, error, error.response));
  };

  const requestEngine = async () => {
    setIsDisabled(true);
    const api_url = "/project-service/api/v1/go/engine";
    const playerId = status.playerId;
    const engineServiceurlOjb = {
      domain: "35.242.183.15:8501",
      basePath: "v1/models",
      resourcePath: "engine_model:predict",
    };
    const body = {
      signature_name: "serving_default",
      inputs: {
        player_id: status.playerId,
        proposals: proposals,
        strings: status.strings,
        new_prisoners: status.newPrisoners,
        cum_prisoners: status.cumPrisoners,
      },
    };
    return zapiPost(api_url, engineServiceurlOjb, body)
      .then(response => {
        const outputs = response.data.outputs;
        if (outputs["is_valid"][0]) {
          setStatus({
            playerId: 1 - status.playerId,
            strings: outputs["strings"],
            newPrisoners: outputs["new_prisoners"],
            cumPrisoners: outputs["cum_prisoners"],
          });
          setBoard(flatBoardRecover(outputs["boards"][0]));
          if (playerId === 0) {
            setBotScore(botScore + outputs["new_prisoners"][0].reduce((a, b) => a + b, 0));
          } else {
            setOpponentScore(opponentScore - outputs["new_prisoners"][0].reduce((a, b) => a + b, 0));
          }
          return true;
        } else {
          if (proposals[0].reduce((a, b) => a + b, 0) == 0 && playerId == 0) {
            alert("BoltzGo passed !");
          } else {
            alert("Oops....this move is invalid !");
          }
          return false;
        }
      })
      .catch(error => console.log(`error: ${api_url}`, error, error.response));
  };

  const initializeBoard = () => {
    let rows = [];
    for (let i = 0; i < size; ++i) {
      let cols = [];
      for (let j = 0; j < size; ++j) {
        cols[j] = 0;
      }
      rows[i] = cols;
    }
    setBoard(rows);
  };

  const updateStones = () => {
    let ret = compact(
      flatMap(y => {
        return map(x => {
          let boardValue = board[y][x];
          if (!boardValue) {
            return null;
          }
          let color = boardValue > 0 ? "white" : "black";
          let stone = {
            x: x,
            y: y,
            color: color,
          };
          return stone;
        })(range0(size));
      })(range0(size))
    );
    setStones(ret);
  };

  const pointToCoordinate = x => {
    let cs = cellSize;
    return x * cs + cs / 2.0;
  };

  const coordinateToPoint = x => {
    return Math.round((x - cellSize / 2) / cellSize);
  };

  const mouseMove = event => {
    if (isDisabled) {
      setHover(null);
    } else {
      let p = eventToPoint(event);
      isValidPoint(p) ? setHover({ x: p.x, y: p.y, color: "white" }) : setHover(null);
    }
  };

  const mouseLeave = () => {
    setHover(null);
  };

  const giveUp = () => {
    setShowGamePrompt(true);
  };

  const eventToPoint = event => {
    let point = svgRef.current.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    let ctm = svgRef.current.getScreenCTM();
    let { x, y } = point.matrixTransform(ctm.inverse());
    return mapValues(a => coordinateToPoint(a))({ x, y });
  };

  const isValidPoint = p => {
    let { x, y } = p;
    return x >= 0 && y >= 0 && x < size && y < size;
  };

  const click = async event => {
    if (isDisabled) {
      return;
    } else {
      let p = eventToPoint(event);
      if (!isValidPoint(p)) {
        return;
      }
      setProposals(pointToProposals(p));
    }
  };

  const pointToProposals = p => {
    let oneHot = new Array(size * size).fill(0);
    let index = p.y * size + p.x;
    oneHot[index] = 1;
    return [oneHot];
  };

  const startNewGame = () => {
    setShowGamePrompt(false);
    setBotScore(0);
    setOpponentScore(0);
    setStatus({
      playerId: initialState["inputs"]["player_id"],
      strings: initialState["inputs"]["strings"],
      newPrisoners: initialState["inputs"]["new_prisoners"],
      cumPrisoners: initialState["inputs"]["cum_prisoners"],
    });
  };

  return (
    <>
      {showGamePrompt && (
        <StartButtonContainer>
          <Button value={"New Game"} onClick={startNewGame}></Button>
        </StartButtonContainer>
      )}
      <Container isDisabled={showGamePrompt}>
        {/* <Modal open={showGamePrompt}>
        <Prompt>
          {
            isGameDone && <h5>Game Complete !</h5>
          }
          <h5>Start a new Game</h5>
          <Button value={"New Game"} onClick={startNewGame}></Button>
        </Prompt>
      </Modal> */}
        <Indicator>
          <WhiteSpan> Opponent: {opponentScore}</WhiteSpan>
          <GiveUpBtn onClick={giveUp}>Pass</GiveUpBtn>
          <BlackSpan> BoltzGo: {botScore}</BlackSpan>
        </Indicator>
        <SVGContainer>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            preserveAspectRatio={"xMidYMid meet"}
            onMouseOver={mouseMove}
            onMouseLeave={mouseLeave}
            onClick={click}
            ref={svgRef}
          >
            {size && <Grid size={size} />}
            {stones && (
              <g>
                {stones.map(stone => (
                  <Stone
                    x={pointToCoordinate(stone.x)}
                    y={pointToCoordinate(stone.y)}
                    color={stone.color}
                    key={stone.x + "|" + stone.y}
                  />
                ))}
                {hover && (
                  <Stone
                    style={{ fill: "white", stroke: "none", fillOpacity: 0.55 }}
                    x={pointToCoordinate(hover.x)}
                    y={pointToCoordinate(hover.y)}
                    color={hover.color}
                  />
                )}
              </g>
            )}
          </svg>
        </SVGContainer>
      </Container>
    </>
  );
};

export default GoGamePlay;
