import DatasetPreviewAnimated from "components/views/DatasetPreviewAnimated";
import ModelRecommendationFromPromptAnimated from "components/widgets/ModelRecommendationFromPropmtAnimated";
import ModelTopBar from "components/widgets/TopBars/ModelTopBar";
import styled from "styled-components";

const Container = styled.div`
  background-color: ${props => props.theme.color.closer0};
  width: 100%;
  height: 600px;
`;

const ModelTabViewAnimated = ({ pipelineConfig, pipelineOutput }) => {
  if (!pipelineConfig?.modelConfig?.architecture) {
    return (
      <ModelRecommendationFromPromptAnimated
        pipelineConfig={pipelineConfig}
        datasetName={pipelineOutput?.dataset?.name}
        featureTypeDescriptors={pipelineOutput?.dataset?.config?.featureTypeDescriptors}
      />
    );
  }

  return (
    <Container>
      <ModelTopBar />
      <DatasetPreviewAnimated datasetId={pipelineOutput?.dataset?.id} />
    </Container>
  );
};

export default ModelTabViewAnimated;
