import styled, { createGlobalStyle } from "styled-components";
import { getBezierPath, getEdgeCenter } from "react-flow-renderer";
import { useState } from "react";

const edgeColor = "#999898";

const Path = styled.path`
  fill: none;
  stroke-width: 3;
  stroke: ${props => (props.isEdgeRed ? "salmon" : props.edgeColor)};
  transition: stroke 0.2s;
`;

const IconPath = styled.path`
  fill: none;
  stroke-width: 2;
  stroke-linecap: round;
  stroke: #3d3d3d;
`;

const IconCircle = styled.circle`
  fill: ${props => props.fill || edgeColor};
  transition: fill 0.2s;
`;

const G = styled.g`
  :hover {
    opacity: 0.5;
  }
`;

const TanhText = styled.text`
  font-size: 17px;
`;

const SigText = styled.text`
  font-size: 17px;
`;

const ReluText = styled.text`
  font-size: 10px;
`;

const IconG = styled.g`
  ${props => props.isSelected && "pointer-events: none; opacity: 0.2;"}
  cursor: pointer;
  text {
    fill: ${props => props.theme.color.closest};
  }
`;

const HiddenG = styled.g`
  display: ${props => (props.isVisible ? "block" : "none")};
`;

const PanelRect = styled.rect`
  fill: ${props => props.theme.color.furthest};
  filter: drop-shadow(0 1px 1px rgba(153, 156, 159, 0.1));
`;

const CrossPath = styled.path`
  stroke: ${props => props.theme.color.furthest};
`;

const CrossCircle = styled.circle`
  fill: ${props => props.theme.color.closest};
`;

const CrossIcon = ({ cx = 0, cy = 0, scale = 0.7, onClick, onMouseEnter, onMouseLeave }) => (
  <G
    onClick={onClick}
    style={{ cursor: "pointer" }}
    transform={`translate(${cx - scale * 16}, ${cy - scale * 16}) scale(${scale})`}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <CrossCircle cx={29} cy={29} r={55} />
    <CrossPath d="M4 54L54 4M4 4L54 54" strokeWidth="10" />
  </G>
);

const ReluIcon = ({ cx = 0, cy = 0, scale = 0.7, onClick, label, isSelected, style, fill }) => (
  <IconG
    style={style}
    isSelected={isSelected}
    onClick={onClick}
    transform={`translate(${cx - scale * 16}, ${cy - scale * 16}) scale(${scale})`}
  >
    <IconCircle cx="16.5" cy="16.5" r="16.5" fill={fill} />
    <IconPath d="M5.97412 17.973H16.2155L25.085 9.10345" />
    <ReluText x={39} y={23}>
      {label}
    </ReluText>
  </IconG>
);

const SigmoidIcon = ({ cx = 0, cy = 0, scale = 0.4, onClick, label, isSelected, style, fill }) => (
  <IconG
    transform={`translate(${cx - 29 * scale}, ${cy - 29 * scale}) scale(${scale})`}
    isSelected={isSelected}
    style={style}
  >
    <IconCircle fill={fill} onClick={onClick} cx="29" cy="29" r="29" />
    <IconPath d="M8 36.8149C37.3313 39.8055 18.4181 17.1018 50 20.2396" />
    <SigText x={70} y={35}>
      {label}
    </SigText>
  </IconG>
);

const TanhIcon = ({ cx = 0, cy = 0, scale = 0.4, onClick, isLabelVisible }) => (
  <g transform={`translate(${cx - 29 * scale}, ${cy - 29 * scale}) scale(${scale})`}>
    <IconCircle onClick={onClick} cx="29" cy="29" r="29" />
    <IconPath d="M14.7085 46.0651C20.8088 24.8275 35.413 31.3814 43.4115 12.1187" />
    {isLabelVisible && (
      <TanhText x={70} y={35}>
        TANH
      </TanhText>
    )}
  </g>
);

const ArrowHeadStyling = createGlobalStyle`
  #react-flow__arrow polyline {
    stroke: ${props => props.color || edgeColor};
  }
  svg {
    pointer-events: none;
  }
  rect, circle, g {
    pointer-events: auto;
  }
  circle {
    cursor: pointer;
  }
`;

const ActivationPlusLayerOption = ({ activeFun, layerType, cy, cx, onClick, isSelected }) => {
  if (activeFun === "SIGMOID") {
    return (
      <SigmoidIcon
        isSelected={isSelected}
        cx={cx}
        cy={cy}
        scale={0.3}
        onClick={onClick}
        label={`SIGMOID, ${layerType}`}
        fill={layerTypeToColor[layerType]}
      />
    );
  }
  if (activeFun === "RELU") {
    return (
      <ReluIcon
        isSelected={isSelected}
        cx={cx}
        cy={cy}
        scale={0.52}
        onClick={onClick}
        label={`RELU, ${layerType}`}
        fill={layerTypeToColor[layerType]}
      />
    );
  }
  return null;
};

const optionsList = [
  ["SIGMOID", "FC"],
  ["SIGMOID", "TransposeConv2D"],
  ["RELU", "FC"],
  ["RELU", "TransposeConv2D"],
];

const layerTypeToColor = {
  FC: edgeColor,
  TransposeConv2D: "#a6cae3",
};

const OperationEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data: { activeFun, type = "FC", onClickDelete = () => {}, updateActiveFunOfEdge = () => {} } = {},
}) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const [centerX, centerY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const [isShowingOptions, setIsShowingOptions] = useState(false);

  const [isEdgeRed, setIsEdgeRed] = useState(false);

  let activeFunIcon = null;
  if (activeFun === "RELU") {
    activeFunIcon = (
      <ReluIcon
        cx={centerX}
        cy={centerY}
        onClick={() => setIsShowingOptions(!isShowingOptions)}
        style={{ opacity: 1 }}
        fill={isEdgeRed ? "salmon" : layerTypeToColor[type]}
      />
    );
  }
  if (activeFun === "SIGMOID") {
    activeFunIcon = (
      <SigmoidIcon
        cx={centerX}
        cy={centerY}
        onClick={() => setIsShowingOptions(!isShowingOptions)}
        style={{ opacity: 1 }}
        fill={isEdgeRed ? "salmon" : layerTypeToColor[type]}
      />
    );
  }
  if (activeFun === "LINEAR") {
    activeFunIcon = (
      <IconCircle cx={centerX} cy={centerY} r={11} onClick={() => setIsShowingOptions(!isShowingOptions)} />
    );
  }

  const rectHeight = 2 + optionsList.length * 20;

  return (
    <>
      <ArrowHeadStyling />
      <Path
        id={id}
        d={edgePath}
        // markerEnd="url(#react-flow__arrow)"
        isEdgeRed={isEdgeRed}
        edgeColor={layerTypeToColor[type]}
      />
      {activeFunIcon}
      <HiddenG id={`${id}-overlay`} isVisible={isShowingOptions}>
        <PanelRect rx={10} x={centerX - 26} y={centerY - rectHeight * 1.2} width={100} height={rectHeight} />
        {optionsList.map(([optionActiveFun, optionType], i) => (
          <ActivationPlusLayerOption
            key={i}
            cx={centerX - 15}
            cy={centerY - (28 + i * 20)}
            activeFun={optionActiveFun}
            layerType={optionType}
            isSelected={activeFun === optionActiveFun && type === optionType}
            onClick={() => updateActiveFunOfEdge(id, optionActiveFun, optionType)}
          />
        ))}
        <CrossIcon
          scale={0.1}
          cx={centerX + 18}
          cy={centerY + 12}
          onClick={() => onClickDelete(id)}
          onMouseEnter={() => setIsEdgeRed(true)}
          onMouseLeave={() => setIsEdgeRed(false)}
        />
      </HiddenG>
    </>
  );
};

export default OperationEdge;
